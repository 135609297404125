<template>
  <div class="publishLog">
    <div class="searchCondition">
      <div class="searchConditionItem">
        应用名称：
        <a-select
          v-model="projectId"
          placeholder="请选择应用名称"
          allowClear
          showSearch
          option-filter-prop="children"
          @change="projectChange"
        >
          <a-select-option v-for="i in projectIdList" :key="i.id">
            {{ i.enName }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        服务器：
        <a-select
          v-model="serverId"
          placeholder="请选择服务器"
          showSearch
          :filter-option="false"
          @search="instanceIdSearch"
          allowClear
          @change="projectChange"
        >
          <a-select-option v-for="item in instanceList" :key="item.id">{{
            item.instanceName
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(columns)"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="pagination"
      size="small"
    >
      <span
        slot="gitLog"
        slot-scope="text, record"
        style="
          display: inline-block;
          max-width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
        "
      >
        <span :title="text">{{ text }}</span>
      </span>
      <span slot="gitBranch" slot-scope="text, record" style="width: 100%">
        <a-tooltip placement="left">
          <span slot="title"> 查看git版本 </span>
          <a-button
            type="link"
            style="padding: 0"
            v-if="record.gitCommitUrl"
            @click="jumpGit(record)"
            class="gitBranch"
          >
            <a-icon type="branches" />{{ text }}#{{ record.gitCommitId }}
          </a-button>
        </a-tooltip>
        <span v-if="!record.gitCommitUrl"
          ><a-icon type="branches" />{{ text }}#{{ record.gitCommitId }}</span
        >
      </span>
      <span slot="serverIp" slot-scope="text, record" style="width: 100%">
        {{ text }}({{ record.serverName }})
      </span>
    </a-table>
  </div>
</template>
<script>
import { deliveryLog } from "../lib/publishLog.js";
import { projectList } from "../lib/projectList.js";
import { serverList } from "../lib/statusList.js";

export default {
  name: "publishLog",
  data() {
    return {
      columns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "申请编号",
          ellipsis: true,
          dataIndex: "requestId",
          width: 100,
        },
        {
          title: "英文名称",
          ellipsis: true,
          dataIndex: "enName",
          width: 180,
        },
        {
          title: "中文名称",
          ellipsis: true,
          dataIndex: "cnName",
          width: 180,
        },
        {
          title: "提交日志",
          ellipsis: true,
          dataIndex: "gitLog",

          scopedSlots: { customRender: "gitLog" },
        },
        {
          title: "服务器",
          ellipsis: true,
          dataIndex: "serverIp",
          width: 250,
          scopedSlots: { customRender: "serverIp" },
        },
        {
          title: "版本",
          ellipsis: true,
          dataIndex: "gitBranch",
          scopedSlots: { customRender: "gitBranch" },
          width: 150,
        },
        {
          title: "git提交日期",
          ellipsis: true,
          dataIndex: "gitDate",
          width: 150,
        },
        {
          title: "发布日期",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 150,
        },
      ],
      dataSource: [],
      pagination: {},
      pageNo: 1,
      projectId: undefined,
      projectIdList: [],
      instanceList: [],
      serverId: undefined,
    };
  },
  created() {
    this.query(1);
    this.getProject();
    this.getServerList();
  },
  methods: {
    getServerList(instanceName) {
      let data = {
        instanceName: instanceName,
      };
      serverList(data).then((res) => {
        if (res.result === 200) {
          this.instanceList = res.data;
        }
      });
    },
    instanceIdSearch(instanceName) {
      this.getServerList(instanceName);
    },
    // 查询应用名称列表
    getProject() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
      };
      projectList(data).then((res) => {
        if (res.result === 200) {
          this.projectIdList = res.data.list;
        }
      });
    },
    projectChange(val) {
      this.query(1);
    },
    // 搜索主机

    // 跳转git
    jumpGit(val) {
      let href = val.gitCommitUrl;
      window.open(href);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        projectId: this.projectId,
        serverId: this.serverId,
      };
      this.getDeliveryLog(data);
    },
    // 查询发布日志列表
    getDeliveryLog(data) {
      deliveryLog(data).then((res) => {
        if (res.result == 200) {
          let list = res.data.list;
          list.forEach((item, i) => {
            item.key = i + 1;
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            item.gitCommitId = item.gitCommitId.substr(0, 8);
          });
          this.dataSource = list;
          this.pagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.count}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.count,
            onChange: (current) => this.changePageItem(current),
          };
        }
      });
    },
    // 分页
    changePageItem(current) {
      this.query(current);
    },
  },
};
</script>
<style lang="scss" scoped>
.gitBranch {
  &::v-deep span {
    margin-left: 0 !important;
  }
}
</style>
