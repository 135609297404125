import http from "./axios";

// 分页查询nginx服务器组
export const listNginxServerGroup = ({ pageNo, pageSize, type, groupName }) => {
  return http.get("/delivery/nginxServerGroup/listNginxServerGroup", {
    pageNo: pageNo,
    pageSize: pageSize,
    type,
    groupName,
  });
};
export const effectiveSign = ({ id }) => {
  return http.json_post("/delivery/nginxServerGroup/effectiveSign", {
    id,
  });
};

// 修改nginx服务器组
export const addGroup = ({ groupName, inputRecord, type }) => {
  return http.json_post("/delivery/nginxServerGroup/addGroup", {
    groupName: groupName,
    inputRecord: inputRecord,
    type,
  });
};

export const modifyRemark = ({ nginxServerId, remark }) => {
  return http.json_post("/delivery/nginxServer/modifyRemark", {
    nginxServerId,
    remark,
  });
};
export const modifyPort = ({ nginxServerId, port }) => {
  return http.json_post("/delivery/nginxServer/modifyPort", {
    nginxServerId,
    port,
  });
};
export const modifyWeight = ({ nginxServerId, weight }) => {
  return http.json_post("/delivery/nginxServer/modifyWeight", {
    nginxServerId,
    weight,
  });
};

// 修改nginx服务器组
export const modifyGroup = ({ groupId, groupName, inputRecord, type }) => {
  return http.json_post("/delivery/nginxServerGroup/modifyGroup", {
    groupId: groupId,
    groupName: groupName,
    inputRecord: inputRecord,
    type,
  });
};

// 删除nginx服务器组
export const removeGroup = ({ groupId }) => {
  return http.json_post("/delivery/nginxServerGroup/removeGroup", {
    groupId: groupId,
  });
};

// 查询指定服务器组内的所有nginx服务器
export const listNginxServerInGroup = ({ groupId, pageNo, pageSize }) => {
  return http.get("/delivery/nginxServerGroup/listNginxServerInGroup", {
    groupId,
    pageNo,
    pageSize,
  });
};

// 向指定组添加nginx服务器
export const addServer = ({ groupId, itemList }) => {
  return http.json_post("/delivery/nginxServerGroup/addServer", {
    groupId,
    itemList,
  });
};
// 删除nginx服务器
export const removeServer = ({ id }) => {
  return http.json_post("/delivery/nginxServer/removeServer", {
    id,
  });
};
