import http from "./axios";

// 查询列表
export const queryPage = () => {
  return http.get("/delivery/timeControlModelList");
};

// 添加
export const addData = (data) => {
  return http.json_post("/delivery/addTimeControlModel", data);
};

// 编辑
export const editData = (data) => {
  return http.json_post("/delivery/editTimeControlModel", data);
};

// 删除
export const deleteData = (data) => {
  return http.json_post("/delivery/deleteTimeControlModel", data);
};

export const timeControlModelDetailList = (data) => {
  return http.get("/delivery/timeControlModelDetailList", data);
};

export const addTimeControlModelDetail = (data) => {
  return http.json_post("/delivery/addTimeControlModelDetail", data);
};

export const editTimeControlModelDetail = (data) => {
  return http.json_post("/delivery/editTimeControlModelDetail", data);
};

export const deleteTimeControlModelDetail = (data) => {
  return http.json_post("/delivery/deleteTimeControlModelDetail", data);
};
