import http from "./axios";

const getApiClassList = ({ projectId, uuid }) => {
  return http.get("/yapi/out/interface/listMenu", {
    projectId,
    uuid,
  });
};

const getApiList = ({ id, uuid }) => {
  return http.get("/yapi/out/interface/get", {
    id,
    uuid,
  });
};

const addClass = ({ yapiProjectId, name, desc, uuid }) => {
  return http.json_post("/yapi/out/interface/addCat", {
    yapiProjectId,
    name,
    desc,
    uuid,
  });
};

const editClass = ({ catId, name, desc, uuid }) => {
  return http.json_post("/yapi/out/interface/upCat", {
    catId,
    name,
    desc,
    uuid,
  });
};

const editApi = ({
  id,
  catId,
  index,
  markdown,
  method,
  path,
  yapiProjectId,
  queryPath,
  reqBodyForm,
  reqBodyIsJsonSchema,
  reqBodyOther,
  reqBodyType,
  reqHeaders,
  reqParams,
  reqQuery,
  resBody,
  resBodyIsJsonSchema,
  resBodyType,
  status,
  tag,
  title,
  userLoginName,
  uuid,
}) => {
  return http.json_post("/yapi/out/interface/up", {
    id,
    catId,
    index,
    markdown,
    method,
    path,
    yapiProjectId,
    queryPath,
    reqBodyForm,
    reqBodyIsJsonSchema,
    reqBodyOther,
    reqBodyType,
    reqHeaders,
    reqParams,
    reqQuery,
    resBody,
    resBodyIsJsonSchema,
    resBodyType,
    status,
    tag,
    title,
    userLoginName,
    uuid,
  });
};

const addApi = ({ yapiProjectId, catId, method, path, title, uuid }) => {
  return http.json_post("/yapi/out/interface/add", {
    yapiProjectId,
    catId,
    method,
    path,
    title,
    uuid,
  });
};

// 删除
const copyApi = ({ id, uuid }) => {
  return http.json_post("/yapi/out/interface/copy", {
    id,
    uuid,
  });
};

// 删除
const deleteClass = ({ catId }) => {
  return http.json_post("/yapi/out/interface/delCat", {
    catId,
    uuid,
  });
};

// 删除
const deleteApi = ({ id, uuid }) => {
  return http.json_post("/yapi/out/interface/del", {
    id,
    uuid,
  });
};

// 查询列表
const queryPage = ({ page, limit, projectId, uuid }) => {
  return http.get("/yapi/out/interface/list", {
    page,
    limit,
    projectId,
    uuid,
  });
};

const catList = ({ page, limit, catId, uuid }) => {
  return http.get("/yapi/out/interface/listCat", {
    page,
    limit,
    catId,
    uuid,
  });
};

const simple = ({ id, status, catId, uuid }) => {
  return http.json_post("/yapi/out/interface/up/simple", {
    id,
    status,
    catId,
    uuid,
  });
};

export default {
  deleteClass,
  getApiClassList,
  getApiList,
  addClass,
  editClass,
  addApi,
  deleteApi,
  editApi,
  copyApi,
  queryPage,
  catList,
  simple,
};
