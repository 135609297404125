<template>
  <div class="interfaceView" :style="{ height: interfaceViewHeight + 'px' }">
    <a-layout style="height: 100%">
      <a-layout-sider
        style="background: #f2f6fc; max-width: 300px; min-width: 300px"
      >
        <tree-file
          :treeData="treeFileData"
          :loadUrl="loadUrl"
          :serverAccountId="serverAccountId"
          @onSelect="onSelect"
          @onExpand="onExpand"
          @onLoadData="onLoadData"
        >
        </tree-file>
      </a-layout-sider>
      <a-layout style="background: #fff">
        <a-layout-header class="i_header_c">
          <a-breadcrumb class="i_header_c_bread">
            <a-breadcrumb-item v-for="item in routes" :key="item.path">
              <a-icon
                type="home"
                @click="showRootFileList"
                v-if="item.breadcrumbName === '/'"
              />
              <a v-else href="javascript:;" @click="breadcrumbClick(item)">{{
                item.breadcrumbName
              }}</a>
            </a-breadcrumb-item>
          </a-breadcrumb>
          <div class="i_header_c_search">
            <a-input-search
              placeholder="试试搜索"
              size="small"
              v-model="searchValue"
              @change="onSearchChange"
            />
          </div>
        </a-layout-header>
        <a-layout-content style="background: #fff">
          <file-list
            :fileList="fileList"
            @fileClick="fileClick"
            @dbFileClick="dbFileClick"
            @fileDivClick="fileDivClick"
            @contextmenuFileView="contextmenuFileView"
            @contextmenuFile="contextmenuFile"
          ></file-list>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>
<script>
import fileList from "./fileList";
import treeFile from "./treeFile";
export default {
  name: "interfaceView",
  components: {
    fileList,
    treeFile,
  },
  props: {
    // 页面高差
    diffHeight: {
      type: Number,
      default: 0,
    },
    // 服务器id
    serverAccountId: {
      type: Number,
      default: -1,
    },
    // 动态加载目录url
    loadUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      routes: [],
      fileList: [],
      oldFileList: [],
      selectKey: "",
      intervalTime: 0,
      interfaceViewHeight: 100,
      newTreeList: [],
      syncLoading: null,
      searchValue: "",
      treeFileData: [],
    };
  },
  mounted() {
    let div = document.getElementById("app");
    this.interfaceViewHeight = div.offsetHeight - this.diffHeight;
    window.addEventListener("resize", () => {
      let div = document.getElementById("app");
      this.interfaceViewHeight = div.offsetHeight - this.diffHeight;
    });
    this.getListFile("/");
  },
  methods: {
    // 左击文件管理页面
    fileDivClick(e) {
      this.$emit("fileDivClick", e);
    },
    // 右击文件
    contextmenuFile(e, item) {
      this.$emit("contextmenuFile", e, item);
    },
    // 右击文件管理页面
    contextmenuFileView(e) {
      this.$emit("contextmenuFileView", e);
    },
    // 搜索文件
    onSearchChange(e) {
      let value = e.target.value;
      let newFileList = [];
      this.oldFileList.forEach((item) => {
        if (item.title.indexOf(value) > -1) {
          newFileList.push(item);
        }
      });
      this.fileList = newFileList;
    },
    // 点击面包屑导航
    breadcrumbClick(val) {
      let pathArr = [];
      for (let i = 0; i < this.routes.length; i++) {
        let item = this.routes[i];
        pathArr.push(item.path);
        if (val.path === item.path) {
          break;
        }
      }
      let eventKey = pathArr.join("/").substr(1);
      this.routes = [];
      pathArr.forEach((item) => {
        this.routes.push({
          path: item,
          breadcrumbName: item,
        });
      });
      this.showFileList(eventKey);
    },
    // 查询列表文件
    getListFile(path) {
      let data = {
        serverAccountId: this.serverAccountId,
        path: path,
      };
      this.syncLoading = this.$syncLoading();
      this.$http
        .get(this.loadUrl, data)
        .then((res) => {
          this.syncLoading.close();
          if (res.result === 200) {
            let list = res.data;
            let newArr = [];
            list.forEach((item) => {
              let obj = {
                title: item.name,
                key: "/" + item.name,
                icon: item.fileType === "DIR" ? "folder" : "file",
                scopedSlots: { icon: "custom" },
                hasChildrenFile: item.fileType === "DIR" ? true : false,
                isLeaf: item.fileType === "DIR" ? false : true,
                filePath: [item.name],
              };
              newArr.push(obj);
            });
            this.treeFileData = newArr;
            this.showRootFileList();
          }
        })
        .catch((err) => {
          this.syncLoading.close();
        });
    },
    // 动态加载
    onLoadData(treeNode, data) {
      let treeList = this.$common.deepClone(data);
      let newArr = [];
      let superiorNode = this.loopGetKeys(treeNode.eventKey, this.treeFileData);
      treeList.forEach((item) => {
        let obj = {
          title: item.name,
          key: treeNode.eventKey + "/" + item.name,
          icon: item.fileType === "DIR" ? "folder" : "file",
          scopedSlots: { icon: "custom" },
          hasChildrenFile: item.fileType === "DIR" ? true : false,
          isLeaf: item.fileType === "DIR" ? false : true,
          filePath: [item.name],
        };
        obj.filePath = [...superiorNode.filePath, ...obj.filePath];
        newArr.push(obj);
      });
      treeNode.dataRef.children = newArr;
      this.treeFileData = [...this.treeFileData];
    },
    // 展开/收起节点时触发
    onExpand(expandedKeys, e) {
      // this.$emit("onExpand", expandedKeys, e);
      console.log("展开");
    },
    // 点击文件列表
    onSelect(info) {
      if (this.selectKey !== info.node.eventKey) {
        this.intervalTime = 0;
      }
      let newTime = new Date().getTime();
      let diff = newTime - this.intervalTime;
      this.intervalTime = newTime;
      this.selectKey = info.node.eventKey;
      // 判断双击
      if (diff > 800) {
        return;
      }
      this.selectKey = "";
      if (!info.node.dataRef.hasChildrenFile) {
        return;
      }
      this.routes = [
        {
          path: "/",
          breadcrumbName: "/",
        },
      ];
      let pathArr = info.node.dataRef.filePath;
      pathArr.forEach((item) => {
        this.routes.push({
          path: item,
          breadcrumbName: item,
        });
      });
      this.newTreeList = [];
      this.newTreeList = this.$common.deepClone(this.treeFileData);
      this.showFileList(info.node.eventKey);
    },
    // 展示文件图形
    showFileList(eventKey) {
      this.fileList = [];
      let treeNode = this.loopGetKeys(eventKey, this.newTreeList);
      if (treeNode.hasChildrenFile) {
        let data = {
          serverAccountId: this.serverAccountId,
          path: eventKey,
        };
        this.syncLoading = this.$syncLoading();
        this.$emit("getPath", data.path);
        this.$http
          .get(this.loadUrl, data)
          .then((res) => {
            this.syncLoading.close();
            if (res.result === 200) {
              let list = res.data;
              let newArr = [];
              list.forEach((item) => {
                let obj = {
                  title: item.name,
                  key: treeNode.key + "/" + item.name,
                  icon: item.fileType === "DIR" ? "folder" : "file",
                  scopedSlots: { icon: "custom" },
                  hasChildrenFile: item.fileType === "DIR" ? true : false,
                  isLeaf: item.fileType === "DIR" ? false : true,
                  filePath: [item.name],
                };
                obj.filePath = [...treeNode.filePath, ...obj.filePath];
                newArr.push(obj);
              });
              treeNode.children = newArr;
              this.newTreeList = [...this.newTreeList];
              for (let i in newArr) {
                let item = newArr[i];
                let label = item.title;
                if (item.title.length > 12) {
                  label =
                    item.title.substr(0, 5) +
                    "..." +
                    item.title.substr(item.title.length - 5);
                }
                let obj = {
                  id: item.key,
                  title: item.title,
                  label: label,
                  img: item.hasChildrenFile
                    ? require("../../assets/wenjianjia.png")
                    : require("../../assets/wenjian.png"),
                  filePath: item.filePath,
                  hasChildrenFile: item.hasChildrenFile,
                };
                this.fileList.push(obj);
                this.oldFileList = this.$common.deepClone(this.fileList);
                this.searchValue = "";
              }
            }
          })
          .catch((err) => {
            this.syncLoading.close();
          });
      }
    },
    // 展示根目录文件图形
    showRootFileList() {
      this.routes = [
        {
          path: "/",
          breadcrumbName: "/",
        },
      ];
      this.fileList = [];
      for (let i in this.treeFileData) {
        let item = this.treeFileData[i];
        let label = item.title;
        if (item.title.length > 12) {
          label =
            item.title.substr(0, 5) +
            "..." +
            item.title.substr(item.title.length - 5);
        }
        let obj = {
          id: item.key,
          title: item.title,
          label: label,
          img: item.hasChildrenFile
            ? require("../../assets/wenjianjia.png")
            : require("../../assets/wenjian.png"),
          filePath: item.filePath,
          hasChildrenFile: item.hasChildrenFile,
        };
        this.fileList.push(obj);
        this.oldFileList = this.$common.deepClone(this.fileList);
        this.searchValue = "";
      }
      this.newTreeList = [];
      this.newTreeList = this.$common.deepClone(this.treeFileData);
    },
    // 点击文件
    fileClick(item) {},
    // 双击文件
    dbFileClick(item) {
      if (item.hasChildrenFile) {
        this.routes = [
          {
            path: "/",
            breadcrumbName: "/",
          },
        ];
        let pathArr = item.filePath;
        pathArr.forEach((item) => {
          this.routes.push({
            path: item,
            breadcrumbName: item,
          });
        });
        this.showFileList(item.id);
      }
    },
    // 获取点击列表info
    loopGetKeys(key, tree) {
      let res;
      for (let i = 0; i < tree.length; i++) {
        const item = tree[i];
        if (item.key === key) {
          res = item;
        } else {
          if (item.children && this.loopGetKeys(key, item.children)) {
            res = this.loopGetKeys(key, item.children);
          }
        }
      }
      return res;
    },
  },
};
</script>
<style lang="scss" scoped>
.interfaceView {
  width: 100%;
  height: 100%;
  border-top: 1px solid #e8e8e8;
  .i_header_c {
    background: #fff;
    padding: 0px 10px;
    line-height: 32px;
    height: 32px;
    border-bottom: 1px solid #dcdfe6;
    display: flex;
    justify-content: space-between;
    .i_header_c_bread {
      display: inline-block;
      line-height: 32px;
    }
    .i_header_c_search {
      display: inline-block;
      line-height: 32px;
      padding-right: 10px;
    }
  }
}
</style>
