<template>
  <div>
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="name"
          @pressEnter="query"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="edit(record)">修改</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="updateNodeAffinity(record)"
          >设置节点亲和性</a
        >
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <a-popconfirm
      placement="right"
      okText="确认"
      cancelText="取消"
      @confirm="batchDeleteClick()"
      :disabled="selectedRowKeys.length == 0"
    >
      <template slot="title">确认是否批量删除</template>
      <a-button
        type="danger"
        icon="delete"
        :disabled="selectedRowKeys.length == 0"
        >批量删除</a-button
      >
    </a-popconfirm>

    <a-modal
      :title="title"
      v-model="updateNodeAffinityTemplateShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="名称:">
          <a-input v-model="updateData.name" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item label="备注:">
          <a-textarea
            v-model="updateData.remark"
            placeholder="请输入备注"
            autosize
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="updateNodeAffinityTemplateShow = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            @click="updateNodeAffinityTemplateSubmit()"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="设置节点亲和性"
      v-model="updateNodeAffinityShow"
      :maskClosable="false"
      width="1000px"
    >
      <a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
        <a-form-item label="必须满足:">
          <a-button type="link" @click="addRequired" size="small"
            >添加规则</a-button
          >
          <div v-for="(item, ruleIndex) in required">
            <div class="item">
              <a-button
                type="link"
                @click="deleteRequired(ruleIndex)"
                size="small"
                class="close"
              >
                <a-icon type="close" />
              </a-button>
              <a-button
                type="link"
                @click="addRequiredItem(ruleIndex)"
                size="small"
                >添加</a-button
              >
              <a-table
                :rowClassName="$common.rowClassColor"
                bordered
                :components="$common.getTitle(nodeAffinityTableColumns)"
                :columns="nodeAffinityTableColumns"
                :dataSource="item.itemList"
                :pagination="false"
                size="small"
              >
                <span slot="labelName" slot-scope="text, record">
                  <a-input v-model="record.labelName" />
                </span>
                <span slot="operator" slot-scope="text, record">
                  <a-select v-model="record.operator" style="width: 130px">
                    <a-select-option
                      v-for="(operatorItem, index) in operatorSelectList"
                      :key="index"
                      :value="operatorItem"
                    >
                      {{ operatorItem }}
                    </a-select-option>
                  </a-select>
                </span>
                <span slot="labelValue" slot-scope="text, record">
                  <a-input
                    v-model="record.labelValue"
                    placeholder="多个值用逗号分隔"
                    :disabled="
                      record.operator == 'DoesNotExist' ||
                      record.operator == 'Exists'
                    "
                  />
                </span>
                <span slot="action" slot-scope="text, record, index">
                  <a
                    href="javascript:;"
                    style="color: #ff4d4f"
                    @click="deleteRequiredItem(ruleIndex, index)"
                    >删除</a
                  >
                </span>
              </a-table>
            </div>
          </div>
        </a-form-item>
        <a-form-item label="尽量满足:">
          <a-button type="link" @click="addOptional" size="small"
            >添加规则</a-button
          >
          <div v-for="(item, ruleIndex) in optional">
            <div class="item">
              <a-button
                type="link"
                @click="deleteOptional(ruleIndex)"
                size="small"
                class="close"
              >
                <a-icon type="close" />
              </a-button>
              <div>权重</div>
              <a-input v-model="item.weight" placeholder="请输入权重" />
              <a-button
                type="link"
                @click="addOptionalItem(ruleIndex)"
                size="small"
                >添加</a-button
              >
              <a-table
                :rowClassName="$common.rowClassColor"
                bordered
                :components="$common.getTitle(nodeAffinityTableColumns)"
                :columns="nodeAffinityTableColumns"
                :dataSource="item.itemList"
                :pagination="false"
                size="small"
              >
                <span slot="labelName" slot-scope="text, record">
                  <a-input v-model="record.labelName" />
                </span>
                <span slot="operator" slot-scope="text, record">
                  <a-select v-model="record.operator" style="width: 130px">
                    <a-select-option
                      v-for="(operatorItem, index) in operatorSelectList"
                      :key="index"
                      :value="operatorItem"
                    >
                      {{ operatorItem }}
                    </a-select-option>
                  </a-select>
                </span>
                <span slot="labelValue" slot-scope="text, record">
                  <a-input
                    v-model="record.labelValue"
                    placeholder="多个值用逗号分隔"
                    :disabled="
                      record.operator == 'DoesNotExist' ||
                      record.operator == 'Exists'
                    "
                  />
                </span>
                <span slot="action" slot-scope="text, record, index">
                  <a
                    href="javascript:;"
                    style="color: #ff4d4f"
                    @click="deleteOptionalItem(ruleIndex, index)"
                    >删除</a
                  >
                </span>
              </a-table>
            </div>
          </div>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="updateNodeAffinityShow = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            @click="updateNodeAffinitySubmit()"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import * as api from "../lib/k8s.js";

export default {
  data() {
    return {
      name: "",
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          width: 100,
        },
        {
          title: "名称",
          dataIndex: "name",
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          width: 200,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 250,
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      pageNo: 1,
      selectedRowKeys: [],
      title: "",
      updateNodeAffinityTemplateShow: false,
      ruleId: undefined,
      updateData: {
        name: "",
        remark: "",
      },
      required: [],
      optional: [],
      updateNodeAffinityShow: false,
      nodeAffinityTableColumns: [
        {
          title: "标签名",
          dataIndex: "labelName",
          scopedSlots: { customRender: "labelName" },
        },
        {
          title: "操作符",
          dataIndex: "operator",
          scopedSlots: { customRender: "operator" },
          width: 100,
        },
        {
          title: "标签值",
          dataIndex: "labelValue",
          scopedSlots: { customRender: "labelValue" },
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 60,
        },
      ],
      operatorSelectList: ["In", "NotIn", "Exists", "DoesNotExist", "Gt", "Lt"],
    };
  },
  created() {
    this.query();
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query();
    },
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        name: this.name,
      };
      this.loadingTable = true;
      api
        .nodeAffinityPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource = res.data.records;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: 15,
              current: this.pageNo,
              total: res.data.total,
              onChange: (current) => this.query(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    add() {
      this.title = "新增";
      this.updateData = {
        name: "",
        remark: "",
      };
      this.updateNodeAffinityTemplateShow = true;
    },
    edit(val) {
      this.title = "修改";
      this.addName = val.name;
      this.updateData = {
        id: val.id,
        name: val.name,
        remark: val.remark,
      };
      this.updateNodeAffinityTemplateShow = true;
    },
    updateNodeAffinityTemplateSubmit() {
      let data = { ...this.updateData };
      if (this.title == "新增") {
        api.addNodeAffinityTemplate(data).then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.updateNodeAffinityTemplateShow = false;
            this.query(1);
          }
        });
      } else {
        api.editNodeAffinityTemplate(data).then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.updateNodeAffinityTemplateShow = false;
            this.query();
          }
        });
      }
    },
    addRequired() {
      this.required.push({
        required: true,
        itemList: [{ labelName: "", operator: "", labelValue: "" }],
      });
    },
    deleteRequired(index) {
      this.required.splice(index, 1);
    },
    addRequiredItem(index) {
      this.required[index].itemList.push({
        labelName: "",
        operator: "In",
        labelValue: "",
      });
    },
    deleteRequiredItem(index, itemIndex) {
      this.required[index].itemList.splice(itemIndex, 1);
    },
    addOptional() {
      this.optional.push({
        required: false,
        weight: "",
        itemList: [{ labelName: "", operator: "", labelValue: "" }],
      });
    },
    deleteOptional(index) {
      this.optional.splice(index, 1);
    },
    addOptionalItem(index) {
      this.optional[index].itemList.push({
        labelName: "",
        labelValue: "",
        operator: "In",
      });
    },
    deleteOptionalItem(index, itemIndex) {
      this.optional[index].itemList.splice(itemIndex, 1);
    },
    updateNodeAffinity(val) {
      let data = {
        id: val.id,
      };
      api.nodeAffinityList(data).then((res) => {
        if (res.result === 200) {
          this.ruleId = val.id;
          this.required = res.data.required;
          this.optional = res.data.optional;
          this.updateNodeAffinityShow = true;
        }
      });
    },
    updateNodeAffinitySubmit() {
      let data = {
        id: this.ruleId,
        required: this.required,
        optional: this.optional,
      };
      api.editNodeAffinity(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("修改成功");
          this.updateNodeAffinityShow = false;
        }
      });
    },
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.delNodeAffinity(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    batchDeleteClick() {
      let arr = [];
      this.selectedRowKeys.forEach((id) => {
        this.tableDataSource.forEach((item) => {
          if (item.id == id) {
            arr.push(item);
          }
        });
      });
      this.$axios
        .all(
          arr.map((item) => {
            let data = {
              id: item.id,
            };
            return api.delNodeAffinity(data);
          })
        )
        .then((resArr) => {
          this.$message.success("删除成功");
          this.query();
        });
    },
  },
};
</script>
<style scoped lang="scss">
.item {
  background-color: #f5f6fa;
  padding: 10px;
  margin-top: 10px;
  position: relative;
  .close {
    color: red;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .ant-table-wrapper {
    background-color: #fff !important;
  }
}
</style>
