import http from "./axios";

export const helmInfoPage = (data) => {
  return http.get("/helmInfo/helmInfoPage", data);
};

export const addHelmInfo = (data) => {
  return http.json_post("/helmInfo/addHelmInfo", data);
};

export const syncHelmList = (data) => {
  return http.json_post("/helmInfo/syncHelmList", data);
};

export const editHelmInfo = (data) => {
  return http.json_post("/helmInfo/editHelmInfo", data);
};

export const copy = (data) => {
  return http.json_post("/helmInfo/copy", data);
};

export const deleteHelmInfo = (data) => {
  return http.json_post("/helmInfo/deleteHelmInfo", data);
};

export const queryDir = (data) => {
  return http.get("/helmInfo/queryDir", data);
};

export const mkdir = (data) => {
  return http.json_post("/helmInfo/mkdir", data);
};

export const rmFile = (data) => {
  return http.json_post("/helmInfo/rmFile", data);
};

export const uploadFile = (data) => {
  return http.files("/helmInfo/uploadFile", data);
};

export const viewContent = (data) => {
  return http.get("/helmInfo/viewContent", data);
};

export const editContent = (data) => {
  return http.json_post("/helmInfo/editContent", data);
};

export const install = (data) => {
  return http.json_post("/helmInfo/install", data);
};

export const upgrade = (data) => {
  return http.json_post("/helmInfo/upgrade", data);
};

export const unInstall = (data) => {
  return http.json_post("/helmInfo/unInstall", data);
};

export const getLogs = (data) => {
  return http.get("/helmInfo/getLogs", data);
};

export const getInstallInfo = (data) => {
  return http.get("/helmInfo/getInstallInfo", data);
};

export const listHistoryRevision = (data) => {
  return http.get("/helmInfo/listHistoryRevision", data);
};

export const loadManifest = (data) => {
  return http.get("/helmInfo/loadManifest", data);
};
