import http from "./axios";

// 查询列表
export const workTaskPage = (data) => {
  return http.get("/workTask/workTaskPage", data);
};

export const projectMemberList = (data) => {
  return http.get("/workTask/projectMemberList", data);
};

export const userList = (data) => {
  return http.get("/delivery/user/list", data);
};

export const workTaskDetail = (data) => {
  return http.get("/workTask/workTaskDetail", data);
};

export const changeLogList = (data) => {
  return http.get("/workTask/changeLogList", data);
};

export const addWorkTask = (data) => {
  return http.json_post("/workTask/addWorkTask", data);
};

export const addWorkTaskImage = (data) => {
  return http.files("/workTask/addWorkTaskImage", data);
};

export const editWorkTask = (data) => {
  return http.json_post("/workTask/editWorkTask", data);
};

export const updateState = (data) => {
  return http.json_post("/workTask/updateState", data);
};

export const updateTitle = (data) => {
  return http.json_post("/workTask/updateTitle", data);
};

export const updateRemark = (data) => {
  return http.json_post("/workTask/updateRemark", data);
};

export const updatePriority = (data) => {
  return http.json_post("/workTask/updatePriority", data);
};

export const updateExecutor = (data) => {
  return http.json_post("/workTask/updateExecutor", data);
};

export const updateSchedule = (data) => {
  return http.json_post("/workTask/updateSchedule", data);
};

export const updateStartEndTime = (data) => {
  return http.json_post("/workTask/updateStartEndTime", data);
};

export const addWorkTaskComment = (data) => {
  return http.json_post("/workTask/addWorkTaskComment", data);
};

export const deleteWorkTask = (data) => {
  return http.json_post("/workTask/deleteWorkTask", data);
};

export const markFinished = (data) => {
  return http.json_post("/workTask/markFinished", data);
};

export const editType = (data) => {
  return http.json_post("/workTask/editType", data);
};

export const deleteWorkTaskComment = (data) => {
  return http.json_post("/workTask/deleteWorkTaskComment", data);
};

export const editWorkTaskComment = (data) => {
  return http.json_post("/workTask/editWorkTaskComment", data);
};

export const addProjectMember = (data) => {
  return http.json_post("/workTask/addProjectMember", data);
};

export const deleteProjectMember = (data) => {
  return http.json_post("/workTask/deleteProjectMember", data);
};

export const addWorkTaskFile = ({ file, taskId }) => {
  return http.files("/workTask/addWorkTaskFile", { file, taskId });
};

export const deleteWorkTaskFile = (data) => {
  return http.json_post("/workTask/deleteWorkTaskFile", data);
};

export const changeOrder = (data) => {
  return http.json_post("/workTask/changeOrder", data);
};

export const scheduleTaskNow = (data) => {
  return http.json_post("/workTask/scheduleTaskNow", data);
};
