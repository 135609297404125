<template>
  <div
    class="fileList"
    @click="fileDivClick"
    @contextmenu="contextmenuFileView($event)"
  >
    <div v-for="item in fileList" :key="item.id">
      <div
        class="fileList_item"
        @dblclick.stop="dbFileClick(item)"
        @click="fileClick(item)"
        @contextmenu="contextmenuFile($event, item)"
      >
        <div
          class="fileList_item_c"
          :class="{ activeImgClass: activeId === item.id }"
        >
          <img :src="item.img" alt="" />
        </div>
        <div
          class="fileList_item_t"
          :class="{ activeTitleClass: activeId === item.id }"
          :title="item.title"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "fileList",
  props: {
    fileList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      activeId: "",
      timeoutID: null,
    };
  },
  mounted() {},
  methods: {
    // 点击页面
    fileDivClick(e) {
      this.activeId = "";
      this.$emit("fileDivClick", e);
    },
    // 右击页面
    contextmenuFileView(e) {
      e.preventDefault();
      e.stopPropagation();
      this.activeId = "";
      this.$emit("contextmenuFileView", e);
    },
    // 右击文件
    contextmenuFile(e, item) {
      e.preventDefault();
      e.stopPropagation();
      this.activeId = item.id;
      this.$emit("contextmenuFile", e, item);
    },
    // 点击文件
    fileClick(item) {
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => {
        this.activeId = item.id;
        this.$emit("fileClick", item);
      }, 50);
    },
    // 双击文件
    dbFileClick(item) {
      clearTimeout(this.timeoutID);
      this.activeId = item.id;
      this.$emit("dbFileClick", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.fileList {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow: auto;
  .fileList_item {
    flex-shrink: 0;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px;
    .fileList_item_c {
      width: 60px;
      height: 50px;
      padding: 0 10px;
      img {
        width: 100%;
      }
    }
    .activeImgClass {
      background: #f0f2f5;
    }
    .fileList_item_t {
      width: 100%;
      font-family: "Helvetica Neue";
      border-radius: 4px;
      padding: 0 3px;
      text-align: center;
      word-wrap: break-word;
      word-break: normal;
      font-size: 12px;
      cursor: default;
    }
    .activeTitleClass {
      background: #1890ff;
      color: #fff;
    }
  }
}
</style>
