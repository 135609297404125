<template>
  <div class="domainAdmin">
    <div class="searchCondition">
      <div class="searchConditionItem">
        主账号：
        <a-select
          v-model="accountId"
          placeholder="请选择主账号"
          :filter-option="false"
          @change="selectChange"
        >
          <a-select-option v-for="item in accountList" :key="item.id">{{
            item.accountName
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="synDomainName">同步域名</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="domainStatus" slot-scope="text">
        <a-tag color="green" v-if="text == '3'">正常</a-tag>
        <a-tag color="orange" v-if="text == '2'">急需赎回</a-tag>
        <a-tag color="red" v-if="text == '1'">急需续费</a-tag>
      </span>
      <span slot="expirationDateStatus" slot-scope="text">
        <a-tag color="green" v-if="text == '1'">未过期</a-tag>
        <a-tag color="red" v-if="text == '2'">已过期</a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="goAnalysis(record)">解析列表</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.domainName }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
  </div>
</template>
<script>
import * as api from "../lib/domainAdmin.js";
export default {
  name: "domainAdmin",
  data() {
    return {
      accountId: undefined,
      accountList: [],
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "域名",
          ellipsis: true,
          dataIndex: "domainName",
        },
        {
          title: "域名类型",
          ellipsis: true,
          dataIndex: "domainType",
        },
        {
          title: "域名状态",
          ellipsis: true,
          dataIndex: "domainStatus",
          scopedSlots: { customRender: "domainStatus" },
        },
        {
          title: "域名过期状态",
          ellipsis: true,
          dataIndex: "expirationDateStatus",
          scopedSlots: { customRender: "expirationDateStatus" },
        },
        {
          title: "剩余天数",
          ellipsis: true,
          dataIndex: "expirationCurrDateDiff",
        },
        {
          title: "域名到期时间",
          ellipsis: true,
          dataIndex: "expirationDate",
        },
        {
          title: "域名注册时间",
          ellipsis: true,
          dataIndex: "registrationDate",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
    };
  },
  mounted() {
    this.getMainAccountList();
  },
  methods: {
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        accountId: this.accountId,
      };
      this.getDomainNameList(data);
    },
    getDomainNameList(data) {
      api.domainNameList(data).then((res) => {
        if (res.result === 200) {
          this.tableDataSource = res.data.records;
        }
      });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 查询阿里云主账号集合
    getMainAccountList() {
      api.mainAccountList().then((res) => {
        if (res.result === 200) {
          this.accountList = res.data;
          this.accountId = this.accountList[0].id;
          let accountId = localStorage.getItem("accountId");
          if (accountId) {
            this.accountId = accountId * 1;
          }
          this.query(1);
        }
      });
    },
    // 下拉框发生改变
    selectChange() {
      localStorage.setItem("accountId", this.accountId);
      this.query(1);
    },
    // 同步域名
    synDomainName() {
      this.loadingTable = true;
      api.synDomainName({ accountId: this.accountId }).then((res) => {
        if (res.result === 200) {
          this.$message.success("同步成功");
          this.loadingTable = false;
          this.getMainAccountList();
        }
      });
    },
    // 前往解析列表
    goAnalysis(val) {
      this.$router.push({
        path: "/domainAdmin/domainNameDNS",
        query: {
          domainId: val.id,
          domainName: val.domainName,
          accountId: this.accountId,
        },
      });
    },
  },
};
</script>
