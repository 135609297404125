import http from "./axios";

// 查询列表
const queryPage = ({ pageSize, pageNo, name, remark }) => {
  return http.get("/yapi/share/page", {
    pageSize,
    pageNo,
    name,
    remark,
  });
};

// 添加
const addData = ({ yapiProjectId, name, content, remark }) => {
  return http.json_post("/yapi/share/addShare", {
    yapiProjectId,
    name,
    content,
    remark,
  });
};

// 查询列表
const detailPage = ({ pageSize, pageNo, shareId }) => {
  return http.get("/yapi/share/detailPage", {
    pageSize,
    pageNo,
    shareId,
  });
};

// 添加
const deleteData = ({ id }) => {
  return http.json_post("/yapi/share/deleteShare", {
    id,
  });
};

const editShareApi = ({ id, interfaceIdList }) => {
  return http.json_post("/yapi/share/editShareInterface", {
    id,
    interfaceIdList,
  });
};

const editShare = ({ id, name, content }) => {
  return http.json_post("/yapi/share/editShare", {
    id,
    name,
    content,
  });
};

export default {
  queryPage,
  addData,
  deleteData,
  detailPage,
  editShareApi,
  editShare,
};
