<template>
  <div class="manualUpload">
    <div class="searchButton">
      <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      <a-button type="primary" @click="add" icon="plus">新增</a-button>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
    </a-table>
    <!-- 新增 -->
    <a-modal
      title="上传jar"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="上传文件:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-upload-dragger
            :fileList="fileList"
            :remove="handleRemove"
            :beforeUpload="beforeUpload"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
            <p class="ant-upload-hint">只支持单个上传</p>
          </a-upload-dragger>
        </a-form-item>
        <a-form-item
          label="groupId:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="groupIdAdd" placeholder="请输入groupId" />
        </a-form-item>
        <a-form-item
          label="artifactId:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="artifactIdAdd" placeholder="请输入artifactId" />
        </a-form-item>
        <a-form-item
          label="version:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="versionAdd" placeholder="请输入version" />
        </a-form-item>
        <a-form-item
          label="packaging:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="packagingAdd" placeholder="请输入packaging" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import api from "../lib/manualUpload.js";

export default {
  name: "manualUpload",
  data() {
    return {
      groupIdAdd: "",
      artifactIdAdd: "",
      versionAdd: "",
      packagingAdd: "",
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
        },
        {
          title: "groupId",
          ellipsis: true,
          dataIndex: "groupId",
        },
        {
          title: "artifactId",
          ellipsis: true,
          dataIndex: "artifactId",
        },
        {
          title: "version",
          ellipsis: true,
          dataIndex: "version",
        },
        {
          title: "packaging",
          ellipsis: true,
          dataIndex: "packaging",
        },
        {
          title: "操作人",
          ellipsis: true,
          dataIndex: "userName",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      fileList: [],
      key: "upload",
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    // 上传文件数据处理
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      this.fileList = this.fileList.slice(-1);
      return false;
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
      };
      this.queryPage(data);
    },
    // 查询列表
    queryPage(data) {
      this.loadingTable = true;
      api
        .queryPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 新增
    add() {
      this.addKeyVisible = true;
      this.groupIdAdd = "";
      this.artifactIdAdd = "";
      this.versionAdd = "";
      this.packagingAdd = "";
    },
    // 确定新增
    add_submit() {
      this.$message.loading({ content: "加载中...", key: this.key });
      let data = {
        file: this.fileList[0],
        groupId: this.groupIdAdd,
        artifactId: this.artifactIdAdd,
        version: this.versionAdd,
        packaging: this.packagingAdd,
      };
      api.uploadToRepository(data).then((res) => {
        if (res.result == 200) {
          this.addKeyVisible = false;
          this.$message.success({ content: "上传成功", key: this.key });
          this.query();
        }
      });
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.groupIdAdd = "";
      this.artifactIdAdd = "";
      this.versionAdd = "";
      this.packagingAdd = "";
    },
  },
};
</script>
