<template>
  <div class="zookeeperAddress">
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="name"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        地址：
        <a-input
          placeholder="请输入地址"
          v-model.trim="address"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        备注：
        <a-input
          placeholder="请输入备注"
          v-model.trim="remark"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="nodeList(record)">节点列表</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm title="确认删除？" @confirm="() => deleteClick(record)">
          <a href="javascript:;" style="color: red">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="nameAdd" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item
          label="地址:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="addressAdd" placeholder="请输入地址" />
        </a-form-item>
        <a-form-item
          label="备注:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="remarkAdd" placeholder="请输入备注" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import api from "../lib/zookeeperUIList.js";

export default {
  name: "zookeeperUIList",
  data() {
    return {
      title: "",
      name: "",
      nameAdd: "",
      address: "",
      addressAdd: "",
      remark: "",
      remarkAdd: "",
      groupId: "",
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "地址",
          ellipsis: true,
          dataIndex: "address",
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    nodeList(val) {
      let preUrl =
        process.env.VUE_APP_CURRENTMODE == "dev"
          ? "http://localhost:8080"
          : location.protocol + "//delivery.huiyu.org.cn";
      let url = preUrl + `/zookeeperUI?id=${val.id}`;
      window.open(url);
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
      };
      this.queryPage(data);
    },
    // 查询列表
    queryPage(data) {
      this.loadingTable = true;
      api
        .queryPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.addKeyVisible = true;
      this.groupId = val.id;
      this.nameAdd = val.name;
      this.addressAdd = val.address;
      this.remarkAdd = val.remark;
    },
    // 新增
    add() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.nameAdd = "";
      this.addressAdd = "";
      this.remarkAdd = "";
    },
    // 确定新增
    add_submit() {
      let data = {
        name: this.nameAdd,
        address: this.addressAdd,
        remark: this.remarkAdd,
      };
      if (this.title === "新增") {
        api.addData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.id = this.groupId;
        api.editData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.groupId = "";
      this.nameAdd = "";
      this.addressAdd = "";
      this.remarkAdd = "";
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteData(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.zookeeperAddress {
  background-color: #fff;
  padding: 20px;
}
</style>
