import http from "./axios";

// 查询vpn列表
export const vpnList = (data) => {
  return http.get("/delivery/vpnManager/page", data);
};

// 新增vpn列表
export const vpnAdd = (data) => {
  return http.json_post("/delivery/vpnManager/insert", data);
};

//更新配置
export const updateConfig = (data) => {
  return http.json_post("/delivery/vpnManager/updateConfig", data);
};

// 查询vpn用户列表
export const vpnUserList = (data) => {
  return http.get("/delivery/vpnManager/vpnUserPage", data);
};

// vpn用户新增
export const vpnUserAdd = (data) => {
  return http.json_post("/delivery/vpnManager/addVpnUser", data);
};

//vpn用户启用、禁用
export const editVpnUser = (data) => {
  return http.json_post("/delivery/vpnManager/editVpnUser", data);
};

// vpn用户删除
export const deleteVpnUser = (data) => {
  return http.json_post("/delivery/vpnManager/deleteVpnUser", data);
};

// 获取密码
export const getPassword = (data) => {
  return http.get("/delivery/vpnManager/getPassword", data);
};
