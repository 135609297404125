import http from "./axios";

// 登陆者当日发布数
export const getUserTodayDeliveryCount = () => {
  return http.get("/summaryInfo/getUserTodayDeliveryCount", {});
};

// 近七天发布数
export const getYearDeliveryCount = () => {
  return http.get("/summaryInfo/getYearDeliveryCount", {});
};

// 项目发布排序
export const getProjectDeliverySort = () => {
  return http.get("/summaryInfo/getProjectDeliverySort", {});
};

// 人员发布
export const getUserDeliverySort = () => {
  return http.get("/summaryInfo/getUserDeliverySort", {});
};

// 系统信息
export const getSystemInfo = () => {
  return http.get("/summaryInfo/getSystemInfo", {});
};

// 获取项目数、服务器数等信息
export const getOtherInfo = () => {
  return http.get("/summaryInfo/getOtherInfo", {});
};

// 获取入口信息
export const getQuickEntranceList = () => {
  return http.get("/summaryInfo/getQuickEntranceList", {});
};
