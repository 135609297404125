import http from "./axios";

export const k8sApplicationPage = (data) => {
  return http.get("/k8sApplication/k8sApplicationPage", data);
};

export const addK8sApplication = (data) => {
  return http.json_post("/k8sApplication/addK8sApplication", data);
};

export const editK8sApplication = (data) => {
  return http.json_post("/k8sApplication/editK8sApplication", data);
};

export const apply = (data) => {
  return http.json_post("/k8sApplication/apply", data);
};

export const deleteK8sApplication = (data) => {
  return http.json_post("/k8sApplication/delete", data);
};

export const getHistoryList = (data) => {
  return http.get("/k8sApplication/historyList", data);
};
