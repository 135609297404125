<template>
  <div>
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="预览">
        <api-preview v-if="active == '1'" :dataList="dataList"></api-preview>
      </a-tab-pane>
      <a-tab-pane key="2" tab="编辑" force-render v-if="!$route.query.uuid">
        <api-edit
          v-if="active == '2'"
          :dataList="dataList"
          @getData="getData"
        ></api-edit>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import ApiEdit from "./apiEdit.vue";
import apiPreview from "./apiPreview.vue";
import api from "../lib/yapi";
import apiOut from "../lib/yapiOut";
export default {
  components: { apiPreview, ApiEdit },
  name: "apiDetail",
  data() {
    return {
      active: "1",
      dataList: {},
    };
  },
  watch: {
    "$route.query": {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        this.getData(newVal.id);
        this.active = "1";
      },
    },
  },
  methods: {
    getData(id) {
      let uuid = this.$route.query.uuid;
      if (uuid) {
        apiOut.getApiList({ id, uuid }).then((res) => {
          if (res.result == 200) {
            this.dataList = res.data;
          }
        });
      } else {
        api.getApiList({ id }).then((res) => {
          if (res.result == 200) {
            this.dataList = res.data;
          }
        });
      }
    },
  },
};
</script>

<style>
</style>