import http from "./axios";

// 查询模板列表
export const codeTemplatePage = ({
  isSystemParameter,
  parameterKey,
  pageNo,
  pageSize,
}) => {
  return http.get("/generatorCode/codeTemplateParameterPage", {
    parameterKey: parameterKey,
    isSystemParameter: isSystemParameter,
    pageNo: pageNo,
    pageSize: pageSize,
  });
};

// 添加模板参数
export const addCodeTemplateParameter = ({ parameterKey, parameterValue }) => {
  return http.json_post("/generatorCode/addCodeTemplateParameter", {
    parameterKey: parameterKey,
    parameterValue: parameterValue,
  });
};

// 编辑模板
export const editCodeTemplateParameter = ({
  id,
  parameterKey,
  parameterValue,
}) => {
  return http.json_post("/generatorCode/editCodeTemplateParameter", {
    id: id,
    parameterKey: parameterKey,
    parameterValue: parameterValue,
  });
};

// 删除模板
export const deleteCodeTemplateParameter = ({ id }) => {
  return http.get("/generatorCode/deleteCodeTemplateParameter", {
    id: id,
  });
};

// 模板集合
export const codeTemplateList = ({ name }) => {
  return http.get("/generatorCode/codeTemplateList", {
    name: name,
  });
};
