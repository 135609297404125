<template>
  <div class="ingressClass">
    <div class="reminder"><span>ingressClass</span></div>
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="name"
          @pressEnter="query"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="sync" icon="sync">同步</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="deleted" slot-scope="text, record">
        <a-tag color="red" v-if="text">已删除</a-tag>
        <a-tag color="green" v-else>未删除</a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="updateClick(record)">修改</a>
      </span>
    </a-table>

    <a-modal
      title="修改"
      v-model="updateShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="dnsType:">
          <a-select v-model="updateData.dnsType">
            <a-select-option
              v-for="(item, index) in dnsTypeList"
              :key="index"
              :value="item"
              >{{ item }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="dns:">
          <a-input v-model="updateData.dns" placeholder="请输入dns" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="updateShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="updateSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/k8s.js";

export default {
  name: "ingressClass",
  data() {
    return {
      name: "",
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          width: 100,
        },
        {
          title: "名称",
          dataIndex: "name",
          width: 150,
        },
        {
          title: "controller",
          dataIndex: "controller",
          width: 200,
        },
        {
          title: "parameters",
          dataIndex: "parameters",
          width: 200,
        },
        {
          title: "dnsType",
          dataIndex: "dnsType",
          width: 80,
        },
        {
          title: "dns",
          dataIndex: "dns",
          width: 200,
        },
        {
          title: "是否删除",
          dataIndex: "deleted",
          scopedSlots: { customRender: "deleted" },
          width: 100,
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          width: 150,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 80,
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      pageNo: 1,
      loadingTable: false,
      updateData: {},
      updateShow: false,
      dnsTypeList: ["DNS", "IP"],
    };
  },
  created() {
    this.query();
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        name: this.name,
      };
      this.loadingTable = true;
      api
        .k8sIngressClassPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource = res.data.records;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: 10,
              current: this.pageNo,
              total: res.data.total,
              onChange: (current) => this.query(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    sync() {
      api.syncIngressClass().then((res) => {
        if (res.result === 200) {
          this.$message.success("同步成功");
          this.query(1);
        }
      });
    },
    updateClick(val) {
      this.updateData = {
        id: val.id,
        name: val.name,
        dnsType: val.dnsType,
        dns: val.dns,
      };
      this.updateShow = true;
    },
    updateSubmit() {
      let data = { ...this.updateData };

      api.editK8sIngressClass(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("修改成功");
          this.updateShow = false;
          this.query();
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.item {
  background-color: #f5f6fa;
  padding: 10px;
  margin-top: 10px;
  position: relative;
  .close {
    color: red;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .ant-table-wrapper {
    background-color: #fff !important;
  }
}
</style>
