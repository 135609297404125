import http from "./axios";

// 查询列表
const queryPage = ({ nginxGatewayId, domain }) => {
  return http.get("/nginx/nginxRuleList", {
    nginxGatewayId,
    domain,
  });
};

const updateRuleEnableStatus = ({ enabled, id }) => {
  return http.json_post("/nginx/updateRuleEnableStatus", {
    enabled,
    id,
  });
};

const addGlobalConfTemplate = ({ fileName, content, nginxInstanceId }) => {
  return http.json_post("/nginxConf/addGlobalConfTemplate", {
    fileName,
    content,
    nginxInstanceId,
  });
};

// 查询文件列表
const confFileList = ({
  nginxInstanceId,
  pageNo,
  pageSize,
  fileName,
  currentVersion,
  globalConf,
}) => {
  return http.get("/nginx/confFileList", {
    nginxInstanceId,
    pageNo,
    pageSize,
    fileName,
    currentVersion,
    globalConf,
  });
};

const listModifiedConf = ({ nginxGatewayId }) => {
  return http.get("/nginx/listModifiedConf", {
    nginxGatewayId,
  });
};

// 查看文件
const confFileContent = ({ confId }) => {
  return http.get("/nginx/confFileContent", {
    confId,
  });
};

const generateName = ({ name }) => {
  return http.get("/nginx/generateName", {
    name,
  });
};

// 查询列表
const nginxAttributeList = () => {
  return http.get("/nginx/nginxAttributeList");
};

const saveData = ({
  name,
  nginxGatewayId,
  index,
  domainList,
  path,
  actionType,
  actionContent,
  id,
  propertyList,
  remark,
  includeGeneralConf,
}) => {
  return http.json_post("/nginx/saveRules", {
    name,
    nginxGatewayId,
    index,
    domainList,
    path,
    actionType,
    actionContent,
    id,
    propertyList,
    remark,
    includeGeneralConf,
  });
};

// 删除
const deleteData = ({ id }) => {
  return http.json_post("/nginx/deleteOneRule", {
    id,
  });
};

// 批量删除
const batchDeleteNginxGatewayMonitor = ({ idList }) => {
  return http.json_post("/nginx/batchDeleteNginxGatewayMonitor", {
    idList,
  });
};

// 保存优先级
const changeRuleIndex = ({ indexList }) => {
  return http.json_post("/nginx/changeRuleIndex", {
    indexList,
  });
};

const copyGlobalConf = ({
  nginxGatewayId,
  templateIdList,
  nginxInstanceId,
}) => {
  return http.json_post("/nginxConf/copyGlobalConf", {
    nginxGatewayId,
    templateIdList,
    nginxInstanceId,
  });
};

// 生效
const activeRules = ({ nginxGatewayId }) => {
  return http.json_post("/nginx/activeRules", {
    nginxGatewayId,
  });
};

const previewConf = ({
  name,
  nginxGatewayId,
  index,
  domainList,
  path,
  actionType,
  actionContent,
  id,
  propertyList,
  enabled,
  includeGeneralConf,
}) => {
  return http.json_post("/nginx/previewConf", {
    name,
    nginxGatewayId,
    index,
    domainList,
    path,
    actionType,
    actionContent,
    id,
    propertyList,
    enabled,
    includeGeneralConf,
  });
};

const precheck = ({ itemList, nginxGatewayId }) => {
  return http.json_post("/nginx/precheck", {
    itemList,
    nginxGatewayId,
  });
};

const rollbackHistoryConf = ({ id }) => {
  return http.json_post("/nginx/rollbackHistoryConf", {
    id,
  });
};

const globalConfList = () => {
  return http.get("/nginxConf/globalConfList");
};

const reloadGlobalConf = ({ nginxGatewayId, confId, nginxInstanceId }) => {
  return http.json_post("/nginxConf/reloadGlobalConf", {
    nginxGatewayId,
    confId,
    nginxInstanceId,
  });
};

const deleteGlobalConfTemplate = ({ id }) => {
  return http.json_post("/nginxConf/deleteGlobalConfTemplate", {
    id,
  });
};
export default {
  deleteData,
  queryPage,
  saveData,
  batchDeleteNginxGatewayMonitor,
  changeRuleIndex,
  activeRules,
  nginxAttributeList,
  previewConf,
  confFileList,
  confFileContent,
  precheck,
  listModifiedConf,
  rollbackHistoryConf,
  copyGlobalConf,
  globalConfList,
  reloadGlobalConf,
  addGlobalConfTemplate,
  deleteGlobalConfTemplate,
  generateName,
  updateRuleEnableStatus,
};
