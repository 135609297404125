<template>
  <div class="securityGroupItem">
    <a-breadcrumb>
      <a-breadcrumb-item>{{ securityGroupName }}</a-breadcrumb-item>
      <a-breadcrumb-item>{{ securityGroupId }}</a-breadcrumb-item>
    </a-breadcrumb>
    <a-tabs v-model="active" @change="callback">
      <a-tab-pane key="ingress" tab="入方向">
        <securityItem :direction="active" v-if="ingressShow"></securityItem>
      </a-tab-pane>
      <a-tab-pane key="egress" tab="出方向">
        <securityItem :direction="active" v-if="egressShow"></securityItem>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import securityItem from "./securityItem.vue";
export default {
  name: "securityGroupItem",
  components: {
    securityItem,
  },
  data() {
    return {
      active: "",
      ingressShow: false,
      egressShow: false,
      securityGroupId: "",
      securityGroupName: "",
    };
  },
  mounted() {
    this.securityGroupName = this.$route.query.securityGroupName;
    this.securityGroupId = this.$route.query.securityGroupId;
    this.callback("ingress");
  },
  methods: {
    // 切换tab
    callback(key) {
      this.active = key;
      if (key === "ingress") {
        this.ingressShow = true;
        this.egressShow = false;
      }
      if (key === "egress") {
        this.ingressShow = false;
        this.egressShow = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
