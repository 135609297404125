<template>
  <div class="aliyunAccountManagement">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="账号管理">
        <router-view name="three" v-if="active == '1'" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="权限类型">
        <router-view name="three" v-if="active == '2'" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "aliyunAccountManagement",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/aliyunAccountManagement/mainAccountPage") {
          this.active = "1";
        } else if (newVal == "/aliyunAccountManagement/policyPage") {
          this.active = "2";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/aliyunAccountManagement/mainAccountPage")
            return;
          this.$router.replace("/aliyunAccountManagement/mainAccountPage");
        }
        if (newVal === "2") {
          if (this.$route.path == "/aliyunAccountManagement/policyPage") return;
          this.$router.replace("/aliyunAccountManagement/policyPage");
        }
      },
    },
  },
};
</script>
