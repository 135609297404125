import http from "./axios";

// 查询列表
const queryPage = ({ groupId, pageNo, pageSize }) => {
  return http.get("/nginxDeployServer/nginxDeployServerPage", {
    groupId,
    pageNo,
    pageSize,
  });
};

// 添加
const addData = ({ groupId, nginxInstanceIdList }) => {
  return http.json_post("/nginxDeployServer/addNginxDeployServer", {
    groupId,
    nginxInstanceIdList,
  });
};

// 删除
const deleteData = ({ id }) => {
  return http.json_post("/nginxDeployServer/deleteNginxDeployServer", {
    id,
  });
};

export default {
  deleteData,
  queryPage,
  addData,
};
