import http from "./axios";

// 查询分页列表
export const logPage = ({ ansibleName, pageNo, pageSize, status }) => {
  return http.get("/ansible/logPage", {
    ansibleName: ansibleName,
    pageNo: pageNo,
    pageSize: pageSize,
    status: status,
  });
};

// 删除
export const deleteAnsibleTask = ({ id }) => {
  return http.json_post("/ansible/deleteAnsibleTask", {
    id: id,
  });
};

// 状态集合
export const statusList = () => {
  return http.get("/ansible/statusList");
};

// 立即执行
export const startAnsibleTask = ({ id }) => {
  return http.json_post("/ansible/startAnsibleTask", {
    id: id,
  });
};

// 详情
export const taskInfo = ({ id }) => {
  return http.get("/ansible/taskInfo", {
    id: id,
  });
};
