<template>
  <div class="helmList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="name"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        命名空间：
        <a-select
          v-model="namespace"
          placeholder="请选择命名空间"
          @change="query(1)"
          showSearch
        >
          <a-select-option
            v-for="(item, index) in namespaceList"
            :key="index"
            :value="item"
          >
            {{ item }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
        <a-button type="primary" @click="sync" icon="sync">同步</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="status" slot-scope="text, record">
        <a-tag v-if="record.status == 'deployed'" color="green"> 运行中 </a-tag>
        <a-tag v-else color="red"> 未运行 </a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="detail(record)">详情</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="edit(record)"> 修改 </a>
        <a-divider type="vertical" />
        <a-dropdown>
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            其它 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a href="javascript:;" @click="getInstallInfo(record)">
                安装说明
              </a>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="historyRevision(record)">
                历史版本
              </a>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="copy(record)">复制</a>
            </a-menu-item>
            <a-menu-item>
              <a-popconfirm
                placement="right"
                okText="确认"
                cancelText="取消"
                @confirm="deleteClick(record)"
              >
                <template slot="title">确认是否删除{{ record.name }}</template>
                <a href="javascript:;" style="color: #ff4d4f">删除</a>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>

    <a-modal
      :title="title"
      v-model="addShow"
      :maskClosable="false"
      width="600px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }">
        <a-form-item label="名称:">
          <a-input v-model="addData.name" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item label="备注:">
          <a-textarea
            :rows="15"
            v-model="addData.remark"
            placeholder="请输入备注"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="addSubmit()"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="detailTitle"
      v-if="detailShow"
      v-model="detailShow"
      :maskClosable="false"
      :footer="false"
      wrapClassName="detailClass"
      width="900px"
    >
      <a-layout class="detailLayout">
        <a-dropdown v-model="rightMenuShow" :trigger="['contextmenu']">
          <template slot="overlay">
            <a-menu>
              <a-menu-item @click="createDir" :disabled="disabled">
                新建文件夹
              </a-menu-item>
              <a-menu-item @click="uploadFile" :disabled="disabled">
                上传文件
              </a-menu-item>
              <a-menu-item
                @click="deleteFile"
                :disabled="path == rootDirectory + helmInfoId"
              >
                <a
                  href="javascript:;"
                  style="color: #ff4d4f"
                  :disabled="path == rootDirectory + helmInfoId"
                  >删除</a
                >
              </a-menu-item>
            </a-menu>
          </template>
          <a-layout-sider
            class="detailLayoutSlider"
            width="220"
            theme="light"
            style="flex: 0 0 auto; max-width: none; min-width: inherit"
            @contextmenu="rightClick"
          >
            <a-tree
              :treeData="dirList"
              show-line
              :loadData="loadData"
              @select="treeSelect"
              @rightClick="treeRightClick"
              :expandedKeys.sync="expandedKeys"
              :selectedKeys.sync="selectedKeys"
            >
            </a-tree>
          </a-layout-sider>
        </a-dropdown>
        <a-layout class="detailLayoutContent">
          <div id="tz" @mousedown="dragEagle"></div>
          <div class="fileContent">
            <codemirror
              v-if="isFile"
              v-model="fileContent"
              :options="options"
            ></codemirror>
          </div>
          <div class="detailLayoutContentFooter">
            <template v-if="fileContent !== watchFileContent">
              <a-button type="info" @click="fileContent = watchFileContent">
                取消
              </a-button>
              <a-popconfirm
                placement="top"
                okText="确认"
                cancelText="取消"
                @confirm="editFileContent"
              >
                <template slot="title">确认是否修改?</template>
                <a-button type="primary">保存修改</a-button>
              </a-popconfirm>
            </template>
            <template v-else>
              <template v-if="status == 'deployed'">
                <a-popconfirm
                  placement="top"
                  okText="确认"
                  cancelText="取消"
                  @confirm="upgrade"
                >
                  <template slot="title">确认是否升级?</template>
                  <a-button type="primary">升级</a-button>
                </a-popconfirm>
                <a-popconfirm
                  placement="top"
                  okText="确认"
                  cancelText="取消"
                  @confirm="unInstall"
                >
                  <template slot="title">确认是否卸载?</template>
                  <a-button type="danger">卸载</a-button>
                </a-popconfirm>
              </template>
              <a-popconfirm
                placement="top"
                okText="确认"
                cancelText="取消"
                @confirm="install"
                v-else
              >
                <template slot="title">确认是否安装?</template>
                <a-button type="primary">安装</a-button>
              </a-popconfirm>
            </template>
          </div>
        </a-layout>
      </a-layout>
    </a-modal>

    <a-modal
      title="创建文件夹"
      v-if="createDirShow"
      v-model="createDirShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="文件夹名称:">
          <a-input v-model="dirName" placeholder="请输入名称" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="createDirShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="createDirSubmit()"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="上传文件"
      v-if="uploadFileShow"
      v-model="uploadFileShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="文件:">
          <a-upload-dragger
            ref="upload"
            :beforeUpload="beforeUpload"
            :fileList="fileList"
            :showUploadList="false"
            directory
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
          </a-upload-dragger>
          <h4 v-if="fileList.length > 0">已上传{{ fileList.length }}个文件</h4>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="uploadFileShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :disabled="fileList.length == 0"
            @click="uploadFileSubmit()"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      v-if="logVisible"
      v-model="logVisible"
      :maskClosable="false"
      :afterClose="logClose"
      width="1400px"
      :footer="false"
    >
      <template slot="title">
        日志
        <div style="display: inline-block">
          <a-tooltip placement="bottom" v-if="logStatus == 'RUNNING'">
            <div slot="title">构建中</div>
            <a-icon type="loading" style="margin-left: 10px" />
          </a-tooltip>
          <a-tooltip placement="bottom" v-if="logStatus == 'SUCCESS'">
            <div slot="title">构建成功</div>
            <div class="rage" style="background-color: #52c41a"></div>
          </a-tooltip>
          <a-tooltip placement="bottom" v-if="logStatus == 'ERROR'">
            <div slot="title">构建失败</div>
            <div class="rage" style="background-color: #f5222d"></div>
          </a-tooltip>
        </div>
      </template>
      <div
        id="terminal"
        style="height: 700px; padding: 10px; background: #008080"
      ></div>
      <h2
        v-if="logStatus !== 'RUNNING'"
        style="text-align: center; margin-top: 10px; margin-bottom: 0"
      >
        {{ time }}秒后关闭
      </h2>
    </a-modal>

    <a-modal
      title="安装说明信息"
      v-model="installInfoShow"
      :maskClosable="false"
      width="1000px"
      :footer="false"
    >
      <div style="height: 700px; overflow-y: scroll" v-html="installInfo" />
    </a-modal>

    <a-modal
      title="历史版本"
      v-model="historyRevisionShow"
      :maskClosable="false"
      width="800px"
      :footer="false"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(historyRevisionColumns)"
        :columns="historyRevisionColumns"
        :dataSource="historyRevisionList"
        :pagination="false"
        size="small"
        :rowKey="(record) => record.id"
      >
        <span slot="action" slot-scope="text, record">
          <a href="javascript:;" @click="loadManifest(record)">查看Manifest</a>
        </span>
      </a-table>
    </a-modal>

    <a-modal
      title="manifest信息"
      v-model="manifestTextShow"
      :maskClosable="false"
      width="600px"
      :footer="false"
    >
      <div style="height: 600px; overflow-y: scroll" v-html="manifestText" />
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/helmList.js";
import "../../node_modules/xterm/css/xterm.css";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import "codemirror/mode/yaml/yaml.js";

export default {
  props: ["namespaceList"],
  name: "helmList",
  data() {
    return {
      name: "",
      namespace: "全部命名空间",
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          ellipsis: true,
          width: 80,
        },
        {
          title: "名称",
          dataIndex: "name",
          ellipsis: true,
        },
        {
          title: "命名空间",
          dataIndex: "namespace",
          ellipsis: true,
        },
        {
          title: "状态",
          dataIndex: "status",
          ellipsis: true,
          scopedSlots: { customRender: "status" },
        },
        {
          title: "版本",
          dataIndex: "chart",
          ellipsis: true,
        },
        {
          title: "appVersion",
          dataIndex: "appVersion",
          ellipsis: true,
        },
        {
          title: "备注",
          dataIndex: "remark",
          ellipsis: true,
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          ellipsis: true,
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 180,
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      helmInfoId: "",
      dir: "",
      title: "",
      addData: {},
      addShow: false,
      rootDirectory: "/opt/delivery/beta-delivery-web/helm/",
      dirList: [],
      expandedKeys: [],
      selectedKeys: [],
      detailTitle: "",
      detailShow: false,
      fileContent: "",
      watchFileContent: "",
      options: {
        mode: "text/javascript",
        theme: "default",
        readOnly: false,
        lineNumbers: true,
        line: true,
        styleActiveLine: true, //设置光标所在行高亮，需引入工具包
        placeholder: "输入内容",
      },
      isFile: false,
      rightMenuShow: false,
      dirName: "",
      createDirShow: false,
      path: "",
      fileList: [],
      uploadFileShow: false,
      logVisible: false,
      getLogTimer: null,
      disabled: false,
      logStatus: "RUNNING",
      syncLoading: null,
      status: "deployed",
      installInfoShow: false,
      installInfo: "",
      historyRevisionList: [],
      historyRevisionColumns: [
        {
          title: "revision",
          dataIndex: "revision",
        },
        {
          title: "chart",
          dataIndex: "chart",
        },
        {
          title: "appVersion",
          dataIndex: "appVersion",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 100,
        },
      ],
      historyRevisionShow: false,
      manifestText: "",
      manifestTextShow: false,
      time: 3,
    };
  },
  created() {
    let namespace = localStorage.getItem("helmNamespace");
    if (namespace) {
      this.namespace = namespace;
    }
    this.query();
    document.body.addEventListener("click", () => {
      this.rightMenuShow = false;
    });
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        name: this.name,
        namespace: this.namespace,
      };
      if (data.namespace == "全部命名空间") {
        delete data.namespace;
      } else {
        localStorage.setItem("helmNamespace", data.namespace);
      }
      this.loadingTable = true;
      api
        .helmInfoPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource = res.data.records;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.query(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    add() {
      this.title = "新增";
      this.addData = {
        name: "",
        remark: "",
      };
      this.addShow = true;
    },
    edit(val) {
      this.title = "修改";
      this.helmInfoId = val.id;
      this.addData = {
        name: val.name,
        remark: val.remark,
      };
      this.addShow = true;
    },
    copy(val) {
      this.title = "复制";
      this.helmInfoId = val.id;
      this.addData = {
        name: val.name,
        remark: val.remark,
      };
      this.addShow = true;
    },
    addSubmit() {
      let data = {
        ...this.addData,
      };
      if (this.title == "新增") {
        api.addHelmInfo(data).then((res) => {
          if (res.result === 200) {
            this.$message.success("添加成功");
            this.addShow = false;
            this.query(1);
          }
        });
      } else {
        data.id = this.helmInfoId;
        if (this.title == "修改") {
          api.editHelmInfo(data).then((res) => {
            if (res.result === 200) {
              this.$message.success("修改成功");
              this.addShow = false;
              this.query();
            }
          });
        } else {
          this.syncLoading = this.$syncLoading({ message: "复制中..." });
          api
            .copy(data)
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("复制成功");
                this.syncLoading.close();
                this.addShow = false;
                this.query();
              }
            })
            .catch((err) => {
              this.syncLoading.close();
            });
        }
      }
    },
    sync() {
      this.syncLoading = this.$syncLoading({ message: "同步中..." });
      api
        .syncHelmList()
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("同步成功");
            this.syncLoading.close();
            this.query();
          }
        })
        .catch((err) => {
          this.syncLoading.close();
        });
    },
    getInstallInfo(val) {
      this.installInfoShow = true;
      if (val.installInfo) {
        this.installInfo = val.installInfo.replace(/\n/g, "<br/>");
      } else {
        this.installInfo = "无";
      }
    },
    historyRevision(val) {
      this.helmInfoId = val.id;
      let data = {
        helmInfoId: this.helmInfoId,
      };
      api.listHistoryRevision(data).then((res) => {
        if (res.result === 200) {
          this.historyRevisionList = res.data;
          this.historyRevisionShow = true;
        }
      });
    },
    loadManifest(val) {
      let data = {
        helmInfoId: this.helmInfoId,
        revision: val.revision,
      };
      api.loadManifest(data).then((res) => {
        if (res.result === 200) {
          this.manifestText = res.data.replace(/\n/g, "<br/>");
          this.manifestTextShow = true;
        }
      });
    },
    detail(val) {
      this.helmInfoId = val.id;
      this.dir = this.rootDirectory + this.helmInfoId;
      this.fileContent = "";
      this.watchFileContent = "";
      this.detailTitle = val.name;
      this.status = val.status;
      this.expandedKeys.splice(0);
      this.$set(this.options, "mode", "text/javascript");
      this.loadData();
    },
    async loadData(treeNode) {
      let data = {
        dir: "",
      };
      if (treeNode) {
        if (treeNode.dataRef.children) {
          return;
        }
        data.dir = treeNode.dataRef.key;
      } else {
        data.dir = this.dir;
      }

      api.queryDir(data).then((res) => {
        if (res.result === 200) {
          let arr = res.data.map((item) => ({
            title: item.name.split("/")[item.name.split("/").length - 1],
            key: item.name,
            isDir: item.directory,
            isLeaf: !item.directory,
          }));
          if (treeNode) {
            // 展开文件夹动态加载
            treeNode.dataRef.children = arr;
            this.dirList = [...this.dirList];
          } else if (this.dir == this.rootDirectory + this.helmInfoId) {
            // 查询根目录
            this.dirList = arr;
            this.detailShow = true;
            let hasValuesYaml = this.dirList.some((item) => {
              return item.title == "values.yaml";
            });
            if (hasValuesYaml) {
              setTimeout(() => {
                this.isFile = true;
                this.path =
                  this.rootDirectory + this.helmInfoId + "/values.yaml";
                this.selectedKeys = [this.path];
                this.getFileContent();
              }, 200);
            }
          } else {
            // 手动操作后重新查询
            const parentNode = this.findNode(this.dirList, data.dir);
            this.$set(parentNode, "children", arr);
            this.dirList = [...this.dirList];
            if (!this.expandedKeys.includes(data.dir)) {
              this.expandedKeys.push(data.dir);
            }
            this.expandedKeys = [...this.expandedKeys];
          }
        }
      });
    },
    // 递归查询节点
    findNode(treeData, key) {
      for (const node of treeData) {
        if (node.key === key) {
          return node;
        }
        if (node.children) {
          const found = this.findNode(node.children, key);
          if (found) {
            return found;
          }
        }
      }
      return null;
    },
    treeSelect(keys, event) {
      let treeNode = event.node.dataRef;
      this.isFile = !treeNode.isDir;
      let hasTarGzOrJar =
        treeNode.title.endsWith(".tar.gz") || treeNode.title.endsWith(".jar");
      if (treeNode.title.endsWith(".yaml")) {
        this.$set(this.options, "mode", "text/x-yaml");
      } else {
        this.$set(this.options, "mode", "text/javascript");
      }
      if (hasTarGzOrJar) {
        this.isFile = false;
        this.fileContent = "";
        this.watchFileContent = "";
      }
      if (this.isFile && !hasTarGzOrJar) {
        this.path = treeNode.key;
        this.getFileContent();
      }
    },
    getFileContent() {
      let data = {
        path: this.path,
      };

      api.viewContent(data).then((res) => {
        if (res.result === 200) {
          this.fileContent = res.data;
          this.watchFileContent = res.data;
        }
      });
    },
    dragEagle(e) {
      var targetDiv = document.getElementsByClassName("detailLayoutSlider")[0];

      //得到点击时该容器的宽高：
      var targetDivWidth = targetDiv.offsetWidth;
      var startX = e.clientX;

      document.onmousemove = (e) => {
        e.preventDefault();
        //得到鼠标拖动的宽高距离：取绝对值
        var distX = Math.abs(e.clientX - startX);
        //往右方拖动：
        if (e.clientX > startX) {
          targetDiv.style.width = targetDivWidth + distX + "px";
        }
        //往左方拖动：
        if (e.clientX < startX) {
          targetDiv.style.width = targetDivWidth - distX + "px";
        }
        //设置最大最小范围：不能无限制缩放，影响体验
        if (parseInt(targetDiv.style.width) <= 60) {
          targetDiv.style.width = 60 + "px";
        }
      };
      document.onmouseup = () => {
        document.onmousemove = null;
      };
    },
    editFileContent() {
      let data = {
        path: this.path,
        content: this.fileContent,
      };
      api.editContent(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("修改成功");
          this.watchFileContent = this.fileContent;
        }
      });
    },
    rightClick(event) {
      // 只在空白区域的右键点击时触发
      if (!event.target.closest(".ant-tree")) {
        this.disabled = false;
        this.path = this.rootDirectory + this.helmInfoId;
      }
    },
    treeRightClick({ node }) {
      this.path = node.dataRef.key;
      this.disabled = !node.dataRef.isDir;
    },
    createDir() {
      this.dirName = "";
      this.createDirShow = true;
    },
    createDirSubmit() {
      let data = {
        path: this.path + "/" + this.dirName,
      };
      api.mkdir(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("创建成功");
          this.createDirShow = false;
          this.dir = this.path;
          this.loadData();
        }
      });
    },
    uploadFile() {
      this.fileList.splice(0);
      this.uploadFileShow = true;
    },
    beforeUpload(file, fileList) {
      this.fileList = [...this.fileList, file];
      return false;
    },
    uploadFileSubmit() {
      this.$axios
        .all(
          this.fileList.map((file) => {
            let data = {
              file,
              path: this.path + "/" + file.webkitRelativePath,
            };
            return api.uploadFile(data);
          })
        )
        .then((resArr) => {
          this.fileList.splice(0);
          this.$message.success("上传成功");
          this.uploadFileShow = false;
          this.dir = this.path;
          this.loadData();
        });
    },
    deleteFile() {
      this.$confirm({
        title: "确认",
        content: (h) => (
          <div style="color:red;">
            删除会立即删除源文件且不可撤销, 是否继续?
          </div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          let data = {
            path: this.path,
          };

          api.rmFile(data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.createDirShow = false;
              this.dir = this.path.slice(0, this.path.lastIndexOf("/"));
              this.loadData();
            }
          });
        },
        onCancel: () => {},
      });
    },
    install() {
      let data = {
        id: this.helmInfoId,
      };
      api.install(data).then((res) => {
        if (res.result === 200) {
          this.logVisible = true;
          this.detailShow = false;
          this.$nextTick(() => {
            this.logInit();
          });
        }
      });
    },
    upgrade() {
      let data = {
        id: this.helmInfoId,
      };
      api.upgrade(data).then((res) => {
        if (res.result === 200) {
          this.logVisible = true;
          this.detailShow = false;
          this.$nextTick(() => {
            this.logInit();
          });
        }
      });
    },
    unInstall() {
      let data = {
        id: this.helmInfoId,
      };
      api.unInstall(data).then((res) => {
        if (res.result === 200) {
          this.logVisible = true;
          this.detailShow = false;
          this.$nextTick(() => {
            this.logInit();
          });
        }
      });
    },
    logInit() {
      this.term = new Terminal({
        theme: {
          background: "#008080", //背景色
          black: "#003440",
          blue: "#268ad2",
          brightBlack: "#00779a",
          brightBlue: "#109fd2",
          BrightCyan: "#00bdae",
          cyan: "#2aa197",
          brightGreen: "#5bee96",
          green: "#7cc67f",
          brightMagenta: "#e9679f",
          megenta: "#d33582",
          brightRed: "#f9314b",
          red: "#dc312e",
          brightWhite: "#fdf6e3",
          white: "#eee8d5",
          brightYellow: "#c08f34",
          yellow: "#b58900",
          foreground: "#f0f0f0", //字体
          selection: "#2c9aff66",
          cursor: "#f86100",
          cursorAccent: "#003440",
        },
      });
      const fitAddon = new FitAddon();
      this.term.loadAddon(fitAddon);
      this.term.open(document.getElementById("terminal"));
      fitAddon.fit();
      this.term.write("\x1b[2K\r");
      this.term.clear();
      this.logStatus = "RUNNING";
      this.getLogs();
    },
    logClose() {
      this.term.dispose();
      if (this.getLogTimer) {
        clearInterval(this.getLogTimer);
        this.getLogTimer = null;
      }
    },
    autoRefresh() {
      if (this.getLogTimer) {
        clearInterval(this.getLogTimer);
        this.getLogTimer = null;
      }
      this.getLogTimer = setInterval(() => {
        this.getLogs();
      }, 1000);
    },
    getLogs() {
      let data = {
        id: this.helmInfoId,
      };
      api.getLogs(data).then((res) => {
        if (res.result === 200) {
          if (res.data.logs.length > 0) {
            res.data.logs.forEach((item) => {
              this.term.write(item + "\r\n");
            });
          }
          if (res.data.success == null) {
            if (!this.getLogTimer) {
              this.autoRefresh();
            }
          } else {
            if (res.data.success) {
              this.logStatus = "SUCCESS";
            } else {
              this.logStatus = "ERROR";
            }
            this.time = 3;
            setInterval(() => {
              this.time -= 1;
              if (this.time == 0) {
                this.logVisible = false;
                this.query();
              }
            }, 1000);
            if (this.getLogTimer) {
              clearInterval(this.getLogTimer);
              this.getLogTimer = null;
            }
          }
        }
      });
    },
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteHelmInfo(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
  },
};
</script>
