<template>
  <div class="probe">
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="name"
          @pressEnter="query"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <span slot="enabled" slot-scope="text, record">
        <a-tag v-if="text" color="green"> 已启用 </a-tag>
        <a-tag v-if="!text" color="red"> 已禁用 </a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="edit(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <a-popconfirm
      placement="right"
      okText="确认"
      cancelText="取消"
      @confirm="batchDeleteClick()"
      :disabled="selectedRowKeys.length == 0"
    >
      <template slot="title">确认是否批量删除</template>
      <a-button
        type="danger"
        icon="delete"
        :disabled="selectedRowKeys.length == 0"
        >批量删除</a-button
      >
    </a-popconfirm>

    <a-modal
      :title="title"
      v-model="updateShow"
      :maskClosable="false"
      width="700px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="名称:">
          <a-input v-model="updateData.name" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item label="启用状态:">
          <a-switch
            v-model="updateData.enabled"
            checkedChildren="启用"
            unCheckedChildren="禁用"
          />
        </a-form-item>
        <template v-for="item in probeFormList">
          <a-form-item>
            <span slot="label">
              {{ item.label }}
              <a-tooltip>
                <template slot="title">
                  {{
                    item.type == "liveness"
                      ? "用来检查容器是否正常，不正常则重启容器"
                      : item.type == "readiness"
                      ? "用来确定容器是否已经就绪可以接受流量"
                      : "容器内应用是否已启动"
                  }}
                </template>
                <a-icon type="question-circle" />
              </a-tooltip>
            </span>
            <a-checkbox
              v-model="updateData[item.type + 'ProbeEnabled']"
              @change="
                probeEnabledChange(
                  updateData[item.type + 'ProbeEnabled'],
                  item.type,
                  localData
                )
              "
            ></a-checkbox>
          </a-form-item>
          <div class="probeForm">
            <a-form
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 14 }"
              v-if="updateData[item.type + 'ProbeEnabled']"
            >
              <a-tabs
                v-model="updateData[item.type + 'Probe'].requestType"
                @change="
                  tabClick(
                    updateData[item.type + 'Probe'].requestType,
                    updateData[item.type + 'Probe']
                  )
                "
              >
                <a-tab-pane tab="Http请求" key="HTTP"></a-tab-pane>
                <a-tab-pane tab="TCP连接" key="TCP"></a-tab-pane>
                <a-tab-pane tab="命令行" key="SHELL"></a-tab-pane>
              </a-tabs>
              <a-form-item
                label="路径:"
                v-if="updateData[item.type + 'Probe'].requestType == 'HTTP'"
              >
                <a-input
                  v-model="updateData[item.type + 'Probe'].extraInfo.path"
                />
              </a-form-item>
              <a-form-item
                label="端口:"
                v-if="
                  updateData[item.type + 'Probe'].requestType == 'HTTP' ||
                  updateData[item.type + 'Probe'].requestType == 'TCP'
                "
              >
                <a-input
                  v-model="updateData[item.type + 'Probe'].extraInfo.port"
                />
              </a-form-item>
              <a-form-item
                label="命令行:"
                v-if="updateData[item.type + 'Probe'].requestType == 'SHELL'"
              >
                <a-input
                  v-model="updateData[item.type + 'Probe'].extraInfo.command"
                />
              </a-form-item>
              <a-form-item label="延迟探测时间(秒):">
                <a-input
                  v-model="updateData[item.type + 'Probe'].initialDelaySeconds"
                />
              </a-form-item>
              <a-form-item label="执行探测频率(秒):">
                <a-input
                  v-model="updateData[item.type + 'Probe'].periodSeconds"
                />
              </a-form-item>
              <a-form-item label="超时时间(秒):">
                <a-input
                  v-model="updateData[item.type + 'Probe'].timeoutSeconds"
                />
              </a-form-item>
              <a-form-item label="健康阈值:">
                <a-input
                  v-model="updateData[item.type + 'Probe'].successThreshold"
                  disabled
                />
              </a-form-item>
              <a-form-item label="不健康阈值:">
                <a-input
                  v-model="updateData[item.type + 'Probe'].failureThreshold"
                />
              </a-form-item>
            </a-form>
          </div>
        </template>
        <a-form-item label="备注:">
          <a-textarea
            v-model="updateData.remark"
            placeholder="请输入备注"
            autoSize
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="updateShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="update_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/k8s.js";

export default {
  name: "probe",
  data() {
    return {
      name: "",
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          width: 100,
        },
        {
          title: "名称",
          dataIndex: "name",
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "启用状态",
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          width: 100,
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 100,
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      pageNo: 1,
      updateData: {
        livenessProbeEnabled: false,
        livenessProbe: {},
        readinessProbeEnabled: false,
        readinessProbe: {},
        startupProbeEnabled: false,
        startupProbe: {},
      },
      localData: {},
      title: "",
      updateShow: false,
      selectedRowKeys: [],
      probeFormList: [
        { type: "liveness", label: "存活检查" },
        { type: "readiness", label: "就绪检查" },
        { type: "startup", label: "启动探测" },
      ],
    };
  },
  created() {
    this.query();
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query();
    },
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        name: this.name,
      };
      this.loadingTable = true;
      api
        .probeTemplatePage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource = res.data.records;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: 15,
              current: this.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    changePageItem(index) {
      this.query(index);
    },
    add() {
      this.title = "新增";
      this.localData = null;
      this.updateData = {
        name: "",
        remark: "",
        enabled: true,
        livenessProbeEnabled: true,
        livenessProbe: {
          requestType: "HTTP",
          initialDelaySeconds: 15,
          periodSeconds: 10,
          timeoutSeconds: 1,
          successThreshold: 1,
          failureThreshold: 3,
          extraInfo: {
            path: "",
            port: "",
          },
        },
        readinessProbeEnabled: false,
        readinessProbe: {},
        startupProbeEnabled: false,
        startupProbe: {},
      };
      this.updateShow = true;
    },
    edit(val) {
      this.title = "修改";
      this.updateData = {
        id: val.id,
        name: val.name,
        remark: val.remark,
        enabled: val.enabled,
        livenessProbeEnabled: val.livenessProbe ? true : false,
        livenessProbe: {},
        readinessProbeEnabled: val.readinessProbe ? true : false,
        readinessProbe: {},
        startupProbeEnabled: val.startupProbe ? true : false,
        startupProbe: {},
      };
      this.probeEnabledChange(
        this.updateData.livenessProbeEnabled,
        "liveness",
        val
      );
      this.probeEnabledChange(
        this.updateData.readinessProbeEnabled,
        "readiness",
        val
      );
      this.probeEnabledChange(
        this.updateData.startupProbeEnabled,
        "startup",
        val
      );
      this.localData = val;
      this.updateShow = true;
    },
    probeEnabledChange(enabled, type, val) {
      let probe = {};
      if (enabled) {
        if (val && val[type + "Probe"]) {
          switch (type) {
            case "liveness":
              probe = { ...val.livenessProbe };
              probe.extraInfo = JSON.parse(probe.extraInfo);
              break;
            case "readiness":
              probe = { ...val.readinessProbe };
              probe.extraInfo = JSON.parse(probe.extraInfo);
              break;
            case "startup":
              probe = { ...val.startupProbe };
              probe.extraInfo = JSON.parse(probe.extraInfo);
              break;
          }
        } else {
          probe = {
            requestType: "HTTP",
            initialDelaySeconds: 15,
            periodSeconds: 10,
            timeoutSeconds: 1,
            successThreshold: 1,
            failureThreshold: 3,
            extraInfo: {
              path: "",
              port: "",
            },
          };
        }
      }
      switch (type) {
        case "liveness":
          this.$set(this.updateData, "livenessProbe", probe);
          break;
        case "readiness":
          this.$set(this.updateData, "readinessProbe", probe);
          break;
        case "startup":
          this.$set(this.updateData, "startupProbe", probe);
          break;
      }
    },
    tabClick(key, probe) {
      switch (key) {
        case "HTTP":
          this.$set(probe, "extraInfo", {
            path: "",
            port: "",
          });
          break;
        case "TCP":
          this.$set(probe, "extraInfo", {
            port: "",
          });
          break;
        case "SHELL":
          this.$set(probe, "extraInfo", {
            command: "",
          });
          break;
      }
    },
    update_submit() {
      let data = { ...this.updateData };
      if (data.livenessProbe) {
        data.livenessProbe.extraInfo = JSON.stringify(
          data.livenessProbe.extraInfo
        );
      } else {
        delete data.livenessProbe;
      }
      if (data.readinessProbe) {
        data.readinessProbe.extraInfo = JSON.stringify(
          data.readinessProbe.extraInfo
        );
      } else {
        delete data.readinessProbe;
      }
      if (data.startupProbe) {
        data.startupProbe.extraInfo = JSON.stringify(
          data.startupProbe.extraInfo
        );
      } else {
        delete data.startupProbe;
      }

      delete data.livenessProbeEnabled;
      delete data.readinessProbeEnabled;
      delete data.startupProbeEnabled;
      if (this.title == "新增") {
        api.addTemplate(data).then((res) => {
          if (res.result === 200) {
            this.$message.success("新增成功");
            this.updateShow = false;
            this.query();
          }
        });
      } else {
        api.editTemplate(data).then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.updateShow = false;
            this.query();
          }
        });
      }
    },
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.delTemplate(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    batchDeleteClick() {
      let arr = [];
      this.selectedRowKeys.forEach((id) => {
        this.tableDataSource.forEach((item) => {
          if (item.id == id) {
            arr.push(item);
          }
        });
      });
      this.$axios
        .all(
          arr.map((item) => {
            let data = {
              id: item.id,
            };
            return api.delTemplate(data);
          })
        )
        .then((resArr) => {
          this.$message.success("删除成功");
          this.query();
        });
    },
  },
};
</script>
<style scoped>
.probeForm {
  width: 80%;
  margin-left: 20%;
}
</style>
