<template>
  <div class="interfaceManagement">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="内部接口">
        <router-view name="three" v-if="active == '1'" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="外部接口">
        <router-view name="three" v-if="active == '2'" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "interfaceManagement",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/interfaceManagement/innerApi") {
          this.active = "1";
        } else if (newVal == "/interfaceManagement/outerApi") {
          this.active = "2";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/interfaceManagement/innerApi") return;
          this.$router.replace("/interfaceManagement/innerApi");
        }
        if (newVal === "2") {
          if (this.$route.path == "/interfaceManagement/outerApi") return;
          this.$router.replace("/interfaceManagement/outerApi");
        }
      },
    },
  },
};
</script>
