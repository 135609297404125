import http from "./axios";

// 查询账号列表
export const aliyunList = ({ pageNo, pageSize, accountName }) => {
  return http.get("/delivery/aliyun/account/mainAccountPage", {
    pageNo: pageNo,
    pageSize: pageSize,
    accountName: accountName,
  });
};

// 新增账号列表
export const addMainAccount = ({
  accountName,
  accessKey,
  accessSecret,
  remark,
}) => {
  return http.json_post("/delivery/aliyun/account/addMainAccount", {
    accountName: accountName,
    accessKey: accessKey,
    accessSecret: accessSecret,
    remark: remark,
  });
};
// 编辑账号列表
export const editMainAccount = ({ accountName, id, remark }) => {
  return http.json_post("/delivery/aliyun/account/editMainAccount", {
    accountName: accountName,
    id: id,
    remark: remark,
  });
};
// 删除账号列表
export const deleteMainAccount = ({ id }) => {
  return http.json_post("/delivery/aliyun/account/deleteMainAccount", {
    id: id,
  });
};
