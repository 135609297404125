<template>
  <div
    :class="{ rule: true, select: item.selectShow, editBorder: item.editShow }"
  >
    <div v-if="item.editShow" class="edit">
      <div class="ruleHeader">
        {{ item.id }}&nbsp;
        <a-input
          v-model="item.name"
          placeholder="请输入名称"
          style="width: 300px"
        />
      </div>
      <div class="ruleContent">
        <div class="flexItem">
          <div class="item"><b>如果</b></div>
          <template v-for="(condition, index) in conditionList">
            <div class="item" v-if="condition.type">
              <div class="col">
                {{ typeLabel(condition.type)
                }}<span
                  style="
                    color: #888;
                    cursor: pointer;
                    float: right;
                    font-size: 18px;
                  "
                  @click="deleteCondition(index)"
                >
                  <a-icon type="delete" />
                </span>
              </div>
              <div
                class="col"
                v-for="(conditionValue, conditionIndex) in condition.value"
              >
                {{ conditionIndex == 0 ? "是" : "或" }}&nbsp;
                <a-input
                  v-model="conditionValue.content"
                  style="width: 60%; margin-right: 10px"
                />
                <a-switch
                  v-if="condition.type == 'domain'"
                  v-model="conditionValue.publicVisit"
                  checkedChildren="外网"
                  unCheckedChildren="内网"
                />
                <span
                  v-show="conditionIndex !== 0"
                  style="
                    color: #888;
                    cursor: pointer;
                    font-size: 16px;
                    display: inline-block;
                    margin-left: 10px;
                    vertical-align: middle;
                  "
                  @click="deleteConditionValue(index, conditionIndex)"
                >
                  <a-icon type="close" />
                </span>
              </div>
              <div
                class="col"
                style="color: #0070cc; cursor: pointer"
                @click="addConditionValue(index, condition.type)"
              >
                <a-icon type="plus" />添加{{ typeLabel(condition.type) }}
              </div>
            </div>
            <div class="item" v-else>
              <a-select
                v-model="condition.type"
                style="width: 90%"
                placeholder="请选择条件类型"
                allowClear
              >
                <a-select-option
                  v-for="item in conditionSelectList"
                  :key="item.id"
                  :value="item.code"
                  :label="item.name"
                  :disabled="conditionDisabled(item.code)"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
              <span
                style="
                  color: #888;
                  cursor: pointer;
                  float: right;
                  font-size: 18px;
                "
                v-show="index !== 0"
                @click="deleteCondition(index)"
              >
                <a-icon type="delete" />
              </span>
            </div>
          </template>
          <div
            class="item"
            style="color: #0070cc; cursor: pointer"
            @click="addCondition"
          >
            <a-icon type="plus" />添加转发条件
          </div>
        </div>
        <div class="flexItem">
          <div class="item"><b>那么</b></div>
          <template v-for="(action, index) in actionList">
            <div class="item" v-if="action.type">
              <div class="col">
                {{ typeLabel(action.type)
                }}<span
                  style="
                    color: #888;
                    cursor: pointer;
                    float: right;
                    font-size: 18px;
                  "
                  @click="deleteAction(index)"
                >
                  <a-icon type="delete" />
                </span>
              </div>
              <div class="col" v-if="action.type == 'FORWARD_JAVA'">
                <a-select
                  v-model="action.value.content"
                  showSearch
                  placeholder="请选择项目"
                  :filter-option="false"
                  @search="statusIdSearch"
                  @change="getName($event, action.type)"
                  allowClear
                  style="width: 90%"
                >
                  <a-select-option
                    v-for="item in statusList"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.enName }} | {{ item.ip }}</a-select-option
                  >
                </a-select>
              </div>
              <div class="col" v-if="action.type == 'FORWARD_FRONT'">
                <a-select
                  v-model="action.value.content"
                  showSearch
                  placeholder="请选择项目"
                  :filter-option="false"
                  @search="projectIdSearch"
                  @change="getName($event, action.type)"
                  allowClear
                  style="width: 90%"
                >
                  <a-select-option
                    v-for="item in dataSource"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.enName }}
                  </a-select-option>
                </a-select>
              </div>
              <div class="col" v-if="action.type == 'UPSTREAM_GROUP'">
                <a-select
                  v-model="action.value.groupId"
                  showSearch
                  placeholder="请选择服务器组"
                  :filter-option="false"
                  @search="serverGroupIdSearch"
                  @change="getName($event, action.type)"
                  allowClear
                  style="width: 90%"
                >
                  <a-select-option
                    v-for="item in serverGroupList"
                    :key="item.id"
                    :value="item.id"
                    :disabled="item.nginxServerCount == 0"
                    >{{ item.groupName }} ｜
                    {{ item.nginxServerCount }}</a-select-option
                  >
                </a-select>
              </div>
              <div class="col" v-if="action.type == 'FIXED_RESPONSE'">
                <span class="label">响应状态码</span>
                <a-input
                  placeholder="请填写响应状态码"
                  v-model="action.value.code"
                  style="width: calc(100% - 90px)"
                />
              </div>
              <div class="col" v-if="action.type == 'FIXED_RESPONSE'">
                <span class="label">响应正文类型（可选）</span>
                <a-select
                  v-model="action.value.type"
                  placeholder="请选择响应正文类型"
                  style="width: calc(100% - 170px)"
                  allowClear
                >
                  <a-select-option
                    v-for="item in contentTypeList"
                    :key="item.id"
                    :value="item.value"
                    >{{ item.value }}
                  </a-select-option>
                </a-select>
              </div>
              <div class="col" v-if="action.type == 'FIXED_RESPONSE'">
                <span class="textarea label">响应正文（可选）</span>
                <a-textarea
                  placeholder="请填写响应正文"
                  :rows="5"
                  v-model="action.value.content"
                  style="width: calc(100% - 138px)"
                />
              </div>
              <div
                class="col"
                v-if="action.type == 'FIXED_RESPONSE'"
                style="color: grey"
              >
                可用占位符: ${publicIp}, ${privateIp}, ${name}
              </div>
              <div class="col" v-if="action.type == 'REDIRECT'">
                <span class="label">协议</span>
                <a-select
                  v-model="action.value.protocol"
                  placeholder="请选择协议"
                  style="width: calc(100% - 90px)"
                >
                  <a-select-option
                    v-for="item in protocolList"
                    :key="item.id"
                    :value="item.value"
                    >{{ item.value }}
                  </a-select-option>
                </a-select>
              </div>
              <div class="col" v-if="action.type == 'REDIRECT'">
                <span class="label">域名</span>
                <a-input
                  placeholder="请填写域名"
                  v-model="action.value.host"
                  style="width: calc(100% - 90px)"
                />
              </div>
              <div class="col" v-if="action.type == 'REDIRECT'">
                <span class="label">端口</span>
                <a-input
                  placeholder="请填写端口"
                  v-model="action.value.port"
                  style="width: calc(100% - 90px)"
                />
              </div>
              <div class="col" v-if="action.type == 'REDIRECT'">
                <span class="label">路径</span>
                <a-input
                  placeholder="请填写路径"
                  v-model="action.value.path"
                  style="width: calc(100% - 90px)"
                />
              </div>
              <div class="col" v-if="action.type == 'REDIRECT'">
                <span class="label">状态码</span>
                <a-select
                  v-model="action.value.code"
                  placeholder="请选择状态码"
                  style="width: calc(100% - 90px)"
                >
                  <a-select-option
                    v-for="item in codeList"
                    :key="item.id"
                    :value="item.value"
                    >{{ item.value }}
                  </a-select-option>
                </a-select>
              </div>
              <div class="col" v-if="action.type == 'DIY'">
                <span class="textarea label">内容</span>
                <a-textarea
                  placeholder="请填写内容"
                  :rows="5"
                  v-model="action.value.content"
                  style="width: calc(100% - 90px)"
                />
              </div>
              <div class="col" v-if="action.type == 'ALIAS'">
                <span class="label">内容</span>
                <a-input
                  placeholder="请填写内容"
                  v-model="action.value.content"
                  style="width: calc(100% - 90px)"
                />
              </div>
              <div class="col" v-if="action.type == 'ROOT'">
                <span class="label">路径</span>
                <a-input
                  placeholder="请填写路径"
                  v-model="action.value.content"
                  style="width: calc(100% - 90px)"
                />
              </div>
              <div class="col" v-if="action.type == 'ROOT'">
                <span class="label">try files(可选)</span>
                <a-input
                  placeholder="请填写tryFile"
                  v-model="action.value.tryFile"
                  style="width: calc(100% - 140px)"
                />
              </div>
              <div
                class="col"
                v-if="
                  action.type == 'FIXED_PORT' ||
                  action.type == 'WEBSOCKET_REDIRECT'
                "
              >
                <span class="label">服务器</span>
                <a-select
                  v-model="action.value.ip"
                  v-if="!action.value.enterShow"
                  showSearch
                  placeholder="请选择服务器"
                  :filter-option="false"
                  @search="serverIdSearch"
                  @change="getName($event, action.type)"
                  allowClear
                  style="width: calc(100% - 200px)"
                >
                  <a-select-option
                    v-for="item in serverSelectList"
                    :key="item.id"
                    :value="item.privateIp"
                    >{{ item.instanceName }}({{
                      item.privateIp
                    }})</a-select-option
                  >
                </a-select>
                <a-input
                  placeholder="请输入服务器地址"
                  v-model="action.value.ip"
                  v-else
                  style="width: calc(100% - 200px)"
                />
                <a-button
                  type="link"
                  @click="action.value.enterShow = !action.value.enterShow"
                  >{{
                    action.value.enterShow ? "选择服务器" : "手动输入"
                  }}</a-button
                >
              </div>
              <div
                class="col"
                v-if="
                  action.type == 'FIXED_PORT' ||
                  action.type == 'WEBSOCKET_REDIRECT'
                "
              >
                <span class="label">端口</span>
                <a-input
                  placeholder="请填写端口"
                  v-model="action.value.port"
                  style="width: calc(100% - 200px); margin-left: 15px"
                />
              </div>
              <div
                class="col"
                v-if="action.type == 'UPSTREAM'"
                style="overflow: auto"
              >
                <a-button type="link" @click="addItem()" style="float: right">
                  <a-icon type="plus" />
                </a-button>
                <a-table
                  :rowClassName="$common.rowClassColor"
                  bordered
                  :components="$common.getTitle(tableColumns)"
                  :columns="tableColumns"
                  :dataSource="action.value.itemList"
                  :pagination="false"
                  size="small"
                  style="
                    width: calc(100% - 60px);
                    float: right;
                    margin-right: 12px;
                  "
                >
                  <span
                    slot="address"
                    slot-scope="text, record"
                    style="width: 100%"
                  >
                    <a-tooltip>
                      <template slot="title">
                        {{
                          action.value.addressShow
                            ? text
                            : contentLabel("FORWARD_JAVA", text)
                        }}
                      </template>
                      {{
                        action.value.addressShow
                          ? text
                          : contentLabel("FORWARD_JAVA", text)
                      }}
                    </a-tooltip>
                  </span>
                  <span
                    slot="action"
                    slot-scope="text, record"
                    style="width: 100%"
                  >
                    <span
                      style="
                        color: #888;
                        cursor: pointer;
                        font-size: 16px;
                        display: inline-block;
                        margin-left: 5px;
                      "
                      @click="deleteItem(record.id)"
                    >
                      <a-icon type="close" />
                    </span>
                  </span>
                </a-table>
              </div>
            </div>
            <div class="item" v-else>
              <a-select
                v-model="action.type"
                style="width: 90%"
                placeholder="请选择动作类型"
                allowClear
              >
                <a-select-option
                  v-for="item in actionSelectList"
                  :key="item.id"
                  :value="item.code"
                  :label="item.name"
                >
                  {{ item.name }}</a-select-option
                >
              </a-select>
              <span
                style="
                  color: #888;
                  cursor: pointer;
                  float: right;
                  font-size: 18px;
                "
                v-show="index !== 0"
                @click="deleteAction(index)"
              >
                <a-icon type="delete" />
              </span>
            </div>
          </template>
          <div class="item">
            <a-select
              v-model="property"
              mode="multiple"
              style="width: 60%; margin-right: 20px"
              placeholder="请选择属性"
              allowClear
            >
              <a-select-option
                v-for="item in propertySelectList"
                :key="item.id"
                :value="item.id"
                :label="item.code"
                >{{ item.code }}</a-select-option
              >
            </a-select>
            <a-button type="primary" @click="add">
              <a-icon type="plus" />添加属性
            </a-button>
          </div>
          <div class="item" v-if="propertyList.length > 0">
            <template class="col" v-for="(item, index) in propertyList">
              <div
                style="position: relative; line-height: 32px; margin-top: 5px"
              >
                <span
                  style="
                    display: inline-block;
                    width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: middle;
                    white-space: nowrap;
                    text-align: right;
                  "
                  :title="attribute(item.attributeId)"
                  v-if="!item.noValue"
                  >{{ attribute(item.attributeId) }} &nbsp;: &nbsp;</span
                >
                <span v-else>{{ attribute(item.attributeId) }}</span>
                <a-input
                  v-model="item.value"
                  placeholder="请输入值"
                  style="width: calc(100% - 230px)"
                  v-show="!item.noValue"
                />
                <a-button
                  type="link"
                  @click="deleteProperty(index)"
                  style="position: absolute; right: 30px"
                >
                  <a-icon type="delete" style="color: rgb(136, 136, 136)" />
                </a-button>
              </div>
            </template>
          </div>
          <div class="item" v-if="actionList[0].type == 'FORWARD_FRONT'">
            <a-checkbox v-model="item.includeGeneralConf"
              >general.conf</a-checkbox
            >
          </div>
        </div>
      </div>
      <div class="bottomButton">
        <a-button
          type="primary"
          style="margin-right: 10px"
          @click.stop="generateName(true)"
          >确定</a-button
        >
        <a-button
          type="primary"
          style="margin-right: 10px"
          @click.stop="generateName(false)"
          >预览</a-button
        >
        <a-button @click.stop="cancel">取消</a-button>
      </div>
      <div class="remark">
        <a-input
          v-model="remark"
          placeholder="请输入备注"
          style="width: 240px"
        />
      </div>
    </div>
    <div v-else class="preview">
      <div class="ruleHeader">
        <span style="color: #000; font-weight: 500; font-size: 14px"
          >{{ item.id }} - {{ item.name }}</span
        >
        <a-switch
          v-model="item.enabled"
          checkedChildren="启用"
          unCheckedChildren="未启用"
          style="float: right"
          @change="enabledChange"
        />
        <span class="button" v-show="!disabled" @click.stop="deleteRule">
          <a-icon type="delete" />
        </span>
        <span class="button" v-show="!disabled" @click.stop="edit">
          <a-icon type="edit" />
        </span>
        <span
          class="button"
          v-show="!disabled"
          @click.stop="generateName(false)"
        >
          <a-icon type="eye" />
        </span>
      </div>
      <div class="ruleContent">
        <div class="flexItem">
          <div class="item">
            <div class="col"><b>如果</b></div>
            <div
              class="col"
              v-for="(item, index) in conditionList"
              :key="index"
            >
              {{ typeLabel(item.type) }}
              <template v-for="(valueItem, valueIndex) in item.value">
                <span style="color: #888" v-if="valueIndex == 0">是</span>
                <span style="color: #888; margin-left: 32px" v-else>或</span>
                <span style="color: #888">&nbsp;{{ valueItem.content }}</span>
                <br />
              </template>
            </div>
          </div>
        </div>
        <div class="flexItem">
          <div class="item">
            <div class="col">
              <b>那么</b>
              <a-tooltip overlayClassName="property">
                <template slot="title">
                  <div class="item" v-if="propertyList.length > 0">
                    <div class="col"><b>属性</b></div>
                    <template v-for="(item, index) in propertyList">
                      <div class="col" v-show="!item.noValue">
                        {{ item.attribute }}:{{ item.value }}
                      </div>
                      <div class="col" v-show="item.noValue">
                        {{ item.attribute }}
                      </div>
                    </template>
                  </div>
                </template>
                <span
                  class="button"
                  style="float: right; margin-right: 20px"
                  v-if="propertyList.length > 0"
                >
                  <a-icon type="exclamation-circle" />
                </span>
              </a-tooltip>
            </div>
            <template v-for="(item, index) in actionList">
              <div
                class="col"
                v-if="
                  item.type == 'FORWARD_FRONT' || item.type == 'FORWARD_JAVA'
                "
              >
                {{ typeLabel(item.type) }}&nbsp;&nbsp;
                <span style="color: #888">{{
                  item.value.displayName ||
                  contentLabel(item.type, item.value.content)
                }}</span>
              </div>
              <div class="col" v-else-if="item.type == 'UPSTREAM_GROUP'">
                {{ typeLabel(item.type) }}&nbsp;&nbsp;<span
                  style="color: #888"
                  >{{ item.value.groupName }}</span
                >
              </div>
              <div class="col" v-else-if="item.type == 'FIXED_RESPONSE'">
                {{ typeLabel(item.type) }}&nbsp;&nbsp;
                <span style="color: #888"
                  >响应状态码 {{ item.value.code }}</span
                >
                <br />
                <template v-if="item.value.type">
                  <span style="color: #888; margin-left: 95px"
                    >响应正文类型（可选）{{ item.value.type }}</span
                  ><br />
                </template>
                <template v-if="item.value.content">
                  <span style="color: #888; margin-left: 95px"
                    >响应正文（可选）{{ item.value.content }}</span
                  ><br />
                </template>
              </div>
              <div class="col" v-else-if="item.type == 'REDIRECT'">
                {{ typeLabel(item.type) }}&nbsp;&nbsp;
                <span style="color: #888">协议 {{ item.value.protocol }}</span>
                <br />
                <template v-if="item.value.host">
                  <span style="color: #888; margin-left: 68px"
                    >域名 {{ item.value.host }}</span
                  >
                  <br />
                </template>
                <template v-if="item.value.port">
                  <span style="color: #888; margin-left: 68px"
                    >端口 {{ item.value.port }}</span
                  >
                  <br />
                </template>
                <template v-if="item.value.path">
                  <span style="color: #888; margin-left: 68px"
                    >路径 {{ item.value.path }}</span
                  >
                  <br />
                </template>
                <template v-if="item.value.code">
                  <span style="color: #888; margin-left: 68px">状态码 </span>
                  <span style="color: #888">{{ item.value.code }}</span>
                </template>
              </div>
              <div class="col" v-else-if="item.type == 'DIY'">
                {{ typeLabel(item.type) }}&nbsp;&nbsp;
                <template v-if="item.value.content">
                  <span style="color: #888">内容 {{ item.value.content }}</span>
                </template>
              </div>
              <div class="col" v-else-if="item.type == 'ALIAS'">
                {{ typeLabel(item.type) }}&nbsp;&nbsp;
                <template v-if="item.value.content">
                  <span style="color: #888">内容 {{ item.value.content }}</span>
                </template>
              </div>
              <div class="col" v-else-if="item.type == 'ROOT'">
                {{ typeLabel(item.type) }}&nbsp;&nbsp;
                <template v-if="item.value.content">
                  <span style="color: #888">路径 {{ item.value.content }}</span>
                </template>
                <template v-if="item.value.tryFile">
                  <span style="color: #888"
                    >try files {{ item.value.tryFile }}</span
                  >
                </template>
              </div>
              <div
                class="col"
                v-else-if="
                  item.type == 'FIXED_PORT' || item.type == 'WEBSOCKET_REDIRECT'
                "
              >
                {{ typeLabel(item.type) }}&nbsp;&nbsp;
                <template v-if="item.value.ip">
                  <span style="color: #888">服务器</span>
                  {{
                    item.value.enterShow
                      ? item.value.ip
                      : item.value.displayName ||
                        contentLabel(item.type, item.value.ip)
                  }}
                </template>
                <template v-if="item.value.port">
                  <span style="color: #888">端口</span> {{ item.value.port }}
                </template>
              </div>
              <div
                class="col"
                v-else-if="item.type == 'UPSTREAM'"
                style="overflow: auto"
              >
                {{ typeLabel(item.type) }}<br />
                <template v-if="item.value.itemList.length > 0">
                  <div style="margin-top: 10px">
                    <a-table
                      :rowClassName="$common.rowClassColor"
                      bordered
                      :components="$common.getTitle(tableColumnsPreview)"
                      :columns="tableColumnsPreview"
                      :dataSource="item.value.itemList"
                      :pagination="false"
                      size="small"
                      style="width: calc(100% - 60px); float: left"
                    >
                      <span
                        slot="address"
                        slot-scope="text, record"
                        style="width: 100%"
                      >
                        <a-tooltip>
                          <template slot="title">
                            {{
                              item.value.addressShow
                                ? text
                                : contentLabel("FORWARD_JAVA", text)
                            }}
                          </template>
                          {{
                            item.value.addressShow
                              ? text
                              : contentLabel("FORWARD_JAVA", text)
                          }}
                        </a-tooltip>
                      </span>
                    </a-table>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="remark" style="font-size: 12px; color: #000" v-if="remark">
        备注：{{ remark }}
      </div>
    </div>
    <a-modal
      title="nginx规则预览"
      v-model="visible"
      :maskClosable="false"
      :afterClose="rulePreviewClose"
      width="1000px"
    >
      <div class="previewList">
        <div
          class="desc"
          v-for="(item, index) in confList"
          :key="index"
          :style="{ marginTop: index == 0 ? '0' : '20px' }"
        >
          <header class="desc_header">
            <div style="float: left">
              {{ item.instanceName }} ｜ {{ item.fileName }}
            </div>
          </header>
          <pre v-html="item.content" class="desc_content"></pre>
        </div>
        <div class="dec" v-show="confList.length == 0">
          未检测到任何配置更新
        </div>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="preCheck">nginx -t</a-button>
          <a-button key="back" @click="visible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
    <a-modal
      title="新增"
      v-model="addVisible"
      :maskClosable="false"
      width="700px"
    >
      <a-form>
        <a-form-item
          label="address:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input
            v-model="addItemForm.address"
            placeholder="请输入address"
            v-if="addressShow"
            style="width: 75%"
          />
          <a-select
            v-else
            v-model="addItemForm.address"
            showSearch
            placeholder="请选择项目"
            :filter-option="false"
            @search="statusIdSearch"
            allowClear
            style="width: 75%"
          >
            <a-select-option
              v-for="item in statusList"
              :key="item.id"
              :value="item.id"
              >{{ item.enName }} | {{ item.ip }}</a-select-option
            >
          </a-select>
          <a-button type="link" @click="addressShow = !addressShow">{{
            addressShow ? "选择服务器" : "手动输入"
          }}</a-button>
        </a-form-item>
        <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
          <span slot="label">
            weight
            <a-tooltip>
              <template slot="title">
                访问权重, 默认值为1, 值越大，被访问到概率越大
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-input v-model="addItemForm.weight" placeholder="请输入weight" />
        </a-form-item>
        <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
          <span slot="label">
            max_fails
            <a-tooltip>
              <template slot="title">
                当server访问出错达到max_fails时,
                nginx就会标记这个server为故障状态, 后面就不会再去请求它了
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-input
            v-model="addItemForm.maxFails"
            placeholder="请输入maxFails"
          />
        </a-form-item>
        <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
          <span slot="label">
            fail_timeout
            <a-tooltip>
              <template slot="title">
                故障等待超时时间, server标记为故障状态后, 会被标记为正常状态
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-input
            v-model="addItemForm.failTimeout"
            placeholder="请输入failTimeout"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addVisible = false">关闭</a-button>
          <a-button type="primary" @click="addItem_submit">确认</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import api from "../lib/slb.js";

export default {
  name: "rule",
  props: [
    "index",
    "item",
    "disabled",
    "nginxGatewayId",
    "propertySelectList",
    "serverSelectList",
    "statusList",
    "dataSource",
    "serverGroupList",
  ],
  data() {
    return {
      conditionSelectList: [
        { name: "域名", id: 1, code: "domain" },
        { name: "路径", id: 2, code: "path" },
      ],
      actionSelectList: [
        { name: "转发至前端应用", id: 1, code: "FORWARD_FRONT" },
        { name: "转发至后端应用", id: 2, code: "FORWARD_JAVA" },
        { name: "转发至固定端口", id: 3, code: "FIXED_PORT" },
        { name: "转发至upstream", id: 4, code: "UPSTREAM" },
        { name: "转发至服务器组", id: 5, code: "UPSTREAM_GROUP" },
        { name: "返回固定响应", id: 6, code: "FIXED_RESPONSE" },
        { name: "websocket转发", id: 7, code: "WEBSOCKET_REDIRECT" },
        { name: "alias路径映射", id: 8, code: "ALIAS" },
        { name: "root路径映射", id: 9, code: "ROOT" },
        { name: "重定向", id: 10, code: "REDIRECT" },
        { name: "自定义", id: 11, code: "DIY" },
      ],
      conditionList: [{ type: undefined, value: "" }],
      actionList: [{ type: undefined, value: "" }],
      contentTypeList: [
        { value: "text/plain", id: 1 },
        { value: "text/css", id: 2 },
        { value: "text/html", id: 3 },
        { value: "application/javascript", id: 4 },
        { value: "application/json", id: 5 },
      ],
      ruleIdList: [],
      propertyList: [],
      property: [],
      visible: false,
      codeList: [
        { value: "301", id: 1 },
        { value: "302", id: 2 },
        { value: "303", id: 3 },
        { value: "307", id: 4 },
        { value: "308", id: 5 },
      ],
      protocolList: [
        { value: "${protocol}", id: 1 },
        { value: "HTTP", id: 2 },
        { value: "HTTPS", id: 3 },
      ],
      tableColumns: [
        {
          title: "address",
          ellipsis: true,
          dataIndex: "address",

          scopedSlots: { customRender: "address" },
        },
        {
          title: "weight",
          ellipsis: true,
          dataIndex: "weight",

          width: 100,
        },
        {
          title: "",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableColumnsPreview: [
        {
          title: "address",
          ellipsis: true,
          dataIndex: "address",

          scopedSlots: { customRender: "address" },
        },
        {
          title: "weight",
          ellipsis: true,
          dataIndex: "weight",

          width: 100,
        },
      ],
      addVisible: false,
      addItemForm: {
        address: undefined,
        weight: "",
        maxFails: "",
        failTimeout: "",
      },
      addressShow: false,
      remark: "",
      confList: [],
    };
  },
  computed: {
    typeLabel() {
      return (type) => {
        if (type == "domain") {
          return "域名";
        } else if (type == "path") {
          return "路径";
        } else if (type == "FORWARD_FRONT") {
          return "转发至前端应用";
        } else if (type == "FORWARD_JAVA") {
          return "转发至后端应用";
        } else if (type == "FIXED_RESPONSE") {
          return "返回固定响应";
        } else if (type == "REDIRECT") {
          return "重定向至";
        } else if (type == "DIY") {
          return "自定义";
        } else if (type == "FIXED_PORT") {
          return "转发至固定端口";
        } else if (type == "ALIAS") {
          return "alias路径映射";
        } else if (type == "WEBSOCKET_REDIRECT") {
          return "websocket转发";
        } else if (type == "UPSTREAM") {
          return "转发至upstream";
        } else if (type == "UPSTREAM_GROUP") {
          return "转发至服务器组";
        } else if (type == "ROOT") {
          return "root路径映射";
        }
      };
    },
    contentLabel() {
      return (type, content) => {
        let label;
        if (type == "FORWARD_FRONT") {
          this.dataSource.forEach((item) => {
            if (item.id == content) {
              label = item.enName;
            }
          });
        } else if (type == "FORWARD_JAVA") {
          this.statusList.forEach((item) => {
            if (item.id == content) {
              label = item.enName + " | " + item.ip;
            }
          });
        } else if (type == "FIXED_PORT" || type == "WEBSOCKET_REDIRECT") {
          this.serverSelectList.forEach((item) => {
            if (item.privateIp == content) {
              label = item.instanceName + "(" + item.privateIp + ")";
            }
          });
        } else if (type == "UPSTREAM_GROUP") {
          this.serverGroupList.forEach((item) => {
            if (item.id == content) {
              label = item.groupName;
            }
          });
        }
        return label;
      };
    },
    attribute() {
      return (attributeId) => {
        let code;
        this.propertySelectList.forEach((item) => {
          if (item.id == attributeId) {
            code = item.code;
          }
        });
        return code;
      };
    },
    conditionDisabled() {
      return (code) => {
        let flag = false;
        this.conditionList.forEach((item) => {
          if (item.type == code) {
            flag = true;
          }
        });
        return flag;
      };
    },
    conditionListValueArr() {
      let obj = { domainList: "", path: "" };
      let domain = [];
      let path = [];
      this.conditionList.forEach((item) => {
        if (item.type == "domain") {
          item.value.forEach((valueItem) => {
            domain.push({
              domain: valueItem.content,
              publicVisit: valueItem.publicVisit,
            });
          });
        } else {
          item.value.forEach((valueItem) => {
            path.push(valueItem.content);
          });
        }
      });
      obj.domainList = domain;
      obj.path = path.join(",");
      return obj;
    },
  },
  watch: {
    actionList: {
      deep: true,
      handler(newVal) {
        newVal.forEach((element, index) => {
          if (element.type == "FIXED_RESPONSE" && element.value == "") {
            this.$set(this.actionList[index], "value", {
              code: "200",
              type: "text/plain",
              content: "",
            });
          }
          if (
            (element.type == "FORWARD_FRONT" ||
              element.type == "FORWARD_JAVA") &&
            element.value == ""
          ) {
            this.$set(this.actionList[index], "value", {
              content: undefined,
              displayName: "",
            });
          }
          if (element.type == "UPSTREAM_GROUP" && element.value == "") {
            this.$set(this.actionList[index], "value", {
              groupId: undefined,
              upstreamName: "",
              groupName: "",
            });
          }
          if (element.type == "REDIRECT" && element.value == "") {
            this.$set(this.actionList[index], "value", {
              protocol: "${protocol}",
              host: "${host}",
              port: "${port}",
              path: "${path}",
              code: 301,
            });
          }
          if (element.type == "DIY" && element.value == "") {
            this.$set(this.actionList[index], "value", {
              content: "",
            });
          }
          if (
            (element.type == "FIXED_PORT" ||
              element.type == "WEBSOCKET_REDIRECT") &&
            element.value == ""
          ) {
            this.$set(this.actionList[index], "value", {
              ip: undefined,
              port: "",
              enterShow: false,
              displayName: "",
            });
          }
          if (element.type == "ALIAS" && element.value == "") {
            this.$set(this.actionList[index], "value", {
              content: "",
            });
          }
          if (element.type == "ROOT" && element.value == "") {
            this.$set(this.actionList[index], "value", {
              content: "",
              tryFile: "/index.html",
            });
          }
          if (element.type == "UPSTREAM" && element.value == "") {
            this.$set(this.actionList[index], "value", {
              name: null,
              itemList: [],
              addressShow: false,
            });
          }
        });
      },
    },
    conditionList: {
      deep: true,
      handler(newVal) {
        newVal.forEach((element, index) => {
          if (element.type == "domain" && element.value == "") {
            this.$set(this.conditionList[index], "value", [
              {
                content: "",
                publicVisit: true,
              },
            ]);
          } else if (element.type == "path" && element.value == "") {
            this.$set(this.conditionList[index], "value", [
              {
                content: "",
              },
            ]);
          }
        });
      },
    },
    "item.domain": {
      deep: true,
      handler() {
        this.init();
      },
    },
    "item.path": {
      deep: true,
      handler() {
        this.init();
      },
    },
    "item.actionContent": {
      deep: true,
      handler() {
        this.init();
      },
    },
    "item.actionType": {
      deep: true,
      handler() {
        this.init();
      },
    },
    "item.propertyList": {
      deep: true,
      handler() {
        this.init();
      },
    },
    "item.remark": {
      handler() {
        this.init();
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    enabledChange(val) {
      if (val) {
        this.enabledChangeSubmit(val);
      } else {
        this.item.enabled = true;
        this.$confirm({
          title: "确认禁用",
          content: (h) => (
            <div style="color:red;">
              确定要禁用该监听规则吗?(禁用将立即生效)
            </div>
          ),
          okText: "确认",
          cancelText: "取消",
          onOk: () => {
            this.enabledChangeSubmit(val);
            this.item.enabled = false;
          },
        });
      }
    },
    enabledChangeSubmit(val) {
      api
        .updateRuleEnableStatus({ id: this.item.id, enabled: val })
        .then((res) => {
          if (res.result == 200) {
            this.$message.success(val ? "启用成功" : "禁用成功");
            this.$emit("getConfList");
          }
        });
    },
    getName(id, type) {
      if (id) {
        if (type == "FORWARD_FRONT") {
          this.dataSource.forEach((item) => {
            if (item.id == id) {
              this.actionList[0].value.displayName = item.enName;
            }
          });
        } else if (type == "FORWARD_JAVA") {
          this.statusList.forEach((item) => {
            if (item.id == id) {
              this.actionList[0].value.displayName =
                item.enName + " | " + item.ip;
            }
          });
        } else if (type == "FIXED_PORT" || type == "WEBSOCKET_REDIRECT") {
          this.serverSelectList.forEach((item) => {
            if (item.privateIp == id) {
              this.actionList[0].value.displayName =
                item.instanceName + "(" + item.privateIp + ")";
            }
          });
        } else if (type == "UPSTREAM_GROUP") {
          this.serverGroupList.forEach((item) => {
            if (item.id == id) {
              this.actionList[0].value.groupName = item.groupName;
            }
          });
        }
      }
    },
    saveOrPreview(ok) {
      let data = {
        name: this.item.name,
        nginxGatewayId: this.nginxGatewayId,
        index: this.item.index,
        domainList: this.conditionListValueArr.domainList,
        path: this.conditionListValueArr.path,
        actionType: this.actionList[0].type,
        actionContent: JSON.stringify(this.actionList[0].value),
        propertyList: this.propertyList,
        includeGeneralConf: this.item.includeGeneralConf,
      };
      if (this.item.id) {
        data.id = this.item.id;
      }
      if (ok) {
        data.remark = this.remark;
        api.saveData(data).then((res) => {
          if (res.result == 200) {
            this.$message.success("保存成功");
            this.cancel();
            this.$emit("getConfList");
          }
        });
      } else {
        data.enabled = this.item.enabled;
        api.previewConf(data).then((res) => {
          if (res.result == 200) {
            this.confList = res.data;
            this.visible = true;
          }
        });
      }
    },
    generateName(ok) {
      if (
        this.actionList[0].type == "UPSTREAM" &&
        !this.actionList[0].value.name
      ) {
        api.generateName({ name: "upstream" }).then((res) => {
          if (res.result == 200) {
            this.$set(this.actionList[0].value, "name", res.data);
            this.saveOrPreview(ok);
          }
        });
      } else if (
        this.actionList[0].type == "UPSTREAM_GROUP" &&
        !this.actionList[0].value.upstreamName
      ) {
        api.generateName({ name: "upstream" }).then((res) => {
          if (res.result == 200) {
            this.$set(this.actionList[0].value, "upstreamName", res.data);
            this.saveOrPreview(ok);
          }
        });
      } else {
        this.saveOrPreview(ok);
      }
    },
    deleteItem(id) {
      this.actionList[0].value.itemList.splice(id - 1, 1);
    },
    addItem() {
      this.addItemForm = {
        address: undefined,
        weight: "1",
        maxFails: "1",
        failTimeout: "10s",
      };
      this.addVisible = true;
    },
    addItem_submit() {
      this.$set(
        this.addItemForm,
        "id",
        this.actionList[0].value.itemList.length + 1
      );
      this.actionList[0].value.itemList.push(this.addItemForm);
      this.actionList[0].value.addressShow = this.addressShow;
      this.addVisible = false;
      if (this.addressShow) {
        this.addressShow = false;
      }
    },
    preCheck() {
      let itemList = [];
      this.confList.forEach((item) => {
        itemList.push({
          nginxInstanceId: item.nginxInstanceId,
          content: item.content,
        });
      });
      api
        .precheck({
          itemList,
          nginxGatewayId: this.nginxGatewayId,
        })
        .then((res) => {
          if (res.result == 200) {
            this.$message.success("校验成功");
          }
        });
    },
    deleteConditionValue(index, conditionIndex) {
      this.conditionList[index].value.splice(conditionIndex, 1);
    },
    addConditionValue(index, type) {
      if (type == "domain") {
        this.conditionList[index].value.push({
          content: "",
          publicVisit: true,
        });
      } else {
        this.conditionList[index].value.push({ content: "" });
      }
    },
    rulePreviewClose() {
      this.confList.splice(0);
    },
    init() {
      if (this.item.domainList || this.item.path) {
        this.conditionList.splice(0);
        this.ruleIdList.splice(0);
        this.ruleIdList.push(this.item.id);

        if (this.item.domainList.length > 0) {
          let arr = [];
          this.item.domainList.forEach((item) => {
            arr.push({ content: item.domain, publicVisit: item.publicVisit });
          });
          this.conditionList.push({
            type: "domain",
            value: arr,
          });
        }
        if (this.item.path) {
          let arr = [];
          this.item.path.split(",").forEach((item) => {
            arr.push({ content: item });
          });
          this.conditionList.push({
            type: "path",
            value: arr,
          });
        }
      }
      if (this.item.actionType) {
        this.actionList.splice(0);
        this.actionList.push({
          type: this.item.actionType,
          value: JSON.parse(this.item.actionContent),
        });
      }
      this.propertyList.splice(0);
      if (this.item.propertyList) {
        this.item.propertyList.forEach((item) => {
          this.propertyList.push(item);
        });
      }
      if (this.item.remark) {
        this.remark = this.item.remark;
      }
      this.property = [];
    },
    projectIdSearch(name) {
      this.$emit("getProjectList", name);
    },
    statusIdSearch(enName) {
      this.$emit("getStatusList", enName);
    },
    serverGroupIdSearch(name) {
      this.$emit("searchServerGroup", name);
    },
    serverIdSearch(instanceName) {
      this.$emit("getServerList", instanceName);
    },
    select() {
      if (!this.item.editShow && !this.disabled) {
        this.$set(this.item, "selectShow", !this.item.selectShow);
      }
    },
    edit() {
      if (!this.item.editShow) {
        this.$set(this.item, "editShow", !this.item.editShow);
      }
    },
    deleteRule() {
      this.$confirm({
        title: "确认删除",
        content: (h) => (
          <div style="color:red;">确定要删除该监听规则吗？(删除将立即生效)</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: this.item.id,
          };
          api.deleteData(data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.$emit("monitorList");
              this.$emit("getConfList");
            }
          });
        },
        onCancel: () => {},
      });
    },
    cancel() {
      this.$emit("monitorList");
      this.$set(this.item, "editShow", !this.item.editShow);
    },
    deleteCondition(index) {
      this.conditionList.splice(index, 1);
      if (this.conditionList.length == 0) {
        this.addCondition();
      }
    },
    deleteAction(index) {
      this.actionList.splice(index, 1);
      if (this.actionList.length == 0) {
        this.actionList.push({ type: undefined, value: "" });
      }
    },
    addCondition() {
      this.conditionList.push({ type: undefined, value: "" });
    },
    add() {
      if (this.property.length > 0) {
        let flag = true;
        this.propertyList.forEach((property) => {
          this.property.forEach((item) => {
            if (item == property.attributeId) {
              flag = false;
            }
          });
        });
        if (flag) {
          this.propertySelectList.forEach((property) => {
            this.property.forEach((item) => {
              if (item == property.id) {
                this.propertyList.push({
                  attributeId: property.id,
                  value: property.defaultValue,
                  noValue: property.noValue,
                });
              }
            });
          });
        } else {
          this.$message.warning("请勿重复添加相同的属性");
        }
      } else {
        this.$message.warning("请先选择要添加的属性");
      }
      this.property.splice(0);
    },
    deleteProperty(index) {
      this.propertyList.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.previewList {
  max-height: 550px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
.desc {
  background-color: #f7f7f7;
  border-radius: 5px;

  .desc_header {
    padding: 10px 20px;
    border-bottom: 1px solid #e5e5e5;
    overflow: auto;
  }

  .desc_content {
    padding: 10px 20px;
  }
}
.rule {
  border: 1px solid #dedede;
  background-color: #fff;
  min-height: 200px;
  padding: 10px;
  position: relative;
  overflow: auto;

  &:hover {
    box-shadow: 0 4px 16px 0 rgb(0 0 0 / 15%);
  }

  .ruleHeader {
    color: black;
    font-size: 16px;
    position: relative;

    .button {
      color: #888;
      float: right;
      padding: 0 5px;
      cursor: pointer;
    }

    .icon {
      position: absolute;
      top: 0;
      left: 40%;
      font-size: 14px;
      color: #868686;
      font-weight: 500;
    }
  }

  .ruleContent {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    .flexItem {
      width: 49.5%;
      min-height: 65px;
      padding: 10px;
      color: black;

      .item {
        background-color: #f7f7f7;
        padding: 10px;
        margin-bottom: 2px;

        .col {
          width: 100%;
          background-color: #f7f7f7;
          padding-bottom: 10px;
          .ant-switch {
            background-color: #52c41a !important;
          }
          .ant-switch-checked {
            background-color: #1890ff !important;
          }
          .textarea {
            vertical-align: top;
            margin-top: 2px;
          }

          .label {
            display: inline-block;
            color: #888;
            margin-right: 10px;
            font-size: 14px;
          }
        }
      }
    }
  }

  .bottomButton {
    float: right;
  }

  .remark {
    margin-left: 15px;
  }
}

.select {
  border: 1px solid #0070cc;
}

.editBorder {
  border: 2px dashed #0070cc;
}
</style>
<style lang="scss">
.property {
  .ant-tooltip-arrow {
    &::before {
      background-color: #fff !important;
    }
  }

  .ant-tooltip-inner {
    width: 470px;
    background-color: #fff !important;
    box-shadow: none !important;
    box-shadow: 0 4px 16px 0 rgb(0 0 0 / 15%) !important;

    .item {
      background-color: #f7f7f7;
      padding: 10px;
      margin-bottom: 2px;
      color: black;

      .col {
        width: 100%;
        background-color: #f7f7f7;
        padding-bottom: 10px;

        .textarea {
          vertical-align: top;
          margin-top: 2px;
        }

        .label {
          display: inline-block;
          color: #888;
          margin-right: 10px;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
