<template>
  <div class="timeControlModelList">
    <div class="searchButton">
      <a-button type="primary" @click="query()" icon="search">查询</a-button>
      <a-button type="primary" @click="add" icon="plus">新增</a-button>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="false"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="action" slot-scope="text, record">
        <a-button type="link" style="padding: 0" @click="modifyClick(record)"
          >修改</a-button
        >
        <a-divider type="vertical" />
        <a-button type="link" style="padding: 0" @click="detail(record)"
          >详情</a-button
        >
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>

    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="nameAdd" placeholder="请输入名称" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="发布时间"
      v-model="detailVisible"
      :maskClosable="false"
      width="800px"
      :afterClose="query"
    >
      <div style="margin-bottom: 20px">
        <a-button type="primary" @click="addTime()" icon="plus">新增</a-button>
      </div>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(detailColumns)"
        :columns="detailColumns"
        :dataSource="detailList"
        :pagination="false"
        :rowKey="(record) => record.id"
        size="small"
      >
        <span slot="time" slot-scope="text, record">
          {{ record.week }} | {{ text }}
        </span>
        <span slot="action" slot-scope="text, record">
          <a-button type="link" style="padding: 0" @click="editTime(record)"
            >修改</a-button
          >
          <a-divider type="vertical" />
          <a-popconfirm
            placement="right"
            okText="确认"
            cancelText="取消"
            @confirm="deleteTime(record)"
          >
            <template slot="title">确认是否删除</template>
            <a href="javascript:;" style="color: #ff4d4f">删除</a>
          </a-popconfirm>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="detailVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="detailTitle"
      v-model="detailEditVisible"
      :maskClosable="false"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="星期"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="dayOfWeek"
            style="width: 250px"
            placeholder="请选择星期"
          >
            <a-select-option
              v-for="item in weekList"
              :key="item.value"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="时间:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-time-picker v-model="timeStart" placeholder="开始时间">
          </a-time-picker>
          <i style="display: inline-block; margin-left: 20px"></i>
          <a-time-picker v-model="timeEnd" placeholder="结束时间">
          </a-time-picker>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="detailEditVisible = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addTime_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/timeControlModelList.js";
import moment from "moment";

export default {
  name: "timeControlModelList",
  data() {
    return {
      title: "",
      modelId: "",
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "发布时间",
          ellipsis: true,
          dataIndex: "time",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      loadingTable: false,
      dayOfWeek: undefined,
      weekList: [
        { label: "周一", value: 1 },
        { label: "周二", value: 2 },
        { label: "周三", value: 3 },
        { label: "周四", value: 4 },
        { label: "周五", value: 5 },
        { label: "周六", value: 6 },
        { label: "周日", value: 7 },
      ],
      timeStart: "",
      timeEnd: "",
      editTimeId: "",
      detailList: [],
      detailColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "发布时间",
          ellipsis: true,
          dataIndex: "time",
          scopedSlots: { customRender: "time" },
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      detailEditVisible: false,
      detailVisible: false,
      detailTitle: "",
      nameAdd: "",
    };
  },
  computed: {
    formatEndTime() {
      // 开始时间
      if (this.timeEnd) {
        return this.$common.transformTime(this.timeEnd).split(" ")[1];
      }
      return "";
    },
    formatStartTime() {
      // 结束时间
      if (this.timeStart) {
        return this.$common.transformTime(this.timeStart).split(" ")[1];
      }
      return "";
    },
  },
  mounted() {
    this.query();
  },
  methods: {
    // 查询列表
    query() {
      this.loadingTable = true;
      api
        .queryPage()
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource = res.data;
            this.tableDataSource.forEach((data) => {
              let time = [];
              data.detailList.forEach((item) => {
                this.weekList.forEach((week) => {
                  if (item.dayOfWeek == week.value) {
                    time.push(
                      week.label + " | " + item.timeStart + " - " + item.timeEnd
                    );
                  }
                });
              });
              this.$set(data, "time", time.join(","));
            });
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.groupId = val.id;
      this.nameAdd = val.name;
      this.addKeyVisible = true;
    },
    add() {
      this.title = "新增";
      this.nameAdd = "";
      this.addKeyVisible = true;
    },
    // 确定新增
    add_submit() {
      let data = {
        name: this.nameAdd,
      };
      if (this.title === "新增") {
        api.addData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.id = this.groupId;
        api.editData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteData(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    detail(val) {
      if (val) {
        this.modelId = val.id;
      }
      api.timeControlModelDetailList({ modelId: this.modelId }).then((res) => {
        if (res.result == 200) {
          this.detailList = res.data;
          this.detailList.forEach((item) => {
            this.$set(item, "time", item.timeStart + " - " + item.timeEnd);
            this.weekList.forEach((week) => {
              if (item.dayOfWeek == week.value) {
                this.$set(item, "week", week.label);
              }
            });
          });
          this.detailVisible = true;
        }
      });
    },
    addTime() {
      this.detailTitle = "新增";
      this.environmentId = undefined;
      this.dayOfWeek = undefined;
      this.timeStart = moment("16:00:00", "HH:mm:ss");
      this.timeEnd = moment("23:59:59", "HH:mm:ss");
      this.detailEditVisible = true;
    },
    editTime(val) {
      this.detailTitle = "修改";
      this.editTimeId = val.id;
      this.environmentId = val.environmentId;
      this.dayOfWeek = val.dayOfWeek;
      if (val.timeStart) {
        this.timeStart = moment(val.timeStart, "HH:mm:ss");
      } else {
        this.timeStart = null;
      }
      if (val.timeEnd) {
        this.timeEnd = moment(val.timeEnd, "HH:mm:ss");
      } else {
        this.timeEnd = null;
      }
      this.detailEditVisible = true;
    },
    addTime_submit() {
      this.loading = true;
      let data = {
        dayOfWeek: this.dayOfWeek,
        timeStart: this.formatStartTime,
        timeEnd: this.formatEndTime,
      };
      if (this.detailTitle == "新增") {
        data.modelId = this.modelId;
        api.addTimeControlModelDetail(data).then((res) => {
          if (res.result == 200) {
            this.$message.success("添加成功");
            this.detailEditVisible = false;
            this.loading = false;
            this.detail();
          }
        });
      } else {
        data.id = this.editTimeId;
        api.editTimeControlModelDetail(data).then((res) => {
          if (res.result == 200) {
            this.$message.success("修改成功");
            this.detailEditVisible = false;
            this.loading = false;
            this.detail();
          }
        });
      }
    },
    deleteTime(val) {
      let data = {
        id: val.id,
      };
      api.deleteTimeControlModelDetail(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.detail();
        }
      });
    },
  },
};
</script>
