<template>
  <div class="serverPortInfoList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        服务器id：
        <a-select
          v-model="serverId"
          placeholder="请选择服务器"
          showSearch
          option-filter-prop="children"
          style="width: 300px"
          allowClear
        >
          <a-select-option v-for="item in serverIdList" :key="item.id">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        端口号：
        <a-input
          placeholder="请输入端口号"
          v-model.trim="port"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        备注：
        <a-input
          placeholder="请输入备注"
          v-model.trim="remark"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        是否系统使用：
        <a-checkbox v-model="system" @change="query()" />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="instanceName" slot-scope="text, record" style="width: 100%">
        {{ record.instanceId }}<br />{{ text }}
      </span>
      <span slot="system" slot-scope="text" style="width: 100%">
        <a-tag color="green" v-if="text">是</a-tag>
        <a-tag color="red" v-else>否</a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="服务器:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="serverIdAdd"
            placeholder="请选择服务器"
            showSearch
            option-filter-prop="children"
            style="width: 300px"
            allowClear
          >
            <a-select-option v-for="item in serverIdList" :key="item.id">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="端口号:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="portAdd" placeholder="请输入端口号" />
        </a-form-item>
        <a-form-item
          label="是否系统使用:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-switch
            v-model="systemAdd"
            checkedChildren="是"
            unCheckedChildren="否"
            @change="statusOnChange(record)"
          />
        </a-form-item>
        <a-form-item
          label="备注:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="remarkAdd" placeholder="请输入备注" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import api from "../lib/serverPortInfoList.js";
import { serverList } from "../lib/serverList.js";

export default {
  name: "serverPortInfoList",
  data() {
    return {
      title: "",
      serverId: undefined,
      serverIdAdd: undefined,
      port: undefined,
      portAdd: undefined,
      system: undefined,
      systemAdd: undefined,
      remark: "",
      remarkAdd: "",
      groupId: "",
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "服务器实例名称",
          ellipsis: true,
          dataIndex: "instanceName",
          scopedSlots: { customRender: "instanceName" },
        },
        {
          title: "端口号",
          ellipsis: true,
          dataIndex: "port",
        },
        {
          title: "是否系统使用",
          ellipsis: true,
          dataIndex: "system",
          scopedSlots: { customRender: "system" },
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      serverIdList: [],
    };
  },
  mounted() {
    this.getServerAll();
  },
  methods: {
    getServerAll() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        enabled: true,
      };
      serverList(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item) => {
            item.title = item.publicIp + "(" + item.instanceName + ")";
          });
          this.serverIdList = list;
          this.query(1);
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        serverId: this.serverId,
        port: this.port,
        system: this.system,
        remark: this.remark,
      };
      this.queryPage(data);
    },
    // 查询列表
    queryPage(data) {
      this.loadingTable = true;
      api
        .queryPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tableDataSource.forEach((item) => {
              this.$set(item, "instanceName", "");
              this.$set(item, "instanceId", "");
              this.serverIdList.forEach((server) => {
                if (item.serverId == server.id) {
                  this.$set(item, "instanceName", server.instanceName);
                  this.$set(item, "instanceId", server.instanceId);
                }
              });
            });
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.addKeyVisible = true;
      this.groupId = val.id;
      this.serverIdAdd = val.serverId;
      this.portAdd = val.port;
      this.systemAdd = val.system;
      this.remarkAdd = val.remark;
    },
    // 新增
    add() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.serverIdAdd = undefined;
      this.portAdd = "";
      this.systemAdd = false;
      this.remarkAdd = "";
    },
    // 确定新增
    add_submit() {
      let data = {
        serverId: this.serverIdAdd,
        port: this.portAdd,
        system: this.systemAdd,
        remark: this.remarkAdd,
      };
      if (this.title === "新增") {
        api.addData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.id = this.groupId;
        api.editData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.groupId = "";
      this.serverIdAdd = undefined;
      this.portAdd = undefined;
      this.systemAdd = undefined;
      this.remarkAdd = "";
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteData(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
  },
};
</script>
