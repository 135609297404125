import { render, staticRenderFns } from "./serverPortInfoList.vue?vue&type=template&id=a9f4b346"
import script from "./serverPortInfoList.vue?vue&type=script&lang=js"
export * from "./serverPortInfoList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports