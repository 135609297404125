<template>
  <div class="serverGroupItemList" @click="visible = false">
    <div>
      <span @click="back()" class="back">
        <icon-font type="icon-arrow-left" />
        {{ groupName }}
      </span>
    </div>
    <div class="searchButton">
      <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      <a-button type="primary" @click.stop="add" icon="plus">新增</a-button>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      :rowKey="(record) => record.id"
    >
      <span slot="action" slot-scope="text, record">
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">删除将立即生效, 确认是否操作</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <div :class="{ addDetail: true, visible }" @click.stop="visible = true">
      <header class="header">
        <span class="title">添加后端服务器</span>
        <span
          style="float: right; font-size: 20px; cursor: pointer"
          @click.stop="visible = false"
        >
          <a-icon type="close" style="vertical-align: top" />
        </span>
      </header>
      <div style="padding: 10px 20px; overflow: auto">
        <a-select
          v-model="serverId"
          showSearch
          placeholder="请选择服务器"
          :filter-option="false"
          @search="instanceIdSearch"
          allowClear
          @change="serverIdSearch"
          style="width: 240px"
        >
          <a-select-option v-for="item in instanceList" :key="item.id">{{
            item.instanceName
          }}</a-select-option>
        </a-select>
      </div>
      <div
        style="
          padding: 10px 20px;
          height: calc(100vh - 141px);
          overflow-y: scroll;
        "
      >
        <a-table
          :rowClassName="$common.rowClassColor"
          bordered
          :components="$common.getTitle(nginxTableColumns)"
          :columns="nginxTableColumns"
          :dataSource="nginxTableDataSource"
          :pagination="nginxTablePagination"
          :loading="loadingStatusTable"
          :rowKey="(record) => record.id"
          :row-selection="{
            onChange: onSelectChange,
            selectedRowKeys: selectedRowKeys,
          }"
        >
          <span slot="enabled" slot-scope="text" style="width: 100%">
            <a-tag v-if="text" color="green"> 已启用 </a-tag>
            <a-tag v-if="!text" color="red"> 已禁用 </a-tag>
          </span>
        </a-table>
      </div>
      <footer class="footer">
        <a-button
          type="primary"
          @click.stop="add_submit"
          :disabled="!hasSelected"
          >确定</a-button
        >
        <a-button key="back" @click.stop="visible = false">取消</a-button>
      </footer>
    </div>
  </div>
</template>

<script>
import api from "../lib/serverGroupItemList.js";
import editableCell from "./editableCell.vue";
import nginxInstanceApi from "../lib/nginxInstance.js";
import { serverList } from "../lib/trustList.js";

export default {
  name: "serverGroupItemList",
  components: {
    editableCell,
  },
  data() {
    return {
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "服务器名称",
          align: "center",
          ellipsis: true,
          dataIndex: "serverName",
        },
        {
          title: "外网IP",
          align: "center",
          ellipsis: true,
          dataIndex: "publicIp",
        },
        {
          title: "内网IP",
          align: "center",
          ellipsis: true,
          dataIndex: "privateIp",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      groupId: "",
      groupName: "",
      pageNo: 1,
      visible: false,
      stepCurrent: 0,
      statusList: [],
      projectName: "",
      loadingStatusTable: false,
      nginxTableDataSource: [],
      nginxTablePagination: {},
      nginxTableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "外网ip",
          ellipsis: true,
          dataIndex: "publicIp",
        },
        {
          title: "内网ip",
          ellipsis: true,
          dataIndex: "privateIp",
        },
        {
          title: "启用状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
      ],
      nginxPageNo: 1,
      selectedRowKeys: [],
      serverId: undefined,
      instanceList: [],
    };
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  created() {
    if (this.$route.query.groupId) {
      this.groupId = this.$route.query.groupId * 1;
      this.groupName = this.$route.query.groupName;
      this.query(1);
    }
    this.getNginxQueryPage();
    this.getServerList();
  },
  methods: {
    instanceIdSearch(instanceName) {
      this.getServerList(instanceName);
    },
    getServerList(instanceName) {
      let data = {
        instanceName: instanceName,
        enabled: true,
      };
      serverList(data).then((res) => {
        if (res.result === 200) {
          this.instanceList = res.data;
        }
      });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
    },
    serverIdSearch() {
      this.getNginxQueryPage();
    },
    getNginxQueryPage(index) {
      if (index) {
        this.nginxPageNo = index;
      }
      this.loadingStatusTable = true;
      let data = {
        pageNo: this.nginxPageNo,
        pageSize: 10,
        serverId: this.serverId,
      };
      nginxInstanceApi.queryPage(data).then((res) => {
        if (res.result == 200) {
          let list = res.data.records;
          this.nginxTableDataSource = list;
          this.loadingStatusTable = false;
          this.nginxTablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.changeStatusPageItem(current),
          };
        }
      });
    },
    changeStatusPageItem(index) {
      this.getStatus("", index);
    },
    next() {
      this.stepCurrent++;
    },
    previous() {
      this.stepCurrent--;
    },
    add_submit() {
      let data = {
        groupId: this.groupId,
        nginxInstanceIdList: this.selectedRowKeys,
      };
      api.addData(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("添加成功");
          this.visible = false;
          this.query(1);
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
    query(index) {
      this.loadingTable = true;
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        groupId: this.groupId,
      };
      api.queryPage(data).then((res) => {
        if (res.result === 200) {
          this.tableDataSource = res.data.records;
          this.tablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.changePageItem(current),
          };
          this.loadingTable = false;
        }
      });
    },
    changePageItem(index) {
      this.query(index);
    },
    add() {
      this.visible = true;
      this.stepCurrent = 0;
      this.selectedRowKeys.splice(0);
    },
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteData(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query(1);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.serverGroupItemList {
  .back {
    display: inline-block;
    cursor: pointer;
    font-size: 20px;
    color: black;
    font-weight: 500;
    padding-bottom: 10px;
  }
  .addDetail {
    position: fixed;
    right: -900px;
    top: 0;
    height: 100vh;
    width: 900px;
    background-color: #fff;
    z-index: 1002;
    box-shadow: 0 4px 16px 0 rgb(0 0 0 / 30%);
    transition: all 0.5s;
    .header {
      border-bottom: 1px solid #e5e5e5;
      padding: 20px;
      .title {
        font-size: 18px;
        font-weight: 500;
      }
    }
    .step {
      padding: 20px;
    }
    .footer {
      border-top: 1px solid #e5e5e5;
      padding: 20px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 900px;
      z-index: 1003;
      background-color: #fff;
      button {
        margin-right: 20px;
      }
    }
  }
  .visible {
    right: 0;
  }
}
</style>
