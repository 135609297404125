<template>
  <div class="outerApi">
    <div class="searchCondition">
      <div class="searchConditionItem">
        用户：
        <a-input
          placeholder="请输入用户"
          v-model.trim="userName"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        备注：
        <a-input
          placeholder="请输入备注"
          v-model.trim="remark"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addTaskVisible = true" icon="plus"
          >新增</a-button
        >
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="edit(record)">编辑</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="editApi(1, record)">编辑接口</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="copyUrl(record)">复制路径</a>
        <a-divider type="vertical" />
        <a-dropdown>
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            其它 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a-button
                type="link"
                style="padding: 0"
                @click="detail(1, record)"
                >详情列表</a-button
              >
            </a-menu-item>
            <a-menu-item>
              <a-popconfirm
                placement="right"
                okText="确认"
                cancelText="取消"
                @confirm="deleteData(record)"
              >
                <template slot="title">确认是否删除{{ record.name }}</template>
                <a href="javascript:;" style="color: #ff4d4f">删除</a>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>
    <a-modal
      title="新增项目"
      v-model="addTaskVisible"
      :maskClosable="false"
      :after-close="addTaskClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="项目:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select v-model="projectId" placeholder="请选择项目" allowClear>
            <a-select-option
              v-for="(i, index) in projectList"
              :key="index"
              :value="i.id"
            >
              {{ i.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="用户:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input placeholder="请输入用户" v-model="name" />
        </a-form-item>
        <a-form-item
          label="备注:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input placeholder="请输入备注" v-model="remarkAdd" />
        </a-form-item>
        <a-form-item
          label="附加信息:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-textarea
            placeholder="请输入附加信息"
            v-model="content"
            type="textarea"
            :row="10"
          ></a-textarea>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addTaskVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addTask_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <a-modal
      title="分享详情列表"
      v-model="shareTaskVisible"
      :maskClosable="false"
      :after-close="shareTaskClose"
      width="1000px"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(detailTableColumns)"
        :columns="detailTableColumns"
        :dataSource="detailTableDataSource"
        :pagination="detailTablePagination"
        :loading="detailLoadingTable"
        size="small"
        :rowKey="(record) => record.id"
      >
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="shareTaskVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
    <a-modal
      title="编辑接口"
      v-model="editApiTaskVisible"
      :maskClosable="false"
      :after-close="editApiTaskClose"
      width="1000px"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(editTableColumns)"
        :columns="editTableColumns"
        :dataSource="editTableDataSource"
        :pagination="editTablePagination"
        :loading="editLoadingTable"
        size="small"
        :rowKey="(record) => record.id"
        :row-selection="{
          onChange: onSelectChange,
          selectedRowKeys: selectedRowKeys,
        }"
      >
        <span slot="method" slot-scope="text, record">
          <a-tag v-if="text == 'GET'" color="green">
            {{ text }}
          </a-tag>
          <a-tag v-else color="blue">
            {{ text }}
          </a-tag>
          {{ record.path }}
        </span>
      </a-table>
      <a-button
        type="danger"
        @click="addIds"
        :disabled="!hasSelected"
        icon="plus"
        >批量添加</a-button
      >
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="editApiTaskVisible = false"
            >关闭</a-button
          >
        </div>
      </template>
    </a-modal>
    <a-modal
      title="编辑"
      v-model="editTaskVisible"
      :maskClosable="false"
      :after-close="editTaskClose"
      width="500px"
    >
      <a-form-item
        label="用户:"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-input placeholder="请输入用户" v-model="name" />
      </a-form-item>
      <a-form-item
        label="附加信息:"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-textarea
          placeholder="请输入附加信息"
          v-model="content"
          type="textarea"
          :row="10"
        ></a-textarea>
      </a-form-item>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="editTaskVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="edit_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import api from "../lib/outerApi.js";
import innerApi from "../lib/innerApi.js";
import yapi from "../lib/yapi";

export default {
  name: "outerApi",
  data() {
    return {
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "用户",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "附加信息",
          ellipsis: true,
          dataIndex: "content",
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      detailLoadingTable: false,
      editLoadingTable: false,
      addTaskVisible: false,
      loading: false,
      projectList: [],
      projectId: undefined,
      name: "",
      detailTablePagination: {},
      detailTableDataSource: [],
      detailTableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "接口名称",
          ellipsis: true,
          dataIndex: "interfaceName",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
      ],
      detailPageNo: 1,
      editPageNo: 1,
      shareId: "",
      shareTaskVisible: false,
      editApiTaskVisible: false,
      editTablePagination: {},
      editTableDataSource: [],
      editTableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "接口名称",
          ellipsis: true,
          dataIndex: "title",
        },
        {
          title: "路径",
          ellipsis: true,
          dataIndex: "method",
          scopedSlots: { customRender: "method" },
        },
      ],
      yapiProjectId: "",
      selectedRowKeys: [],
      content: "",
      editTaskVisible: false,
      editId: "",
      userName: "",
      remark: "",
      remarkAdd: "",
    };
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  mounted() {
    this.query(1);
    this.queryProjectList(1);
  },
  methods: {
    edit_submit() {
      let data = {
        name: this.name,
        content: this.content,
        id: this.editId,
      };
      api.editShare(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("编辑成功");
          this.editTaskVisible = false;
          this.query(1);
        }
      });
    },
    editTaskClose() {
      this.name = "";
      this.content = "";
      this.editId = "";
    },
    edit(val) {
      this.name = val.name;
      this.content = val.content;
      this.editId = val.id;
      this.editTaskVisible = true;
    },
    copyUrl(record) {
      let url;
      if (process.env.VUE_APP_CURRENTMODE == "prod") {
        url = location.protocol + "//delivery.huiyu.org.cn";
      } else {
        url = "http://localhost:8080";
      }
      url =
        url +
        `/allApiList?projectId=${record.yapiProjectId}&&uuid=${record.uuid}`;
      this.copy(url);
    },
    copy(data) {
      const textarea = document.createElement("textarea");
      textarea.readOnly = "readonly";
      textarea.style.position = "absolute";
      textarea.style.left = "-9999px";
      textarea.value = data;
      document.body.appendChild(textarea);
      textarea.select();
      const result = document.execCommand("Copy");
      if (result) {
        this.$message.success("复制成功");
      }
      document.body.removeChild(textarea);
    },
    addIds() {
      let data = {
        id: this.shareId,
        interfaceIdList: this.selectedRowKeys,
      };
      api.editShareApi(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("编辑成功");
          this.editApiTaskVisible = false;
          this.query(1);
        }
      });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      // this.selectedRowKeys.splice(0);
      // selectedRows.forEach((item) => {
      //   this.selectedRowKeys.push(item.id);
      // });
    },
    editApi(index, val) {
      if (index) {
        this.editPageNo = index;
      }
      if (val) {
        this.yapiProjectId = val.yapiProjectId;
        this.shareId = val.id;
        if (val.interfaceIdList) {
          this.selectedRowKeys.push(...val.interfaceIdList);
        }
      }
      let data = {
        page: this.editPageNo,
        limit: 10,
        projectId: this.yapiProjectId,
      };
      this.editLoadingTable = true;
      this.editTableDataSource.splice(0);
      yapi
        .queryPage(data)
        .then((res) => {
          this.editLoadingTable = false;
          if (res.result == 200) {
            this.editTableDataSource.push(...res.data.records);
            this.editTablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: 10,
              current: this.editPageNo,
              total: res.data.total,
              onChange: (current) => this.editChangePageItem(current),
            };
            this.editApiTaskVisible = true;
          }
        })
        .catch((err) => {
          this.editLoadingTable = false;
        });
    },
    editChangePageItem(index) {
      this.editApi(index);
    },
    editApiTaskClose() {
      this.editPageNo = 1;
      this.selectedRowKeys.splice(0);
    },
    shareTaskClose() {
      this.detailTableDataSource.splice(0);
      this.detailPageNo = 1;
    },
    queryProjectList(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        page: this.pageNo,
        limit: 100,
      };
      this.getProjectList(data);
    },
    getProjectList(data) {
      this.loadingTable = true;
      this.projectList.splice(0);
      innerApi
        .queryPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result == 200) {
            this.projectList.push(...res.data.records);
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    deleteData(val) {
      api.deleteData({ id: val.id }).then((res) => {
        if (res.result == 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    addTaskClose() {
      this.projectId = undefined;
      this.name = "";
    },
    addTask_submit() {
      let data = {
        yapiProjectId: this.projectId,
        name: this.name,
        content: this.content,
        remark: this.remarkAdd,
      };
      api.addData(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("添加成功");
          this.addTaskVisible = false;
          this.query();
        }
      });
    },
    detail(index, val) {
      if (index) {
        this.detailPageNo = index;
      }
      if (val) {
        this.shareId = val.id;
      }
      let data = {
        pageNo: this.detailPageNo,
        pageSize: 10,
        shareId: this.shareId,
      };
      this.detailLoadingTable = true;
      this.detailTableDataSource.splice(0);
      api
        .detailPage(data)
        .then((res) => {
          this.detailLoadingTable = false;
          if (res.result == 200) {
            this.detailTableDataSource.push(...res.data.records);
            this.detailTablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.detailChangePageItem(current),
            };
            this.shareTaskVisible = true;
          }
        })
        .catch((err) => {
          this.detailLoadingTable = false;
        });
    },
    detailChangePageItem(index) {
      this.detail(index);
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        name: this.userName,
        remark: this.remark,
      };
      this.queryPage(data);
    },
    // 查询代码生成列表
    queryPage(data) {
      this.loadingTable = true;
      api
        .queryPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result == 200) {
            this.tableDataSource = res.data.records;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
  },
};
</script>
<style>
.code_style .CodeMirror {
  height: 600px;
}
</style>
<style lang="scss" scoped>
::v-deep .ant-col-16 {
  width: 78%;
  margin-top: 5px;
  .ant-form-item-children {
    display: flex;
    .select-tag {
      width: 270px;
      margin-right: 20px;
    }
  }
}
</style>
