import http from "./axios";

// 查询告警组分页列表
export const alarmLogPage = ({ groupId, pageNo, pageSize }) => {
  return http.get("/alarmGroup/alarmLogPage", {
    groupId: groupId,
    pageNo: pageNo,
    pageSize: pageSize,
  });
};

// 告警组集合
export const groupList = () => {
  return http.get("/alarmGroup/groupList");
};

// 新增告警组成员
export const markAsRead = ({ logId }) => {
  return http.json_post("/alarmGroup/markAsRead", {
    logId: logId,
  });
};

// 批量删除
export const deleteAlarmLog = ({ idList }) => {
  return http.json_post("/alarmGroup/deleteAlarmLog", {
    idList: idList,
  });
};
