<template>
  <div class="loadingDialog" v-if="visible">
    <div class="loading">
      <div class="download-div">
        <a-spin size="large" class="loadingClass" />
        <span class="download-text">{{ message }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "mLoading",
  data() {
    return {
      message: "请稍后...",
      visible: false,
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
.loadingDialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
}
.loadingDialog .loading {
  width: 300px;
  height: 100px;
  border-radius: 20px;
  position: fixed;
  top: 40%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.download-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
}
.download-text {
  display: inline-block;
  position: relative;
  font-size: 17px;
  width: 170px;
  margin-left: 10px;
}
.loadingClass {
  font-size: 40px;
  color: #409eff;
}
</style>


