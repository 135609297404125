import http from "./axios";

// 查询所有cluster
export const clusterList = ({ clusterId }) => {
  return http.get("/rocketmq/dashboard/cluster/list", { clusterId });
};

// 查询指定broker的配置信息
export const brokerConfig = ({ brokerAddr, clusterId }) => {
  return http.get("/rocketmq/dashboard/cluster/brokerConfig", {
    brokerAddr,
    clusterId,
  });
};

// 获取近7天的消息产生量
export const sevenDayProduceInfo = ({ clusterId }) => {
  return http.get("/rocketmq/dashboard/topic/sevenDayProduceInfo", {
    clusterId,
  });
};
