<template>
  <div ref="flameGraph" class="flameGraphFile">
    <div style="width: 400px">
      <a-upload-dragger
        name="file"
        :multiple="true"
        :showUploadList="false"
        :customRequest="customRequest"
      >
        <p class="ant-upload-drag-icon">
          <a-icon type="inbox" />
        </p>
        <p class="ant-upload-text">上传文件</p>
        <p class="ant-upload-hint">生成火焰图分析数据</p>
      </a-upload-dragger>
    </div>
    <div id="chart"></div>
  </div>
</template>

<script>
import * as api from "../lib/flameGraph";
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    customRequest(info) {
      let data = { file: info.file };
      api.uploadFileFlameGraphs(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("上传成功");
          this.init(res.data);
        }
      });
    },
    init(data) {
      var clientWidth = this.$refs.flameGraph.clientWidth;
      let svgArr = document.getElementsByClassName("d3v4-flame-graph-tip");
      let tipArr = document.getElementsByClassName("d3v4-flame-graph");
      if (svgArr.length > 0) {
        for (let i = 0; i < svgArr.length; i++) {
          const element = svgArr[i];
          element.remove();
        }
      }
      if (tipArr.length > 0) {
        for (let i = 0; i < tipArr.length; i++) {
          const element = tipArr[i];
          element.remove();
        }
      }
      let flameGraph = d3v4
        .flameGraph()
        .width(clientWidth)
        .cellHeight(18)
        .transitionDuration(0)
        .transitionEase(d3v4.easeCubic)
        .sort(true)
        .title("");

      let tip = d3v4
        .tip()
        .direction("s")
        .offset([8, 0])
        .attr("class", "d3v4-flame-graph-tip")
        .html(function (d) {
          return d.data.name + ", thread count: " + d.data.value;
        });
      flameGraph.tooltip(tip);
      d3v4.select("#chart").datum(data).call(flameGraph);
      document.getElementById("HomeContent").scrollTop =
        document.getElementById("HomeContent").scrollHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/d3.flameGraph.min.css";
.flameGraphFile {
  width: 100%;

  #chart {
    margin-top: 20px;
  }
}
</style>
