import http from "./axios";

export const logPage = ({ pageNo, pageSize, userLoginName, serverId }) => {
  return http.get("/shellLog/logPage", {
    pageNo,
    pageSize,
    userLoginName,
    serverId,
  });
};

export const serverList = ({
  pageNo,
  pageSize,
  enabled,
  instanceName,
  ip,
  isAliyun,
}) => {
  return http.get("/delivery/server/serverList", {
    pageNo,
    pageSize,
    enabled,
    instanceName,
    ip,
    isAliyun,
  });
};

export const serverAccountList = ({ serverId }) => {
  return http.get("/delivery/serverAccount/serverAccountList", {
    serverId,
  });
};

export const userList = ({ name }) => {
  return http.get("/delivery/user/list", { name });
};

export const getCopyShareLinkData = ({ id }) => {
  return http.get("/shellLog/getCopyShareLinkData", { id });
};

export const deleteLog = ({ id }) => {
  return http.json_post("/shellLog/deleteLog", { id });
};

export const addShare = ({
  userLoginName,
  serverId,
  serverAccountId,
  shareValidSeconds,
}) => {
  return http.json_post("/shellLog/addShare", {
    userLoginName,
    serverId,
    serverAccountId,
    shareValidSeconds,
  });
};
