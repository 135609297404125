import http from "./axios";

// 查询错误日志列表
export const errorLogList = (data) => {
  return http.get("/delivery/errorLog/infoPage", data);
};

// 查询错误日志详情集合
export const itemList = ({ errorLogId }) => {
  return http.get("/delivery/errorLog/itemList", {
    errorLogId: errorLogId,
  });
};

// 删除错误日志
export const deleteLog = ({ id }) => {
  return http.get("/delivery/errorLog/deleteLog", {
    id: id,
  });
};

// 批量删除错误日志
export const batchDeleteLog = ({ idList }) => {
  return http.json_post("/delivery/errorLog/batchDeleteLog", {
    idList: idList,
  });
};

export const distributionWorkTask = ({ id, executorList }) => {
  return http.json_post("/delivery/errorLog/distributionWorkTask", {
    id,
    executorList,
  });
};

// 已读
export const read = ({ errorLogId }) => {
  return http.get("/delivery/errorLog/read", {
    errorLogId: errorLogId,
  });
};

// 查询环境
export const environmentList = () => {
  return http.get("/delivery/environment/environmentList", {});
};
