import http from "./axios";

// 查询安全组列表
export const securityGroupList = ({ pageNo, pageSize, securityGroupName }) => {
  return http.get("/delivery/securityGroup/list", {
    pageNo: pageNo,
    pageSize: pageSize,
    securityGroupName: securityGroupName,
  });
};

// 查询安全组
export const securityGroupItemList = ({ securityGroupId, direction }) => {
  return http.get("/delivery/securityGroup/itemList", {
    securityGroupId: securityGroupId,
    direction: direction,
  });
};

// 安全组数据同步
export const syncSecurityGroup = () => {
  return http.get("/delivery/securityGroup/sync");
};

// 安全组规则新增
export const addSecurityGroupItem = ({
  securityGroupId,
  ipProtocol,
  portRange,
  sourcePortRange,
  sourceGroupId,
  sourceGroupName,
  sourceCidrIp,
  ipv6SourceCidrIp,
  policy,
  nicType,
  sourceGroupOwnerAccount,
  destGroupId,
  destGroupName,
  destCidrIp,
  ipv6DestCidrIp,
  destGroupOwnerAccount,
  priority,
  direction,
  description,
}) => {
  return http.json_post("/delivery/securityGroup/addSecurityGroupItem", {
    securityGroupId: securityGroupId,
    ipProtocol: ipProtocol,
    portRange: portRange,
    sourcePortRange: sourcePortRange,
    sourceGroupId: sourceGroupId,
    sourceGroupName: sourceGroupName,
    sourceCidrIp: sourceCidrIp,
    ipv6SourceCidrIp: ipv6SourceCidrIp,
    policy: policy,
    nicType: nicType,
    sourceGroupOwnerAccount: sourceGroupOwnerAccount,
    destGroupId: destGroupId,
    destGroupName: destGroupName,
    destCidrIp: destCidrIp,
    ipv6DestCidrIp: ipv6DestCidrIp,
    destGroupOwnerAccount: destGroupOwnerAccount,
    priority: priority,
    direction: direction,
    description: description,
  });
};

// 安全组规则删除
export const deleteSecurityGroupItem = ({ id }) => {
  return http.json_post("/delivery/securityGroup/deleteSecurityGroupItem", {
    id: id,
  });
};
