<template>
  <div class="serverConfigTemplate">
    <div class="searchButton">
      <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      <a-button type="primary" @click="addClick" icon="plus">新增</a-button>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="enabled" slot-scope="text, record">
        <a-switch
          checked-children="已启用"
          un-checked-children="已禁用"
          v-model="record.enabled"
          @change="editClick(record, true)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="editClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="modifyTitle"
      v-model="modifyShow"
      :maskClosable="false"
      :afterClose="modifyClose"
      width="1300px"
    >
      <a-form>
        <a-form-item
          label="模版名称:"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 20 }"
          required
        >
          <a-input
            placeholder="请输入模版名称"
            v-model="modifyForm.name"
            allowClear
            style="width: 400px"
          />
        </a-form-item>
        <a-form-item
          label="内容:"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 20 }"
          required
        >
          <!-- <a-textarea id="contentInput" placeholder="请输入内容" v-model="modifyForm.content" allowClear :rows="25"></a-textarea> -->
          <codemirror
            v-if="modifyShow"
            ref="myCode"
            v-model="modifyForm.content"
            :options="temOptions"
            class="code_style"
          >
          </codemirror>
          <div>
            <span>标签：</span>
            <span class="label-c-c-c">
              <a href="javascript:;" @click="labelClick('$INSTANCE_NAME')"
                >$INSTANCE_NAME</a
              >
              <a href="javascript:;" @click="labelClick('$PUBLIC_IP')"
                >$PUBLIC_IP</a
              >
              <a href="javascript:;" @click="labelClick('$INNER_IP')"
                >$INNER_IP</a
              >
            </span>
          </div>
        </a-form-item>
        <a-form-item
          label="启用:"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 20 }"
        >
          <a-switch
            v-model="modifyForm.enabled"
            checked-children="启用"
            un-checked-children="禁用"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="modifyShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="modify_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/serverConfigTemplate.js";
export default {
  name: "serverConfigTemplate",
  data() {
    return {
      temOptions: {
        mode: "text/javascript",
        theme: "default",
        readOnly: false,
        lineNumbers: true,
        line: true,
        styleActiveLine: true, //设置光标所在行高亮，需引入工具包
        placeholder: "输入内容",
      },
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "key",
        },
        {
          title: "模版名称",
          ellipsis: true,
          dataIndex: "name",

          width: 400,
        },
        {
          title: "内容",
          ellipsis: true,
          dataIndex: "content",
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          width: 90,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      modifyForm: {
        name: "",
        content: "",
        enabled: true,
      },
      modifyTitle: "",
      modifyShow: false,
      loading: false,
      id: "",
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    // 点击标签插入内容
    labelClick(val) {
      let pos1 = this.$refs.myCode.codemirror.getCursor();
      let pos2 = {};
      pos2.line = pos1.line;
      pos2.ch = pos1.ch;
      this.$refs.myCode.codemirror.replaceRange(val, pos2);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
      };
      this.getServerConfigTemplateList(data);
    },
    // 查询模版列表
    getServerConfigTemplateList(data) {
      this.loadingTable = true;
      api
        .serverConfigTemplateList(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
            });
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 点击新增
    addClick() {
      this.modifyTitle = "新增";
      this.modifyShow = true;
    },
    // 点击修改
    editClick(val, enable) {
      this.modifyTitle = "【" + val.name + "】修改";
      this.id = val.id;
      this.modifyForm = {
        name: val.name,
        content: val.content,
        enabled: val.enabled,
      };
      if (enable) {
        this.modify_submit();
      } else {
        this.modifyShow = true;
      }
    },
    // 确定新增
    modify_submit() {
      let data = {
        name: this.modifyForm.name,
        content: this.modifyForm.content,
        enabled: this.modifyForm.enabled,
      };
      if (!data.name) {
        this.$message.warning("请输入模版名称");
        return;
      }
      if (!data.content) {
        this.$message.warning("请输入内容");
        return;
      }
      this.loading = true;
      if (this.modifyTitle === "新增") {
        api
          .serverConfigTemplateAdd(data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.$message.success("新增成功");
              this.modifyShow = false;
              this.query(1);
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        data.id = this.id;
        api
          .serverConfigTemplateUpdate(data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.$message.success("新增成功");
              this.modifyShow = false;
              this.query(1);
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 关闭新增
    modifyClose() {
      this.modifyForm = {
        name: "",
        content: "",
        enabled: true,
      };
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.serverConfigTemplateDelete(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
  },
};
</script>
<style>
.code_style .CodeMirror {
  height: 600px;
}
.code_style .CodeMirror-lines {
  padding: 0px;
}
.code_style .CodeMirror .CodeMirror-line {
  font-size: 13px;
  line-height: 150%;
  color: #4d4d4c;
  font-family: arial;
}
.code_style .CodeMirror .CodeMirror-placeholder {
  font-size: 13px;
  line-height: 150%;
  color: #8e908c;
  font-family: arial;
}
</style>
<style lang="scss" scoped>
.ant-switch-checked {
  background-color: #52c41a;
}
.label-c-c-c {
  a {
    margin: 0 5px;
  }
}
</style>
