<template>
  <div class="workbenchType">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="当前任务">
        <router-view
          v-if="active == '1'"
          name="three"
          type="GENERAL_TASK"
          :loginAvatarUrl="loginAvatarUrl"
          :loginNickName="loginNickName"
          :loginName="loginName"
          :workTaskRefresh="workTaskRefresh"
          @stopWorkTaskRefresh="stopWorkTaskRefresh"
        />
      </a-tab-pane>
      <a-tab-pane key="2" tab="周期任务">
        <router-view
          v-if="active == '2'"
          name="three"
          type="SCHEDULE_TASK"
          :loginAvatarUrl="loginAvatarUrl"
          :loginNickName="loginNickName"
          :loginName="loginName"
          :workTaskRefresh="workTaskRefresh"
          @stopWorkTaskRefresh="stopWorkTaskRefresh"
        />
      </a-tab-pane>
      <a-tab-pane key="3" tab="任务库">
        <router-view
          v-if="active == '3'"
          name="three"
          type="INVENTORY_TASK"
          :loginAvatarUrl="loginAvatarUrl"
          :loginNickName="loginNickName"
          :loginName="loginName"
          :workTaskRefresh="workTaskRefresh"
          @stopWorkTaskRefresh="stopWorkTaskRefresh"
        />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "workbenchType",
  props: ["loginAvatarUrl", "loginNickName", "loginName", "workTaskRefresh"],
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/workbench/general") {
          this.active = "1";
        } else if (newVal == "/workbench/schedule") {
          this.active = "2";
        } else if (newVal == "/workbench/store") {
          this.active = "3";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/workbench/general") return;
          this.$router.replace("/workbench/general");
        }
        if (newVal === "2") {
          if (this.$route.path == "/workbench/schedule") return;
          this.$router.replace("/workbench/schedule");
        }
        if (newVal === "3") {
          if (this.$route.path == "/workbench/store") return;
          this.$router.replace("/workbench/store");
        }
      },
    },
  },
  methods: {
    stopWorkTaskRefresh() {
      this.$emit("stopWorkTaskRefresh");
    },
  },
};
</script>
<style scoped>
.workbenchType {
  background-color: #f7f7f7;
}
</style>
