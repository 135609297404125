import http from "./axios";

// 查询列表
const queryPage = ({ pageNo, pageSize }) => {
  return http.get("/zookeeperAddress/list", {
    pageNo,
    pageSize,
  });
};

// 添加
const addData = ({ name, address, remark }) => {
  return http.json_post("/zookeeperAddress/add", {
    name,
    address,
    remark,
  });
};

// 编辑
const editData = ({ id, name, address, remark }) => {
  return http.json_post("/zookeeperAddress/update", {
    id,
    name,
    address,
    remark,
  });
};

// 删除
const deleteData = ({ id }) => {
  return http.json_post("/zookeeperAddress/delete", {
    id,
  });
};

const getChildren = ({ id, path }) => {
  return http.get("/zookeeperAddress/getChildren", {
    id,
    path,
  });
};

const deleteNode = ({ id, path }) => {
  return http.json_post("/zookeeperAddress/deleteNode", {
    id,
    path,
  });
};

const getStat = ({ id, path }) => {
  return http.get("/zookeeperAddress/getStat", {
    id,
    path,
  });
};

const getData = ({ id, path }) => {
  return http.get("/zookeeperAddress/getData", {
    id,
    path,
  });
};

const setData = ({ id, path, data }) => {
  return http.json_post("/zookeeperAddress/setData", {
    id,
    path,
    data,
  });
};

const createNode = ({ id, path, data }) => {
  return http.json_post("/zookeeperAddress/createNode", {
    id,
    path,
    data,
  });
};

export default {
  deleteData,
  queryPage,
  addData,
  editData,
  getChildren,
  deleteNode,
  getStat,
  getData,
  setData,
  createNode,
};
