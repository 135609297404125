import http from "./axios";

// 查询列表
const queryPage = ({ page, limit }) => {
  return http.get("/yapi/project/list", {
    page,
    limit,
  });
};

// 添加
const addData = ({ projectId, desc }) => {
  return http.json_post("/yapi/project/add", {
    projectId,
    desc,
  });
};

// 添加
const deleteData = ({ id }) => {
  return http.json_post("/yapi/project/del", {
    id,
  });
};

export default {
  queryPage,
  addData,
  deleteData,
};
