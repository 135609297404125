<template>
  <div class="projectList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        项目名称：
        <a-input
          placeholder="请输入项目的英文名称"
          v-model.trim="proName"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addDatabase" icon="plus"
          >新建项目</a-button
        >
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(columns)"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="pagination"
      size="small"
    >
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a href="javascript:;" @click="share(record)">分享</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="publishListCheck(record)">发布列表</a>
        <a-divider type="vertical" />
        <a-dropdown>
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            其他 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a
                href="javascript:;"
                style="color: #1890ff"
                @click="edit(record)"
                >编辑</a
              >
            </a-menu-item>
            <a-menu-item>
              <a
                href="javascript:;"
                style="color: #1890ff"
                @click="OwnerChange(record)"
                >所属者变更</a
              >
            </a-menu-item>
            <a-menu-item>
              <a
                href="javascript:;"
                style="color: #1890ff"
                @click="postApplicationTime(record)"
                >发布时间</a
              >
            </a-menu-item>
            <a-menu-item>
              <a
                href="javascript:;"
                style="color: #1890ff"
                @click="subscribeProject(record)"
                >{{ record.subscribe ? "取消关注发布" : "关注发布" }}</a
              >
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>
    <!-- 分享  -->
    <a-modal
      title="分享项目"
      v-model="visibleShared"
      :maskClosable="false"
      :afterClose="closeShared"
    >
      <a-form>
        <a-form-item>
          <a-select
            mode="multiple"
            :value="searchIdList"
            style="width: 100%"
            :filterOption="false"
            @search="searchUserName"
            @change="handleChange"
            placeholder="请输入关键词"
          >
            <a-select-option v-for="i in searchList" :key="i.loginName">{{
              i.userName
            }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="sharedCancel">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="sharedOk"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 所属者变更  -->
    <a-modal
      title="变更项目所属者"
      v-model="visibleOwner"
      :maskClosable="false"
      :afterClose="closeOwner"
    >
      <a-form>
        <a-form-item>
          <a-select
            mode="multiple"
            :value="owner"
            style="width: 100%"
            :filterOption="false"
            @search="searchUserName"
            @change="ownerChange"
            placeholder="请输入关键词"
          >
            <a-select-option v-for="i in searchList" :key="i.loginName">{{
              i.userName
            }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="ownerCancel">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="ownerOk"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 新建项目 -->
    <a-modal
      :title="title"
      v-model="visibleAddProject"
      :maskClosable="false"
      :afterClose="closeAddProject"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="中文名称" required>
          <a-input placeholder="请输入中文名称" v-model="addForm.cnName" />
        </a-form-item>
        <a-form-item label="英文名称" required>
          <a-input placeholder="请输入英文名称" v-model="addForm.enName" />
        </a-form-item>
        <a-form-item label="选择项目" required>
          <a-select
            v-model="addForm.gitProjectId"
            showSearch
            placeholder="请选择项目"
            option-filter-prop="children"
          >
            <a-select-option
              v-for="item in gitProjectList"
              :key="item.id"
              :value="item.id"
              >{{ item.projectName }}</a-select-option
            >
          </a-select>
          <a href="javascript:;" @click="syncClick" class="cc_sync">刷新</a>
        </a-form-item>
        <a-form-item
          label="项目类型"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select :value="addForm.projectType" @change="handleChangeAdd">
            <a-select-option value="JAVA">JAVA</a-select-option>
            <a-select-option value="FRONT">FRONT</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="资源检查"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="witchStatus"
        >
          <a-switch
            checked-children="开"
            un-checked-children="关"
            default-checked
            v-model="addForm.needResourcesCheck"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addProjectCancel">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addProjectOk"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 发布列表 -->
    <a-modal
      :title="'发布列表-' + projectName"
      v-model="publishListShow"
      :maskClosable="false"
      :afterClose="publishListClose"
      width="1000px"
      :footer="null"
    >
      <div class="searchButton">
        <a-button type="primary" @click="addDeliveryConfig" icon="plus"
          >新增发布环境</a-button
        >
      </div>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(innerColumns)"
        :columns="innerColumns"
        :data-source="innerData"
        :pagination="false"
        size="small"
      >
        <span slot="needAudit" slot-scope="text, record" style="width: 100%">
          <a-switch
            v-model="text"
            checkedChildren="开启"
            unCheckedChildren="关闭"
            @change="needAuditOnChange(record)"
          />
        </span>
        <span slot="action" slot-scope="text, record" style="width: 100%">
          <a href="javascript:;" @click="clickVisibleAdd(record)"
            >新建发布申请</a
          >
          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
              其它操作<a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a href="javascript:;" @click="editPipeline(record)">编辑</a>
              </a-menu-item>
              <a-menu-item>
                <a-popconfirm
                  placement="right"
                  okText="确认"
                  cancelText="取消"
                  @confirm="deleteDeliveryConfig(record)"
                >
                  <template slot="title"
                    >确认是否删除{{ record.environment }}的发布配置</template
                  >
                  <a href="javascript:;" style="color: #ff4d4f">删除</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </a-table>
    </a-modal>

    <a-modal
      title="新增发布环境"
      v-model="addDeliveryConfigShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="发布环境">
          <a-select v-model="environmentId" placeholder="请选择发布环境">
            <a-select-option
              v-for="item in environmentList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="发布审核:">
          <a-switch
            v-model="needAudit"
            checkedChildren="开启"
            unCheckedChildren="关闭"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addDeliveryConfigShow = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addDeliveryConfigSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <!-- 新建发布申请 -->
    <newRequest
      :show="requestShow"
      :requestTitle="requestTitle"
      :configId="configId"
      :requestData="newRelease"
      :branchList="branchList"
      @refresh="branchListRefresh"
      @submit="requestSubmit"
      @cancel="requestClose"
      :projectId="projectId"
    ></newRequest>

    <a-modal
      title="发布时间"
      v-model="timeVisible"
      :maskClosable="false"
      width="800px"
      :afterClose="closeTime"
    >
      <div style="margin-bottom: 20px">
        <a-button type="primary" @click="addTime()" icon="plus">新增</a-button>
      </div>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(postApplicationTimeColumns)"
        :columns="postApplicationTimeColumns"
        :dataSource="postApplicationTimeList"
        :pagination="false"
        :rowKey="(record) => record.id"
        size="small"
      >
        <span slot="enabled" slot-scope="text, record">
          <a-switch
            checked-children="已启用"
            un-checked-children="已禁用"
            v-model="record.enabled"
            @change="editTime(record, true)"
          />
        </span>
        <span slot="action" slot-scope="text, record">
          <a-button type="link" style="padding: 0" @click="editTime(record)"
            >修改</a-button
          >
          <a-divider type="vertical" />
          <a-popconfirm
            placement="right"
            okText="确认"
            cancelText="取消"
            @confirm="deleteTime(record)"
          >
            <template slot="title">确认是否删除</template>
            <a href="javascript:;" style="color: #ff4d4f">删除</a>
          </a-popconfirm>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="timeVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="editTimeTitle"
      v-model="editTimeVisible"
      :maskClosable="false"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="模板"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="modelId"
            style="width: 180px; margin-right: 10px"
            placeholder="请选择模板"
          >
            <a-select-option
              v-for="item in modelList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="发布环境"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="environmentId"
            style="width: 250px"
            placeholder="请选择发布环境"
          >
            <a-select-option
              v-for="item in environmentList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="状态:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-switch
            v-model="enabled"
            checkedChildren="启用"
            unCheckedChildren="禁用"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="editTimeVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="editTime_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/projectList.js";
import * as timeControlModelListApi from "../lib/timeControlModelList.js";
import { environmentList } from "../lib/environment.js";
import { synGitCommitLog } from "../lib/request.js";
import newRequest from "../pages/newRequest.vue";

export default {
  name: "projectList",
  components: {
    newRequest,
  },
  data() {
    return {
      title: "",
      updateId: "",
      witchStatus: true,
      gitProjectList: [],
      environmentId: undefined,
      needAudit: false,
      requestTitle: "",
      configId: "",
      requestShow: false,
      newRelease: {
        appTitle: "",
        commitInfo: "",
        branchGit: undefined,
        commitId: undefined,
        projectId: "",
      },
      branchList: [],
      projectName: "",
      publishListShow: false,
      innerColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "key",
          width: 60,
        },
        {
          title: "发布环境",
          ellipsis: true,
          dataIndex: "environment",
        },
        {
          title: "发布审核",
          ellipsis: true,
          dataIndex: "needAudit",
          scopedSlots: { customRender: "needAudit" },
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      innerData: [],
      environmentList: [],
      environmentPublishList: [],
      pageNo: 1,
      proName: "",
      columns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "英文名称",
          ellipsis: true,
          dataIndex: "enName",
        },
        {
          title: "中文名称",
          ellipsis: true,
          dataIndex: "cnName",
        },
        {
          title: "git地址",
          ellipsis: true,
          dataIndex: "gitUrl",
        },
        {
          title: "所属者",
          ellipsis: true,
          dataIndex: "owner",
        },
        {
          title: "项目类型",
          ellipsis: true,
          dataIndex: "projectType",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataSource: [],
      pagination: {},
      visibleShared: false,
      loading: false,
      searchIdList: [],
      searchList: [],
      projectId: "",
      oldSearchIdS: [],
      visibleAddProject: false,
      addForm: {
        cnName: "",
        enname: "",
        gitProjectId: undefined,
        projectType: "JAVA",
        needResourcesCheck: true,
      },
      owner: "",
      visibleOwner: false,
      timeVisible: false,
      postApplicationTimeColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "模板名称",
          ellipsis: true,
          dataIndex: "modelName",
        },
        {
          title: "发布环境",
          ellipsis: true,
          dataIndex: "environment",
        },
        {
          title: "是否启用",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      postApplicationTimeList: [],
      projectId: "",
      editTimeTitle: "",
      enabled: true,
      editTimeVisible: false,
      editTimeId: "",
      modelId: undefined,
      modelList: [],
      addDeliveryConfigShow: false,
    };
  },
  mounted() {
    this.query(1);
    this.getEnvironmentList();
    this.getGitProjectList();
    this.getModelList();
  },
  methods: {
    postApplicationTime(val) {
      if (val) {
        this.projectId = val.id;
      }
      api
        .projectTimeControlModelList({ projectId: this.projectId })
        .then((res) => {
          if (res.result == 200) {
            this.postApplicationTimeList = res.data;
            this.postApplicationTimeList.forEach((item) => {
              this.environmentList.forEach((environment) => {
                if (environment.id == item.environmentId) {
                  this.$set(item, "environment", environment.name);
                }
              });
              this.modelList.forEach((model) => {
                if (model.id == item.modelId) {
                  this.$set(item, "modelName", model.name);
                }
              });
            });
            this.modelId = undefined;
            this.timeVisible = true;
          }
        });
    },
    subscribeProject(val) {
      let data = {
        id: val.id,
      };
      if (val.subscribe) {
        api.cancelSubscribeProject(data).then((res) => {
          if (res.result == 200) {
            this.$message.success("取消成功");
            this.query();
          }
        });
      } else {
        api.subscribeProject(data).then((res) => {
          if (res.result == 200) {
            this.$message.success("关注成功");
            this.query();
          }
        });
      }
    },
    getModelList() {
      timeControlModelListApi.queryPage().then((res) => {
        if (res.result === 200) {
          this.modelList = res.data;
        }
      });
    },
    closeTime() {
      this.projectId = null;
    },
    addTime() {
      this.editTimeTitle = "新增";
      this.modelId = undefined;
      this.environmentId = undefined;
      this.enabled = true;
      this.editTimeVisible = true;
    },
    editTime(val, enable) {
      this.editTimeTitle = "修改";
      this.editTimeId = val.id;
      this.modelId = val.modelId;
      this.environmentId = val.environmentId;
      this.enabled = val.enabled;
      if (enable) {
        this.editTime_submit();
      } else {
        this.editTimeVisible = true;
      }
    },
    editTime_submit() {
      this.loading = true;
      let data = {
        projectId: this.projectId,
        environmentId: this.environmentId,
        modelId: this.modelId,
        enabled: this.enabled,
      };
      if (this.editTimeTitle == "新增") {
        api.addProjectTimeControlModel(data).then((res) => {
          if (res.result == 200) {
            this.$message.success("添加成功");
            this.editTimeVisible = false;
            this.loading = false;
            this.postApplicationTime();
          }
        });
      } else {
        data.id = this.editTimeId;
        api.editProjectTimeControlModel(data).then((res) => {
          if (res.result == 200) {
            this.$message.success("修改成功");
            this.editTimeVisible = false;
            this.loading = false;
            this.postApplicationTime();
          }
        });
      }
    },
    deleteTime(val) {
      api.deleteProjectTimeControl({ id: val.id }).then((res) => {
        if (res.result == 200) {
          this.$message.success("删除成功");
          this.postApplicationTime();
        }
      });
    },
    // 点击刷新--新建项目
    syncClick() {
      api.syncProject().then((res) => {
        if (res.result === 200) {
          this.$message.success("刷新成功");
          this.getGitProjectList();
        }
      });
    },
    // 查询git项目
    getGitProjectList() {
      api.gitProjectList().then((res) => {
        if (res.result === 200) {
          this.gitProjectList = res.data;
        }
      });
    },
    // 点击分支刷新
    branchListRefresh() {
      synGitCommitLog({ projectId: this.projectId }).then((res) => {
        if (res.result === 200) {
          this.branchListRefresh1();
        }
      });
    },
    branchListRefresh1() {
      this.branchList = [];
      api.listRepositoryBranches({ projectId: this.projectId }).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          list.forEach((item) => {
            this.branchList.push({
              branchName: item.branchName,
            });
            item.gitDate = this.$common.transformTime(
              item.commitInfo.committedDateStr.replace(/-/g, "/")
            );
          });
        }
      });
    },
    needAuditOnChange(record) {
      this.loadingTable = true;
      let data = {
        id: record.id,
        needAudit: !record.needAudit,
      };
      api.updateAuditStatus(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("修改成功");
          this.loadingTable = false;
          this.getDeliveryConfigList();
        }
      });
    },
    // 点击新建申请发布
    clickVisibleAdd(val) {
      this.configId = val.id;
      this.requestShow = true;
      this.requestTitle =
        "新建发布申请 - " + this.projectName + " - " + val.environment;
      api.configDetailList({ id: val.id }).then((res) => {
        if (res.result === 200) {
          let list = res.data.serverIdList;
          this.newRelease = {
            appTitle: "",
            commitInfo: "",
            branchGit: undefined,
            commitId: undefined,
            projectId: res.data.projectId,
            environmentId: res.data.environmentId,
          };
        }
      });

      setTimeout(() => {
        this.branchListRefresh1();
      }, 200);
    },
    // 确认新建申请发布
    requestSubmit(val) {
      this.requestShow = val;
      this.newRelease = {
        appTitle: "",
        commitInfo: "",
        branchGit: undefined,
        commitId: undefined,
        projectId: "",
      };
      this.$router.replace("/request");
    },
    // 关闭新建申请发布
    requestClose() {
      this.requestShow = false;
      this.newRelease = {
        appTitle: "",
        commitInfo: "",
        branchGit: undefined,
        commitId: undefined,
        projectId: "",
      };
    },
    // 查询发布列表
    getDeliveryConfigList() {
      api.deliveryConfigList({ projectId: this.projectId }).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          list.forEach((item, index) => {
            item.key = index + 1;
            this.environmentList.forEach((menu) => {
              if (item.environmentId === menu.id) {
                item.environment = menu.name;
              }
            });
          });
          this.innerData = list;
        }
      });
    },
    // 点击查看发布列表
    publishListCheck(val) {
      this.projectName = val.enName;
      this.publishListShow = true;
      this.projectId = val.id;
      this.getDeliveryConfigList();
    },
    editPipeline(val) {
      if (val.pipelineTaskTemplateId == -1) {
        api.initRelationPipeline({ configId: val.id }).then((res) => {
          if (res.result === 200) {
            window.open(location.origin + `/pipeline?id=${res.data.id}`);
          }
        });
      } else {
        window.open(
          location.origin + `/pipeline?id=${val.pipelineTaskTemplateId}`
        );
      }
    },
    addDeliveryConfig() {
      this.environmentId = undefined;
      this.needAudit = false;
      this.addDeliveryConfigShow = true;
    },
    addDeliveryConfigSubmit() {
      let data = {
        projectId: this.projectId,
        environmentId: this.environmentId,
        needAudit: this.needAudit,
      };
      api.addNew(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("添加成功");
          this.addDeliveryConfigShow = false;
          this.getDeliveryConfigList();
        }
      });
    },
    // 删除发布环境
    deleteDeliveryConfig(val) {
      api.deliveryConfigDelete({ id: val.id }).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.getDeliveryConfigList();
        }
      });
    },
    // 关闭发布列表
    publishListClose() {
      this.projectName = "";
      this.innerData = [];
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        name: this.proName,
      };
      this.getProjectList(data);
    },
    // 查询项目列表
    getProjectList(data) {
      api.projectList(data).then((res) => {
        if (res.result == 200) {
          let list = res.data.list;
          list.forEach((item, i) => {
            item.key = i + 1;
          });
          this.dataSource = list;
          // console.log(list);
          this.pagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.count}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.count,
            onChange: (current) => this.changePageItem(current),
          };
        }
      });
    },
    // 分页
    changePageItem(current) {
      this.query(current);
    },
    // 新建项目
    addDatabase() {
      this.visibleAddProject = true;
      this.title = "新建项目";
      this.getGitProjectList();
    },
    // 取消新增项目
    addProjectCancel() {
      this.visibleAddProject = false;
    },
    // 编辑项目
    edit(val) {
      this.title = "编辑项目";
      this.visibleAddProject = true;
      this.addForm.enName = val.enName;
      this.addForm.cnName = val.cnName;
      this.addForm.projectType = val.projectType;
      this.addForm.needResourcesCheck = val.needResourcesCheck;
      let newList = this.gitProjectList.filter(
        (el) => el.projectName == val.projectName
      );
      this.addForm.gitProjectId = newList[0].id;
      this.updateId = val.id;
    },
    // 提交新增项目
    addProjectOk() {
      if (this.title == "新建项目") {
        if (
          this.addForm.gitProjectId &&
          this.addForm.enName &&
          this.addForm.cnName
        ) {
          this.loading = true;
          api.addProject(this.addForm).then((res) => {
            this.loading = false;
            if (res.result == 200) {
              this.query(1);
              this.addProjectCancel();
            }
          });
        } else {
          this.$message.warning("信息填写不完整！");
        }
      } else {
        this.loading = true;
        this.addForm.id = this.updateId;
        api.updateProject(this.addForm).then((res) => {
          this.loading = false;
          if (res.result == 200) {
            this.query(1);
            this.addProjectCancel();
            this.$message.success("编辑成功");
          }
        });
      }
    },
    // 关闭新增项目
    closeAddProject() {
      this.addForm = {
        cnName: "",
        enname: "",
        gitProjectId: undefined,
        projectType: "JAVA",
        needResourcesCheck: true,
      };
    },
    // 选择项目类型
    handleChangeAdd(val) {
      this.addForm.projectType = val;
      if (val === "FRONT") {
        this.addForm.needResourcesCheck = false;
        this.witchStatus = false;
      } else {
        this.addForm.needResourcesCheck = true;
        this.witchStatus = true;
      }
    },
    // 点击分享
    share(val) {
      this.searchIdList = [];
      this.oldSearchIdS = [];
      this.projectId = val.id;
      this.visibleShared = true;
      this.searchList = val.managerList;
      val.managerList.forEach((item) => {
        this.searchIdList.push(item.loginName);
        this.oldSearchIdS.push(item.loginName);
      });
    },
    // 选择分享xxx
    handleChange(val) {
      this.searchIdList = val;
    },
    // 取消分享
    sharedCancel() {
      this.visibleShared = false;
    },
    // 确定分享
    sharedOk() {
      let withdrawStr = [],
        withdrawArr = [],
        searchStr = [],
        [...searchArr] = this.searchIdList,
        index = "";
      // 判断去除或增加人员
      for (let i = 0, len = this.oldSearchIdS.length; i < len; i++) {
        index = searchArr.indexOf(this.oldSearchIdS[i]);
        if (index != -1) {
          searchArr.splice(index, 1);
        } else {
          withdrawArr.push(this.oldSearchIdS[i]);
        }
      }
      if (withdrawArr.length) {
        withdrawStr = withdrawArr;
      }
      // 新增
      if (searchArr.length > 0) {
        let data = {
          userLoginNameList: searchArr,
          projectId: this.projectId,
        };
        this.loading = true;
        api.shareProject(data).then((res) => {
          this.loading = false;
          if (res.result == 200) {
            this.$message.success("操作成功！");
            this.query();
            this.sharedCancel();
          }
        });
      }
      // 去除
      if (withdrawStr.length > 0) {
        let data = {
          userLoginNameList: withdrawStr,
          projectId: this.projectId,
        };
        this.loading = true;
        api.withdrawProject(data).then((res) => {
          this.loading = false;
          if (res.result == 200) {
            this.$message.success("操作成功！");
            this.query();
            this.sharedCancel();
          }
        });
      }
    },
    // 关闭分享
    closeShared() {
      this.searchList = [];
    },
    // 搜索分享名称
    searchUserName(val) {
      if (val !== "") {
        //非空
        api.userList({ name: val }).then((res) => {
          if (res.result == 200) {
            this.searchList = res.data.userList;
          }
        });
      } else {
        //空值
        this.searchList = [];
      }
    },
    // 查询环境列表
    getEnvironmentList() {
      let data = {
        pageNo: 1,
        pageSize: 100,
        name: "",
      };
      environmentList(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item) => {
            item.disabled = false;
          });
          this.environmentList = list;
        }
      });
    },
    // 点击变更所属者
    OwnerChange(val) {
      this.searchIdList = [];
      this.oldSearchIdS = [];
      this.projectId = val.id;
      this.visibleOwner = true;
      this.searchList = val.managerList;
      this.owner = val.owner;
      val.managerList.forEach((item) => {
        this.searchIdList.push(item.loginName);
        this.oldSearchIdS.push(item.loginName);
      });
    },
    // 关闭变更所属者
    closeOwner() {
      this.searchList = [];
    },
    // 取消变更所属者
    ownerCancel() {
      this.visibleOwner = false;
    },
    //  选择变更所属者
    ownerChange(val) {
      if (val.length > 1) {
        val.splice(0, val.length - 1);
      }
      this.owner = val[0];
    },
    // 确定变更所属者
    ownerOk() {
      api
        .ownerChange_post({ projectId: this.projectId, successor: this.owner })
        .then(
          (res) => {
            console.log(res);
            this.$message.success("操作成功！");
            this.query();
            this.ownerCancel();
          },
          (err) => {
            console.log(err);
          }
        );
    },
  },
};
</script>
<style lang="scss" scoped>
.cc_sync {
  position: absolute;
  top: -10px;
  right: -50px;
}
</style>
