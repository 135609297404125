<template>
  <div class="mqMessage">
    <div class="searchCondition">
      <div class="searchConditionItem">
        时间：
        <a-range-picker
          v-model="rangeTime"
          format="YYYY-MM-DD HH:mm:ss"
          :ranges="range"
          style="width: 340px"
          showTime
        ></a-range-picker>
      </div>
      <div class="searchConditionItem">
        内容：
        <a-input
          placeholder="请输入内容"
          v-model.trim="content"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button
          :disabled="!rangeTime[0] || !rangeTime[1]"
          type="primary"
          @click="query(1)"
          icon="search"
          >查询</a-button
        >
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="false"
      :loading="loadingTable"
      size="small"
    >
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="detailClick(record)">详情</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="modifyClick(record)">消息链路</a>
      </span>
    </a-table>
    <div class="pagination">
      <div :class="{ disabled: pageNo == 1 }" @click="previous">上一页</div>
      <div @click="next">下一页</div>
    </div>
    <a-modal
      title="消息链路"
      v-model="viewVisible"
      :maskClosable="false"
      width="1200px"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(viewColumns)"
        :columns="viewColumns"
        :dataSource="viewDateSource"
        :loading="loadingTable"
        size="small"
      >
        <span slot="costTime" slot-scope="text">
          {{ text + "ms" }}
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="viewVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
    <a-modal
      v-model="detailVisible"
      :maskClosable="false"
      width="800px"
      :footer="null"
    >
      <a-form>
        <a-form-item
          label="Message ID:"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
        >
          <a-input v-model="messageView.msgId" />
        </a-form-item>
        <a-form-item
          label="Topic:"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
        >
          <a-input v-model="messageView.topic" />
        </a-form-item>
        <a-form-item
          label="队列:"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
        >
          <a-input v-model="messageView.queueId" />
        </a-form-item>
        <a-form-item
          label="重新消费次数:"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
        >
          <a-input v-model="messageView.reconsumeTimes" />
        </a-form-item>
        <!-- <a-form-item
          label="Tag:"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
        >
          <a-input v-model="messageView.tag" />
        </a-form-item>
        <a-form-item
          label="Key:"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
        >
          <a-input v-model="messageView.key" />
        </a-form-item> -->
        <a-form-item
          label="存储时间:"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
        >
          <a-input v-model="messageView.storeTime" />
        </a-form-item>
        <a-form-item
          label="生产时间:"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
        >
          <a-input v-model="messageView.bornTime" />
        </a-form-item>
        <a-form-item
          label="Message body:"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
        >
          <a-textarea
            placeholder="Basic usage"
            :rows="4"
            v-model="messageView.messageBody"
          />
        </a-form-item>
        <div>messageTrackList:</div>
        <a-table
          :rowClassName="$common.rowClassColor"
          bordered
          :components="$common.getTitle(trackColumns)"
          :columns="trackColumns"
          :dataSource="messageTrackList"
          :loading="loadingTable"
          size="small"
        >
          <span slot="action" slot-scope="text, record">
            <a href="javascript:;" @click="resendClick(record)">重推消息</a>
            <a-divider type="vertical" />
            <a href="javascript:;" @click="viewClick(record)">查看异常</a>
          </span>
        </a-table>
      </a-form>
      <div style="display: flex; justify-content: center">
        <a-button key="back" @click="detailVisible = false">关闭</a-button>
      </div>
    </a-modal>
    <!-- resend弹框 -->
    <a-modal
      title="重推成功"
      v-model="resendVisible"
      :maskClosable="false"
      width="800px"
      centered
    >
      <div>
        {{ resData }}
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="resendVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
    <!-- exception弹框 -->
    <a-modal
      title="Result"
      v-model="exceptionVisible"
      :maskClosable="false"
      width="800px"
      centered
    >
      <div v-if="exceptionData">
        {{ exceptionData }}
      </div>
      <div v-else>Don't have Exception</div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="exceptionVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/mqMessage.js";
import moment from "moment";

export default {
  name: "mqMessage",
  props: ["clusterId", "topic"],
  data() {
    return {
      loading: false,
      tableColumns: [
        {
          title: "Message ID",
          ellipsis: true,
          dataIndex: "msgId",
          width: 300,
        },
        {
          title: "队列",
          ellipsis: true,
          dataIndex: "queueId",
          width: 60,
        },
        {
          title: "内容",
          ellipsis: true,
          dataIndex: "messageBody",
        },
        {
          title: "重新消费次数",
          ellipsis: true,
          dataIndex: "reconsumeTimes",
          width: 100,
        },
        {
          title: "生产时间",
          ellipsis: true,
          dataIndex: "bornTime",
          width: 150,
        },
        {
          title: "存储时间",
          ellipsis: true,
          dataIndex: "storeTime",
          width: 150,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      loadingTable: false,
      queryKey: "",
      pageNo: 1,
      range: {
        "5分钟": [moment(new Date()).subtract(5, "minute"), moment(new Date())],
        "30分钟": [
          moment(new Date()).subtract(30, "minute"),
          moment(new Date()),
        ],
        今天: [moment().startOf("day"), moment().endOf("day")],
        本周: [moment().startOf("week"), moment().endOf("week")],
        本月: [moment().startOf("month"), moment().endOf("month")],
      },
      rangeTime: [],
      content: "",
      trackColumns: [
        {
          title: "consumerGroup",
          ellipsis: true,
          dataIndex: "consumerGroup",
        },
        {
          title: "trackType",
          ellipsis: true,
          dataIndex: "trackType",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      loadingTable: false,
      showTimeStartDate: {
        defaultValue: moment("00:00:00", "HH:mm:ss"),
      },
      showTimeEndDate: {
        defaultValue: moment("23:59:59", "HH:mm:ss"),
      },
      loginEndOpen: false,
      lastLoginDateStart: null,
      lastLoginDateEnd: null,
      detailVisible: false,
      messageTrackList: [],
      messageView: {},
      viewVisible: false,
      viewDateSource: [],
      viewColumns: [
        {
          title: "Message ID",
          ellipsis: true,
          dataIndex: "msgId",
        },
        {
          title: "Tag",
          ellipsis: true,
          dataIndex: "tags",
        },
        {
          title: "Message Key",
          ellipsis: true,
          dataIndex: "keys",
        },
        {
          title: "StoreTime",
          ellipsis: true,
          dataIndex: "timeStamp",
          customRender: (text, row, index) => {
            return moment(text).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          title: "StoreHost",
          ellipsis: true,
          dataIndex: "storeHost",
        },
        {
          title: "costTime",
          ellipsis: true,
          dataIndex: "costTime",
          scopedSlots: { customRender: "costTime" },
        },
        {
          title: "status",
          ellipsis: true,
          dataIndex: "status",
        },
        {
          title: "traceType",
          ellipsis: true,
          dataIndex: "traceType",
        },
      ],
      resendVisible: false,
      exceptionVisible: false,
      exceptionData: "",
      reMsgId: "",
      resData: "",
    };
  },
  computed: {
    formatEndTime() {
      if (this.rangeTime[1]) {
        return this.$common.transformTime(this.rangeTime[1]);
      }
      return null;
    },
    formatStartTime() {
      // 结束时间
      if (this.rangeTime[0]) {
        return this.$common.transformTime(this.rangeTime[0]);
      }
      return null;
    },
  },
  watch: {
    clusterId: {
      handler() {
        this.query();
      },
    },
  },
  created() {
    if (this.clusterId) {
      this.query();
    }
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    query(queryKey) {
      if (this.rangeTime.length == 0) {
        this.rangeTime = [moment().startOf("day"), moment().endOf("day")];
      }
      let data = {
        topic: this.topic,
        clusterId: this.clusterId,
        begin: this.formatStartTime,
        end: this.formatEndTime,
        pageSize: 10,
        pageNo: this.pageNo,
        content: this.content,
      };
      if (queryKey) {
        data.queryKey = queryKey;
      }
      api.consumerConnection(data).then((res) => {
        if (res.result == 200) {
          this.tableDataSource = res.data.mqMessageList;
          this.queryKey = res.data.queryKey;
        }
      });
    },
    next() {
      this.pageNo += 1;
      this.query(this.queryKey);
    },
    previous() {
      if (this.pageNo > 1) {
        this.pageNo -= 1;
        this.query(this.queryKey);
      }
    },
    modifyClick(val) {
      this.viewVisible = true;
      let data = {
        topic: this.topic,
        msgId: val.msgId,
        clusterId: this.clusterId,
      };
      api.viewMessageTraceDetail(data).then((res) => {
        this.viewDateSource = res.data;
      });
    },
    detailClick(val) {
      this.reMsgId = val.msgId;
      let data = {
        msgId: val.msgId,
        topic: this.topic,
        clusterId: this.clusterId,
      };
      api.viewMessage(data).then((res) => {
        this.messageTrackList = res.data.messageTrackList;
        let obj = res.data.messageView;
        this.messageView = obj;
        this.detailVisible = true;
      });
    },
    resendClick(val) {
      let data = {
        consumerGroup: val.consumerGroup,
        topic: this.topic,
        msgId: this.reMsgId,
        clusterId: this.clusterId,
      };
      api.consumeMessageDirectly(data).then((res) => {
        this.resData = res.data;
        this.resendVisible = true;
      });
    },
    //点击view exception
    viewClick(val) {
      this.exceptionData = val.exceptionDesc;
      this.exceptionVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.pagination {
  display: flex;
  float: right;
  margin-top: 10px;
  div {
    color: #1890ff;
    border-color: #1890ff;
    display: block;
    height: 100%;
    font-size: 12px;
    text-align: center;
    background-color: #fff;
    border: 1px solid;
    border-radius: 4px;
    padding: 5px 10px;
    margin: 0 5px;
    min-width: 35px;
    cursor: pointer;
  }
  .disabled {
    cursor: not-allowed;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
  }
}
</style>
