import http from "./axios";

// 告警组成员分页列表
export const getmemberPage = ({ groupId, pageNo, pageSize, userLoginName }) => {
  return http.get("/alarmGroup/memberPage", {
    groupId: groupId,
    pageNo: pageNo,
    pageSize: pageSize,
    userLoginName: userLoginName,
  });
};

// 新增告警组成员
export const addMemberToGroup = ({ groupId, userLoginName }) => {
  return http.json_post("/alarmGroup/addMemberToGroup", {
    groupId: groupId,
    userLoginName: userLoginName,
  });
};

// 删除告警组成员
export const deleteMemberFromGroup = ({ id }) => {
  return http.get("/alarmGroup/deleteMemberFromGroup", {
    id: id,
  });
};

// 告警组集合
export const groupList = () => {
  return http.get("/alarmGroup/groupList");
};
