<template>
  <div class="sshShell">
    <div class="xTerm" id="xTerm">
      <div :id="id"></div>
    </div>
    <div class="footer" v-if="type == 'sshShellRecord'">
      <my-progress
        ref="myProgress"
        :min="min"
        :max="max"
        :value="value"
        @change="myProgressChange"
        @playOrStop="playOrStop"
        v-if="!shelling"
      ></my-progress>
    </div>
  </div>
</template>
<script>
import "xterm/css/xterm.css";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import { WebLinksAddon } from "xterm-addon-web-links";
import "../lib/zmodem";
import { cookieInfo } from "../lib/terminal";
import * as api from "../lib/sshShell";
import myProgress from "./myProgress.vue";

export default {
  components: { myProgress },
  name: "sshShell",
  data() {
    return {
      socketURI: "wss://delivery.huiyu.org.cn/ws/sshShellRecord",
      socket: null,
      fitAddon: null,
      currentReceiveXfer: null,
      rows: 40,
      cols: 100,
      uuid: "",
      timer: null,
      term: null,
      id: "",
      percent: 0,
      max: 0,
      min: 0,
      share: "",
      index: 0,
      value: 0,
      type: "sshShellRecord",
      sessionId: "",
      shelling: true,
    };
  },
  mounted() {
    let id = this.$route.query.id;
    let uuid = this.$route.query.uuid;
    let share = this.$route.query.share;
    if (id) {
      this.id = id;
    }
    this.getUserInfo();
    if (uuid && share) {
      this.uuid = uuid;
      this.share = share;
      this.socketURI = "wss://delivery.huiyu.org.cn/ws/sshShell";
      this.type = "sshShell";
      this.initSocket();
    } else {
      this.getCookieInfo();
    }
  },
  methods: {
    getUserInfo() {
      this.$http.get("/alpha/user/info").then((res) => {
        if (res.result == 200) {
          this.loginNickName = res.userName;
          this.$watermark.set(
            this.loginNickName,
            document.getElementById("xTerm")
          );
        }
      });
    },
    playOrStop(timestamp, stop) {
      if (stop) {
        this.stopPlay();
      } else {
        this.onSend({ timestamp, logId: this.id });
      }
    },
    stopPlay(timestamp) {
      api.stopPlay({ sessionId: this.sessionId }).then((res) => {
        if (res.result == 200) {
          if (timestamp) {
            this.term.write("\x1b[2K\r");
            this.term.clear();
            this.onSend({ timestamp, logId: this.id, drag: true });
          }
        }
      });
    },
    myProgressChange(timestamp) {
      this.stopPlay(timestamp);
    },
    // 获取目录列表
    getCookieInfo() {
      cookieInfo()
        .then((res) => {
          if (res.result === 200) {
            this.uuid = res.data.value;
            this.initSocket();
          }
        })
        .catch((err) => {});
    },
    initTerm() {
      let offsetHeight = document.querySelector(".sshShell").offsetHeight;
      this.rows = (offsetHeight - 40) / 17;
      this.term = new Terminal({
        rendererType: "canvas", //渲染类型
        rows: parseInt(this.rows), //行数
        cols: parseInt(this.cols), // 列数
        convertEol: true, //启用时，光标将设置为下一行的开头
        scrollback: 10000, //终端中的回滚量
        disableStdin: false, //是否应禁用输入。
        cursorStyle: "underline", //光标样式
        cursorBlink: true, //光标闪烁
        theme: {
          foreground: "#ffffff", //字体
          background: "#1e1b1b", //背景色
          cursor: "help", //设置光标
          lineHeight: 16,
        },
      });
      // // // canvas背景全屏
      this.fitAddon = new FitAddon();
      this.term.loadAddon(new WebLinksAddon());
      this.term.zmodemRetract = () => {
        console.log("------retract----");
      };
      this.term.zmodemDetect = (detection) => {
        console.log("------zmodemDetect----");
        (() => {
          const zsession = detection.confirm();

          let promise;

          if (zsession.type === "receive") {
            promise = this.handleReceiveSession(zsession);
          } else {
            promise = this.handleSendSession(zsession);
          }

          promise.catch(console.error.bind(console)).then(() => {
            console.log("----promise then-----");
          });
        })();
      };
      this.term.loadAddon(this.fitAddon);
      this.term.open(document.getElementById(this.id));
      this.fitAddon.fit();
      window.addEventListener("resize", this.resizeScreen);
      this.term.focus();
      if (this.term._initialized) {
        return;
      }
      // 初始化
      this.term._initialized = true;
      if (this.share) {
        this.term.zmodemAttach(this.socket, {
          noTerminalWriteOutsideSession: true,
        });
        this.term.onData((ev) => {
          if (!this.socket) {
            this.initSocket();
            return;
          }
          this.onSend(ev);
        });
      }
    },
    // 下载文件处理
    handleReceiveSession(zsession) {
      zsession.on("offer", (xfer) => {
        this.currentReceiveXfer = xfer;

        const onFormSubmit = () => {
          // 开始下载
          const FILE_BUFFER = [];
          xfer.on("input", (payload) => {
            // 下载中
            this.updateProgress(xfer);
            FILE_BUFFER.push(new Uint8Array(payload));
          });
          xfer.accept().then(() => {
            // 下载完毕，保存文件
            this.saveToDisk(xfer, FILE_BUFFER);
          }, console.error.bind(console));
        };

        onFormSubmit();
      });

      const promise = new Promise((res) => {
        zsession.on("session_end", () => {
          console.log("-----zession close----");
          res();
        });
      });

      zsession.start();

      return promise;
    },
    // 获取进度，可以发送给后台，展示在页面上
    updateProgress(xfer) {
      const fileName = xfer.get_details().name;
      const totalIn = xfer.get_offset();
      const percentReceived = (100 * totalIn) / xfer.get_details().size;
      this.currentProcess = percentReceived.toFixed(2);
    },
    // 下载完毕，保存文件
    saveToDisk(xfer, buffer) {
      this.term.zmodemBrowser.save_to_disk(buffer, xfer.get_details().name);
    },
    // 上传文件处理
    handleSendSession(zsession) {
      const promise = new Promise((res, rej) => {
        zsession.on("session_end", () => {
          console.log("-----zession close----");
          res();
        });
      });

      return promise;
    },
    // 内容全屏显示
    resizeScreen() {
      this.fitAddon.fit();
    },
    // 创建socket
    initSocket() {
      this.socket = new WebSocket(this.socketURI);
      this.socketOnClose();
      this.socketOnMessage();
      this.socketOnOpen();
      this.socketOnError();
    },
    // 链接成功后
    socketOnOpen() {
      this.socket.onopen = (event) => {
        if (this.term) {
          this.term.dispose();
          this.term = null;
        }
        this.initTerm();
        let data = {
          name: "ESTABLISH",
          uuid: this.uuid,
          type: "SYSTEM_USER",
        };
        if (this.share) {
          data.share = this.share;
          data.type = "SHARE_LINK";
          data.widthCharacters = this.term.cols;
          data.heightCharacters = this.term.rows;
        }
        this.onSend(data, "xtermEvent:");
      };
    },
    // socket消息
    socketOnMessage() {
      this.socket.onmessage = (e) => {
        if (this.type == "sshShell") {
          this.term.write(e.data);
        } else {
          this.index++;
          if (this.index == 1) {
            if (e.data == "SHELLING") {
              this.shelling = true;
            } else {
              this.shelling = false;
              let obj = JSON.parse(e.data);
              this.min = obj.start;
              this.max = obj.end;
              this.sessionId = obj.sessionId;
            }
          } else {
            if (this.shelling) {
              var reader = new FileReader();
              reader.readAsText(e.data, "utf-8");
              reader.onload = () => {
                this.term.write(reader.result);
              };
            } else {
              if (typeof e.data == "string") {
                this.value = e.data * 1;
              } else {
                var reader = new FileReader();
                reader.readAsText(e.data, "utf-8");
                reader.onload = () => {
                  this.term.write(reader.result);
                };
              }
            }
          }
        }
      };
    },
    // socket关闭
    socketOnClose() {
      this.socket.onclose = () => {
        console.log("WebSocket连接关闭");
        this.socket = null;
      };
    },
    // socket失败
    socketOnError() {
      this.socket.onerror = () => {
        console.log("socket 链接失败");
      };
    },
    // socket发送
    onSend(data, sgin) {
      let uuid = "";
      let share = "";
      if (data.uuid) {
        uuid = data.uuid;
      }
      if (data.share) {
        share = data.share;
      }
      data = typeof data === "object" ? JSON.stringify(data) : data;
      data = data.constructor === Array ? data.toString() : data;
      data = data.replace(/\\\\/, "\\");
      if (sgin) {
        data = sgin + data;
      }
      this.socket.send(data);
      if (uuid && !share) {
        this.onSend({ logId: this.id });
      }
    },
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
    window.removeEventListener("resize", this.resizeScreen);
  },
};
</script>
<style lang="scss" scoped>
.sshShell {
  background-color: #1e1b1b;
  color: #ff00009b;
  min-height: 100vh;
  .xTerm {
    width: 100%;
    padding: 5px;
    &::v-deep .xterm-viewport::-webkit-scrollbar {
      display: none;
    }
  }
  .footer {
    width: 100vw;
    height: 15px;
    padding: 0 5px;
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
</style>
