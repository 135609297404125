import http from "./axios";

// 删除环境
export const environmentDelete = ({ id }) => {
  return http.json_post("/delivery/environment/delete", { id: id });
};

// 新增环境
export const environmentAdd = ({ name, sign, remark }) => {
  return http.json_post("/delivery/environment/add", {
    name: name,
    sign: sign,
    remark: remark,
  });
};

// 修改环境
export const environmentUpdate = ({ id, name, sign, remark }) => {
  return http.json_post("/delivery/environment/update", {
    id: id,
    name: name,
    sign: sign,
    remark: remark,
  });
};

// 查询环境列表
export const environmentList = ({ pageNo, pageSize, name }) => {
  return http.get("/delivery/environment/list", {
    pageNo: pageNo,
    pageSize: pageSize,
    name: name,
  });
};
