import { render, staticRenderFns } from "./slb.vue?vue&type=template&id=79b01e38&scoped=true"
import script from "./slb.vue?vue&type=script&lang=js"
export * from "./slb.vue?vue&type=script&lang=js"
import style0 from "./slb.vue?vue&type=style&index=0&id=79b01e38&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79b01e38",
  null
  
)

export default component.exports