import http from "./axios";

// 查询公钥列表
export const publicKeyList = ({ pageNo, pageSize, type }) => {
  return http.get("/server/publicKey/page", {
    pageNo: pageNo,
    pageSize: pageSize,
    type: type,
  });
};

// 查询服务器集合
export const serverList = ({ instanceName }) => {
  return http.get("/delivery/server/list", {
    instanceName: instanceName,
  });
};

// 查询公钥集合
export const searchPublicKey = ({ identifyName }) => {
  return http.get("/server/publicKey/list", {
    identifyName: identifyName,
  });
};

// 新增公钥列表
export const publicKeyAdd = ({ type, identifyId, remark, publicKey }) => {
  return http.json_post("/server/publicKey/insert", {
    type: type,
    identifyId: identifyId,
    remark: remark,
    publicKey: publicKey,
  });
};

// 删除公钥
export const publicKeyDelete = ({ id }) => {
  return http.get("/server/publicKey/delete", {
    id: id,
  });
};
