import http from "./axios";

// 查询项目状态列表
export const getStatus = ({
  environmentId,
  pageNo,
  pageSize,
  enName,
  serverId,
  status,
  queryAll,
}) => {
  return http.get("/delivery/project/statusList", {
    pageNo,
    pageSize,
    enName,
    serverId,
    status,
    environmentId,
    queryAll,
  });
};

// 查询服务器集合
export const serverList = ({ instanceName }) => {
  return http.get("/delivery/server/list?enabled=true", {
    instanceName: instanceName,
  });
};
