import http from "./axios";

export const taskDetail = (data) => {
  return http.get("/pipelineTask/taskDetail", data);
};

export const loadDynamicApiData = (data) => {
  return http.get("/pipelineNode/loadDynamicApiData", data);
};

export const loadNamespaceList = (data) => {
  return http.get("/pipelineTask/loadNamespaceList", data);
};

export const invokeDynamicApi = (data) => {
  return http.post("/pipelineNode/invokeDynamicApi", data);
};

export const loadProfileList = (data) => {
  return http.get("/pipelineTask/loadProfileList", data);
};

export const nodeLogList = (data) => {
  return http.get("/pipelineNode/nodeLogList", data);
};

export const addPipelineStage = (data) => {
  return http.json_post("/pipelineStage/addPipelineStage", data);
};

export const editPipelineStage = (data) => {
  return http.json_post("/pipelineStage/editPipelineStage", data);
};

export const deletePipelineStage = (data) => {
  return http.json_post("/pipelineStage/deletePipelineStage", data);
};

export const nodeTypeList = (data) => {
  return http.get("/pipelineNode/nodeTypeList", data);
};

export const addPipelineNode = (data) => {
  return http.json_post("/pipelineNode/addPipelineNode", data);
};

export const editPipelineNode = (data) => {
  return http.json_post("/pipelineNode/editPipelineNode", data);
};

export const deletePipelineNode = (data) => {
  return http.json_post("/pipelineNode/deletePipelineNode", data);
};

export const restartTask = (data) => {
  return http.json_post("/pipelineTask/restartTask", data);
};

export const startTask = (data) => {
  return http.json_post("/pipelineTask/startTask", data);
};

export const stopTask = (data) => {
  return http.json_post("/pipelineTask/stopTask", data);
};

export const listPipelineGlobalParameter = (data) => {
  return http.get("/pipelineGlobalParameter/listPipelineGlobalParameter", data);
};

export const addPipelineGlobalParameter = (data) => {
  return http.json_post(
    "/pipelineGlobalParameter/addPipelineGlobalParameter",
    data
  );
};

export const editPipelineGlobalParameter = (data) => {
  return http.json_post(
    "/pipelineGlobalParameter/editPipelineGlobalParameter",
    data
  );
};

export const deletePipelineGlobalParameter = (data) => {
  return http.json_post(
    "/pipelineGlobalParameter/deletePipelineGlobalParameter",
    data
  );
};

export const updateGlobalParameters = (data) => {
  return http.json_post("/pipelineTask/updateGlobalParameters", data);
};

export const resetNodeState = (data) => {
  return http.json_post("/pipelineNode/resetNodeState", data);
};

export const editPipelineStageSort = (data) => {
  return http.json_post("/pipelineStage/editPipelineStageSort", data);
};

export const editPipelineNodeSort = (data) => {
  return http.json_post("/pipelineNode/editPipelineNodeSort", data);
};

export const updatePipelineConfigTemplateContent = (data) => {
  return http.json_post(
    "/pipelineConfig//updatePipelineConfigTemplateContent",
    data
  );
};
