<template>
  <div class="environment">
    <div class="searchCondition">
      <div class="searchConditionItem">
        环境名称：
        <a-input
          placeholder="请输入环境名称"
          v-model.trim="envName"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="clickVisibleAdd('add')" icon="plus"
          >新建</a-button
        >
        <a-button type="primary" @click="query()" icon="reload">刷新</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(envColumns)"
      :columns="envColumns"
      :dataSource="envDataSource"
      :pagination="envPagination"
      size="small"
      :loading="tableLoading"
    >
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a href="javascript:;" @click="clickVisibleAdd('modify', record)"
          >编辑</a
        >
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteServer(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新建环境 -->
    <a-modal
      :title="visibleAddtitle"
      v-model="visibleAddServer"
      :maskClosable="false"
      :afterClose="closeAddServer"
      width="600px"
    >
      <a-form>
        <a-form-item
          label="应用名称"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input
            placeholder="请输入环境名称，例如：来发环境"
            v-model="addForm.envName"
          />
        </a-form-item>
        <a-form-item
          label="唯一标识符"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input
            placeholder="请输入唯一标识符，例如：dev"
            v-model="addForm.identifier"
          />
        </a-form-item>
        <a-form-item
          label="备注信息"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input placeholder="请输入备注信息" v-model="addForm.remark" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="visibleAddServer = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addServerOk"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/environment.js";
export default {
  name: "environment",
  data() {
    return {
      tableLoading: false,
      loading: false,
      envColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "key",
          width: 60,
        },
        {
          title: "环境名称",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "标识符",
          ellipsis: true,
          dataIndex: "sign",
        },
        {
          title: "描述信息",
          ellipsis: true,
          dataIndex: "remark",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      envDataSource: [],
      envPagination: {},
      envPageNo: 1,
      envName: "",
      visibleAddtitle: "",
      visibleAddServer: false,
      addForm: {
        envName: "",
        identifier: "",
        remark: "",
      },
    };
  },
  mounted() {
    this.query();
  },
  methods: {
    // 点击应用新建or编辑
    clickVisibleAdd(key, val) {
      if (key === "add") {
        this.visibleAddtitle = "新建应用";
      }
      if (key === "modify") {
        this.visibleAddtitle = "编辑应用";
        this.envId = val.id;
        this.addForm = {
          envName: val.name,
          identifier: val.sign,
          remark: val.remark,
        };
      }
      this.visibleAddServer = true;
    },
    // 删除应用
    deleteServer(val) {
      api.environmentDelete({ id: val.id }).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    // 新建应用
    addServerOk() {
      this.addForm.envName = this.addForm.envName.trim();
      this.addForm.identifier = this.addForm.identifier.trim();
      let data = {
        name: this.addForm.envName,
        sign: this.addForm.identifier,
        remark: this.addForm.remark,
      };
      if (this.addForm.envName.length && this.addForm.identifier.length) {
        this.loading = true;
        if (this.visibleAddtitle === "新建应用") {
          api
            .environmentAdd(data)
            .then((res) => {
              this.loading = false;
              if (res.result === 200) {
                this.$message.success("新建成功");
                this.visibleAddServer = false;
                this.query();
              }
            })
            .catch((err) => {
              this.loading = false;
            });
        }
        if (this.visibleAddtitle === "编辑应用") {
          data.id = this.envId;
          api
            .environmentUpdate(data)
            .then((res) => {
              this.loading = false;
              if (res.result === 200) {
                this.$message.success("编辑成功");
                this.visibleAddServer = false;
                this.query(1);
              }
            })
            .catch((err) => {
              this.loading = false;
            });
        }
      } else {
        this.$message.warning("信息填写不完整！");
      }
    },
    // 关闭新建应用
    closeAddServer() {
      this.addForm = {
        envName: "",
        identifier: "",
        remark: "",
      };
      this.loading = false;
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    //查询
    query(index) {
      if (index) {
        this.envPageNo = index;
      }
      let data = {
        pageNo: this.envPageNo,
        pageSize: 10,
        name: this.envName,
      };
      this.getEnvironmentList(data);
    },
    // 查询服务器列表
    getEnvironmentList(data) {
      this.tableLoading = true;
      api
        .environmentList(data)
        .then((res) => {
          this.tableLoading = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
            });
            this.envDataSource = list;
            this.envPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    // 分页
    changePageItem(current) {
      this.query(current);
    },
  },
};
</script>
