import http from "./axios";

// 立即执行
export const batchExecute = ({ serverIdList, commandList, groupIdList }) => {
  return http.json_post("/commandExecute/batchExecute", {
    serverIdList: serverIdList,
    groupIdList: groupIdList,
    commandList: commandList,
  });
};

export const getExecuteProcess = ({ uuid }) => {
  return http.get("/commandExecute/getExecuteProcess", { uuid: uuid });
};

// 查询服务器组
export const groupServer = ({ groupName }) => {
  return http.get("/delivery/serverGroup/list", { groupName: groupName });
};
