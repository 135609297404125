import { render, staticRenderFns } from "./mRightMenuItem.vue?vue&type=template&id=21f2d338&scoped=true"
import script from "./mRightMenuItem.vue?vue&type=script&lang=js"
export * from "./mRightMenuItem.vue?vue&type=script&lang=js"
import style0 from "./mRightMenuItem.vue?vue&type=style&index=0&id=21f2d338&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21f2d338",
  null
  
)

export default component.exports