<template>
  <div class="templateParameter">
    <a-tabs v-model="active" @change="callback" type="card">
      <a-tab-pane key="personage" tab="我的参数">
        <div class="searchCondition">
          <div class="searchConditionItem">
            参数名：
            <a-input
              placeholder="请输入参数名"
              v-model.trim="parameterKey"
              @pressEnter="query(1)"
              @change="allowClearChange"
              allowClear
            />
          </div>
          <div class="searchButton">
            <a-button type="primary" @click="query(1)" icon="search"
              >查询</a-button
            >
            <a-button type="primary" @click="addClick" icon="plus"
              >新增</a-button
            >
          </div>
        </div>
        <a-table
          :rowClassName="$common.rowClassColor"
          bordered
          :components="$common.getTitle(tableColumns)"
          :columns="tableColumns"
          :dataSource="tableDataSource"
          :pagination="tablePagination"
          :loading="loadingTable"
          size="small"
        >
          <span slot="action" slot-scope="text, record">
            <a href="javascript:;" @click="modifyClick(record)">修改</a>
            <a-divider type="vertical" />
            <a-popconfirm
              placement="right"
              okText="是"
              cancelText="否"
              v-if="record.deletable"
              @confirm="deleteClick(record)"
            >
              <template slot="title"
                >确定要删除【{{ record.parameterKey }}】?</template
              >
              <a href="javascript:;" style="color: #ff4d4f">删除</a>
            </a-popconfirm>
          </span>
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="system" tab="系统参数">
        <div class="searchCondition">
          <div class="searchConditionItem">
            参数名：
            <a-input
              placeholder="请输入参数名"
              v-model.trim="parameterKey"
              @pressEnter="query(1)"
              @change="allowClearChange"
              allowClear
            />
          </div>
          <div class="searchButton">
            <a-button type="primary" @click="query(1)" icon="search"
              >查询</a-button
            >
            <a-button type="primary" @click="addClick" icon="plus"
              >新增</a-button
            >
          </div>
        </div>
        <a-table
          :rowClassName="$common.rowClassColor"
          bordered
          :components="$common.getTitle(tableColumns)"
          :columns="tableColumns"
          :dataSource="tableDataSource"
          :pagination="tablePagination"
          :loading="loadingTable"
          size="small"
        >
          <span slot="action" slot-scope="text, record">
            <a href="javascript:;" @click="modifyClick(record)">修改</a>
            <a-divider type="vertical" />
            <a-popconfirm
              placement="right"
              okText="是"
              cancelText="否"
              :disabled="!record.deletable"
              @confirm="deleteClick(record)"
            >
              <template slot="title"
                >确定要删除【{{ record.parameterKey }}】?</template
              >
              <a
                href="javascript:;"
                :disabled="!record.deletable"
                style="color: #ff4d4f"
                >删除</a
              >
            </a-popconfirm>
          </span>
        </a-table>
      </a-tab-pane>
    </a-tabs>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="参数名:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="parameterKeyAdd" placeholder="请输入参数名" />
        </a-form-item>
        <a-form-item
          label="参数值:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="parameterValue" placeholder="请输入参数值" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/templateParameter.js";

export default {
  name: "templateParameter",
  data() {
    return {
      active: "personage",
      title: "",
      // templateId: undefined,
      parameterKey: "",
      parameterKeyAdd: "",
      // templateIdAdd: undefined,
      parameterValue: "",
      groupId: "",
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "参数名",
          ellipsis: true,
          dataIndex: "parameterKey",
        },
        {
          title: "参数值",
          ellipsis: true,
          dataIndex: "parameterValue",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 120,
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      templateIdList: [],
    };
  },
  mounted() {
    this.query(1);
    this.getCodeTemplateList();
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        parameterKey: this.parameterKey,
        // templateId: this.templateId,
      };
      if (this.active == "system") {
        data.isSystemParameter = true;
      } else {
        data.isSystemParameter = false;
      }
      this.codeTemplateParameterPage(data);
    },
    // 查询模板参数列表
    codeTemplateParameterPage(data) {
      this.loadingTable = true;
      api
        .codeTemplatePage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.addKeyVisible = true;
      this.groupId = val.id;
      this.parameterKeyAdd = val.parameterKey;
      // this.templateIdAdd = val.templateId;
      this.parameterValue = val.parameterValue;
    },
    // 新增
    addClick() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.parameterKeyAdd = "";
      // this.templateIdAdd = undefined;
      this.parameterValue = "";
    },
    // 确定新增
    add_submit() {
      let data = {
        // templateId: this.templateIdAdd,
        parameterKey: this.parameterKeyAdd,
        parameterValue: this.parameterValue,
      };
      if (this.title === "新增") {
        api.addCodeTemplateParameter(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.id = this.groupId;
        api.editCodeTemplateParameter(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.parameterKeyAdd = "";
      this.parameterValue = "";
      this.groupId = "";
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteCodeTemplateParameter(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    // 获取模板集合
    getCodeTemplateList() {
      let data = {
        name: "",
      };
      api.codeTemplateList(data).then((res) => {
        if (res.result === 200) {
          this.templateIdList = res.data;
        }
      });
    },
    callback() {
      this.query(1);
    },
  },
};
</script>
<style lang="scss" scoped></style>
