import http from "./axios";

// 查询列表
const queryPage = ({ name, serverId, pageNo, pageSize }) => {
  return http.get("/nginxInstance/nginxInstancePage", {
    name,
    serverId,
    pageNo,
    pageSize,
  });
};

// 添加
const addData = ({ serverId }) => {
  return http.json_post("/nginxInstance/addNginxInstance", {
    serverId,
  });
};

// 编辑
const editData = ({ id, name, enabled }) => {
  return http.json_post("/nginxInstance/editNginxInstance", {
    id,
    name,
    enabled,
  });
};

// 删除
const deleteData = ({ id }) => {
  return http.json_post("/nginxInstance/deleteNginxInstance", {
    id,
  });
};

export default {
  deleteData,
  queryPage,
  addData,
  editData,
};
