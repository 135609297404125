import http from "./axios";

// 查询发布日志列表
export const deliveryLog = ({ pageNo, pageSize, projectId, serverId }) => {
  return http.get("/delivery/log/deliveryLog", {
    pageNo,
    pageSize,
    projectId,
    serverId,
  });
};
