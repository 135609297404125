<template>
  <div class="templateProject">
    <div class="searchCondition">
      <div class="searchConditionItem">
        项目：
        <a-select
          v-model="projectId"
          placeholder="请选择项目名称"
          allowClear
          showSearch
          option-filter-prop="children"
        >
          <a-select-option v-for="i in projectIdList" :key="i.id">
            {{ i.enName }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        实例：
        <a-select
          showSearch
          v-model="instanceId"
          placeholder="可搜索IP地址"
          :defaultActiveFirstOption="false"
          :showArrow="false"
          :filterOption="false"
          @search="handleInstanceListSearch"
          @change="handleInstanceListChange"
          :notFoundContent="fetching ? undefined : null"
        >
          <a-spin v-if="fetching" slot="notFoundContent" size="small" />
          <a-select-option v-for="d in instanceList" :key="d.id"
            >{{ d.name }}({{ d.host }}:{{ d.port }})</a-select-option
          >
        </a-select>
      </div>
      <div class="searchConditionItem">
        数据库名：
        <a-select v-model="databaseName" placeholder="请选择数据库名称">
          <a-select-opt-group label="customer">
            <a-select-option v-for="d in customerList" :key="d.value">{{
              d.label
            }}</a-select-option>
          </a-select-opt-group>
          <a-select-opt-group label="system">
            <a-select-option v-for="d in systemList" :key="d.value">{{
              d.label
            }}</a-select-option>
          </a-select-opt-group>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addClick" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="enabled" slot-scope="text, record" style="width: 100%">
        <a-switch
          checked-children="已启用"
          un-checked-children="已禁用"
          v-model="record.enabled"
          @change="modifyClick(record, true)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">编辑</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.projectName }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="项目:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="projectIdAdd"
            placeholder="请选择项目名称"
            allowClear
            showSearch
            option-filter-prop="children"
          >
            <a-select-option v-for="i in projectIdList" :key="i.id">
              {{ i.enName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="实例:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            showSearch
            v-model="instanceIdAdd"
            placeholder="可搜索IP地址"
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :filterOption="false"
            @search="handleInstanceListSearch"
            @change="handleInstanceListChange"
            :notFoundContent="fetching ? undefined : null"
          >
            <a-spin v-if="fetching" slot="notFoundContent" size="small" />
            <a-select-option v-for="d in instanceList" :key="d.id"
              >{{ d.name }}({{ d.host }}:{{ d.port }})</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="数据库名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select v-model="databaseNameAdd" placeholder="请选择数据库名称">
            <a-select-opt-group label="customer">
              <a-select-option v-for="d in customerList" :key="d.value">{{
                d.label
              }}</a-select-option>
            </a-select-opt-group>
            <a-select-opt-group label="system">
              <a-select-option v-for="d in systemList" :key="d.value">{{
                d.label
              }}</a-select-option>
            </a-select-opt-group>
          </a-select>
        </a-form-item>
        <a-form-item
          label="包名:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="packageName" placeholder="请输入包名" />
        </a-form-item>
        <a-form-item
          label="启用:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="enabled"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/templateProject.js";
import { projectList } from "../lib/projectList.js";
import { showDatabases, instanceList } from "../lib/management.js";

export default {
  name: "templateProject",
  data() {
    return {
      title: "",
      projectId: undefined,
      projectIdAdd: undefined,
      instanceId: undefined,
      instanceIdAdd: undefined,
      databaseName: undefined,
      databaseNameAdd: undefined,
      fetching: false,
      customerList: [],
      systemList: [],
      packageName: "",
      enabled: true,
      groupId: "",
      // -----------------------------
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "项目名",
          ellipsis: true,
          dataIndex: "projectName",
        },
        {
          title: "实例名称",
          ellipsis: true,
          dataIndex: "instanceName",
        },
        {
          title: "数据库名称",
          ellipsis: true,
          dataIndex: "databaseName",
        },
        {
          title: "包名",
          ellipsis: true,
          dataIndex: "packageName",
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      //   configIdList: [],
      //   tableNamesList: [],
      previewList: [],
      previewTitle: "",
      previewVisible: false,
      previewActiveKey: "1",
      previewActiveKey1: "0",
      projectIdList: [],
      instanceList: [],
    };
  },
  mounted() {
    this.query(1);
    this.getProject();
    this.getInstanceList();
  },
  methods: {
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        projectId: this.projectId,
        instanceId: this.instanceId,
        databaseName: this.databaseName,
      };
      this.getCodeProjectConfigPage(data);
    },
    // 查询项目配置列表
    getCodeProjectConfigPage(data) {
      this.loadingTable = true;
      api
        .codeProjectConfigPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 修改
    modifyClick(val, enable) {
      this.title = "修改";
      this.groupId = val.id;
      this.projectIdAdd = val.projectId;
      this.instanceIdAdd = val.instanceId;
      this.databaseNameAdd = val.databaseName;
      this.packageName = val.packageName;
      this.enabled = val.enabled;
      if (enable) {
        this.add_submit();
      } else {
        this.addKeyVisible = true;
      }
    },
    // 新增
    addClick() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.projectIdAdd = undefined;
      this.databaseNameAdd = undefined;
      this.instanceIdAdd = undefined;
      this.packageName = "";
      this.enabled = true;
    },
    // 确定新增
    add_submit() {
      let data = {
        projectId: this.projectIdAdd,
        instanceId: this.instanceIdAdd,
        databaseName: this.databaseNameAdd,
        packageName: this.packageName,
        enabled: this.enabled,
      };
      if (this.title === "新增") {
        api.addCodeProjectConfig(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.id = this.groupId;
        api.editCodeProjectConfig(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.projectIdAdd = undefined;
      this.instanceIdAdd = undefined;
      this.databaseNameAdd = undefined;
      this.groupId = "";
      this.packageName = "";
      this.enabled = true;
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteCodeProjectConfig(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    // 查询项目下拉框
    getProject() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
      };
      projectList(data).then((res) => {
        if (res.result === 200) {
          this.projectIdList = res.data.list;
        }
      });
    },
    // 搜索实例
    handleInstanceListSearch(host) {
      this.fetching = true;
      this.getInstanceList(host);
    },
    // 查询实例
    getInstanceList(host) {
      let data = {
        host,
      };
      instanceList(data).then((res) => {
        if (res.result == 200) {
          this.instanceList = res.data;
          this.fetching = false;
        }
      });
    },
    // 选择实例
    handleInstanceListChange(val) {
      this.databaseName = undefined;
      this.databaseNameAdd = undefined;
      this.getShowDatabases({ instanceId: val });
    },
    // 查询账号数据库
    getShowDatabases(data) {
      showDatabases(data).then((res) => {
        if (res.result === 200) {
          let data = res.data;
          this.customerList = [];
          this.systemList = [];
          for (let i in data) {
            if (i === "customer") {
              data[i].forEach((item) => {
                let param = {
                  label: item,
                  value: item,
                };
                this.customerList.push(param);
              });
            }
            if (i === "system") {
              data[i].forEach((item) => {
                let param = {
                  label: item,
                  value: item,
                };
                this.systemList.push(param);
              });
            }
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
