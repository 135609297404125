<template>
  <div class="statefulSetTab">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="statefulSet列表">
        <router-view name="three" :namespace="namespace" v-if="active == '1'" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="自定义statefulSet">
        <router-view name="three" type="STATEFUL_SET" v-if="active == '2'" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "statefulSetTab",
  props: ["namespace"],
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/statefulSetTab/statefulSetList") {
          this.active = "1";
        } else if (newVal == "/statefulSetTab/customStatefulSet") {
          this.active = "2";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/statefulSetTab/statefulSetList") return;
          this.$router.replace("/statefulSetTab/statefulSetList");
        }
        if (newVal === "2") {
          if (this.$route.path == "/statefulSetTab/customStatefulSet") return;
          this.$router.replace("/statefulSetTab/customStatefulSet");
        }
      },
    },
  },
};
</script>
