<template>
  <div class="mqTopic">
    <div class="searchCondition">
      <div class="searchConditionItem">
        Topic：
        <a-input
          placeholder="请输入Topic"
          allowClear
          v-model.trim="topicName"
          @pressEnter="query(1)"
          @change="allowClearChange"
        />
      </div>
      <div class="searchConditionItem">
        项目：
        <a-select
          v-model="projectId"
          placeholder="请选择项目"
          allowClear
          showSearch
          option-filter-prop="children"
          style="width: 240px"
          @change="query(1)"
        >
          <a-select-option v-for="i in projectSelectList" :key="i.id">
            {{ i.enName }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        <a-checkbox-group @change="query(1)" v-model="categories">
          <a-checkbox value="NORMAL"> NORMAL </a-checkbox>
          <a-checkbox value="RETRY"> RETRY </a-checkbox>
          <a-checkbox value="DLQ"> DLQ </a-checkbox>
          <a-checkbox value="SYS"> SYSTEM </a-checkbox>
        </a-checkbox-group>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="addClick" icon="plus">新增</a-button>
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="projectList" slot-scope="text, record">
        <a-tag color="blue" v-for="item in record.projectList" :key="item">{{
          item
        }}</a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="detail(record)">详情</a>
        <a-divider type="vertical" />
        <a
          href="javascript:;"
          @click="goTopic(record)"
          v-if="
            record.topic.indexOf('%SYS') == -1 &&
            record.topic.indexOf('%DLQ') == -1 &&
            record.topic.indexOf('%RETRY') == -1
          "
          >消费监控</a
        >
        <a-divider
          type="vertical"
          v-if="
            record.topic.indexOf('%SYS') == -1 &&
            record.topic.indexOf('%DLQ') == -1 &&
            record.topic.indexOf('%RETRY') == -1
          "
        />

        <a href="javascript:;" @click="manageClick(record)">消费者详情</a>
        <a-divider type="vertical" />
        <a
          href="javascript:;"
          @click="sendMassageClick(record)"
          v-if="record.topic.indexOf('%SYS') == -1"
          >手动提交消息</a
        >
        <a-divider type="vertical" v-if="record.topic.indexOf('%SYS') == -1" />
        <a
          href="javascript:;"
          @click="resetClick(record)"
          style="color: #ff4d4f"
          v-if="record.topic.indexOf('%SYS') == -1"
          >重置OFFSET</a
        >
        <a-divider type="vertical" v-if="record.topic.indexOf('%SYS') == -1" />
        <a-dropdown :trigger="['click']">
          <a class="ant-dropdown-link" href="#">
            其他 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a href="javascript:;" @click="statusClick(record)">状态</a>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="routerClick(record)">路由</a>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="configClick(record)">配置</a>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="bindClick(record)">关联项目</a>
            </a-menu-item>
            <a-menu-item>
              <a-popconfirm
                placement="right"
                okText="确认"
                cancelText="取消"
                @confirm="deleteClick(record)"
                v-if="record.topic.indexOf('%SYS') == -1"
              >
                <template slot="title"
                  >确定要删除名为【{{ record.topic }}】的Topic吗?</template
                >
                <a href="javascript:;" style="color: #ff4d4f">删除</a>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      title="新增"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="800px"
    >
      <a-form>
        <a-form-item
          label="clusterName:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="clusterNameList"
            placeholder="Select Some Options"
            allowClear
            mode="multiple"
          >
            <a-select-option v-for="item in clusterNameList1" :key="item">{{
              item
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="BROKER_NAME:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="brokerNameList"
            placeholder="Select Some Options"
            allowClear
            mode="multiple"
          >
            <a-select-option v-for="item in brokerNameList1" :key="item">{{
              item
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="topicName:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="topicName" placeholder="请输入topicName" />
        </a-form-item>
        <a-form-item
          label="writeQueueNums:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input
            v-model="writeQueueNums"
            placeholder="请输入writeQueueNums"
          />
        </a-form-item>
        <a-form-item
          label="readQueueNums:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="readQueueNums" placeholder="请输入readQueueNums" />
        </a-form-item>
        <a-form-item
          label="perm:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="perm" placeholder="请输入perm" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- STATUS弹框 -->
    <a-modal
      :title="statusTitle"
      v-model="statusVisible"
      :maskClosable="false"
      width="980px"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(statusColumns)"
        :columns="statusColumns"
        :dataSource="statusDateSource"
        :loading="loadingTable"
        :pagination="false"
        size="small"
      >
        <span slot="lastUpdateTimestamp" slot-scope="text">
          {{ text }}
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="statusVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
    <!-- Router弹框 -->
    <a-modal
      :title="routerTitle"
      v-model="routerVisible"
      :maskClosable="false"
      width="1200px"
    >
      <div class="routerItem">
        <div class="itemLeft">brokerDatas</div>
        <div class="itemRight" v-for="(item, key) in brokerDatas" :key="key">
          <div class="rAll">
            <div class="rTable">
              <div class="divLeft">broker:</div>
              <div class="divRight">{{ item.brokerName }}</div>
            </div>
            <div class="rTable">
              <div class="divLeft">brokerAddrs:</div>
              <div class="divRight">{{ item.brokerAddrs }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="routerItem">
        <div class="itemLeft">queueDatas</div>
        <div class="itemRight" v-for="(item, key) in queueDatas" :key="key">
          <div class="rAll">
            <div class="rTable">
              <div class="divLeft">BROKER_NAME:</div>
              <div class="divRight">{{ item.brokerName }}</div>
            </div>
            <div class="rTable">
              <div class="divLeft">readQueueNums:</div>
              <div class="divRight">{{ item.readQueueNums }}</div>
            </div>
            <div class="rTable">
              <div class="divLeft">writeQueueNums:</div>
              <div class="divRight">{{ item.writeQueueNums }}</div>
            </div>
            <div class="rTable">
              <div class="divLeft">perm:</div>
              <div class="divRight">{{ item.perm }}</div>
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="routerVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
    <!-- manage弹框 -->
    <a-modal
      :title="manageTitle"
      v-model="manageVisible"
      :maskClosable="false"
      :afterClose="manageClose"
      width="980px"
    >
      <div v-if="manageData">
        <div class="manage" v-if="manageData">
          <div>SubscriptionGroup</div>
          <div>{{ manageData }}</div>
          <div>Delay</div>
          <div>{{ manageObj.diffTotal }}</div>
          <div>LastConsumeTime</div>
          <div>
            {{ moment(manageObj.lastTimestamp).format("YYYY-MM-DD HH:mm:ss") }}
          </div>
        </div>
        <a-table
          :rowClassName="$common.rowClassColor"
          bordered
          :components="$common.getTitle(manageColumns)"
          :columns="manageColumns"
          :dataSource="manageDateSource"
          :loading="loadingTable"
          :pagination="false"
          size="small"
        >
          <span slot="lastTimeStamp" slot-scope="text">
            {{ text }}
          </span>
          <span slot="diffTotal" slot-scope="text, scope">
            {{ scope.brokerOffset - scope.consumerOffset }}
          </span>
        </a-table>
      </div>

      <div v-else>Don't have SubscriptionGroup</div>

      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="manageVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
    <!-- config弹框 -->
    <a-modal
      title="Topic Change"
      v-model="configVisible"
      :afterClose="configClose"
      :maskClosable="false"
      width="700px"
      :footer="null"
    >
      <div v-for="item in configList" :key="item.topicName">
        <a-form>
          <a-form-item
            label="BROKER_NAME:"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 16 }"
          >
            <a-select
              v-model="item.brokerNameList"
              placeholder="请选择BROKER_NAME"
              allowClear
              :defaultActiveFirstOption="false"
              :showArrow="false"
              :filterOption="false"
              mode="multiple"
              disabled
            >
              <a-select-option v-for="d in item.brokerNameList" :key="d">{{
                d
              }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            label="topicName:"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 16 }"
          >
            <a-input
              disabled
              v-model="item.topicName"
              placeholder="请输入topicName"
            />
          </a-form-item>
          <a-form-item
            label="writeQueueNums:"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 16 }"
          >
            <a-input
              v-model="item.writeQueueNums"
              placeholder="请输入writeQueueNums"
            />
          </a-form-item>
          <a-form-item
            label="readQueueNums:"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 16 }"
          >
            <a-input
              v-model="item.readQueueNums"
              placeholder="请输入readQueueNums"
            />
          </a-form-item>
          <a-form-item
            label="perm:"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 16 }"
          >
            <a-input v-model="item.perm" placeholder="请输入perm" />
          </a-form-item>
        </a-form>
        <div
          style="display: flex; justify-content: flex-end; margin-bottom: 20px"
        >
          <a-button key="back" @click="configVisible = false">关闭</a-button>
          <i style="margin-left: 10px"></i>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="config_submit(item)"
            >提交</a-button
          >
        </div>
      </div>

      <!-- <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="configVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="config_submit"
            >确定</a-button
          >
        </div>
      </template> -->
    </a-modal>
    <!-- sendMassage弹框 -->
    <a-modal
      :title="sendMassageTitle"
      v-model="sendMassageVisible"
      :afterClose="sendMassageClose"
      :maskClosable="false"
      width="900px"
    >
      <a-form>
        <a-form-item
          label="Topic:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input disabled v-model="sendTopic" />
        </a-form-item>
        <a-form-item
          label="Tag:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="sendTag" placeholder="请输入Tag" />
        </a-form-item>
        <a-form-item
          label="Key:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="sendKey" placeholder="请输入Key" />
        </a-form-item>
        <a-form-item
          label="Message Body:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-textarea
            v-model="messageBody"
            placeholder="请输入Message Body"
            :rows="4"
          />
        </a-form-item>
      </a-form>

      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="sendMassageVisible = false"
            >关闭</a-button
          >
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="sendMassage_submit"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- sendResult弹框 -->
    <a-modal
      title="SendResult"
      v-model="sendResultVisible"
      :maskClosable="false"
      width="900px"
    >
      <div class="sendResult">
        <div>sendStatus</div>
        <div>{{ sendResultDate.sendStatus }}</div>
      </div>
      <div class="sendResult">
        <div>msgId</div>
        <div>{{ sendResultDate.msgId }}</div>
      </div>
      <div class="sendResult">
        <div>messageQueue</div>
        <div>{{ sendResultDate.messageQueue }}</div>
      </div>
      <div class="sendResult">
        <div>queueOffset</div>
        <div>{{ sendResultDate.queueOffset }}</div>
      </div>
      <div class="sendResult">
        <div>transactionId</div>
        <div>{{ sendResultDate.transactionId }}</div>
      </div>
      <div class="sendResult">
        <div>offsetMsgId</div>
        <div>{{ sendResultDate.offsetMsgId }}</div>
      </div>
      <div class="sendResult">
        <div>regionId</div>
        <div>{{ sendResultDate.regionId }}</div>
      </div>
      <div class="sendResult">
        <div>traceOn</div>
        <div>{{ sendResultDate.traceOn }}</div>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="sendResultVisible = false"
            >CLOSE</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- resetOffset弹框 -->
    <a-modal
      title="resetOffset"
      v-model="resetVisible"
      :maskClosable="false"
      width="980px"
      :afterClose="resetClose"
    >
      <a-form>
        <a-form-item
          label="SubscriptionGroup:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="consumerGroup"
            placeholder="No results match"
            allowClear
            mode="multiple"
          >
            <a-select-option v-for="item in consumerGroupList" :key="item">{{
              item
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="Time:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-date-picker
            show-time
            placeholder="Select Time"
            @change="onChange"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="resetVisible = false">CLOSE</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="reset_submit"
            >COMMIT</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- ResetResult弹框 -->
    <a-modal
      title="ResetResult"
      v-model="resetResultVisible"
      :maskClosable="false"
      width="980px"
    >
      <div class="resultTop">
        <div>GroupName:{{ resultName }}</div>
        <div>State:{{ resultStatus }}</div>
      </div>
      <div class="resultBottom" v-for="(item, key) in resultTable" :key="key">
        <div>{{ item }}</div>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="resetResultVisible = false"
            >CLOSE</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="项目绑定"
      v-model="bindVisible"
      :maskClosable="false"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="Topic:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          {{ topic }}
        </a-form-item>
        <a-form-item
          label="项目:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="projectIdListAdd"
            placeholder="请选择项目"
            allowClear
            showSearch
            mode="multiple"
            option-filter-prop="children"
          >
            <a-select-option v-for="i in projectSelectList" :key="i.id">
              {{ i.enName }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="bindVisible = false">取消</a-button>
          <a-button key="submit" type="primary" @click="bind_submit"
            >确认</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/mqTopic.js";
import { clusterList } from "../lib/mqCluster.js";
import moment from "moment";
// 引入基本模板
import * as echarts from "echarts";
// 引入折线图组件
require("echarts/lib/chart/bar");
import { projectList } from "../lib/projectList.js";

export default {
  name: "mqTopic",
  props: ["clusterId"],
  data() {
    return {
      statusTitle: "",
      statusVisible: false,
      routerTitle: "",
      routerVisible: false,
      brokerDatas: [],
      queueDatas: [],
      configVisible: false,
      configList: [], //config弹框数组数据
      manageTitle: "",
      manageVisible: false,
      manageList: [],
      manageData: "",
      manageObj: {},
      manageDateSource: [],
      sendMassageTitle: "",
      sendMassageVisible: false,
      sendTopic: "",
      sendTag: "tag",
      sendKey: "key",
      messageBody: "messageBody",
      sendResultVisible: false,
      sendResultDate: {},
      //
      title: "",
      topicName: "",
      writeQueueNums: "16",
      readQueueNums: "16",
      perm: "6",
      brokerNameList: [],
      clusterNameList: [],
      brokerNameList1: [],
      clusterNameList1: [],
      groupId: "",
      // -----------------------------
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "Topic",
          ellipsis: true,
          dataIndex: "topic",
          width: 300,
        },
        {
          title: "tps",
          ellipsis: true,
          dataIndex: "tps",
        },
        {
          title: "今日消息量",
          ellipsis: true,
          dataIndex: "todayMessageCount",
        },
        {
          title: "昨日消息量",
          ellipsis: true,
          dataIndex: "yesterdayMessageCount",
        },
        {
          title: "关联项目",
          ellipsis: true,
          dataIndex: "projectList",
          scopedSlots: { customRender: "projectList" },
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      statusColumns: [
        {
          title: "Queue",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "minOffset",
          ellipsis: true,
          dataIndex: "minOffset",
        },
        {
          title: "maxOffset",
          ellipsis: true,
          dataIndex: "maxOffset",
        },
        {
          title: "lastUpdateTimeStamp",
          ellipsis: true,
          dataIndex: "lastUpdateTimestamp",
          customRender: (text, row, index) => {
            return moment(text).format("YYYY-MM-DD HH:mm:ss");
          },
        },
      ],
      manageColumns: [
        {
          title: "Broker",
          ellipsis: true,
          dataIndex: "brokerName",
        },
        {
          title: "Queue",
          ellipsis: true,
          dataIndex: "queueId",
        },
        {
          title: "consumerClient",
          ellipsis: true,
          dataIndex: "clientInfo",
        },
        {
          title: "brokerOffset",
          ellipsis: true,
          dataIndex: "brokerOffset",
        },
        {
          title: "consumerOffset",
          ellipsis: true,
          dataIndex: "consumerOffset",
        },
        {
          title: "diffTotal",
          ellipsis: true,
          dataIndex: "diffTotal",
          scopedSlots: { customRender: "diffTotal" },
        },
        {
          title: "lastTimeStamp",
          ellipsis: true,
          dataIndex: "lastTimeStamp",
          customRender: (text, row, index) => {
            return moment(text).format("YYYY-MM-DD HH:mm:ss");
          },
        },
      ],
      statusDateSource: [],
      tableDataSource: [],
      tableDataSource1: [],
      tablePagination: {},
      loadingTable: false,
      tableDataSourceInput: [],
      tableDataSourceCheck: [],
      // reset
      resetVisible: false,
      consumerGroup: [],
      consumerGroupList: [],
      resetTime: "",
      resetResultVisible: false,
      resultName: "",
      resultTable: [],
      resultStatus: "",
      categories: ["NORMAL"],
      projectSelectList: [],
      projectIdListAdd: [],
      projectId: undefined,
      bindVisible: false,
      topicId: "",
      topic: "",
    };
  },
  watch: {
    clusterId: {
      handler() {
        this.getProject();
      },
    },
  },
  created() {
    if (this.clusterId) {
      this.getProject();
    }
  },
  methods: {
    moment,
    bind_submit() {
      let data = {
        topicId: this.topicId,
        projectIdList: this.projectIdListAdd,
      };
      api.bindProject(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("绑定成功");
          this.bindVisible = false;
          this.query();
        }
      });
    },
    bindClick(value) {
      this.topicId = value.id;
      this.topic = value.topic;
      if (value.projectIdList) {
        this.projectIdListAdd = value.projectIdList;
      } else {
        this.projectIdListAdd = [];
      }
      this.bindVisible = true;
    },
    getProject() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
      };
      projectList(data).then((res) => {
        if (res.result === 200) {
          this.projectSelectList = res.data.list;
          this.query();
        }
      });
    },
    detail(val) {
      this.$router.push({
        path: "/mqTopicDetail",
        query: { topic: val.topic },
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      this.tableDataSourceCheck = [];
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 20,
        categories: this.categories.join(","),
        topic: this.topicName,
        clusterId: this.clusterId,
        projectIds: this.projectId,
      };
      this.getTopicList(data);
    },
    // 获取近7天的消息产生量
    sevenDayProduceInfo() {
      api.sevenDayProduceInfo({ clusterId: this.clusterId }).then((res) => {
        if (res.result === 200) {
          // 基于准备好的dom，初始化echarts实例
          let myChart = echarts.init(document.getElementById("myChart"));
          // 绘制图表
          myChart.setOption({
            // title: {
            //   text: "近7天的消息产生量",
            // },
            color: ["#91cc75"],
            tooltip: {
              trigger: "axis",
            },
            legend: {
              data: ["近7天的消息产生量"],
            },
            grid: {
              left: "3%",
              right: "7%",
              bottom: "3%",
              top: "3%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: res.data.dateList,
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                name: "当日消息产生量/万",
                type: "line",
                data: res.data.countList,
              },
            ],
          });
        }
      });
    },
    // 查询所有topic
    getTopicList(data) {
      this.loadingTable = true;
      api
        .topicList(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSourceInput = list;
            this.tableDataSource = list;
            this.tableDataSource.forEach((item) => {
              let projectList = [];
              if (item.projectIdList) {
                item.projectIdList.forEach((projectId) => {
                  this.projectSelectList.forEach((project) => {
                    if (projectId == project.id) {
                      projectList.push(project.enName);
                    }
                  });
                });
              }
              this.$set(item, "projectList", projectList);
            });
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 新增
    addClick() {
      this.clusterNameList1 = [];
      this.addKeyVisible = true;
      this.topicName = "";
      clusterList({ clusterId: this.clusterId }).then((res) => {
        if (res.result === 200) {
          let obj = res.data.clusterInfo.clusterAddrTable;
          console.log(Object.keys(obj)[0], Object.values(obj)[0]);
          this.clusterNameList1.push(Object.keys(obj)[0]);
          this.brokerNameList1 = Object.values(obj)[0];
        }
      });
    },
    // 确定新增
    add_submit() {
      let data = {
        writeQueueNums: this.writeQueueNums,
        readQueueNums: this.readQueueNums,
        perm: this.perm,
        order: false,
        topicName: this.topicName,
        brokerNameList: this.brokerNameList,
        clusterNameList: this.clusterNameList,
        clusterId: this.clusterId,
      };
      api.createOrUpdate(data).then((res) => {
        if (res.result === 200) {
          this.addKeyVisible = false;
          this.$message.success("添加成功");
          this.query();
        }
      });
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.topicName = "";
      this.groupId = "";
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        topic: val.topic
          .replace("%RETRY%", "")
          .replace("%SYS%", "")
          .replace("%DLQ%", ""),
        clusterId: this.clusterId,
      };
      api.deleteTopic(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    paixu(ary, key) {
      return ary.sort(function (a, b) {
        let x = a[key];
        let y = b[key];
        return x > y ? -1 : x < y ? 1 : 0;
      });
    },
    // input搜索框发生变化
    inputChange(val) {
      this.tableDataSource = this.tableDataSourceInput.filter(
        (el) => el.topic.indexOf(this.topicName) != -1
      );
    },
    goTopic(val) {
      this.$router.push({ path: "/topic", query: { topic: val.topic } });
    },
    // status点击
    statusClick(val) {
      this.statusVisible = true;
      let data = val.topic
        .replace("%RETRY%", "")
        .replace("%SYS%", "")
        .replace("%DLQ%", "");
      this.statusTitle = "[" + data + "]Status";
      this.getStatsList(data);
    },
    // 获取status表格
    getStatsList(val) {
      api.statsList({ topic: val, clusterId: this.clusterId }).then((res) => {
        if (res.result === 200) {
          let obj = res.data.offsetTable;
          let arr = [];
          let arr1 = [];
          for (var i in obj) {
            arr.push(obj[i]);
            arr1.push(i);
          }
          for (var i in arr) {
            arr[i].topic = arr1[i];
          }
          this.statusDateSource = arr;
        }
      });
    },
    // router点击-------------------------------------------------
    routerClick(val) {
      this.routerVisible = true;
      let data = val.topic
        .replace("%RETRY%", "")
        .replace("%SYS%", "")
        .replace("%DLQ%", "");
      this.routerTitle = "[" + data + "]Router";
      api.routeList({ topic: data, clusterId: this.clusterId }).then((res) => {
        if (res.result === 200) {
          this.brokerDatas = res.data.brokerDatas;
          this.queueDatas = res.data.queueDatas;
        }
      });
    },
    // CONSUMER MANAGE点击-------------------------------------------------
    manageClick(val) {
      let data = val.topic
        // .replace("%RETRY%", "")
        .replace("%SYS%", "")
        .replace("%DLQ%", "");
      this.manageVisible = true;
      this.manageTitle = data;
      this.getQueryConsumerByTopic(data);
    },
    getQueryConsumerByTopic(val) {
      api
        .queryConsumerByTopic({ topic: val, clusterId: this.clusterId })
        .then((res) => {
          // if (res.result === 200) {
          this.manageData = Object.keys(res)[0];
          this.manageObj = Object.values(res)[0];
          this.manageList = this.manageObj.queueStatInfoList;
          this.manageDateSource = this.manageList;
          // }
        });
    },
    manageClose() {
      this.manageList = [];
      this.manageData = "";
      this.manageObj = {};
    },
    // config点击----------------------------------------------------------
    configClick(val) {
      let data = val.topic
        .replace("%RETRY%", "")
        .replace("%SYS%", "")
        .replace("%DLQ%", "");
      this.configVisible = true;
      this.getConfigList(data);
    },
    getConfigList(val) {
      api.configList({ topic: val, clusterId: this.clusterId }).then((res) => {
        if (res.result === 200) {
          this.configList = res.data;
        }
      });
    },
    config_submit() {},
    configClose() {
      this.configList = [];
    },
    // sendMassage点击----------------------------------------------------------
    sendMassageClick(val) {
      let data = val.topic
        .replace("%RETRY%", "")
        .replace("%SYS%", "")
        .replace("%DLQ%", "");
      this.sendMassageVisible = true;
      this.sendMassageTitle = "Send[" + data + "]Message";
      this.sendTopic = data;
    },
    sendMassageClose() {
      this.sendTopic = "";
      this.sendKey = "key";
      this.sendTag = "tag";
      this.messageBody = "messageBody";
    },
    sendMassage_submit() {
      let data = {
        topic: this.sendTopic,
        key: this.sendKey,
        tag: this.sendTag,
        messageBody: this.messageBody,
        clusterId: this.clusterId,
      };
      api.sendTopicMessage(data).then((res) => {
        if (res.result === 200) {
          this.sendResultVisible = true;
          this.sendResultDate = res.data;
        }
      });
    },
    // post------------------------
    // RESET CONSUMER OFFSET
    resetClick(val) {
      let data = val.topic
        .replace("%RETRY%", "")
        .replace("%SYS%", "")
        .replace("%DLQ%", "");
      this.resetVisible = true;
      this.sendTopic = data;
      api.reset({ topic: data, clusterId: this.clusterId }).then((res) => {
        if (res.result === 200) {
          this.consumerGroupList = res.data.groupList;
        }
      });
    },
    resetClose() {
      this.consumerGroup = [];
    },
    onChange(value, dateString) {
      this.resetTime = moment(dateString).valueOf();
    },
    reset_submit() {
      let data = {
        consumerGroupList: this.consumerGroup,
        force: true,
        resetTime: this.resetTime,
        topic: this.sendTopic,
        clusterId: this.clusterId,
      };
      api.resetOffset(data).then((res) => {
        if (res.result === 200) {
          this.resetResultVisible = true;
          this.resetVisible = false;
          this.resultName = Object.keys(res.data)[0];
          this.resultTable = Object.values(res.data)[0].rollbackStatsList;
          this.resultStatus = Object.values(res.data)[0].status;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.chart {
  width: 520px;
  height: 200px;
  margin-bottom: 10px;
  #myChart {
    width: 100%;
    height: 100%;
  }
}
.manage {
  width: 100%;
  height: 50px;
  background-color: #e0e0e0;
  display: flex;
  margin-bottom: 20px;
  div {
    height: 100%;
    width: 15%;
    border: 1px solid #adadad;
    text-align: center;
    line-height: 50px;
  }
  div:nth-child(2) {
    border-right-width: 0;
    border-left-width: 0;
    width: 35%;
  }
  div:nth-child(3) {
    width: 10%;
  }
  div:nth-child(4) {
    border-right-width: 0;
    border-left-width: 0;
    width: 10%;
  }
  div:nth-child(6) {
    border-left-width: 0;
    width: 20%;
  }
}
.sendResult {
  // text-align: center;
  line-height: 40px;
  height: 40px;
  width: 100%;
  border-right: 1px solid #adadad;
  border-left: 1px solid #adadad;
  border-bottom: 1px solid #adadad;
  display: inline-flex;
  div:nth-child(1) {
    width: 20%;
    padding-left: 10px;
    font-size: 15px;
    border-right: 1px solid #adadad;
  }
  div:nth-child(2) {
    width: 80%;
    padding-left: 10px;
    font-size: 16px;
  }
}
.sendResult:nth-child(1) {
  border-top: 1px solid #adadad;
}
.routerItem {
  box-sizing: border-box;
  width: 100%;
  border-right: 1px solid #adadad;
  border-left: 1px solid #adadad;
  border-bottom: 1px solid #adadad;
  display: inline-flex;
  .itemLeft {
    width: 20%;
    padding: 10px;
    font-size: 15px;
    border-right: 1px solid #adadad;
  }
  .itemRight {
    width: 80%;
    padding: 10px;
    font-size: 16px;
    .rAll {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
      background-color: #f2f2f2;
      .rTable {
        // height: 40px;
        line-height: 40px;
        width: 100%;
        border-right: 1px solid #adadad;
        border-left: 1px solid #adadad;
        border-bottom: 1px solid #adadad;
        display: inline-flex;
        .divLeft {
          width: 40%;
          font-size: 15px;
          border-right: 1px solid #adadad;
          padding-left: 10px;
        }
        .divRight {
          width: 60%;
          font-size: 15px;
          padding-left: 10px;
        }
      }
      .rTable:nth-child(1) {
        border-top: 1px solid #adadad;
      }
    }
  }
}
.routerItem:nth-child(1) {
  border-top: 1px solid #adadad;
}
.resultTop {
  width: 100%;
  height: 50px;
  background-color: #e0e0e0;
  display: flex;
  margin-bottom: 20px;
  div {
    height: 100%;
    width: 80%;
    border: 1px solid #adadad;
    line-height: 50px;
    padding-left: 20px;
  }
  div:nth-child(2) {
    border-left-width: 0;
    width: 20%;
  }
}
.resultBottom {
  width: 100%;
  background-color: #e0e0e0;
  div {
    height: 40px;
    width: 100%;
    border: 1px solid #adadad;
    line-height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    border-top-width: 0;
    border-bottom-width: 0;
  }
  div:nth-child(1) {
    border-top: 1px solid #adadad;
  }
  div:last-child {
    border-bottom: 1px solid #adadad;
  }
}
</style>
