<template>
  <div class="policyPage">
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="name"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addClick" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="jurisdictionClick(record)">权限管理</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input v-model="nameAdd" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item
          label="编码:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input v-model="codeAdd" placeholder="请输入编码" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 授权 -->
    <a-modal
      title="授权"
      v-model="jurisdictionShow"
      :maskClosable="false"
      :afterClose="jurisdictionClose"
      width="80%"
    >
      <div class="modalTable">
        <a-row style="margin-bottom: 10px">
          <a-col :span="6">
            <a-input-search
              style="width: 300px"
              placeholder="备注名称模糊搜索"
              enter-button
              v-model="searchName"
              @search="onSearch"
              allowClear
          /></a-col>
        </a-row>
        <a-table
          :rowClassName="$common.rowClassColor"
          bordered
          :components="$common.getTitle(modalColumns)"
          :columns="modalColumns"
          :dataSource="modalSource1"
          :loading="loadingModal"
          size="small"
          bordered
          :customRow="rowClick"
        >
        </a-table>
      </div>
      <div class="modalCard">
        <a-card :title="cardTitle">
          <a slot="extra" @click="empty">清空</a>
          <div
            class="cardContent"
            v-for="item in accountPoliciesList"
            :key="item"
          >
            <div class="cardLeft">{{ item }}</div>
            <div class="cardDel" @click="jurisdictionDel(item)">X</div>
          </div>
        </a-card>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="jurisdictionShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="jurisdiction_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/policyPage.js";

export default {
  name: "policyPage",
  data() {
    return {
      title: "",
      name: "",
      nameAdd: "",
      codeAdd: "",
      editId: "",
      // -----------------------------
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "编码",
          ellipsis: true,
          dataIndex: "code",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      jurisdictionShow: false,
      loadingModal: false,
      modalSource: [],
      modalSource1: [],
      searchName: "",
      modalColumns: [
        {
          title: "权限名称",
          ellipsis: true,
          dataIndex: "description",
          scopedSlots: { customRender: "description" },
          filteredValue: this.searchName ? this.searchName : null,
        },
        {
          title: "权限编码",
          ellipsis: true,
          dataIndex: "policyName",
        },
      ],
      accountPoliciesList: [],
      cardTitle: "已选择（0）",
      majorKeyId: "",
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        name: this.name,
      };
      this.getPolicyTypePage(data);
    },
    // 查询告警组分页列表
    getPolicyTypePage(data) {
      this.loadingTable = true;
      api
        .policyTypePage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.addKeyVisible = true;
      this.editId = val.id;
      this.nameAdd = val.name;
      this.codeAdd = val.code;
    },
    // 新增
    addClick() {
      this.title = "新增";
      this.addKeyVisible = true;
    },
    // 确定新增
    add_submit() {
      let data = {
        name: this.nameAdd,
        code: this.codeAdd,
      };
      if (this.title === "新增") {
        api.addPolicyType(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.id = this.editId;
        api.editPolicyType(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.nameAdd = "";
      this.codeAdd = "";
      this.editId = "";
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deletePolicyType(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    onSearch() {
      if (this.searchName && this.searchName !== "") {
        this.modalSource1 = this.modalSource.filter(
          (p) => p.description.indexOf(this.searchName) !== -1
        );
      } else {
        this.modalSource1 = this.modalSource;
      }
    },
    // 获取阿里云所有服务器集合
    getListPolicies() {
      api.listPolicies().then((res) => {
        if (res.result === 200) {
          this.modalSource = res.data;
          this.modalSource1 = this.modalSource;
          this.loadingModal = false;
        }
      });
    },
    // 获取当前账号拥有的权限
    getListAccountPolicies(data) {
      api.listAccountPolicies({ id: data }).then((res) => {
        if (res.result === 200) {
          this.accountPoliciesList = res.data;
          this.cardTitle = "已选择(" + this.accountPoliciesList.length + ")";
        }
      });
    },
    // 点击授权
    jurisdictionClick(val) {
      this.jurisdictionShow = true;
      this.loadingModal = true;
      this.getListPolicies();
      this.getListAccountPolicies(val.id);
      this.majorKeyId = val.id;
    },
    // 授权弹框关闭
    jurisdictionClose() {
      this.searchName = "";
      this.accountPoliciesList = [];
    },
    // 点击清空
    empty() {
      this.accountPoliciesList = [];
      this.cardTitle = "已选择(" + this.accountPoliciesList.length + ")";
    },
    // 确定授权
    jurisdiction_submit() {
      let data = {
        id: this.majorKeyId,
        policyList: this.accountPoliciesList,
      };
      api.editAccountPolicy(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("授权成功");
          this.jurisdictionShow = false;
        }
      });
    },
    // 点击删除已授权
    jurisdictionDel(val) {
      Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        if (index > -1) {
          this.splice(index, 1);
        }
      };
      this.accountPoliciesList.remove(val);
      this.cardTitle = "已选择(" + this.accountPoliciesList.length + ")";
    },

    // 点击行
    rowClick(record, index) {
      return {
        on: {
          click: (event) => {
            if (this.accountPoliciesList.indexOf(record.policyName) == -1) {
              this.accountPoliciesList.push(record.policyName);
              this.cardTitle =
                "已选择(" + this.accountPoliciesList.length + ")";
            } else {
              this.$message.warning("已授权");
            }
          },
        },
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.modalTable {
  width: 70%;
  display: inline-block;
}
.modalCard {
  width: 25%;
  height: 62%;
  position: absolute;
  display: inline-block;
  margin-left: 30px;
  margin-top: 40px;
  .ant-card {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}
.cardContent {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  .cardDel {
    cursor: pointer;
  }
}
</style>
