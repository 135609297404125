<template>
  <div class="m-right-menu" id="m_right_menu">
    <ul>
      <slot></slot>
    </ul>
  </div>
</template>
<script>
export default {
  name: "mRightMenu",
  props: {
    menuData: Object,
  },
  data() {
    return {};
  },
  mounted() {
    let div = document.getElementById("m_right_menu");
    div.style.left = this.menuData.left;
    div.style.top = this.menuData.top;
    div.style.zIndex = 3000;
    div.style.position = "absolute";
    div.style.display = "block";
  },
};
</script>
<style lang="scss" scoped>
/* 右击框 */
.m-right-menu {
  display: none;
  width: 100px;
  border: 1px solid #e1e1e1;
  z-index: 1000;
  background: #fff;
  box-shadow: 0 0 6px rgb(192, 196, 204);
}

.m-right-menu ul {
  margin: 0;
}
</style>
