<template>
  <div>
    <div :style="treeStyle(item.value)" v-for="(item, index) in data">
      <span
        style="display: inline-block; cursor: pointer"
        v-if="item.value.type == 'object'"
        @click="objAni(index)"
      >
        <a-icon type="caret-down" v-if="objShow[index]" />
        <a-icon type="caret-right" v-else />
      </span>
      <a-input
        v-model="item.key"
        placeholder="root"
        style="width: 18.5%; margin-right: 10px; margin-left: 10px"
        :disabled="show"
      />
      <a-checkbox v-model="item.value.required" :disabled="show"></a-checkbox>
      <a-select
        v-model="item.value.type"
        style="width: 10%; margin-right: 20px; margin-left: 10px"
        defaultValue="object"
      >
        <a-select-option
          v-for="(type, index) in datatypeList"
          :key="index"
          :value="type.value"
          >{{ type.label }}</a-select-option
        >
      </a-select>
      <a-input
        v-model="item.value.description"
        placeholder="备注"
        style="width: 20.5%"
      />
      <span class="iconButton" v-if="!show" @click="deleteKey(index)">
        <a-icon type="close" style="color: red" />
      </span>
      <span class="iconButton" v-if="item.value.type == 'object'">
        <a-dropdown>
          <a-icon type="plus" style="color: #2395f1" />
          <a-menu slot="overlay">
            <a-menu-item key="0">
              <span @click="add">添加兄弟节点</span>
            </a-menu-item>
            <a-menu-item key="1">
              <span @click="addObj(item.value)">添加子节点</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
      <span
        class="iconButton"
        @click="add"
        v-if="item.value.type !== 'object' && !show"
      >
        <a-tooltip>
          <template slot="title"> 添加兄弟节点 </template>
          <a-icon type="plus" style="color: #2395f1" />
        </a-tooltip>
      </span>
      <template v-if="item.value.type == 'object' && objShow[index]">
        <tree :data="item.value.properties" :show="false"></tree>
      </template>
      <template v-if="item.value.type == 'array'">
        <tree :data="item.value.properties" :show="true"></tree>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "tree",
  props: ["data", "show"],
  data() {
    return {
      datatypeList: [
        {
          value: "string",
          label: "string",
        },
        {
          value: "number",
          label: "number",
        },
        {
          value: "array",
          label: "array",
        },
        {
          value: "object",
          label: "object",
        },
        {
          value: "integer",
          label: "integer",
        },
        {
          value: "boolean",
          label: "boolean",
        },
      ],
      index: 1,
      objShow: [],
    };
  },
  watch: {
    dataList: {
      deep: true,
      handler(newVal, oldVal) {
        oldVal.forEach((element, index) => {
          if (newVal[index]) {
            if (oldVal[index].value.type !== newVal[index].value.type) {
              if (newVal[index].value.type == "object") {
                this.$set(this.data[index].value, "properties", []);
              } else if (newVal[index].value.type == "array") {
                this.$set(this.data[index].value, "properties", [
                  {
                    key: "items",
                    value: { type: "string", description: "" },
                  },
                ]);
              } else {
                this.$delete(this.data[index].value, "properties");
              }
            }
          }
        });
      },
    },
  },
  computed: {
    dataList() {
      if (Array.isArray) {
        return JSON.parse(JSON.stringify(this.data));
      } else {
        return [];
      }
    },
    treeStyle() {
      return (value) => {
        if (value) {
          if (value.type == "object") {
            return {
              margin: "5px 0 5px 2%",
              width: "100%",
            };
          } else {
            return {
              margin: "5px 0 5px 3%",
              width: "100%",
            };
          }
        }
      };
    },
  },
  created() {
    if (Array.isArray(this.data)) {
      this.data.forEach((item) => {
        this.objShow.push(true);
      });
    }
  },
  methods: {
    objAni(index) {
      this.$set(this.objShow, index, !this.objShow[index]);
    },
    add() {
      let flag = false;
      let index;
      this.data.forEach((item) => {
        if (item.key.indexOf("key_") !== -1) {
          flag = true;
          index = item.key.split("_")[1] * 1;
        }
      });
      if (flag) {
        this.index = index + 1;
      }
      this.data.push({
        key: `key_${this.index}`,
        value: {
          type: "string",
          description: "",
          required: true,
        },
      });
      this.objShow.push(true);
      this.index++;
    },
    addObj(val) {
      let flag = false;
      let index;
      val.properties.forEach((item) => {
        if (item.key.indexOf("key_") !== -1) {
          flag = true;
          index = item.key.split("_")[1] * 1;
        }
      });
      if (flag) {
        this.index = index + 1;
      }
      val.properties.push({
        key: `key_${this.index}`,
        value: {
          type: "string",
          description: "",
          required: true,
        },
      });
      this.index++;
    },
    deleteKey(index) {
      this.data.splice(index, 1);
    },
  },
};
</script>

<style>
</style>