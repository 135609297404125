<template>
  <div
    style="height: 100%; width: 100vw"
    @click="(expandShow = false), (isActive = false)"
  >
    <div
      :class="{ menu: true, expand: expandShow }"
      @click.stop="expandShow = true"
    >
      <div style="width: 100%; height: 50px; line-height: 50px">
        <div class="trigger" @click.stop="expand">
          <span style="transform: translate(-7px, 0)"></span>
          <span style="transform: translate(7px, 0)"></span>
          <span style="transform: translate(0, -7px)"></span>
          <span style="transform: translate(0, 7px)"></span>
          <span style="transform: translate(7px, 7px)"></span>
          <span style="transform: translate(-7px, -7px)"></span>
          <span></span>
          <span style="transform: translate(-7px, 7px)"></span>
          <span style="transform: translate(7px, -7px)"></span>
        </div>
        <span class="title-theme">{{ name }}</span>
      </div>
      <div class="flex">
        <div
          class="item"
          v-for="(item, index) in flexList"
          :key="index"
          @click.stop="listChange(item)"
        >
          <div class="icon">
            <a-icon :type="item.iconType"></a-icon>
          </div>
          <div class="title">{{ item.name }}</div>
        </div>
        <div class="spaceItem"></div>
        <div class="spaceItem"></div>
      </div>
    </div>
    <div
      :class="{ notice: true, noticeActive: isActive }"
      @click.stop="isActive = true"
    >
      <div class="header-cc" style="padding-right: 30px">
        <a-dropdown class="user-name-cc" style="float: right">
          <span style="cursor: pointer; padding-left: 10px; font-size: 16px">
            <a-avatar
              class="avatar"
              size="small"
              shape="circle"
              :src="loginAvatarUrl"
              v-if="loginAvatarUrl"
            />
            <span>{{ loginNickName }}</span>
          </span>
          <a-menu style="width: 150px" slot="overlay">
            <a-menu-item>
              <a
                href="javascript:void(0)"
                @click="logOut()"
                style="
                  display: inline-block;
                  text-align: center;
                  width: 100%;
                  padding: 0;
                  margin: 0;
                "
              >
                <a-icon type="poweroff" />
                <span>退出登录</span>
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <div
          style="float: right; position: relative"
          class="noticeHover"
          @click.stop="isActive = !isActive"
        >
          <a-badge
            :count="count"
            :number-style="{ backgroundColor: '#1b9aee' }"
            :offset="[-20, 5]"
            style="cursor: pointer"
          >
            <a-button type="link">
              <a-icon
                v-if="isActive"
                type="bell"
                style="font-size: 18px; vertical-align: middle; color: #1b9aee"
              />
              <a-icon
                v-else
                type="bell"
                style="font-size: 18px; vertical-align: middle; color: grey"
              />
            </a-button>
          </a-badge>
        </div>
      </div>
      <div style="padding: 10px 10px 10px 5px; overflow: auto">
        <a-dropdown style="float: left">
          <span style="cursor: pointer">
            <a-button type="link" style="color: grey" v-if="active == '1'">
              <a-icon type="bell" />
              <span>全部通知</span>
            </a-button>
            <a-button type="link" style="color: grey" v-else>
              <span class="weidu"></span>
              <span style="margin-left: 8px">未读通知</span>
            </a-button>
            <a-icon
              type="down"
              style="font-size: 18px; color: grey; vertical-align: middle"
            />
          </span>
          <a-menu style="width: 200px" slot="overlay">
            <a-menu-item @click="allNotice">
              <a-button
                type="link"
                style="color: grey; width: 200px; text-align: left"
              >
                <a-icon type="bell" />
                <span>全部通知</span>
                <a-icon
                  v-if="active == '1'"
                  type="check"
                  style="
                    float: right;
                    vertical-align: middle;
                    margin-right: 10px;
                  "
                />
              </a-button>
            </a-menu-item>
            <a-menu-item @click="unreadNotice">
              <a-button
                type="link"
                style="color: grey; width: 200px; text-align: left"
              >
                <span class="weidu"></span>
                <span style="margin-left: 8px">未读通知</span>
                <a-icon
                  v-if="active == '2'"
                  type="check"
                  style="
                    float: right;
                    vertical-align: middle;
                    margin-right: 10px;
                  "
                />
              </a-button>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <a-dropdown style="float: right">
          <span style="cursor: pointer">
            <a-button type="link">
              <a-icon type="ellipsis" style="font-size: 18px; color: grey" />
            </a-button>
          </span>
          <a-menu slot="overlay">
            <a-menu-item @click="readNotice">
              <a-button type="link" style="color: grey">
                <a-icon type="ordered-list" />
                <span>标记所有为已读</span>
              </a-button>
            </a-menu-item>
            <a-menu-item @click="deleteAllReadNotice">
              <a-button type="link" style="color: grey">
                <a-icon type="delete" />
                <span>删除所有已读消息</span>
              </a-button>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
      <div
        class="noticeContent"
        style="height: calc(100vh - 102px); overflow-y: scroll"
        v-infinite-scroll="handleInfiniteOnLoad"
        infinite-scroll-throttle-delay="500"
        :infinite-scroll-disabled="busy"
        :infinite-scroll-distance="0"
      >
        <template v-for="(item, index) in noticeArr">
          <notice
            :item="item"
            :index="index"
            :key="index"
            @deleteNotice="deleteNotice"
          ></notice>
        </template>
        <div v-if="!busy" class="demo-loading-container">
          <a-spin />
        </div>
      </div>
    </div>
    <a-layout id="components-layout-demo-custom-trigger">
      <a-layout-header
        style="
          background: #fff;
          padding: 0 30px 0 0;
          position: fixed;
          width: 100%;
          height: 50px;
          box-shadow: 0 4px 16px 0 rgb(0 0 0 / 15%);
          z-index: 999;
        "
      >
        <div class="header-cc">
          <div style="height: 100%; line-height: 50px; float: left">
            <div class="trigger" @click.stop="expand">
              <span style="transform: translate(-7px, 0)"></span>
              <span style="transform: translate(7px, 0)"></span>
              <span style="transform: translate(0, -7px)"></span>
              <span style="transform: translate(0, 7px)"></span>
              <span style="transform: translate(7px, 7px)"></span>
              <span style="transform: translate(-7px, -7px)"></span>
              <span></span>
              <span style="transform: translate(-7px, 7px)"></span>
              <span style="transform: translate(7px, -7px)"></span>
            </div>
            <span class="title-theme">{{ name }}</span>
            <div
              class="search"
              @click.stop="quickStarterSearch"
              v-if="name !== '容器组' && name !== '消息队列'"
            >
              <a-input
                placeholder="请输入关键词搜索"
                v-model="keyword"
                @change="quickStarterSearch"
                @focus="searchFocus"
                @keydown.stop="keyCheck"
                @keyup.stop=""
              />
              <div ref="searchList" class="list" v-if="searchShow">
                <div
                  :class="{ item: true, itemActive: index == searchIndex }"
                  :ref="'item' + index"
                  v-for="(item, index) in searchList"
                  @click="goPageURL(item)"
                  :key="index"
                >
                  <div class="title">
                    {{ item.title }}
                  </div>
                  <div class="group">
                    {{ item.group }}
                  </div>
                </div>
              </div>
            </div>
            <template v-if="name == '消息队列'">
              <a-select
                v-model="clusterId"
                style="width: 240px; margin: 0 10px"
                showSearch
                @search="getMqClusterInfo(1, $event)"
              >
                <a-select-option
                  v-for="item in mqClusterInfoData"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
              <a-tooltip>
                <template slot="title"> 集群管理 </template>
                <a-button type="link" @click="mqClusterInfoVisible = true">
                  <icon-font type="icon-peizhiguanli" />
                </a-button>
              </a-tooltip>
            </template>
          </div>
          <a-dropdown class="user-name-cc" style="float: right">
            <span style="cursor: pointer; padding-left: 10px; font-size: 16px">
              <a-avatar
                class="avatar"
                size="small"
                shape="circle"
                :src="loginAvatarUrl"
                v-if="loginAvatarUrl"
              />
              <span>{{ loginNickName }}</span>
            </span>
            <a-menu style="width: 150px" slot="overlay">
              <a-menu-item>
                <a
                  href="javascript:void(0)"
                  @click="logOut()"
                  style="
                    display: inline-block;
                    text-align: center;
                    width: 100%;
                    padding: 0;
                    margin: 0;
                  "
                >
                  <a-icon type="poweroff" />
                  <span>退出登录</span>
                </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <div class="noticeHover" @click.stop="isActive = !isActive">
            <a-badge
              :count="count"
              :number-style="{ backgroundColor: '#1b9aee' }"
              :offset="[-20, 5]"
              style="cursor: pointer"
            >
              <a-button type="link">
                <a-icon
                  v-if="isActive"
                  type="bell"
                  style="
                    font-size: 18px;
                    vertical-align: middle;
                    color: #1b9aee;
                  "
                />
                <a-icon
                  v-else
                  type="bell"
                  style="font-size: 18px; vertical-align: middle; color: grey"
                />
              </a-button>
            </a-badge>
          </div>
        </div>
      </a-layout-header>
      <a-layout>
        <a-layout-sider
          v-model="collapsed"
          :trigger="null"
          collapsible
          :style="{
            overflow: 'auto',
            height: '100%',
            position: 'fixed',
            top: '50px',
            left: 0,
          }"
          width="180"
          theme="light"
        >
          <a-menu
            mode="inline"
            :selectedKeys="[selectedKeys]"
            :openKeys="openKeys"
            @click="selectClick"
            @openChange="onOpenChange"
          >
            <a-menu-item v-for="menu in list" :key="menu.url">
              <icon-font :type="menu.iconType" />
              <span>{{ menu.name }}</span>
            </a-menu-item>
          </a-menu>
          <div class="setting">
            <a-button
              class="settingButton"
              @click="visible = true"
              v-if="settingShow"
              icon="setting"
            >
              系统设置
            </a-button>
            <a-icon
              class="switch"
              :type="collapsed ? 'menu-unfold' : 'menu-fold'"
              @click="() => (collapsed = !collapsed)"
            />
          </div>
        </a-layout-sider>
        <a-layout
          id="HomeContent"
          :class="{
            isContent: true,
            onContentPc: isPcShow,
          }"
          :style="{ padding: '10px 16px' }"
        >
          <div
            :class="{ backGroundColor }"
            style="
              background: rgb(255, 255, 255);
              padding: 24px;
              position: relative;
            "
          >
            <template
              v-if="
                name == '容器组' &&
                $route.path.indexOf('/nodeList') == -1 &&
                $route.path.indexOf('/event') == -1 &&
                $route.path.indexOf('/schedulingTemplatePage') == -1 &&
                $route.path.indexOf('/pvList') == -1
              "
            >
              命名空间:
              <a-select
                v-model="namespace"
                placeholder="请选择命名空间"
                style="width: 180px; margin-bottom: 10px"
                showSearch
              >
                <a-select-option
                  v-for="(item, index) in namespaceList"
                  :key="index"
                  :value="item"
                  >{{ item }}</a-select-option
                >
              </a-select>
            </template>
            <keep-alive :include="['monitor']">
              <router-view
                name="second"
                :loginAvatarUrl="loginAvatarUrl"
                :loginNickName="loginNickName"
                :loginName="loginName"
                :workTaskRefresh="workTaskRefresh"
                :clusterId="clusterId"
                :namespace="namespace"
                :namespaceList="namespaceList"
                @stopWorkTaskRefresh="stopWorkTaskRefresh"
              />
            </keep-alive>
          </div>
        </a-layout>
      </a-layout>
    </a-layout>

    <a-modal title="配置" v-model="visible" :maskClosable="false" width="500px">
      <a-form>
        <template v-for="(item, index) in userConfigList">
          <a-form-item
            :label="item.desc"
            :label-col="{ span: 7 }"
            :wrapper-col="{ span: 14 }"
            :key="index"
          >
            <a-switch
              checkedChildren="是"
              unCheckedChildren="否"
              v-model="item.configValue"
              :loading="item.loading"
              @change="updateConfig($event, item)"
            />
          </a-form-item>
        </template>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="visible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="集群管理列表"
      v-model="mqClusterInfoVisible"
      :maskClosable="false"
      width="1000px"
    >
      <div style="margin-bottom: 10px">
        <a-button type="primary" @click="addMqClusterInfo" icon="plus"
          >新增</a-button
        >
      </div>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(mqClusterInfoTableColumns)"
        :columns="mqClusterInfoTableColumns"
        :dataSource="mqClusterInfoData"
        :pagination="mqClusterInfoPagination"
        :loading="mqClusterInfoLoadingTable"
        size="small"
        :rowKey="(record) => record.id"
      >
        <span slot="enabled" slot-scope="text" style="width: 100%">
          <a-switch
            checked-children="已启用"
            un-checked-children="已禁用"
            v-model="record.enabled"
            @change="editMqClusterInfo(record, true)"
          />
        </span>
        <span slot="action" slot-scope="text, record" style="width: 100%">
          <a href="javascript:;" @click="editMqClusterInfo(record)">修改</a>
          <a-divider type="vertical" />
          <a-popconfirm
            placement="right"
            okText="确认"
            cancelText="取消"
            @confirm="deleteMqClusterInfo(record)"
          >
            <template slot="title">确认是否删除{{ record.name }}</template>
            <a href="javascript:;" style="color: #ff4d4f">删除</a>
          </a-popconfirm>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="mqClusterInfoVisible = false"
            >关闭</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="mqClusterInfoEditTitle"
      v-model="mqClusterInfoEditVisible"
      :maskClosable="false"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="集群名称"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-input placeholder="请输入集群名称" v-model="nameAdd" />
        </a-form-item>
        <a-form-item
          label="地址"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-input placeholder="请输入地址" v-model="addressAdd" />
        </a-form-item>
        <a-form-item
          label="版本"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-input placeholder="请输入版本" v-model="versionAdd" />
        </a-form-item>
        <a-form-item
          label="备注"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-input placeholder="请输入备注" v-model="remarkAdd" />
        </a-form-item>
        <a-form-item
          label="是否启用"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-switch
            checkedChildren="是"
            unCheckedChildren="否"
            v-model="enabled"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="mqClusterInfoEditVisible = false"
            >关闭</a-button
          >
          <a-button type="primary" @click="editMqClusterInfo_submit"
            >确认</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import notice from "../components/notice.vue";
import { cookieInfo } from "../lib/terminal";
import { namespaceList } from "../lib/k8s";
import mqClusterInfoApi from "../lib/mqClusterInfo";

export default {
  components: { notice },
  name: "home",
  data() {
    return {
      routerViewShow: false,
      openKeys: [],
      list: [
        {
          name: "发布统计",
          url: "/statistics",
          iconType: "icon-zhexiantu",
        },
        {
          name: "发布申请",
          url: "/request",
          iconType: "icon-fabu",
        },
        {
          name: "项目管理",
          url: "/projectList",
          iconType: "icon-xiangmu",
        },
        {
          name: "错误日志",
          url: "/errorLog",
          iconType: "icon-bug",
        },
        {
          name: "其它配置",
          url: "/otherDeploy",
          iconType: "icon-ziyuan218",
        },
      ],
      rootSubmenuKeys: [],
      loginAvatarUrl: "",
      loginNickName: "",
      loading: false,
      isPc: false,
      expandShow: false,
      flexList: [
        {
          name: "发布管理",
          url: "1",
          iconType: "environment",
          children: [
            {
              name: "发布统计",
              url: "/statistics",
              iconType: "icon-zhexiantu",
            },
            {
              name: "发布申请",
              url: "/request",
              iconType: "icon-fabu",
            },
            {
              name: "项目管理",
              url: "/projectManagement",
              iconType: "icon-xiangmu",
            },
            {
              name: "错误日志列表",
              url: "/errorLog",
              iconType: "icon-bug",
            },
            {
              name: "其它配置",
              url: "/otherDeploy",
              iconType: "icon-ziyuan218",
            },
          ],
        },
        {
          name: "阿里云",
          url: "2",
          iconType: "cloud-server",
          children: [
            {
              name: "服务器列表",
              url: "/serverList",
              iconType: "icon-fuwuqi",
            },
            {
              name: "阿里云账号管理",
              url: "/aliyunAccountManagement",
              iconType: "icon-aliyun",
            },
            {
              name: "域名管理",
              url: "/domainAdmin",
              iconType: "icon-yumingguanli",
            },
            {
              name: "安全组管理",
              url: "/securityGroupManagement",
              iconType: "icon-yunfanghuoqiang",
            },
            {
              name: "公钥管理",
              url: "/publickeyManagement",
              iconType: "icon-yuechi",
            },
            {
              name: "VPN管理",
              url: "/vpnManager",
              iconType: "icon-openvpn",
            },
            {
              name: "证书管理",
              url: "/certificate",
              iconType: "icon-zhengshu1",
            },
            {
              name: "磁盘管理",
              url: "/diskManage",
              iconType: "icon-a-23Ecipan",
            },
            {
              name: "ansible",
              url: "/ansible",
              iconType: "icon-ansible",
            },
            {
              name: "批量执行",
              url: "/batchExecute",
              iconType: "icon-cloudshellyunminglinghang",
            },
            {
              name: "shell定时任务",
              url: "/shell",
              iconType: "icon-piliang",
            },
          ],
        },
        {
          name: "告警管理",
          url: "4",
          iconType: "bell",
          children: [
            {
              name: "告警配置",
              url: "/warning",
              iconType: "icon-ziyuanxhdpi",
            },
          ],
        },
        {
          name: "消息队列",
          url: "5",
          iconType: "message",
          children: [
            {
              name: "Cluster",
              url: "/mqCluster",
              iconType: "icon-cluster",
            },
            {
              name: "Topic",
              url: "/mqTopic",
              iconType: "icon-topic",
            },
            {
              name: "Consumer",
              url: "/mqConsumer",
              iconType: "icon-guke_consumer",
            },
            {
              name: "Producer",
              url: "/mqProducer",
              iconType: "icon-chuangjianren",
            },
          ],
        },
        {
          name: "API网关",
          url: "6",
          iconType: "api",
          children: [
            {
              name: "监听配置",
              url: "/monitor",
              iconType: "icon-jianting",
            },
            {
              name: "nginx实例列表",
              url: "/nginxInstanceList",
              iconType: "icon-Nginx",
            },
            {
              name: "服务器组",
              url: "/serverGroupList",
              iconType: "icon-fuwuqizu",
            },
          ],
        },
        {
          name: "容器组",
          url: "7",
          iconType: "container",
          children: [
            {
              name: "节点列表",
              url: "/nodeList",
              iconType: "icon-jiedian",
            },
            {
              name: "事件通知",
              url: "/event",
              iconType: "icon-shijiantongzhi",
            },
            {
              name: "容器组",
              url: "/podList",
              iconType: "icon-rongqifuwu-rongqizu",
            },
            {
              name: "无状态",
              url: "/deploymentTab",
              iconType: "icon-wuxupaixu",
            },
            {
              name: "有状态",
              url: "/statefulSetTab",
              iconType: "icon-youxupaixu",
            },
            {
              name: "配置与存储",
              url: "/configAndStorage",
              iconType: "icon-config-map",
            },
            {
              name: "路由与服务",
              url: "/ingressAndService",
              iconType: "icon-ingress",
            },
            {
              name: "调度模板",
              url: "/schedulingTemplatePage",
              iconType: "icon-tiaodumoban",
            },
          ],
        },
        {
          name: "系统工具",
          url: "8",
          iconType: "desktop",
          children: [
            {
              name: "工作台",
              url: "/workbench",
              iconType: "icon-gongzuotai",
            },
            {
              name: "代码生成",
              url: "/generatorCode",
              iconType: "icon-daimashengcheng",
            },
            {
              name: "接口管理",
              url: "/interfaceManagement",
              iconType: "icon-jiekouguanli",
            },
            {
              name: "ZookeeperUI",
              url: "/zookeeperUIList",
              iconType: "icon-Zookeeper",
            },
            {
              name: "mvn管理",
              url: "/mvnManagement",
              iconType: "icon-zhilingpeizhi",
            },
            {
              name: "火焰图",
              url: "/flameGraphFile",
              iconType: "icon-hot",
            },
          ],
        },
      ],
      name: "发布管理",
      isActive: false,
      active: "1",
      noticeArr: [],
      count: 0,
      timer: null,
      backGroundColor: false,
      noticePageNo: 0,
      noticeLoading: false,
      busy: true,
      socketURI: "wss://delivery.huiyu.org.cn/ws/remindMsg",
      socket: null,
      socketRefreshTimer: null,
      cookie: "",
      userConfigList: [],
      visible: false,
      openTab: false,
      settingShow: false,
      loginName: "",
      workTaskRefresh: false,
      mqClusterInfoPageNo: 1,
      mqClusterInfoData: [],
      mqClusterInfoTableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "地址",
          ellipsis: true,
          dataIndex: "address",
        },
        {
          title: "版本",
          ellipsis: true,
          dataIndex: "version",
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      mqClusterInfoPagination: {},
      mqClusterInfoLoadingTable: false,
      clusterId: undefined,
      mqClusterInfoName: "",
      mqClusterInfoVisible: false,
      mqClusterInfoEditVisible: false,
      mqClusterInfoEditTitle: "",
      nameAdd: "",
      addressAdd: "",
      versionAdd: "",
      remarkAdd: "",
      enabled: true,
      mqClusterInfoId: "",
      keyword: "",
      searchShow: false,
      searchList: [],
      searchIndex: -1,
      collapsed: true,
      namespaceList: [],
      namespace: "default",
    };
  },
  computed: {
    isPcShow() {
      if (this.collapsed && this.isPc) {
        return true;
      }
      return false;
    },
    selectedKeys() {
      let path = "/" + this.$route.path.split("/")[1];
      return path;
    },
  },
  watch: {
    name: {
      immediate: true,
      handler(newVal) {
        document.title = newVal;
        this.flexList.forEach((item) => {
          if (item.name == newVal) {
            this.list.splice(0);
            this.list.push(...item.children);
          }
        });
      },
    },
    isActive: {
      handler(newVal) {
        if (newVal) {
          this.getNoticeList();
        }
      },
    },
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal.split("/")[1] == "workbench") {
          this.backGroundColor = true;
        } else {
          this.backGroundColor = false;
        }
        if (
          newVal.split("/")[1] == "mqTopicDetail" ||
          newVal.split("/")[1] == "topic"
        ) {
          this.name = "消息队列";
        }
      },
    },
    collapsed: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.settingShow = false;
        } else {
          setTimeout(() => {
            this.settingShow = true;
          }, 200);
        }
      },
    },
  },
  created() {
    this.flexList.forEach((element) => {
      element.children.forEach((item) => {
        if (this.selectedKeys == item.url) {
          this.name = element.name;
        }
      });
    });
    if (this.name == "消息队列") {
      this.getMqClusterInfo();
    }
    if (this.name == "容器组") {
      this.getNameSpaceList();
    }
    window.onresize = () => {
      this.IsPC();
    };
    this.IsPC();
    this.login();
    this.getCookieInfo();
    this.getUserConfigList();

    document.body.addEventListener("click", () => {
      this.searchShow = false;
    });
  },
  methods: {
    getNameSpaceList() {
      namespaceList().then((res) => {
        if (res.result == 200) {
          this.namespaceList = res.data;
        }
      });
    },
    searchFocus() {
      this.searchShow = true;
    },
    quickStarterSearch() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$http
          .get("/quickStarter/search", { keyword: this.keyword })
          .then((res) => {
            if (res.result == 200) {
              this.searchList = res.data;
            }
          });
      }, 200);
    },
    keyCheck(value) {
      if (value.which == 38 && this.searchIndex > 0) {
        this.searchIndex--;
      }
      if (value.which == 40 && this.searchIndex < this.searchList.length - 1) {
        this.searchIndex++;
      }
      if (this.searchIndex >= 0 && this.searchList.length > 0) {
        let offsetTop = this.$refs["item" + this.searchIndex][0].offsetTop;
        let scrollTop = this.$refs.searchList.scrollTop;
        if (offsetTop > scrollTop + 270) {
          this.$refs.searchList.scrollTop = offsetTop + 30 - 300;
        } else if (offsetTop <= scrollTop + 30) {
          this.$refs.searchList.scrollTop -= 100 + scrollTop - offsetTop;
        } else if (this.searchIndex == 0) {
          this.$refs.searchList.scrollTop = 0;
        }
        if (value.which == 13) {
          this.goPageURL(this.searchList[this.searchIndex]);
        }
      }
    },
    goPageURL(item) {
      location.href = item.link;
    },
    addMqClusterInfo() {
      this.mqClusterInfoEditTitle = "新增";
      this.nameAdd = "";
      this.addressAdd = "";
      this.versionAdd = "";
      this.remarkAdd = "";
      this.enabled = true;
      this.mqClusterInfoEditVisible = true;
    },
    editMqClusterInfo(val, enable) {
      this.mqClusterInfoEditTitle = "修改";
      this.mqClusterInfoId = val.id;
      this.nameAdd = val.name;
      this.addressAdd = val.address;
      this.versionAdd = val.version;
      this.remarkAdd = val.remark;
      this.enabled = val.enabled;
      if (enable) {
        this.editMqClusterInfo_submit();
      } else {
        this.mqClusterInfoEditVisible = true;
      }
    },
    editMqClusterInfo_submit() {
      let data = {
        name: this.nameAdd,
        address: this.addressAdd,
        version: this.versionAdd,
        remark: this.remarkAdd,
        enabled: this.enabled,
      };
      if (this.mqClusterInfoEditTitle == "新增") {
        mqClusterInfoApi.addData(data).then((res) => {
          if (res.result == 200) {
            this.$message.success("添加成功");
            this.mqClusterInfoEditVisible = false;
            this.getMqClusterInfo();
          }
        });
      } else {
        data.id = this.mqClusterInfoId;
        mqClusterInfoApi.editData(data).then((res) => {
          if (res.result == 200) {
            this.$message.success("修改成功");
            this.mqClusterInfoEditVisible = false;
            this.getMqClusterInfo();
          }
        });
      }
    },
    deleteMqClusterInfo(val) {
      mqClusterInfoApi.deleteData({ id: val.id }).then((res) => {
        if (res.result == 200) {
          this.$message.success("删除成功");
          this.getMqClusterInfo();
        }
      });
    },
    getMqClusterInfo(index, name) {
      this.mqClusterInfoLoadingTable = true;
      if (index) {
        this.mqClusterInfoPageNo = index;
      }
      let data = {
        pageNo: this.mqClusterInfoPageNo,
        pageSize: 10,
        name: this.mqClusterInfoName,
      };
      if (name) {
        data.name = name;
      }
      mqClusterInfoApi.queryPage(data).then((res) => {
        if (res.result == 200) {
          this.mqClusterInfoData = res.data.records;
          this.clusterId = this.mqClusterInfoData[0].id;
          this.mqClusterInfoPagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: 10,
            current: this.mqClusterInfoPageNo,
            total: res.data.total,
            onChange: (current) => this.mqClusterInfoChangePageItem(current),
          };
          this.mqClusterInfoLoadingTable = false;
        }
      });
    },
    mqClusterInfoChangePageItem(index) {
      this.getMqClusterInfo(index);
    },
    stopWorkTaskRefresh() {
      this.workTaskRefresh = false;
    },
    updateConfig(value, item) {
      item.loading = true;
      this.$http
        .json_post("/delivery/userConfig/updateUserConfig", {
          configCode: item.configCode,
          configValue: value,
        })
        .then((res) => {
          if (res.result == 200) {
            this.$message.success("修改成功");
            this.getUserConfigList();
          }
        });
    },
    getUserConfigList() {
      this.$http.get("/delivery/userConfig/getUserConfigList").then((res) => {
        if (res.result == 200) {
          this.userConfigList = res.data;
          this.userConfigList.forEach((item) => {
            this.$set(item, "configValue", JSON.parse(item.configValue));
            this.$set(item, "loading", false);
            if (item.configCode == "HIDE_MENU_TEXT") {
              this.collapsed = item.configValue;
            }

            if (item.configCode == "OPEN_MODULE_IN_NEW_TAB") {
              this.openTab = item.configValue;
            }
          });
        }
      });
    },
    initSocket() {
      this.socket = new WebSocket(this.socketURI);
      this.socketOnClose();
      this.socketOnMessage();
      this.socketOnOpen();
      this.socketOnError();
    },
    // 链接成功后
    socketOnOpen() {
      this.socket.onopen = () => {
        let data = {
          name: "ESTABLISH",
          type: "DELIVERY",
          uuid: this.cookie,
        };
        this.onSend(data, "xtermEvent:");
        if (this.socketRefreshTimer) {
          clearTimeout(this.socketRefreshTimer);
          this.socketRefreshTimer = null;
        }
        console.log("建立连接");
      };
    },
    // socket消息
    socketOnMessage() {
      this.socket.onmessage = (e) => {
        let data = JSON.parse(e.data);
        if (data.type == "REMIND_MSG_COUNT") {
          this.count = data.count;
        } else if (data.type == "WORK_TASK") {
          this.workTaskRefresh = true;
        }
      };
    },
    // socket关闭
    socketOnClose() {
      this.socket.onclose = (e) => {
        console.log("websocket关闭了");
        if (this.socketRefreshTimer) {
          clearTimeout(this.socketRefreshTimer);
          this.socketRefreshTimer = null;
        }
        this.socketRefreshTimer = setTimeout(() => {
          this.initSocket();
        }, 3000);
      };
    },
    // socket失败
    socketOnError() {
      this.socket.onerror = (e) => {
        this.socket.close();
      };
    },
    // socket发送
    onSend(data, sign) {
      data = typeof data === "object" ? JSON.stringify(data) : data;
      if (sign) {
        data = sign + data;
      }
      this.socket.send(data);
    },
    getCookieInfo() {
      cookieInfo()
        .then((res) => {
          if (res.result === 200) {
            this.cookie = res.data.value;
            this.initSocket();
          }
        })
        .catch((err) => {});
    },
    handleInfiniteOnLoad() {
      this.busy = false;
      if (this.noticeLoading) {
        this.$message.warning("没有数据了");
        this.busy = true;
        return;
      }
      this.getNoticeList(true);
    },
    deleteNotice(index) {
      this.noticeArr.splice(index, 1);
    },
    readNotice() {
      this.$http.get("/remindMsg/readAll").then((res) => {
        if (res.result == 200) {
          this.getNoticeList();
        }
      });
    },
    deleteAllReadNotice() {
      this.$http.get("/remindMsg/deleteAllRead").then((res) => {
        if (res.result == 200) {
          this.getNoticeList();
        }
      });
    },
    getNoticeList(pageFlag) {
      if (!pageFlag) {
        this.noticePageNo = 1;
        this.noticeArr.splice(0);
      } else {
        this.noticePageNo += 1;
      }
      let data = {
        pageNo: this.noticePageNo,
        pageSize: 50,
      };
      this.$http.get("/remindMsg/list", data).then((res) => {
        if (res.result == 200) {
          this.noticeArr.push(...res.data.records);
          this.busy = true;
          if (this.noticeArr.length >= res.data.total) {
            this.noticeLoading = true;
          }
        }
      });
    },
    unreadNotice() {
      this.active = "2";
      this.noticeArr = this.noticeArr.filter((item) => {
        return !item.haveRead;
      });
    },
    allNotice() {
      this.active = "1";
      this.getNoticeList();
    },
    listChange(item) {
      let url =
        process.env.VUE_APP_CURRENTMODE == "dev"
          ? "http://localhost:8080"
          : "https://delivery.huiyu.org.cn";
      url += item.children[0].url;
      if (this.openTab) {
        window.open(url);
      } else {
        location.href = url;
      }
      this.expandShow = false;
    },
    expand() {
      this.expandShow = !this.expandShow;
    },
    // 判断手机or电脑端
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      this.isPc = flag; //true为PC端，false为手机端
      //手机端默认收起菜单3
      if (!this.isPc) {
        this.collapsed = true;
      }
    },
    // 开启关闭导航栏
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    login() {
      //用户登录
      this.$http.get("/alpha/user/info").then((res) => {
        if (res.result == 200) {
          this.routerViewShow = true;
          this.loginNickName = res.userName;
          this.loginAvatarUrl = res.avatar;
          this.loginName = res.loginName;
          let url = window.location.href;
          url = url.split("/");
          this.list.forEach((item) => {
            this.rootSubmenuKeys.push(item.url);
          });
        }
      });
    },
    // 导航选择
    selectClick({ item, key, keyPath }) {
      let url = window.location.href;
      url = url.split("/");
      if (key === "/" + url[3]) {
        // 禁止重复跳转相同路由
        return;
      }
      this.$router.replace(key);
    },
    // 点击退出登录
    logOut() {
      this.$http.post("/alpha/user/logout").then((res) => {
        if (res.result == 200) {
          let url = window.location.href;
          url = url.split("/");
          url = url[0] + "//" + url[2];
          window.location.href = url;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
#components-layout-demo-custom-trigger {
  height: 100%;

  .search {
    width: 240px;
    display: inline-block;
    position: relative;
    margin-left: 10px;

    .list {
      position: absolute;
      width: 238px;
      background-color: #ffffff;
      height: 300px;
      top: 43px;
      left: 10px;
      border-radius: 5px;
      border: 1px solid #d9d9d9;
      box-shadow: 0px 1px 3px rgb(0 0 0 / 13%);
      overflow-y: auto;
      padding: 5px 0;

      .item {
        height: 20px;
        line-height: 20px;
        padding: 5px 10px;
        cursor: pointer;
        box-sizing: content-box;
        display: flex;
        align-items: center;

        .title {
          color: #000000a6;
          font-weight: bold;
        }
        &:hover {
          background-color: #509ee3;
          color: white;
        }
        .group {
          font-size: 12px;
          margin-left: auto;
        }
      }

      .itemActive {
        color: white;
        background-color: #509ee3;
      }
    }
  }

  .avatar {
    margin-right: 10px !important;
  }

  .setting {
    position: absolute;
    bottom: 50px;
    right: 0;
    line-height: 50px;
    width: 100%;

    .settingButton {
      background-color: transparent;
      color: #595959;
      padding: 5px 15px;
      font-weight: 500;
      margin-left: 10px;
      border: none;

      &:hover {
        background-color: #e8e8e8;
        color: #595959;
        border: none;
      }
    }

    .switch {
      font-size: 16px;
      line-height: 30px;
      padding: 0 16px;
      cursor: pointer;
      &:hover {
        color: #1890ff;
      }
    }
  }
  .isContent {
    margin-left: 180px;
    margin-top: 64px;

    .backGroundColor {
      background-color: #f7f7f7 !important;
    }
  }
  .onContentPc {
    margin-left: 50px;
  }
}

.trigger {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  margin: 11px 10px 0 18px;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #0091ff30;
    border-radius: 5px;
    span {
      background-color: #0091ff;
    }
  }
  span {
    position: absolute;
    width: 4px;
    height: 4px;
    background-color: #bfbfbf;
    border-radius: 50%;
  }
}

.title-theme {
  display: inline-block;
  font-weight: bold;
  font-size: 18px;
}

.header-cc {
  height: 50px;
  line-height: 50px;

  .user-name-cc {
    height: 100%;
    vertical-align: initial;
    min-width: 100px;

    :hover {
      background-color: #0091ff30;
    }
  }
  .noticeHover {
    float: right;
    position: relative;
    padding: 0 15px;

    &::v-deep .ant-badge-count {
      height: 15px !important;
      line-height: 15px !important;
      font-size: 10px !important;
    }
    &:hover {
      background-color: #0091ff30;
    }
  }
}

.noticeActive {
  right: 0 !important;
  box-shadow: 0 4px 16px 0 rgb(0 0 0 / 30%);
}
.notice {
  position: fixed;
  top: 0;
  right: -400px;
  height: 100vh;
  width: 400px;
  background-color: #ffffff;
  z-index: 1000;
  transition: all 0.5s;

  .weidu {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url("../assets/weiduxiaoxi.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    vertical-align: middle;
  }
  .noticeContent {
    &::-webkit-scrollbar {
      display: none;
    }

    .demo-loading-container {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
    }
  }
}

.menu {
  position: fixed;
  width: 300px;
  height: 100vh;
  background-color: #ffffff;
  top: 0;
  left: -300px;
  z-index: 1000;
  transition: all 0.5s;

  .flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;

    .item,
    .spaceItem {
      width: 28%;
      height: 100px;
      padding: 10px 0;
      cursor: pointer;
      margin-top: 20px;
    }
    .item {
      &:hover {
        background-color: #ababab63;
        border-radius: 5px;
      }

      .icon {
        font-size: 25px;
        text-align: center;
        padding: 15px;
      }
      .title {
        text-align: center;
        line-height: 20px;
      }
    }
  }
}
.expand {
  left: 0;
  box-shadow: 0 4px 16px 0 rgb(0 0 0 / 30%);
}

.ant-table-body {
  overflow: auto;
}
.ant-table th {
  white-space: nowrap;
}
.ant-table td {
  white-space: nowrap;
  position: relative;
}
.ant-menu-item-active {
  background-color: #e6f7ff5d !important;
}
.ant-menu-item {
  margin: 0 !important;
}
.ant-menu-inline-collapsed {
  width: 50px !important;
}
.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 16px !important;
}
.ant-layout-sider-collapsed {
  width: 50px !important;
  max-width: 50px !important;
  min-width: 50px !important;
}
</style>
