import http from "./axios";

// 查询代码生成列表
export const codeGeneratorLogPage = ({
  configId,
  tableNames,
  pageNo,
  pageSize,
}) => {
  return http.get("/generatorCode/codeGeneratorLogPage", {
    configId: configId,
    tableNames: tableNames,
    pageNo: pageNo,
    pageSize: pageSize,
  });
};

// 新增代码生成
export const addCodeGeneratorLog = ({ configId, tableNames, group }) => {
  return http.json_post("/generatorCode/addCodeGeneratorLog", {
    configId: configId,
    tableNames: tableNames,
    group,
  });
};

// 删除代码生成列表
export const deleteCodeGeneratorLog = ({ id }) => {
  return http.get("/generatorCode/deleteCodeGeneratorLog", {
    id: id,
  });
};

// 项目下拉框
export const codeProjectConfigList = () => {
  return http.get("/generatorCode/codeProjectConfigList", {});
};

// 表名下拉框
export const codeProjectTableList = ({ configId, tableName, newest }) => {
  return http.get("/generatorCode/codeProjectTableList", {
    configId: configId,
    tableName: tableName,
    newest,
  });
};

// 预览代码生成列表
export const preview = ({ id }) => {
  return http.get("/generatorCode/preview", {
    id: id,
  });
};

// 同步
export const syncInfo = ({ id }) => {
  return http.json_post("/generatorCode/syncInfo", {
    id: id,
  });
};

export const groupList = () => {
  return http.get("/generatorCode/groupList");
};
