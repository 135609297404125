import http from "./axios";

export const consumerConnection = ({
  topic,
  clusterId,
  begin,
  end,
  offsetStart,
  offsetEnd,
  queryKey,
  pageSize,
  pageNo,
  content,
}) => {
  return http.get("/rocketmq/dashboard/message/queryMessageByTopic", {
    topic,
    clusterId,
    begin,
    end,
    offsetStart,
    offsetEnd,
    queryKey,
    pageSize,
    pageNo,
    content,
  });
};

export const viewMessage = ({ msgId, topic, clusterId }) => {
  return http.get("/rocketmq/dashboard/message/viewMessage", {
    msgId: msgId,
    topic: topic,
    clusterId,
  });
};

export const viewMessageTraceDetail = ({ msgId, topic, clusterId }) => {
  return http.get("/rocketmq/dashboard/messageTrace/viewMessageTraceDetail", {
    msgId: msgId,
    topic: topic,
    clusterId,
  });
};

export const consumeMessageDirectly = ({
  topic,
  consumerGroup,
  msgId,
  clusterId,
}) => {
  return http.post("/rocketmq/dashboard/message/consumeMessageDirectly", {
    topic: topic,
    consumerGroup: consumerGroup,
    msgId: msgId,
    clusterId,
  });
};
