import http from "./axios";

// 告警配置分页列表
export const getconfigPage = ({ groupId, pageNo, pageSize }) => {
  return http.get("/alarmGroup/configPage", {
    groupId,
    pageNo,
    pageSize,
  });
};

// 新增告警组
export const addAlarmConfig = ({ groupId, code, enabled }) => {
  return http.json_post("/alarmGroup/addAlarmConfig", {
    groupId,
    code,
    enabled,
  });
};

// 修改告警组
export const updateAlarmConfig = ({ id, groupId, code, enabled, config }) => {
  return http.json_post("/alarmGroup/updateAlarmConfig", {
    id,
    groupId,
    code,
    enabled,
    config,
  });
};

// 删除告警组
export const deleteAlarmConfig = ({ id }) => {
  return http.get("/alarmGroup/deleteAlarmConfig", {
    id,
  });
};

// 告警组集合
export const groupList = () => {
  return http.get("/alarmGroup/groupList");
};
