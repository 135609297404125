<template>
  <div class="shellLog">
    <div class="searchCondition">
      <div class="searchConditionItem">
        服务器：
        <a-select
          v-model="serverId"
          placeholder="试试搜索"
          showSearch
          option-filter-prop="children"
          style="width: 240px"
          allowClear
        >
          <a-select-option v-for="item in serverIdList" :key="item.id">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        用户：
        <a-select
          v-model="userLoginName"
          placeholder="请输入选择用户"
          style="width: 240px"
          showSearch
          allowClear
          :filter-option="false"
          @search="searchUserName"
          @blur="searchUserName()"
        >
          <a-select-option
            v-for="item in userLoginNameList"
            :key="item.userId"
            :value="item.loginName"
          >
            {{ item.userName }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addShare()" icon="plus"
          >新增分享</a-button
        >
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="action" slot-scope="text, record">
        <a
          href="javascript:;"
          :disabled="!record.shareAble"
          @click="share(record)"
          >复制分享链接</a
        >
        <a-divider type="vertical" />
        <a href="javascript:;" @click="examine(record)">查看</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <a-modal
      title="新增分享"
      v-model="addKeyVisible"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="服务器:">
          <a-select
            v-model="serverIdAdd"
            placeholder="试试搜索"
            showSearch
            option-filter-prop="children"
            style="width: 240px"
            allowClear
            @change="getServerAccountList"
          >
            <a-select-option v-for="item in serverIdList" :key="item.id">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="服务器账号:">
          <a-select
            v-model="serverAccountIdAdd"
            placeholder="请选择服务器账号"
            style="width: 240px"
            :disabled="!serverIdAdd"
          >
            <a-select-option v-for="item in serverAccountIdList" :key="item.id">
              {{ item.user }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="用户:">
          <a-input
            v-model="userLoginNameAdd"
            placeholder="请输入选择用户"
            style="width: 240px"
          />
        </a-form-item>
        <a-form-item label="有效时长(s):">
          <a-input-number
            placeholder="请输入有效时长，单位为秒"
            style="width: 240px"
            v-model="shareValidSecondsAdd"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button key="submit" type="primary" @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/shellLog.js";
export default {
  name: "shellLog",
  data() {
    return {
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 100,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "用户名称",
          ellipsis: true,
          dataIndex: "userName",
          width: 400,
        },
        {
          title: "系统用户",
          ellipsis: true,
          dataIndex: "user",
        },
        {
          title: "服务器",
          ellipsis: true,
          dataIndex: "serverName",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      serverId: undefined,
      serverIdAdd: undefined,
      serverIdList: [],
      userLoginName: undefined,
      userLoginNameAdd: undefined,
      userLoginNameList: [],
      serverAccountIdAdd: undefined,
      serverAccountIdList: [],
      shareValidSecondsAdd: undefined,
      addKeyVisible: false,
    };
  },
  mounted() {
    this.getServerAll();
    this.query(1);
  },
  methods: {
    getServerAccountList() {
      this.serverAccountIdAdd = undefined;
      api.serverAccountList({ serverId: this.serverIdAdd }).then((res) => {
        if (res.result == 200) {
          this.serverAccountIdList = res.data;
        }
      });
    },
    addShare() {
      this.userLoginNameAdd = undefined;
      this.serverIdAdd = undefined;
      this.serverAccountIdAdd = undefined;
      this.shareValidSecondsAdd = "";
      this.addKeyVisible = true;
    },
    add_submit() {
      let data = {
        userLoginName: this.userLoginNameAdd,
        serverId: this.serverIdAdd,
        serverAccountId: this.serverAccountIdAdd,
        shareValidSeconds: this.shareValidSecondsAdd,
      };
      api.addShare(data).then((res) => {
        if (res.result == 200) {
          this.addKeyVisible = false;
          this.$message.success("新增成功");
          this.query(1);
        }
      });
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        userLoginName: this.userLoginName,
        serverId: this.serverId,
      };
      this.loadingTable = true;
      api
        .logPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource = res.data.records;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    share(val) {
      let data = {
        id: val.id,
      };
      api.getCopyShareLinkData(data).then((res) => {
        if (res.result === 200) {
          var protocol = window.location.protocol;
          var hostname = window.location.hostname;
          var port = window.location.port;
          let url =
            protocol +
            hostname +
            (port ? ":" + port : "") +
            `/sshShell?id=35&uuid=${res.data.sign}&share=${res.data.share}`;
          this.copy(url);
        }
      });
    },
    copy(data) {
      const textarea = document.createElement("textarea");
      textarea.readOnly = "readonly";
      textarea.style.position = "absolute";
      textarea.style.left = "-9999px";
      textarea.value = data;
      document.body.appendChild(textarea);
      textarea.select();
      const result = document.execCommand("Copy");
      if (result) {
        this.$message.success("复制成功");
      }
      document.body.removeChild(textarea);
    },
    examine(val) {
      this.$router.push({ path: "/sshShell", query: { id: val.id } });
    },
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteLog(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    getServerAll() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        enabled: true,
      };
      api.serverList(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item) => {
            item.title = item.publicIp + "(" + item.instanceName + ")";
          });
          this.serverIdList = list;
        }
      });
    },
    searchUserName(val) {
      if (val !== "") {
        //非空
        api.userList({ name: val }).then((res) => {
          if (res.result == 200) {
            this.userLoginNameList = res.data.userList;
          }
        });
      } else {
        //空值
        this.userLoginNameList = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.ant-switch-checked {
  background-color: #52c41a;
}
.label-c-c-c {
  a {
    margin: 0 5px;
  }
}
</style>
