<template>
  <div class="configMap">
    <div class="reminder">配置 <span> configMap </span></div>
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="name"
          @pressEnter="query"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.name"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <span slot="label" slot-scope="text, record">
        <template v-for="(value, key) in record.label">
          <a-tag style="margin-top: 5px"> {{ key }}:{{ value }} </a-tag>
        </template>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button type="link" @click="edit(record)">修改</a-button>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>

    <a-popconfirm
      placement="right"
      okText="确认"
      cancelText="取消"
      @confirm="batchDeleteClick()"
      :disabled="selectedRowKeys.length == 0"
    >
      <template slot="title">确认是否批量删除</template>
      <a-button
        type="danger"
        icon="delete"
        :disabled="selectedRowKeys.length == 0"
        >批量删除</a-button
      >
    </a-popconfirm>

    <a-drawer
      :title="title"
      placement="right"
      :visible="addVisible"
      @close="addVisible = false"
      width="1000"
    >
      <a-form
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 16 }"
        labelAlign="left"
        style="padding-bottom: 53px"
      >
        <a-form-item label="名称:">
          <a-input v-model="updateData.name" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item label="命名空间:">
          <a-select v-model="updateData.namespace" placeholder="请选择命名空间">
            <a-select-option
              v-for="(item, index) in namespaceList"
              :key="index"
              :value="item"
              >{{ item }}</a-select-option
            >
          </a-select>
        </a-form-item>

        <a-table
          :rowClassName="$common.rowClassColor"
          bordered
          :components="$common.getTitle(columns)"
          :columns="columns"
          :dataSource="data"
          size="small"
          class="editTable"
        >
          <div slot="key" slot-scope="text, record" style="height: 100%">
            <a-form-item
              help="名称只能包含数字、字母、下划线（_）、中划线（-）和小数点（.）"
              :wrapper-col="{ span: 24 }"
            >
              <a-input v-model="record.key" placeholder="请输入key" />
            </a-form-item>
          </div>
          <span slot="value" slot-scope="text, record">
            <a-textarea
              v-model="record.value"
              placeholder="请输入value"
              autosize
            />
          </span>
          <span slot="action" slot-scope="text, record, index">
            <a-popconfirm
              placement="right"
              okText="确认"
              cancelText="取消"
              @confirm="deleteData(index)"
            >
              <template slot="title">确认是否删除</template>
              <a href="javascript:;" style="color: #ff4d4f">删除</a>
            </a-popconfirm>
          </span>
        </a-table>

        <a-button @click="addData" icon="plus" style="width: 100%"
          >添加</a-button
        >
      </a-form>

      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="addVisible = false">
          取消
        </a-button>
        <a-button type="primary" @click="addSubmit"> 确定 </a-button>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import * as api from "../lib/k8s.js";

export default {
  name: "configMap",
  props: ["namespace", "namespaceList"],
  data() {
    return {
      title: "",
      name: "",
      tableColumns: [
        {
          title: "名称",
          dataIndex: "name",
        },
        {
          title: "命名空间",
          dataIndex: "namespace",
          width: 100,
        },
        {
          title: "标签",
          dataIndex: "label",
          scopedSlots: { customRender: "label" },
          width: 300,
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          width: 200,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      loadingTable: false,
      updateData: {},
      addVisible: false,
      data: [],
      columns: [
        {
          title: "名称",
          dataIndex: "key",
          scopedSlots: { customRender: "key" },
          width: 300,
        },
        {
          title: "值",
          dataIndex: "value",
          scopedSlots: { customRender: "value" },
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 80,
        },
      ],
      selectedRowKeys: [],
    };
  },
  watch: {
    namespace: {
      immediate: true,
      handler() {
        this.query();
      },
    },
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query();
    },
    query() {
      let data = {
        namespace: this.namespace,
        name: this.name,
      };
      this.loadingTable = true;
      api
        .configMapList(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource = res.data;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    add() {
      this.title = "新增";
      this.updateData = {
        namespace: undefined,
        name: "",
      };
      this.data.splice(0);
      this.addVisible = true;
    },
    edit(val) {
      this.title = "修改";
      this.updateData = {
        name: val.name,
        namespace: val.namespace,
      };
      this.data.splice(0);
      for (const key in val.data) {
        const value = val.data[key];
        this.data.push({ key, value });
      }
      this.addVisible = true;
    },
    addData() {
      this.data.push({ key: "", value: "" });
    },
    deleteData(index) {
      this.data.splice(index, 1);
    },
    addSubmit() {
      let data = { ...this.updateData };
      data.data = {};
      this.data.forEach((item) => {
        data.data[item.key] = item.value;
      });
      if (this.title == "新增") {
        api.addConfigMap(data).then((res) => {
          if (res.result === 200) {
            this.addVisible = false;
            this.$message.success("新增成功");
            this.query();
          }
        });
      } else {
        api.editConfigMap(data).then((res) => {
          if (res.result === 200) {
            this.addVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    deleteClick(val) {
      let data = {
        name: val.name,
        namespace: val.namespace,
      };
      api.deleteConfigMap(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    batchDeleteClick() {
      let arr = [];
      this.selectedRowKeys.forEach((name) => {
        this.tableDataSource.forEach((item) => {
          if (item.name == name) {
            arr.push(item);
          }
        });
      });
      this.$axios
        .all(
          arr.map((item) => {
            let data = {
              name: item.name,
              namespace: item.namespace,
            };
            return api.deleteConfigMap(data);
          })
        )
        .then((resArr) => {
          this.$message.success("删除成功");
          this.query();
        });
    },
  },
};
</script>
<style scoped lang="scss">
.editTable {
  ::v-deep td {
    vertical-align: top !important;
  }
}
</style>
