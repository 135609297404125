<template>
  <div class="generatorCode">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="代码生成">
        <router-view name="three" v-if="active == '1'" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="模板列表">
        <router-view name="three" v-if="active == '2'" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="模板参数列表">
        <router-view name="three" v-if="active == '3'" />
      </a-tab-pane>
      <a-tab-pane key="4" tab="项目配置">
        <router-view name="three" v-if="active == '4'" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "generatorCode",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/generatorCode/templateCode") {
          this.active = "1";
        } else if (newVal == "/generatorCode/templated") {
          this.active = "2";
        } else if (newVal == "/generatorCode/templateParameter") {
          this.active = "3";
        } else if (newVal == "/generatorCode/templateProject") {
          this.active = "4";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/generatorCode/templateCode") return;
          this.$router.replace("/generatorCode/templateCode");
        } else if (newVal === "2") {
          if (this.$route.path == "/generatorCode/templated") return;
          this.$router.replace("/generatorCode/templated");
        } else if (newVal === "3") {
          if (this.$route.path == "/generatorCode/templateParameter") return;
          this.$router.replace("/generatorCode/templateParameter");
        } else {
          if (this.$route.path == "/generatorCode/templateProject") return;
          this.$router.replace("/generatorCode/templateProject");
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped></style>
