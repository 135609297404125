import http from "./axios";

// 查询指定生产者组所有的连接信息
export const consumerConnection = ({ producerGroup, topic, clusterId }) => {
  return http.get("/rocketmq/dashboard/producer/producerConnection", {
    producerGroup: producerGroup,
    topic: topic,
    clusterId,
  });
};
