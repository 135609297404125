<template>
  <div class="nginxServer" @click="visible = false">
    <div>
      <span @click="back()" class="back">
        <icon-font type="icon-arrow-left" />
        {{ groupName }}
      </span>
    </div>
    <div class="searchButton">
      <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      <a-button type="primary" @click.stop="add" icon="plus">新增</a-button>
      <a-button type="danger" @click.stop="takeEffect" v-if="signShow"
        >变更生效</a-button
      >
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      :rowKey="(record) => record.id"
    >
      <template slot="port" slot-scope="text, record">
        <editable-cell
          type="port"
          :text="text"
          @change="onCellChange(record.id, 'port', $event)"
        />
      </template>
      <template slot="weight" slot-scope="text, record">
        <editable-cell
          type="weight"
          :text="text"
          @change="onCellChange(record.id, 'weight', $event)"
        />
      </template>
      <template slot="remark" slot-scope="text, record">
        <editable-cell
          type="remark"
          :text="text"
          @change="onCellChange(record.id, 'remark', $event)"
        />
      </template>
      <span slot="action" slot-scope="text, record">
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <div :class="{ addDetail: true, visible }" @click.stop="visible = true">
      <header class="header">
        <span class="title">添加后端服务器</span>
        <span
          style="float: right; font-size: 20px; cursor: pointer"
          @click.stop="visible = false"
        >
          <a-icon type="close" style="vertical-align: top" />
        </span>
      </header>
      <div class="step">
        <a-steps :current="stepCurrent">
          <a-step title="选择服务器" />
          <a-step title="配置端口和权重" />
        </a-steps>
      </div>
      <div style="padding: 10px 20px; overflow: auto" v-if="stepCurrent != 1">
        <a-input-search
          v-model.trim="projectName"
          placeholder="请输入项目名称"
          style="width: 240px; float: left; margin-left: 10px"
          @search="statusIdSearch"
        />
      </div>
      <div
        style="
          padding: 10px 20px;
          height: calc(100vh - 141px);
          overflow-y: scroll;
        "
      >
        <a-table
          :rowClassName="$common.rowClassColor"
          bordered
          :components="$common.getTitle(statusTableColumns)"
          :columns="statusTableColumns"
          :dataSource="statusTableDataSource"
          :pagination="statusTablePagination"
          :loading="loadingStatusTable"
          :rowKey="(record) => record.id"
          :row-selection="{
            onChange: onSelectChange,
            selectedRowKeys: selectedRowKeys,
          }"
          v-if="stepCurrent != 1"
        >
        </a-table>
        <a-table
          :rowClassName="$common.rowClassColor"
          bordered
          :components="$common.getTitle(selectedRowKeysColumns)"
          :columns="selectedRowKeysColumns"
          :dataSource="selectedRowKeysSource"
          :pagination="false"
          :rowKey="(record) => record.id"
          v-if="stepCurrent != 0"
        >
          <span slot="enName" slot-scope="text, record">
            <a-tag color="green" v-if="record.submission"> 已提交 </a-tag>
            <a-tag color="blue" v-else> 待提交 </a-tag>
            <span style="display: inline-block">{{ text }}</span>
          </span>
          <span slot="serverPrivateIp" slot-scope="text, record">
            <a-input v-model="record.serverPrivateIp" style="width: 130px" />
          </span>
          <span slot="serverName" slot-scope="text, record">
            <a-input v-model="record.serverName" style="width: 150px" />
          </span>
          <span slot="port" slot-scope="text, record">
            <a-input v-model="record.port" style="width: 90px" />
          </span>
          <span slot="weight" slot-scope="text, record">
            <a-input v-model="record.weight" style="width: 90px" />
          </span>
          <span slot="remark" slot-scope="text, record">
            <a-input v-model="record.remark" style="width: 110px" />
          </span>
          <span slot="action" slot-scope="text, record">
            <a href="javascript:;" @click="deleteSelectedRowKeysClick(record)"
              >移除</a
            >
          </span>
        </a-table>
      </div>
      <footer class="footer">
        <a-button
          type="primary"
          @click.stop="next"
          v-if="stepCurrent != 1"
          :disabled="!hasSelected"
          >下一步</a-button
        >
        <a-button type="primary" @click.stop="previous" v-if="stepCurrent != 0"
          >上一步</a-button
        >
        <a-button
          type="primary"
          @click.stop="add_submit"
          v-if="stepCurrent != 0"
          >确定</a-button
        >
        <a-button key="back" @click.stop="visible = false">取消</a-button>
      </footer>
    </div>
  </div>
</template>

<script>
import * as api from "../lib/nginxServerGroup.js";
import editableCell from "../components/editableCell.vue";
import { getStatus } from "../lib/statusList.js";

export default {
  name: "nginxServer",
  components: {
    editableCell,
  },
  data() {
    return {
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "服务器名称",
          ellipsis: true,
          dataIndex: "serverName",
        },
        {
          title: "ip",
          ellipsis: true,
          dataIndex: "ip",
        },
        {
          title: "端口",
          ellipsis: true,
          dataIndex: "port",
          width: 110,
          scopedSlots: { customRender: "port" },
        },
        {
          title: "权重",
          ellipsis: true,
          dataIndex: "weight",
          width: 105,
          scopedSlots: { customRender: "weight" },
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
          width: 165,
          scopedSlots: { customRender: "remark" },
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      groupId: "",
      groupName: "",
      pageNo: 1,
      visible: false,
      stepCurrent: 0,
      statusList: [],
      projectName: "",
      loadingStatusTable: false,
      statusTableDataSource: [],
      statusTablePagination: {},
      statusTableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "英文名称",
          ellipsis: true,
          dataIndex: "enName",
          width: 150,
        },
        {
          title: "ip地址",
          ellipsis: true,
          dataIndex: "ip",
          width: 300,
        },
        {
          title: "端口",
          ellipsis: true,
          dataIndex: "port",
          width: 100,
        },
      ],
      statusPageNo: 1,
      selectedRowKeys: [],
      selectedRowKeysSource: [],
      selectedRowKeysColumns: [
        {
          title: "英文名称",
          ellipsis: true,
          dataIndex: "enName",
          width: 250,
          scopedSlots: { customRender: "enName" },
        },
        {
          title: "ip地址",
          ellipsis: true,
          dataIndex: "serverPrivateIp",
          scopedSlots: { customRender: "serverPrivateIp" },
        },
        {
          title: "服务器名称",
          ellipsis: true,
          dataIndex: "serverName",
          scopedSlots: { customRender: "serverName" },
        },
        {
          title: "端口",
          ellipsis: true,
          dataIndex: "port",
          scopedSlots: { customRender: "port" },
        },
        {
          title: "权重",
          ellipsis: true,
          dataIndex: "weight",
          scopedSlots: { customRender: "weight" },
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
          scopedSlots: { customRender: "remark" },
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      signShow: false,
    };
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  created() {
    if (this.$route.query.groupId) {
      this.groupId = this.$route.query.groupId * 1;
      this.groupName = this.$route.query.groupName;
      this.query(1);
    }
    this.getStatus();
    this.getSignShow();
  },
  methods: {
    getSignShow() {
      let data = {
        pageNo: 1,
        pageSize: 10,
        groupName: this.groupName,
        type: "APPLICATION",
      };
      api.listNginxServerGroup(data).then((res) => {
        if (res.result === 200) {
          if (
            res.data.records[0].workSign == res.data.records[0].lastUpdateSign
          ) {
            this.signShow = false;
          } else {
            this.signShow = true;
          }
        }
      });
    },
    takeEffect() {
      this.$confirm({
        title: "确认生效",
        content: (h) => (
          <div style="color:red;">
            该操作将直接影响到nginx监听配置文件，是否继续
          </div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          api.effectiveSign({ id: this.groupId }).then((res) => {
            if (res.result == 200) {
              this.$message.success("生效成功");
              this.getSignShow();
            }
          });
        },
        onCancel: () => {},
      });
    },
    deleteSelectedRowKeysClick(record) {
      this.selectedRowKeysSource.forEach((item, index) => {
        if (record.id == item.id) {
          this.selectedRowKeysSource.splice(index, 1);
          this.selectedRowKeys.splice(index, 1);
        }
      });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.statusTableDataSource.forEach((item) => {
        this.selectedRowKeys.forEach((itemId) => {
          if (item.id == itemId) {
            this.selectedRowKeysSource.push(item);
          }
        });
      });
      const map = new Map();
      this.selectedRowKeysSource = this.selectedRowKeysSource.filter(
        (item) => !map.has(item.id) && map.set(item.id, 1)
      );
      this.selectedRowKeysSource.forEach((item) => {
        this.$set(item, "weight", 100);
        this.$set(item, "remark", "");
        this.$set(item, "submission", false);
      });
    },
    statusIdSearch(enName) {
      this.getStatus(enName);
    },
    getStatus(enName, index) {
      if (index) {
        this.statusPageNo = index;
      }
      this.loadingStatusTable = true;
      let data = {
        pageNo: this.statusPageNo,
        pageSize: 10,
        enName: enName,
        queryAll: true,
      };
      getStatus(data).then((res) => {
        if (res.result == 200) {
          let list = res.data.records;
          this.statusTableDataSource = list;
          this.loadingStatusTable = false;
          this.statusTablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.changeStatusPageItem(current),
          };
        }
      });
    },
    changeStatusPageItem(index) {
      this.getStatus("", index);
    },
    next() {
      this.stepCurrent++;
    },
    previous() {
      this.stepCurrent--;
    },
    add_submit() {
      let itemList = [];
      this.selectedRowKeysSource.forEach((item) => {
        this.$set(item, "submission", true);
        itemList.push({
          ip: item.serverPrivateIp,
          serverName: item.serverName,
          serverId: item.serverId,
          port: item.port,
          weight: item.weight,
          remark: item.remark,
        });
      });
      let data = {
        groupId: this.groupId,
        itemList,
      };
      api.addServer(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("添加成功");
          this.visible = false;
          this.query(1);
          this.getSignShow();
        }
      });
    },
    onCellChange(serverId, dataIndex, value) {
      if (dataIndex == "port") {
        api.modifyPort({ nginxServerId: serverId, port: value }).then((res) => {
          if (res.result == 200) {
            this.$message.success("修改成功");
            this.getSignShow();
          }
        });
      } else if (dataIndex == "weight") {
        api
          .modifyWeight({ nginxServerId: serverId, weight: value })
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("修改成功");
              this.getSignShow();
            }
          });
      } else if (dataIndex == "remark") {
        api
          .modifyRemark({ nginxServerId: serverId, remark: value })
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("修改成功");
              this.getSignShow();
            }
          });
      }
    },
    back() {
      this.$router.go(-1);
    },
    query(index) {
      this.loadingTable = true;
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        groupId: this.groupId,
      };
      api.listNginxServerInGroup(data).then((res) => {
        if (res.result === 200) {
          this.tableDataSource = res.data.records;
          this.tablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.changePageItem(current),
          };
          this.loadingTable = false;
        }
      });
    },
    changePageItem(index) {
      this.query(index);
    },
    add() {
      this.visible = true;
      this.stepCurrent = 0;
      this.selectedRowKeys.splice(0);
      this.selectedRowKeysSource.splice(0);
    },
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.removeServer(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query(1);
          this.getSignShow();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.nginxServer {
  .back {
    display: inline-block;
    cursor: pointer;
    font-size: 20px;
    color: black;
    font-weight: 500;
    padding-bottom: 10px;
  }
  .addDetail {
    position: fixed;
    right: -1100px;
    top: 0;
    height: 100vh;
    width: 1100px;
    background-color: #fff;
    z-index: 1002;
    box-shadow: 0 4px 16px 0 rgb(0 0 0 / 30%);
    transition: all 0.5s;
    .header {
      border-bottom: 1px solid #e5e5e5;
      padding: 20px;
      .title {
        font-size: 18px;
        font-weight: 500;
      }
    }
    .step {
      padding: 20px;
    }
    .footer {
      border-top: 1px solid #e5e5e5;
      padding: 20px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 1100px;
      z-index: 1003;
      background-color: #fff;
      button {
        margin-right: 20px;
      }
    }
  }
  .visible {
    right: 0;
  }
}
</style>
