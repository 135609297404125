import http from "./axios";

// 查询列表
const queryPage = ({ pageNo, pageSize, name }) => {
  return http.get("/mq/cluster/mqClusterInfoPage", {
    pageNo,
    pageSize,
    name,
  });
};

const addData = ({ name, address, version, remark, enabled }) => {
  return http.json_post("/mq/cluster/addMqClusterInfo", {
    name,
    address,
    version,
    remark,
    enabled,
  });
};

// 编辑
const editData = ({ id, name, address, version, remark, enabled }) => {
  return http.json_post("/mq/cluster/editMqClusterInfo", {
    id,
    name,
    address,
    version,
    remark,
    enabled,
  });
};

// 删除
const deleteData = ({ id }) => {
  return http.json_post("/mq/cluster/deleteMqClusterInfo", {
    id,
  });
};

export default {
  deleteData,
  queryPage,
  addData,
  editData,
};
