<template>
  <div class="pvcList">
    <div class="reminder">存储声明 <span>persistentVolumeClaim</span></div>
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="name"
          @pressEnter="query"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.name"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <span slot="action" slot-scope="text, record">
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <a-popconfirm
      placement="right"
      okText="确认"
      cancelText="取消"
      @confirm="batchDeleteClick()"
      :disabled="selectedRowKeys.length == 0"
    >
      <template slot="title">确认是否批量删除</template>
      <a-button
        type="danger"
        icon="delete"
        :disabled="selectedRowKeys.length == 0"
        >批量删除</a-button
      >
    </a-popconfirm>
  </div>
</template>
<script>
import * as api from "../lib/k8s.js";

export default {
  name: "pvcList",
  props: ["namespace"],
  data() {
    return {
      name: "",
      tableColumns: [
        {
          title: "名称",
          dataIndex: "name",
        },
        {
          title: "总量",
          dataIndex: "capacity",
          width: 100,
        },
        {
          title: "访问模式",
          dataIndex: "accessMode",
          width: 120,
        },
        {
          title: "状态",
          dataIndex: "status",
          width: 80,
        },
        {
          title: "存储类型",
          dataIndex: "pvType",
          width: 170,
        },
        {
          title: "关联的存储卷",
          dataIndex: "boundPv",
          width: 400,
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 100,
        },
      ],
      tableDataSource: [],
      loadingTable: false,
      selectedRowKeys: [],
    };
  },
  watch: {
    namespace: {
      immediate: true,
      handler() {
        this.query();
      },
    },
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query();
    },
    query() {
      let data = {
        namespace: this.namespace,
        name: this.name,
      };
      this.loadingTable = true;
      api
        .pvcList(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource = res.data;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    deleteClick(val) {
      let data = {
        name: val.name,
        namespace: this.namespace,
      };
      api.deletePvc(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    batchDeleteClick() {
      let arr = [];
      this.selectedRowKeys.forEach((name) => {
        this.tableDataSource.forEach((item) => {
          if (item.name == name) {
            arr.push(item);
          }
        });
      });
      this.$axios
        .all(
          arr.map((item) => {
            let data = {
              name: item.name,
              namespace: this.namespace,
            };
            return api.deletePvc(data);
          })
        )
        .then((resArr) => {
          this.$message.success("删除成功");
          this.query();
        });
    },
  },
};
</script>
