<template>
  <div>
    <div class="searchButton">
      <a-button type="primary" @click="queryCompiler(1)" icon="search"
        >查询</a-button
      >
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(compilerColumns)"
      :columns="compilerColumns"
      :dataSource="compilerDataSource"
      :loading="compilerLoading"
      size="small"
    >
    </a-table>
  </div>
</template>

<script>
import * as api from "../lib/otherDeploy";

export default {
  name: "compiler",
  data() {
    return {
      compilerColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "key",
          width: 60,
        },
        {
          title: "编译机名称",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "局域网名称",
          ellipsis: true,
          dataIndex: "lanName",
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
        },
      ],
      compilerDataSource: [],
      compilerPagination: {},
      compilerLoading: false,
    };
  },
  created() {
    this.queryCompiler();
  },
  methods: {
    // 点击查询编译机列表
    queryCompiler(index) {
      this.getCompileServerList();
    },
    // 查询编译机列表
    getCompileServerList() {
      this.compilerLoading = true;
      api
        .compileServerList()
        .then((res) => {
          this.compilerLoading = false;
          if (res.result === 200) {
            let list = res.data;
            list.forEach((item, index) => {
              item.key = index + 1;
            });
            this.compilerDataSource = list;
          }
        })
        .catch((err) => {
          this.compilerLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
