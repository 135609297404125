<template>
  <div class="warningRecordPage">
    <div class="searchCondition">
      <div class="searchConditionItem">
        告警组：
        <a-select
          v-model="groupId"
          placeholder="请选择告警组"
          showSearch
          allowClear
        >
          <a-select-option v-for="item in categoryList" :key="item.id">{{
            item.value
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      rowKey="id"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <span slot="read" slot-scope="text">
        <a-tag color="green" v-if="text == true">已读</a-tag>
        <a-tag color="red" v-if="text == false">未读</a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="viewMessage(record)">查看</a>
        <a-divider type="vertical" />
        <a-popconfirm
          title="确定?"
          ok-text="是"
          cancel-text="否"
          @confirm="readClick(record)"
          :disabled="record.read"
        >
          <a :disabled="record.read" href="javascript:;">已读</a>
        </a-popconfirm>
      </span>
    </a-table>
    <a-popconfirm
      placement="right"
      okText="确认"
      cancelText="取消"
      @confirm="deleteBatchClick(record)"
    >
      <template slot="title">确认是否删除</template>
      <a-button type="danger" icon="delete" :disabled="disabledClick"
        >删除</a-button
      >
    </a-popconfirm>

    <a-modal title="消息" v-model="messageShow" :maskClosable="false">
      <div v-text="message"></div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="messageShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/warningRecordPage.js";
export default {
  name: "warningRecordPage",
  data() {
    return {
      selectedRowKeys: [],
      title: "",
      groupName: "",
      groupId: undefined,
      groupIdAdd: undefined,
      categoryList: [],
      enabled: true,
      code: "",
      warningId: undefined,
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      pageSize: 10,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "分组",
          ellipsis: true,
          dataIndex: "groupIdLabel",
        },
        {
          title: "消息",
          ellipsis: true,
          dataIndex: "message",
          width: 400,
        },
        {
          title: "发送人",
          ellipsis: true,
          dataIndex: "toUsers",
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "read",
          scopedSlots: { customRender: "read" },
        },
        {
          title: "发送时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      message: "",
      messageShow: false,
    };
  },
  computed: {
    disabledClick() {
      if (this.selectedRowKeys.length > 0) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.getWarningList();
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        groupId: this.groupId,
      };
      this.getconfigPageList(data);
    },
    // 查询告警配置分页列表
    getconfigPageList(data) {
      this.loadingTable = true;
      api
        .alarmLogPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.groupIdLabel = "";
              this.categoryList.forEach((menu) => {
                if (menu.id === item.groupId) {
                  item.groupIdLabel = menu.value;
                }
              });
            });
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showSizeChanger: true,
              pageSizeOptions: ["10", "50", "200"],
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
              onShowSizeChange: (current, pageSize) =>
                this.onShowSizeChange(current, pageSize),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
      this.query();
    },
    viewMessage(val) {
      this.message = val.message;
      this.messageShow = true;
    },
    readClick(val) {
      let data = {
        logId: val.id,
      };
      api.markAsRead(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("操作成功");
          this.query();
        }
      });
    },
    // 告警组转换用
    getWarningList() {
      api.groupList().then((res) => {
        if (res.result === 200) {
          let list = [];
          for (let i in res.data) {
            let obj = {
              id: res.data[i].id,
              value: res.data[i].groupName,
            };
            list.push(obj);
          }
          this.categoryList = list;
          this.query(1);
        }
      });
    },
    // 点击批量删除
    deleteBatchClick() {
      let data = {
        idList: this.selectedRowKeys,
      };
      api.deleteAlarmLog(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
