import http from "./axios";

// 获取topic
export const topicStat = ({ topic, startTime, endTime, clusterId }) => {
  return http.get("/monitor/mq/getTopicStat", {
    topic: topic,
    startTime: startTime,
    endTime: endTime,
    clusterId,
  });
};

// 查询指定topic生产者时序数据
export const listProductItem = ({ topic, startTime, endTime, clusterId }) => {
  return http.get("/monitor/mq/listProductItem", {
    topic: topic,
    startTime: startTime,
    endTime: endTime,
    clusterId,
  });
};

// 查询指定topic消费者时序数据
export const listConsumerTimeSerialData = ({
  topic,
  startTime,
  endTime,
  clusterId,
}) => {
  return http.get("/monitor/mq/listConsumerTimeSerialData", {
    topic: topic,
    startTime: startTime,
    endTime: endTime,
    clusterId,
  });
};
