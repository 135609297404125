import http from "./axios";

// 查询代码生成列表
export const codeProjectConfigPage = ({
  projectId,
  instanceId,
  databaseName,
  pageNo,
  pageSize,
}) => {
  return http.get("/generatorCode/codeProjectConfigPage", {
    projectId: projectId,
    instanceId: instanceId,
    databaseName: databaseName,
    pageNo: pageNo,
    pageSize: pageSize,
  });
};

// 新增代码生成
export const addCodeProjectConfig = ({
  projectId,
  instanceId,
  databaseName,
  packageName,
  enabled,
}) => {
  return http.json_post("/generatorCode/addCodeProjectConfig", {
    projectId: projectId,
    instanceId: instanceId,
    databaseName: databaseName,
    packageName: packageName,
    enabled: enabled,
  });
};

// 编辑
export const editCodeProjectConfig = ({
  id,
  projectId,
  instanceId,
  databaseName,
  packageName,
  enabled,
}) => {
  return http.json_post("/generatorCode/editCodeProjectConfig", {
    id: id,
    projectId: projectId,
    instanceId: instanceId,
    databaseName: databaseName,
    packageName: packageName,
    enabled: enabled,
  });
};

// 删除
export const deleteCodeProjectConfig = ({ id }) => {
  return http.get("/generatorCode/deleteCodeProjectConfig", {
    id: id,
  });
};
