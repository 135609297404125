<template>
  <div class="templateCode">
    <div class="searchCondition">
      <div class="searchConditionItem">
        项目：
        <a-select
          v-model="configId"
          placeholder="请选择项目"
          class="select-tag"
          allowClear
          showSearch
          @change="configIdChange"
        >
          <a-select-option
            v-for="i in configIdList"
            :key="i.id"
            :value="i.id"
            >{{ i.projectName }}</a-select-option
          >
        </a-select>
      </div>
      <div class="searchConditionItem">
        表名：
        <a-select
          v-model="tableNames"
          placeholder="请选择表名"
          class="select-tag"
          allowClear
          showSearch
          mode="multiple"
          :disabled="!configId"
        >
          <a-select-option
            v-for="i in tableNamesList"
            :key="i.key"
            :value="i.key"
            >{{ i.value }}</a-select-option
          >
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addClick" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="previewClick(record)">预览</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="downloadClick(record)">下载</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.projectName }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="项目:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="configIdAdd"
            placeholder="请选择项目"
            class="select-tag"
            allowClear
            showSearch
            option-filter-prop="children"
            @change="configIdChange"
          >
            <a-select-option
              v-for="i in configIdList"
              :key="i.id"
              :value="i.id"
              >{{ i.projectName }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="表名:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="tableNamesAdd"
            placeholder="请选择表名"
            class="select-tag tablename"
            allowClear
            showSearch
            mode="multiple"
            :disabled="!configIdAdd"
          >
            <a-select-option
              v-for="i in tableNamesList"
              :key="i.key"
              :value="i.key"
              >{{ i.value }}</a-select-option
            >
          </a-select>
          <a-tooltip>
            <template slot="title"> 过滤最近创建的表 </template>
            <a-checkbox @change="newestChange" :checked="newest"></a-checkbox>
          </a-tooltip>
          <a-button
            type="primary"
            @click="Synchronize()"
            style="margin-left: 10px"
            >同步</a-button
          >
        </a-form-item>
        <a-form-item
          label="分组:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="group"
            placeholder="请选择分组"
            class="select-tag"
            allowClear
          >
            <a-select-option
              v-for="i in groupSelectList"
              :key="i.group"
              :value="i.group"
              >{{ i.group }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 预览 -->
    <a-modal
      :title="previewTitle"
      v-model="previewVisible"
      :maskClosable="false"
      :afterClose="previewClose"
      width="1000px"
    >
      <a-tabs v-model="previewActiveKey" type="card">
        <a-tab-pane
          v-for="pane in previewList"
          :key="pane.key"
          :tab="pane.tableName"
        >
          <a-tabs v-model="previewActiveKey1" tab-position="left">
            <a-tab-pane
              v-for="item in pane.codeList"
              :key="item.index"
              :tab="item.template"
            >
              <div>
                <codemirror
                  ref="myCode"
                  v-model="item.content"
                  :options="temOptions"
                  class="code_style"
                />
              </div>
            </a-tab-pane>
          </a-tabs>
        </a-tab-pane>
      </a-tabs>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="previewVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/templateCode.js";

export default {
  name: "templateCode",
  data() {
    return {
      temOptions: {
        mode: "text/javascript",
        theme: "default",
        readOnly: false,
        lineNumbers: true,
        line: true,
        styleActiveLine: true, //设置光标所在行高亮，需引入工具包
        placeholder: "输入内容",
        autoRefresh: true,
      },
      title: "",
      configId: undefined,
      configIdAdd: undefined,
      tableNames: [],
      tableNamesAdd: [],
      groupId: "",
      // -----------------------------
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "项目名",
          ellipsis: true,
          dataIndex: "projectName",
        },
        {
          title: "表名",
          ellipsis: true,
          dataIndex: "tableNames",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      configIdList: [],
      tableNamesList: [],
      previewList: [],
      previewTitle: "",
      previewVisible: false,
      previewActiveKey: "1",
      previewActiveKey1: "0",
      groupSelectList: [],
      group: undefined,
      newest: true,
    };
  },
  created() {
    this.query(1);
    this.getCodeProjectConfigList();
    this.getGroupList();
  },
  methods: {
    newestChange() {
      this.newest = !this.newest;
      this.getCodeProjectTableList();
    },
    getGroupList() {
      api.groupList().then((res) => {
        if (res.result == 200) {
          this.groupSelectList = res.data;
        }
      });
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        configId: this.configId,
        tableNames: this.tableNames.join(","),
      };
      this.getCodeGeneratorLogPage(data);
    },
    // 查询代码生成列表
    getCodeGeneratorLogPage(data) {
      this.loadingTable = true;
      api
        .codeGeneratorLogPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // // 修改
    // modifyClick(val) {
    //   this.title = "修改";
    //   this.addKeyVisible = true;
    //   this.groupId = val.id;
    //   this.configIdAdd = val.configId;
    //   this.tableNamesAdd = val.tableNames;
    // },
    // 预览
    previewClick(val) {
      this.previewVisible = true;
      this.previewTitle = "【" + val.projectName + "】预览";
      api.preview({ id: val.id }).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          list.forEach((item, index) => {
            item.key = String(index + 1);
          });
          this.previewList = list;
          // this.refresh();
        }
      });
    },
    previewClose() {
      this.previewList = [];
      this.previewActiveKey = "1";
      this.previewActiveKey1 = "0";
    },
    // 新增
    addClick() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.configIdAdd = undefined;
      this.tableNamesAdd = [];
    },
    // 确定新增
    add_submit() {
      let data = {
        configId: this.configIdAdd,
        tableNames: this.tableNamesAdd.join(","),
        group: this.group,
      };
      if (this.title === "新增") {
        api.addCodeGeneratorLog(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        // data.id = this.groupId;
        // api.editCodeTemplate(data).then((res) => {
        //   if (res.result === 200) {
        //     this.addKeyVisible = false;
        //     this.$message.success("修改成功");
        //     this.query();
        //   }
        // });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.configIdAdd = undefined;
      this.tableNamesAdd = [];
      this.groupId = "";
      this.group = undefined;
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteCodeGeneratorLog(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    // 获取项目集合
    getCodeProjectConfigList() {
      api.codeProjectConfigList().then((res) => {
        if (res.result === 200) {
          this.configIdList = res.data;
        }
      });
    },
    configIdChange(val) {
      if (val !== undefined) {
        if (this.addKeyVisible) {
          this.configIdAdd = val;
        } else {
          this.configId = val;
        }
        this.getCodeProjectTableList();
      }
      this.tableNamesList = [];
      this.tableNamesAdd = [];
      this.tableNames = [];
    },
    // 获取表名集合
    getCodeProjectTableList() {
      let data;
      if (this.addKeyVisible) {
        data = {
          configId: this.configIdAdd,
          tableNames: "",
          newest: this.newest,
        };
      } else {
        data = {
          configId: this.configId,
          tableNames: "",
          newest: false,
        };
      }
      api.codeProjectTableList(data).then((res) => {
        if (res.result === 200) {
          this.tableNamesList = res.data;
        }
      });
    },
    // 点击下载
    downloadClick(val) {
      let data = {
        id: val.id,
      };
      let url = "";
      for (let i in data) {
        if (data[i]) {
          url += i + "=" + data[i];
          //   + "&";
        }
      }
      let href =
        location.protocol +
        process.env.VUE_APP_BASE_API +
        "/generatorCode/downloadCode?" +
        url;
      this.derivedDis = true;
      this.$common.promiseDownload(href).then((data) => {
        setTimeout(() => {
          this.derivedDis = data;
        }, 4000);
      });
    },
    // 点击同步
    Synchronize() {
      api.syncInfo({ id: this.configIdAdd }).then((res) => {
        if (res.result == 200) {
          this.getCodeProjectTableList();
          this.$message.success("同步成功");
        }
      });
    },
  },
};
</script>
<style>
.code_style .CodeMirror {
  height: 600px;
}
</style>
<style lang="scss" scoped>
::v-deep .ant-col-16 {
  width: 78%;
  margin-top: 5px;
  .ant-form-item-children {
    display: flex;
    .select-tag {
      width: 270px;
      margin-right: 20px;
    }
  }
}
</style>
