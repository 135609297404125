<template>
  <div class="diskManage">
    <div class="searchCondition">
      <div class="searchConditionItem">
        服务器：
        <a-select
          v-model="serverId"
          placeholder="请选择服务器"
          showSearch
          :filter-option="false"
          @search="instanceIdSearch"
          allowClear
        >
          <a-select-option v-for="item in instanceList" :key="item.id">{{
            item.instanceName
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        磁盘名：
        <a-input
          placeholder="请输入磁盘名"
          v-model.trim="diskName"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        百分比：
        <a-input-number
          id="inputNumber"
          v-model.trim="percentStart"
          :min="0"
          :max="100"
          @pressEnter="query(1)"
          @change="allowClearChange"
        />
        -
        <a-input-number
          id="inputNumber"
          v-model.trim="percentEnd"
          :min="0"
          :max="100"
          @pressEnter="query(1)"
          @change="allowClearChange"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="size" slot-scope="text"> {{ text + "G" }} </span>
      <span slot="percent" slot-scope="text, record">
        <a-tooltip>
          <template slot="title">
            {{ record.usedSize + "G" }}/ {{ record.size + "G" }}</template
          >
          <a-progress
            v-if="text >= 85 && text < 90"
            :percent="text"
            size="small"
            strokeColor="#FF8000"
          />
          <a-progress
            v-if="text >= 90"
            :percent="text"
            size="small"
            strokeColor="red"
          />
          <a-progress v-if="text < 85" :percent="text" size="small" />
        </a-tooltip>
      </span>
    </a-table>
  </div>
</template>
<script>
import * as api from "../lib/diskManage.js";
export default {
  name: "diskManage",
  data() {
    return {
      serverId: undefined,
      instanceList: [],
      diskName: "",
      percentStart: "0",
      percentEnd: "100",
      // -----------------------------
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "服务器",
          ellipsis: true,
          dataIndex: "serverName",
        },
        {
          title: "磁盘名称",
          ellipsis: true,
          dataIndex: "diskName",
        },
        {
          title: "挂载目录",
          ellipsis: true,
          dataIndex: "mountPoint",
        },
        {
          title: "容量",
          ellipsis: true,
          dataIndex: "size",
          scopedSlots: { customRender: "size" },
        },
        {
          title: "使用百分比",
          ellipsis: true,
          dataIndex: "percent",
          scopedSlots: { customRender: "percent" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
    };
  },
  mounted() {
    this.getServerList();
    this.query(1);
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        serverId: this.serverId,
        diskName: this.diskName,
        percentStart: this.percentStart,
        percentEnd: this.percentEnd,
      };
      this.getDiskList(data);
    },
    // 查询告警配置分页列表
    getDiskList(data) {
      this.loadingTable = true;
      api
        .getDisk(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            // list.forEach((item, index) => {
            //   item.groupIdLabel = "";
            //   this.categoryList.forEach((menu) => {
            //     if (menu.id === item.groupId) {
            //       item.groupIdLabel = menu.value;
            //     }
            //   });
            // });
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 告警组转换用
    // getWarningList() {
    //   api.groupList().then((res) => {
    //     if (res.result === 200) {
    //       let list = [];
    //       for (let i in res.data) {
    //         let obj = {
    //           id: res.data[i].id,
    //           value: res.data[i].groupName,
    //         };
    //         list.push(obj);
    //       }
    //       this.categoryList = list;
    //       this.query(1);
    //     }
    //   });
    // },

    // 服务器下拉框发生变化
    instanceIdSearch(instanceName) {
      this.getServerList(instanceName);
    },
    // 查询服务器列表
    getServerList(instanceName) {
      let data = {
        instanceName: instanceName,
      };
      api.serverList(data).then((res) => {
        if (res.result === 200) {
          this.instanceList = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.backup {
  padding: 5px 0;
}
</style>
