import mRightMenu from './mRightMenu.vue'
import mRightMenuItem from './mRightMenuItem.vue'

const rightMenu = {
  install(Vue) {
    Vue.component('mRightMenu', mRightMenu)
    Vue.component('mRightMenuItem', mRightMenuItem)
  }
}

export default rightMenu