import http from "./axios";

// 查询信任列表
export const trustList = ({ pageNo, pageSize, serverId }) => {
  return http.get("/server/trustPublicKey/page", {
    pageNo: pageNo,
    pageSize: pageSize,
    serverId: serverId,
  });
};

// 新增信任列表
export const trustAdd = ({ publicKeyId, serverIdList }) => {
  return http.json_post("/server/trustPublicKey/insert", {
    publicKeyId: publicKeyId,
    serverIdList: serverIdList,
  });
};

// 删除信任
export const trustDelete = ({ id }) => {
  return http.get("/server/trustPublicKey/delete", {
    id: id,
  });
};

// 查询服务器集合
export const serverList = ({ instanceName, enabled }) => {
  return http.get("/delivery/server/list", {
    instanceName: instanceName,
    enabled: enabled,
  });
};

// 查询公钥集合
export const searchPublicKey = ({ identifyName }) => {
  return http.get("/server/publicKey/list", {
    identifyName: identifyName,
  });
};
