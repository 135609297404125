import { render, staticRenderFns } from "./zookeeperUI.vue?vue&type=template&id=5610d793&scoped=true"
import script from "./zookeeperUI.vue?vue&type=script&lang=js"
export * from "./zookeeperUI.vue?vue&type=script&lang=js"
import style0 from "./zookeeperUI.vue?vue&type=style&index=0&id=5610d793&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5610d793",
  null
  
)

export default component.exports