<template>
  <div class="serverListTab">
    <div class="searchCondition">
      <div class="searchConditionItem">
        实例名称：
        <a-input
          v-model.trim="instanceName"
          @pressEnter="query(1)"
          @change="allowClearChange"
          placeholder="请输入实例名称"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        ip：
        <a-input
          v-model.trim="ip"
          @pressEnter="query(1)"
          @change="allowClearChange"
          placeholder="请输入ip"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addServer" icon="plus"
          >新增服务器</a-button
        >
        <a-button @click="syncEcsServerClick">同步Ecs服务器</a-button>
        <span>
          过滤禁用：
          <a-switch v-model="filterEnabled" />
        </span>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(columns)"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="pagination"
      :loading="tableLoading"
      size="small"
    >
      <span slot="instanceName" slot-scope="text, record">
        <div>{{ record.instanceId }}</div>
        <div
          @mouseover="
            () => {
              record.editNameShow = true;
            }
          "
          @mouseout="
            () => {
              record.editNameShow = false;
            }
          "
        >
          <span>{{ text }}</span>
          <a-tooltip>
            <template slot="title"> 修改实例名称 </template>
            <a-icon
              type="edit"
              class="table-tr-btn"
              @click="editNameClick(record)"
              v-show="record.editNameShow"
            />
          </a-tooltip>
          <a-tooltip>
            <template slot="title"> 复制 </template>
            <a-icon
              type="copy"
              class="table-tr-btn"
              v-copy="text"
              v-show="record.editNameShow"
            />
          </a-tooltip>
        </div>
      </span>
      <span slot="publicIp" slot-scope="text, record">
        <div
          v-show="text"
          @mouseover="
            () => {
              record.oipNameShow = true;
            }
          "
          @mouseout="
            () => {
              record.oipNameShow = false;
            }
          "
        >
          <span>{{ text }} (外网)</span>
          <a-tooltip>
            <template slot="title"> 复制 </template>
            <a-icon
              type="copy"
              class="table-tr-btn"
              v-copy="text"
              v-show="record.oipNameShow"
            />
          </a-tooltip>
        </div>
        <div
          v-show="record.privateIp"
          @mouseover="
            () => {
              record.iipNameShow = true;
            }
          "
          @mouseout="
            () => {
              record.iipNameShow = false;
            }
          "
        >
          <span>{{ record.privateIp }} (内网)</span>
          <a-tooltip>
            <template slot="title"> 复制 </template>
            <a-icon
              type="copy"
              class="table-tr-btn"
              v-copy="record.privateIp"
              v-show="record.iipNameShow"
            />
          </a-tooltip>
        </div>
      </span>
      <span slot="enabled" slot-scope="text, record" style="width: 100%">
        <a-tag v-if="record.enabled" color="green"> 可用 </a-tag>
        <a-tag v-else color="red"> 禁用 </a-tag>
      </span>
      <span slot="expireDate" slot-scope="text, record">
        <span v-if="record.provider !== 'HUIYU'">{{ text }}</span>
        <span v-else>-</span>
      </span>
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a-dropdown :disabled="record.accountList.length <= 0">
          <a
            :disabled="record.accountList.length <= 0"
            class="ant-dropdown-link"
            @click="(e) => e.preventDefault()"
          >
            webShell <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-for="item in record.accountList" :key="item.id">
              <a-button type="link" @click="goTerminal(item)">{{
                item.user
              }}</a-button>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="updateServer(record)">修改</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="templateAnalysisClick(record)"
          >模版解析</a
        >
        <a-divider type="vertical" />
        <a-dropdown>
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            其它 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a-popconfirm
                title="确定要初始化吗?"
                ok-text="是"
                cancel-text="否"
                @confirm="initServer(record)"
              >
                <a-button type="link" style="padding: 0">初始化</a-button>
              </a-popconfirm>
            </a-menu-item>
            <a-menu-item>
              <a-popconfirm
                placement="right"
                okText="确认"
                cancelText="取消"
                @confirm="deleteServer(record)"
              >
                <template slot="title"
                  >确认是否删除{{ record.privateIp }}</template
                >
                <a href="javascript:;" style="color: #ff4d4f">删除</a>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>
    <!-- 新增or修改服务器 -->
    <a-modal
      :title="editServerTitle"
      v-model="visibleAddServer"
      :maskClosable="false"
      :afterClose="closeAddServer"
    >
      <a-form>
        <a-form-item
          label="提供方"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-select v-model="addForm.provider">
            <a-select-option v-for="item in providerList" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="实例名称"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="addForm.provider === 'HUIYU'"
          required
        >
          <a-input
            placeholder="请输入实例名称"
            v-model="addForm.instanceName"
          />
        </a-form-item>
        <a-form-item
          label="所属账号:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="addForm.provider === 'ALIYUN'"
          required
        >
          <a-select
            v-model="addForm.aliyunAccountId"
            placeholder="请选择所属账号"
          >
            <a-select-option v-for="item in aliyunAccountList" :key="item.id">{{
              item.accountName
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="外网IP"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input placeholder="请输入外部IP" v-model="addForm.publicIp" />
        </a-form-item>
        <a-form-item
          label="内网IP"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input placeholder="请输入内部IP" v-model="addForm.privateIp" />
        </a-form-item>
        <a-form-item
          label="端口号"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
          v-if="editServerTitle === '新增服务器'"
        >
          <a-input placeholder="请输入端口号" v-model="addForm.port" />
        </a-form-item>
        <a-form-item
          label="登录名"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
          v-if="editServerTitle === '新增服务器'"
        >
          <a-input placeholder="请输入登录名" v-model="addForm.user" />
        </a-form-item>
        <a-form-item
          label="局域网"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-select v-model="addForm.lanCode">
            <a-select-option v-for="item in lanList" :key="item.code">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="是否可用"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-switch v-model="addForm.enabled" />
        </a-form-item>
        <a-form-item
          label="备注"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input placeholder="请输入备注" v-model="addForm.remark" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addServerCancel">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addServerOk"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 模版解析 -->
    <a-modal
      title="模版解析"
      v-model="templateShow"
      :maskClosable="false"
      :afterClose="templateClose"
      :footer="null"
      :width="1300"
    >
      <div>
        <span>
          选择模版：
          <a-select
            v-model="templateId"
            placeholder="请选择模版"
            showSearch
            option-filter-prop="children"
            style="width: 300px"
            allowClear
            @change="templateChange"
          >
            <a-select-option v-for="item in templateList" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </span>
        <span style="padding-left: 20px">
          选择服务器：
          <a-select
            v-model="serverId"
            placeholder="请选择服务器"
            showSearch
            option-filter-prop="children"
            style="width: 300px"
            allowClear
            @change="serverChange"
          >
            <a-select-option v-for="item in serverIdList" :key="item.id">
              {{ item.instanceName }}
            </a-select-option>
          </a-select>
        </span>
      </div>
      <div class="template-c" v-if="templateShow">
        <div class="template-c-i">
          <h4>输入</h4>
          <codemirror
            v-model="templateContent"
            :options="temOptions"
            class="code_style"
          >
          </codemirror>
        </div>
        <div class="template-c-z">
          <a-icon
            type="arrow-right"
            class="table-tr-btn"
            @click="template_submit"
          />
        </div>
        <div class="template-c-i">
          <h4>输出</h4>
          <codemirror
            v-model="outputContent"
            :options="outOptions"
            class="code_style"
          >
          </codemirror>
        </div>
      </div>
    </a-modal>
    <!-- 修改实力名称 -->
    <a-modal
      title="编辑实例名称"
      v-model="editNameVisible"
      :afterClose="editNameClose"
    >
      <a-form>
        <a-form-item
          label="实例名称"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input placeholder="请输入实例名称" v-model="editName" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="editNameVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="editName_submit"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/serverList.js";
import { lanInfoList } from "../lib/otherDeploy";
import { serverConfigTemplateList } from "../lib/serverConfigTemplate.js";
export default {
  name: "serverListTab",
  data() {
    return {
      temOptions: {
        mode: "text/javascript",
        theme: "default",
        readOnly: false,
        lineNumbers: true,
        line: true,
        styleActiveLine: true, //设置光标所在行高亮，需引入工具包
        placeholder: "输入内容",
      },
      outOptions: {
        mode: "text/javascript",
        theme: "default",
        readOnly: true,
        lineNumbers: true,
        line: true,
        styleActiveLine: true, //设置光标所在行高亮，需引入工具包
        placeholder: "输出内容",
      },
      serverId: undefined,
      serverIdList: [],
      editName: "",
      editId: undefined,
      editNameVisible: false,
      serverItemInfo: {},
      templateContent: "",
      outputContent: "",
      templateShow: false,
      templateId: undefined,
      templateList: [],
      filterEnabled: true,
      lanList: [],
      editServerTitle: "",
      columns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "实例名称",
          ellipsis: true,
          dataIndex: "instanceName",
          scopedSlots: { customRender: "instanceName" },
          width: 350,
        },
        {
          title: "IP地址",
          ellipsis: true,
          dataIndex: "publicIp",
          scopedSlots: { customRender: "publicIp" },
          width: 200,
        },
        {
          title: "所属账号",
          ellipsis: true,
          dataIndex: "aliyunAccountName",
        },
        {
          title: "是否可用",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "过期时间",
          ellipsis: true,
          dataIndex: "expireDate",
          scopedSlots: { customRender: "expireDate" },
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      instanceName: "",
      dataSource: [],
      pagination: {},
      pageNo: 1,
      visibleAddServer: false,
      providerList: [
        {
          id: "HUIYU",
          name: "惠誉",
        },
        {
          id: "ALIYUN",
          name: "阿里云",
        },
      ],
      addForm: {
        id: "",
        privateIp: "",
        publicIp: "",
        provider: undefined,
        lanCode: undefined,
        port: "",
        user: "",
        enabled: false,
        remark: "",
        instanceName: "",
        aliyunAccountId: undefined,
      },
      loading: false,
      tableLoading: false,
      lanCode: "",
      // -------------------
      ip: "",
      aliyunAccountList: [],
    };
  },
  mounted() {
    this.query(1);
    this.getLanInfoList();
    this.getMainAccountList();
  },
  methods: {
    // selectChange() {
    //   this.addForm.instanceName = "";
    //   this.addForm.aliyunAccountId = undefined;
    // },
    getMainAccountList() {
      api.mainAccountList().then((res) => {
        if (res.result === 200) {
          this.aliyunAccountList = res.data;
        }
      });
    },
    // 选择服务器
    serverChange(val) {
      this.serverIdList.forEach((item) => {
        if (item.id === val) {
          this.serverItemInfo = item;
        }
      });
    },
    // 点击修改实力名称
    editNameClick(val) {
      this.editNameVisible = true;
      this.editName = val.instanceName;
      this.editId = val.id;
    },
    // 确定修改实力名称
    editName_submit() {
      let data = {
        id: this.editId,
        instanceName: this.editName,
      };
      api.updateServerInstanceName(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("修改成功");
          this.editNameVisible = false;
          this.query(1);
        }
      });
    },
    // 关闭修改实力名称
    editNameClose() {},
    // 查询模版列表
    getServerConfigTemplateList() {
      let data = {
        pageNo: 1,
        pageSize: 100,
      };
      serverConfigTemplateList(data).then((res) => {
        if (res.result === 200) {
          this.templateList = res.data.records;
          if (this.templateList && this.templateList.length > 0) {
            this.templateId = this.templateList[0].id;
            this.templateChange(this.templateId);
          }
        }
      });
    },
    // 选择模版
    templateChange(val) {
      this.templateContent = "";
      this.templateList.forEach((item) => {
        if (val === item.id) {
          setTimeout(() => {
            this.templateContent = item.content;
          }, 500);
        }
      });
    },
    // 点击模版解析
    templateAnalysisClick(val) {
      this.templateShow = true;
      this.serverItemInfo = val;
      this.getServerConfigTemplateList();
      this.serverIdList = [];
      let data = {
        pageNo: 1,
        pageSize: 200,
        enabled: true,
      };
      api.serverList(data).then((res) => {
        if (res.result === 200) {
          this.serverIdList = res.data.records;
          this.serverId = val.id;
        }
      });
    },
    // 确认解析模版
    template_submit() {
      this.outputContent = this.templateContent;
      this.outputContent = this.outputContent.replaceAll(
        "$INSTANCE_NAME",
        this.serverItemInfo.instanceName
      );
      this.outputContent = this.outputContent.replaceAll(
        "$PUBLIC_IP",
        this.serverItemInfo.publicIp
      );
      this.outputContent = this.outputContent.replaceAll(
        "$INNER_IP",
        this.serverItemInfo.privateIp
      );
    },
    // 关闭解析模版
    templateClose() {
      this.templateId = undefined;
      this.serverItemInfo = {};
      this.templateContent = "";
      this.outputContent = "";
      this.serverId = undefined;
    },
    // 搜索模版

    // 点击同步ecs服务器
    syncEcsServerClick() {
      api.syncEcsServerList().then((res) => {
        if (res.result === 200) {
          this.$message.success("同步成功");
        }
      });
    },
    // 删除服务器
    deleteServer(val) {
      api.serverDelete({ id: val.id }).then((res) => {
        if (res.result == 200) {
          this.$message.success("删除成功！");
          this.getServerList({
            pageNo: this.pageNo,
            pageSize: 10,
          });
        }
      });
    },
    // 点击修改服务器
    updateServer(val) {
      this.visibleAddServer = true;
      this.editServerTitle = "修改服务器";
      this.addForm = {
        id: val.id,
        privateIp: val.privateIp,
        publicIp: val.publicIp,
        provider: val.provider,
        port: "",
        user: "",
        enabled: val.enabled,
        lanCode: val.lanCode,
        remark: val.remark,
        instanceName: val.instanceName,
        aliyunAccountId: val.aliyunAccountId,
      };
    },
    // 点击新增服务器
    addServer() {
      this.visibleAddServer = true;
      this.editServerTitle = "新增服务器";
      this.addForm = {
        id: "",
        privateIp: "",
        publicIp: "",
        provider: "HUIYU",
        port: "22",
        user: "root",
        lanCode: this.lanCode,
        enabled: true,
        remark: "",
        instanceName: "",
        aliyunAccountId: undefined,
      };
    },
    // 新增服务器
    addServerOk() {
      this.addForm.privateIp = this.addForm.privateIp.trim();
      this.addForm.publicIp = this.addForm.publicIp.trim();
      if (
        this.addForm.privateIp.length &&
        this.addForm.lanCode &&
        this.addForm.provider
      ) {
        if (this.editServerTitle === "新增服务器") {
          this.addForm.port = Number(this.addForm.port);
          this.addForm.user = this.addForm.user.trim();
          if (this.addForm.port && this.addForm.user.length) {
            let data = {
              privateIp: this.addForm.privateIp,
              publicIp: this.addForm.publicIp,
              provider: this.addForm.provider,
              port: this.addForm.port,
              user: this.addForm.user,
              lanCode: this.addForm.lanCode,
              enabled: this.addForm.enabled,
              remark: this.addForm.remark,
            };
            if (this.addForm.provider === "HUIYU") {
              data.instanceName = this.addForm.instanceName;
            }
            if (this.addForm.provider === "ALIYUN") {
              data.aliyunAccountId = this.addForm.aliyunAccountId;
            }
            api.addServer(data).then((res) => {
              if (res.result == 200) {
                this.$message.success("新增服务器成功！");
                this.visibleAddServer = false;
                this.query();
              }
            });
          } else {
            this.$message.warning("信息填写不完整！");
          }
        }
        if (this.editServerTitle === "修改服务器") {
          api.updateServer(this.addForm).then((res) => {
            if (res.result === 200) {
              this.$message.success("修改服务器成功！");
              this.visibleAddServer = false;
              this.query();
            }
          });
        }
      } else {
        this.$message.warning("信息填写不完整！");
      }
    },
    // 取消新增服务器
    addServerCancel() {
      this.visibleAddServer = false;
    },
    // 关闭新增服务器
    closeAddServer() {
      this.addForm = {
        id: "",
        privateIp: "",
        publicIp: "",
        instanceName: "",
        aliyunAccountId: undefined,
        provider: undefined,
        lanCode: undefined,
        port: "",
        user: "",
        enabled: false,
        remark: "",
      };
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    //查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        instanceName: this.instanceName,
        ip: this.ip,
      };
      if (this.filterEnabled) {
        data.enabled = this.filterEnabled;
      }
      this.getServerList(data);
    },
    // 查询服务器列表
    getServerList(data) {
      this.tableLoading = true;
      api
        .serverList(data)
        .then((res) => {
          this.tableLoading = false;
          if (res.result == 200) {
            let list = res.data.records;
            list.forEach((item, i) => {
              item.key = i + 1;
              item.editNameShow = false;
              item.oipNameShow = false;
              item.iipNameShow = false;
            });
            this.dataSource = list;
            this.pagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    // 分页
    changePageItem(current) {
      this.query(current);
    },
    // 查询局域网列表
    getLanInfoList() {
      lanInfoList().then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.lanList = list;
          if (list.length > 0) {
            this.lanCode = list[0].code;
          }
        }
      });
    },
    goTerminal(val) {
      let routeData = this.$router.resolve({
        path: "/terminal",
        query: { id: val.id, title: val.serverName },
      });
      window.open(routeData.href, "_blank");
    },
    // 初始化
    initServer(val) {
      api.initServer({ id: val.id }).then((res) => {
        if (res.result === 200) {
          this.$message.warning("初始化成功！");
        }
      });
    },
  },
};
</script>
<style>
.code_style .CodeMirror {
  height: 600px;
}
.code_style .CodeMirror-lines {
  padding: 0px;
}
.code_style .CodeMirror .CodeMirror-line {
  font-size: 13px;
  line-height: 150%;
  color: #4d4d4c;
  font-family: arial;
}
.code_style .CodeMirror .CodeMirror-placeholder {
  font-size: 13px;
  line-height: 150%;
  color: #8e908c;
  font-family: arial;
}
</style>
<style lang="scss" scoped>
.ant-switch-checked {
  background-color: #52c41a;
}
.template-c {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.template-c-i {
  width: 47%;
}
.template-c-z {
  font-size: 28px;
}
.table-tr-btn {
  margin: 0 4px;
  cursor: pointer;
}
.table-tr-btn:hover {
  color: #409eff;
}
</style>
