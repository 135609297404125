<template>
  <div class="statistics">
    <div class="nav">
      <div class="top_item" @click="deliveryClick">
        <div class="top_num">{{ deliveryCount }}</div>
        <div class="top_txt">今日发布数</div>
      </div>
      <div class="top_item" @click="exceptionClick">
        <div v-if="exceptionCount == 0" class="top_num">
          {{ exceptionCount }}
        </div>
        <div v-else class="top_num" style="color: red">
          {{ exceptionCount }}
        </div>
        <div class="top_txt">未处理异常数</div>
      </div>
      <div class="top_item" @click="alertClick">
        <div v-if="alertCount == 0" class="top_num">
          {{ alertCount }}
        </div>
        <div v-else class="top_num" style="color: red">
          {{ alertCount }}
        </div>
        <div class="top_txt">未处理告警数</div>
      </div>
      <div class="top_item">
        <div class="top_num">{{ cpu }}</div>
        <div class="top_txt">cpu</div>
      </div>
      <div class="top_item">
        <div class="top_num">{{ startUpTime }}</div>
        <div class="top_txt">启动时长</div>
      </div>
    </div>
    <div class="content">
      <div class="histogram">
        <div id="myChart1" class="myChart"></div>
        <div id="myChart2" class="myChart"></div>
      </div>
      <div class="scrollBoard">
        <div class="scrollBoardTxt">项目排名：</div>
        <dv-scroll-ranking-board :config="config" />
      </div>
      <div class="scrollBoard1">
        <div class="scrollBoard1Txt">页面入口：</div>
        <div class="entrance" v-for="item in jumpList" :key="item.id">
          <div class="enter" @click="jump(item)">{{ item.name }}</div>
        </div>
      </div>
    </div>

    <div class="system">
      <div class="systemTitle">系统信息</div>
      <div class="systemContent">
        <span> io：{{ io }} </span>
        <span> 服务器数：{{ serverCount }} </span>
        <span> 项目数：{{ projectCount }} </span>
      </div>
    </div>
  </div>
</template>
<script>
import * as api from "../lib/statistics.js";
// 引入基本模板
import * as echarts from "echarts";
// 引入折线图组件
require("echarts/lib/chart/bar");

export default {
  name: "statistics",
  data() {
    return {
      jumpList: [],
      timer: null,
      startUpTime: "",
      io: "",
      cpu: "",
      deliveryCount: "",
      exceptionCount: "",
      alertCount: "",
      serverCount: "",
      projectCount: "",
      errorInfoCount: "",
      config: {
        data: [],
        rowNum: 7,
      },
      myChart: null,
    };
  },
  mounted() {
    this.query();
    this.getYearDeliveryCount();
    this.getQuickEntranceList();
    this.timer = setInterval(() => {
      this.query();
    }, 60000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); //关闭定时器
    }
  },
  methods: {
    getWeekData(year, month) {
      //传入  年  月  获取当月有几周 以及日期
      var new_year = year; //取当前的年份
      var new_month = month++; //取下一个月的第一天，方便计算（最后一天不固定）
      if (new_month < 10) {
        new_month = "0" + new_month;
      }
      var weekDay = [
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
        "星期天",
      ];
      if (new_month > 12) {
        new_month -= 12; //月份减
        new_year++; //年份增
      }
      var first_date = new Date(new_year, new_month, 1); //取当年当月中的第一天-时间格式
      var last_Data = new Date(
        first_date.getTime() - 1000 * 60 * 60 * 24
      ).getDate(); //获取当月最后一天日期
      //当月第一天是周几
      var firstzhouji =
        new Date(new_year + "/" + new_month + "/" + 1).getDay() == 0
          ? "星期天"
          : weekDay[
              new Date(new_year + "/" + new_month + "/" + 1).getDay() - 1
            ];
      //当月最后一天是周几
      var lastzhouji =
        new Date(new_year + "/" + new_month + "/" + last_Data).getDay() == 0
          ? "星期天"
          : weekDay[
              new Date(new_year + "/" + new_month + "/" + last_Data).getDay() -
                1
            ];
      var firsttime = ""; //第一周有几天
      if (firstzhouji == "星期一") {
        firsttime = 7;
      }
      if (firstzhouji == "星期二") {
        firsttime = 6;
      }
      if (firstzhouji == "星期三") {
        firsttime = 5;
      }
      if (firstzhouji == "星期四") {
        firsttime = 4;
      }
      if (firstzhouji == "星期五") {
        firsttime = 3;
      }
      if (firstzhouji == "星期六") {
        firsttime = 2;
      }
      if (firstzhouji == "星期天") {
        firsttime = 1;
      }

      var lasttime = ""; //最后一周有几天
      if (lastzhouji == "星期一") {
        lasttime = 1;
      }
      if (lastzhouji == "星期二") {
        lasttime = 2;
      }
      if (lastzhouji == "星期三") {
        lasttime = 3;
      }
      if (lastzhouji == "星期四") {
        lasttime = 4;
      }
      if (lastzhouji == "星期五") {
        lasttime = 5;
      }
      if (lastzhouji == "星期六") {
        lasttime = 6;
      }
      if (lastzhouji == "星期天") {
        lasttime = 7;
      }

      var contime = Math.floor(2 + (last_Data - lasttime - firsttime) / 7);

      return contime;
    },
    getVirtualData(year) {
      const date = +echarts.time.parse(year + "-01-01");
      const end = +echarts.time.parse(year + "-12-31");
      const dayTime = 3600 * 24 * 1000;
      const data = [];
      for (let time = date; time <= end; time += dayTime) {
        data.push([echarts.time.format(time, "{yyyy}-{MM}-{dd}", false), 0]);
      }
      return data;
    },
    // 获取入口信息
    getQuickEntranceList() {
      api.getQuickEntranceList().then((res) => {
        if (res.result === 200) {
          this.jumpList = res.data;
        }
      });
    },
    // 页面跳转
    jump(val) {
      window.open(val.path, "_blank");
    },
    deliveryClick() {
      this.$router.replace("/request");
    },
    exceptionClick() {
      this.$router.replace("/errorLog");
    },
    alertClick() {
      this.$router.replace("/warning/warningRecordPage");
    },
    query() {
      this.getProjectDeliverySort();
      this.getUserTodayDeliveryCount();
      this.getSystemInfo();
      this.getOtherInfo();
    },
    // 登陆者当日发布数
    getUserTodayDeliveryCount() {
      api.getUserTodayDeliveryCount().then((res) => {
        if (res.result === 200) {
          this.deliveryCount = res.data.deliveryCount;
          this.exceptionCount = res.data.exceptionCount;
          this.alertCount = res.data.alertCount;
        }
      });
    },
    // 近七天发布数
    getYearDeliveryCount() {
      api.getYearDeliveryCount().then((res) => {
        if (res.result === 200) {
          // 基于准备好的dom，初始化echarts实例
          let myChart1 = echarts.init(document.getElementById("myChart1"));
          let myChart2 = echarts.init(document.getElementById("myChart2"));
          let year = new Date().getFullYear();
          let data1 = this.getVirtualData(year);
          let data2 = this.getVirtualData(year);
          let max1 = 0;
          let max2 = 0;
          res.data.forEach((y) => {
            data1.forEach((i) => {
              if (i[0] == y.date) {
                i[1] = y.deliveryCount;
                if (y.deliveryCount) {
                  max1 = y.deliveryCount;
                }
              }
            });
            data2.forEach((i) => {
              if (i[0] == y.date) {
                i[1] = y.untreatedExceptionCount;
                if (y.untreatedExceptionCount) {
                  max2 = y.untreatedExceptionCount;
                }
              }
            });
          });
          let option1 = {
            title: {
              left: "center",
              text: "发布统计",
            },
            tooltip: {
              position: "top",
              formatter: function (p) {
                var format = echarts.time.format("yyyy-MM-dd", p.data[0]);
                return format + "<br/>" + p.data[1] + "条";
              },
            },
            visualMap: {
              min: 0,
              max: max1 + 5,
              top: "center",
              show: false,
              inRange: {
                color: ["#ddddddb3", "#73c77e", "#259533", "#00670d"],
              },
            },
            calendar: [],
            series: [],
          };
          let option2 = {
            title: {
              left: "center",
              text: "未处理异常统计",
            },
            tooltip: {
              position: "top",
              formatter: function (p) {
                var format = echarts.time.format("yyyy-MM-dd", p.data[0]);
                return format + "<br/>" + p.data[1] + "条";
              },
            },
            visualMap: {
              min: 0,
              max: max2 + 5,
              top: "center",
              show: false,
              inRange: {
                color: ["#ddddddb3", "#f18686", "#d93232", "#be0101"],
              },
            },
            calendar: [],
            series: [],
          };
          let left = 0;

          for (let i = 0; i < 12; i++) {
            let week_count = this.getWeekData(year, i + 1);

            option1.calendar.push({
              left,
              cellSize: [18, 18],
              dayLabel: {
                firstDay: 1,
                show: false,
              },
              monthLabel: {
                margin: 10,
                nameMap: "ZH",
              },
              yearLabel: { show: false },
              range: year + "-" + (i + 1),
              itemStyle: {
                borderWidth: 5,
                borderColor: "#fff",
              },
              splitLine: {
                lineStyle: {
                  width: 5,
                  color: "#fff",
                },
              },
            });
            option1.series.push({
              type: "heatmap",
              coordinateSystem: "calendar",
              calendarIndex: i,
              data: data1,
            });
            option2.calendar.push({
              left,
              cellSize: [18, 18],
              dayLabel: {
                firstDay: 1,
                show: false,
              },
              monthLabel: {
                margin: 10,
                nameMap: "ZH",
              },
              yearLabel: { show: false },
              range: year + "-" + (i + 1),
              itemStyle: {
                borderWidth: 5,
                borderColor: "#fff",
              },
              splitLine: {
                lineStyle: {
                  width: 5,
                  color: "#fff",
                },
              },
            });
            option2.series.push({
              type: "heatmap",
              coordinateSystem: "calendar",
              calendarIndex: i,
              data: data2,
            });

            if (week_count == 4) {
              left += 85;
            } else if (week_count == 5) {
              left += 100;
            } else {
              left += 115;
            }
          }
          // 绘制图表
          myChart1.setOption(option1);
          myChart2.setOption(option2);
        }
      });
    },
    // 项目发布排序
    getProjectDeliverySort() {
      api.getProjectDeliverySort().then((res) => {
        if (res.result === 200) {
          this.config.data = res.data;
          this.config = { ...this.config };
        }
      });
    },
    // 系统信息
    getSystemInfo() {
      api.getSystemInfo().then((res) => {
        if (res.result === 200) {
          this.startUpTime = res.data.startUpTime;
          this.io = res.data.io;
          this.cpu = res.data.cpu;
        }
      });
    },
    // 其他信息
    getOtherInfo() {
      api.getOtherInfo().then((res) => {
        if (res.result === 200) {
          this.serverCount = res.data.serverCount;
          this.projectCount = res.data.projectCount;
          this.errorInfoCount = res.data.errorInfoCount;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.statistics {
  .nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid #eceef2;
    border-radius: 8px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    .top_item {
      margin: 15px 0;
      border-right: 1px solid #dbdbdb;
      text-align: center;
      width: 100%;
      height: 60px;
      .top_num {
        font-size: 33px;
        font-family: "DINCondensed-Bold", Arial, sans-serif;
        font-weight: 400;
        cursor: pointer;
      }
      .top_txt {
        font-size: 13px;
      }
    }
    .top_item:last-child {
      border-right: 0px solid #dbdbdb;
    }
  }
  .content {
    margin-top: 30px;
    height: 400px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    .histogram {
      width: 70%;
      height: 390px;
      .myChart {
        width: 100%;
        height: 50%;
      }
    }
    .scrollBoard {
      width: 15%;
      .dv-scroll-ranking-board {
        color: #000;
      }
      .scrollBoardTxt {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
      }
    }
    .scrollBoard1 {
      width: 10%;
      .scrollBoard1Txt {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
      }
      .entrance {
        div {
          color: #1354fa;
          height: 20px;
          cursor: pointer;
          margin-top: 20px;
        }
      }
    }
  }

  .system {
    width: 100%;
    height: 100px;
    .systemTitle {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    .systemContent {
      width: 100%;
      font-size: 14px;
      margin-bottom: 10px;
      span {
        width: 33%;
        display: inline-block;
      }
    }
  }
}
</style>
