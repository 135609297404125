import http from "./axios";

// 查询告警组分页列表
export const getDisk = ({
  serverId,
  pageNo,
  pageSize,
  diskName,
  percentStart,
  percentEnd,
}) => {
  return http.get("/server/disk/page", {
    serverId: serverId,
    pageNo: pageNo,
    pageSize: pageSize,
    diskName: diskName,
    percentStart: percentStart,
    percentEnd: percentEnd,
  });
};

// 查询服务器集合
export const serverList = ({ instanceName }) => {
  return http.get("/delivery/server/list?enabled=true", {
    instanceName: instanceName,
  });
};
