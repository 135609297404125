import http from "./axios";

// 查询权限列表
export const policyTypePage = ({ pageNo, pageSize, name }) => {
  return http.get("/policyType/policyTypePage", {
    pageNo: pageNo,
    pageSize: pageSize,
    name: name,
  });
};

// 新增权限列表
export const addPolicyType = ({ name, code }) => {
  return http.json_post("/policyType/addPolicyType", {
    name: name,
    code: code,
  });
};
// 编辑权限列表
export const editPolicyType = ({ name, id, code }) => {
  return http.json_post("/policyType/editPolicyType", {
    name: name,
    id: id,
    code: code,
  });
};
// 删除权限列表
export const deletePolicyType = ({ id }) => {
  return http.json_post("/policyType/deletePolicyType", {
    id: id,
  });
};

// 获取阿里云所有权限集合
export const listPolicies = () => {
  return http.get("/delivery/aliyun/account/listPolicies", {});
};
// 当前账号拥有的权限
export const listAccountPolicies = ({ id }) => {
  return http.get("/policyType/policyList", {
    id: id,
  });
};
// 保存账号权限
export const editAccountPolicy = ({ id, policyList }) => {
  return http.json_post("/policyType/editPolicy", {
    id: id,
    policyList: policyList,
  });
};
