<template>
  <div class="mqConsumer">
    <div class="searchCondition">
      <div class="searchConditionItem">
        SubscriptionGroup：
        <a-input
          v-model.trim="subscriptionGroup"
          placeholder="请输入SubscriptionGroup"
          @pressEnter="getConsumerList()"
          allowClear
          @change="inputChange"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="getConsumerList()" icon="reload"
          >刷新</a-button
        >
        <a-button type="primary" @click="addClick">ADD/UPDATE</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :loading="loadingTable"
      size="small"
    >
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="clientClick(record)">客户端详情</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="detailClick(record)">消费详情</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="configClick(record)">配置</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      title="新增"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="900px"
    >
      <a-form>
        <a-form-item
          label="clusterName:"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-select
            v-model="clusterNameList"
            placeholder="Select Some Options"
            allowClear
            mode="multiple"
          >
            <a-select-option v-for="item in clusterNameList1" :key="item">{{
              item
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="BROKER_NAME:"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-select
            v-model="brokerNameList"
            placeholder="Select Some Options"
            allowClear
            mode="multiple"
          >
            <a-select-option v-for="item in brokerNameList1" :key="item">{{
              item
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="groupName:"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-input
            v-model="subscriptionGroupConfig.groupName"
            placeholder="请输入groupName"
          />
        </a-form-item>
        <a-form-item
          label="consumeEnable:"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-switch
            default-checked
            v-model="subscriptionGroupConfig.consumeEnable"
          />
        </a-form-item>
        <a-form-item
          label="consumeBroadcastEnable:"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-switch
            default-checked
            v-model="subscriptionGroupConfig.consumeBroadcastEnable"
          />
        </a-form-item>
        <a-form-item
          label="retryQueueNums:"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-input
            v-model="subscriptionGroupConfig.retryQueueNums"
            placeholder="请输入retryQueueNums"
          />
        </a-form-item>
        <a-form-item
          label="brokerId:"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-input
            v-model="subscriptionGroupConfig.brokerId"
            placeholder="请输入brokerId"
          />
        </a-form-item>
        <a-form-item
          label="whichBrokerWhenConsumeSlowly:"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-input
            v-model="subscriptionGroupConfig.whichBrokerWhenConsumeSlowly"
            placeholder="请输入whichBrokerWhenConsumeSlowly"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- CLIENT弹框 -->
    <a-modal
      :title="clientTitle"
      v-model="clientVisible"
      :maskClosable="false"
      :afterClose="clientClose"
      width="980px"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(clientColumns)"
        :columns="clientColumns"
        :dataSource="clientDateSource"
        :loading="loadingTable"
        :pagination="false"
        size="small"
        bordered
      >
      </a-table>
      <div class="below">Below is subscription:</div>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(clientBotColumns)"
        :columns="clientBotColumns"
        :dataSource="clientBotDateSource"
        :loading="loadingTable"
        :pagination="false"
        size="small"
        bordered
      >
      </a-table>
      <div class="botDiv">ConsumeType:{{ clientData.consumeType }}</div>
      <div class="botDiv">MessageModel:{{ clientData.messageModel }}</div>
      <div class="botDiv">
        ConsumeFromWhere:{{ clientData.consumeFromWhere }}
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="clientVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
    <!-- detail弹框 -->
    <a-modal
      :title="detailTitle"
      v-model="detailVisible"
      :maskClosable="false"
      :afterClose="detailClose"
      width="980px"
    >
      <div v-if="detailData">
        <div v-for="item in detailData" :key="item.lastTimestamp">
          <div class="detail" v-if="detailData">
            <div>Topic</div>
            <div>{{ item.topic }}</div>
            <div>Delay</div>
            <div>{{ item.diffTotal }}</div>
            <div>LastConsumeTime</div>
            <div>
              {{ moment(item.lastTimestamp).format("YYYY-MM-DD HH:mm:ss") }}
            </div>
          </div>
          <a-table
            :rowClassName="$common.rowClassColor"
            bordered
            :components="$common.getTitle(detailColumns)"
            :columns="detailColumns"
            :dataSource="item.queueStatInfoList"
            :loading="loadingTable"
            :pagination="false"
            size="small"
          >
            <span slot="diffTotal" slot-scope="text, record">
              {{ record.brokerOffset - record.consumerOffset }}
            </span>
            <span slot="lastTimeStamp" slot-scope="text">
              {{ text }}
            </span>
          </a-table>
        </div>
      </div>

      <div v-else>Don't have SubscriptionGroup</div>

      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="detailVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
    <!-- config弹框 -->
    <a-modal
      title="Subscription  Change"
      v-model="configVisible"
      :afterClose="configClose"
      :maskClosable="false"
      width="800px"
      :footer="null"
    >
      <div v-for="item in configList" :key="item.topicName">
        <a-form>
          <a-form-item
            label="BROKER_NAME:"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 13 }"
          >
            <a-select
              v-model="item.brokerNameList"
              placeholder="请选择BROKER_NAME"
              allowClear
              :defaultActiveFirstOption="false"
              :showArrow="false"
              :filterOption="false"
              mode="multiple"
              disabled
            >
              <a-select-option v-for="d in item.brokerNameList" :key="d">{{
                d
              }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            label="groupName:"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 13 }"
          >
            <a-input
              disabled
              v-model="item.subscriptionGroupConfig.groupName"
              placeholder="请输入groupName"
            />
          </a-form-item>
          <a-form-item
            label="consumeEnable:"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 13 }"
          >
            <!-- <a-input
              v-model="item.subscriptionGroupConfig.consumeEnable"
              placeholder="请输入consumeEnable"
            /> -->
            <a-switch v-model="item.subscriptionGroupConfig.consumeEnable" />
          </a-form-item>
          <a-form-item
            label="consumeBroadcastEnable:"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 13 }"
          >
            <!-- <a-input
              v-model="item.subscriptionGroupConfig.consumeBroadcastEnable"
              placeholder="请输入consumeBroadcastEnable"
            /> -->
            <a-switch
              v-model="item.subscriptionGroupConfig.consumeBroadcastEnable"
            />
          </a-form-item>
          <a-form-item
            label="retryQueueNums:"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 13 }"
          >
            <a-input
              v-model="item.subscriptionGroupConfig.retryQueueNums"
              placeholder="请输入retryQueueNums"
            />
          </a-form-item>
          <a-form-item
            label="brokerId:"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 13 }"
          >
            <a-input
              v-model="item.subscriptionGroupConfig.brokerId"
              placeholder="请输入brokerId"
            />
          </a-form-item>
          <a-form-item
            label="whichBrokerWhenConsumeSlowly:"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 13 }"
          >
            <a-input
              v-model="
                item.subscriptionGroupConfig.whichBrokerWhenConsumeSlowly
              "
              placeholder="请输入whichBrokerWhenConsumeSlowly"
            />
          </a-form-item>
        </a-form>
        <div
          style="display: flex; justify-content: flex-end; margin-bottom: 20px"
        >
          <a-button key="back" @click="configVisible = false">关闭</a-button>
          <i style="margin-left: 10px"></i>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="config_submit(item)"
            >提交</a-button
          >
        </div>
      </div>
    </a-modal>
    <!-- 删除 -->
    <a-modal
      :title="titleDel"
      v-model="delVisible"
      :maskClosable="false"
      width="600px"
    >
      <a-form>
        <a-form-item
          label="clusterName:"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-select
            v-model="brokerNameListDel"
            placeholder="Select Some Options"
            allowClear
            mode="multiple"
          >
            <a-select-option v-for="item in brokerNameListDel1" :key="item">{{
              item
            }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="delVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="del_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/mqConsumer.js";
import { clusterList } from "../lib/mqCluster.js";
import moment from "moment";

export default {
  name: "mqConsumer",
  props: ["clusterId"],
  data() {
    return {
      addKeyVisible: false,
      topicName: "",
      subscriptionGroup: "",
      consumerGroup: "",
      clientTitle: "",
      clientVisible: false,
      clientColumns: [
        {
          title: "ClientId",
          ellipsis: true,
          dataIndex: "clientId",
          align: "center",
        },
        {
          title: "ClientAddr",
          ellipsis: true,
          dataIndex: "clientAddr",
          align: "center",
        },
        {
          title: "Language",
          ellipsis: true,
          dataIndex: "language",
          align: "center",
        },
        {
          title: "Version",
          ellipsis: true,
          dataIndex: "versionDesc",
          align: "center",
        },
      ],
      clientDateSource: [],
      clientBotColumns: [
        {
          title: "Topic",
          ellipsis: true,
          dataIndex: "name",
          align: "center",
        },
        {
          title: "SubExpression",
          ellipsis: true,
          dataIndex: "subString",
          align: "center",
        },
      ],
      clientBotDateSource: [],
      clientData: {},
      detailTitle: "",
      detailVisible: false,
      detailColumns: [
        {
          title: "Broker",
          ellipsis: true,
          dataIndex: "brokerName",
        },
        {
          title: "Queue",
          ellipsis: true,
          dataIndex: "queueId",
        },
        {
          title: "consumerClient",
          ellipsis: true,
          dataIndex: "clientInfo",
        },
        {
          title: "brokerOffset",
          ellipsis: true,
          dataIndex: "brokerOffset",
        },
        {
          title: "consumerOffset",
          ellipsis: true,
          dataIndex: "consumerOffset",
        },
        {
          title: "diffTotal",
          ellipsis: true,
          dataIndex: "diffTotal",
          scopedSlots: { customRender: "diffTotal" },
        },
        {
          title: "lastTimeStamp",
          ellipsis: true,
          dataIndex: "lastTimeStamp",
          customRender: (text, row, index) => {
            return moment(text).format("YYYY-MM-DD HH:mm:ss");
          },
        },
      ],
      detailDateSource: [],
      detailData: [],
      configVisible: false,
      configList: [],
      // -----------------------------
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "SubscriptionGroup",
          ellipsis: true,
          dataIndex: "group",
        },
        {
          title: "Quantity",
          ellipsis: true,
          dataIndex: "count",
        },
        {
          title: "Version",
          ellipsis: true,
          dataIndex: "version",
        },
        {
          title: "Type",
          ellipsis: true,
          dataIndex: "consumeType",
        },
        {
          title: "Mode",
          ellipsis: true,
          dataIndex: "messageModel",
        },
        {
          title: "TPS",
          ellipsis: true,
          dataIndex: "consumeTps",
        },
        {
          title: "Delay",
          ellipsis: true,
          dataIndex: "diffTotal",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      loadingTable: false,
      clusterNameList: [],
      clusterNameList1: [],
      brokerNameList: [],
      brokerNameList1: [],
      subscriptionGroupConfig: {
        groupName: "",
        retryQueueNums: "1",
        brokerId: "0",
        whichBrokerWhenConsumeSlowly: "1",
        consumeEnable: true,
        consumeBroadcastEnable: true,
        consumeFromMinEnable: true,
        retryMaxTimes: "16",
      },
      titleDel: "",
      delVisible: false,
      brokerNameListDel: [],
      brokerNameListDel1: [],
      groupDel: "",
    };
  },
  watch: {
    clusterId: {
      handler() {
        this.getConsumerList();
      },
    },
  },
  created() {
    if (this.clusterId) {
      this.getConsumerList();
    }
  },
  methods: {
    moment, // 引用moment方法
    // 点击查询所有消费者
    getConsumerList() {
      this.loadingTable = true;
      api
        .consumerList({ clusterId: this.clusterId })
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data;
            this.tableDataSource = list;
            this.tableDataSourceInput = list;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 新增
    addClick() {
      this.addKeyVisible = true;
      this.groupName = "";
      clusterList({ clusterId: this.clusterId }).then((res) => {
        if (res.result === 200) {
          let obj = res.data.clusterInfo.clusterAddrTable;
          console.log(Object.keys(obj)[0], Object.values(obj)[0]);
          this.clusterNameList1.push(Object.keys(obj)[0]);
          this.brokerNameList1 = Object.values(obj)[0];
        }
      });
    },
    // 确定新增
    add_submit() {
      let data = {
        brokerNameList: this.brokerNameList,
        clusterNameList: this.clusterNameList,
        subscriptionGroupConfig: this.subscriptionGroupConfig,
        clusterId: this.clusterId,
      };
      api.createOrUpdate(data).then((res) => {
        if (res.result === 200) {
          this.addKeyVisible = false;
          this.$message.success("添加成功");
          this.getConsumerList();
        }
      });
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.clusterNameList = [];
      this.brokerNameList = [];
      this.subscriptionGroupConfig = {
        groupName: "",
        retryQueueNums: "1",
        brokerId: "0",
        whichBrokerWhenConsumeSlowly: "1",
        consumeEnable: true,
        consumeBroadcastEnable: true,
        consumeFromMinEnable: true,
        retryMaxTimes: "16",
      };
    },
    // 点击删除
    deleteClick(val) {
      this.titleDel = "[" + val.group + "]Delete";
      this.groupDel = val.group;
      this.delVisible = true;
      api
        .fetchBrokerNameList({
          consumerGroup: val.group,
          clusterId: this.clusterId,
        })
        .then((res) => {
          this.brokerNameListDel1 = res.data;
        });
    },
    del_submit() {
      let data = {
        groupName: this.groupDel,
        brokerNameList: this.brokerNameListDel,
        clusterId: this.clusterId,
      };
      api.deleteSubGroup(data).then((res) => {
        if (res.result === 200) {
          this.delVisible = false;
          this.$message.success("删除成功");
          this.getConsumerList();
        }
      });
    },
    inputChange() {
      this.tableDataSource = this.tableDataSourceInput.filter(
        (el) => el.group.indexOf(this.subscriptionGroup) != -1
      );
    },
    clientClick(val) {
      this.clientVisible = true;
      this.consumerGroup = val.group;
      this.clientTitle = "[" + this.consumerGroup + "]CLIENT";
      api
        .consumerConnection({
          consumerGroup: this.consumerGroup,
          clusterId: this.clusterId,
        })
        .then((res) => {
          if (res.result === 200) {
            this.clientDateSource = res.data.connectionSet;
            let obj = res.data.subscriptionTable;
            let arr = [];
            let arr1 = [];
            for (var i in obj) {
              arr.push(obj[i]);
              arr1.push(i);
            }
            for (var i in arr) {
              arr[i].name = arr1[i];
            }
            this.clientBotDateSource = arr;
            this.clientData = res.data;
          }
        });
    },
    clientClose() {},
    detailClick(val) {
      this.detailVisible = true;
      this.consumerGroup = val.group;
      this.detailTitle = "[" + this.consumerGroup + "]CONSUME DETAIL";
      api
        .queryTopicByConsumer({
          consumerGroup: this.consumerGroup,
          clusterId: this.clusterId,
        })
        .then((res) => {
          // this.detailDateSource
          this.detailData = res.data;
        });
    },
    detailClose() {},

    configClick(val) {
      this.configVisible = true;
      this.consumerGroup = val.group;
      api
        .examineSubscriptionGroupConfig({
          consumerGroup: this.consumerGroup,
          clusterId: this.clusterId,
        })
        .then((res) => {
          this.configList = res.data;
        });
    },
    config_submit() {},
    configClose() {
      this.configList = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.below {
  font-size: 16px;
  margin: 20px 0;
}
.botDiv {
  font-size: 16px;
  margin-top: 10px;
}
.detail {
  width: 100%;
  height: 50px;
  background-color: #e0e0e0;
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  div {
    height: 100%;
    width: 15%;
    border: 1px solid #adadad;
    text-align: center;
    line-height: 50px;
  }
  div:nth-child(1) {
    width: 10%;
  }
  div:nth-child(2) {
    border-right-width: 0;
    border-left-width: 0;
    width: 35%;
  }
  div:nth-child(3) {
    width: 10%;
  }
  div:nth-child(4) {
    border-right-width: 0;
    border-left-width: 0;
    width: 10%;
  }
  div:nth-child(6) {
    border-left-width: 0;
    width: 20%;
  }
}
</style>
