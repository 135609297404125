<template>
  <div class="event">
    <div class="reminder">事件通知 <span>event</span></div>
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="事件通知">
        <router-view name="three" v-if="active == '1'" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="历史事件通知">
        <router-view name="three" v-if="active == '2'" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "event",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/event/eventPage") {
          this.active = "1";
        } else if (newVal == "/event/eventHistoryPage") {
          this.active = "2";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/event/eventPage") return;
          this.$router.replace("/event/eventPage");
        }
        if (newVal === "2") {
          if (this.$route.path == "/event/eventHistoryPage") return;
          this.$router.replace("/event/eventHistoryPage");
        }
      },
    },
  },
};
</script>
