import http from "./axios";

// 查询列表
export const serverConfigTemplateList = ({ pageNo, pageSize }) => {
  return http.get("/delivery/serverConfigTemplate/list", {
    pageNo: pageNo,
    pageSize: pageSize,
  });
};

// 新增
export const serverConfigTemplateAdd = ({ name, content, enabled }) => {
  return http.json_post("/delivery/serverConfigTemplate/add", {
    name: name,
    content: content,
    enabled: enabled,
  });
};

// 修改
export const serverConfigTemplateUpdate = ({ id, name, content, enabled }) => {
  return http.json_post("/delivery/serverConfigTemplate/update", {
    id: id,
    name: name,
    content: content,
    enabled: enabled,
  });
};

// 删除
export const serverConfigTemplateDelete = ({ id }) => {
  return http.json_post("/serverConfigTemplate/delete", {
    id: id,
  });
};
