<template>
  <div class="apiEdit">
    <h2 class="interface-title">基本信息</h2>
    <div class="desc">
      <a-form>
        <a-form-item
          label="接口名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="formList.title" placeholder="请输入接口名称" />
        </a-form-item>
        <a-form-item
          label="选择分类:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="formList.catId"
            placeholder="请选择分类"
            allowClear
          >
            <a-select-option
              v-for="item in menuList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="接口路径:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="formList.path" placeholder="请输入接口路径">
            <a-select
              slot="addonBefore"
              v-model="formList.method"
              default-value="GET"
            >
              <a-select-option
                v-for="(item, index) in methodList"
                :key="index"
                :value="item.value"
                >{{ item.label }}</a-select-option
              >
            </a-select>
          </a-input>
        </a-form-item>
        <a-form-item
          label="状态:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="formList.status"
            placeholder="请选择状态"
            allowClear
          >
            <a-select-option
              v-for="(item, index) in statusList"
              :key="index"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
    </div>
    <h2 class="interface-title">请求参数</h2>
    <a-tabs type="card" :tabBarStyle="{ border: 'none' }">
      <a-tab-pane key="1" tab="Query">
        <div class="desc">
          <div>
            <a-button type="primary" size="small" @click="addQuery"
              >添加Query参数</a-button
            >
            <div
              v-for="(item, index) in formList.reqQuery"
              :key="index"
              style="margin: 5px 0"
            >
              <a-input
                placeholder="参数名称"
                v-model="item.name"
                style="width: 20%; margin-right: 20px"
              />
              <a-select
                v-model="item.required"
                style="width: 10%; margin-right: 20px"
              >
                <a-select-option
                  v-for="(requiredItem, index) in requiredList"
                  :key="index"
                  :value="requiredItem.value"
                  >{{ requiredItem.label }}</a-select-option
                >
              </a-select>
              <a-input
                placeholder="参数示例"
                v-model="item.example"
                style="width: 20%; margin-right: 20px"
              />
              <a-input
                placeholder="备注"
                v-model="item.desc"
                style="width: 20%"
              />
              <a-button type="link" @click="deleteQuery(index)">
                <a-icon type="delete" theme="filled" style="color: grey" />
              </a-button>
            </div>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Header">
        <div class="desc">
          <div>
            <a-button type="primary" size="small" @click="addHeader"
              >添加Header参数</a-button
            >
          </div>
          <div
            v-for="(item, index) in formList.reqHeaders"
            :key="index"
            style="margin: 5px 0"
          >
            <a-select
              v-model="item.name"
              allowClear
              placeholder="参数名称"
              style="width: 15%; margin-right: 20px"
              showSearch
              option-filter-prop="children"
            >
              <a-select-option
                v-for="(item, index) in headerList"
                :key="index"
                :value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
            <a-input
              placeholder="参数值"
              v-model="item.value"
              style="width: 20%; margin-right: 20px"
            />
            <a-input
              placeholder="参数示例"
              v-model="item.example"
              style="width: 20%; margin-right: 20px"
            />
            <a-input
              placeholder="备注"
              v-model="item.desc"
              style="width: 20%; margin-right: 20px"
            />
            <a-button type="link" @click="deleteHeader(index)">
              <a-icon type="delete" theme="filled" style="color: grey" />
            </a-button>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="3" tab="Body" v-if="formList.method == 'POST'">
        <div class="desc">
          <a-tabs v-model="formList.reqBodyType" @change="callback">
            <a-tab-pane key="form" tab="form">
              <div>
                <a-button type="primary" size="small" @click="addForm"
                  >添加form参数</a-button
                >
                <div
                  v-for="(item, index) in formList.reqBodyForm"
                  :key="index"
                  style="margin: 5px 0"
                >
                  <a-input
                    placeholder="参数名称"
                    v-model="item.name"
                    style="width: 20%"
                  />
                  <a-select v-model="item.type">
                    <a-select-option
                      v-for="(item, index) in typeList"
                      :key="index"
                      :value="item.value"
                      >{{ item.label }}</a-select-option
                    >
                  </a-select>
                  <a-select
                    v-model="item.required"
                    allowClear
                    style="width: 10%"
                  >
                    <a-select-option
                      v-for="(requiredItem, index) in requiredList"
                      :key="index"
                      :value="requiredItem.value"
                      >{{ requiredItem.label }}</a-select-option
                    >
                  </a-select>
                  <a-input
                    placeholder="参数示例"
                    v-model="item.example"
                    style="width: 20%"
                  />
                  <a-input
                    placeholder="备注"
                    v-model="item.desc"
                    style="width: 20%"
                  />
                  <a-button type="link" @click="deleteForm(index)">
                    <a-icon type="delete" theme="filled" style="color: grey" />
                  </a-button>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="json" tab="json">
              <div style="margin: 5px 0">
                <span
                  style="display: inline-block; cursor: pointer"
                  @click="objAni"
                >
                  <a-icon type="caret-down" v-if="objShow" />
                  <a-icon type="caret-right" v-else />
                </span>
                <a-input
                  disabled
                  placeholder="root"
                  style="width: 20%; margin-right: 10px; margin-left: 10px"
                />
                <a-checkbox @change="onCheckAll"></a-checkbox>
                <a-select
                  v-model="formList.reqBodyOther.type"
                  style="width: 10%; margin-right: 20px; margin-left: 10px"
                  defaultValue="object"
                  disabled
                >
                  <a-select-option
                    v-for="(item, index) in datatypeList"
                    :key="index"
                    :value="item.value"
                    >{{ item.label }}</a-select-option
                  >
                </a-select>
                <a-input
                  v-model="formList.reqBodyOther.description"
                  placeholder="备注"
                  style="width: 20%"
                />
                <span class="iconButton" @click="addReqBodyOtherObj">
                  <a-tooltip>
                    <template slot="title"> 添加子节点 </template>
                    <a-icon type="plus" style="color: #2395f1" />
                  </a-tooltip>
                </span>
              </div>
              <template>
                <tree
                  :data="formList.reqBodyOther.properties"
                  :show="false"
                  v-show="objShow"
                  ref="tree"
                ></tree>
              </template>
            </a-tab-pane>
            <a-tab-pane key="file" tab="file">
              <a-textarea :rows="30" v-model="reqBodyOther" />
            </a-tab-pane>
            <a-tab-pane key="raw" tab="raw">
              <a-textarea :rows="30" v-model="reqBodyOther" />
            </a-tab-pane>
          </a-tabs>
        </div>
      </a-tab-pane>
    </a-tabs>
    <h2 class="interface-title">返回数据</h2>
    <a-tabs
      type="card"
      v-model="formList.resBodyType"
      @change="resCallback"
      :tabBarStyle="{ border: 'none' }"
    >
      <a-tab-pane key="json" tab="JSON">
        <div class="desc">
          <a-tabs>
            <a-tab-pane key="muban" tab="模板">
              <div style="margin: 5px 0">
                <span
                  style="display: inline-block; cursor: pointer"
                  @click="objAni"
                >
                  <a-icon type="caret-down" v-if="objShow" />
                  <a-icon type="caret-right" v-else />
                </span>
                <a-input
                  disabled
                  placeholder="root"
                  style="width: 20%; margin-right: 10px; margin-left: 10px"
                />
                <a-checkbox @change="onResCheckAll"></a-checkbox>
                <a-select
                  v-model="formList.resBody.type"
                  style="width: 10%; margin-right: 20px; margin-left: 10px"
                  defaultValue="object"
                  disabled
                >
                  <a-select-option
                    v-for="(item, index) in datatypeList"
                    :key="index"
                    :value="item.value"
                    >{{ item.label }}</a-select-option
                  >
                </a-select>
                <a-input
                  v-model="formList.resBody.description"
                  placeholder="备注"
                  style="width: 20%"
                />
                <span class="iconButton" @click="addResBodyOtherObj">
                  <a-tooltip>
                    <template slot="title"> 添加子节点 </template>
                    <a-icon type="plus" style="color: #2395f1" />
                  </a-tooltip>
                </span>
              </div>
              <template>
                <tree
                  :data="formList.resBody.properties"
                  :show="false"
                  v-show="objShow"
                  ref="resTree"
                ></tree>
              </template>
            </a-tab-pane>
            <a-tab-pane key="yulan" tab="预览">
              <div>
                <codemirror
                  v-model="codemirrorData"
                  :options="temOptions"
                  class="code_style"
                >
                </codemirror>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </a-tab-pane>
      <a-tab-pane key="raw" tab="RAW">
        <div class="desc">
          <a-textarea :rows="30" v-model="resBody" />
        </div>
      </a-tab-pane>
    </a-tabs>
    <h2 class="interface-title">备注</h2>
    <div class="desc">
      <a-textarea :rows="15" v-model="formList.markdown" />
    </div>
    <div :class="{ fixButton: true, fix }">
      <a-button type="primary" @click="saveSubmit">保存</a-button>
    </div>
  </div>
</template>

<script>
import api from "../lib/yapi";
import tree from "./tree.vue";
export default {
  components: { tree },
  name: "apiEdit",
  data() {
    return {
      formList: {},
      menuList: [],
      projectId: "",
      methodList: [
        {
          value: "GET",
          label: "GET",
        },
        {
          value: "POST",
          label: "POST",
        },
      ],
      typeList: [
        {
          value: "text",
          label: "text",
        },
        {
          value: "file",
          label: "file",
        },
      ],
      datatypeList: [
        {
          value: "string",
          label: "string",
        },
        {
          value: "number",
          label: "number",
        },
        {
          value: "array",
          label: "array",
        },
        {
          value: "object",
          label: "object",
        },
        {
          value: "integer",
          label: "integer",
        },
        {
          value: "boolean",
          label: "boolean",
        },
      ],
      statusList: [
        {
          value: "done",
          label: "已完成",
        },
        {
          value: "undone",
          label: "未完成",
        },
      ],
      reqBodyOtherJson: "",
      requiredList: [
        {
          value: "1",
          label: "必需",
        },
        {
          value: "0",
          label: "非必需",
        },
      ],
      index: 1,
      headerList: [
        "Accept",
        "Accept-Charset",
        "Accept-Encoding",
        "Accept-Language",
        "Accept-Datetime",
        "Authorization",
        "Cache-Control",
        "Connection",
        "Cookie",
        "Content-Disposition",
        "Content-Length",
        "Content-MD5",
        "Content-Type",
        "Date",
        "Expect",
        "From",
        "Host",
        "If-Match",
        "If-Modified-Since",
        "If-None-Match",
        "If-Range",
        "If-Unmodified-Since",
        "Max-Forwards",
        "Origin",
        "Pragma",
        "Proxy-Authorization",
        "Range",
        "Referer",
        "TE",
        "User-Agent",
        "Upgrade",
        "Via",
        "Warning",
        "X-Requested-With",
        "DNT",
        "X-Forwarded-For",
        "X-Forwarded-Host",
        "X-Forwarded-Proto",
        "Front-End-Https",
        "X-Http-Method-Override",
        "X-ATT-DeviceId",
        "X-Wap-Profile",
        "Proxy-Connection",
        "X-UIDH",
        "X-Csrf-Token",
      ],
      objShow: true,
      temOptions: {
        mode: { name: "javascript", json: true },
        lineNumbers: true,
        styleActiveLine: true, //设置光标所在行高亮，需引入工具包
        placeholder: "输入内容",
        readOnly: "nocursor",
      },
      fix: false,
    };
  },
  props: ["dataList"],
  watch: {
    dataList: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        this.formList = JSON.parse(JSON.stringify(this.dataList));
        if (!this.formList.reqBodyType) {
          this.$set(this.formList, "reqBodyType", "json");
        }
        if (this.formList.reqBodyOther) {
          this.$set(
            this.formList,
            "reqBodyOther",
            JSON.parse(this.formList.reqBodyOther)
          );
          this.$set(
            this.formList.reqBodyOther,
            "properties",
            this.ObjArr(this.formList.reqBodyOther.properties)
          );
        } else {
          this.$set(this.formList, "reqBodyOther", {
            type: "object",
            description: "",
            properties: [],
          });
        }
        if (this.formList.resBody) {
          this.$set(
            this.formList,
            "resBody",
            JSON.parse(this.formList.resBody)
          );
          this.$set(
            this.formList.resBody,
            "properties",
            this.ObjArr(this.formList.resBody.properties)
          );
        } else {
          this.$set(this.formList, "resBody", {
            type: "object",
            description: "",
            properties: [],
          });
        }
        if (!this.formList.resBodyType) {
          this.$set(this.formList, "resBodyType", "json");
        }
      },
    },
  },
  computed: {
    resBody: {
      set(val) {},
      get() {
        if (typeof this.formList.resBody == "object") {
          return JSON.stringify({
            type: "object",
            description: this.formList.resBody.description,
            properties: this.ArrObj(this.formList.resBody.properties),
          });
        }
      },
    },
    codemirrorData() {
      if (typeof this.formList.resBody == "object") {
        return JSON.stringify(
          this.ArrObjcodemirror(this.formList.resBody.properties),
          null,
          4
        );
      }
    },
    reqBodyOther: {
      set(val) {},
      get() {
        if (typeof this.formList.reqBodyOther == "object") {
          return JSON.stringify({
            type: "object",
            description: this.formList.reqBodyOther.description,
            properties: this.ArrObj(this.formList.reqBodyOther.properties),
          });
        }
      },
    },
  },
  created() {
    let projectId = this.$route.query.projectId;
    if (projectId) {
      this.projectId = projectId;
      this.getApiClass(projectId);
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    onResCheckAll(e) {
      this.cycleCheck(e.target.checked, this.formList.resBody.properties);
    },
    onCheckAll(e) {
      this.cycleCheck(e.target.checked, this.formList.reqBodyOther.properties);
    },
    cycleCheck(show, val) {
      val.forEach((item) => {
        this.$set(item.value, "required", show);
        if (item.value.properties && item.value.type == "object") {
          this.cycleCheck(show, item.value.properties);
        }
      });
    },
    saveSubmit() {
      this.$set(this.formList, "reqBodyOther", this.reqBodyOther);
      this.$set(this.formList, "resBody", this.resBody);
      this.$set(this.formList, "queryPath", {
        path: this.formList.path,
        params: [],
      });
      this.$set(this.formList, "catId", this.formList.catId);
      this.$set(this.formList, "id", this.formList.id);
      api.editApi(this.formList).then((res) => {
        if (res.result == 200) {
          this.$message.success("保存成功");
          this.$emit("getData", this.$route.query.id);
        }
      });
    },
    handleScroll(event) {
      if (event.target.scrollingElement.scrollTop <= 500) {
        this.fix = true;
      } else {
        this.fix = false;
      }
    },
    ArrObjcodemirror(val) {
      let obj = {};
      if (Array.isArray(val)) {
        val.forEach((element) => {
          if (element.value.type == "string") {
            obj[element.key] = "";
          } else if (
            element.value.type == "number" ||
            element.value.type == "integer"
          ) {
            obj[element.key] = 0;
          } else if (element.value.type == "boolean") {
            obj[element.key] = true;
          } else if (element.value.type == "object") {
            obj[element.key] = this.ArrObjcodemirror(element.value.properties);
          } else if (element.value.type == "array") {
            obj[element.key] = this.ArrObjcodemirror(element.value.properties);
          }
        });
      }
      return obj;
    },
    ObjArr(val) {
      let arr = [];
      for (const key in val) {
        if (val[key].properties) {
          arr.push({
            key,
            value: {
              type: val[key].type,
              description: val[key].description,
              required: val[key].required,
              properties: this.ObjArr(val[key].properties),
            },
          });
        } else {
          arr.push({
            key,
            value: {
              type: val[key].type,
              description: val[key].description,
              required: val[key].required,
            },
          });
        }
      }
      return arr;
    },
    ArrObj(val) {
      let obj = {};
      if (Array.isArray(val)) {
        val.forEach((element) => {
          if (element.value.properties) {
            obj[element.key] = {
              type: element.value.type,
              description: element.value.description,
              required: element.value.required,
              properties: this.ArrObj(element.value.properties),
            };
          } else {
            obj[element.key] = element.value;
          }
        });
      }
      return obj;
    },
    objAni() {
      this.objShow = !this.objShow;
    },
    addReqBodyOtherObj() {
      this.$refs.tree.add();
    },
    addResBodyOtherObj() {
      this.$refs.resTree.add();
    },
    callback(key) {
      this.formList.reqBodyType = key;
    },
    resCallback(key) {
      this.formList.resBodyType = key;
    },
    addForm() {
      if (!this.formList.reqBodyForm) {
        this.$set(this.formList, "reqBodyForm", []);
      }
      this.formList.reqBodyForm.push({
        name: "",
        desc: "",
        example: "",
        required: "1",
        type: "text",
      });
    },
    deleteQuery(index) {
      this.formList.reqQuery.splice(index, 1);
    },
    addQuery() {
      if (!this.formList.reqQuery) {
        this.$set(this.formList, "reqQuery", []);
      }
      this.formList.reqQuery.push({
        name: "",
        desc: "",
        example: "",
        required: "1",
      });
    },
    deleteForm(index) {
      this.formList.reqBodyForm.splice(index, 1);
    },
    addHeader() {
      if (!this.formList.reqHeaders) {
        this.$set(this.formList, "reqHeaders", []);
      }
      this.formList.reqHeaders.push({
        name: undefined,
        desc: "",
        example: "",
        value: "",
        required: "1",
      });
    },
    deleteHeader(index) {
      this.formList.reqHeaders.splice(index, 1);
    },
    getApiClass(projectId) {
      this.menuList.splice(0);
      api.getApiClassList({ projectId }).then((res) => {
        if (res.result == 200) {
          this.menuList.push(...res.data);
        }
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.apiEdit {
  padding: 0 40rpx;
  .interface-title {
    clear: both;
    font-weight: 400;
    margin-top: 0.48rem;
    margin-bottom: 1rem;
    border-left: 3px solid #2395f1;
    padding-left: 8px;
  }
  .desc {
    background-color: #f3f4f6;
    padding: 20px;
    .ant-tabs-nav {
      justify-content: left;
      align-items: left;
      display: flex;
    }
    .ant-tabs-tab-active {
      background-color: #fff !important;
      color: #2395f1 !important;
    }
  }
  .ant-tabs-nav {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .ant-tabs-tab-active {
    background-color: #2395f1 !important;
    color: #fff !important;
  }
  .ant-tabs-tab {
    margin: 0 !important;
  }
  .iconButton {
    display: inline-block;
    width: 16px;
    margin: 0 5px;
    cursor: pointer;
  }
  .fixButton {
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin-top: 20px;
  }
  .fix {
    width: calc(100% - 296px);
    position: fixed;
    bottom: 0;
    right: 24px;
    background-color: #f3f4f6;
  }
}
.code_style {
  height: 100%;
  &::v-deep .CodeMirror {
    height: 100% !important;
  }
}
</style>
