<template>
  <div>
    <div class="searchCondition">
      <div class="searchConditionItem">
        环境：
        <a-select
          v-model="environmentId"
          placeholder="请选择环境"
          @change="getErrorLogHistogram()"
        >
          <a-select-option v-for="item in environmentIdList" :key="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        日期：
        <a-date-picker
          format="YYYY-MM-DD"
          v-model="startTime"
          placeholder="开始日期"
          @change="getErrorLogHistogram()"
        />
        <i style="display: inline-block; margin-left: 20px"></i>
        <a-date-picker
          format="YYYY-MM-DD"
          placeholder="结束日期"
          v-model="endTime"
          @change="getErrorLogHistogram()"
        />
      </div>
    </div>
    <div id="myChart" style="height: 500px; margin-top: 50px"></div>
  </div>
</template>

<script>
import { environmentList } from "@/lib/errorLogList";
import * as api from "../lib/errorLogStatistics";
import * as echarts from "echarts";
require("echarts/lib/chart/bar");
import moment from "moment";
export default {
  name: "errorLogStatistics",
  data() {
    return {
      environmentIdList: [],
      environmentId: "",
      startTime: null,
      endTime: null,
    };
  },
  computed: {
    formatDateEndTime() {
      // 开始时间
      if (this.endTime) {
        return this.$common.dayFormat(this.endTime) + " 23:59:59";
      }
      return "";
    },
    formatDateStartTime() {
      // 结束时间
      if (this.startTime) {
        return this.$common.dayFormat(this.startTime) + " 00:00:00";
      }
      return "";
    },
  },
  mounted() {
    this.startTime = moment()
      .subtract(new Date().getDay() + 6, "days")
      .format("YYYY/MM/DD");
    this.endTime = moment()
      .subtract(new Date().getDay(), "days")
      .format("YYYY/MM/DD");
    this.getEnvironmentList();
  },
  methods: {
    getEnvironmentList() {
      environmentList().then((res) => {
        if (res.result === 200) {
          this.environmentIdList = res.data;
          this.environmentId = this.environmentIdList[0].id;
          this.getErrorLogHistogram();
        }
      });
    },
    getErrorLogHistogram() {
      let data = {
        environmentId: this.environmentId,
        startTime: this.formatDateStartTime,
        endTime: this.formatDateEndTime,
      };
      api.errorLogHistogram(data).then((res) => {
        if (res.result == 200) {
          let myChart = echarts.init(document.getElementById("myChart"));
          myChart.setOption({
            title: {
              left: "center",
              text: "接口调用统计",
            },
            color: ["#ee6666"],
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
              formatter: function (params) {
                if (params[0].data.value > 0) {
                  return (
                    params[0].name +
                    "<br>数量: " +
                    params[0].data.value +
                    "<br>详细信息:<br>" +
                    params[0].data.otherInfo.split(",").join("<br>")
                  );
                } else {
                  return params[0].name + "<br>数量: " + params[0].data.value;
                }
              },
            },
            xAxis: {
              type: "category",
              data: res.data.dateList,
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                data: res.data.valueList,
                type: "bar",
              },
            ],
          });
          myChart.getZr().on("click", (params) => {
            let pointInPixel = [params.offsetX, params.offsetY];
            if (myChart.containPixel("grid", pointInPixel)) {
              //点击第几个柱子
              let pointInGrid = myChart.convertFromPixel(
                { seriesIndex: 0 },
                pointInPixel
              );
              let xIndex = pointInGrid[0];
              this.$router.replace({
                path: "/errorLog/errorLogList",
                query: {
                  date: res.data.dateList[xIndex],
                },
              });
            }
          });
        }
      });
    },
  },
};
</script>
