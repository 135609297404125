<template>
  <div class="k8sApplication">
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="name"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="status" slot-scope="text, record">
        <a-tag v-if="record.status == 'APPLY'" color="green"> 已应用 </a-tag>
        <a-tag v-else color="red"> 已删除 </a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="edit(record)"> 修改 </a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="viewContent(record)"> 查看 </a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="history(record)"> 历史版本 </a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
          v-if="record.status == 'APPLY'"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="applyClick(record)"
          v-else
        >
          <template slot="title">确认是否应用{{ record.name }}</template>
          <a href="javascript:;">应用</a>
        </a-popconfirm>
      </span>
    </a-table>

    <a-modal
      :title="title"
      v-model="addShow"
      :maskClosable="false"
      width="600px"
    >
      <a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
        <a-form-item label="名称:">
          <a-input v-model="addData.name" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item label="内容:">
          <a-textarea
            :rows="10"
            v-model="addData.content"
            placeholder="请输入内容"
          />
        </a-form-item>
        <a-form-item label="备注:">
          <a-textarea
            :rows="5"
            v-model="addData.remark"
            placeholder="请输入备注"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="addSubmit()"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="历史版本"
      v-model="historyShow"
      :maskClosable="false"
      width="800px"
      :footer="false"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(historyColumns)"
        :columns="historyColumns"
        :dataSource="historyList"
        :pagination="false"
        size="small"
        :rowKey="(record) => record.id"
      >
        <span slot="action" slot-scope="text, record">
          <a href="javascript:;" @click="viewContent(record)"> 查看 </a>
        </span>
      </a-table>
    </a-modal>

    <a-modal
      title="内容"
      v-if="viewContentShow"
      v-model="viewContentShow"
      :maskClosable="false"
      width="800px"
      :footer="false"
    >
      <div style="height: 600px; overflow-y: scroll" v-html="content" />
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/k8sApplication.js";

export default {
  name: "k8sApplication",
  props: ["type"],
  data() {
    return {
      name: "",
      namespace: "全部命名空间",
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          ellipsis: true,
          width: 80,
        },
        {
          title: "名称",
          dataIndex: "name",
          ellipsis: true,
          width: 300,
        },
        {
          title: "状态",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          width: 100,
        },
        {
          title: "内容",
          dataIndex: "content",
          ellipsis: true,
          width: 400,
        },
        {
          title: "备注",
          dataIndex: "remark",
          ellipsis: true,
          width: 150,
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          ellipsis: true,
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 210,
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      groupId: "",
      title: "",
      addData: {},
      addShow: false,
      historyList: [],
      historyColumns: [
        {
          title: "编号",
          dataIndex: "id",
          ellipsis: true,
          width: 80,
        },
        {
          title: "内容",
          dataIndex: "content",
          ellipsis: true,
          width: 400,
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 80,
        },
      ],
      historyShow: false,
      content: "",
      viewContentShow: false,
    };
  },
  created() {
    this.query();
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        name: this.name,
        type: this.type,
      };
      this.loadingTable = true;
      api
        .k8sApplicationPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource = res.data.records;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.query(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    add() {
      this.title = "新增";
      this.addData = {
        name: "",
        content: "",
        remark: "",
        status: "DELETE",
      };
      this.addShow = true;
    },
    edit(val) {
      this.title = "修改";
      this.groupId = val.id;
      this.addData = {
        name: val.name,
        content: val.content,
        remark: val.remark,
        status: val.status,
      };
      this.addShow = true;
    },
    addSubmit() {
      let data = {
        ...this.addData,
      };
      if (this.title == "新增") {
        data.type = this.type;
        api.addK8sApplication(data).then((res) => {
          if (res.result === 200) {
            this.$message.success("添加成功");
            this.addShow = false;
            this.query(1);
          }
        });
      } else {
        data.id = this.groupId;
        api.editK8sApplication(data).then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.addShow = false;
            this.query();
          }
        });
      }
    },
    history(val) {
      let data = {
        applicationId: val.id,
      };
      api.getHistoryList(data).then((res) => {
        if (res.result === 200) {
          this.historyList = res.data;
          this.historyShow = true;
        }
      });
    },
    viewContent(val) {
      this.content = val.content.replace(/\n/g, "<br/>");
      this.viewContentShow = true;
    },
    applyClick(val) {
      let data = {
        id: val.id,
      };
      api.apply(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("应用成功");
          this.query();
        }
      });
    },
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteK8sApplication(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
  },
};
</script>
