<template>
  <div class="priorityList">
    <div class="reminder" style="font-size: 12px">
      当节点资源不足时,会发生资源抢占,优先级高的POD会抢占优先级低的POD资源
    </div>
    <div class="searchCondition">
      <div class="searchButton">
        <a-button type="primary" @click="query()" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="false"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.key"
    >
      <span slot="globalDefault" slot-scope="text, record">
        {{ record.globalDefault ? "是" : "否" }}
      </span>
      <span slot="valueTitle">
        优先级
        <a-tooltip placement="left">
          <div slot="title">
            值越大表示优先级越高,用户创建的优先级值应该在0到1000000000之间
          </div>
          <a-icon
            type="question-circle"
            style="color: #1879ff; font-size: 14px"
          />
        </a-tooltip>
      </span>
      <span slot="action" slot-scope="text, record">
        <a
          href="javascript:;"
          @click="edit(record)"
          :disabled="!record.editable"
          >修改</a
        >
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          :disabled="!record.editable"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a
            href="javascript:;"
            style="color: #ff4d4f"
            :disabled="!record.editable"
            >删除</a
          >
        </a-popconfirm>
      </span>
    </a-table>
    <a-modal
      :title="title"
      v-model="updateShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="名称:">
          <a-input
            v-model="updateData.name"
            placeholder="请输入名称"
            :disabled="title == '修改'"
          />
        </a-form-item>
        <a-form-item label="是否默认等级:">
          <a-switch
            v-model="updateData.globalDefault"
            checkedChildren="是"
            unCheckedChildren="否"
            :disabled="dataHasGlobalDefault && !updateData.globalDefault"
          />
        </a-form-item>
        <a-form-item label="优先级:">
          <a-input-number
            v-model="updateData.value"
            :min="1"
            :max="1000000000"
            placeholder="请输入优先级"
            style="width: 150px"
          />
        </a-form-item>
        <a-form-item label="描述:">
          <a-textarea
            v-model="updateData.description"
            :autoSize="{ minRows: 5 }"
            placeholder="请输入描述"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="updateShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="updateSubmit()"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/k8s.js";

export default {
  name: "priorityList",
  data() {
    return {
      tableColumns: [
        {
          title: "名称",
          dataIndex: "name",
          ellipsis: true,
        },
        {
          title: "是否默认等级",
          dataIndex: "globalDefault",
          ellipsis: true,
          scopedSlots: { customRender: "globalDefault" },
        },
        {
          dataIndex: "value",
          ellipsis: true,
          scopedSlots: { title: "valueTitle" },
        },
        {
          title: "描述",
          dataIndex: "description",
          ellipsis: true,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 180,
        },
      ],
      tableDataSource: [],
      loadingTable: false,
      title: "",
      updateData: {},
      updateShow: false,
    };
  },
  computed: {
    dataHasGlobalDefault() {
      return this.tableDataSource.some((item) => {
        return item.globalDefault == true;
      });
    },
  },
  created() {
    this.query();
  },
  methods: {
    query() {
      this.loadingTable = true;
      api
        .listPriorityClass()
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource = res.data;
            this.tableDataSource.forEach((item, index) => {
              this.$set(item, "key", index + 1);
            });
            this.tableDataSource.sort((a, b) => {
              return a.value - b.value;
            });
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    add() {
      this.title = "新增";
      this.updateData = {
        name: "",
        globalDefault: false,
        value: 1000,
        description: "",
      };
      this.updateShow = true;
    },
    edit(val) {
      this.title = "修改";
      this.updateData = {
        name: val.name,
        globalDefault: val.globalDefault,
        value: val.value,
        description: val.description,
      };
      this.updateShow = true;
    },
    updateSubmit() {
      let data = {
        ...this.updateData,
      };
      if (this.title == "新增") {
        api.createPriorityClass(data).then((res) => {
          if (res.result === 200) {
            this.$message.success("添加成功");
            this.updateShow = false;
            this.query();
          }
        });
      } else {
        api.updatePriorityClass(data).then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.updateShow = false;
            this.query();
          }
        });
      }
    },
    deleteClick(val) {
      let data = {
        name: val.name,
      };
      api.deletePriorityClass(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
  },
};
</script>
