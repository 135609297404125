<template>
  <div class="trustList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        服务器：
        <a-select
          v-model="serverId"
          placeholder="请选择服务器"
          :filter-option="false"
          @search="instanceIdSearch"
          allowClear
        >
          <a-select-option v-for="item in instanceList" :key="item.id">{{
            item.instanceName
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addClick" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="action" slot-scope="text, record">
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      title="新增"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="服务器:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="serverIdList"
            mode="multiple"
            showSearch
            placeholder="请选择服务器"
            :filter-option="false"
            @search="instanceIdSearch"
            allowClear
          >
            <a-select-option v-for="item in instanceList" :key="item.id">{{
              item.instanceName
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="公钥:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="publicKeyId"
            showSearch
            placeholder="请选择公钥"
            :filter-option="false"
            @search="publicKeyIdSarch"
            allowClear
          >
            <a-select-option v-for="item in publicKeyIdList" :key="item.id">{{
              item.identifyName
            }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/trustList.js";

export default {
  name: "trustList",
  data() {
    return {
      serverId: undefined,
      publicKeyId: undefined,
      publicKeyIdList: [],
      serverIdList: [],
      instanceList: [],
      // -----------------------------
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "服务器名称",
          ellipsis: true,
          dataIndex: "serverName",
          width: 200,
        },
        {
          title: "公钥名称",
          ellipsis: true,
          dataIndex: "name",
          width: 150,
        },
        {
          title: "操作者",
          ellipsis: true,
          dataIndex: "operator",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 150,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
    };
  },
  mounted() {
    this.query(1);
    this.getServerList();
  },
  methods: {
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        serverId: this.serverId,
      };
      this.getTrustList(data);
    },
    // 查询账号列表
    getTrustList(data) {
      this.loadingTable = true;
      api
        .trustList(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 公钥下拉框发生变化
    publicKeyIdSarch(identifyName) {
      this.getSearchPublicKey(identifyName);
    },
    // 查询公钥集合列表
    getSearchPublicKey(identifyName) {
      let data = {
        identifyName: identifyName,
      };
      api.searchPublicKey(data).then((res) => {
        if (res.result === 200) {
          this.publicKeyIdList = res.data;
        }
      });
    },
    // 服务器下拉框发生变化
    instanceIdSearch(instanceName) {
      this.getServerList(instanceName);
    },
    // 查询服务器列表
    getServerList(instanceName) {
      let data = {
        instanceName: instanceName,
        enabled: true,
      };
      api.serverList(data).then((res) => {
        if (res.result === 200) {
          this.instanceList = res.data;
        }
      });
    },
    // 新增
    addClick() {
      this.addKeyVisible = true;
      this.getSearchPublicKey();
      this.getServerList();
      this.publicKeyId = undefined;
      this.serverIdList = [];
    },
    // 确定新增
    add_submit() {
      let data = {
        publicKeyId: this.publicKeyId,
        serverIdList: this.serverIdList,
      };
      api.trustAdd(data).then((res) => {
        if (res.result === 200) {
          this.addKeyVisible = false;
          this.$message.success(res.data);
          this.query();
        }
      });
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.trustDelete(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
