<template>
  <a-layout id="components-layout-demo-top-side-2">
    <a-layout id="content">
      <a-layout-sider width="250" style="background: #fff">
        <a-menu
          mode="inline"
          :selectedKeys="selectedKeys"
          :openKeys="openKeys"
          @openChange="onOpenChange"
          :style="{ height: '100%', borderRight: 0 }"
        >
          <a-menu-item key="all" v-if="!$route.query.uuid" @click="allApiList">
            <span
              style="display: inline-block; width: 100%"
              @mouseenter="addApiShow = true"
              @mouseleave="addApiShow = false"
            >
              {{ projectName }}
              <span
                style="float: right"
                @click.stop="addClass"
                v-if="addApiShow && !$route.query.uuid"
              >
                <a-tooltip>
                  <template slot="title">添加分类</template>
                  <a-icon type="plus" />
                </a-tooltip>
              </span>
            </span>
          </a-menu-item>
          <a-sub-menu
            v-for="(item, index) in menuList"
            :key="item.id"
            :class="{ 'ant-menu-item-selected': item.selectShow }"
          >
            <span
              slot="title"
              style="display: inline-block; width: 100%"
              @mouseenter="item.show = true"
              @mouseleave="item.show = false"
              @click.stop="CatList(item)"
            >
              <span :class="{ menuShow: item.show }">{{ item.name }}</span>
              <span
                style="float: right"
                @click.stop="deleteClass(item)"
                v-if="item.show && !$route.query.uuid"
              >
                <a-tooltip>
                  <template slot="title">删除分类</template>
                  <a-icon type="delete" />
                </a-tooltip>
              </span>
              <span
                style="float: right"
                @click.stop="editClass(item)"
                v-if="item.show && !$route.query.uuid"
              >
                <a-tooltip>
                  <template slot="title">修改分类</template>
                  <a-icon type="edit" />
                </a-tooltip>
              </span>
              <span
                style="float: right"
                @click.stop="addApi(item)"
                v-if="item.show && !$route.query.uuid"
              >
                <a-tooltip>
                  <template slot="title">添加接口</template>
                  <a-icon type="plus" />
                </a-tooltip>
              </span>
            </span>
            <a-menu-item
              v-for="(menu, index) in item.list"
              :key="menu.id"
              @click="apiDetail(menu)"
            >
              <span
                style="display: inline-block; width: 100%"
                @mouseenter="menu.show = true"
                @mouseleave="menu.show = false"
              >
                <span :class="{ menuShow: menu.show }">{{ menu.title }}</span>
                <span
                  style="float: right"
                  @click.stop="copyApi(menu)"
                  v-if="menu.show && !$route.query.uuid"
                >
                  <a-tooltip>
                    <template slot="title">复制接口</template>
                    <a-icon type="copy" />
                  </a-tooltip>
                </span>
                <span
                  style="float: right"
                  @click.stop="deleteApi(menu)"
                  v-if="menu.show && !$route.query.uuid"
                >
                  <a-tooltip>
                    <template slot="title">删除接口</template>
                    <a-icon type="delete" />
                  </a-tooltip>
                </span>
              </span>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout style="padding: 0 0 0 20px">
        <a-layout-content
          :style="{
            background: '#fff',
            padding: '24px',
            margin: 0,
            minHeight: '280px',
          }"
        >
          <div style="background: rgb(255, 255, 255)">
            <router-view name="second" @apiDetail="apiDetail" />
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
    <a-modal
      :title="title"
      v-model="addClassVisible"
      :maskClosable="false"
      :afterClose="addClassClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="分类名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input placeholder="请输入分类名称" v-model="className" />
        </a-form-item>
        <a-form-item
          label="备注:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input placeholder="请输入备注" v-model="remark" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addClassVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addClass_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <a-modal
      title="添加接口"
      v-model="addApiVisible"
      :maskClosable="false"
      :afterClose="addApiClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="接口分类:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select v-model="apiClass">
            <a-select-option
              v-for="(item, index) in menuList"
              :key="index"
              :value="item.name"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="接口名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input placeholder="请输入接口名称" v-model="apiName" />
        </a-form-item>
        <a-form-item
          label="接口路径:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="path" placeholder="请输入接口路径">
            <a-select slot="addonBefore" v-model="method" default-value="GET">
              <a-select-option
                v-for="(item, index) in methodList"
                :key="index"
                :value="item.value"
                >{{ item.label }}</a-select-option
              >
            </a-select>
          </a-input>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addApiVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addApi_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <a-modal
      title="复制接口"
      v-model="copyApiVisible"
      :maskClosable="false"
      :afterClose="copyApiClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="接口名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input placeholder="请输入接口名称" v-model="apiTitle" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="copyApiVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="copyApi_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </a-layout>
</template>
<script>
import api from "../lib/yapi";
import apiOut from "../lib/yapiOut";

export default {
  name: "yapi",
  data() {
    return {
      menuList: [],
      projectId: "",
      openKeys: [],
      remark: "",
      className: "",
      addClassVisible: false,
      addApiVisible: false,
      loading: false,
      title: "新增分类",
      path: "",
      apiName: "",
      method: "GET",
      methodList: [
        {
          value: "GET",
          label: "GET",
        },
        {
          value: "POST",
          label: "POST",
        },
      ],
      apiClass: "",
      catId: "",
      addApiShow: false,
      selectedKeysArr: [],
      copyApiVisible: false,
      apiId: "",
      apiTitle: "",
      projectName: "",
    };
  },
  computed: {
    selectedKeys() {
      return this.selectedKeysArr;
    },
  },
  created() {
    document.title = "接口列表";
    if (this.$route.path !== "/allApiList") {
      this.openKeys = [this.$route.query.catId * 1];
      this.selectedKeysArr = [this.$route.query.id * 1];
    } else if (this.$route.query.catId) {
      this.selectedKeysArr = [];
    } else {
      this.selectedKeysArr = ["all"];
    }
    let projectId = this.$route.query.projectId;
    if (projectId) {
      this.projectId = projectId;
      this.getApiClass(projectId);
    } else {
      this.$router.replace("/interfaceManagement");
    }
  },
  methods: {
    copyApi_submit() {
      let data = {
        id: this.apiId,
        title: this.apiTitle,
      };
      api.copyApi(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("添加接口成功");
          this.getApiClass(this.projectId);
          this.copyApiVisible = false;
        }
      });
    },
    copyApiClose() {
      this.apiTitle = "";
      this.apiId = "";
    },
    CatList(val) {
      this.selectedKeysArr = [];
      this.menuList.forEach((item) => {
        this.$set(item, "selectShow", false);
      });
      this.$set(val, "selectShow", true);
      if (this.openKeys[0] !== val.id) {
        this.openKeys = [val.id];
      }
      if (
        !(
          this.$route.query.catId == val.id && this.$route.path == "/allApiList"
        )
      ) {
        if (this.$route.query.uuid) {
          this.$router.replace({
            path: "/allApiList",
            query: {
              projectId: this.projectId,
              catId: val.id,
              uuid: this.$route.query.uuid,
            },
          });
        } else {
          this.$router.replace({
            path: "/allApiList",
            query: {
              projectId: this.projectId,
              catId: val.id,
            },
          });
        }
      }
    },
    copyApi(val) {
      this.apiId = val.id;
      this.apiTitle = val.title + "_copy";
      this.copyApiVisible = true;
    },
    deleteApi(val) {
      this.$confirm({
        title: "确认删除此接口吗",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          api.deleteApi({ id: val.id }).then((res) => {
            if (res.result == 200) {
              this.$message.success("删除成功");
              this.getApiClass(this.projectId);
            }
          });
        },
        onCancel: () => {},
        class: "test",
      });
    },
    addApi_submit() {
      let data = {
        projectId: this.projectId,
        catId: this.catId,
        method: this.method,
        path: this.path,
        title: this.apiName,
      };
      api.addApi(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("添加成功");
          this.addApiVisible = false;
          this.getApiClass(this.projectId);
        }
      });
    },
    addApiClose() {
      this.apiClass = "";
      this.method = "GET";
      this.apiName = "";
      this.path = "";
    },
    addApi(val) {
      this.catId = val.id;
      this.apiClass = val.name;
      this.addApiVisible = true;
    },
    deleteClass(val) {
      this.$confirm({
        title: "确认删除此接口分类吗",
        content: (h) => (
          <div>温馨提示：该操作会删除该分类下所有接口，接口删除后无法恢复</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          api.deleteClass({ catId: val.id }).then((res) => {
            if (res.result == 200) {
              this.$message.success("删除成功");
              this.getApiClass(this.projectId);
            }
          });
        },
        onCancel: () => {
          // console.log('Cancel');
        },
        class: "test",
      });
    },
    editClass(val) {
      this.title = "修改分类";
      this.className = val.name;
      this.remark = val.desc;
      this.addClassVisible = true;
      this.catId = val.id;
    },
    addClass_submit() {
      let data = {
        name: this.className,
        desc: this.remark,
      };
      if (this.title == "新增分类") {
        data.yapiProjectId = this.projectId;
        api.addClass(data).then((res) => {
          if (res.result == 200) {
            this.$message.success("添加成功");
            this.addClassVisible = false;
            this.getApiClass(this.projectId);
          }
        });
      } else {
        data.catId = this.catId;
        api.editClass(data).then((res) => {
          if (res.result == 200) {
            this.$message.success("添加成功");
            this.addClassVisible = false;
            this.getApiClass(this.projectId);
          }
        });
      }
    },
    addClassClose() {
      this.className = "";
      this.remark = "";
      this.title = "新增分类";
    },
    addClass() {
      this.addClassVisible = true;
    },
    onOpenChange(openKeys) {
      this.openKeys = openKeys;
    },
    apiDetail(val) {
      let catId = this.$route.query.catId;
      let id = this.$route.query.id;
      if (id !== val.id || catId !== val.catId) {
        if (this.$route.query.uuid) {
          this.$router.replace({
            path: "/apiDetail",
            query: {
              catId: val.catId,
              id: val.id,
              projectId: this.projectId,
              uuid: this.$route.query.uuid,
            },
          });
        } else {
          this.$router.replace({
            path: "/apiDetail",
            query: {
              catId: val.catId,
              id: val.id,
              projectId: this.projectId,
            },
          });
        }
      }
      this.selectedKeysArr = [this.$route.query.id * 1];
      this.selectHide();
    },
    allApiList() {
      if (
        !(this.$route.path == "/allApiList" && this.$route.query.catId == null)
      ) {
        if (this.$route.query.uuid) {
          this.$router.replace({
            path: "/allApiList",
            query: {
              projectId: this.projectId,
              catId: null,
              uuid: this.$route.query.uuid,
            },
          });
        } else {
          this.$router.replace({
            path: "/allApiList",
            query: {
              projectId: this.projectId,
              catId: null,
            },
          });
        }
      }
      this.selectedKeysArr = ["all"];
      this.openKeys = [this.menuList[0].id];
      this.selectHide();
    },
    selectHide() {
      this.menuList.forEach((item) => {
        this.$set(item, "selectShow", false);
      });
    },
    getApiClass(projectId) {
      this.menuList.splice(0);
      let uuid = this.$route.query.uuid;
      if (uuid) {
        apiOut.getApiClassList({ projectId, uuid }).then((res) => {
          if (res.result == 200) {
            this.projectName = res.data.projectName;
            this.menuList.push(...res.data);
            let flag = false;
            this.menuList.forEach((item) => {
              this.$set(item, "show", false);
              this.$set(item, "selectShow", false);
              if (this.$route.query.uuid) {
                this.$set(item, "selectShow", true);
                this.openKeys = [item.id];
                flag = true;
              } else if (
                this.$route.query.catId == item.id &&
                this.$route.path == "/allApiList"
              ) {
                this.$set(item, "selectShow", true);
                this.openKeys = [item.id];
                flag = true;
              } else if (
                this.$route.query.catId == item.id &&
                this.$route.path == "/apiDetail"
              ) {
                item.list.forEach((listItem) => {
                  if (this.$route.query.id == listItem.id) {
                    this.openKeys = [item.id];
                    flag = true;
                  }
                });
              }
              if (Array.isArray(item.list)) {
                item.list.forEach((listItem) => {
                  this.$set(listItem, "show", false);
                });
              }
            });
            if (!flag) {
              this.openKeys = [this.menuList[0].id];
            }
          }
        });
      } else {
        api.getApiClassList({ projectId }).then((res) => {
          if (res.result == 200) {
            this.projectName = res.data.projectName;
            this.menuList.push(...res.data.list);
            let flag = false;
            this.menuList.forEach((item) => {
              this.$set(item, "show", false);
              this.$set(item, "selectShow", false);
              if (
                this.$route.query.catId == item.id &&
                this.$route.path == "/allApiList"
              ) {
                this.$set(item, "selectShow", true);
                this.openKeys = [item.id];
                flag = true;
              } else if (
                this.$route.query.catId == item.id &&
                this.$route.path == "/apiDetail"
              ) {
                item.list.forEach((listItem) => {
                  if (this.$route.query.id == listItem.id) {
                    this.openKeys = [item.id];
                    flag = true;
                  }
                });
              }
              if (Array.isArray(item.list)) {
                item.list.forEach((listItem) => {
                  this.$set(listItem, "show", false);
                });
              }
            });
            if (!flag) {
              this.openKeys = [this.menuList[0].id];
            }
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#components-layout-demo-top-side-2 {
  min-height: 100%;
  padding: 20px;
  .menuShow {
    display: inline-block;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
