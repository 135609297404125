import http from "./axios";

// 查询模板列表
export const codeTemplatePage = (data) => {
  return http.get("/generatorCode/codeTemplatePage", data);
};

// 新增模板
export const addCodeTemplate = (data) => {
  return http.json_post("/generatorCode/addCodeTemplate", data);
};

// 编辑模板
export const editCodeTemplate = (data) => {
  return http.json_post("/generatorCode/editCodeTemplate", data);
};

// 删除模板
export const deleteCodeTemplate = (data) => {
  return http.get("/generatorCode/deleteCodeTemplate", data);
};

// 修改模板内容
export const editCodeTemplateContent = (data) => {
  return http.json_post("/generatorCode/editCodeTemplateContent", data);
};

// 模板类型
export const templateTypeList = () => {
  return http.get("/generatorCode/templateTypeList");
};

// 模板集合
export const codeTemplateList = (data) => {
  return http.get("/generatorCode/codeTemplateList", data);
};

// 类别下拉框
export const codeTemplateCategoryList = () => {
  return http.get("/generatorCode/codeTemplateCategoryList");
};
