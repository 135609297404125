import http from "./axios";

// 查询分页列表
export const getPage = ({ name, pageNo, pageSize }) => {
  return http.get("/ansible/page", {
    name: name,
    pageNo: pageNo,
    pageSize: pageSize,
  });
};

// 新增
export const add = ({ name, path, remark }) => {
  return http.json_post("/ansible/add", {
    name: name,
    path: path,
    remark: remark,
  });
};

// 修改
export const edit = ({ id, name, path, remark }) => {
  return http.json_post("/ansible/edit", {
    id: id,
    name: name,
    path: path,
    remark: remark,
  });
};

// 删除
export const ansibleDelete = ({ id }) => {
  return http.json_post("/ansible/delete", {
    id: id,
  });
};

// 新增
export const addAnsibleTask = ({ ansibleId, serverIdList }) => {
  return http.json_post("/ansible/addAnsibleTask", {
    ansibleId: ansibleId,
    serverIdList: serverIdList,
  });
};
