<template>
  <div class="warning">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="告警组">
        <router-view name="three" v-if="active == '1'" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="告警配置">
        <router-view name="three" v-if="active == '2'" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="告警组成员管理">
        <router-view name="three" v-if="active == '3'" />
      </a-tab-pane>
      <a-tab-pane key="4" tab="告警记录">
        <router-view name="three" v-if="active == '4'" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "warning",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/warning/warningGroupPage") {
          this.active = "1";
        } else if (newVal == "/warning/warningConfigPage") {
          this.active = "2";
        } else if (newVal == "/warning/warningMemberPage") {
          this.active = "3";
        } else if (newVal == "/warning/warningRecordPage") {
          this.active = "4";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/warning/warningGroupPage") return;
          this.$router.replace("/warning/warningGroupPage");
        } else if (newVal === "2") {
          if (this.$route.path == "/warning/warningConfigPage") return;
          this.$router.replace("/warning/warningConfigPage");
        } else if (newVal === "3") {
          if (this.$route.path == "/warning/warningMemberPage") return;
          this.$router.replace("/warning/warningMemberPage");
        } else {
          if (this.$route.path == "/warning/warningRecordPage") return;
          this.$router.replace("/warning/warningRecordPage");
        }
      },
    },
  },
};
</script>
