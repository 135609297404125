import http from "./axios";

// 查询证书分页列表
export const getPage = ({ name, pageNo, pageSize }) => {
  return http.get("/certificate/page", {
    name: name,
    pageNo: pageNo,
    pageSize: pageSize,
  });
};

export const providerList = () => {
  return http.get("/certificate/providerList");
};

// 新增证书
export const addCertificate = ({
  name,
  fullChainFile,
  privateKeyFile,
  provider,
}) => {
  return http.files("/certificate/addCertificate", {
    name: name,
    fullChainFile: fullChainFile,
    privateKeyFile: privateKeyFile,
    provider,
  });
};

export const renewCertificate = ({
  id,
  fullChainFile,
  privateKeyFile,
  provider,
  checkNameMatch,
}) => {
  return http.files("/certificate/renewCertificate", {
    id: id,
    fullChainFile: fullChainFile,
    privateKeyFile: privateKeyFile,
    provider,
    checkNameMatch,
  });
};

// 部署证书
export const listDeployedCertificate = ({ certificateId }) => {
  return http.get("/certificate/listDeployedCertificate", {
    certificateId,
  });
};

export const deployCertificate = ({ idList, deployType }) => {
  return http.json_post("/certificate/deployCertificate", {
    idList,
    deployType,
  });
};

// 删除证书
export const deleteCertificate = ({ id }) => {
  return http.json_post("/certificate/deleteCertificate", {
    id: id,
  });
};

// 下载证书
export const downloadCert = ({ id, type }) => {
  return http.get("/certificate/downloadCert", {
    id: id,
    type: type,
  });
};
