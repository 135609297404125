<template>
  <div class="shellTiming">
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="name"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addClick" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="status" slot-scope="text, record">
        <a-switch
          checked-children="已启用"
          un-checked-children="已禁用"
          v-model="record.status"
          @change="modifyClick(record, true)"
        />
      </span>
      <span slot="serverNameList" slot-scope="text">
        <span>{{ text.join("，") }}</span>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <div class="txt">*每分钟轮询一次</div>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="nameAdd" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item
          label="命令:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <!-- <a-input v-model="shell" placeholder="请输入命令" /> -->
          <a-textarea v-model="shell" placeholder="请输入命令" :rows="4" />
        </a-form-item>
        <a-form-item
          label="cron表达式:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="cron" placeholder="请输入cron表达式" />
        </a-form-item>
        <a-form-item
          label="状态:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="status"
          />
        </a-form-item>
        <a-form-item
          label="服务器:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="serverId"
            placeholder="试试搜索"
            showSearch
            option-filter-prop="children"
            style="width: 300px"
            allowClear
            mode="multiple"
          >
            <a-select-option v-for="item in serverIdList" :key="item.id">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/shellTiming.js";
import { serverList } from "../lib/serverList.js";

export default {
  name: "shellTiming",
  data() {
    return {
      title: "",
      name: "",
      nameAdd: "",
      shell: "",
      cron: "",
      status: true,
      serverId: [],
      serverIdList: [],
      groupId: "",
      // -----------------------------
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
          width: 200,
        },
        {
          title: "命令",
          ellipsis: true,
          dataIndex: "shell",
        },
        {
          title: "cron表达式",
          ellipsis: true,
          dataIndex: "cron",
          width: 200,
        },
        {
          title: "服务器",
          ellipsis: true,
          dataIndex: "serverNameList",
          width: 250,
          scopedSlots: { customRender: "serverNameList" },
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          width: 100,
        },
        {
          title: "下次执行时间",
          ellipsis: true,
          dataIndex: "nextExecuteTime",
          width: 150,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 150,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        name: this.name,
      };
      this.getTimeTask(data);
    },
    // 查询shell定时任务分页列表
    getTimeTask(data) {
      this.loadingTable = true;
      api
        .timeTask(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 修改
    modifyClick(val, status) {
      this.getServerAll();
      this.title = "修改";
      this.groupId = val.id;
      this.nameAdd = val.name;
      this.shell = val.shell;
      this.cron = val.cron;
      this.status = val.status;
      this.serverId = val.serverIdList;
      if (status) {
        this.add_submit();
      } else {
        this.addKeyVisible = true;
      }
    },
    // 新增
    addClick() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.getServerAll();
    },
    // 确定新增
    add_submit() {
      let data = {
        name: this.nameAdd,
        shell: this.shell,
        cron: this.cron,
        status: this.status,
        serverList: this.serverId,
      };
      if (this.title === "新增") {
        api.addTask(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.id = this.groupId;
        api.updateTask(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.nameAdd = "";
      this.shell = "";
      this.cron = "";
      this.status = true;
      this.serverId = [];
      this.groupId = "";
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteTask(data).then((res) => {
        if (res.result === 200) {
          this.query();
          this.$message.success("删除成功");
        }
      });
    },
    // 查询所有服务器
    getServerAll() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        enabled: true,
      };
      serverList(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item) => {
            item.title = item.publicIp + "(" + item.instanceName + ")";
          });
          this.serverIdList = list;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.txt {
  color: Orange;
  font-size: 16px;
  font-weight: 600;
}
</style>
