<template>
  <div class="editable-cell">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <a-input
        v-show="type == 'port'"
        :value="value"
        @change="handleChange"
        @pressEnter="check"
        style="width: 55px"
      />
      <a-input
        v-show="type == 'weight'"
        :value="value"
        @change="handleChange"
        @pressEnter="check"
        style="width: 50px"
      />
      <a-input
        v-show="type == 'remark'"
        :value="value"
        @change="handleChange"
        @pressEnter="check"
        style="width: 110px"
      />
      <a-input
        v-show="type == 'groupName'"
        :value="value"
        @change="handleChange"
        @pressEnter="check"
        style="width: 200px"
      />
      <a-icon
        type="check"
        class="editable-cell-icon-check editable-cell-icon"
        @click="check"
      />
    </div>
    <div v-else class="editable-cell-text-wrapper">
      {{ value || "—" }}
      <a-icon type="edit" class="editable-cell-icon" @click="edit" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["text", "type"],
  data() {
    return {
      value: this.text,
      editable: false,
    };
  },
  methods: {
    handleChange(e) {
      const value = e.target.value;
      this.value = value;
    },
    check() {
      this.editable = false;
      this.$emit("change", this.value);
    },
    edit() {
      this.editable = true;
    },
  },
};
</script>

<style>
.editable-cell {
  position: relative;
  min-height: 21px;
}
.editable-cell-icon {
  float: right;
  opacity: 0;
  margin-top: 5px;
  margin-left: 5px;
}
.editable-cell:hover .editable-cell-icon {
  opacity: 1;
}
</style>