<template>
  <div class="newRequest">
    <!-- 新建发布申请 -->
    <a-modal
      :title="requestTitle"
      :visible="visible"
      :maskClosable="false"
      :afterClose="afterClose"
      @cancel="requestClose"
      width="800px"
      class="newRequest_style"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="申请标题" required>
          <a-input v-model="newRelease.appTitle" placeholder="请输入申请标题" />
        </a-form-item>
        <a-form-item
          label="选择分支/标签/版本"
          required
          extra="根据网络情况，首次刷新可能会很慢，请耐心等待。"
        >
          <a-input-group compact>
            <a-select style="width: 20%" default-value="Branch">
              <a-select-option value="Branch"> Branch </a-select-option>
            </a-select>
            <a-select
              v-model="newRelease.branchGit"
              placeholder="请选择分支/标签/版本"
              style="width: 80%"
              @change="branchChange"
            >
              <a-select-option v-for="i in branchList" :key="i.branchName">
                {{ i.branchName }}
              </a-select-option>
            </a-select>
          </a-input-group>
          <a-button type="link" class="sx_btn" @click="branchListRefresh">
            刷新
          </a-button>
        </a-form-item>
        <a-form-item label="选择Commit ID" required>
          <a-select
            v-model="newRelease.commitId"
            placeholder="请选择Commit ID"
            @change="commitIdChange"
          >
            <a-select-option v-for="i in commitIdList" :key="i.id">
              <a-tooltip v-if="i.id == currentCommitId">
                <template slot="title"> 当前版本 </template>
                <span class="current">*</span>
              </a-tooltip>
              {{ i.committedDate.slice(5) }} | {{ i.message }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="requestClose">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            @click="newRequest_submit"
            :disabled="submitDisabled"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/newRequest";
import {
  listRepositoryCommits,
  currentDeliveryInfo,
} from "../lib/projectList.js";

export default {
  name: "newRequest",
  props: {
    show: {
      // 显示
      type: Boolean,
      default: false,
    },
    requestTitle: {
      // 名称
      type: String,
      default: "",
    },
    branchInfo: {
      // 分支信息
      type: String,
      default: "",
    },
    // 发布列表Id
    configId: [String, Number],
    projectId: [String, Number],
    requestData: {
      // 发布申请配配置数据
      type: Object,
      default: function () {
        return {};
      },
    },
    branchList: {
      // branchList列表
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      commitIdList: [],
      newRelease: {
        appTitle: "",
        commitInfo: "",
        gitCommiter: "",
        gitDate: "",
        branchGit: undefined,
        commitId: undefined,
        projectId: "",
        environmentId: "",
      },
      submitDisabled: true,
      currentCommitId: "",
    };
  },
  watch: {
    requestData: {
      immediate: true,
      handler(val) {
        this.newRelease = this.$common.deepClone(val);
      },
    },
    branchList: {
      immediate: true,
      handler() {
        this.submitDisabled = true;
        if (this.newRelease.projectId) {
          for (let i in this.branchList) {
            let item = this.branchList[i];
            if (item.branchName === this.branchInfo) {
              this.branchChange(item.branchName);
              return;
            }
            if (item.branchName === "master") {
              this.branchChange(item.branchName);
            }
          }
        }
      },
    },
  },
  computed: {
    visible() {
      return this.show;
    },
  },
  methods: {
    deliveryRequestAdd_submit() {
      let data = {
        title: this.newRelease.appTitle,
        configId: this.configId,
        branch: this.newRelease.branchGit,
        commitId: this.newRelease.commitId,
        commitInfo: this.newRelease.commitInfo,
        gitDate: this.newRelease.gitDate,
        gitCommiter: this.newRelease.gitCommiter,
      };
      this.submitDisabled = true;
      api
        .deliveryRequestAddNew(data)
        .then((res) => {
          this.submitDisabled = false;
          if (res.result === 200) {
            this.$message.success("新建发布申请成功");
            this.$emit("submit", false);
            if (this.$route.path.indexOf("/request") == -1) {
              this.$router.replace("/request");
            }
          } else {
            this.$message.error(res.message);
            this.$emit("submit", false);
          }
        })
        .catch((err) => {
          this.submitDisabled = false;
        });
    },
    // 确定新建申请发布
    newRequest_submit() {
      api
        .ifCanDelivery({
          projectId: this.newRelease.projectId,
          environmentId: this.newRelease.environmentId,
        })
        .then((res) => {
          if (res.result == 200) {
            if (res.data) {
              this.deliveryRequestAdd_submit();
            } else {
              this.$confirm({
                title: "确认发布",
                zIndex: 4000,
                content: (h) => (
                  <div style="color:red;">
                    现在为非常规发布时间, 强制发布造成的线上问题将由自己负责
                  </div>
                ),
                okText: "确定",
                cancelText: "取消",
                onOk: () => {
                  this.deliveryRequestAdd_submit();
                },
              });
            }
          }
        });
    },
    // 取消新建申请发布
    requestClose() {
      this.$emit("cancel", true);
    },
    // 关闭新建申请发布
    afterClose() {
      this.newRelease = {
        appTitle: "",
        commitInfo: "",
        gitDate: "",
        gitCommiter: "",
        branchGit: undefined,
        commitId: undefined,
        projectId: "",
      };
    },
    // 点击分支刷新
    branchListRefresh() {
      this.$emit("refresh");
    },
    // 选择git分支
    branchChange(val) {
      let data = {
        projectId: this.newRelease.projectId,
        branchName: val,
      };
      this.newRelease.branchGit = val;
      listRepositoryCommits(data).then((res) => {
        if (res.result === 200) {
          this.commitIdList = res.data;
          if (this.commitIdList.length > 0) {
            this.newRelease.commitId = this.commitIdList[0].id;
            this.newRelease.appTitle = this.commitIdList[0].message;
            this.newRelease.commitInfo = this.commitIdList[0].message;
            this.newRelease.gitDate = this.$common.transformTime(
              this.commitIdList[0].committedDateStr.replace(/-/g, "/")
            );
            this.newRelease.gitCommiter = this.commitIdList[0].committerName;
            this.submitDisabled = false;
            this.$forceUpdate();
            this.getCurrentDeliveryInfo();
          }
        }
      });
    },
    getCurrentDeliveryInfo() {
      let data = {
        projectId: this.newRelease.projectId,
        environmentId: this.newRelease.environmentId,
      };
      currentDeliveryInfo(data).then((res) => {
        if (res.result == 200) {
          if (res.data) {
            this.currentCommitId = res.data.commitId;
          }
        }
      });
    },
    // 选择commitId
    commitIdChange(val) {
      this.commitIdList.forEach((item) => {
        if (item.id === val) {
          this.newRelease.commitId = item.id;
          this.newRelease.appTitle = item.message;
          this.newRelease.commitInfo = item.message;
          this.newRelease.gitDate = item.gitDate;
          this.newRelease.gitCommiter = item.gitCommiter;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.current {
  color: red;
  line-height: 1;
  margin-right: 5px;
}
.newRequest_style {
  .sx_btn {
    position: absolute;
    top: -5px;
    right: -70px;
  }
  .tags_div {
    width: 100%;
    display: inline-block;
  }
  .tags_btn {
    position: absolute;
    top: -5px;
    right: -99px;
  }
}
.select_host_style {
  .server_group_class {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  .host_tag_select {
    border: 2px dashed #e4e7ed;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    min-height: 120px;
    .ant-tag {
      margin-bottom: 10px;
      height: 25px;
    }
  }
}
</style>
<style>
.ant-select-dropdown {
  z-index: 4000;
}
</style>
