<template>
  <div class="certificateManagement">
    <div class="searchCondition">
      <div class="searchConditionItem">
        证书名称：
        <a-input
          placeholder="请输入证书名称"
          v-model.trim="name"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addClick" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      :rowKey="(record) => record.id"
      size="small"
    >
      <span slot="status" slot-scope="text">
        <a-tag color="green" v-if="text == true">正常</a-tag>
        <a-tag color="red" v-if="text == false">过期</a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-dropdown>
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            下载证书 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a-button type="link" @click="down(record, 'fullchain')">{{
                record.fullChainName
              }}</a-button>
            </a-menu-item>
            <a-menu-item>
              <a-button
                type="link"
                @click="down(record, 'privkey')"
                class="btn-class"
                >{{ record.privateKeyName }}</a-button
              >
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="deployCertificate(record.id)"
          >部署证书</a
        >
        <a-divider type="vertical" />
        <a href="javascript:;" @click="refreshCertificate(record)">刷新证书</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="title == '新增'"
        >
          <span slot="label">
            证书名称
            <a-tooltip>
              <template slot="title"> 泛域名证书必须以*开头 </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-input v-model="nameAdd" placeholder="请输入证书名称" />
        </a-form-item>
        <a-form-item
          label="提供商:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select v-model="provider" placeholder="请选择提供商">
            <a-select-option v-for="item in providerList" :key="item">{{
              item
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="fullchain:"
        >
          <div class="dropbox">
            <a-upload-dragger
              :fileList="fileList"
              :remove="handleRemove"
              :beforeUpload="beforeUpload"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
              <p class="ant-upload-hint">只支持单个上传</p>
            </a-upload-dragger>
          </div>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="privkey:"
        >
          <div class="dropbox">
            <a-upload-dragger
              :fileList="fileList1"
              :remove="handleRemove1"
              :beforeUpload="beforeUpload1"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
              <p class="ant-upload-hint">只支持单个上传</p>
            </a-upload-dragger>
          </div>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="名称检查:"
          v-if="title == '刷新'"
        >
          <a-checkbox v-model="checkNameMatch"></a-checkbox>
        </a-form-item>
      </a-form>
      <h3 v-if="title == '刷新'">刷新后还须"部署证书"才能最终生效</h3>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="'部署证书' + ' | ' + certificateName"
      v-model="visible"
      :maskClosable="false"
      width="1200px"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(sslColumns)"
        :columns="sslColumns"
        :dataSource="sslList"
        :pagination="false"
        size="small"
        :rowKey="(record) => record.id"
        :row-selection="{
          onChange: onSelectChange,
          selectedRowKeys: selectedRowKeys,
        }"
      >
        <span slot="fullChainMd5sum" slot-scope="text, record">
          {{ text }}
          <a-icon
            type="check"
            style="color: #52c41a; margin-left: 10px"
            v-if="text == record.latestFullChainMd5sum"
          />
          <a-button type="link" @click="deploy(record, 'fullChain')" v-else
            >部署</a-button
          >
        </span>
        <span slot="privateKeyMd5sum" slot-scope="text, record">
          {{ text }}
          <a-icon
            type="check"
            style="color: #52c41a; margin-left: 10px"
            v-if="text == record.latestPrivateKeyMd5sum"
          />
          <a-button type="link" @click="deploy(record, 'privateKey')" v-else
            >部署</a-button
          >
        </span>
        <span slot="action" slot-scope="text, record">
          <a-button
            type="link"
            @click="deployRowAll(record)"
            :disabled="
              record.fullChainMd5sum == record.latestFullChainMd5sum &&
              record.privateKeyMd5sum == record.latestPrivateKeyMd5sum
            "
            >一键部署</a-button
          >
        </span>
      </a-table>
      <a-button
        type="danger"
        style="margin-top: 20px"
        @click="deployAll"
        :disabled="!hasSelected"
        >一键部署</a-button
      >
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="visible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/certificateManagement.js";

export default {
  name: "certificateManagement",
  data() {
    return {
      fileList: [],
      fileList1: [],
      title: "",
      name: "",
      nameAdd: "",
      groupId: "",
      // -----------------------------
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "证书名称",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "提供商",
          ellipsis: true,
          dataIndex: "provider",
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "剩余天数",
          ellipsis: true,
          dataIndex: "expireDays",
        },
        {
          title: "过期时间",
          ellipsis: true,
          dataIndex: "expireDate",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      sslList: [],
      certificateName: "",
      visible: false,
      sslColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "网关名称",
          ellipsis: true,
          dataIndex: "nginxGatewayName",
        },
        {
          title: "实例名称",
          ellipsis: true,
          dataIndex: "nginxInstanceName",
        },
        {
          title: "fullChainMd5sum",
          ellipsis: true,
          dataIndex: "fullChainMd5sum",
          scopedSlots: { customRender: "fullChainMd5sum" },
        },
        {
          title: "privateKeyMd5sum",
          ellipsis: true,
          dataIndex: "privateKeyMd5sum",
          scopedSlots: { customRender: "privateKeyMd5sum" },
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      selectedRowKeys: [],
      certificateId: "",
      providerList: [],
      provider: undefined,
      checkNameMatch: true,
    };
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  mounted() {
    this.query(1);
    this.getProviderList();
  },
  methods: {
    getProviderList() {
      api.providerList().then((res) => {
        if ((res.result = 200)) {
          this.providerList = res.data;
        }
      });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys.splice(0);
      selectedRows.forEach((item) => {
        this.selectedRowKeys.push(item.id);
      });
    },
    deployAll() {
      this.deploySubmit(this.selectedRowKeys, "all");
    },
    deployRowAll(record) {
      this.deploySubmit([record.id], "all");
    },
    deploy(record, deployType) {
      this.deploySubmit([record.id], deployType);
    },
    deploySubmit(idList, deployType) {
      api.deployCertificate({ idList, deployType }).then((res) => {
        if (res.result == 200) {
          this.$message.success("部署成功");
          this.deployCertificate(this.certificateId);
        }
      });
    },
    refreshCertificate(val) {
      this.title = "刷新";
      this.addKeyVisible = true;
      this.checkNameMatch = true;
      this.certificateId = val.id;
      this.provider = val.provider;
    },
    deployCertificate(certificateId) {
      this.certificateId = certificateId;
      api.listDeployedCertificate({ certificateId }).then((res) => {
        if (res.result == 200) {
          this.sslList = res.data.sslList;
          this.certificateName = res.data.certificateName;
          this.visible = true;
          this.selectedRowKeys.splice(0);
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        name: this.name,
      };
      this.getCertificatePage(data);
    },
    // 查询证书
    getCertificatePage(data) {
      this.loadingTable = true;
      api
        .getPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 新增
    addClick() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.nameAdd = "";
      this.provider = this.providerList[0];
    },
    handleRemove1(file) {
      const index = this.fileList1.indexOf(file);
      const newFileList = this.fileList1.slice();
      newFileList.splice(index, 1);
      this.fileList1 = newFileList;
    },
    beforeUpload1(file) {
      this.fileList1 = [...this.fileList1, file];
      this.fileList1 = this.fileList1.slice(-1);
      return false;
    },
    // 上传文件数据处理
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      this.fileList = this.fileList.slice(-1);
      return false;
    },
    // 确定新增
    add_submit() {
      let data = {
        fullChainFile: this.fileList[0],
        privateKeyFile: this.fileList1[0],
        provider: this.provider,
      };
      if (this.title == "新增") {
        data.name = this.nameAdd;
        api.addCertificate(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.id = this.certificateId;
        data.checkNameMatch = this.checkNameMatch;
        api.renewCertificate(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("刷新成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.groupNameAdd = "";
      this.groupId = "";
      this.fileList = [];
      this.fileList1 = [];
    },
    // 点击下载
    down(val, name) {
      window.location.href =
        "https://delivery.huiyu.org.cn/api/certificate/downloadCert?id=" +
        val.id +
        "&type=" +
        name;
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteCertificate(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
