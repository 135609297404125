import http from "./axios";

// 查询列表
const queryPage = ({ projectName, moduleName, pageNo, pageSize }) => {
  return http.get("/module/page", {
    projectName,
    moduleName,
    pageNo,
    pageSize,
  });
};

const detail = ({ moduleId, branch }) => {
  return http.get("/module/detail", {
    moduleId,
    branch,
  });
};

const deploy = ({ moduleId, branch }) => {
  return http.get("/module/deploy", {
    moduleId,
    branch,
  });
};

const deployDetail = ({ moduleId, size }) => {
  return http.get("/module/deployDetail", {
    moduleId,
    size,
  });
};

const logPage = ({ moduleId, pageNo, pageSize }) => {
  return http.get("/module/logPage", {
    moduleId,
    pageNo,
    pageSize,
  });
};

export default {
  queryPage,
  deploy,
  logPage,
  detail,
  deployDetail,
};
