<template>
  <div class="schedulingTemplatePage">
    <div class="reminder">调度模板 <span>schedulingTemplate</span></div>
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="升级方式">
        <router-view name="three" v-if="active == '1'" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="节点亲和性">
        <router-view name="three" v-if="active == '2'" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="探针模板">
        <router-view name="three" v-if="active == '3'" />
      </a-tab-pane>
      <a-tab-pane key="4" tab="优先级列表">
        <router-view name="three" v-if="active == '4'" />
      </a-tab-pane>
      <a-tab-pane key="5" tab="镜像列表">
        <router-view name="three" v-if="active == '5'" />
      </a-tab-pane>
      <a-tab-pane key="6" tab="helm列表">
        <router-view
          name="three"
          v-if="active == '6'"
          :namespaceList="namespaceList"
        />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  props: ["namespaceList"],
  name: "schedulingTemplatePage",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/schedulingTemplatePage/kubeSchedulingUpdateMethod") {
          this.active = "1";
        } else if (newVal == "/schedulingTemplatePage/nodeAffinity") {
          this.active = "2";
        } else if (newVal == "/schedulingTemplatePage/probe") {
          this.active = "3";
        } else if (newVal == "/schedulingTemplatePage/priorityList") {
          this.active = "4";
        } else if (newVal == "/schedulingTemplatePage/dockerImage") {
          this.active = "5";
        } else if (newVal == "/schedulingTemplatePage/helmList") {
          this.active = "6";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (
            this.$route.path ==
            "/schedulingTemplatePage/kubeSchedulingUpdateMethod"
          )
            return;
          this.$router.replace(
            "/schedulingTemplatePage/kubeSchedulingUpdateMethod"
          );
        }
        if (newVal === "2") {
          if (this.$route.path == "/schedulingTemplatePage/nodeAffinity")
            return;
          this.$router.replace("/schedulingTemplatePage/nodeAffinity");
        }
        if (newVal === "3") {
          if (this.$route.path == "/schedulingTemplatePage/probe") return;
          this.$router.replace("/schedulingTemplatePage/probe");
        }
        if (newVal === "4") {
          if (this.$route.path == "/schedulingTemplatePage/priorityList")
            return;
          this.$router.replace("/schedulingTemplatePage/priorityList");
        }
        if (newVal === "5") {
          if (this.$route.path == "/schedulingTemplatePage/dockerImage") return;
          this.$router.replace("/schedulingTemplatePage/dockerImage");
        }
        if (newVal === "6") {
          if (this.$route.path == "/schedulingTemplatePage/helmList") return;
          this.$router.replace("/schedulingTemplatePage/helmList");
        }
      },
    },
  },
};
</script>
<style lang="scss">
.detailClass {
  .ant-modal-close-x {
    width: 43px;
    height: 43px;
    line-height: 43px;
  }

  .ant-modal-header {
    padding: 10px;
    font-size: 12px;
  }

  .ant-modal-body {
    padding: 0 !important;

    .detailLayout {
      width: 100%;
      height: 800px;
      position: relative;

      .detailLayoutSlider {
        overflow-y: scroll;
        height: 100%;

        &::-webkit-scrollbar {
          display: none;
        }
        .ant-tree-node-content-wrapper {
          width: calc(100% - 34px);

          .ant-tree-title {
            display: inline-block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .detailLayoutContent {
        position: relative;
        box-sizing: border-box;
        flex: 1;

        #tz {
          width: 2px;
          height: 100%;
          border: 0px solid #ededed;
          position: absolute;
          left: 0px;
          top: 0px;
          background-color: #eee;
          cursor: w-resize;
        }

        .fileContent {
          height: 100% !important;
          background-color: #fff;

          .vue-codemirror {
            height: 100% !important;

            .CodeMirror {
              height: 100% !important;

              .CodeMirror-line {
                font-size: 14px !important;
                line-height: 150%;
                color: #4d4d4c;
                font-family: arial;
              }
              .CodeMirror-placeholder {
                font-size: 13px;
                line-height: 150%;
                color: #8e908c;
                font-family: arial;
              }
            }

            .CodeMirror-lines {
              padding: 0px;
            }
          }
        }
        .detailLayoutContentFooter {
          height: calc(100% - 750px);
          background-color: transparent;
          position: absolute;
          bottom: 0;
          right: 0;
          border-radius: 5px 5px 0 0;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-right: 16px;

          .ant-btn {
            margin-right: 8px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
</style>
