<template>
  <div class="mqTopicDetail">
    <div>
      <span @click="back()" class="back">
        <icon-font type="icon-arrow-left" />
        {{ topic }}
      </span>
    </div>
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="消息">
        <router-view
          name="three"
          v-if="active == '1'"
          :topic="topic"
          :clusterId="clusterId"
        />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "mqTopicDetail",
  props: ["clusterId"],
  data() {
    return {
      active: "1",
      topic: "",
    };
  },
  created() {
    let topic = this.$route.query.topic;
    if (topic) {
      this.topic = topic;
    }
  },
  methods: {
    back() {
      this.$router.back();
    },
  },
};
</script>
<style lang="scss" scoped>
.back {
  display: inline-block;
  cursor: pointer;
  font-size: 20px;
  color: black;
  font-weight: 500;
  padding-bottom: 10px;
}
</style>
