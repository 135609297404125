<template>
  <div>
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="name"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add()" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(columns)"
      :columns="columns"
      :dataSource="dataSource"
      :loading="loading"
      :pagination="pagination"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a href="javascript:;" @click="look(record)">查看</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="history(record)">历史版本</a>
        <a-divider type="vertical" />
        <a-popconfirm title="确认删除？" @confirm="() => deleteFile(record)">
          <a href="javascript:;" style="color: red">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <a-modal
      :title="historyTitle"
      v-model="historyVisible"
      :maskClosable="false"
      width="1000px"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(historyColumns)"
        :columns="historyColumns"
        :dataSource="historyDataSource"
        :loading="historyLoading"
        :pagination="historyPagination"
        size="small"
        :rowKey="(record) => record.id"
      >
        <span
          slot="currentVersion"
          slot-scope="text, record"
          style="width: 100%"
        >
          <a-icon type="check" v-show="text" style="font-size: 16px" />
        </span>
        <span slot="action" slot-scope="text, record" style="width: 100%">
          <a href="javascript:;" @click="look(record)">查看</a>
          <a-divider type="vertical" />
          <a-button
            type="link"
            @click="rollback(record)"
            :disabled="record.currentVersion"
            style="padding: 0"
            >配置回滚</a-button
          >
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="historyVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
    <a-modal
      title="配置查看"
      v-model="visible"
      v-if="visible"
      :maskClosable="false"
      :afterClose="filePreviewClose"
      width="1000px"
    >
      <div class="desc">
        <header class="desc_header">
          <div style="float: left">{{ fileName }}</div>
        </header>
        <pre v-html="previewText" class="desc_content"></pre>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="visible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
    <a-modal
      title="新增"
      v-model="addVisible"
      :maskClosable="false"
      width="700px"
    >
      <a-form>
        <a-form-item
          label="名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="nameAdd" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item
          label="内容:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-textarea placeholder="请输入内容" :rows="5" v-model="content" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addVisible = false">关闭</a-button>
          <a-button type="primary" @click="add_submit">确认</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import api from "../lib/slb.js";

export default {
  name: "globalConfiguration",
  data() {
    return {
      columns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "文件名称",
          ellipsis: true,
          dataIndex: "fileName",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataSource: [],
      pagination: {},
      historyColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "文件名称",
          ellipsis: true,
          dataIndex: "fileName",
        },
        {
          title: "当前版本",
          ellipsis: true,
          dataIndex: "currentVersion",
          align: "center",
          scopedSlots: { customRender: "currentVersion" },
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      historyPagination: {},
      historyDataSource: [],
      pageNo: 1,
      loading: false,
      historyLoading: false,
      visible: false,
      previewText: "",
      name: "",
      fileList: [],
      historyVisible: false,
      historyTitle: "",
      fileName: "",
      addVisible: false,
      content: "",
      nameAdd: "",
    };
  },
  created() {
    this.query(1);
  },
  methods: {
    deleteFile(record) {
      api.deleteGlobalConfTemplate({ id: record.id }).then((res) => {
        if (res.result == 200) {
          this.$message.success("删除成功");
          this.query(1);
        }
      });
    },
    add_submit() {
      api
        .addGlobalConfTemplate({
          fileName: this.nameAdd,
          content: this.content,
          nginxInstanceId: -1,
        })
        .then((res) => {
          if (res.result == 200) {
            this.$message.success("添加成功");
            this.addVisible = false;
            this.query(1);
          }
        });
    },
    add() {
      this.content = "";
      this.nameAdd = "";
      this.addVisible = true;
    },
    rollback(record) {
      api.rollbackHistoryConf({ id: record.id }).then((res) => {
        if (res.result == 200) {
          this.$message.success("回滚成功");
          this.history(record);
        }
      });
    },
    history(record) {
      this.query(1, record.fileName);
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    query(index, name) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        nginxInstanceId: -1,
        pageNo: this.pageNo,
        pageSize: 50,
        globalConf: true,
      };
      if (name) {
        data.fileName = name;
        this.historyLoading = true;
        this.historyDataSource.splice(0);
      } else {
        data.currentVersion = true;
        data.fileName = this.name;
        this.loading = true;
        this.dataSource.splice(0);
      }
      api.confFileList(data).then((res) => {
        if (res.result == 200) {
          if (name) {
            this.historyLoading = false;
            this.historyDataSource.push(...res.data.records);
            this.historyPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current, name),
            };
            this.historyTitle = name + " - 历史版本";
            if (!this.historyVisible) {
              this.historyVisible = true;
            }
          } else {
            this.loading = false;
            this.dataSource.push(...res.data.records);
            this.pagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        }
      });
    },
    changePageItem(index, name) {
      if (name) {
        this.query(index, name);
      } else {
        this.query(index);
      }
    },
    look(record) {
      let data = {
        confId: record.id,
      };
      api.confFileContent(data).then((res) => {
        if (res.result == 200) {
          this.previewText = res.data.content;
          this.fileName = res.data.fileName;
          this.visible = true;
        }
      });
    },
    filePreviewClose() {
      this.previewText = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.desc {
  background-color: #f7f7f7;
  border-radius: 5px;
  .desc_header {
    padding: 10px 20px;
    border-bottom: 1px solid #e5e5e5;
    overflow: auto;
  }
  .desc_content {
    padding: 10px 20px;
  }
}
</style>
