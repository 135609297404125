import http from "./axios";

// 查询列表
const queryPage = ({ pageNo, pageSize }) => {
  return http.get("/module/uploadLogPage", {
    pageNo,
    pageSize,
  });
};

// 查询列表
const uploadToRepository = ({
  file,
  groupId,
  artifactId,
  version,
  packaging,
}) => {
  return http.files("/module/uploadToRepository", {
    file,
    groupId,
    artifactId,
    version,
    packaging,
  });
};
export default {
  queryPage,
  uploadToRepository,
};
