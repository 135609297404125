<template>
  <div
    class="terminal"
    @click="terminalClick"
    @contextmenu="terminalContextmenu"
  >
    <a-layout style="height: 100%">
      <a-layout-sider
        class="siderLeft"
        style="background: #f0f2f5"
        v-if="!fullscreen"
      >
        <!-- 搜索服务器 -->
        <div class="sInput">
          <a-input-search placeholder="试试搜索" @change="onChange" />
        </div>
        <!-- 服务器分组 -->
        <a-directory-tree
          :expanded-keys="expandedKeys"
          :auto-expand-parent="autoExpandParent"
          @rightClick="onRightClick"
          @select="onSelect"
          @expand="onExpand"
          class="tree_scroll"
        >
          <template v-for="item in catalogueList">
            <a-tree-node :key="item.key">
              <template slot="title" slot-scope="{ title }">
                <span v-if="item.title.indexOf(searchValue) > -1">
                  {{ item.title.substr(0, item.title.indexOf(searchValue)) }}
                  <span style="color: #f50">{{ searchValue }}</span>
                  {{
                    item.title.substr(
                      item.title.indexOf(searchValue) + searchValue.length
                    )
                  }}
                </span>
                <span v-else>{{ item.title }}</span>
              </template>
              <template
                v-if="item.isChildren"
                v-for="menu in item.userServerAccountRelationList"
              >
                <a-tree-node :key="menu.key" is-leaf>
                  <template slot="title" slot-scope="{ title }">
                    <span v-if="menu.title.indexOf(searchValue) > -1">
                      {{
                        menu.title.substr(0, menu.title.indexOf(searchValue))
                      }}
                      <span style="color: #f50">{{ searchValue }}</span>
                      {{
                        menu.title.substr(
                          menu.title.indexOf(searchValue) + searchValue.length
                        )
                      }}
                    </span>
                    <span v-else>{{ menu.title }}</span>
                  </template>
                </a-tree-node>
              </template>
            </a-tree-node>
          </template>
        </a-directory-tree>
        <!-- 添加分组按钮 -->
        <!-- <a-button ghost icon="plus" block @click="addService">添加</a-button> -->
      </a-layout-sider>
      <a-layout-content class="content_cc">
        <!-- 命令窗口tab -->
        <div class="xterm_div">
          <ul class="xterm_ul">
            <li
              v-for="pane in panes"
              :key="pane.key"
              class="xterm_li"
              :class="{ xterm_li_active: activeKey === pane.key }"
              @click="activeClick(pane.key)"
            >
              <span>{{ pane.title }}</span>
              <a-icon
                @click="remove(pane.key)"
                type="close"
                v-if="pane.closable"
              />
            </li>
          </ul>
        </div>
        <div @click="toggle1" class="full">
          <a-icon
            class="aIcon"
            :type="!fullscreen ? 'fullscreen' : 'fullscreen-exit'"
          />
        </div>
        <!-- 设置按钮 -->
        <a-dropdown class="dropdown_cc">
          <a-button
            icon="unordered-list"
            style="
              background-color: #2e2a2a;
              border-color: #1e1b1b;
              color: #fff;
            "
          ></a-button>
          <a-menu slot="overlay">
            <a-menu-item>
              <a href="javascript:;" @click="commandsClick">常用命令</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <!-- 命令窗口 -->
        <div v-if="show">
          <div v-for="item in panes" :key="item.key">
            <div v-if="item.type === 'term'">
              <x-term
                :xTermData="item"
                :activeKey="activeKey"
                :term="term"
                :loginNickName="loginNickName"
                v-if="activeKey === item.key"
                :fullscreen="fullscreen"
                @child-fullscreen="childFullscreen"
              ></x-term>
            </div>
            <div v-if="item.type === 'interface'">
              <interface-view
                loadUrl="/delivery/fileManager/listFile"
                :serverAccountId="serverAccountId"
                :diffHeight="40"
                @contextmenuFileView="contextmenuFileView"
                @fileDivClick="fileDivClick"
                @contextmenuFile="contextmenuFile"
                v-if="activeKey === item.key"
                @getPath="getPath"
                ref="interface"
              ></interface-view>
            </div>
          </div>
        </div>
      </a-layout-content>
    </a-layout>
    <!-- 新增分组or修改分组名称or修改服务器名称 -->
    <a-modal
      :title="addTitle"
      v-model="addGroupVisible"
      :maskClosable="false"
      :afterClose="addGroupClose"
    >
      <a-form>
        <a-form-item
          label="分组名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
          v-if="this.addTitle === '新增分组'"
        >
          <a-input placeholder="请输入分组名称" v-model="addName" />
        </a-form-item>
        <a-form-item
          label="分组名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
          v-if="this.addTitle === '修改分组名称'"
        >
          <a-input placeholder="请输入分组名称" v-model="addName" />
        </a-form-item>
        <a-form-item
          label="服务器名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
          v-if="this.addTitle === '修改服务器名称'"
        >
          <a-input placeholder="请输入服务器名称" v-model="addName" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addGroupVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addGroup_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 修改分组 -->
    <a-modal
      title="修改分组"
      v-model="modifyGroupVisible"
      :maskClosable="false"
      :afterClose="modifyGroupClose"
    >
      <a-form>
        <a-form-item
          label="选择分组:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-select
            v-model="groupId"
            placeholder="请选择分组"
            showSearch
            option-filter-prop="children"
            style="width: 300px"
            allowClear
          >
            <a-select-option v-for="item in groupIdList" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="modifyGroupVisible = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="modifyGroup_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 常用命令 -->
    <a-modal
      title="常用命令"
      v-model="commandsShow"
      :maskClosable="false"
      :width="600"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(commandsColumns)"
        :columns="commandsColumns"
        :dataSource="commandsList"
        size="small"
        :pagination="commandsPagination"
      >
        <span slot="action" slot-scope="text, record" style="width: 100%">
          <a href="javascript:;" @click="commandsSee(record)">查看</a>
          <a-divider type="vertical" />
          <a href="javascript:;" @click="commandsModify(record)">修改</a>
          <a-divider type="vertical" />
          <a href="javascript:;" v-copy="record.content">复制</a>
          <a-divider type="vertical" />
          <a
            href="javascript:;"
            @click="commandsDelete(record)"
            style="color: #f56c6c"
            >删除</a
          >
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="commandsShow = false">取消</a-button>
          <a-button type="primary" @click="commandsAdd">新增常用命令</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 新增常用命令or修改常用命令 -->
    <a-modal
      :title="addCommandsTitle"
      v-model="addCommandsShow"
      :maskClosable="false"
      :afterClose="addCommandsClose"
    >
      <a-form>
        <a-form-item
          label="名称"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input placeholder="请输入名称" v-model="addCommands.name" />
        </a-form-item>
        <a-form-item
          label="命令"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-textarea
            placeholder="请输入内容"
            v-model="addCommands.content"
            :rows="6"
          />
        </a-form-item>
        <a-form-item
          label="备注"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-textarea
            placeholder="请输入备注"
            v-model="addCommands.remark"
            :rows="3"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="addCommandsShow = false">取消</a-button>
          <a-button
            type="primary"
            :loading="loading"
            @click="addCommands_Submit"
            >新增</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 查看命令 -->
    <a-modal
      title="查看命令"
      v-model="seeCommandsShow"
      :maskClosable="false"
      :afterClose="seeCommandsClose"
      :footer="null"
    >
      <a-form>
        <a-form-item
          label="命令"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-textarea v-model="seeCommands" :rows="6" readOnly />
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 新建文件夹 -->
    <a-modal
      title="新建文件夹"
      v-model="addFolderShow"
      :maskClosable="false"
      :afterClose="addFolderClose"
    >
      <a-form>
        <a-form-item
          label="文件夹名称"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input placeholder="请输入文件夹名称" v-model="folderName" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="addFolderShow = false">取消</a-button>
          <a-button
            type="primary"
            :loading="loading"
            @click="addFolderClose_Submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 上传文件 -->
    <a-modal
      title="上传文件"
      v-model="uploadVisible"
      :maskClosable="false"
      :afterClose="uploadClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          label="文件:"
        >
          <div class="dropbox">
            <a-upload-dragger
              :fileList="fileList"
              :remove="handleRemove"
              :beforeUpload="beforeUpload"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
              <p class="ant-upload-hint">只支持单个上传</p>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="uploadVisible = false">取消</a-button>
          <a-button key="submit" type="primary" @click="addFile_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 目录右击菜单 -->
    <m-right-menu
      v-if="rightMenu.showCatalogue"
      :menuData="rightMenu"
      style="background-color: #2e2a2a; color: #fff"
    >
      <m-right-menu-item
        :disabled="rightMenu.starter"
        @click="modifyName(rightMenu)"
        >修改名称</m-right-menu-item
      >
      <m-right-menu-item
        v-if="rightMenu.serverAccountId ? true : false"
        @click="modifyGroup(rightMenu)"
        >修改分组</m-right-menu-item
      >
      <m-right-menu-item
        v-if="rightMenu.serverAccountId ? true : false"
        @click="interfaceViewOpen(rightMenu)"
        >文件管理</m-right-menu-item
      >
      <m-right-menu-item
        v-if="rightMenu.serverAccountId ? false : true"
        @click="addService()"
        >添加分组</m-right-menu-item
      >
      <m-right-menu-item
        v-if="rightMenu.serverAccountId ? false : true"
        :disabled="rightMenu.starter"
        @click="deleteClick(rightMenu)"
        >删除</m-right-menu-item
      >
    </m-right-menu>
    <!-- 文件右击菜单 -->
    <m-right-menu v-if="rightMenu.show" :menuData="rightMenu">
      <m-right-menu-item @click="rename">重命名</m-right-menu-item>
      <m-right-menu-item @click="downloadFile">下载</m-right-menu-item>
      <m-right-menu-item>复制</m-right-menu-item>
      <m-right-menu-item>详细信息</m-right-menu-item>
    </m-right-menu>
    <!-- 文件管理页面右击菜单 -->
    <m-right-menu v-if="rightMenu.showView" :menuData="rightMenu">
      <m-right-menu-item @click="uploadClick">上传</m-right-menu-item>
      <m-right-menu-item @click="newFolder">新建文件夹</m-right-menu-item>
    </m-right-menu>
    <!-- 快速查找服务器 -->
    <a-modal
      title="查找服务器"
      v-model="findVisible"
      :maskClosable="false"
      :afterClose="findClose"
      width="500px"
      keyboard
    >
      <div class="selectStyle">
        服务器：
        <a-select
          v-if="combinationKey"
          style="width: 300px"
          v-model="modelServerId"
          placeholder="请选择服务器"
          allowClear
          showSearch
          option-filter-prop="children"
          @change="modelServerChange"
          @keyup.enter.native="find_submit"
          id="check1"
          :getPopupContainer="(trigger) => trigger.parentNode || document.body"
        >
          <a-select-option
            v-for="i in modelServerList"
            :key="i.serverAccountId"
          >
            {{ i.alias }}
          </a-select-option>
        </a-select>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="findVisible = false">取消</a-button>
          <a-button key="submit" type="primary" @click="find_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import xTerm from "../pages/xTerm";
import interfaceView from "../pages/interface/interfaceView";
import * as api from "../lib/terminal";
import screenfull from "screenfull";

export default {
  name: "terminal",
  components: {
    xTerm,
    interfaceView,
  },
  data() {
    return {
      findVisible: false,
      fullscreen: false,
      modelServerList: [],
      modelServerId: undefined,
      modelValue: "",
      filePath: "/",
      addFolderShow: false,
      folderName: "",
      uploadVisible: false,
      fileList: [],
      path: location.protocol + process.env.VUE_APP_BASE_API,
      commandId: "",
      addCommandsTitle: "",
      seeCommandsShow: false,
      seeCommands: "",
      addCommandsShow: false,
      addCommands: {
        name: "",
        content: "",
        remark: "",
      },
      commandsShow: false,
      commandsColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "key",
          width: 50,
        },
        {
          title: "命令名称",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      commandsList: [],
      commandsPagination: {},
      groupId: undefined,
      groupIdList: [],
      modifyGroupVisible: false,
      addTitle: "",
      loading: false,
      addGroupVisible: false,
      addName: "",
      activeKey: "1",
      panes: [],
      newTabIndex: 0,
      show: false,
      term: {},
      catalogueList: [],
      selectKey: "",
      intervalTime: 0,
      dataList: [],
      expandedKeys: [],
      searchValue: "",
      autoExpandParent: true,
      rightMenu: {},
      fileData: {},
      userServerGroupId: "",
      serverAccountId: "",
      userServerAccountId: "",
      serverId: "",
      lastNum: "0",
      combinationKey: false,
      loginNickName: "",
    };
  },
  created() {
    this.getUserInfo();
  },
  mounted() {
    this.getUserServerRelationList();
    this.panes.forEach((item) => {
      if (item.type === "term") {
        this.term[item.key] = null;
      }
    });
    this.show = true;
    // 监听事件
    window.addEventListener("resize", this.onresize);
    window.addEventListener("keydown", this.keydown);
    // 页面传参
    this.serverId = this.$route.query.id;
    if (this.serverId !== undefined) {
      this.fullscreen = true;
      this.add(this.$route.query.title, this.$route.query.id, "term");
    }
    this.serverAccountId = this.$route.query.id;
  },
  beforeDestroy() {
    // 取消监听事件
    window.removeEventListener("resize", this.onresize);
    window.removeEventListener("keydown", this.keydown);
  },
  methods: {
    getUserInfo() {
      this.$http.get("/alpha/user/info").then((res) => {
        if (res.result == 200) {
          this.loginNickName = res.userName;
        }
      });
    },
    find_submit() {
      this.add(this.modelValue, this.modelServerId, "term");
      this.findVisible = false;
    },
    findClose() {
      this.modelServerId = undefined;
      this.modelValue = "";
      this.combinationKey = false;
    },
    modelServerChange(val) {
      let arr = this.modelServerList.filter((el) => el.serverAccountId == val);
      this.modelValue = arr[0].alias;
      console.log(val, this.modelValue);
    },
    childFullscreen(data) {
      console.log("childFullscreen", data);
    },
    toggle1() {
      this.fullscreen = !this.fullscreen;
      console.log(123);
    },
    toggle() {
      // 判断是否支持
      if (!screenfull.isEnabled) {
        this.$message.warning("不支持全屏");
        return false;
      }
      screenfull.toggle();
      // this.fullscreen = !this.fullscreen;
    },

    // 阻止F11默认事件
    keydown(event) {
      if (event.keyCode === 122) {
        event.preventDefault();
        event.returnValue = false;
      }
      if (this.fullscreen) {
        if (event.keyCode === 27) {
          this.fullscreen = false;
        }
      }
      if (event.keyCode == 13 && event.metaKey) {
        //回车
        this.fullscreen = !this.fullscreen;
        console.log("fuyemian");
      }
      if (event.keyCode == 79 && event.metaKey) {
        this.combinationKey = true;
        event.preventDefault();
        event.returnValue = false;
        console.log("哦", this.lastNum);
        this.findVisible = true;
        this.$nextTick(() => {
          //   // this.$refs.select.focus();
          //   // this.$refs.check1.focus();
          //   document.getElementById("check1").click();
          let check = document.getElementById("check1");
          document.getElementById("check1").click();
        });

        // document.body.pressKey(40);
      }
      if (event.keyCode == 37 && event.metaKey) {
        console.log("左", this.activeKey);
        event.preventDefault();
        event.returnValue = false;
        if (this.activeKey > 1) {
          this.activeClick(this.activeKey - 1 + "");
        }
      }
      if (event.keyCode == 39 && event.metaKey) {
        console.log("右", this.activeKey);
        event.preventDefault();
        event.returnValue = false;
        if (this.activeKey < this.lastNum) {
          this.activeClick(Number(this.activeKey) + 1 + "");
        }
      }
      // 关闭窗口快捷键
    },
    /**
     * 响应屏幕变化事件，给isFullscreen赋值，标识是否全屏
     * IE中bug: 调试器打开，且与页面不分离时 -- 无效
     * **/
    onresize(event) {
      // 利用屏幕分辨率和window对象的内高度来判断兼容IE
      let winFlag = window.innerHeight === window.screen.height;
      // 利用window全屏标识来判断 -- IE无效
      let isFull = window.fullScreen || document.webkitIsFullScreen;

      if (isFull === undefined) {
        this.fullscreen = winFlag;
      } else {
        this.fullscreen = winFlag || isFull;
      }
    },
    // 点击空白处获得路径
    getPath(val) {
      console.log(val);
      this.filePath = val;
    },
    // 点击上传文件
    uploadClick() {
      this.uploadVisible = true;
    },
    // 确定上传
    addFile_submit() {
      let data = {
        file: this.fileList[0],
        uploadPath: this.filePath,
        serverAccountId: this.serverAccountId,
      };
      api.upload(data).then((res) => {
        if (res.result === 200) {
          this.uploadVisible = false;
          this.$message.success("上传成功");
          this.$nextTick(() => {
            this.$refs.interface.showFileList(this.filePath);
          });
        }
      });
    },
    uploadClose() {
      this.fileList = [];
    },
    // 上传文件数据处理
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      this.fileList = this.fileList.slice(-1);
      return false;
    },
    // 点击新建文件夹
    newFolder() {
      this.addFolderShow = true;
      console.log(
        "新建文件夹",
        "serverAccountId-",
        this.serverAccountId,
        "path-",
        this.filePath
      );
    },
    // 新建文件夹关闭
    addFolderClose() {
      this.folderName = "";
    },
    // 确定新建文件夹
    addFolderClose_Submit() {
      let data = {
        serverAccountId: this.serverAccountId,
        path: this.filePath,
        folderName: this.folderName,
      };
      api.newFolder(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("新建成功");
          this.addFolderShow = false;
          this.$nextTick(() => {
            this.$refs.interface.showFileList(this.filePath);
          });
        }
      });
    },
    // 点击下载文件
    downloadFile(e) {
      let data = {
        serverAccountId: this.serverAccountId,
        path: this.fileData.id,
        type: this.fileData.hasChildrenFile ? "DIR" : "folder",
      };
      let url = "";
      for (let i in data) {
        if (data[i]) {
          url += i + "=" + data[i] + "&";
        }
      }
      let href = this.path + "/delivery/fileManager/downloadFile?" + url;
      window.location.href = href;
    },
    // 点击重命名
    rename() {
      console.log(this.fileData.id);
    },
    // 右击文件
    contextmenuFile(e, item) {
      // 判断是文件夹 return
      if (item.hasChildrenFile) {
        return;
      }
      this.fileData = this.$common.deepClone(item);
      this.rightMenu.showView = false;
      this.rightMenu.showCatalogue = false;
      if (this.rightMenu.show) {
        this.rightMenu.show = false;
        return;
      }
      this.$nextTick(() => {
        this.rightMenu.show = true;
        this.rightMenu.left = e.clientX + "px";
        this.rightMenu.top = e.clientY + "px";
      });
    },
    // 左击文件管理页面
    fileDivClick(e) {},
    // 右击文件管理页面
    contextmenuFileView(e) {
      this.rightMenu.show = false;
      this.rightMenu.showCatalogue = false;
      if (this.rightMenu.showView) {
        this.rightMenu.showView = false;
        return;
      }
      this.$nextTick(() => {
        this.rightMenu.showView = true;
        this.rightMenu.left = e.clientX + "px";
        this.rightMenu.top = e.clientY + "px";
      });
    },
    // 点击常用命令
    commandsClick() {
      this.commandsShow = true;
      this.inquireCommands(1);
    },
    // 点击查看常用命令
    commandsSee(val) {
      this.seeCommandsShow = true;
      this.seeCommands = val.content;
    },
    // 点击修改常用命令
    commandsModify(val) {
      this.addCommandsTitle = "修改命令";
      this.commandId = val.id;
      this.addCommandsShow = true;
      this.addCommands = {
        name: val.name,
        content: val.content,
        remark: val.remark,
      };
    },
    // 点击删除常用命令
    commandsDelete(val) {
      let data = {
        id: val.id,
      };
      this.$confirm({
        title: "确认删除?",
        content: `此操作将删除【${val.name}】的命令?, 是否继续？`,
        onOk: () => {
          api.memoDeleteMemo(data).then((res) => {
            if (res.result == 200) {
              this.$message.success("删除成功");
              this.inquireCommands(1);
            }
          });
        },
        onCancel() {},
      });
    },
    // 点击新增常用命令
    commandsAdd() {
      this.addCommandsTitle = "新增命令";
      this.addCommandsShow = true;
    },
    //查询常用命令
    inquireCommands(index) {
      let data = {
        pageNo: index || 1,
        pageSize: 10,
        type: "SHELL",
      };
      api.memoList(data).then((res) => {
        if (res.result == 200) {
          let list = res.data.records;
          list.forEach((item, i) => {
            item.key = i + 1;
          });
          this.commandsList = list;
          this.commandsPagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.commandsChangePageItem(current),
          };
        }
      });
    },
    // 常用命令分页查询
    commandsChangePageItem(index) {
      this.inquireCommands(index);
    },
    // 关闭新增常用命令
    addCommandsClose() {
      this.addCommands = {
        name: "",
        content: "",
        remark: "",
      };
    },
    // 确认新增常用命令
    addCommands_Submit() {
      let data = {
        name: this.addCommands.name,
        content: this.addCommands.content,
        remark: this.addCommands.remark,
      };
      if (!data.name) {
        this.$message.warning("请输入名称");
        return;
      }
      if (!data.content) {
        this.$message.warning("请输入内容");
        return;
      }
      this.loading = true;
      if (this.addCommandsTitle === "新增命令") {
        data.type = "SHELL";
        api
          .memoAddMemo(data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.$message.success("新增成功");
              this.addCommandsShow = false;
              this.inquireCommands(1);
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
      if (this.addCommandsTitle === "修改命令") {
        data.id = this.commandId;
        api
          .memoUpdateMemo(data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.$message.success("修改成功");
              this.addCommandsShow = false;
              this.inquireCommands(1);
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 关闭查看命令
    seeCommandsClose() {
      this.seeCommands = "";
    },
    // 查询分组
    getGroupList() {
      api.groupList().then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.groupIdList = list;
        }
      });
    },
    // 搜索分组

    // 左击页面
    terminalClick() {
      this.rightMenu = {
        show: false,
        showView: false,
        showCatalogue: false,
        left: 0,
        top: 0,
        starter: false,
        title: "",
        serverAccountId: null,
        userServerGroupId: null,
        userServerAccountId: null,
      };
    },
    // 右击页面
    terminalContextmenu() {
      this.rightMenu = {
        show: false,
        showView: false,
        showCatalogue: false,
        left: 0,
        top: 0,
        starter: false,
        title: "",
        serverAccountId: null,
        userServerGroupId: null,
        userServerAccountId: null,
      };
    },
    // 右击修改名称
    modifyName(val) {
      if (!val.serverAccountId) {
        this.addTitle = "修改分组名称";
      } else {
        this.addTitle = "修改服务器名称";
      }
      this.addName = val.title;
      this.userServerGroupId = val.userServerGroupId;
      this.serverAccountId = val.serverAccountId;
      this.userServerAccountId = val.userServerAccountId;
      this.addGroupVisible = true;
    },
    // 右击修改分组
    modifyGroup(val) {
      this.userServerAccountId = val.userServerAccountId;
      this.modifyGroupVisible = true;
      this.getGroupList();
    },
    // 打开图形界面
    interfaceViewOpen(val) {
      this.serverAccountId = val.serverAccountId;
      this.add(val.title, val.serverAccountId, "interface");
    },
    // 确定修改分组
    modifyGroup_submit() {
      let data = {
        userServerAccountId: this.userServerAccountId,
        userServerAccountGroupId: this.groupId,
      };
      if (!data.userServerAccountGroupId) {
        this.$message.warning("请选择分组");
        return;
      }
      this.loading = true;
      api
        .reGroup(data)
        .then((res) => {
          this.loading = false;
          if (res.result === 200) {
            this.modifyGroupVisible = false;
            this.getUserServerRelationList();
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 关闭修改分组
    modifyGroupClose() {
      this.groupId = undefined;
      this.userServerAccountId = "";
    },
    // 右击删除
    deleteClick(val) {
      this.$confirm({
        title: "提示",
        content: (h) => (
          <div style="color:red;">
            此操作将删除【{val.title}】的分组?, 是否继续？
          </div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          api
            .deleteUserServerGroup({ id: val.userServerGroupId })
            .then((res) => {
              if (res.result == 200) {
                this.$message.success("删除成功！");
                this.getUserServerRelationList();
              }
            });
        },
        onCancel: () => {
          // console.log('Cancel');
        },
        class: "test",
      });
    },
    // 点击添加
    addService() {
      this.addTitle = "新增分组";
      this.addGroupVisible = true;
    },
    // 确定添加
    addGroup_submit() {
      let data = {};
      if (this.addTitle === "新增分组") {
        data.name = this.addName;
        if (!data.name) {
          this.$message.warning("请输入分组名称");
          return;
        }
        this.loading = true;
        api
          .addUserServerGroup(data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.addGroupVisible = false;
              this.$message.success("添加分组成功");
              this.getUserServerRelationList();
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
      if (this.addTitle === "修改分组名称") {
        data.name = this.addName;
        data.userServerGroupId = this.userServerGroupId;
        if (!data.name) {
          this.$message.warning("请输入分组名称");
          return;
        }
        this.loading = true;
        api
          .groupRename(data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.addGroupVisible = false;
              this.$message.success("修改分组名称成功");
              this.getUserServerRelationList();
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
      if (this.addTitle === "修改服务器名称") {
        data.alias = this.addName;
        data.userServerAccountId = this.userServerAccountId;
        if (!data.alias) {
          this.$message.warning("请输入服务器名称");
          return;
        }
        this.loading = true;
        api
          .rename(data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.addGroupVisible = false;
              this.$message.success("修改服务器名称成功");
              this.getUserServerRelationList();
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 关闭添加
    addGroupClose() {
      this.addName = "";
      this.userServerGroupId = "";
      this.serverAccountId = "";
      this.userServerAccountId = "";
    },
    // 展开/收起节点时触发
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    // 搜索目录
    onChange(e) {
      const value = e.target.value;
      const expandedKeys = this.dataList
        .map((item) => {
          if (item.title.indexOf(value) > -1) {
            return this.getParentKey(item.key, this.catalogueList);
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);
      Object.assign(this, {
        expandedKeys,
        searchValue: value,
        autoExpandParent: true,
      });
    },
    // 获取parentKey
    getParentKey(key, tree) {
      let parentKey;
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          if (node.children.some((item) => item.key === key)) {
            parentKey = node.key;
          } else if (this.getParentKey(key, node.children)) {
            parentKey = this.getParentKey(key, node.children);
          }
        }
      }
      return parentKey;
    },
    // 获取tree key
    generateList(data) {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const key = node.key;
        this.dataList.push({ key, title: node.title });
        if (node.children) {
          this.generateList(node.children);
        }
      }
    },
    // 点击目录
    onSelect(keys, event) {
      let next = false;
      let serverAccountId = "";
      let title = "";
      for (let i in this.catalogueList) {
        let item = this.catalogueList[i];
        if (item.key === keys[0] && !item.isChildren) {
          next = true;
          serverAccountId = item.serverAccountId;
          title = item.title;
          break;
        }
        if (item.isChildren) {
          for (let j in item.userServerAccountRelationList) {
            let menu = item.userServerAccountRelationList[j];
            if (menu.key === keys[0]) {
              next = true;
              serverAccountId = menu.serverAccountId;
              title = menu.title;
              break;
            }
          }
        }
      }
      if (!next) return;
      if (this.selectKey !== keys[0]) {
        this.intervalTime = 0;
      }
      let newTime = new Date().getTime();
      let diff = newTime - this.intervalTime;
      this.intervalTime = newTime;
      this.selectKey = keys[0];
      if (diff > 800) {
        return;
      }
      // console.log(title, serverAccountId, "term");
      this.add(title, serverAccountId, "term");
    },
    // 目录--右键点击
    onRightClick(e) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.rightMenu.show = false;
      this.rightMenu.showView = false;
      if (this.rightMenu.showCatalogue) {
        this.rightMenu.showCatalogue = false;
        return;
      }
      let key = e.node._props.eventKey.trim();
      let serverAccountId = "";
      let userServerAccountId = "";
      let id = "";
      let title = "";
      for (let i in this.catalogueList) {
        let item = this.catalogueList[i];
        if (item.key === key) {
          id = item.id;
          title = item.title;
          break;
        }
        if (item.isChildren) {
          for (let j in item.userServerAccountRelationList) {
            let menu = item.userServerAccountRelationList[j];
            if (menu.key === key) {
              serverAccountId = menu.serverAccountId;
              userServerAccountId = menu.id;
              title = menu.title;
              break;
            }
          }
        }
      }
      this.$nextTick(() => {
        this.rightMenu.showCatalogue = true;
        this.rightMenu.left = e.event.clientX + "px";
        this.rightMenu.top = e.event.clientY + "px";
        this.rightMenu.starter = id === 0 ? true : false;
        this.rightMenu.title = title;
        this.rightMenu.serverAccountId = serverAccountId;
        this.rightMenu.userServerAccountId = userServerAccountId;
        this.rightMenu.userServerGroupId = id;
      });
    },
    // 获取目录列表
    getUserServerRelationList() {
      api
        .userServerRelationList()
        .then((res) => {
          if (res.result === 200) {
            let list = res.data;
            list.forEach((item) => {
              item.isChildren = false;
              item.key = item.id.toString();
              item.title = item.name;
              item.children = [];
              item.scopedSlots = { title: "title" };
              if (
                item.userServerAccountRelationList &&
                item.userServerAccountRelationList.length > 0
              ) {
                item.isChildren = true;
                item.children = item.userServerAccountRelationList;
                item.children.forEach((menu) => {
                  menu.key = item.id + "-" + menu.id;
                  menu.title = menu.alias;
                  menu.scopedSlots = { title: "title" };
                });
              }
            });
            this.catalogueList = list;
            for (var i = 0; i < list.length; i++) {
              this.modelServerList = [
                ...this.modelServerList,
                ...list[i].userServerAccountRelationList,
              ];
            }
            this.dataList = [];
            this.generateList(list);
          }
        })
        .catch((err) => {});
    },
    // 点击tab
    activeClick(key) {
      this.activeKey = key;
      if (this.term[key]) {
        setTimeout(() => {
          this.term[key].focus();
        }, 200);
      }
    },
    // 添加
    add(title, serverAccountId, type) {
      this.lastNum++;
      this.intervalTime = 0;
      const panes = this.panes;
      const activeKey = `${++this.newTabIndex}`;
      panes.push({
        title: `${title}-窗口-${activeKey}`,
        key: activeKey,
        closable: true,
        serverAccountId: serverAccountId,
        type: type,
      });
      this.panes = panes;
      this.activeKey = activeKey;
      if (type === "trem") {
        this.term[activeKey] = null;
      }
      if (type === "interface") {
      }
    },
    // 关闭tab
    remove(targetKey) {
      this.lastNum--;
      let activeKey = this.activeKey;
      let lastIndex;
      this.panes.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1;
        }
      });
      const panes = this.panes.filter((pane) => pane.key !== targetKey);
      if (panes.length && activeKey === targetKey) {
        if (lastIndex >= 0) {
          activeKey = panes[lastIndex].key;
        } else {
          activeKey = panes[0].key;
        }
      }
      this.panes = panes;
      if (this.term[targetKey]) {
        this.term[targetKey].dispose();
        this.term[targetKey] = null;
      }
      this.$nextTick(() => {
        setTimeout(() => {
          this.activeKey = activeKey;
        }, 200);
      });
    },
  },
};
</script>
<style lang="scss">
.sInput {
  .ant-input {
    background-color: #2e2a2a !important;
    color: #fff;
    border: 0;
    border-bottom: 1px solid #50a78e;
    height: 40px;
  }
}
.siderLeft {
  background: #2e2a2a !important;
  .ant-tree li {
    color: #fff !important;
  }
  .ant-tree li .ant-tree-node-content-wrapper {
    color: #fff !important;
  }

  .ant-tree.ant-tree-directory .ant-tree-child-tree {
    li span.ant-tree-node-content-wrapper:hover::before {
      background-color: #474545 !important ;
      // transition: all 100s !important;
    }
  }
  .ant-tree.ant-tree-directory {
    li span.ant-tree-node-content-wrapper:hover::before {
      background-color: #474545 !important ;
    }
  }
}
.selectStyle {
  .ant-select-dropdown {
    position: absolute;
    left: 83px !important;
    top: 115px !important;
  }
}
</style>
<style lang="scss" scoped>
.terminal {
  height: 100%;

  .content_cc {
    background: #1e1b1b;
    position: relative;
    .dropdown_cc {
      position: absolute;
      right: 45px;
      top: 4px;
    }
    .full {
      position: absolute;
      right: 8px;
      top: 0;
      font-size: 30px;
      color: #fff;
      display: inline-block;
    }
  }
  .tree_scroll {
    overflow-y: auto;
    height: calc(100% - 72px);
  }
}
.xterm_div {
  width: 100%;
  background: #2e2a2a;
  .xterm_ul {
    width: calc(100% - 80px);
    height: 40px;
    margin: 0;
    overflow: auto;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
    .xterm_li {
      color: #fff;
      display: inline-block;
      height: 40px;
      margin: 0 2px 0 0;
      padding: 0 16px;
      line-height: 38px;
      background: #2e2a2a;
      // border: 1px solid #e8e8e8;
      border-radius: 4px 4px 0 0;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .xterm_li_active {
      color: #fff;
      // background: #fff;
      border-color: #e8e8e8;
      border-bottom: 5px solid #50a78e;
    }
  }
}
</style>
