import http from "./axios";

export const dockerImagePage = (data) => {
  return http.get("/dockerImage/dockerImagePage", data);
};

export const addDockerImage = (data) => {
  return http.json_post("/dockerImage/addDockerImage", data);
};

export const editDockerImage = (data) => {
  return http.json_post("/dockerImage/editDockerImage", data);
};

export const copy = (data) => {
  return http.json_post("/dockerImage/copy", data);
};

export const deleteDockerImage = (data) => {
  return http.json_post("/dockerImage/deleteDockerImage", data);
};

export const queryDir = (data) => {
  return http.get("/dockerImage/queryDir", data);
};

export const mkdir = (data) => {
  return http.json_post("/dockerImage/mkdir", data);
};

export const rmFile = (data) => {
  return http.json_post("/dockerImage/rmFile", data);
};

export const uploadFile = (data) => {
  return http.files("/dockerImage/uploadFile", data);
};

export const viewContent = (data) => {
  return http.get("/dockerImage/viewContent", data);
};

export const editContent = (data) => {
  return http.json_post("/dockerImage/editContent", data);
};

export const build = (data) => {
  return http.json_post("/dockerImage/build", data);
};

export const getLogs = (data) => {
  return http.get("/dockerImage/getLogs", data);
};

export const dockerImageItemPage = (data) => {
  return http.get("/dockerImage/dockerImageItemPage", data);
};

export const addDockerImageItem = (data) => {
  return http.json_post("/dockerImage/addDockerImageItem", data);
};

export const editDockerImageItem = (data) => {
  return http.json_post("/dockerImage/editDockerImageItem", data);
};

export const deleteDockerImageItem = (data) => {
  return http.json_post("/dockerImage/deleteDockerImageItem", data);
};
