var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"serviceList"},[_vm._m(0),_c('div',{staticClass:"searchCondition"},[_c('div',{staticClass:"searchConditionItem"},[_vm._v(" 名称： "),_c('a-input',{attrs:{"placeholder":"请输入名称","allowClear":""},on:{"pressEnter":_vm.query,"change":_vm.allowClearChange},model:{value:(_vm.name),callback:function ($$v) {_vm.name=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"name"}})],1),_c('div',{staticClass:"searchButton"},[_c('a-button',{attrs:{"type":"primary","icon":"search"},on:{"click":_vm.query}},[_vm._v("查询")])],1)]),_c('a-table',{attrs:{"rowClassName":_vm.$common.rowClassColor,"bordered":"","components":_vm.$common.getTitle(_vm.tableColumns),"columns":_vm.tableColumns,"dataSource":_vm.tableDataSource,"loading":_vm.loadingTable,"size":"small","rowKey":(record) => record.name,"row-selection":{
      selectedRowKeys: _vm.selectedRowKeys,
      onChange: _vm.onSelectChange,
    }},scopedSlots:_vm._u([{key:"label",fn:function(text, record){return _c('span',{},[(record.label && Object.keys(record.label).length > 0)?[_vm._l((record.label),function(value,key){return [_c('a-tag',{staticStyle:{"margin-top":"5px"}},[_vm._v(" "+_vm._s(key)+":"+_vm._s(value)+" ")])]})]:[_vm._v(" - ")]],2)}},{key:"innerEndPoint",fn:function(text, record){return _c('span',{},[(
          record.innerEndPoint && Object.keys(record.innerEndPoint).length > 0
        )?[_vm._l((record.innerEndPoint),function(item){return [_c('div',[_vm._v(_vm._s(item))])]})]:[_vm._v(" - ")]],2)}},{key:"outerEndPoint",fn:function(text, record){return _c('span',{},[(
          record.outerEndPoint && Object.keys(record.outerEndPoint).length > 0
        )?[_vm._l((record.outerEndPoint),function(item){return [_c('div',[_vm._v(_vm._s(item))])]})]:[_vm._v(" - ")]],2)}},{key:"action",fn:function(text, record){return _c('span',{},[_c('a-popconfirm',{attrs:{"placement":"right","okText":"确认","cancelText":"取消"},on:{"confirm":function($event){return _vm.deleteClick(record)}}},[_c('template',{slot:"title"},[_vm._v("确认是否删除"+_vm._s(record.name))]),_c('a',{staticStyle:{"color":"#ff4d4f"},attrs:{"href":"javascript:;"}},[_vm._v("删除")])],2)],1)}}])}),_c('a-popconfirm',{attrs:{"placement":"right","okText":"确认","cancelText":"取消","disabled":_vm.selectedRowKeys.length == 0},on:{"confirm":function($event){return _vm.batchDeleteClick()}}},[_c('template',{slot:"title"},[_vm._v("确认是否批量删除")]),_c('a-button',{attrs:{"type":"danger","icon":"delete","disabled":_vm.selectedRowKeys.length == 0}},[_vm._v("批量删除")])],2)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reminder"},[_vm._v("服务 "),_c('span',[_vm._v("service")])])
}]

export { render, staticRenderFns }