import http from "./axios";

// 查询证书分页列表
export const getPage = ({ host, pageNo, pageSize }) => {
  return http.get("/certificateDomainName/page", {
    host: host,
    pageNo: pageNo,
    pageSize: pageSize,
  });
};

// 新增证书
export const addCertificate = ({ host, remark }) => {
  return http.json_post("/certificateDomainName/add", {
    host: host,
    remark: remark,
  });
};
// 编辑证书
export const editCertificate = ({ host, id, remark }) => {
  return http.json_post("/certificateDomainName/edit", {
    host: host,
    remark: remark,
    id: id,
  });
};

// 删除证书
export const deleteCertificate = ({ id }) => {
  return http.json_post("/certificateDomainName/delete", {
    id: id,
  });
};

// 同步
export const syn = ({ id }) => {
  return http.json_post("/certificateDomainName/syn", {
    id: id,
  });
};
