<template>
  <div class="allApiList">
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="name" slot-scope="text, record">
        <a-button type="link" @click="apiDetail(record)">{{ text }}</a-button>
      </span>
      <span slot="method" slot-scope="text, record">
        <a-tag v-if="text == 'GET'" color="green">
          {{ text }}
        </a-tag>
        <a-tag v-else color="blue">
          {{ text }}
        </a-tag>
        {{ record.path }}
      </span>
      <span
        slot="catId"
        slot-scope="text, record"
        style="display: inline-block; width: 100%"
      >
        <a-select
          v-model="record.catId"
          style="width: 80%"
          @change="statusOnChange(record, text)"
        >
          <a-select-option
            v-for="item in menuList"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </span>
      <span slot="statusChange" slot-scope="text, record">
        <a-switch
          v-model="text"
          checkedChildren="已完成"
          unCheckedChildren="未完成"
          @change="statusOnChange(record)"
        />
      </span>
    </a-table>
  </div>
</template>
<script>
import api from "../lib/yapi";
import apiOut from "../lib/yapiOut";

export default {
  name: "allApiList",
  data() {
    return {
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "接口名称",
          ellipsis: true,
          dataIndex: "title",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "路径",
          ellipsis: true,
          dataIndex: "method",
          scopedSlots: { customRender: "method" },
        },
        {
          title: "接口分类",
          ellipsis: true,
          dataIndex: "catId",
          width: 300,
          scopedSlots: { customRender: "catId" },
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "statusChange",
          scopedSlots: { customRender: "statusChange" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      addTaskVisible: false,
      loading: false,
      projectList: [],
      projectId: undefined,
      desc: "",
      menuList: [],
    };
  },
  watch: {
    "$route.query": {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        let projectId = newVal.projectId;
        this.projectId = newVal.projectId;
        let catId = newVal.catId;
        if (catId) {
          this.query(1, projectId, catId);
        } else {
          this.query(1, projectId);
        }
        this.getApiClass(projectId);
      },
    },
  },
  created() {
    if (this.$route.query.uuid) {
      this.tableColumns.pop();
    }
  },
  methods: {
    apiDetail(val) {
      this.$emit("apiDetail", val);
    },
    getApiClass(projectId) {
      this.menuList.splice(0);
      let uuid = this.$route.query.uuid;
      if (uuid) {
        apiOut.getApiClassList({ projectId, uuid }).then((res) => {
          if (res.result == 200) {
            this.menuList.push(...res.data);
          }
        });
      } else {
        api.getApiClassList({ projectId }).then((res) => {
          if (res.result == 200) {
            this.menuList.push(...res.data.list);
          }
        });
      }
    },
    statusOnChange(record) {
      this.loadingTable = true;
      let uuid = this.$route.query.uuid;
      if (uuid) {
        this.loadingTable = false;
        this.$message.warning("没有权限");
      } else {
        let data = {
          id: record.id,
          catId: record.catId,
        };
        if (record.status == "done") {
          data.status = "undone";
        } else {
          data.status = "done";
        }
        api.simple(data).then((res) => {
          if (res.result == 200) {
            this.$message.success("修改成功");
            this.loadingTable = false;
            let projectId = this.$route.query.projectId;
            let catId = this.$route.query.catId;
            if (catId) {
              this.query(1, projectId, catId);
            } else {
              this.query(1, projectId);
            }
          }
        });
      }
    },
    // 点击查询
    query(index, projectId, catId) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        page: this.pageNo,
        limit: 15,
      };
      if (catId) {
        data.catId = catId;
        this.queryPage(data, catId);
      } else {
        data.projectId = projectId;
        this.queryPage(data);
      }
    },
    // 查询代码生成列表
    queryPage(data, catId) {
      this.loadingTable = true;
      this.tableDataSource.splice(0);
      let uuid = this.$route.query.uuid;
      if (uuid) {
        data.uuid = uuid;
        if (catId) {
          apiOut
            .catList(data)
            .then((res) => {
              this.loadingTable = false;
              if (res.result == 200) {
                this.tableDataSource.push(...res.data.records);
                this.tableDataSource.forEach((item) => {
                  this.$set(item, "statusChange", item.status == "done");
                });
                this.tablePagination = {
                  showQuickJumper: true,
                  showTotal: () => `共${res.data.total}条`,
                  pageSize: 15,
                  current: this.pageNo,
                  total: res.data.total,
                  onChange: (current) => this.changePageItem(current),
                };
              }
            })
            .catch((err) => {
              this.loadingTable = false;
            });
        } else {
          apiOut
            .queryPage(data)
            .then((res) => {
              this.loadingTable = false;
              if (res.result == 200) {
                this.tableDataSource.push(...res.data.records);
                this.tableDataSource.forEach((item) => {
                  this.$set(item, "statusChange", item.status == "done");
                });
                this.tablePagination = {
                  showQuickJumper: true,
                  showTotal: () => `共${res.data.total}条`,
                  pageSize: 15,
                  current: this.pageNo,
                  total: res.data.total,
                  onChange: (current) => this.changePageItem(current),
                };
              }
            })
            .catch((err) => {
              this.loadingTable = false;
            });
        }
      } else {
        if (catId) {
          api
            .catList(data)
            .then((res) => {
              this.loadingTable = false;
              if (res.result == 200) {
                this.tableDataSource.push(...res.data.records);
                this.tableDataSource.forEach((item) => {
                  this.$set(item, "statusChange", item.status == "done");
                });
                this.tablePagination = {
                  showQuickJumper: true,
                  showTotal: () => `共${res.data.total}条`,
                  pageSize: 15,
                  current: this.pageNo,
                  total: res.data.total,
                  onChange: (current) => this.changePageItem(current),
                };
              }
            })
            .catch((err) => {
              this.loadingTable = false;
            });
        } else {
          api
            .queryPage(data)
            .then((res) => {
              this.loadingTable = false;
              if (res.result == 200) {
                this.tableDataSource.push(...res.data.records);
                this.tableDataSource.forEach((item) => {
                  this.$set(item, "statusChange", item.status == "done");
                });
                this.tablePagination = {
                  showQuickJumper: true,
                  showTotal: () => `共${res.data.total}条`,
                  pageSize: 15,
                  current: this.pageNo,
                  total: res.data.total,
                  onChange: (current) => this.changePageItem(current),
                };
              }
            })
            .catch((err) => {
              this.loadingTable = false;
            });
        }
      }
    },
    // 翻页
    changePageItem(index) {
      let projectId = this.$route.query.projectId;
      let catId = this.$route.query.catId;
      if (catId) {
        this.query(index, projectId, catId);
      } else {
        this.query(index, projectId);
      }
    },
  },
};
</script>
<style>
.code_style .CodeMirror {
  height: 600px;
}
</style>
<style lang="scss" scoped>
::v-deep .ant-col-16 {
  width: 78%;
  margin-top: 5px;
  .ant-form-item-children {
    display: flex;
    .select-tag {
      width: 270px;
      margin-right: 20px;
    }
  }
}
::v-deep .ant-select-selection {
  border: none !important;
}
</style>
