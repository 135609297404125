<template>
  <div class="pipeline">
    <header class="pipeline_header">
      <div class="title">
        <span class="name">
          {{ name }}
        </span>
        <template v-if="type !== 'TEMPLATE'">
          <a-tooltip placement="bottom" v-if="state == 'RUNNING'">
            <div slot="title">执行中</div>
            <a-icon type="loading" style="margin-left: 10px" />
          </a-tooltip>
          <a-tooltip placement="bottom" v-if="state == 'FINISHED'">
            <div slot="title">执行完成</div>
            <div class="rage" style="background-color: #52c41a"></div>
          </a-tooltip>
          <a-tooltip placement="bottom" v-if="state == 'ERROR'">
            <div slot="title">执行失败</div>
            <div class="rage" style="background-color: #f5222d"></div>
          </a-tooltip>
          <a-tooltip placement="bottom" v-if="state == 'WAITING'">
            <div slot="title">等待执行</div>
            <div class="rage" style="background-color: #fa8c16"></div>
          </a-tooltip>
        </template>
      </div>
      <div class="buttonList">
        <div class="select">
          命名空间:
          <a-select
            v-model="namespace"
            placeholder="请选择命名空间"
            @change="updateGlobalParameters('namespace')"
          >
            <a-select-option
              v-for="(item, index) in nameSpaceList"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </div>
        <div class="select">
          profile:
          <a-select
            v-model="profile"
            placeholder="请选择profile"
            @change="updateGlobalParameters('profile')"
          >
            <a-select-option
              v-for="(item, index) in profileList"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </div>
        <a-tooltip placement="bottom">
          <div slot="title">设置参数</div>
          <a-button
            type="link"
            @click="queryParameter(true)"
            class="button"
            style="margin-right: 10px"
          >
            <a-icon type="setting" />
          </a-button>
        </a-tooltip>

        <template v-if="type !== 'TEMPLATE'">
          <a-tooltip
            placement="bottom"
            v-if="state == 'FINISHED' || state == 'ERROR'"
          >
            <div slot="title">重新执行</div>
            <a-button type="link" class="button" @click.stop="restart">
              <a-icon type="reload" />
            </a-button>
          </a-tooltip>
          <a-tooltip placement="bottom" v-if="state == 'WAITING'">
            <div slot="title">启动</div>
            <a-button type="link" class="button" @click.stop="start">
              <a-icon type="play-circle" />
            </a-button>
          </a-tooltip>
          <a-tooltip placement="bottom" v-if="state == 'RUNNING'">
            <div slot="title">停止</div>
            <a-button
              type="link"
              class="button"
              @click.stop="stop"
              style="color: red"
            >
              <a-icon type="pause-circle" />
            </a-button>
          </a-tooltip>
        </template>
      </div>
    </header>
    <div class="pipeline_content">
      <div class="pipeline_flow_content">
        <div class="flow_groups">
          <template v-for="(item, index) in stageList">
            <div
              :key="item.id"
              v-dragging="
                type == 'TEMPLATE'
                  ? { item: item, list: stageList, group: 'stageItem' }
                  : false
              "
              :class="{
                flow_group: true,
                template_flow_group: type == 'TEMPLATE',
              }"
              @dragend="editPipelineStageSort"
            >
              <div class="group_head">
                <template v-if="item.editStageNameShow">
                  <a-input
                    placeholder="请输入任务名称"
                    v-model="item.name"
                    style="width: 200px"
                  />
                  <a-button
                    type="link"
                    class="button"
                    style="margin-left: 5px; margin-top: 5px"
                    @click.stop="editStageSubmit(item)"
                  >
                    <a-icon type="check" />
                  </a-button>
                  <a-button
                    type="link"
                    class="button"
                    style="margin-left: 5px; margin-top: 5px"
                    @click.stop="unEditStage(item)"
                  >
                    <a-icon type="close" />
                  </a-button>
                </template>
                <template v-else>
                  <div class="teamix_title">{{ item.name }}</div>
                  <a-button
                    type="link"
                    class="button"
                    @click="editStage(item)"
                    v-if="type == 'TEMPLATE'"
                  >
                    <a-icon type="form" />
                  </a-button>

                  <a-tooltip v-if="type == 'TEMPLATE'">
                    <template slot="title"> 删除 </template>
                    <a-button
                      type="link"
                      class="button"
                      @click="deleteStage(item)"
                      style="margin-left: auto"
                    >
                      <a-icon type="delete"></a-icon>
                    </a-button>
                  </a-tooltip>
                </template>
              </div>
              <div class="stages">
                <div
                  class="stage_container"
                  v-for="(node, nodeIndex) in item.nodeList"
                >
                  <div
                    class="tasks_container"
                    :key="nodeIndex"
                    v-dragging="
                      type == 'TEMPLATE'
                        ? {
                            item: node,
                            list: item.nodeList,
                            group: item.id + 'nodeItem',
                          }
                        : false
                    "
                    @dragend.stop="editPipelineNodeSort"
                  >
                    <a-dropdown :trigger="['contextmenu']">
                      <div class="node">
                        <div
                          :class="{
                            node_content: true,
                            detail_node_content: type !== 'TEMPLATE',
                          }"
                          @click="editNode(node)"
                        >
                          <div class="node_content_top">
                            <div class="node_name" :title="node.name">
                              {{ node.name }}
                            </div>
                            <div class="node_state" v-if="type !== 'TEMPLATE'">
                              <a-tooltip
                                placement="bottom"
                                v-if="node.state == 'RUNNING'"
                              >
                                <div slot="title">执行中</div>
                                <a-icon
                                  type="loading"
                                  style="margin-left: 10px"
                                />
                              </a-tooltip>
                              <a-tooltip
                                placement="bottom"
                                v-if="node.state == 'FINISHED'"
                              >
                                <div slot="title">执行完成</div>
                                <div
                                  class="rage"
                                  style="background-color: #52c41a"
                                ></div>
                              </a-tooltip>
                              <a-tooltip
                                placement="bottom"
                                v-if="node.state == 'ERROR'"
                              >
                                <div slot="title">
                                  {{ node.errorMessage }}
                                </div>
                                <div
                                  class="rage"
                                  style="background-color: #f5222d"
                                ></div>
                              </a-tooltip>
                              <a-tooltip
                                placement="bottom"
                                v-if="node.state == 'WAITING'"
                              >
                                <div slot="title">等待执行</div>
                                <div
                                  class="rage"
                                  style="background-color: #fa8c16"
                                ></div>
                              </a-tooltip>
                              <a-tooltip
                                placement="bottom"
                                v-if="node.state == 'CANCELED'"
                              >
                                <div slot="title">取消执行</div>
                                <div
                                  class="rage"
                                  style="background-color: #ffb600"
                                ></div>
                              </a-tooltip>
                            </div>
                          </div>
                          <div
                            class="node_content_bottom"
                            v-if="type !== 'TEMPLATE'"
                          >
                            <div class="node_time">
                              {{ node.time }}
                            </div>

                            <a-popconfirm
                              placement="bottom"
                              okText="确认"
                              cancelText="取消"
                              @confirm.stop="
                                invokeDynamicApi(node.extraInfo.hostnamePerfix)
                              "
                            >
                              <template slot="title">确认是否强制发布</template>
                              <a-button
                                v-if="
                                  node.type == 'deliveryLockNodeService' &&
                                  node.state == 'RUNNING'
                                "
                                type="link"
                                size="small"
                                style="font-size: 12px; margin-left: 5px"
                                @click.stop=""
                              >
                                强制发布
                              </a-button>
                            </a-popconfirm>
                            <div class="node_log">
                              <a-tooltip placement="bottom">
                                <div slot="title">查看日志</div>
                                <a-button
                                  type="link"
                                  @click.stop="nodeLog(node)"
                                  style="color: #666666"
                                >
                                  <a-icon type="snippets" />
                                </a-button>
                              </a-tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a-menu
                        slot="overlay"
                        v-if="
                          type !== 'TEMPLATE' &&
                          (node.state == 'FINISHED' ||
                            node.state == 'ERROR' ||
                            node.state == 'CANCELED')
                        "
                      >
                        <a-menu-item key="1" @click="resetState(node)">
                          重置状态
                        </a-menu-item>
                      </a-menu>
                    </a-dropdown>
                  </div>
                </div>
                <div class="stage_container add_stage_container">
                  <div class="tasks_container">
                    <div class="node node_plus" @click="addNode(item)">
                      <div class="node_content_top">
                        <icon-font type="icon-plus-circle" />
                        <div class="node_name">并行任务</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flow_group_splitline">
              <div
                :class="{
                  flow_group_splitline_button: true,
                  last_splitline:
                    index == stageList.length - 1 && type !== 'TEMPLATE',
                  detail_group_splitline_button: type !== 'TEMPLATE',
                }"
              >
                <icon-font
                  type="icon-plus-circle"
                  v-if="index !== stageList.length - 1 && type == 'TEMPLATE'"
                  @click="addStage(index + 2)"
                />
              </div>
            </div>
          </template>
          <div class="flow_group flow_group_create" v-if="type == 'TEMPLATE'">
            <div class="group_head">
              <div class="teamix_title">新阶段</div>
            </div>
            <div class="stages">
              <div class="stage_container add_stage_container">
                <div class="tasks_container">
                  <div
                    class="node node_plus"
                    @click="addStage(stageList.length + 1)"
                  >
                    <div class="node_content_top">
                      <icon-font type="icon-plus-circle" />
                      <div class="node_name">新的任务</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="drawer" :style="nodeMenuStyle">
      <div class="drawer_content">
        <div class="drawer_content_head">
          <a-button type="link" class="button" @click="nodeMenuShow = false">
            <a-icon type="close"></a-icon>
          </a-button>
        </div>
        <div class="drawer_content_body">
          <div class="menu_content">
            <div
              class="menu_node"
              v-for="item in menuNodeList"
              :key="item.id"
              @click="addSubmit(item)"
            >
              <div class="menu_node_left"></div>
              <div class="menu_node_right">
                <div class="name">{{ item.name }}</div>
                <div class="desc"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="mask"
        :style="maskShowStyle"
        @click="nodeMenuShow = false"
      ></div>
    </div>

    <div class="drawer" :style="editNodeStyle">
      <div class="drawer_content">
        <div class="drawer_content_head">
          <div class="title">
            编辑
            <a-tooltip>
              <template slot="title"> 删除 </template>
              <a-button type="link" class="button" @click="deleteNode">
                <a-icon type="delete"></a-icon>
              </a-button>
            </a-tooltip>
          </div>
          <a-button type="link" class="button" @click="editNodeShow = false">
            <a-icon type="close"></a-icon>
          </a-button>
        </div>
        <div class="drawer_content_body">
          <div class="edit_node_form">
            <a-tabs v-model="active">
              <a-tab-pane key="1" tab="基本信息">
                <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
                  <a-form-item label="任务名称">
                    <a-input placeholder="请输入任务名称" v-model="nodeName" />
                  </a-form-item>
                  <template v-if="nodeType == 'gitCloneNodeService'">
                    <a-form-item label="模式">
                      <a-radio-group v-model="extraInfo.inputType">
                        <a-radio value="SELECT">选择模式</a-radio>
                        <a-radio value="INPUT">输入模式</a-radio>
                      </a-radio-group>
                    </a-form-item>
                    <a-form-item
                      label="git仓库地址"
                      v-if="extraInfo.inputType == 'INPUT'"
                    >
                      <a-input
                        placeholder="请输入git仓库地址"
                        v-model="extraInfo.url"
                      />
                    </a-form-item>
                    <a-form-item label="项目名">
                      <template v-if="extraInfo.inputType == 'SELECT'">
                        <a-select
                          v-model="extraInfo.projectId"
                          placeholder="请选择"
                          @change="projectIdChange"
                          showSearch
                          :filterOption="false"
                          @search="searchUrlList"
                          @blur="loadDynamicApiData('urlList')"
                        >
                          <a-select-option
                            v-for="(item, index) in urlList"
                            :key="index"
                            :value="item.projectId"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </template>
                      <a-input
                        v-else
                        placeholder="请输入项目名"
                        v-model="extraInfo.projectName"
                      />
                    </a-form-item>
                    <a-form-item label="分支">
                      <a-select
                        v-if="extraInfo.inputType == 'SELECT'"
                        v-model="extraInfo.branch"
                        placeholder="请选择分支"
                        @change="branchChange"
                        :disabled="!extraInfo.projectId"
                      >
                        <a-select-option v-for="item in branchList" :key="item">
                          {{ item }}
                        </a-select-option>
                      </a-select>
                      <a-input
                        v-else
                        placeholder="请输入分支"
                        v-model="extraInfo.branch"
                      />
                    </a-form-item>
                    <a-form-item label="commitId">
                      <a-select
                        v-if="extraInfo.inputType == 'SELECT'"
                        v-model="extraInfo.commitId"
                        placeholder="请选择commitId"
                        :disabled="!extraInfo.branch || !extraInfo.projectId"
                      >
                        <a-select-option
                          v-for="item in commitList"
                          :key="item.id"
                        >
                          {{ item.committedDate.slice(5) }} | {{ item.message }}
                        </a-select-option>
                      </a-select>
                      <a-input
                        v-else
                        placeholder="请输入commitId"
                        v-model="extraInfo.commitId"
                      />
                    </a-form-item>
                  </template>

                  <template
                    v-if="
                      nodeType == 'mavenCompileNodeService' ||
                      nodeType == 'npmCompileNodeService'
                    "
                  >
                    <a-form-item label="工作目录">
                      <a-input
                        placeholder="请输入工作目录"
                        v-model="extraInfo.workDir"
                      />
                    </a-form-item>
                    <a-form-item
                      label="maven版本"
                      v-if="nodeType == 'mavenCompileNodeService'"
                    >
                      <a-select
                        v-model="extraInfo.mavenVersion"
                        placeholder="请选择maven版本"
                      >
                        <a-select-option
                          v-for="(item, index) in versionList"
                          :key="index"
                          :value="item.value"
                        >
                          {{ item.label }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item label="编译命令">
                      <a-textarea
                        placeholder="请输入编译命令"
                        v-model="extraInfo.compileCommand"
                        :rows="5"
                      />
                    </a-form-item>
                    <a-form-item
                      label="target包路径"
                      v-if="nodeType == 'mavenCompileNodeService'"
                    >
                      <a-input
                        placeholder="请输入target包路径"
                        v-model="extraInfo.targetDirPath"
                      />
                    </a-form-item>
                    <a-form-item
                      label="dist包路径"
                      v-if="nodeType == 'npmCompileNodeService'"
                    >
                      <a-input
                        placeholder="请输入dist包路径"
                        v-model="extraInfo.distDirPath"
                      />
                    </a-form-item>
                  </template>

                  <template v-if="nodeType == 'trans2ServerNodeService'">
                    <a-form-item label="源文件路径">
                      <a-input
                        placeholder="请输入源文件路径"
                        v-model="extraInfo.sourcePath"
                      />
                    </a-form-item>
                    <a-form-item label="目标文件路径">
                      <a-input
                        placeholder="请输入目标文件路径"
                        v-model="extraInfo.targetPath"
                      />
                    </a-form-item>
                    <a-form-item label="是否覆盖文件">
                      <a-switch
                        checkedChildren="是"
                        unCheckedChildren="否"
                        v-model="extraInfo.overrideFile"
                      />
                    </a-form-item>
                    <a-form-item label="过滤表达式(包含)">
                      <a-input
                        placeholder="请输入过滤表达式(包含)"
                        v-model="extraInfo.includeFilterPattern"
                      />
                    </a-form-item>
                    <a-form-item label="过滤表达式(排除)">
                      <a-input
                        placeholder="请输入过滤表达式(排除)"
                        v-model="extraInfo.excludeFilterPattern"
                      />
                    </a-form-item>
                  </template>

                  <template v-if="nodeType == 'cmdExecuteNodeService'">
                    <a-form-item>
                      <span slot="label">
                        命令
                        <a-tooltip placement="left">
                          <div slot="title">可以使用分号和换行符分割</div>
                          <a-icon
                            type="question-circle"
                            style="color: #1879ff; font-size: 14px"
                          />
                        </a-tooltip>
                      </span>
                      <a-textarea
                        :rows="10"
                        placeholder="请输入命令"
                        v-model="extraInfo.command"
                      />
                    </a-form-item>
                  </template>

                  <template
                    v-if="
                      nodeType == 'trans2ServerNodeService' ||
                      nodeType == 'cmdExecuteNodeService'
                    "
                  >
                    <a-form-item label="远程服务器id">
                      <a-select
                        v-model="extraInfo.serverList"
                        placeholder="请选择远程服务器"
                        showSearch
                        :filterOption="false"
                        @search="searchServerSelectList"
                        mode="multiple"
                        @blur="loadDynamicApiData('server')"
                      >
                        <a-select-option
                          v-for="(item, index) in serverSelectList"
                          :key="index"
                          :value="item.id"
                        >
                          {{ item.instanceName }} | {{ item.publicIp }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </template>

                  <template
                    v-if="
                      nodeType == 'dockerPushNodeService' ||
                      nodeType == 'dockerPullNodeService' ||
                      nodeType == 'generateDeploymentNewService' ||
                      nodeType == 'generateStatefulSetService'
                    "
                  >
                    <a-form-item
                      label="本地文件地址"
                      v-if="nodeType == 'dockerPushNodeService'"
                    >
                      <a-input
                        placeholder="请输入本地文件地址"
                        v-model="extraInfo.filePath"
                      />
                    </a-form-item>
                    <a-form-item
                      label="副本数"
                      v-if="
                        nodeType == 'generateDeploymentNewService' ||
                        nodeType == 'generateStatefulSetService'
                      "
                    >
                      <a-input
                        placeholder="请输入文件保存路径"
                        v-model="extraInfo.replicas"
                      />
                    </a-form-item>
                    <a-form-item
                      label="镜像仓库地址"
                      v-if="nodeType == 'dockerPushNodeService'"
                    >
                      <a-select
                        v-model="extraInfo.hubUrl"
                        placeholder="请选择镜像仓库地址"
                        showSearch
                      >
                        <a-select-option
                          v-for="(item, index) in hubUrlList"
                          :key="index"
                          :value="item"
                        >
                          {{ item }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item
                      label="dockerfile"
                      v-if="nodeType == 'dockerPushNodeService'"
                    >
                      <a-select
                        v-model="extraInfo.templateCode"
                        placeholder="请选择dockerfile"
                        showSearch
                        style="width: 80%; margin-right: 10px"
                      >
                        <a-select-option
                          v-for="(item, index) in templateList"
                          :key="index"
                          :value="item.code"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                      <a-button type="link" @click="getTemplateContentShow"
                        >查看内容</a-button
                      >
                    </a-form-item>
                    <a-form-item label="镜像名称">
                      <a-input
                        placeholder="请输入镜像名称"
                        v-model="extraInfo.projectName"
                        v-if="
                          nodeType == 'generateDeploymentNewService' ||
                          nodeType == 'generateStatefulSetService'
                        "
                      />
                      <a-input
                        v-else
                        placeholder="请输入镜像名称"
                        v-model="extraInfo.repository"
                      />
                    </a-form-item>
                    <a-form-item label="版本号">
                      <a-input
                        placeholder="请输入版本号"
                        v-model="extraInfo.tag"
                      />
                    </a-form-item>
                    <a-form-item
                      label="hub仓库的项目"
                      v-if="nodeType == 'dockerPushNodeService'"
                    >
                      <a-input
                        placeholder="请输入hub仓库的项目"
                        v-model="extraInfo.hubProject"
                      />
                    </a-form-item>
                    <a-form-item
                      label="profile"
                      v-if="nodeType == 'dockerPushNodeService'"
                    >
                      <a-input
                        placeholder="请输入profile"
                        v-model="extraInfo.profile"
                      />
                    </a-form-item>
                    <template
                      v-if="
                        nodeType == 'generateDeploymentNewService' ||
                        nodeType == 'generateStatefulSetService'
                      "
                    >
                      <a-form-item label="升级策略">
                        <a-select
                          v-model="extraInfo.strategyTemplateId"
                          placeholder="请选择升级策略"
                          showSearch
                          style="width: 75%; margin-right: 10px"
                        >
                          <a-select-option
                            v-for="(item, index) in schedulingTemplateList"
                            :key="index"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                        <a-button
                          type="link"
                          size="small"
                          :disabled="extraInfo.strategyTemplateId == -1"
                          @click="
                            updateSchedulingTemplate(
                              extraInfo.strategyTemplateId
                            )
                          "
                          >更新升级策略</a-button
                        >
                      </a-form-item>
                      <a-form-item label="探针模板">
                        <a-select
                          v-model="extraInfo.healthProbeId"
                          placeholder="请选择探针模板"
                          showSearch
                          style="width: 75%; margin-right: 10px"
                        >
                          <a-select-option
                            v-for="(item, index) in probeTemplateList"
                            :key="index"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                        <a-button
                          type="link"
                          size="small"
                          :disabled="extraInfo.healthProbeId == -1"
                          @click="updateProbe(extraInfo.healthProbeId)"
                          >更新探针</a-button
                        >
                      </a-form-item>
                      <a-form-item label="节点亲和性">
                        <a-select
                          v-model="extraInfo.nodeAffinityId"
                          placeholder="请选择节点亲和性模板"
                          showSearch
                          style="width: 75%; margin-right: 10px"
                        >
                          <a-select-option
                            v-for="(item, index) in nodeAffinityList"
                            :key="index"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>

                        <a-button
                          type="link"
                          size="small"
                          :disabled="extraInfo.nodeAffinityId == -1"
                          @click="updateNodeAffinity(extraInfo.nodeAffinityId)"
                          >设置节点亲和性</a-button
                        >
                      </a-form-item>
                      <a-form-item label="优先级">
                        <a-select
                          v-model="extraInfo.priorityClassName"
                          placeholder="请选择优先级"
                          showSearch
                        >
                          <a-select-option
                            v-for="(item, index) in priorityList"
                            :key="index"
                            :value="item.name"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item label="只读">
                        <a-checkbox v-model="extraInfo.readOnly"></a-checkbox>
                      </a-form-item>
                      <a-form-item label="非root">
                        <a-checkbox v-model="extraInfo.nonRoot"></a-checkbox>
                      </a-form-item>
                    </template>
                  </template>

                  <template
                    v-if="
                      nodeType == 'generateServiceService' ||
                      nodeType == 'generateIngressService'
                    "
                  >
                    <a-form-item label="元数据名称">
                      <a-input
                        placeholder="请输入元数据名称"
                        v-model="extraInfo.metaName"
                      />
                    </a-form-item>
                    <a-form-item label="工作目录">
                      <a-input
                        placeholder="请输入工作目录"
                        v-model="extraInfo.workDir"
                      />
                    </a-form-item>
                    <a-form-item label="模板">
                      <a-select
                        v-model="extraInfo.templateCode"
                        placeholder="请选择模板"
                        showSearch
                      >
                        <a-select-option
                          v-for="(item, index) in templateList"
                          :key="index"
                          :value="item.code"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item
                      label="访问域名"
                      v-if="nodeType == 'generateIngressService'"
                    >
                      <a-input
                        placeholder="请输入访问域名"
                        v-model="extraInfo.host"
                      />
                    </a-form-item>
                    <a-form-item
                      label="证书名称"
                      v-if="nodeType == 'generateIngressService'"
                    >
                      <a-input
                        placeholder="请输入证书名称"
                        v-model="extraInfo.tlsSecretName"
                      />
                    </a-form-item>
                    <a-form-item label="命名空间">
                      <a-input
                        placeholder="请输入命名空间"
                        v-model="extraInfo.namespace"
                      />
                    </a-form-item>
                  </template>

                  <template
                    v-if="
                      nodeType == 'deliveryLockNodeService' ||
                      nodeType == 'deliveryUnlockNodeService'
                    "
                  >
                    <a-form-item label="hostname前缀">
                      <a-input
                        placeholder="请输入hostname前缀"
                        v-model="extraInfo.hostnamePerfix"
                      />
                    </a-form-item>
                  </template>
                </a-form>
              </a-tab-pane>
              <a-tab-pane
                key="2"
                tab="资源限制"
                v-if="
                  nodeType == 'generateDeploymentNewService' ||
                  nodeType == 'generateStatefulSetService'
                "
              >
                <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
                  <a-form-item label="资源限制">
                    <div style="display: inline-block; margin-right: 10px">
                      CPU
                      <a-input
                        v-model="extraInfo.limitCpu"
                        style="width: 85px; margin-left: 10px"
                      />
                      Core
                    </div>
                    <div style="display: inline-block; margin-right: 10px">
                      内存
                      <a-input
                        v-model="extraInfo.limitMemory"
                        style="width: 85px; margin-left: 10px"
                      />
                      MiB
                    </div>
                    存储
                    <a-input
                      v-model="extraInfo.limitEphemeralStorage"
                      style="width: 85px; margin-left: 10px"
                    />
                    MiB
                  </a-form-item>
                  <a-form-item label="所需资源">
                    <div style="display: inline-block; margin-right: 10px">
                      CPU
                      <a-input
                        v-model="extraInfo.requestCpu"
                        style="width: 85px; margin-left: 10px"
                      />
                      Core
                    </div>
                    <div style="display: inline-block; margin-right: 10px">
                      内存
                      <a-input
                        v-model="extraInfo.requestMemory"
                        style="width: 85px; margin-left: 10px"
                      />
                      MiB
                    </div>
                    存储
                    <a-input
                      v-model="extraInfo.requestEphemeralStorage"
                      style="width: 85px; margin-left: 10px"
                    />
                    MiB
                  </a-form-item>
                </a-form>
              </a-tab-pane>
              <a-tab-pane
                key="4"
                tab="数据卷"
                v-if="
                  nodeType == 'generateDeploymentNewService' ||
                  nodeType == 'generateStatefulSetService'
                "
              >
                <div class="searchCondition">
                  <div class="searchButton">
                    <a-button
                      type="link"
                      icon="plus"
                      style="margin: 10px"
                      @click="addNewVolume()"
                    >
                      新增本地存储
                    </a-button>
                  </div>
                </div>

                <a-table
                  :rowClassName="$common.rowClassColor"
                  bordered
                  :components="$common.getTitle(newVolumeColumns)"
                  :columns="newVolumeColumns"
                  :dataSource="extraInfo.localVolumeList"
                  :pagination="false"
                  :loading="false"
                  size="small"
                >
                  <span slot="type" slot-scope="text, record, index">
                    <a-select
                      v-model="record.type"
                      style="width: 100%"
                      @change="volumeTypeChange(record.type, index)"
                    >
                      <a-select-option
                        v-for="(item, index) in volumeNewTypeList"
                        :key="index"
                        :value="item.value"
                      >
                        {{ item.label }}
                      </a-select-option>
                    </a-select>
                  </span>
                  <span slot="name" slot-scope="text, record">
                    <a-input v-model="record.name" placeholder="请输入名称" />
                  </span>
                  <span slot="sourceName" slot-scope="text, record">
                    <a-select
                      v-model="record.sourceName"
                      style="width: 200px"
                      v-if="record.type == 'CONFIG_MAP'"
                      placeholder="请选择挂载源"
                      :dropdownMatchSelectWidth="false"
                      @focus="volumeTypeChange('CONFIG_MAP')"
                    >
                      <a-select-option
                        v-for="(item, index) in configMapList"
                        :key="index"
                        :value="item.name"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                    <a-select
                      v-model="record.sourceName"
                      style="width: 200px"
                      v-else-if="record.type == 'SECRET'"
                      placeholder="请选择挂载源"
                      :dropdownMatchSelectWidth="false"
                      @focus="volumeTypeChange('SECRET')"
                    >
                      <a-select-option
                        v-for="(item, index) in secretList"
                        :key="index"
                        :value="item.metadata.name"
                      >
                        {{ item.metadata.name }}
                      </a-select-option>
                    </a-select>
                    <a-input
                      v-else-if="record.type == 'HOST_PATH'"
                      v-model="record.sourceName"
                      placeholder="请输入主机路径，如/tmp"
                      style="width: 200px"
                    />
                    <template v-else> 临时目录 </template>
                  </span>
                  <span slot="mountPath" slot-scope="text, record">
                    <a-input
                      v-model="record.mountPath"
                      placeholder="请输入挂载容器路径，如/tmp"
                    />
                  </span>
                  <span slot="subPath" slot-scope="text, record">
                    <a-input
                      v-model="record.subPath"
                      placeholder="选填，默认为空"
                    />
                  </span>
                  <span slot="action" slot-scope="text, record, index">
                    <a
                      :disabled="
                        record.type !== 'CONFIG_MAP' && record.type !== 'SECRET'
                      "
                      href="javascript:;"
                      @click="editOtherOptions(index)"
                    >
                      其它选项
                    </a>
                    <a-divider type="vertical" />
                    <a
                      href="javascript:;"
                      style="color: #ff4d4f"
                      @click="deleteNewVolume(index)"
                    >
                      删除
                    </a>
                  </span>
                </a-table>

                <div class="searchCondition">
                  <div class="searchButton">
                    <a-button
                      type="link"
                      icon="plus"
                      style="margin: 10px"
                      @click="addClaimVolume()"
                    >
                      新增云存储
                    </a-button>
                  </div>
                </div>

                <a-table
                  :rowClassName="$common.rowClassColor"
                  bordered
                  :components="$common.getTitle(newVolumeColumns)"
                  :columns="newVolumeColumns"
                  :dataSource="extraInfo.pvcVolumeList"
                  :pagination="false"
                  :loading="false"
                  size="small"
                >
                  <span slot="type" slot-scope="text, record">
                    <a-select v-model="record.type" style="width: 100%">
                      <a-select-option
                        v-for="(item, index) in volumeClaimTypeList"
                        :key="index"
                        :value="item.value"
                      >
                        {{ item.label }}
                      </a-select-option>
                    </a-select>
                  </span>
                  <span slot="name" slot-scope="text, record">
                    <a-input v-model="record.name" placeholder="请输入名称" />
                  </span>
                  <span slot="sourceName" slot-scope="text, record">
                    <a-select
                      v-model="record.sourceName"
                      style="width: 200px"
                      :dropdownMatchSelectWidth="false"
                      placeholder="请选择挂载源"
                      showSearch
                    >
                      <a-select-option
                        v-for="(item, index) in pvcNameList"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </a-select-option>
                    </a-select>
                  </span>
                  <span slot="mountPath" slot-scope="text, record">
                    <a-input
                      v-model="record.mountPath"
                      placeholder="请输入挂载容器路径，如/tmp"
                    />
                  </span>
                  <span slot="subPath" slot-scope="text, record">
                    <a-input
                      v-model="record.subPath"
                      placeholder="选填，默认为空"
                    />
                  </span>
                  <span slot="action" slot-scope="text, record, index">
                    <a
                      href="javascript:;"
                      style="color: #ff4d4f"
                      @click="deleteClaimVolume(index)"
                    >
                      删除
                    </a>
                  </span>
                </a-table>
              </a-tab-pane>
              <a-tab-pane key="5" tab="参数" v-if="parameterList.length > 0">
                <a-form :label-col="{ span: 7 }" :wrapper-col="{ span: 15 }">
                  <a-form-item
                    :label="item.label"
                    v-for="(item, index) in parameterList"
                    :key="index"
                  >
                    <a-input
                      :placeholder="'请输入' + item.label"
                      v-model="item.value"
                      disabled
                      style="width: 80%; margin-right: 10px"
                    />
                    <a-button type="link" v-copy="item.value">复制</a-button>
                  </a-form-item>
                </a-form>
              </a-tab-pane>
            </a-tabs>
          </div>
        </div>
        <div class="drawer_content_footer">
          <a-button type="primary" @click="editNodeSubmit">确定</a-button>
        </div>
      </div>
      <div
        class="mask"
        :style="maskShowStyle"
        @click="editNodeShow = false"
      ></div>
    </div>

    <a-modal
      title="设置参数"
      v-model="parameterVisible"
      :maskClosable="false"
      width="1000px"
    >
      <div class="searchCondition">
        <div class="searchButton">
          <a-button type="primary" @click="add" icon="plus">新增</a-button>
        </div>
      </div>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(tableColumns)"
        :columns="tableColumns"
        :dataSource="tableDataSource"
        :pagination="false"
        :loading="loadingTable"
        size="small"
        :rowKey="(record) => record.id"
      >
        <span slot="action" slot-scope="text, record">
          <a
            href="javascript:;"
            @click="modifyClick(record)"
            :disabled="!record.editable"
            >修改</a
          >
          <a-divider type="vertical" />
          <a-popconfirm
            placement="right"
            okText="确认"
            cancelText="取消"
            @confirm="deleteClick(record)"
            :disabled="!record.editable"
          >
            <template slot="title">确认是否删除{{ record.name }}</template>
            <a
              href="javascript:;"
              style="color: #ff4d4f"
              :disabled="!record.editable"
              >删除</a
            >
          </a-popconfirm>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="parameterVisible = false">
            关闭
          </a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="参数key:">
          <a-input
            v-model="updateData.key"
            placeholder="请输入参数key"
            :disabled="title == '修改'"
          />
        </a-form-item>
        <a-form-item label="参数值:">
          <a-input
            v-model="updateData.value"
            placeholder="请输入参数值"
            :disabled="title == '修改' && !updateData.editable"
          />
        </a-form-item>
        <a-form-item label="备注:">
          <a-input v-model="updateData.remark" placeholder="请输入备注" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      v-model="logVisible"
      :maskClosable="false"
      :afterClose="logClose"
      width="1400px"
      :footer="false"
    >
      <template slot="title">
        日志
        <div style="display: inline-block">
          <a-tooltip placement="bottom" v-if="nodeState == 'RUNNING'">
            <div slot="title">执行中</div>
            <a-icon type="loading" style="margin-left: 10px" />
          </a-tooltip>
          <a-tooltip placement="bottom" v-if="nodeState == 'FINISHED'">
            <div slot="title">执行完成</div>
            <div class="rage" style="background-color: #52c41a"></div>
          </a-tooltip>
          <a-tooltip placement="bottom" v-if="nodeState == 'ERROR'">
            <div slot="title">{{ nodeErrorMessage }}</div>
            <div class="rage" style="background-color: #f5222d"></div>
          </a-tooltip>
          <a-tooltip placement="bottom" v-if="nodeState == 'WAITING'">
            <div slot="title">等待执行</div>
            <div class="rage" style="background-color: #fa8c16"></div>
          </a-tooltip>
        </div>
      </template>
      <div
        id="terminal"
        style="height: 700px; padding: 10px; background: #008080"
      ></div>
    </a-modal>

    <a-modal
      :title="templateName"
      v-model="templateContentShow"
      :maskClosable="false"
      width="800px"
    >
      <div style="height: 700px">
        <codemirror
          v-model="templateContent"
          :options="options"
          class="code"
        ></codemirror>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="templateContentShow = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            @click="updateTemplateContentSubmit()"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="设置节点亲和性"
      v-model="updateNodeAffinityShow"
      :maskClosable="false"
      width="1000px"
    >
      <a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
        <a-form-item label="必须满足:">
          <a-button type="link" @click="addRequired" size="small"
            >添加规则</a-button
          >
          <div v-for="(item, ruleIndex) in required">
            <div class="item">
              <a-button
                type="link"
                @click="deleteRequired(ruleIndex)"
                size="small"
                class="close"
              >
                <a-icon type="close" />
              </a-button>
              <a-button
                type="link"
                @click="addRequiredItem(ruleIndex)"
                size="small"
                >添加</a-button
              >
              <a-table
                :rowClassName="$common.rowClassColor"
                bordered
                :components="$common.getTitle(nodeAffinityTableColumns)"
                :columns="nodeAffinityTableColumns"
                :dataSource="item.itemList"
                :pagination="false"
                size="small"
              >
                <span slot="labelName" slot-scope="text, record">
                  <a-input v-model="record.labelName" />
                </span>
                <span slot="operator" slot-scope="text, record">
                  <a-select v-model="record.operator" style="width: 130px">
                    <a-select-option
                      v-for="(operatorItem, index) in operatorSelectList"
                      :key="index"
                      :value="operatorItem"
                    >
                      {{ operatorItem }}
                    </a-select-option>
                  </a-select>
                </span>
                <span slot="labelValue" slot-scope="text, record">
                  <a-input
                    v-model="record.labelValue"
                    placeholder="多个值用逗号分隔"
                    :disabled="
                      record.operator == 'DoesNotExist' ||
                      record.operator == 'Exists'
                    "
                  />
                </span>
                <span slot="action" slot-scope="text, record, index">
                  <a
                    href="javascript:;"
                    style="color: #ff4d4f"
                    @click="deleteRequiredItem(ruleIndex, index)"
                    >删除</a
                  >
                </span>
              </a-table>
            </div>
          </div>
        </a-form-item>
        <a-form-item label="尽量满足:">
          <a-button type="link" @click="addOptional" size="small"
            >添加规则</a-button
          >
          <div v-for="(item, ruleIndex) in optional">
            <div class="item">
              <a-button
                type="link"
                @click="deleteOptional(ruleIndex)"
                size="small"
                class="close"
              >
                <a-icon type="close" />
              </a-button>
              <div>权重</div>
              <a-input v-model="item.weight" placeholder="请输入权重" />
              <a-button
                type="link"
                @click="addOptionalItem(ruleIndex)"
                size="small"
                >添加</a-button
              >
              <a-table
                :rowClassName="$common.rowClassColor"
                bordered
                :components="$common.getTitle(nodeAffinityTableColumns)"
                :columns="nodeAffinityTableColumns"
                :dataSource="item.itemList"
                :pagination="false"
                size="small"
              >
                <span slot="labelName" slot-scope="text, record">
                  <a-input v-model="record.labelName" />
                </span>
                <span slot="operator" slot-scope="text, record">
                  <a-select v-model="record.operator" style="width: 130px">
                    <a-select-option
                      v-for="(operatorItem, index) in operatorSelectList"
                      :key="index"
                      :value="operatorItem"
                    >
                      {{ operatorItem }}
                    </a-select-option>
                  </a-select>
                </span>
                <span slot="labelValue" slot-scope="text, record">
                  <a-input
                    v-model="record.labelValue"
                    placeholder="多个值用逗号分隔"
                    :disabled="
                      record.operator == 'DoesNotExist' ||
                      record.operator == 'Exists'
                    "
                  />
                </span>
                <span slot="action" slot-scope="text, record, index">
                  <a
                    href="javascript:;"
                    style="color: #ff4d4f"
                    @click="deleteOptionalItem(ruleIndex, index)"
                    >删除</a
                  >
                </span>
              </a-table>
            </div>
          </div>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="updateNodeAffinityShow = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            @click="updateNodeAffinitySubmit()"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="更新探针"
      v-model="updateProbeShow"
      :maskClosable="false"
      width="700px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="名称:">
          <a-input v-model="updateProbeData.name" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item label="启用状态:">
          <a-switch
            v-model="updateProbeData.enabled"
            checkedChildren="启用"
            unCheckedChildren="禁用"
          />
        </a-form-item>
        <template v-for="item in probeFormList">
          <a-form-item>
            <span slot="label">
              {{ item.label }}
              <a-tooltip>
                <template slot="title">
                  {{
                    item.type == "liveness"
                      ? "用来检查容器是否正常，不正常则重启容器"
                      : item.type == "readiness"
                      ? "用来确定容器是否已经就绪可以接受流量"
                      : "容器内应用是否已启动"
                  }}
                </template>
                <a-icon type="question-circle" />
              </a-tooltip>
            </span>
            <a-checkbox
              v-model="updateProbeData[item.type + 'ProbeEnabled']"
              @change="
                probeEnabledChange(
                  updateProbeData[item.type + 'ProbeEnabled'],
                  item.type,
                  localData
                )
              "
            ></a-checkbox>
          </a-form-item>
          <div class="probeForm">
            <a-form
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 14 }"
              v-if="updateProbeData[item.type + 'ProbeEnabled']"
            >
              <a-tabs
                v-model="updateProbeData[item.type + 'Probe'].requestType"
                @change="
                  tabClick(
                    updateProbeData[item.type + 'Probe'].requestType,
                    updateProbeData[item.type + 'Probe']
                  )
                "
              >
                <a-tab-pane tab="Http请求" key="HTTP"></a-tab-pane>
                <a-tab-pane tab="TCP连接" key="TCP"></a-tab-pane>
                <a-tab-pane tab="命令行" key="SHELL"></a-tab-pane>
              </a-tabs>
              <a-form-item
                label="路径:"
                v-if="
                  updateProbeData[item.type + 'Probe'].requestType == 'HTTP'
                "
              >
                <a-input
                  v-model="updateProbeData[item.type + 'Probe'].extraInfo.path"
                />
              </a-form-item>
              <a-form-item
                label="端口:"
                v-if="
                  updateProbeData[item.type + 'Probe'].requestType == 'HTTP' ||
                  updateProbeData[item.type + 'Probe'].requestType == 'TCP'
                "
              >
                <a-input
                  v-model="updateProbeData[item.type + 'Probe'].extraInfo.port"
                />
              </a-form-item>
              <a-form-item
                label="命令行:"
                v-if="
                  updateProbeData[item.type + 'Probe'].requestType == 'SHELL'
                "
              >
                <a-input
                  v-model="
                    updateProbeData[item.type + 'Probe'].extraInfo.command
                  "
                />
              </a-form-item>
              <a-form-item label="延迟探测时间(秒):">
                <a-input
                  v-model="
                    updateProbeData[item.type + 'Probe'].initialDelaySeconds
                  "
                />
              </a-form-item>
              <a-form-item label="执行探测频率(秒):">
                <a-input
                  v-model="updateProbeData[item.type + 'Probe'].periodSeconds"
                />
              </a-form-item>
              <a-form-item label="超时时间(秒):">
                <a-input
                  v-model="updateProbeData[item.type + 'Probe'].timeoutSeconds"
                />
              </a-form-item>
              <a-form-item label="健康阈值:">
                <a-input
                  v-model="
                    updateProbeData[item.type + 'Probe'].successThreshold
                  "
                  disabled
                />
              </a-form-item>
              <a-form-item label="不健康阈值:">
                <a-input
                  v-model="
                    updateProbeData[item.type + 'Probe'].failureThreshold
                  "
                />
              </a-form-item>
            </a-form>
          </div>
        </template>
        <a-form-item label="备注:">
          <a-textarea
            v-model="updateProbeData.remark"
            placeholder="请输入备注"
            autoSize
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="updateProbeShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="updateProbeSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="设置升级策略"
      v-model="updateSchedulingShow"
      :maskClosable="false"
      width="670px"
    >
      <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="名称:">
          <a-input
            v-model="updateSchedulingData.name"
            placeholder="请输入名称"
          />
        </a-form-item>
        <a-form-item>
          <span slot="label">
            升级方式
            <a-tooltip>
              <template slot="title">
                滚动升级: 启动新Pod, 暂停旧版本的Pod, 适用于大部分情况 <br />
                替换升级: 先停止旧版本的所有Pod,
                然后启动新版本的Pod,升级期间会有短暂的服务不可用
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <div>
            <a-radio-group
              :options="kubeSchedulingUpdateMethods"
              v-model="updateSchedulingData.updateMethod"
            />
          </div>
          <a-form>
            <a-row type="flex" justify="space-between">
              <template
                v-if="updateSchedulingData.updateMethod == 'ROLLING_UPDATE'"
              >
                <a-col>
                  <a-form-item label="不可用Pod最大数量:">
                    <a-input v-model="updateSchedulingData.maxUnavailable">
                      <a-select
                        slot="addonAfter"
                        v-model="updateSchedulingData.maxUnavailableUnit"
                      >
                        <a-select-option
                          v-for="(item, index) in unitList"
                          :key="index"
                          :value="item"
                        >
                          {{ item }}
                        </a-select-option>
                      </a-select>
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-col>
                  <a-form-item label="超过期望的Pod数量:">
                    <a-input v-model="updateSchedulingData.maxSurge">
                      <a-select
                        slot="addonAfter"
                        v-model="updateSchedulingData.maxSurgeUnit"
                      >
                        <a-select-option
                          v-for="(item, index) in unitList"
                          :key="index"
                          :value="item"
                        >
                          {{ item }}
                        </a-select-option>
                      </a-select>
                    </a-input>
                  </a-form-item>
                </a-col>
              </template>
              <a-col>
                <a-form-item>
                  <span slot="label">
                    最小准备时间
                    <a-tooltip>
                      <template slot="title">
                        在Pod被视为可用之前，Pod中的容器需至少运行多长时间，默认为0
                      </template>
                      <a-icon type="question-circle" />
                    </a-tooltip>
                  </span>
                  <a-input v-model="updateSchedulingData.minimumReadyTime">
                    <div slot="addonAfter" style="width: 28px">秒</div>
                  </a-input>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="updateSchedulingShow = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            @click="updateSchedulingTemplateSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="修改其他选项"
      v-model="editOtherOptionsShow"
      :maskClosable="false"
      width="700px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item
          v-for="(value, key) in editOtherOptionsData"
          :key="key"
          :label="key + ':'"
        >
          <a-input
            v-model="editOtherOptionsData[key]"
            :placeholder="'请输入' + key"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="editOtherOptionsShow = false"
            >取消</a-button
          >
          <a-button key="submit" type="primary" @click="editOtherOptionsSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import * as pipelineApi from "../lib/pipeline.js";
import {
  listRepositoryBranches,
  listRepositoryCommits,
} from "../lib/projectList.js";
import { configMapList, pvcNameList, secretList } from "../lib/k8s.js";
import "../../node_modules/xterm/css/xterm.css";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import * as k8sApi from "../lib/k8s.js";
import moment from "moment";

export default {
  name: "pipeline",
  props: ["id"],
  data() {
    return {
      name: "",
      pipelineTaskId: 1,
      nodeMenuShow: false,
      stageList: [],
      menuNodeList: [],
      pipelineStageId: "",
      addStageOrder: 0,
      addNodeOrder: 0,
      editNodeShow: false,
      nodeName: "",
      nodeId: "",
      nodeState: "",
      nodeErrorMessage: "",
      nodeType: "",
      extraInfo: {},
      watchStageName: "",
      urlList: [],
      branchList: [],
      commitList: [],
      searchTimer: {},
      parameterList: [],
      active: "1",
      versionList: [],
      serverSelectList: [],
      hubUrlList: [],
      templateList: [],
      state: "",
      type: "",
      timer: null,
      title: "",
      groupId: "",
      addKeyVisible: false,
      updateData: {},
      loading: false,
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
        },
        {
          title: "参数key",
          dataIndex: "key",
        },
        {
          title: "参数值",
          dataIndex: "value",
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      loadingTable: false,
      parameterVisible: false,
      nameSpaceList: [],
      profileList: [],
      namespace: undefined,
      profile: undefined,
      logVisible: false,
      term: null,
      nodeLogTimer: null,
      minId: "",
      newVolumeColumns: [
        {
          title: "类型",
          dataIndex: "type",
          scopedSlots: { customRender: "type" },
          width: 120,
        },
        {
          title: "名称",
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
          width: 215,
        },
        {
          title: "挂载源",
          dataIndex: "sourceName",
          scopedSlots: { customRender: "sourceName" },
          width: 160,
        },
        {
          title: "容器路径",
          dataIndex: "mountPath",
          scopedSlots: { customRender: "mountPath" },
          width: 160,
        },
        {
          title: "子路径",
          dataIndex: "subPath",
          scopedSlots: { customRender: "subPath" },
          width: 150,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          width: 120,
          scopedSlots: { customRender: "action" },
        },
      ],
      templateContentShow: false,
      templateContent: "",
      templateId: "",
      templateName: "",
      options: {
        mode: "text/javascript",
        theme: "default",
        readOnly: false,
        lineNumbers: true,
        line: true,
        styleActiveLine: true, //设置光标所在行高亮，需引入工具包
        placeholder: "输入内容",
      },
      requestId: "",
      volumeTypeList: [],
      volumeNewTypeList: [
        { label: "主机目录", value: "HOST_PATH" },
        { label: "配置项", value: "CONFIG_MAP" },
        { label: "保密字典", value: "SECRET" },
        { label: "临时目录", value: "EMPTY_DIR" },
      ],
      volumeClaimTypeList: [{ label: "云存储", value: "PVC" }],
      configMapList: [],
      pvcNameList: [],
      secretList: [],
      nodeAffinityList: [],
      schedulingTemplateList: [],
      probeTemplateList: [],
      priorityList: [],
      ruleId: undefined,
      required: [],
      optional: [],
      updateNodeAffinityShow: false,
      nodeAffinityTableColumns: [
        {
          title: "标签名",
          dataIndex: "labelName",
          scopedSlots: { customRender: "labelName" },
        },
        {
          title: "操作符",
          dataIndex: "operator",
          scopedSlots: { customRender: "operator" },
          width: 100,
        },
        {
          title: "标签值",
          dataIndex: "labelValue",
          scopedSlots: { customRender: "labelValue" },
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 60,
        },
      ],
      operatorSelectList: ["In", "NotIn", "Exists", "DoesNotExist", "Gt", "Lt"],
      updateProbeData: {},
      localProbeData: {},
      updateProbeShow: false,
      probeFormList: [
        { type: "liveness", label: "存活检查" },
        { type: "readiness", label: "就绪检查" },
        { type: "startup", label: "启动探测" },
      ],
      updateSchedulingData: {},
      updateSchedulingShow: false,
      unitList: ["个", "%"],
      kubeSchedulingUpdateMethods: [],
      stageOrderList: [],
      nodeOrderList: [],
      editOtherOptionsData: {},
      volumeIndex: 0,
      editOtherOptionsShow: false,
    };
  },
  computed: {
    nodeMenuStyle() {
      return {
        transform: this.nodeMenuShow ? "" : "translate3d(100%, 0, 0)",
      };
    },
    editNodeStyle() {
      return {
        transform: this.editNodeShow ? "" : "translate3d(100%, 0, 0)",
      };
    },
    maskShowStyle() {
      return {
        opacity: this.editNodeShow || this.nodeMenuShow ? "1" : "",
        zIndex: this.editNodeShow || this.nodeMenuShow ? "999" : "",
      };
    },
    getExtraInfo() {
      return (type) => {
        let extraInfo = {};
        switch (type) {
          case "gitCloneNodeService":
            extraInfo = {
              inputType: "SELECT",
              projectId: undefined,
              projectName: undefined,
              url: undefined,
              branch: undefined,
              commitId: undefined,
            };
            break;
          case "mavenCompileNodeService":
            extraInfo = {
              workDir: "${gitWorkDir}/${projectName}",
              mavenVersion: "",
              compileCommand: "mvn clean install -U",
              targetDirPath: "target/*.jar",
            };
            break;
          case "npmCompileNodeService":
            extraInfo = {
              workDir: "",
              compileCommand: "",
              distDirPath: "",
            };
            break;
          case "trans2ServerNodeService":
            extraInfo = {
              sourcePath: "${pathOfService}",
              targetPath: "/opt/delivery/${projectName}/service",
              overrideFile: true,
              includeFilterPattern: "",
              excludeFilterPattern: "",
              serverList: [],
            };
            break;
          case "cmdExecuteNodeService":
            extraInfo = {
              command:
                "kubectl apply -f /opt/delivery/${projectName}/service/${nameOfService}",
              serverList: [],
            };
            break;
          case "dockerPushNodeService":
            extraInfo = {
              filePath: "${pathOfJar}",
              hubUrl: undefined,
              repository: "${projectName}",
              tag: "${shortCommitId}",
              hubProject: undefined,
              profile: "${profile}",
              templateCode: "",
            };
            break;
          case "dockerPullNodeService":
            extraInfo = {
              filePath: "",
              hubUrl: undefined,
              repository: "",
              tag: "",
              hubProject: undefined,
              profile: "",
            };
            break;
          case "generateDeploymentNewService":
            extraInfo = {
              replicas: "1",
              tag: "${shortCommitId}",
              projectName: "${projectName}-${profile}",
              requestCpu: "0.1",
              requestMemory: "512",
              requestEphemeralStorage: "512",
              limitCpu: "1",
              limitMemory: "1024",
              limitEphemeralStorage: "1024",
              localVolumeList: [],
              pvcVolumeList: [],
              nodeAffinityId: -1,
              healthProbeId: -1,
              strategyTemplateId: -1,
              readOnly: false,
              nonRoot: false,
              priorityClassName: undefined,
            };
            break;
          case "generateStatefulSetService":
            extraInfo = {
              replicas: "1",
              tag: "${shortCommitId}",
              projectName: "${projectName}-${profile}",
              requestCpu: "0.1",
              requestMemory: "512",
              requestEphemeralStorage: "512",
              limitCpu: "1",
              limitMemory: "1024",
              limitEphemeralStorage: "1024",
              localVolumeList: [],
              pvcVolumeList: [],
              nodeAffinityId: -1,
              healthProbeId: -1,
              strategyTemplateId: -1,
              readOnly: false,
              nonRoot: false,
              priorityClassName: undefined,
            };
            break;
          case "generateServiceService":
            extraInfo = {
              metaName: "${projectName}-${profile}",
              workDir: "${baseDir}/service",
              templateCode: undefined,
              namespace: "${namespace}",
            };
            break;
          case "generateIngressService":
            extraInfo = {
              metaName: "${projectName}-${profile}",
              workDir: "${baseDir}/ingress",
              templateCode: undefined,
              host: "demo.huiyu.org.cn",
              tlsSecretName: "huiyu.org.cn",
              namespace: "${namespace}",
            };
            break;
          case "deliveryLockNodeService":
            extraInfo = {
              hostnamePerfix: "",
            };
            break;
          case "deliveryUnlockNodeService":
            extraInfo = {
              hostnamePerfix: "",
            };
            break;
        }
        return extraInfo;
      };
    },
  },
  created() {
    let pipelineTaskId = this.$route.query.id;
    let requestId = this.$route.query.requestId;

    if (pipelineTaskId) {
      this.pipelineTaskId = pipelineTaskId;
    }
    if (requestId) {
      this.requestId = requestId;
    }
    this.getTaskDetail();
    this.getNodeTypeList();
    this.loadNamespaceList();
    this.loadProfileList();
  },
  mounted() {
    this.$dragging.$on("dragged", ({ value }) => {
      this.stageOrderList.splice(0);
      this.nodeOrderList.splice(0);
      if (value.group == "stageItem") {
        this.stageOrderList = value.list.map((item, index) => {
          return { id: item.id, order: index + 1 };
        });
      } else {
        this.nodeOrderList = value.list.map((item, index) => {
          return { id: item.id, order: index + 1 };
        });
      }
    });
  },
  methods: {
    editPipelineStageSort() {
      if (this.type == "TEMPLATE") {
        let data = {
          list: this.stageOrderList,
        };
        pipelineApi.editPipelineStageSort(data).then((res) => {
          if (res.result == 200) {
            this.$message.success("修改成功");
            this.stageOrderList.splice(0);
          }
        });
      }
    },
    editPipelineNodeSort() {
      if (this.type == "TEMPLATE") {
        let data = {
          list: this.nodeOrderList,
        };
        pipelineApi.editPipelineNodeSort(data).then((res) => {
          if (res.result == 200) {
            this.$message.success("修改成功");
            this.nodeOrderList.splice(0);
          }
        });
      }
    },
    getTemplateContentShow() {
      let template = this.templateList.filter((item) => {
        return this.extraInfo.templateCode == item.code;
      })[0];
      if (!template.content) {
        this.templateContent = "无";
      } else {
        this.templateContent = template.content;
      }
      this.templateName = template.name;
      this.templateId = template.id;
      this.templateContentShow = true;
    },
    updateTemplateContentSubmit() {
      let data = {
        id: this.templateId,
        content: this.templateContent,
      };
      pipelineApi.updatePipelineConfigTemplateContent(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("修改成功");
          this.templateContentShow = false;
          this.loadDynamicApiData("templateList");
        }
      });
    },
    nodeLog(val) {
      this.minId = "";
      this.nodeId = val.id;
      this.nodeState = val.state;
      this.nodeErrorMessage = val.errorMessage;
      this.logVisible = true;
      this.$nextTick(() => {
        this.term = new Terminal({
          theme: {
            background: "#008080", //背景色
            black: "#003440",
            blue: "#268ad2",
            brightBlack: "#00779a",
            brightBlue: "#109fd2",
            BrightCyan: "#00bdae",
            cyan: "#2aa197",
            brightGreen: "#5bee96",
            green: "#7cc67f",
            brightMagenta: "#e9679f",
            megenta: "#d33582",
            brightRed: "#f9314b",
            red: "#dc312e",
            brightWhite: "#fdf6e3",
            white: "#eee8d5",
            brightYellow: "#c08f34",
            yellow: "#b58900",
            foreground: "#f0f0f0", //字体
            selection: "#2c9aff66",
            cursor: "#f86100",
            cursorAccent: "#003440",
          },
        });
        const fitAddon = new FitAddon();
        this.term.loadAddon(fitAddon);
        this.term.open(document.getElementById("terminal"));
        fitAddon.fit();
        this.term.write("\x1b[2K\r");
        this.term.clear();
        this.getNodeLog();
      });
    },
    logClose() {
      this.term.dispose();
      if (this.nodeLogTimer) {
        clearInterval(this.nodeLogTimer);
        this.nodeLogTimer = null;
      }
    },
    autoRefresh() {
      this.nodeLogTimer = setInterval(() => {
        this.getNodeLog();
      }, 1000);
    },
    getNodeLog() {
      let data = {
        taskId: this.pipelineTaskId,
        nodeId: this.nodeId,
        minId: this.minId,
      };
      pipelineApi.nodeLogList(data).then((res) => {
        if (res.result === 200) {
          if (res.data.logs.length > 0) {
            this.minId = res.data.logs[res.data.logs.length - 1].id;
            res.data.logs.forEach((item) => {
              this.term.write(item.content + "\r\n");
            });
          }
          let next = true;
          this.stageList.forEach((item) => {
            item.nodeList.forEach((node) => {
              if (
                node.id == this.nodeId &&
                (node.state == "FINISHED" || node.state == "ERROR")
              ) {
                next = false;
              }
            });
          });
          if (next) {
            if (!this.nodeLogTimer) {
              this.autoRefresh();
            }
          } else {
            if (this.nodeLogTimer) {
              clearInterval(this.nodeLogTimer);
              this.nodeLogTimer = null;
            }
          }
        }
      });
    },
    loadNamespaceList() {
      pipelineApi.loadNamespaceList().then((res) => {
        if (res.result === 200) {
          this.nameSpaceList = res.data;
        }
      });
    },
    loadProfileList() {
      pipelineApi.loadProfileList().then((res) => {
        if (res.result === 200) {
          this.profileList = res.data;
          this.queryParameter();
        }
      });
    },
    updateGlobalParameters(key) {
      let data = {
        taskId: this.pipelineTaskId,
        key,
        value: key == "namespace" ? this.namespace : this.profile,
      };
      pipelineApi.updateGlobalParameters(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("优化成功");
          this.getTaskDetail();
        }
      });
    },
    // 点击查询
    queryParameter(visible) {
      let data = {
        taskId: this.pipelineTaskId,
      };
      this.loadingTable = true;
      pipelineApi
        .listPipelineGlobalParameter(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource = res.data;
            if (visible) {
              this.parameterVisible = true;
            } else if (!this.parameterVisible) {
              this.tableDataSource.forEach((item) => {
                if (item.key == "namespace") {
                  this.namespace = item.value;
                }
                if (item.key == "profile") {
                  this.profile = item.value;
                }
              });
            }
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.addKeyVisible = true;
      this.groupId = val.id;
      this.updateData = {
        taskId: this.pipelineTaskId,
        key: val.key,
        value: val.value,
        remark: val.remark,
        editable: val.editable,
      };
    },
    // 新增
    add() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.updateData = {
        taskId: this.pipelineTaskId,
        key: "",
        value: "",
        remark: "",
      };
    },
    // 确定新增
    add_submit() {
      let data = { ...this.updateData };
      delete data.editable;
      if (this.title === "新增") {
        pipelineApi.addPipelineGlobalParameter(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.queryParameter();
          }
        });
      } else {
        data.id = this.groupId;
        pipelineApi.editPipelineGlobalParameter(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.queryParameter();
          }
        });
      }
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      pipelineApi.deletePipelineGlobalParameter(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.queryParameter();
        }
      });
    },
    restart() {
      pipelineApi
        .restartTask({ taskId: this.pipelineTaskId, requestId: this.requestId })
        .then((res) => {
          if (res.result == 200) {
            this.$message.success("执行成功");
            this.getTaskDetail();
          }
        });
    },
    start() {
      pipelineApi.startTask({ id: this.pipelineTaskId }).then((res) => {
        if (res.result == 200) {
          this.$message.success("启动成功");
          this.getTaskDetail();
        }
      });
    },
    stop() {
      pipelineApi.stopTask({ id: this.pipelineTaskId }).then((res) => {
        if (res.result == 200) {
          this.$message.success("停止成功");
          this.getTaskDetail();
        }
      });
    },
    getTaskDetail() {
      pipelineApi.taskDetail({ id: this.pipelineTaskId }).then((res) => {
        if (res.result == 200) {
          this.stageList = res.data.stageList;
          this.name = res.data.name;
          this.state = res.data.state;
          this.type = res.data.type;
          this.stageList.forEach((item) => {
            this.$set(item, "editStageNameShow", false);
            item.nodeList.forEach((node) => {
              if (node.extraInfo) {
                this.$set(node, "extraInfo", JSON.parse(node.extraInfo));
              } else {
                this.$set(node, "extraInfo", this.getExtraInfo(node.type));
              }
              if (node.endAt) {
                this.$set(
                  node,
                  "time",
                  this.calculateTimeDifference(node.startAt, node.endAt) + "秒"
                );
              } else {
                this.$set(node, "time", "0秒");
              }

              if (this.logVisible && this.nodeId == node.id) {
                this.nodeState = node.state;
                this.nodeErrorMessage = node.errorMessage;
              }
            });
          });
          if (this.state == "RUNNING" && !this.timer) {
            this.timer = setInterval(() => {
              this.getTaskDetail();
            }, 2000);
          }
          if (this.state !== "RUNNING" && this.timer) {
            clearInterval(this.timer);
            this.timer = null;
            if (this.$route.path == "/postingDetails") {
              this.$emit("refreshStatus", null, true);
            }
          }
        }
      });
    },
    calculateTimeDifference(startAt, endAt) {
      endAt = new Date(endAt);
      startAt = new Date(startAt);

      // 计算差值（毫秒）
      var timeDifference = endAt - startAt;
      var seconds = Math.floor(timeDifference / 1000);

      return seconds;
    },
    getNodeTypeList() {
      pipelineApi.nodeTypeList().then((res) => {
        if (res.result == 200) {
          this.menuNodeList = res.data;
        }
      });
    },
    addStage(order) {
      this.nodeMenuShow = true;
      this.pipelineStageId = "";
      this.addStageOrder = order;
    },
    addNode(item) {
      this.nodeMenuShow = true;
      this.addNodeOrder = item.nodeList[item.nodeList.length - 1].order + 1;
      this.pipelineStageId = item.id;
    },
    addSubmit(item) {
      let data = {
        name: item.name,
        type: item.type,
        extraInfo: "",
        order: this.addNodeOrder,
      };
      data.extraInfo = JSON.stringify(this.getExtraInfo(item.type));
      if (this.pipelineStageId) {
        data.pipelineStageId = this.pipelineStageId;
        this.addNodeSubmit(data);
      } else {
        let stageData = {
          pipelineTaskId: this.pipelineTaskId,
          name: item.category,
          order: this.addStageOrder,
        };
        pipelineApi.addPipelineStage(stageData).then((res) => {
          if (res.result == 200) {
            data.pipelineStageId = res.data.id;
            this.addNodeSubmit(data);
          }
        });
      }
    },
    addNodeSubmit(data) {
      pipelineApi.addPipelineNode(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("添加成功");
          this.getTaskDetail();
          this.nodeMenuShow = false;
        }
      });
    },
    resetState(node) {
      let nodeIdList = [];
      nodeIdList.push(node.id);
      let current = -1;
      this.stageList.forEach((item, index) => {
        if (current > -1) {
          item.nodeList.forEach((nodeItem) => {
            if (nodeItem.state == "FINISHED" || nodeItem.state == "ERROR") {
              nodeIdList.push(nodeItem.id);
            }
          });
        }
        if (item.id == node.pipelineStageId) {
          current = index;
        }
      });

      let data = {
        taskId: this.pipelineTaskId,
        nodeIds: nodeIdList.join(","),
      };
      pipelineApi.resetNodeState(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("重置成功");
          this.getTaskDetail();
        }
      });
    },
    livenessProbeEnableChange() {
      if (this.extraInfo.livenessProbe.enable) {
        this.$set(this.extraInfo, "livenessProbe", {
          enable: true,
          path: "/api/actuator/health/liveness",
          port: "8080",
        });
      } else {
        this.$set(this.extraInfo, "livenessProbe", {
          enable: false,
          path: "",
          port: "",
        });
      }
    },
    readinessProbeEnableChange() {
      if (this.extraInfo.readinessProbe.enable) {
        this.$set(this.extraInfo, "readinessProbe", {
          enable: true,
          path: "/api/actuator/health/readiness",
          port: "8080",
        });
      } else {
        this.$set(this.extraInfo, "readinessProbe", {
          enable: false,
          path: "",
          port: "",
        });
      }
    },
    addVolume() {
      this.extraInfo.volumeList.push({
        type: "PVC",
        pvName: "",
        pvMountPath: "",
        pvcName: "",
      });
    },
    addNewVolume() {
      this.extraInfo.localVolumeList.push({
        type: "HOST_PATH",
        name:
          "volume-" +
          moment().format("YYMMDDHHmmss") +
          this.getRandomInt(0, 99).toString().padStart(2, "0"),
        mountPath: "",
        sourceName: "",
        subPath: "",
        defaultMode: 420,
      });
    },
    addClaimVolume() {
      this.extraInfo.pvcVolumeList.push({
        type: "PVC",
        name:
          "volume-" +
          moment().format("YYMMDDHHmmss") +
          this.getRandomInt(0, 99).toString().padStart(2, "0"),
        mountPath: "",
        sourceName: undefined,
        subPath: "",
      });
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    deleteVolume(index) {
      this.extraInfo.volumeList.splice(index, 1);
    },
    editOtherOptions(index) {
      this.volumeIndex = index;
      this.editOtherOptionsData = {
        defaultMode: this.extraInfo.localVolumeList[index].defaultMode,
      };
      this.editOtherOptionsShow = true;
    },
    editOtherOptionsSubmit() {
      for (const key in this.editOtherOptionsData) {
        const value = this.editOtherOptionsData[key];
        this.$set(this.extraInfo.localVolumeList[this.volumeIndex], key, value);
      }
      this.$message.success("修改成功");
      this.editOtherOptionsShow = false;
    },
    deleteNewVolume(index) {
      this.extraInfo.localVolumeList.splice(index, 1);
    },
    deleteClaimVolume(index) {
      this.extraInfo.pvcVolumeList.splice(index, 1);
    },
    volumeTypeChange(type, index) {
      let data = {
        namespace: this.namespace,
      };
      if (
        (this.nodeType == "generateDeploymentNewService" ||
          this.nodeType == "generateStatefulSetService") &&
        index !== undefined
      ) {
        this.$set(this.extraInfo.localVolumeList[index], "sourceName", "");
      }
      if (type == "CONFIG_MAP") {
        configMapList(data).then((res) => {
          if (res.result === 200) {
            this.configMapList = res.data;
          }
        });
      } else if (type == "SECRET") {
        secretList(data).then((res) => {
          if (res.result === 200) {
            this.secretList = res.data;
          }
        });
      } else if (type == "PVC") {
        pvcNameList(data).then((res) => {
          if (res.result === 200) {
            this.pvcNameList = res.data;
          }
        });
      }
    },
    editNode(node) {
      this.nodeName = node.name;
      this.nodeId = node.id;
      this.nodeType = node.type;
      this.active = "1";
      this.pipelineStageId = node.pipelineStageId;
      this.extraInfo = { ...node.extraInfo };
      this.parameterList.splice(0);
      this.loadDynamicApiData("parameterList");
      if (
        this.nodeType == "generateDeploymentNewService" ||
        this.nodeType == "generateStatefulSetService"
      ) {
        this.loadDynamicApiData("probeTemplateList");
        this.getSchedulingTemplateList();
        this.getListPriorityClass();
        this.loadDynamicApiData("nodeAffinity");
        this.volumeTypeChange("PVC");
      }

      if (
        this.nodeType == "gitCloneNodeService" &&
        this.extraInfo.inputType == "SELECT"
      ) {
        this.loadDynamicApiData("urlList");
      }
      if (this.nodeType == "mavenCompileNodeService") {
        this.loadDynamicApiData("versionList");
      }
      if (
        this.nodeType == "trans2ServerNodeService" ||
        this.nodeType == "cmdExecuteNodeService"
      ) {
        this.loadDynamicApiData("server");
      }
      if (this.nodeType == "dockerPushNodeService") {
        this.loadDynamicApiData("hubList");
      }
      if (
        this.nodeType == "generateServiceService" ||
        this.nodeType == "generateIngressService" ||
        this.nodeType == "dockerPushNodeService"
      ) {
        this.loadDynamicApiData("templateList");
      }
      this.editNodeShow = true;
    },
    loadDynamicApiData(api, name) {
      let data = {
        action: this.nodeType,
        api,
      };
      if (api == "urlList" || api == "server") {
        data.name = name;
      }
      pipelineApi.loadDynamicApiData(data).then((res) => {
        if (res.result == 200) {
          switch (api) {
            case "urlList":
              this.urlList.splice(0);
              this.urlList.push(...res.data);
              if (!this.extraInfo.projectId) {
                this.$set(
                  this.extraInfo,
                  "projectId",
                  this.urlList[0].projectId
                );
              }
              this.getBranchList(this.extraInfo.projectId);
              break;
            case "parameterList":
              this.parameterList.push(...res.data);
              this.parameterList.forEach((item) => {
                this.$set(item, "value", item.default);
              });
              break;
            case "versionList":
              this.versionList.splice(0);
              this.versionList.push(...res.data);
              if (!this.extraInfo.mavenVersion) {
                this.$set(
                  this.extraInfo,
                  "mavenVersion",
                  this.versionList[0].value
                );
              }
              break;
            case "server":
              this.serverSelectList.splice(0);
              this.serverSelectList.push(...res.data);
              break;
            case "hubList":
              this.hubUrlList.splice(0);
              this.hubUrlList.push(...res.data);
              if (!this.extraInfo.hubUrl) {
                this.$set(this.extraInfo, "hubUrl", this.hubUrlList[0]);
              }
              break;
            case "templateList":
              this.templateList.splice(0);
              this.templateList.push(...res.data);
              if (!this.extraInfo.templateCode) {
                this.$set(
                  this.extraInfo,
                  "templateCode",
                  this.templateList[0].code
                );
              }
              break;
            case "volumeType":
              this.volumeTypeList.splice(0);
              this.volumeTypeList.push(...res.data);
              break;
            case "nodeAffinity":
              this.nodeAffinityList.splice(0);
              this.nodeAffinityList.push(...res.data);
              this.nodeAffinityList.unshift({ id: -1, name: "无" });
              if (!this.extraInfo.nodeAffinityId) {
                this.$set(this.extraInfo, "nodeAffinityId", -1);
              }
              break;
            case "probeTemplateList":
              this.probeTemplateList.splice(0);
              this.probeTemplateList.push(...res.data);
              this.probeTemplateList.unshift({ id: -1, name: "无" });
              if (!this.extraInfo.healthProbeId) {
                this.$set(this.extraInfo, "healthProbeId", -1);
              }
              break;
          }
        }
      });
    },
    addRequired() {
      this.required.push({
        required: true,
        itemList: [{ labelName: "", operator: "", labelValue: "" }],
      });
    },
    deleteRequired(index) {
      this.required.splice(index, 1);
    },
    addRequiredItem(index) {
      this.required[index].itemList.push({
        labelName: "",
        operator: "In",
        labelValue: "",
      });
    },
    deleteRequiredItem(index, itemIndex) {
      this.required[index].itemList.splice(itemIndex, 1);
    },
    addOptional() {
      this.optional.push({
        required: false,
        weight: "",
        itemList: [{ labelName: "", operator: "", labelValue: "" }],
      });
    },
    deleteOptional(index) {
      this.optional.splice(index, 1);
    },
    addOptionalItem(index) {
      this.optional[index].itemList.push({
        labelName: "",
        labelValue: "",
        operator: "In",
      });
    },
    deleteOptionalItem(index, itemIndex) {
      this.optional[index].itemList.splice(itemIndex, 1);
    },
    getSchedulingTemplateList() {
      let data = {
        pageNo: 1,
        pageSize: 100,
      };
      k8sApi.schedulingTemplatePage(data).then((res) => {
        if (res.result === 200) {
          this.schedulingTemplateList = res.data.records;
          this.schedulingTemplateList.unshift({ id: -1, name: "无" });
          if (!this.extraInfo.strategyTemplateId) {
            this.$set(this.extraInfo, "strategyTemplateId", -1);
          }
        }
      });
    },
    getListPriorityClass() {
      k8sApi.listPriorityClass().then((res) => {
        if (res.result === 200) {
          this.priorityList = res.data;
          this.priorityList.sort((a, b) => {
            return a.value - b.value;
          });
          let priorityClassName = "";
          this.priorityList.forEach((item) => {
            if (item.globalDefault == true) {
              priorityClassName = item.name;
            }
          });
          if (!this.extraInfo.priorityClassName && priorityClassName) {
            this.$set(this.extraInfo, "priorityClassName", priorityClassName);
          }
        }
      });
    },
    updateSchedulingTemplate(id) {
      let item = this.schedulingTemplateList.filter((item) => {
        return item.id == id;
      })[0];
      this.updateSchedulingData = {
        id: item.id,
        name: item.name,
        updateMethod: item.updateMethod,
        maxUnavailable: item.maxUnavailable,
        maxUnavailableUnit: item.maxUnavailableUnit,
        maxSurge: item.maxSurge,
        maxSurgeUnit: item.maxSurgeUnit,
        minimumReadyTime: item.minimumReadyTime,
      };
      this.getKubeSchedulingUpdateMethodEnum();
    },
    getKubeSchedulingUpdateMethodEnum() {
      k8sApi.kubeSchedulingUpdateMethodEnum().then((res) => {
        if (res.result == 200) {
          this.kubeSchedulingUpdateMethods.splice(0);
          for (const label in res.data) {
            const value = res.data[label];
            this.kubeSchedulingUpdateMethods.unshift({ label, value });
          }
          this.updateSchedulingShow = true;
        }
      });
    },
    updateSchedulingTemplateSubmit() {
      let data = { ...this.updateSchedulingData };
      k8sApi.editSchedulingTemplate(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("修改成功");
          this.updateSchedulingShow = false;
          this.getSchedulingTemplateList();
        }
      });
    },
    updateProbe(id) {
      let data = {
        id,
      };
      k8sApi.templateDetail(data).then((res) => {
        if (res.result === 200) {
          this.updateProbeData = {
            id,
            name: res.data.name,
            remark: res.data.remark,
            enabled: res.data.enabled,
            livenessProbeEnabled: res.data.livenessProbe ? true : false,
            livenessProbe: {},
            readinessProbeEnabled: res.data.readinessProbe ? true : false,
            readinessProbe: {},
            startupProbeEnabled: res.data.startupProbe ? true : false,
            startupProbe: {},
          };
          this.probeEnabledChange(
            this.updateProbeData.livenessProbeEnabled,
            "liveness",
            res.data
          );
          this.probeEnabledChange(
            this.updateProbeData.readinessProbeEnabled,
            "readiness",
            res.data
          );
          this.probeEnabledChange(
            this.updateProbeData.startupProbeEnabled,
            "startup",
            res.data
          );
          this.localProbeData = res.data;
          this.updateProbeShow = true;
        }
      });
    },
    probeEnabledChange(enabled, type, val) {
      let probe = {};
      if (enabled) {
        if (val && val[type + "Probe"]) {
          switch (type) {
            case "liveness":
              probe = val.livenessProbe;
              probe.extraInfo = JSON.parse(probe.extraInfo);
              break;
            case "readiness":
              probe = val.readinessProbe;
              probe.extraInfo = JSON.parse(probe.extraInfo);
              break;
            case "startup":
              probe = val.startupProbe;
              probe.extraInfo = JSON.parse(probe.extraInfo);
              break;
          }
        } else {
          probe = {
            requestType: "HTTP",
            initialDelaySeconds: 15,
            periodSeconds: 10,
            timeoutSeconds: 1,
            successThreshold: 1,
            failureThreshold: 3,
            extraInfo: {
              path: "",
              port: "",
            },
          };
        }
      }
      switch (type) {
        case "liveness":
          this.$set(this.updateProbeData, "livenessProbe", probe);
          break;
        case "readiness":
          this.$set(this.updateProbeData, "readinessProbe", probe);
          break;
        case "startup":
          this.$set(this.updateProbeData, "startupProbe", probe);
          break;
      }
    },
    tabClick(key, probe) {
      switch (key) {
        case "HTTP":
          this.$set(probe, "extraInfo", {
            path: "",
            port: "",
          });
          break;
        case "TCP":
          this.$set(probe, "extraInfo", {
            port: "",
          });
          break;
        case "SHELL":
          this.$set(probe, "extraInfo", {
            command: "",
          });
          break;
      }
    },
    updateProbeSubmit() {
      let data = { ...this.updateProbeData };
      if (data.livenessProbe) {
        data.livenessProbe.extraInfo = JSON.stringify(
          data.livenessProbe.extraInfo
        );
      } else {
        delete data.livenessProbe;
      }
      if (data.readinessProbe) {
        data.readinessProbe.extraInfo = JSON.stringify(
          data.readinessProbe.extraInfo
        );
      } else {
        delete data.readinessProbe;
      }
      if (data.startupProbe) {
        data.startupProbe.extraInfo = JSON.stringify(
          data.startupProbe.extraInfo
        );
      } else {
        delete data.startupProbe;
      }

      delete data.livenessProbeEnabled;
      delete data.readinessProbeEnabled;
      delete data.startupProbeEnabled;
      k8sApi.editTemplate(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("修改成功");
          this.updateProbeShow = false;
        }
      });
    },
    updateNodeAffinity(id) {
      let data = {
        id,
      };
      k8sApi.nodeAffinityList(data).then((res) => {
        if (res.result === 200) {
          this.ruleId = id;
          this.required = res.data.required;
          this.optional = res.data.optional;
          this.updateNodeAffinityShow = true;
        }
      });
    },
    updateNodeAffinitySubmit() {
      let data = {
        id: this.ruleId,
        required: this.required,
        optional: this.optional,
      };
      k8sApi.editNodeAffinity(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("修改成功");
          this.updateNodeAffinityShow = false;
        }
      });
    },
    invokeDynamicApi(hostnamePerfix) {
      let data = {
        action: "deliveryLockNodeService",
        api: "forceDelivery",
        hostnamePerfix,
        taskId: this.pipelineTaskId,
      };

      pipelineApi.invokeDynamicApi(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("强制发布成功");
        }
      });
    },
    searchUrlList(name) {
      if (this.searchTimer[this.nodeType]) {
        clearTimeout(this.searchTimer[this.nodeType]);
        this.$set(this.searchTimer, this.nodeType, null);
      }
      this.searchTimer[this.nodeType] = setTimeout(() => {
        this.loadDynamicApiData("urlList", name);
      }, 300);
    },
    searchServerSelectList(name) {
      if (this.searchTimer[this.nodeType]) {
        clearTimeout(this.searchTimer[this.nodeType]);
        this.$set(this.searchTimer, this.nodeType, null);
      }
      this.searchTimer[this.nodeType] = setTimeout(() => {
        this.loadDynamicApiData("server", name);
      }, 300);
    },
    editNodeSubmit() {
      if (
        this.nodeType == "generateDeploymentNewService" ||
        this.nodeType == "generateStatefulSetService"
      ) {
        let arr1 = this.extraInfo.localVolumeList.filter((item) => {
          return !item.sourceName || !item.mountPath;
        });
        let arr2 = this.extraInfo.pvcVolumeList.filter((item) => {
          return !item.sourceName || !item.mountPath;
        });
        if (arr1.length > 0 || arr2.length > 0) {
          this.$message.warning("请填写存储数据");
          return;
        }
      }
      let data = {
        id: this.nodeId,
        pipelineStageId: this.pipelineStageId,
        name: this.nodeName,
        extraInfo: JSON.stringify(this.extraInfo),
      };
      pipelineApi.editPipelineNode(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("修改成功");
          this.getTaskDetail();
          this.editNodeShow = false;
        }
      });
    },
    unEditStage(item) {
      this.$set(item, "editStageNameShow", false);
      this.$set(item, "name", this.watchStageName);
    },
    editStage(item) {
      this.stageList.forEach((stage) => {
        if (stage.editStageNameShow) {
          this.$set(stage, "editStageNameShow", false);
          this.$set(item, "name", this.watchStageName);
        }
      });
      this.$set(item, "editStageNameShow", true);
      this.watchStageName = item.name;
    },
    editStageSubmit(item) {
      let data = {
        id: item.id,
        name: item.name,
        order: item.order,
        pipelineTaskId: this.pipelineTaskId,
      };
      pipelineApi.editPipelineStage(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("修改成功");
          this.getTaskDetail();
        }
      });
    },
    deleteNode() {
      this.$confirm({
        title: "确认删除",
        content: (h) => (
          <div style="color:red;">确定要删除节点{this.nodeName}吗?</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          pipelineApi.deletePipelineNode({ id: this.nodeId }).then((res) => {
            if (res.result == 200) {
              let nodeList = this.stageList.filter((item) => {
                return this.pipelineStageId == item.id;
              })[0].nodeList;
              if (nodeList.length - 1 == 0) {
                this.deleteStageSubmit();
              } else {
                this.$message.success("删除成功");
                this.getTaskDetail();
                this.editNodeShow = false;
              }
            }
          });
        },
        onCancel: () => {},
      });
    },
    deleteStage(item) {
      this.$confirm({
        title: "确认删除",
        content: (h) => <div style="color:red;">确定要删除该阶段吗?</div>,
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.pipelineStageId = item.id;
          this.deleteStageSubmit();
        },
        onCancel: () => {},
      });
    },
    deleteStageSubmit() {
      pipelineApi
        .deletePipelineStage({ id: this.pipelineStageId })
        .then((res) => {
          if (res.result == 200) {
            this.$message.success("删除成功");
            this.getTaskDetail();
            this.editNodeShow = false;
          }
        });
    },
    projectIdChange(projectId) {
      this.$set(this.extraInfo, "branch", undefined);
      this.$set(this.extraInfo, "commitId", undefined);
      this.getBranchList(projectId);
    },
    getBranchList(projectId) {
      let project = this.urlList.filter((item) => {
        return projectId == item.projectId;
      })[0];
      this.$set(this.extraInfo, "url", project.url);
      this.$set(this.extraInfo, "projectName", project.name);
      listRepositoryBranches({ projectId }).then((res) => {
        if (res.result === 200) {
          this.branchList = res.data.map((item) => {
            return item.branchName;
          });
          if (this.extraInfo.branch) {
            this.getCommitList(this.extraInfo.branch);
          }
        }
      });
    },
    branchChange(branchName) {
      this.$set(this.extraInfo, "commitId", undefined);
      this.getCommitList(branchName);
    },
    getCommitList(branchName) {
      listRepositoryCommits({
        projectId: this.extraInfo.projectId,
        branchName,
      }).then((res) => {
        if (res.result === 200) {
          this.commitList = res.data;
        }
      });
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.pipeline {
  width: 100%;
  height: 100%;

  .pipeline_header {
    height: 56px;
    padding: 0 21px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e9edf0;
    background: #fff;
    .title {
      color: black;
      font-weight: 800;
      width: calc(100% - 450px);
      .name {
        display: inline-block;
        max-width: calc(100% - 30px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .buttonList {
      margin-left: auto;
      .select {
        display: inline-block;
        margin-right: 20px;
        .ant-select {
          width: 100px;
        }
      }
      .button {
        font-size: 20px !important;
      }
    }
  }

  .pipeline_content {
    width: 100%;
    height: calc(100% - 56px);
    overflow: hidden;
    display: flex;
    overflow-x: auto;
    background: #f2f5f7;

    .pipeline_flow_sidebar {
      width: 300px;
      height: 100%;
      overflow-y: auto;
      padding: 19px;

      .pipeline_flow_sidebar_header {
        font-size: 14px;
        color: #8b8b8b;
      }
    }
    .pipeline_flow_content {
      height: 100%;
      transition: all 0.2s ease-in-out;
      position: relative;
      white-space: nowrap;

      .flow_groups {
        height: 100%;
        position: relative;
        white-space: nowrap;

        .flow_group_splitline {
          width: 48px;
          height: 100%;
          padding: 0 1px;
          position: relative;
          text-align: center;
          display: inline-block;

          .last_splitline {
            &:after {
              margin-left: 0;
              width: 24px !important;
            }
          }
          .detail_group_splitline_button {
            &:after {
              top: 125.5px !important;
            }
          }

          .flow_group_splitline_button {
            height: 100%;
            font-size: 20px;
            position: relative;

            &:before {
              border-left: 1px solid #dbdbdb;
              content: "";
              height: 100%;
              position: absolute;
            }

            &:after {
              border-top: 1px solid #dbdbdb;
              content: "";
              margin-left: -24px;
              position: absolute;
              top: 105.5px;
              width: 48px;
            }

            i {
              cursor: pointer;
              color: #595959;
              margin-left: -10px;
              position: absolute;
              top: 96px;
              z-index: 2;

              &:hover {
                color: #006ad4;
              }
            }
          }
        }

        .template_flow_group {
          &:hover {
            .button {
              display: inline-block !important;
            }

            .add_stage_container {
              display: block !important;
              &:before {
                border-radius: 0 0 16px 16px;
              }
            }

            .stage_container {
              &:nth-last-child(2):before {
                border-radius: 0 !important;
              }
            }
          }
        }

        .flow_group {
          width: 300px;
          display: inline-block;
          height: 100%;
          overflow-y: auto;
          padding-top: 19px;
          display: inline-block;

          .group_head {
            margin-bottom: 38px;
            padding: 0 20px;
            color: #8b8b8b;
            height: 20px;
            display: flex;

            .teamix_title {
              margin-right: 8px;
            }

            .button {
              height: 20px;
              line-height: 20px;
              color: #575757;
              display: none;
              &:hover {
                color: #006ad4;
              }
            }
          }
          .stages {
            .stage_container {
              position: relative;

              &:first-child:before {
                border-left: none;
                border-radius: 0 !important;
                border-right: none;
              }

              &:nth-last-child(2):before {
                border-radius: 0 0 16px 16px;
              }

              &:before {
                border-bottom: 1px solid #dbdbdb;
                border-left: 1px solid #dbdbdb;
                border-right: 1px solid #dbdbdb;
                content: "";
                height: 100%;
                left: 0;
                position: absolute;
                top: -50%;
                transition: border 0.2s ease-in;
                width: 100%;
              }

              &:hover {
                &:before {
                  border-color: #006ad4;
                }

                .node_content {
                  border-color: #006ad4 !important;
                }

                .node_plus {
                  border-color: #006ad4 !important;
                  background: #ffffff !important;
                }
              }

              .tasks_container {
                display: flex;
                margin: 0 14px;

                .node {
                  position: relative;
                  margin: 10px auto;
                  transition: all 0.2s ease-in-out;
                  z-index: 1;

                  .node_content {
                    box-shadow: 0 2px 4px 0 rgba(38, 38, 38, 0.1);
                    min-width: 130px;
                    padding: 0 12px;
                    background: #fff;
                    border: 1px solid #e9edf0;
                    border-radius: 20px;
                    cursor: pointer;
                    height: 40px;
                    display: flex;
                    flex-wrap: wrap;

                    .node_content_top {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      .node_name {
                        color: #292929;
                        display: inline-block;
                        width: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        text-align: center;
                      }
                    }
                  }

                  .detail_node_content {
                    border-radius: 5px !important;
                    width: 200px;
                    height: 80px !important;

                    .node_content_top {
                      .node_name {
                        font-weight: 500;
                        text-align: left;
                      }

                      .node_state {
                        margin-left: auto;
                      }
                    }

                    .node_content_bottom {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      .node_time {
                        color: #b5b5b5;
                      }

                      .node_log {
                        margin-left: auto;
                      }
                    }
                  }
                }
              }
            }

            .add_stage_container {
              display: none;

              .node_plus {
                background: #f2f5f7 !important;
                border: 1px solid #adadad;
                border-radius: 20px;
                box-shadow: 0 2px 4px 0 rgba(38, 38, 38, 0.1);
                cursor: pointer;
                height: 40px;
                text-align: center;
                width: 127px;
                padding: 0 12px;
                display: flex;
                flex-wrap: wrap;

                &:hover {
                  border-color: #006ad4 !important;
                  background: #ffffff !important;
                  .node_content_top i {
                    color: #006ad4 !important;
                  }
                }

                .node_content_top {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  i {
                    color: #8b8b8b;
                    font-size: 20px;
                    display: inline-block;
                    line-height: 0;
                    text-align: center;
                    vertical-align: -0.25em;
                  }

                  .node_name {
                    margin-left: 8px;
                    color: #292929;
                    display: inline-block;
                  }
                }
              }
            }
          }
        }
        .flow_group_create {
          margin-right: 20px;

          .stages {
            .add_stage_container {
              display: inline-block !important;

              &:before {
                width: 50%;
              }

              &:hover {
                &:before {
                  border-color: #dbdbdb !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .drawer {
    position: fixed;
    right: 0px;
    width: 850px;
    height: calc(100% - 56px);
    background: #fff;
    top: 56px;
    transition: all 0.35s ease 0s;
    z-index: 100;

    .drawer_content {
      position: relative;
      height: 100%;
      background: #fff;
      border-left: 1px solid #dae0e5;
      z-index: 1000;

      .drawer_content_head {
        display: flex;
        height: 54px;
        padding: 12px 16px;

        .title {
          font-weight: 500;
          .button {
            font-size: 16px;
            &:hover {
              color: #e62412;
            }
          }
        }
        .button {
          font-size: 20px;
          height: 100%;
          color: #575757;
          margin-left: auto;
          &:hover {
            color: #006ad4;
          }
        }
      }

      .drawer_content_body {
        border-top: 1px solid #e9edf0;
        height: calc(100% - 108px);
        overflow-y: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
        .menu_content {
          max-height: calc(100vh - 54px);
          display: flex;
          overflow: auto;
          padding: 16px 20px;
          flex-wrap: wrap;

          .menu_node {
            align-items: center;
            border: 1px solid #e9edf0;
            border-radius: 4px;
            cursor: pointer;
            display: inline-flex;
            height: 72px;
            margin-bottom: 12px;
            width: 32%;
            margin-right: 7px;
            margin-bottom: 7px;

            &:hover {
              box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.06);
            }

            .menu_node_left {
              align-items: center;
              background-color: #f2f5f7;
              border: 1px solid #f7f7f7;
              border-radius: 4px;
              display: inline-flex;
              height: 38px;
              justify-content: center;
              margin-left: 12px;
              width: 38px;
            }

            .menu_node_right {
              display: inline-block;
              margin-left: 12px;
              vertical-align: top;
              width: calc(100% - 62px);

              .name,
              .desc {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-break: break-all;
              }

              .name {
                color: #292929;
                font-weight: 500;
                margin-bottom: 3px;
              }

              .desc {
                color: #8b8b8b;
                font-size: 12px;
              }
            }
          }
        }

        .edit_node_form {
          height: calc(100vh - 164px);
          padding: 0 20px;

          &::v-deep .ant-form {
            height: 100%;
            .ant-tabs {
              height: 100%;
              .ant-tabs-bar {
                margin-bottom: 0 !important;
              }
              .ant-tabs-content {
                height: calc(100% - 44px);
                .ant-tabs-tabpane {
                  padding-top: 20px;
                  overflow-y: scroll;
                }
              }
            }
          }
        }
      }
      .drawer_content_footer {
        position: relative;
        background: #fff;
        border-top: 1px solid #e9edf0;
        width: 100%;
        height: 54px;
        padding: 12px 16px;
        z-index: 1;
      }
    }

    .mask {
      position: fixed;
      top: 56px;
      right: 0;
      left: 0;
      bottom: 0;
      opacity: 0;
      background: rgba(38, 38, 38, 0.24);
      height: 100%;
      width: 100%;
      z-index: -999;
      transition: all 0.7s ease-in;
    }
  }
}
.item {
  background-color: #f5f6fa;
  padding: 10px;
  margin-top: 10px;
  position: relative;
  .close {
    color: red;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .ant-table-wrapper {
    background-color: #fff !important;
  }
}
</style>

<style lang="scss" scoped>
.probeForm {
  width: 80%;
  margin-left: 20%;
}
.code {
  height: 100%;
  &:deep() .CodeMirror {
    height: 100% !important;
  }
}
</style>
