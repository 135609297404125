<template>
  <div class="warningConfigPage">
    <div class="searchCondition">
      <div class="searchConditionItem">
        告警组：
        <a-select
          v-model="groupId"
          placeholder="请选择告警组"
          showSearch
          allowClear
        >
          <a-select-option v-for="item in categoryList" :key="item.id">{{
            item.value
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addClick" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="enabled" slot-scope="text, record">
        <a-switch
          checked-children="已启用"
          un-checked-children="已禁用"
          v-model="record.enabled"
          @change="modifyClick(record, true)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.code }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="组名:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-select
            v-model="groupIdAdd"
            placeholder="请选择告警组"
            :filter-option="false"
            allowClear
          >
            <a-select-option v-for="item in categoryList" :key="item.id">{{
              item.value
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="编号:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input
            v-model="code"
            placeholder="请输入编号"
            :disabled="title == '修改'"
          />
        </a-form-item>
        <template v-for="item in configList">
          <a-form-item
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 16 }"
            required
          >
            <span slot="label">
              {{ item.label }}
              <a-tooltip v-if="item.remark">
                <template slot="title"> {{ item.remark }} </template>
                <a-icon type="question-circle" />
              </a-tooltip>
            </span>
            <a-input v-model="item.value" placeholder="请输入编号" />
          </a-form-item>
        </template>
        <a-form-item
          label="是否启用:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            default-checked
            v-model="enabled"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/warningConfigPage.js";
export default {
  name: "warningConfigPage",
  data() {
    return {
      title: "",
      groupName: "",
      groupId: undefined,
      groupIdAdd: undefined,
      categoryList: [],
      enabled: true,
      code: "",
      warningId: undefined,
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "分组",
          ellipsis: true,
          dataIndex: "groupIdLabel",
        },
        {
          title: "编码",
          ellipsis: true,
          dataIndex: "code",
        },
        {
          title: "启用",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      configList: [],
    };
  },
  mounted() {
    this.getWarningList();
  },
  methods: {
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        groupId: this.groupId,
      };
      this.getconfigPageList(data);
    },
    // 查询告警配置分页列表
    getconfigPageList(data) {
      this.loadingTable = true;
      api
        .getconfigPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.groupIdLabel = "";
              this.categoryList.forEach((menu) => {
                if (menu.id === item.groupId) {
                  item.groupIdLabel = menu.value;
                }
              });
            });
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 修改
    modifyClick(val, enable) {
      this.title = "修改";
      this.warningId = val.id;
      this.groupIdAdd = val.groupId;
      this.code = val.code;
      this.configList.splice(0);
      if (val.config) {
        this.configList = JSON.parse(val.config);
      }
      this.enabled = val.enabled;
      if (enable) {
        this.add_submit();
      } else {
        this.addKeyVisible = true;
      }
    },
    // 新增
    addClick() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.groupIdAdd = undefined;
      this.code = "";
      this.configList.splice(0);
      this.enabled = true;
    },
    // 确定新增
    add_submit() {
      let data = {
        groupId: this.groupIdAdd,
        code: this.code,
        enabled: this.enabled,
      };
      if (!data.groupId) {
        this.$message.warning("请选择告警组");
        return;
      }
      if (!data.code) {
        this.$message.warning("请输入编号");
        return;
      }
      if (this.title === "新增") {
        api.addAlarmConfig(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.id = this.warningId;
        if (this.configList.length > 0) {
          data.config = JSON.stringify(this.configList);
        } else {
          data.config = "";
        }
        api.updateAlarmConfig(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.code = "";
      this.enabled = true;
      this.groupId = undefined;
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteAlarmConfig(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    // 告警组转换用
    getWarningList() {
      api.groupList().then((res) => {
        if (res.result === 200) {
          let list = [];
          for (let i in res.data) {
            let obj = {
              id: res.data[i].id,
              value: res.data[i].groupName,
            };
            list.push(obj);
          }
          this.categoryList = list;
          this.query(1);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
