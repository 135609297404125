<template>
  <div class="eventPage">
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.name"
      @change="onChange"
    >
      <span slot="object" slot-scope="text, record">
        <div>
          {{ record.kind }}
        </div>
        <div>
          {{ record.object }}
        </div>
      </span>
    </a-table>
  </div>
</template>
<script>
import * as api from "../lib/k8s.js";

export default {
  name: "eventPage",
  data() {
    return {
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          width: 100,
        },
        {
          title: "类型",
          dataIndex: "type",
          filters: [
            {
              text: "Normal",
              value: "Normal",
            },
            {
              text: "Warning",
              value: "Warning",
            },
          ],
          filterMultiple: false,
          width: 130,
        },
        {
          title: "对象",
          dataIndex: "object",
          scopedSlots: { customRender: "object" },
          width: 450,
        },
        {
          title: "信息",
          dataIndex: "description",
          ellipsis: true,
        },
        {
          title: "内容",
          dataIndex: "content",
          width: 150,
        },
        {
          title: "时间",
          dataIndex: "time",
          width: 180,
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      pageNo: 1,
      loadingTable: false,
      type: "",
    };
  },
  created() {
    this.query();
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        type: this.type,
      };
      this.loadingTable = true;
      let queryPage;
      if (this.$route.path == "/event/eventPage") {
        queryPage = api.eventPage;
      } else {
        queryPage = api.eventHistoryPage;
      }
      queryPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource.splice(0);
            this.tableDataSource.push(...res.data.records);
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: 15,
              current: this.pageNo,
              total: res.data.total,
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    onChange(pagination, filters, sorter) {
      if (filters.type) {
        this.type = filters.type[0];
      }
      this.query(pagination.current);
    },
  },
};
</script>
