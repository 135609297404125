<template>
  <div class="ruleList">
    <div class="contentHeader">
      <a-button
        style="float: left"
        icon="plus"
        @click="addRule(0)"
        :disabled="disabled"
        >插入新规则</a-button
      >
      <a-input-search
        v-model.trim="domain"
        placeholder="请输入域名"
        style="width: 240px; float: left; margin-left: 10px"
        @search="monitorList"
      />
      <a-badge
        :count="this.confList.length"
        style="float: right; margin-left: 10px; cursor: pointer"
      >
        <a-button
          type="primary"
          :disabled="takeEffectDisabled"
          @click="takeEffect"
          >变更预览</a-button
        >
      </a-badge>
      <a-button
        type="primary"
        style="float: right; margin-left: 10px"
        v-show="!sortShow"
        @click="monitorIndex"
        >保存优先级
      </a-button>
      <a-button
        type="link"
        style="float: right"
        v-show="!sortShow"
        @click="reset"
        >重置</a-button
      >
    </div>
    <div class="main">
      <draggable
        v-model="ruleList"
        @start="start"
        @end="end"
        :disabled="disabled"
      >
        <div
          v-for="(item, index) in ruleList"
          :key="item.id"
          :class="{ margin: disabled }"
        >
          <div v-if="index !== 0 && !disabled">
            <a-button
              type="link"
              style="color: #0070cc; font-size: 14px; padding: 0"
              @click="addRule(index)"
            >
              <a-icon type="plus"></a-icon>
              插入新规则
            </a-button>
          </div>
          <rule
            :index="index"
            :item="item"
            :disabled="disabled"
            :nginxGatewayId="nginxGatewayId"
            @monitorList="monitorList"
            :propertySelectList="propertySelectList"
            :serverSelectList="serverSelectList"
            :serverGroupList="serverGroupList"
            :statusList="statusList"
            :dataSource="dataSource"
            @getProjectList="getProjectList"
            @getStatusList="getStatusList"
            @getServerList="getServerList"
            @searchServerGroup="searchServerGroup"
            @getConfList="getConfList"
          ></rule>
        </div>
      </draggable>
    </div>
    <a-modal
      title="配置预览"
      v-model="visible"
      :maskClosable="false"
      width="1000px"
    >
      <a-spin size="large" :spinning="spinning">
        <div class="previewList">
          <div
            class="desc"
            v-for="(item, index) in confList"
            :key="index"
            :style="{ marginTop: index == 0 ? '0' : '20px' }"
          >
            <header class="desc_header">
              <div style="float: left">
                {{ item.instanceName }} ｜ {{ item.fileName }}
              </div>
            </header>
            <pre
              v-html="item.content"
              :class="{ desc_content: true, line: item.willBeDelete }"
            ></pre>
          </div>
          <div class="dec" v-show="confList.length == 0">
            未检测到任何配置更新
          </div>
        </div>
      </a-spin>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-popconfirm
            title="确认生效？"
            @confirm="() => takeEffect_submit()"
            :disabled="confList.length == 0"
          >
            <a-button type="primary" :disabled="confList.length == 0"
              >立即生效</a-button
            >
          </a-popconfirm>
          <a-button
            key="back"
            @click="visible = false"
            style="margin-left: 10px"
            >关闭</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { listNginxServerGroup } from "../lib/nginxServerGroup.js";
import { projectList } from "../lib/projectList.js";
import { getStatus } from "../lib/statusList.js";
import { serverList } from "../lib/serverList.js";
import rule from "./rule.vue";
import api from "../lib/slb.js";
import draggable from "vuedraggable";

export default {
  name: "ruleList",
  components: { rule, draggable },
  data() {
    return {
      active: "1",
      dataSource: [],
      statusList: [],
      serverSelectList: [],
      serverGroupList: [],
      ruleList: [],
      sortShow: true,
      sortList: [],
      deleteShow: true,
      disabled: false,
      nginxGatewayId: "",
      idList: [],
      indexList: [],
      propertySelectList: [],
      domain: "",
      spinning: false,
      visible: false,
      confList: [],
      takeEffectDisabled: true,
    };
  },
  watch: {
    ruleList: {
      deep: true,
      handler(newVal, oldVal) {
        let flag = false;
        let deleteFlag = false;
        let disabledFlag = false;
        this.idList.splice(0);
        newVal.forEach((element, index) => {
          if (this.sortList[index].id !== element.id) {
            flag = true;
          }
          if (this.sortList[index].selectShow !== element.selectShow) {
            this.idList.push(element.id);
            deleteFlag = true;
          }
          if (element.editShow) {
            disabledFlag = true;
          }
        });
        if (flag) {
          this.sortShow = false;
        } else {
          this.sortShow = true;
        }
        if (deleteFlag) {
          this.deleteShow = false;
        } else {
          this.deleteShow = true;
        }
        if (disabledFlag) {
          this.disabled = true;
        } else {
          this.disabled = false;
        }
      },
    },
  },
  created() {
    this.projectList();
    this.getStatus();
    this.serverList();
    this.getServerGroupList();
    let nginxGatewayId = this.$route.query.id * 1;
    if (nginxGatewayId) {
      this.nginxGatewayId = nginxGatewayId;
      this.getConfList();
      this.monitorList();
      this.nginxAttributeList();
    }
  },
  methods: {
    searchServerGroup(name) {
      this.getServerGroupList(name);
    },
    getProjectList(name) {
      this.projectList(name);
    },
    getStatusList(name) {
      this.getStatus(name);
    },
    getServerList(name) {
      this.serverList(name);
    },
    nginxAttributeList() {
      api.nginxAttributeList().then((res) => {
        if (res.result == 200) {
          this.propertySelectList = res.data;
        }
      });
    },
    takeEffect() {
      this.visible = true;
      this.getConfList();
    },
    getConfList() {
      this.confList.splice(0);
      api
        .listModifiedConf({
          nginxGatewayId: this.nginxGatewayId,
        })
        .then((res) => {
          if (res.result == 200) {
            this.confList.push(...res.data.modifiedConfList);
            if (this.confList.length == 0) {
              this.takeEffectDisabled = true;
            } else {
              this.takeEffectDisabled = false;
            }
          }
        });
    },
    takeEffect_submit() {
      this.spinning = true;
      api
        .activeRules({
          nginxGatewayId: this.nginxGatewayId,
        })
        .then((res) => {
          this.spinning = false;
          if (res.result == 200) {
            this.$message.success("生效成功");
            this.visible = false;
            this.getConfList();
          }
        })
        .catch(() => {
          this.spinning = false;
        });
    },
    monitorIndex() {
      this.indexList.splice(0);
      this.ruleList.forEach((item, index) => {
        this.indexList.push({ id: item.id, index: index + 1 });
      });
      let data = {
        indexList: this.indexList,
      };
      api.changeRuleIndex(data).then((res) => {
        if (res.result == 200) {
          this.monitorList();
          this.$message.success("优先级修改成功");
        }
      });
    },
    monitorList() {
      api
        .queryPage({
          nginxGatewayId: this.nginxGatewayId,
          domain: this.domain,
        })
        .then((res) => {
          if (res.result == 200) {
            this.ruleList.splice(0);
            if (res.data) {
              this.ruleList.push(...res.data);
              this.ruleList.forEach((item) => {
                this.$set(item, "selectShow", false);
                this.$set(item, "editShow", false);
              });
              this.sortList = JSON.parse(JSON.stringify(this.ruleList));
            }
          }
        });
    },
    start(e) {},
    end(e) {},
    reset() {
      this.ruleList.splice(0);
      this.ruleList.push(...this.sortList);
      this.$message.success("重置成功");
    },
    addRule(index) {
      api.generateName({ name: "rule" }).then((res) => {
        if (res.result == 200) {
          this.ruleList.splice(index, 0, {
            name: res.data,
            selectShow: false,
            editShow: true,
            detailList: [],
            includeGeneralConf: false,
          });
          this.sortList.splice(index, 0, {
            name: res.data,
            selectShow: false,
            editShow: true,
            detailList: [],
            includeGeneralConf: false,
          });
        }
      });
    },
    getServerGroupList(groupName) {
      let data = {
        pageNo: 1,
        pageSize: 10,
        groupName,
        type: "APPLICATION",
      };
      listNginxServerGroup(data).then((res) => {
        if (res.result == 200) {
          this.serverGroupList = res.data.records;
        }
      });
    },
    serverList(instanceName) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        instanceName,
        enabled: true,
      };
      serverList(data).then((res) => {
        if (res.result == 200) {
          this.serverSelectList.splice(0);
          this.serverSelectList.push(...res.data.records);
        }
      });
    },
    // 查询项目列表
    projectList(name) {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        name: name,
        projectType: "FRONT",
        queryAll: true,
      };
      projectList(data).then((res) => {
        if (res.result == 200) {
          let list = res.data.list;
          this.dataSource = list;
        }
      });
    },
    getStatus(enName) {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        enName: enName,
        queryAll: true,
        status: "RUNNING",
      };
      getStatus(data).then((res) => {
        if (res.result == 200) {
          let list = res.data.records;
          this.statusList = list;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.previewList {
  max-height: 550px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
.ruleList {
  padding: 0 20px;
  .contentHeader {
    width: 100%;
    display: inline-block;
  }

  .main {
    margin-top: 10px;

    .margin {
      margin-bottom: 10px;
    }
  }
}

.desc {
  background-color: #f7f7f7;
  border-radius: 5px;

  .desc_header {
    padding: 10px 20px;
    border-bottom: 1px solid #e5e5e5;
    overflow: auto;
  }

  .desc_content {
    padding: 10px 20px;
  }
  .line {
    text-decoration: line-through;
  }
}
</style>
