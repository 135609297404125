import { render, staticRenderFns } from "./postApplication.vue?vue&type=template&id=61ba6c84&scoped=true"
import script from "./postApplication.vue?vue&type=script&lang=js"
export * from "./postApplication.vue?vue&type=script&lang=js"
import style0 from "./postApplication.vue?vue&type=style&index=0&id=61ba6c84&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61ba6c84",
  null
  
)

export default component.exports