import http from "./axios";

// 查询列表
const queryPage = ({ projectId, name, pageNo, pageSize, searchChild }) => {
  return http.get("/pom/page", {
    projectId,
    name,
    pageNo,
    pageSize,
    searchChild,
  });
};

export default {
  queryPage,
};
