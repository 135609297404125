import http from "./axios";

// 查询shell定时任务分页列表
export const timeTask = ({ name, pageNo, pageSize, status }) => {
  return http.get("/shell/timeTask/page", {
    name: name,
    pageNo: pageNo,
    pageSize: pageSize,
    status: status,
  });
};

// 新增shell定时任务
export const addTask = ({ name, shell, cron, status, serverList }) => {
  return http.json_post("/shell/timeTask/addTask", {
    name: name,
    shell: shell,
    cron: cron,
    status: status,
    serverList: serverList,
  });
};

// 修改shell定时任务
export const updateTask = ({ id, name, shell, cron, status, serverList }) => {
  return http.json_post("/shell/timeTask/updateTask", {
    id: id,
    name: name,
    shell: shell,
    cron: cron,
    status: status,
    serverList: serverList,
  });
};

// 删除shell定时任务
export const deleteTask = ({ id }) => {
  return http.get("/shell/timeTask/deleteTask", {
    id: id,
  });
};
