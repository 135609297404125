import http from "./axios";

// 查询主机记录
export const urlDomainList = ({
  pageNo,
  pageSize,
  rr,
  value,
  domainId,
  type,
}) => {
  return http.get("/delivery/urlDomain/recordList", {
    pageNo: pageNo,
    pageSize: pageSize,
    rr: rr,
    value: value,
    domainId,
    type,
  });
};

// 添加主机记录
export const urlDomainAddRecord = (data) => {
  return http.json_post("/delivery/urlDomain/addRecord", data);
};

// 批量添加主机记录
export const urlDomainAddRecordBatch = (data) => {
  return http.json_post("/delivery/urlDomain/addRecordBatch", data);
};

// 修改主机记录
export const urlDomainUpdateRecord = (data) => {
  return http.json_post("/delivery/urlDomain/updateRecord", data);
};

// 删除主机记录
export const urlDomainDeleteRecord = ({ idList, accountId }) => {
  return http.json_post("/delivery/urlDomain/deleteRecord", {
    idList,
    accountId,
  });
};

// 暂停主机记录
export const urlDomainPauseRecord = ({ idList, accountId }) => {
  return http.json_post("/delivery/urlDomain/pauseRecord", {
    idList,
    accountId,
  });
};

// 启用主机记录
export const urlDomainResumeRecord = ({ idList, accountId }) => {
  return http.json_post("/delivery/urlDomain/resumeRecord", {
    idList,
    accountId,
  });
};

export const updateSafeMode = ({ id, safeMode }) => {
  return http.json_post("/delivery/urlDomain/updateSafeMode", {
    id,
    safeMode,
  });
};

// 同步
export const synUrlDomain = ({ domainId, accountId }) => {
  return http.get("/delivery/urlDomain/synUrlDomain", {
    domainId,
    accountId,
  });
};
