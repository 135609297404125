<template>
  <a-layout id="components-layout-demo-top-side-2">
    <a-layout id="content">
      <a-layout-sider width="250" style="background: #fff; max-height: 100vh">
        <a-menu
          mode="inline"
          :selectedKeys="selectedKeys"
          :openKeys="openKeys"
          @openChange="onOpenChange"
          :style="{ height: '100%', borderRight: 0 }"
        >
          <a-sub-menu key="sub" style="height: 100%; overflow-y: scroll">
            <span
              slot="title"
              style="display: inline-block; width: 100%"
              @mouseenter="menuShow = true"
              @mouseleave="menuShow = false"
            >
              <span>/</span>
              <span
                style="float: right"
                @click.stop="createNode('/')"
                v-show="menuShow"
              >
                <a-tooltip>
                  <template slot="title">添加节点</template>
                  <a-icon type="plus" />
                </a-tooltip>
              </span>
            </span>
            <template v-for="item in menuList">
              <sub-menu
                v-if="item.list.length > 0"
                :item="item"
                @nodeDetail="nodeDetail"
                @deleteNode="deleteNode"
                @createNode="createNode"
              ></sub-menu>
              <template v-else>
                <a-menu-item :key="item.path">
                  <span
                    style="display: inline-block; width: 100%"
                    @mouseenter="item.show = true"
                    @mouseleave="item.show = false"
                    @click.stop="nodeDetail(item, false)"
                  >
                    <span class="menuShow">{{ item.name }}</span>
                    <span
                      style="float: right"
                      @click.stop="deleteNode(item.path)"
                      v-show="item.show"
                    >
                      <a-tooltip>
                        <template slot="title">删除节点</template>
                        <a-icon type="delete" />
                      </a-tooltip>
                    </span>
                    <span
                      style="float: right"
                      @click.stop="createNode(item.path)"
                      v-show="item.show"
                    >
                      <a-tooltip>
                        <template slot="title">添加节点</template>
                        <a-icon type="plus" />
                      </a-tooltip>
                    </span>
                  </span>
                </a-menu-item>
              </template>
            </template>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout style="padding: 0 24px 24px">
        <a-layout-content
          :style="{
            background: '#fff',
            padding: '24px',
            margin: 0,
            minHeight: '280px',
          }"
        >
          <div style="background: rgb(255, 255, 255)">
            <zookeeper-detail
              :nodeData="nodeData"
              :nodeStat="nodeStat"
              :publicPath="publicPath"
              :node="node"
              :nodeFlag="nodeFlag"
              :id="id"
              @nodeDetail="nodeDetail"
              ref="detail"
            ></zookeeper-detail>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
    <a-modal
      v-model="addNodeVisible"
      :maskClosable="false"
      :afterClose="addNodeClose"
      class="action-class"
      width="500px"
      title="新增节点"
    >
      <a-form>
        <a-form-item
          label="节点路径:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input placeholder="请输入节点路径" v-model="addPath">
            <span slot="addonBefore" class="pre">
              <a-tooltip>
                <template slot="title">{{ createPath }}</template>
                <span>{{ createPath }}</span>
              </a-tooltip>
            </span>
          </a-input>
        </a-form-item>
        <a-form-item
          label="节点值:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-textarea
            v-model="addData"
            :rows="3"
            placeholder="请输入节点值"
            allowClear
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addNodeVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addNode_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </a-layout>
</template>
<script>
import api from "../lib/zookeeperUIList.js";
import zookeeperDetail from "../components/zookeeperDetail.vue";
import subMenu from "../components/subMenu.vue";

export default {
  name: "zookeeperUI",
  components: { zookeeperDetail, subMenu },
  data() {
    return {
      menuList: [],
      openKeys: ["sub"],
      addNodeVisible: false,
      loading: false,
      selectedKeysArr: [],
      nodeData: "",
      nodeStat: "",
      id: "",
      publicPath: "",
      menuShow: false,
      createPath: "",
      addPath: "",
      addData: "",
      node: {},
      nodeFlag: false,
    };
  },
  computed: {
    selectedKeys() {
      return this.selectedKeysArr;
    },
  },
  created() {
    this.id = this.$route.query.id;
    if (this.id && this.$route.path == "/zookeeperUI") {
      this.menuList = this.getMenu(this.id, "/", 1);
    } else {
      this.$router.replace("/zookeeperUIList");
    }
  },
  methods: {
    addNode_submit() {
      let data = {
        id: this.id,
        path: (this.createPath == "/" ? "" : this.createPath) + this.addPath,
        data: this.addData,
      };
      api.createNode(data).then((res) => {
        if (res.result == 200) {
          this.addNodeVisible = false;
          this.$message.success("添加成功");
          this.menuList = this.getMenu(this.id, "/");
        }
      });
    },
    addNodeClose() {
      this.addPath = "";
      this.addData = "";
    },
    deleteNode(path) {
      this.$confirm({
        title: `确认删除${path}节点吗`,
        content: (h) => (
          <div>温馨提示：该操作会删除该节点以及该节点下所有节点子项</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          api.deleteNode({ id: this.id, path }).then((res) => {
            if (res.result == 200) {
              this.$message.success("删除成功");
              this.menuList = this.getMenu(this.id, "/");
            }
          });
        },
        onCancel: () => {
          // console.log('Cancel');
        },
        class: "test",
      });
    },
    createNode(path) {
      this.createPath = path;
      this.addNodeVisible = true;
    },
    selectAllHide(arr) {
      arr.forEach((item) => {
        if (item.list.length > 0) {
          this.selectAllHide(item.list);
        }
        this.$set(item, "selectShow", false);
      });
    },
    nodeDetail(item, flag) {
      this.node = item;
      this.nodeFlag = flag;
      this.publicPath = item.path;
      this.selectAllHide(this.menuList);
      let pathArr = item.path.split("/");
      pathArr.shift();
      pathArr.forEach((item, index) => {
        if (index > 0) {
          pathArr[index] = pathArr[index - 1] + "/" + item;
        } else {
          pathArr[index] = "/" + item;
        }
      });
      if (flag) {
        this.openKeys = ["sub"];
        this.openKeys.push(...pathArr);
        this.selectedKeysArr = [];
        this.$set(item, "selectShow", true);
      } else {
        this.selectedKeysArr = [item.path];
      }
      let data = {
        id: this.id,
        path: item.path,
      };
      this.$axios.all([api.getStat(data), api.getData(data)]).then(
        this.$axios.spread((stat, data) => {
          if (stat.result == 200 && data.result == 200) {
            this.nodeStat = stat.data;
            this.nodeData = data.data;
            this.$refs.detail.init();
          }
        })
      );
    },
    getMenu(id, path, index) {
      let arr = [];
      api.getChildren({ id, path }).then((res) => {
        if (res.result == 200) {
          if (Array.isArray(res.data)) {
            if (res.data.length > 0) {
              res.data.forEach((element, itemIndex) => {
                let PATH = path == "/" ? "/" : path + "/";
                if (index == 1 && itemIndex == 0) {
                  arr.push({
                    name: element,
                    list: this.getMenu(id, PATH + element),
                    selectShow: true,
                    show: false,
                    path: PATH + element,
                  });
                  this.node = arr[0];
                  if (this.node.list.length > 0) {
                    this.nodeFlag = false;
                  } else {
                    this.nodeFlag = true;
                  }
                  this.nodeDetail(this.node, this.nodeFlag);
                } else {
                  arr.push({
                    name: element,
                    list: this.getMenu(id, PATH + element),
                    selectShow: false,
                    show: false,
                    path: PATH + element,
                  });
                }
              });
            }
          } else {
            return [];
          }
        }
      });

      return arr;
    },
    onOpenChange(openKeys) {
      this.openKeys = openKeys;
    },
  },
};
</script>

<style lang="scss" scoped>
#components-layout-demo-top-side-2 {
  min-height: 100%;
  .menuShow {
    display: inline-block;
    width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.action-class {
  .pre {
    display: inline-block;
    max-width: 100px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
