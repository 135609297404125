<template>
  <div class="mqProducer">
    <div class="searchCondition">
      <div class="searchConditionItem">
        Topic：
        <a-select
          v-model="topic"
          placeholder="请选择Topic"
          allowClear
          showSearch
          @search="getTopicList"
          @focus="getTopicList('')"
        >
          <a-select-option v-for="item in topicSelect" :key="item.topic">{{
            item.topic
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        ProducerGroup：
        <a-input
          placeholder="请输入ProducerGroup"
          v-model.trim="producerGroup"
          @pressEnter="query"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :loading="loadingTable"
      size="small"
    >
    </a-table>
  </div>
</template>
<script>
import * as api from "../lib/mqProducer.js";
import { topicList } from "../lib/mqTopic.js";

export default {
  name: "mqProducer",
  props: ["clusterId"],
  data() {
    return {
      topic: undefined,
      producerGroup: "CLIENT_INNER_PRODUCER",
      topicSelect: [],
      loading: false,
      tableColumns: [
        {
          title: "clientId",
          ellipsis: true,
          dataIndex: "clientId",
        },
        {
          title: "clientAddr",
          ellipsis: true,
          dataIndex: "clientAddr",
        },
        {
          title: "language",
          ellipsis: true,
          dataIndex: "language",
        },
        {
          title: "version",
          ellipsis: true,
          dataIndex: "versionDesc",
        },
      ],
      tableDataSource: [],
      loadingTable: false,
    };
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query();
    },
    // 点击查询
    query() {
      let data = {
        topic: this.topic,
        producerGroup: this.producerGroup,
        clusterId: this.clusterId,
      };
      this.getConsumerConnection(data);
    },
    // 查询告警组分页列表
    getConsumerConnection(data) {
      this.loadingTable = true;
      api
        .consumerConnection(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.connectionSet;
            this.tableDataSource = list;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 查询所有topic
    getTopicList(topic) {
      this.loadingTable = true;
      let data = {
        pageNo: 1,
        pageSize: 100,
        categories: "",
        topic,
        clusterId: this.clusterId,
      };
      topicList(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.topicSelect = list;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
