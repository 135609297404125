import http from "./axios";

// 查询告警组分页列表
export const getGroupPage = ({ groupName, pageNo, pageSize }) => {
  return http.get("/alarmGroup/groupPage", {
    groupName: groupName,
    pageNo: pageNo,
    pageSize: pageSize,
  });
};

// 新增告警组
export const addGroup = ({ groupName }) => {
  return http.json_post("/alarmGroup/addGroup", {
    groupName: groupName,
  });
};

// 修改告警组
export const updateGroup = ({ id, groupName }) => {
  return http.json_post("/alarmGroup/updateGroup", {
    id: id,
    groupName: groupName,
  });
};

// 删除告警组
export const deleteGroup = ({ id }) => {
  return http.get("/alarmGroup/deleteGroup", {
    id: id,
  });
};
