<template>
  <div class="serverAccountListTab">
    <div class="searchCondition">
      <div class="searchConditionItem">
        服务器：
        <a-select
          v-model="serverId"
          placeholder="试试搜索"
          showSearch
          option-filter-prop="children"
          style="width: 300px"
          allowClear
        >
          <a-select-option v-for="item in serverIdList" :key="item.id">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        用户：
        <a-select
          v-model="userLoginName"
          placeholder="请输入选择用户"
          style="width: 300px"
          showSearch
          allowClear
          :filter-option="false"
          @search="searchUserName"
        >
          <a-select-option
            v-for="item in userLoginNameList"
            :key="item.userId"
            :value="item.loginName"
          >
            {{ item.userName }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addServer" icon="plus"
          >新增服务器账号</a-button
        >
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(columns)"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="pagination"
      :loading="tableLoading"
      rowKey="id"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      size="small"
    >
      <span slot="instanceName" slot-scope="text, record">
        <div>{{ record.instanceId }}</div>
        <div
          @mouseover="
            () => {
              record.editNameShow = true;
            }
          "
          @mouseout="
            () => {
              record.editNameShow = false;
            }
          "
        >
          <span>{{ text }}</span>
          <a-tooltip>
            <template slot="title"> 修改实例名称 </template>
            <a-icon
              type="edit"
              class="table-tr-btn"
              @click="editNameClick(record)"
              v-show="record.editNameShow"
            />
          </a-tooltip>
          <a-tooltip>
            <template slot="title"> 复制 </template>
            <a-icon
              type="copy"
              class="table-tr-btn"
              v-copy="text"
              v-show="record.editNameShow"
            />
          </a-tooltip>
        </div>
      </span>
      <span slot="publicIp" slot-scope="text, record">
        <div
          v-show="text"
          @mouseover="
            () => {
              record.oipNameShow = true;
            }
          "
          @mouseout="
            () => {
              record.oipNameShow = false;
            }
          "
        >
          <span>{{ text }} (外网)</span>
          <a-tooltip>
            <template slot="title"> 复制 </template>
            <a-icon
              type="copy"
              class="table-tr-btn"
              v-copy="text"
              v-show="record.oipNameShow"
            />
          </a-tooltip>
        </div>
        <div
          v-show="record.privateIp"
          @mouseover="
            () => {
              record.iipNameShow = true;
            }
          "
          @mouseout="
            () => {
              record.iipNameShow = false;
            }
          "
        >
          <span>{{ record.privateIp }} (内网)</span>
          <a-tooltip>
            <template slot="title"> 复制 </template>
            <a-icon
              type="copy"
              class="table-tr-btn"
              v-copy="record.privateIp"
              v-show="record.iipNameShow"
            />
          </a-tooltip>
        </div>
      </span>
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteServer(record)"
        >
          <template slot="title">确认是否删除{{ record.privateIp }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <div class="projectList-bottom-button">
      <a-button @click="batchAuthorize" :disabled="disabledClick"
        >批量授权</a-button
      >
    </div>
    <!-- 新增服务器 -->
    <a-modal
      :title="editServerTitle"
      v-model="visibleAddServer"
      :maskClosable="false"
      :afterClose="closeAddServer"
    >
      <a-form>
        <a-form-item
          label="选择服务器"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 14 }"
          required
        >
          <a-select
            v-model="addForm.serverIdList"
            placeholder="请输入选择服务器"
            mode="multiple"
            allowClear
          >
            <a-select-option v-for="item in serverIdList" :key="item.id">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="账号"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 14 }"
          required
        >
          <a-input placeholder="请输入账号" v-model="addForm.user" />
        </a-form-item>
        <a-form-item
          label="端口号"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 14 }"
          required
        >
          <a-input placeholder="请输入端口号" v-model="addForm.port" />
        </a-form-item>
        <a-form-item
          label="登录方式"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 14 }"
          required
        >
          <a-select
            v-model="addForm.authorizeType"
            placeholder="请输入选择登录方式"
            allowClear
          >
            <a-select-option
              v-for="item in authorizeTypeList1"
              :key="item.value"
            >
              {{ item.key }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="密码或密钥路径"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 14 }"
          required
        >
          <a-input
            placeholder="请输入密码或密钥路径"
            v-model="addForm.authorizeString"
          />
        </a-form-item>
        <a-form-item
          label="备注"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-input placeholder="请输入备注" v-model="addForm.remark" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addServerCancel">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addServerOk"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 修改实力名称 -->
    <a-modal
      title="编辑实例名称"
      v-model="editNameVisible"
      :afterClose="editNameClose"
    >
      <a-form>
        <a-form-item
          label="实例名称"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input placeholder="请输入实例名称" v-model="editName" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="editNameVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="editName_submit"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 批量授权 -->
    <a-modal
      title="批量授权"
      v-model="batchAuthorizeVisible"
      :afterClose="batchAuthorizeClose"
    >
      <a-form>
        <a-form-item
          label="选择用户"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            mode="multiple"
            :value="userLoginNames"
            :filterOption="false"
            @search="searchUserName"
            @change="handleChange"
            placeholder="请输入选择用户"
          >
            <a-select-option
              v-for="i in userLoginNameList"
              :key="i.loginName"
              >{{ i.userName }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 16, offset: 5 }">
          <a-radio-group v-model="valueAuthorize">
            <a-radio value="authorize">授权</a-radio>
            <a-radio value="revoke">撤销</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="batchAuthorizeVisible = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="batchAuthorize_submit"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/serverList.js";
export default {
  name: "serverAccountListTab",
  data() {
    return {
      batchAuthorizeVisible: false,
      userLoginNames: [],
      userLoginNameList: [],
      valueAuthorize: "authorize",
      editName: "",
      editNameVisible: false,
      editServerTitle: "",
      columns: [
        {
          title: "实例名称",
          ellipsis: true,
          dataIndex: "instanceName",
          scopedSlots: { customRender: "instanceName" },
          width: 350,
        },
        {
          title: "IP地址",
          ellipsis: true,
          dataIndex: "publicIp",
          scopedSlots: { customRender: "publicIp" },
          width: 200,
        },
        {
          title: "登录名",
          ellipsis: true,
          dataIndex: "user",
        },
        {
          title: "端口号",
          ellipsis: true,
          dataIndex: "port",
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 180,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataSource: [],
      pagination: {},
      selectedRowKeys: [],
      pageNo: 1,
      visibleAddServer: false,
      addForm: {
        id: "",
        port: "",
        user: "",
        remark: "",
        authorizeString: "",
        authorizeType: undefined,
        serverIdList: [],
      },
      authorizeTypeList: [],
      authorizeTypeList1: [
        {
          key: "密码",
          value: "PASSWORD",
        },
        {
          key: "秘钥",
          value: "PUBLIC_KEY",
        },
      ],
      serverId: undefined,
      serverIdList: [],
      loading: false,
      tableLoading: false,
      userLoginName: undefined,
    };
  },
  computed: {
    disabledClick() {
      return this.selectedRowKeys.length < 1;
    },
  },
  mounted() {
    this.query(1);
    this.getServerAll();
  },
  methods: {
    // 搜索服务器

    // 登陆方式
    getAccountType() {
      api.accountType().then((res) => {
        if (res.result == 200) {
          this.authorizeTypeList = res.data;
        }
      });
    },
    // 点击批量授权
    batchAuthorize() {
      this.batchAuthorizeVisible = true;
    },
    handleChange(val) {
      this.userLoginNames = val;
    },
    searchUserName(name) {
      if (name !== "") {
        //非空
        api.userList({ name }).then((res) => {
          if (res.result == 200) {
            this.userLoginNameList = res.data.userList;
          }
        });
      } else {
        //空值
        this.userLoginNameList = [];
      }
    },
    // 确定批量授权
    batchAuthorize_submit() {
      let data = {
        userLoginNameList: this.userLoginNames,
        serverAccountIdList: this.selectedRowKeys,
      };
      if (data.userLoginNameList.length < 1) {
        this.$message.warning("请选择用户");
        return;
      }
      this.loading = true;
      if (this.valueAuthorize === "authorize") {
        api
          .grantAccount(data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.selectedRowKeys = [];
              this.$message.success("授权成功");
              this.batchAuthorizeVisible = false;
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
      if (this.valueAuthorize === "revoke") {
        api
          .revokeAccount(data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.selectedRowKeys = [];
              this.$message.success("撤销成功");
              this.batchAuthorizeVisible = false;
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 关闭批量授权
    batchAuthorizeClose() {
      this.userLoginNames = [];
      this.valueAuthorize = "authorize";
    },
    // 选择表格
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 点击修改实力名称
    editNameClick(val) {
      this.editNameVisible = true;
      this.editName = val.instanceName;
    },
    // 确定修改实力名称
    editName_submit() {},
    // 关闭修改实力名称
    editNameClose() {},
    // 删除服务器
    deleteServer(val) {
      api.deleteAccount({ id: val.id }).then((res) => {
        if (res.result == 200) {
          this.$message.success("删除成功！");
          this.query();
        }
      });
    },
    // 点击新增服务器
    addServer() {
      this.visibleAddServer = true;
      this.editServerTitle = "新增服务器账号";
      this.addForm = {
        id: "",
        port: "22",
        user: "root",
        remark: "",
        authorizeString: "",
        authorizeType: undefined,
        serverIdList: [],
      };
      this.getAccountType();
    },
    // 新增服务器
    addServerOk() {
      this.addForm.port = Number(this.addForm.port);
      this.addForm.user = this.addForm.user.trim();
      if (
        this.addForm.port &&
        this.addForm.authorizeString &&
        this.addForm.authorizeType &&
        this.addForm.user.length &&
        this.addForm.serverIdList.length > 0
      ) {
        if (this.editServerTitle === "新增服务器账号") {
          let data = {
            serverIdList: this.addForm.serverIdList,
            port: this.addForm.port,
            user: this.addForm.user,
            remark: this.addForm.remark,
            authorizeString: this.addForm.authorizeString,
            authorizeType: this.addForm.authorizeType,
          };
          this.loading = true;
          api
            .addAccount(data)
            .then((res) => {
              this.loading = false;
              if (res.result == 200) {
                this.$message.success("新增服务器账号成功！");
                this.visibleAddServer = false;
                this.query();
              }
            })
            .catch((err) => {
              this.loading = false;
            });
        }
      } else {
        this.$message.warning("信息填写不完整！");
      }
    },
    // 取消新增服务器
    addServerCancel() {
      this.visibleAddServer = false;
    },
    // 关闭新增服务器
    closeAddServer() {
      this.addForm = {
        id: "",
        port: "",
        user: "",
        remark: "",
        authorizeString: "",
        authorizeType: undefined,
        serverIdList: [],
      };
    },
    //查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        serverId: this.serverId,
        userLoginName: this.userLoginName,
        publicIp: "",
      };
      this.getServerAccountList(data);
    },
    // 查询服务器列表
    getServerAccountList(data) {
      this.tableLoading = true;
      api
        .serverAccountList(data)
        .then((res) => {
          this.tableLoading = false;
          if (res.result == 200) {
            let list = res.data.records;
            list.forEach((item, i) => {
              item.key = i + 1;
              item.editNameShow = false;
              item.oipNameShow = false;
              item.iipNameShow = false;
              item.instanceId = item.server.instanceId;
              item.instanceName = item.server.instanceName;
              item.privateIp = item.server.privateIp;
              item.publicIp = item.server.publicIp;
            });
            this.dataSource = list;
            this.pagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    // 分页
    changePageItem(current) {
      this.query(current);
    },
    // 查询所有服务器
    getServerAll() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        enabled: true,
      };
      api.serverList(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item) => {
            item.title = item.publicIp + "(" + item.instanceName + ")";
          });
          this.serverIdList = list;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ant-switch-checked {
  background-color: #52c41a;
}
.template-c {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.template-c-i {
  width: 47%;
}
.template-c-z {
  font-size: 28px;
}
.table-tr-btn {
  margin: 0 4px;
  cursor: pointer;
}
.table-tr-btn:hover {
  color: #409eff;
}
</style>
