<template>
  <div
    :class="{ noticeItem: true, read: item.haveRead }"
    @mouseenter="deleteShow = true"
    @mouseleave="deleteShow = false"
  >
    <div class="header">
      <span
        style="
          display: inline-block;
          width: 40px;
          text-align: center;
          vertical-align: top;
        "
      >
        <a-icon type="info-circle" />
      </span>
      <div class="title" :title="item.title">
        {{ item.title }}
      </div>
      <div style="padding: 0 15px; float: right" v-show="deleteShow">
        <span class="yidu" @click.stop="read"></span>
        <a-icon
          type="close"
          style="color: grey; font-size: 16px; vertical-align: top"
          @click.stop="deleteNotice"
        />
      </div>
    </div>
    <div class="content" @click="jump">
      <div>
        <div class="contentBox">
          {{ item.content }}
        </div>
        <div style="font-size: 12px; color: grey; margin-top: 10px">
          {{ item.time }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "notice",
  props: ["item", "index"],
  data() {
    return {
      deleteShow: false,
    };
  },
  methods: {
    read() {
      if (!this.item.haveRead) {
        this.$set(this.item, "haveRead", true);
        this.$http.get("/remindMsg/read", { id: this.item.id });
      } else {
        this.$message.warning("状态已更改为已读，请勿重复点击");
      }
    },
    deleteNotice() {
      this.$emit("deleteNotice", this.index);
      this.$http.get("/remindMsg/delete", { id: this.item.id });
    },
    jump() {
      if (!this.item.haveRead) {
        this.read();
      }
      window.open(this.item.url);
    },
  },
};
</script>

<style lang="scss" scoped>
.noticeItem {
  padding: 20px 20px 20px 0;
  border-top: 1px solid #e5e5e5;
  cursor: pointer;
  color: black;
  &:hover {
    background: #f7f7f7;
  }
  .header {
    width: 100%;
    padding-left: 25px;
    margin-bottom: 10px;
    .yidu {
      display: inline-block;
      width: 14px;
      height: 14px;
      background-image: url("../assets/biaojiyidu.png");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      vertical-align: top;
      margin-right: 10px;
      margin-top: 1px;
    }
    .title {
      display: inline-block;
      max-width: 230px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .content {
    padding-left: 65px;
    .contentBox {
      width: 100%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      word-break: break-all;
      word-wrap: break-word;
      font-size: 12px;
    }
  }
}
.read {
  color: grey !important;
}
</style>
