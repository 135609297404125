import http from "./axios";

// 查询局域网列表
export const lanInfoList = () => {
  return http.get("/delivery/lanInfo/list");
};

// 查询编译机列表
export const compileServerList = () => {
  return http.get("/delivery/compileServer/list");
};

// 局域网---host配置列表
export const hostList = ({ id }) => {
  return http.get("/delivery/lanInfo/hostList", {
    id: id,
  });
};

// 局域网----host配置同步
export const synHost = ({ id, hosts }) => {
  return http.json_post("/delivery/lanInfo/synHost", {
    id: id,
    hosts: hosts,
  });
};

// 轮询
export const synHostResult = ({ uuid }) => {
  return http.get("/delivery/lanInfo/synHostResult", {
    uuid: uuid,
  });
};
