<template>
  <div class="topic">
    <div>
      <span @click="back()" class="back">
        <icon-font type="icon-arrow-left" />
        {{ topic }}
      </span>
    </div>
    <div class="search">
      <div class="searchConditionItem">
        类型：
        <a-select
          v-model="timeType"
          placeholder="请选择相对时间"
          style="width: 120px"
        >
          <a-select-option
            v-for="item in timeTypeList"
            :key="item.value"
            :value="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </div>
      <div class="searchConditionItem">
        时间：
        <a-select
          v-model="relativeTime"
          placeholder="请选择相对时间"
          v-if="timeType == 'relative'"
          style="width: 120px"
        >
          <a-select-option v-for="item in relativeList" :key="item.key">{{
            item.value
          }}</a-select-option>
        </a-select>
        <a-range-picker
          :show-time="{ format: 'HH:mm:ss' }"
          format="YYYY-MM-DD HH:mm:ss"
          :placeholder="['开始时间', '结束时间']"
          @change="onChange"
          @ok="onOk"
          style="width: 350px"
          v-else
        />
      </div>
      <a-button
        style="margin-left: 10px"
        type="primary"
        v-if="timeType == 'relative'"
        @click="query1"
        icon="search"
        >查询</a-button
      >
      <a-button
        style="margin-left: 10px"
        type="primary"
        @click="query"
        v-else
        icon="search"
        >查询</a-button
      >
    </div>
    <div class="nav">
      <div class="top_item">
        <div class="top_num">{{ produceCount }}</div>
        <div class="top_txt">生产消息量</div>
      </div>
      <div class="top_item">
        <div class="top_num">{{ producerTps }}</div>
        <div class="top_txt">生产TPS均值</div>
      </div>
      <div class="top_item">
        <div class="top_num">{{ consumeCount }}</div>
        <div class="top_txt">消费消息量</div>
      </div>
      <div class="top_item">
        <div class="top_num">{{ consumerTps }}</div>
        <div class="top_txt">消费TPS均值</div>
      </div>
    </div>

    <div class="content">
      <div class="card">
        <div class="tab">
          <div class="title">
            <span class="title2">生产消息量</span>
          </div>
          <div class="chart">
            <div id="myChart"></div>
            <div class="num">
              <div>最大值：{{ maxCount }}</div>
              <div>最小值：{{ minCount }}</div>
            </div>
          </div>
        </div>
        <div class="tab">
          <div class="title">
            <span class="title2">生产消息TPS</span>
          </div>
          <div class="chart">
            <div></div>
            <div id="producerTpsChart"></div>
            <div class="num">
              <div>最大值：{{ maxTps }}</div>
              <div>最小值：{{ minTps }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="tab">
          <div class="title">
            <span class="title1">Group</span>
            <span class="title2">堆积量</span>
          </div>
          <div
            class="chart"
            v-for="(item, index) in chartList1"
            :key="item.consumer"
          >
            <div class="ranking">
              <div class="rank" v-if="index == '0'"><div>Top1</div></div>
              <div class="rankAfter" v-if="index > '0'">
                <div>Top{{ index + 1 }}</div>
              </div>
              {{ item.consumer }}
            </div>
            <div :id="`myChart${index}`" class="chartList"></div>
            <div class="num">
              <div>当前值：{{ item.currentLag }}</div>
            </div>
          </div>
        </div>
        <div class="tab">
          <div class="title">
            <span class="title1">Group</span>
            <span class="title2">消费消息量</span>
          </div>
          <div
            class="chart"
            v-for="(item, index) in chartList2"
            :key="item.consumer"
          >
            <div class="ranking">
              <div class="rank" v-if="index == '0'"><div>Top1</div></div>
              <div class="rankAfter" v-if="index > '0'">
                <div>Top{{ index + 1 }}</div>
              </div>
              {{ item.consumer }}
            </div>
            <div :id="`myChart2${index}`" class="chartList"></div>
            <div class="num">
              <div>总量：{{ item.sumCount }}万</div>
              <div>最大值：{{ item.maxCount }}</div>
              <div>最小值：{{ item.minCount }}</div>
            </div>
          </div>
        </div>
        <div class="tab">
          <div class="title">
            <span class="title1">Group</span>
            <span class="title2">消费消息TPS</span>
          </div>
          <div
            class="chart"
            v-for="(item, index) in chartList3"
            :key="item.consumer"
          >
            <div class="ranking">
              <div class="rank" v-if="index == '0'"><div>Top1</div></div>
              <div class="rankAfter" v-if="index > '0'">
                <div>Top{{ index + 1 }}</div>
              </div>
              {{ item.consumer }}
            </div>
            <div :id="`myChart3${index}`" class="chartList"></div>
            <div class="num">
              <div>最大值：{{ item.maxTps }}</div>
              <div>最小值：{{ item.minTps }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as api from "../lib/topic.js";
import moment from "moment";
// 引入基本模板
import * as echarts from "echarts";
// 引入折线图组件
require("echarts/lib/chart/bar");

export default {
  name: "topic",
  props: ["clusterId"],
  data() {
    return {
      topic: "",
      produceCount: "",
      producerTps: "",
      consumeCount: "",
      consumerTps: "",
      startTime: null,
      endTime: null,
      maxCount: "",
      minCount: "",
      maxTps: "",
      minTps: "",
      chartList1: [],
      chartList2: [],
      chartList3: [],
      relativeTime: "30",
      relativeList: [
        {
          value: "5分钟",
          key: "5",
        },
        {
          value: "10分钟",
          key: "10",
        },
        {
          value: "15分钟",
          key: "15",
        },
        {
          value: "30分钟",
          key: "30",
        },
        {
          value: "1小时",
          key: "60",
        },
        {
          value: "3小时",
          key: "180",
        },
        {
          value: "6小时",
          key: "360",
        },
        {
          value: "24小时",
          key: "1440",
        },
      ],
      charts: [],
      timeType: "relative",
      timeTypeList: [
        { label: "相对时间", value: "relative" },
        { label: "绝对时间", value: "absolute" },
      ],
    };
  },
  watch: {
    clusterId: {
      handler() {
        this.topic = this.$route.query.topic;
        this.relativeTime = "30";
        this.query1();
      },
    },
  },
  created() {
    if (this.clusterId) {
      this.topic = this.$route.query.topic;
      this.relativeTime = "30";
      this.query1();
    }
  },
  methods: {
    back() {
      this.$router.back();
    },
    query() {
      this.getListProductItem();
      this.getListTopic();
    },
    query1() {
      if (this.relativeTime) {
        let nowdate = new Date();
        let startTime = new Date(nowdate - this.relativeTime * 60 * 1000);
        let endTime = new Date();
        let startTime1 = moment(startTime, "YYYY-MM-DD HH:mm:ss");
        let endTime1 = moment(endTime, "YYYY-MM-DD HH:mm:ss");
        this.startTime = this.$common.transformTime(startTime1);
        this.endTime = this.$common.transformTime(endTime1);
      }
      this.getListProductItem();
      this.getListTopic();
    },
    //   获取所有topic
    getListTopic() {
      let data = {
        topic: this.topic,
        startTime: this.startTime,
        endTime: this.endTime,
        clusterId: this.clusterId,
      };
      api.topicStat(data).then((res) => {
        if (res.result === 200) {
          this.produceCount = res.data.produceCount;
          this.producerTps = res.data.producerTps;
          this.consumeCount = res.data.consumeCount;
          this.consumerTps = res.data.consumerTps;
        }
      });
    },
    // 时间选择
    onChange(value, dateString) {
      this.startTime = dateString[0];
      this.endTime = dateString[1];
    },
    onOk(value) {},
    // 查询指定topic生产者时序数据
    getListProductItem() {
      let data = {
        topic: this.topic,
        startTime: this.startTime,
        endTime: this.endTime,
        clusterId: this.clusterId,
      };
      api.listProductItem(data).then((res) => {
        if (res.result === 200) {
          this.maxCount = res.data.maxCount;
          this.minCount = res.data.minCount;
          this.maxTps = res.data.maxTps;
          this.minTps = res.data.minTps;
          // 基于准备好的dom，初始化echarts实例   producerTpsChart
          let myChart = echarts.init(document.getElementById("myChart"));
          let producerTpsChart = echarts.init(
            document.getElementById("producerTpsChart")
          );
          // 绘制图表
          myChart.setOption({
            title: {
              show: false,
              text: "生产消息量",
              textStyle: {
                fontSize: 10,
              },
            },
            color: ["#FF6A00"],
            tooltip: {
              trigger: "axis",
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: {
              show: false,
              type: "category",
              boundaryGap: false,
              data: res.data.collectTimeList,
            },
            yAxis: {
              axisLabel: {
                show: false,
              },
              type: "value",
              splitLine: {
                //网格线
                lineStyle: {
                  type: "dashed", //设置网格线类型 dotted：虚线 solid:实线
                },
                show: true, //隐藏或显示
              },
            },
            series: [
              {
                name: "生产消息量",
                type: "line",
                data: res.data.countList,
                showSymbol: false,
              },
            ],
          });
          producerTpsChart.setOption({
            title: {
              show: false,
              text: "生产消息Tps",
              textStyle: {
                fontSize: 10,
              },
            },
            color: ["#FF6A00"],
            tooltip: {
              trigger: "axis",
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: {
              show: false,
              type: "category",
              boundaryGap: false,
              data: res.data.collectTimeList,
            },
            yAxis: {
              axisLabel: {
                show: false,
              },
              type: "value",
              splitLine: {
                //网格线
                lineStyle: {
                  type: "dashed", //设置网格线类型 dotted：虚线 solid:实线
                },
                show: true, //隐藏或显示
              },
            },
            series: [
              {
                name: "生产消息Tps",
                type: "line",
                data: res.data.tpsList,
                showSymbol: false,
              },
            ],
          });
          this.charts.push(myChart);
          this.charts.push(producerTpsChart);
          this.getListConsumerTimeSerialData();
        }
      });
    },
    // 查询指定topic消费者时序数据
    getListConsumerTimeSerialData() {
      let data = {
        topic: this.topic,
        startTime: this.startTime,
        endTime: this.endTime,
        clusterId: this.clusterId,
      };
      api.listConsumerTimeSerialData(data).then((res) => {
        if (res.result === 200) {
          this.chartList1 = res.data.consumerLagGroupList;
          this.chartList2 = res.data.consumeCountGroupList;
          this.chartList3 = res.data.consumerTpsGroupList;
          this.$nextTick(() => {
            this.chartList1.forEach((item, i) => {
              let myChart = echarts.init(
                document.getElementById("myChart" + i)
              );
              myChart.setOption({
                title: {
                  show: false,
                  text: "堆积量",
                  textStyle: {
                    fontSize: 10,
                  },
                },
                color: ["#FF6A00"],
                tooltip: {
                  trigger: "axis",
                },
                grid: {
                  left: "3%",
                  right: "4%",
                  bottom: "3%",
                  containLabel: true,
                },
                xAxis: {
                  show: false,
                  type: "category",
                  boundaryGap: false,
                  data: item.collectTimeList,
                },
                yAxis: {
                  type: "value",
                  axisLabel: {
                    show: false,
                  },
                  splitLine: {
                    //网格线
                    lineStyle: {
                      type: "dashed", //设置网格线类型 dotted：虚线 solid:实线
                    },
                    show: true, //隐藏或显示
                  },
                },
                series: [
                  {
                    name: "堆积量",
                    type: "line",
                    data: item.lagList,
                    showSymbol: false,
                  },
                ],
              });
              this.charts.push(myChart);
            });
            this.chartList2.forEach((item, i) => {
              let myChart2 = echarts.init(
                document.getElementById("myChart2" + i)
              );
              myChart2.setOption({
                title: {
                  show: false,
                  text: "消费消息量",
                  textStyle: {
                    fontSize: 10,
                  },
                },
                color: ["#FF6A00"],
                tooltip: {
                  trigger: "axis",
                },
                grid: {
                  left: "3%",
                  right: "4%",
                  bottom: "3%",
                  containLabel: true,
                },
                xAxis: {
                  show: false,
                  type: "category",
                  boundaryGap: false,
                  data: item.collectTimeList,
                },
                yAxis: {
                  type: "value",
                  axisLabel: {
                    show: false,
                  },
                  splitLine: {
                    //网格线
                    lineStyle: {
                      type: "dashed", //设置网格线类型 dotted：虚线 solid:实线
                    },
                    show: true, //隐藏或显示
                  },
                },
                series: [
                  {
                    name: "消费消息量",
                    type: "line",
                    data: item.countList,
                    showSymbol: false,
                  },
                ],
              });
              this.charts.push(myChart2);
            });
            this.chartList3.forEach((item, i) => {
              let myChart3 = echarts.init(
                document.getElementById("myChart3" + i)
              );
              myChart3.setOption({
                title: {
                  show: false,
                  text: "消费消息TPS",
                  textStyle: {
                    fontSize: 10,
                  },
                },
                color: ["#FF6A00"],
                tooltip: {
                  trigger: "axis",
                },
                grid: {
                  left: "3%",
                  right: "4%",
                  bottom: "3%",
                  containLabel: true,
                },
                xAxis: {
                  show: false,
                  type: "category",
                  boundaryGap: false,
                  data: item.collectTimeList,
                },
                yAxis: {
                  type: "value",
                  axisLabel: {
                    show: false,
                  },
                  splitLine: {
                    //网格线
                    lineStyle: {
                      type: "dashed", //设置网格线类型 dotted：虚线 solid:实线
                    },
                    show: true, //隐藏或显示
                  },
                },
                series: [
                  {
                    name: "消费消息TPS",
                    type: "line",
                    data: item.tpsList,
                    showSymbol: false,
                  },
                ],
              });
              this.charts.push(myChart3);
            });
            echarts.connect(this.charts);
          });
        }
      });
    },
    // tabs切换
    callback(key) {},
  },
};
</script>
<style lang="scss" scoped>
.back {
  display: inline-block;
  cursor: pointer;
  font-size: 20px;
  color: black;
  font-weight: 500;
  padding-bottom: 10px;
}
.nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid #eceef2;
  border-radius: 8px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  .top_item {
    margin: 15px 0;
    border-right: 1px solid #dbdbdb;
    text-align: center;
    width: 100%;
    height: 60px;
    .top_num {
      font-size: 33px;
      font-family: "DINCondensed-Bold", Arial, sans-serif;
      font-weight: 400;
      cursor: pointer;
    }
    .top_txt {
      font-size: 13px;
    }
  }
  .top_item:last-child {
    border-right: 0px solid #dbdbdb;
  }
}
.content {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .card {
    padding: 10px;
    border: 1px solid #eceef2;
    width: 49%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    .tab {
      position: relative;

      .title {
        position: absolute;
        top: 0;
        width: 100%;
        height: 40px;
        background-color: #f9f9f9;
        border-top: 1px solid #eceef2;
        border-bottom: 1px solid #eceef2;
        span {
          position: absolute;
          top: 10px;
        }
        .title1 {
          left: 20px;
        }
        .title2 {
          left: 100px;
        }
      }
      .chart {
        display: flex;
        border-bottom: 1px solid #eceef2;
        .num {
          margin-top: 80px;
          font-size: 12px;
        }
        .ranking {
          width: 90px;
          margin-top: 70px;
          word-break: break-all;
          font-size: 12px;
          .rank {
            width: 40px;
            height: 20px;
            background-color: #fed9f2;
            margin-left: 25px;
            border-radius: 5px;
            div {
              color: #ed2943;
              margin: 0 auto;
              text-align: center;
              font-style: oblique;
            }
          }
          .rankAfter {
            width: 40px;
            height: 20px;
            margin-left: 25px;
            border-radius: 5px;
            div {
              color: #1bb178;
              margin: 0 auto;
              text-align: center;
              font-style: oblique;
            }
          }
        }
        .num {
          margin-top: 70px;
          font-size: 12px;
        }
        .chartList {
          width: 65%;
          height: 150px;
        }
      }
      #myChart {
        width: 80%;
        height: 150px;
      }
      #producerTpsChart {
        width: 80%;
        height: 150px;
      }
    }
  }
}
</style>

<style lang="scss">
.topic {
  .ant-tabs-bar {
    margin: 0 !important;
  }
}
</style>
