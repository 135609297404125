<template>
  <li
    class="m-right-menu-item"
    @click="click($event)"
    :class="{
      disabled: disabled,
    }"
  >
    <slot></slot>
  </li>
</template>
<script>
export default {
  name: "mRightMenuItem",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    click(e) {
      if (this.disabled) {
        return;
      }
      this.$emit("click", e);
    },
  },
};
</script>
<style lang="scss" scoped>
.m-right-menu-item {
  margin: 0;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
}

.m-right-menu-item:hover {
  background: #c1dbf7;
  color: #fff;
}

.m-right-menu-item.disabled {
  cursor: not-allowed;
}

.m-right-menu-item.disabled:hover {
  background: #c8c9cc;
}
</style>
