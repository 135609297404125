<template>
  <div class="mqCluster">
    <div class="searchButton">
      <a-button type="primary" @click="query(1)" icon="reload">刷新</a-button>
    </div>
    <div class="chart">
      <div id="myChart"></div>
    </div>
    <div class="brokerTitle">Broker列表</div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :loading="loadingTable"
      size="small"
    >
      <span slot="putTps" slot-scope="text">
        {{ text.split(".")[0] + "." + text.split(".")[1].substr(0, 2) }}
      </span>
      <span slot="getMissTps" slot-scope="text">
        {{ text.split(".")[0] + "." + text.split(".")[1].substr(0, 2) }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="statusClick(record)">STATUS</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="configClick(record)">CONFIG</a>
      </span>
      <span slot="yesProduce" slot-scope="text, record">
        {{
          record.msgPutTotalTodayMorning - record.msgPutTotalYesterdayMorning
        }}
      </span>
      <span slot="yesConsume" slot-scope="text, record">
        {{
          record.msgGetTotalTodayMorning - record.msgGetTotalYesterdayMorning
        }}
      </span>
      <span slot="todProduce" slot-scope="text, record">
        {{ record.msgPutTotalTodayNow - record.msgPutTotalTodayMorning }}
      </span>
      <span slot="todConsume" slot-scope="text, record">
        {{ record.msgGetTotalTodayNow - record.msgGetTotalTodayMorning }}
      </span>
      <span slot="progress" slot-scope="text, record">
        <a-tooltip>
          <template slot="title">
            {{
              (
                record.commitLogDirCapacity
                  .split(",")[0]
                  .split(":")[1]
                  .split("G")[0] -
                record.commitLogDirCapacity
                  .split(",")[1]
                  .split(":")[1]
                  .split("G")[0]
              ).toFixed(1)
            }}G/{{
              record.commitLogDirCapacity
                .split(",")[0]
                .split(":")[1]
                .split("G")[0]
            }}G
            <!-- 123 -->
          </template>
          <a-progress :percent="text" />
        </a-tooltip>
      </span>
      <span slot="runtime" slot-scope="text">
        {{
          text.replace(/\[|]/g, "").split(",")[0] +
          text.replace(/\[|]/g, "").split(",")[1]
        }}
      </span>
    </a-table>
    <!-- status弹框 -->
    <a-modal
      :title="statusTitle"
      v-model="statusVisible"
      :maskClosable="false"
      :afterClose="statusClose"
      width="800px"
    >
      <div class="config" v-for="(item, key) in statusObj" :key="key">
        <div>{{ key }}</div>
        <div>{{ item }}</div>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="statusVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
    <!-- config弹框 -->
    <a-modal
      :title="configTitle"
      v-model="configVisible"
      :maskClosable="false"
      :afterClose="configClose"
      width="800px"
    >
      <div class="config" v-for="(item, key) in configObj" :key="key">
        <div>{{ key }}</div>
        <div>{{ item }}</div>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="configVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/mqCluster.js";
// 引入基本模板
import * as echarts from "echarts";
// 引入折线图组件
require("echarts/lib/chart/bar");

export default {
  name: "mqCluster",
  props: ["clusterId"],
  data() {
    return {
      topic: undefined,
      topicSelect: [],
      loading: false,
      tableColumns: [
        {
          title: "Broker",
          ellipsis: true,
          dataIndex: "name",
          width: 120,
        },
        {
          title: "NO.",
          ellipsis: true,
          dataIndex: "noMessage",
        },
        {
          title: "Address",
          ellipsis: true,
          dataIndex: "address",
        },
        {
          title: "Version",
          ellipsis: true,
          dataIndex: "brokerVersionDesc",
        },
        {
          title: "Produce Massage TPS",
          ellipsis: true,
          dataIndex: "putTps",
          scopedSlots: { customRender: "putTps" },
        },
        {
          title: "Consumer Massage TPS",
          ellipsis: true,
          dataIndex: "getMissTps",
          scopedSlots: { customRender: "getMissTps" },
        },
        {
          title: "Yesterday Produce Count",
          ellipsis: true,
          dataIndex: "yesProduce",
          scopedSlots: { customRender: "yesProduce" },
        },
        {
          title: "Yesterday Consume Count",
          ellipsis: true,
          dataIndex: "yesConsume",
          scopedSlots: { customRender: "yesConsume" },
        },
        {
          title: "Today Produce Count",
          ellipsis: true,
          dataIndex: "todProduce",
          scopedSlots: { customRender: "todProduce" },
        },
        {
          title: "Today Consume Count",
          ellipsis: true,
          dataIndex: "todConsume",
          scopedSlots: { customRender: "todConsume" },
        },
        {
          title: "磁盘占用",
          ellipsis: true,
          dataIndex: "progress",
          scopedSlots: { customRender: "progress" },
          width: 200,
        },
        {
          title: "运行时长",
          ellipsis: true,
          dataIndex: "runtime",
          width: 200,
          scopedSlots: { customRender: "runtime" },
        },
        {
          title: "Operation",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      loadingTable: false,
      configTitle: "",
      configVisible: false,
      configObj: {},
      statusTitle: "",
      statusVisible: false,
      statusObj: {},
    };
  },
  watch: {
    clusterId: {
      handler() {
        this.query();
        this.sevenDayProduceInfo();
      },
    },
  },
  created() {
    if (this.clusterId) {
      this.query();
      this.sevenDayProduceInfo();
    }
  },
  methods: {
    // 获取近7天的消息产生量
    sevenDayProduceInfo() {
      api.sevenDayProduceInfo({ clusterId: this.clusterId }).then((res) => {
        if (res.result === 200) {
          // 基于准备好的dom，初始化echarts实例
          let myChart = echarts.init(document.getElementById("myChart"));
          // 绘制图表
          myChart.setOption({
            title: {
              text: "近30天消息量",
            },
            color: ["#91cc75"],
            tooltip: {
              trigger: "axis",
            },
            legend: {
              data: ["近7天的消息产生量"],
            },
            grid: {
              left: "3%",
              right: "7%",
              bottom: "3%",
              // top: "3%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: res.data.dateList,
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                name: "当日消息产生量/万",
                type: "line",
                data: res.data.countList,
              },
            ],
          });
        }
      });
    },
    // 点击查询
    query() {
      this.topicSelect = [];
      this.clusterList();
      this.sevenDayProduceInfo();
    },
    // 查询所有cluster
    clusterList() {
      this.loadingTable = true;
      api
        .clusterList({ clusterId: this.clusterId })
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let objAll = res.data.brokerServer;
            let objAddrs = res.data.clusterInfo.brokerAddrTable;
            let arr = [];
            for (var i in objAll) {
              console.log(i, objAll[i], objAddrs[i]);
              for (var item in objAll[i]) {
                arr.push(objAll[i][item]);
                objAll[i][item].name = i;
                objAll[i][item].address = objAddrs[i].brokerAddrs[item];
                if (item == 0) {
                  objAll[i][item].noMessage = item + "(master)";
                } else {
                  objAll[i][item].noMessage = item + "(slave)";
                }
              }
            }
            arr.forEach((item, i) => {
              item.progress = parseInt(
                ((item.commitLogDirCapacity
                  .split(",")[0]
                  .split(":")[1]
                  .split("G")[0] -
                  item.commitLogDirCapacity
                    .split(",")[1]
                    .split(":")[1]
                    .split("G")[0]) /
                  item.commitLogDirCapacity
                    .split(",")[0]
                    .split(":")[1]
                    .split("G")[0]) *
                  100
              );
            });
            this.tableDataSource = arr;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 点击config
    configClick(val) {
      this.configVisible = true;
      this.configTitle = val.name;
      api
        .brokerConfig({ brokerAddr: val.address, clusterId: this.clusterId })
        .then((res) => {
          if (res.result === 200) {
            this.configObj = res.data;
          }
        });
    },
    configClose() {},
    // 点击status
    statusClick(val) {
      this.statusVisible = true;
      this.statusTitle = val.name;
      this.statusObj = val;
    },
    statusClose() {},
  },
};
</script>
<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 250px;
  margin-bottom: 10px;
  #myChart {
    width: 100%;
    height: 100%;
  }
}
.brokerTitle {
  font-size: 18px;
  font-weight: bold;
  margin-left: 5px;
  margin-bottom: 10px;
}
.config {
  line-height: 40px;
  width: 100%;
  border-right: 1px solid #adadad;
  border-left: 1px solid #adadad;
  border-bottom: 1px solid #adadad;
  display: inline-flex;
  div:nth-child(1) {
    width: 40%;
    padding-left: 10px;
    font-size: 15px;
    border-right: 1px solid #adadad;
  }
  div:nth-child(2) {
    width: 60%;
    padding-left: 10px;
    font-size: 16px;
  }
}
.config:nth-child(1) {
  border-top: 1px solid #adadad;
}
</style>
