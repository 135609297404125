<template>
  <div>
    <div
      :class="{
        workbenchCol: true,
        last_index: length - 1 == index && !item.parentId,
      }"
      :style="{ backgroundColor: item.outOfDate ? '#ff01010f' : '#ffffff' }"
    >
      <div
        class="row font"
        :style="{
          width: columns[0].width,
          display: 'flex',
          flexWrap: 'nowrap',
        }"
        @click="edit()"
        @mouseenter="titleShow = true"
        @mouseleave="titleShow = false"
      >
        <span style="margin-left: 20px">
          <a-checkbox
            @change="onCheck"
            @click.stop="stop"
            v-model="item.check"
          ></a-checkbox>
        </span>
        <span
          style="margin-left: 5px"
          v-show="item.childTaskList"
          @click.stop="childTaskShow"
        >
          <a-icon type="caret-right" v-show="!item.childrenShow" />
          <a-icon type="caret-down" v-show="item.childrenShow" />
        </span>
        <span style="margin-left: 5px" v-show="!item.childTaskList"></span>
        <span style="margin-left: 30px" v-show="item.parentId"></span>
        <a-tooltip>
          <template slot="title"> {{ item.title }} </template>
          <span
            style="
              width: 75%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            "
          >
            <span style="color: #262626">
              {{ item.title }}
            </span>
          </span>
        </a-tooltip>
        <span
          v-show="titleShow && !item.parentId"
          @click.stop="addChildrenTask"
        >
          <a-icon type="plus" style="font-size: 16px" />
        </span>
        <div
          :class="{
            borderRight: true,
            borderHover: hoverIndex == 0 && borderHoverShow,
          }"
          @mouseenter="resizeHover($event, 0)"
          @mouseleave="resizeHide(0)"
        ></div>
      </div>
      <div class="row" :style="{ width: columns[1].width }">
        <div class="executorList" @click.stop="listFix">
          <template v-if="item.executorList.length > 0">
            <template v-for="(executorItem, index) in item.executorList">
              <div
                class="executor"
                @mouseenter="executorItem.iconShow = true"
                @mouseleave="executorItem.iconShow = false"
              >
                <a-icon
                  type="close-circle"
                  class="icon_remove"
                  v-show="executorItem.iconShow && item.isCreator"
                  @click.stop="deleteExecutor(index)"
                />
                <a-avatar size="small" :src="executorItem.avatar" />
                <span class="name" style="margin-left: 5px">{{
                  executorItem.userName
                }}</span>
              </div>
            </template>
          </template>
          <div class="executor" v-else>
            <span class="name">待设置</span>
          </div>
        </div>
        <div
          class="nameList"
          v-show="item.listShow"
          ref="nameList"
          @click.stop="stop"
        >
          <div class="search">
            <a-input
              v-model="name"
              ref="search"
              placeholder="搜索"
              @change="getList(false)"
              @blur="getList(true)"
            />
          </div>
          <div class="userlist">
            <template v-if="projectMemberList.length > 0">
              <div
                v-for="item in projectMemberList"
                :key="item.userId"
                class="userItem"
                @click.stop="checkUser(item)"
              >
                <a-avatar :src="item.avatar" />
                <span style="margin-left: 15px">{{ item.userName }}</span>
              </div>
            </template>
            <a-empty v-else />
          </div>
        </div>
        <div
          :class="{
            borderRight: true,
            borderHover: hoverIndex == 1 && borderHoverShow,
          }"
          @mouseenter="resizeHover($event, 1)"
          @mouseleave="resizeHide(1)"
        ></div>
      </div>

      <template v-if="type !== 'INVENTORY_TASK'">
        <div
          class="row"
          :style="{ width: columns[2].width, paddingLeft: '8px' }"
        >
          <a-select
            v-model="item.state"
            class="priority"
            @change="stateChange(false, item.id, item.state)"
            style="background-color: transparent"
            :dropdownMatchSelectWidth="false"
          >
            <a-select-option
              v-for="state in stateList"
              :key="state.value"
              v-if="state.show || item.isCreator || screen.finished == 'yes'"
            >
              <a-tag
                style="
                  cursor: pointer;
                  height: 28px;
                  line-height: 26px;
                  font-size: 14px;
                  text-align: center;
                "
                :color="state.color"
                >{{ state.label }}</a-tag
              >
            </a-select-option>
          </a-select>
          <a-tooltip
            v-if="type !== 'SCHEDULE_TASK' && !item.stateSame"
            overlayClassName="stateTooltip"
          >
            <template slot="title">
              <template v-for="(executorItem, index) in item.executorList">
                <div style="margin-top: 5px">
                  {{ executorItem.userName }}:&nbsp;<a-tag
                    style="
                      cursor: pointer;
                      height: 28px;
                      line-height: 26px;
                      font-size: 14px;
                      text-align: center;
                    "
                    :color="executorItem.stateColor"
                    >{{ executorItem.stateLabel }}</a-tag
                  >
                </div>
              </template>
            </template>
            <a-icon type="info-circle" style="font-size: 14px" />
          </a-tooltip>
          <div
            :class="{
              borderRight: true,
              borderHover: hoverIndex == 2 && borderHoverShow,
            }"
            @mouseenter="resizeHover($event, 2)"
            @mouseleave="resizeHide(2)"
          ></div>
        </div>
        <div
          class="row"
          :style="{ width: columns[3].width, paddingLeft: '20px' }"
        >
          {{ item.startDateLabel }}
          <a-date-picker
            format="YYYY-MM-DD"
            v-model="item.startDate"
            class="date"
            ref="startDate"
            @change="startDateChange"
          />
          <div
            :class="{
              borderRight: true,
              borderHover: hoverIndex == 3 && borderHoverShow,
            }"
            @mouseenter="resizeHover($event, 3)"
            @mouseleave="resizeHide(3)"
          ></div>
        </div>
        <div
          class="row"
          :style="{
            width: columns[4].width,
            paddingLeft: '20px',
            color: item.endDateColor,
          }"
        >
          {{ item.endDateLabel }}
          <a-date-picker
            format="YYYY-MM-DD"
            v-model="item.endDate"
            class="date"
            ref="endDate"
            @change="endDateChange"
          />
          <div
            :class="{
              borderRight: true,
              borderHover: hoverIndex == 4 && borderHoverShow,
            }"
            @mouseenter="resizeHover($event, 4)"
            @mouseleave="resizeHide(4)"
          ></div>
        </div>
        <div
          class="row"
          :style="{ width: columns[5].width, paddingLeft: '8px' }"
        >
          <a-select
            v-model="item.priority"
            class="priority"
            @change="priorityChange"
            style="background-color: transparent"
            :dropdownMatchSelectWidth="false"
          >
            <a-select-option v-for="item in priorityList" :key="item.value">
              <a-tag
                style="
                  cursor: pointer;
                  height: 28px;
                  line-height: 26px;
                  font-size: 14px;
                  text-align: center;
                  background-color: #ffffff;
                "
                :color="item.color"
                >{{ item.label }}</a-tag
              >
            </a-select-option>
          </a-select>
          <div
            :class="{
              borderRight: true,
              borderHover: hoverIndex == 5 && borderHoverShow,
            }"
            @mouseenter="resizeHover($event, 5)"
            @mouseleave="resizeHide(5)"
          ></div>
        </div>
        <div
          class="row"
          v-if="type == 'SCHEDULE_TASK'"
          :style="{ width: columns[6].width, textAlign: 'center' }"
        >
          <a-button type="link" style="font-size: 14px" @click="scheduleTaskNow"
            >立即创建</a-button
          >
        </div>
      </template>
    </div>
    <div v-if="item.childrenShow && item.childTaskList">
      <template v-for="(childTask, childIndex) in item.childTaskList">
        <workbench-col
          :item="childTask"
          :index="childIndex"
          :priorityList="priorityList"
          :stateList="stateList"
          :hoverIndex="hoverIndex"
          :borderHoverShow="borderHoverShow"
          :columns="columns"
          :projectMemberList="projectMemberList"
          :length="item.childTaskList.length"
          :type="type"
          :screen="screen"
          @edit="edit"
          @changeDate="changeDate"
          @onCheck="onCheck"
          @resizeHide="resizeHide"
          @resizeHover="resizeHover"
          @add="add"
          @getProjectMemberList="getProjectMemberList"
          @listAni="listAni"
          @stateChange="stateChange"
        ></workbench-col>
      </template>
    </div>
  </div>
</template>

<script>
import * as api from "../lib/workbench.js";

export default {
  name: "workbenchCol",
  props: [
    "item",
    "index",
    "priorityList",
    "stateList",
    "hoverIndex",
    "borderHoverShow",
    "columns",
    "projectMemberList",
    "length",
    "screen",
    "type",
  ],
  data() {
    return {
      name: "",
      titleShow: false,
    };
  },
  watch: {
    "item.startDate": {
      handler() {
        this.$emit("changeDate");
      },
    },
    "item.endDate": {
      handler() {
        this.$emit("changeDate");
      },
    },
    "item.listShow": {
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.name = "";
            this.$refs.search.focus();
          });
        }
      },
    },
  },
  computed: {
    getCalendarContainer() {
      return (triggerNode) => triggerNode.parentNode;
    },
    formatDateEndTime() {
      // 开始时间
      if (this.item.endDate) {
        return this.$common.dayFormat(this.item.endDate) + " 23:59:59";
      }
      return "";
    },
    formatDateStartTime() {
      // 结束时间
      if (this.item.startDate) {
        return this.$common.dayFormat(this.item.startDate) + " 00:00:00";
      }
      return "";
    },
  },
  methods: {
    scheduleTaskNow() {
      api.scheduleTaskNow({ id: this.item.id }).then((res) => {
        if (res.result == 200) {
          this.$message.success("执行成功");
        }
      });
    },
    changeDate() {
      this.$emit("changeDate");
    },
    childTaskShow() {
      this.$emit("childTaskShow", this.item);
    },
    addChildrenTask() {
      this.$emit("add", this.item.title, this.item.id);
    },
    add(title, id) {
      this.$emit("add", title, id);
    },
    resizeHide(index) {
      this.$emit("resizeHide", index);
    },
    resizeHover(e, index) {
      this.$emit("resizeHover", e, index);
    },
    listFix(e) {
      this.$emit("listAni", this.index, true);
      let clientHeight = document.body.clientHeight;
      let y = e.pageY;
      if (clientHeight - y >= 330) {
        this.$refs.nameList.style.top = e.pageY - e.layerY + 40 + "px";
      } else {
        this.$refs.nameList.style.bottom = "0px";
      }
    },
    listAni(index, listShow) {
      this.$emit("listAni", index, listShow);
    },
    onCheck() {
      this.$emit("onCheck");
    },
    stop() {},
    updateExecutor() {
      let executorList = [];
      this.item.executorList.forEach((item) => {
        executorList.push(item.executor);
      });
      api.updateExecutor({ id: this.item.id, executorList, type: "EXECUTOR" });
    },
    deleteExecutor(index) {
      this.item.executorList.splice(index, 1);
      this.updateExecutor();
    },
    checkUser(item) {
      this.item.executorList.push({
        executor: item.loginName,
        avatar: item.avatar,
        userName: item.userName,
        iconShow: false,
      });
      this.$emit("listAni", this.index, false);
      this.updateExecutor();
    },
    getList(blur) {
      if (blur) {
        this.$emit("getProjectMemberList", "");
      } else {
        this.$emit("getProjectMemberList", this.name);
      }
    },
    getProjectMemberList(name) {
      this.$emit("getProjectMemberList", name);
    },
    startDateChange() {
      this.updateStartEndTime(this.formatDateStartTime, true);
    },
    endDateChange() {
      this.updateStartEndTime(this.formatDateEndTime, false);
    },
    updateStartEndTime(dateTime, start) {
      api.updateStartEndTime({ id: this.item.id, dateTime, start });
    },
    priorityChange() {
      api.updatePriority({ id: this.item.id, priority: this.item.priority });
    },
    stateChange(edit, id, state) {
      this.$emit("stateChange", edit, id, state);
    },
    onCheckAll() {},
    edit() {
      this.$emit("edit", this.item);
    },
  },
};
</script>

<style lang="scss">
.last_index {
  border-bottom: none !important;
}
.workbenchCol {
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  overflow: hidden;

  .font {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Helvetica Neue, PingFang SC, Noto Sans, Noto Sans CJK SC, Microsoft YaHei,
      \\5FAE\8F6F\96C5\9ED1, sans-serif;
  }
  .row {
    height: 40px;
    line-height: 40px;
    border-right: 1px solid #e5e5e5;
    position: relative;
    float: left;

    .borderRight {
      height: 40px;
      line-height: 40px;
      position: absolute;
      top: 0;
      right: 0;
      content: "";
      border-right: 2px solid transparent;
    }
    .borderHover {
      border-right: 2px solid #1b9aee;
      cursor: col-resize;
    }
    .executorList {
      position: relative;
      display: flex;
      .executor {
        line-height: 30px;
        margin-top: 5px;
        padding: 0 10px;
        cursor: pointer;
        border-radius: 9999px;
        box-sizing: border-box;
        position: relative;

        span {
          vertical-align: middle;
        }
        &:hover {
          background: #ebebeb;
        }
        .icon_remove {
          font-size: 16px;
          display: none;
          vertical-align: top;
          position: absolute;
          right: -5px;
          top: -5px;
          z-index: 1;
          cursor: pointer;
          color: #bfbfbf;
          width: 20px;
          height: 20px;
          line-height: 20px;
          display: inline-block;
          &:hover {
            color: #1b9aee;
          }
        }
      }
    }
    .nameList {
      position: fixed;
      width: 300px;
      left: 391px;
      background-color: #ffffff;
      border-radius: 4px;
      z-index: 9999;
      box-shadow: 0 2px 20px rgb(0 0 0 / 10%);
      .search {
        padding: 16px;
      }
      .userlist {
        width: 100%;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
        .userItem {
          padding: 8px 16px;
          cursor: pointer;
          &:hover {
            background: #f7f7f7;
            color: #1b9aee;
          }
        }
      }
    }
    .date {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;

      .ant-calendar-picker-container {
        top: 40px !important;
      }
      .ant-calendar-picker-input {
        cursor: pointer !important;
        opacity: 0;
      }
      i {
        opacity: 0;
      }
    }
    .priority {
      width: 60px;
      height: 100%;
      .ant-select-selection {
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
        -webkit-transition: all 0s !important;
        transition: all 0s !important;
        background-color: transparent !important;
        &:focus {
          border: none !important;
          box-shadow: none !important;
          outline: none !important;
        }
        .ant-select-arrow {
          display: none;
        }
        .ant-select-selection__rendered {
          margin: 0 !important;
        }
      }
    }
  }
  &:hover {
    background: #f7f7f7;
    cursor: pointer;
    .ant-select-selection {
      background: #f7f7f7 !important;
    }
  }
}
.ant-tag {
  font-size: 12px !important;
}
.stateTooltip {
  .ant-tooltip-arrow {
    &::before {
      background-color: #fff !important;
    }
  }

  .ant-tooltip-inner {
    background-color: #fff !important;
    box-shadow: none !important;
    box-shadow: 0 4px 16px 0 rgb(0 0 0 / 15%) !important;
    color: #bfbfbf;
  }
}
</style>
