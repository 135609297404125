let watermark = {};

const debounce = (func, delay) => {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), delay);
  };
};

watermark.set = (text, sourceBody) => {
  const id = "watermark-" + Date.now(); // 使用当前时间戳生成唯一ID

  // 创建水印容器
  const water_div = document.createElement("div");
  water_div.id = id;
  water_div.style.pointerEvents = "none";
  water_div.style.position = "absolute";
  water_div.style.width = "100%";
  water_div.style.height = "100%";
  water_div.style.zIndex = "9999";
  water_div.style.top = "0";
  water_div.style.left = "0";

  // 创建 canvas
  const can = document.createElement("canvas");
  const context = can.getContext("2d");

  // 创建一个函数来绘制水印
  const drawWatermark = () => {
    // 确保获取最新的容器高度
    sourceBody.style.position = "relative"; // 确保 sourceBody 是相对定位的
    const containerWidth = sourceBody.clientWidth;
    const containerHeight = window.innerHeight - 40;
    console.log(containerHeight);

    if (containerWidth === 0 || containerHeight === 0) {
      return; // 防止容器尺寸为 0 时绘制水印
    }

    can.width = containerWidth; // 设置canvas宽度为容器宽度
    can.height = containerHeight; // 设置canvas高度为容器高度

    context.clearRect(0, 0, can.width, can.height); // 清除之前的内容
    context.font = "15px Vedana";
    context.fillStyle = "#bababa10";
    context.textAlign = "left";
    context.textBaseline = "middle";

    const rows = Math.ceil(containerHeight / 150); // 根据容器高度计算行数
    const cols = Math.ceil(containerWidth / 250); // 根据容器宽度计算列数

    // 生成水印文本
    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < cols; col++) {
        const x =
          (containerWidth / cols) * col +
          (row % 2) * (containerWidth / (cols * 2)); // 水平错位
        const y = (containerHeight / rows) * row + 15; // 垂直位置

        // 保存上下文状态
        context.save();
        context.translate(x, y); // 移动到文本位置
        context.rotate((-30 * Math.PI) / 180); // 旋转文本
        context.fillText(text, 0, 0); // 在旋转后的坐标位置绘制文本
        context.restore(); // 恢复上下文状态
      }
    }

    // 设置水印的背景图
    water_div.style.background =
      "url(" + can.toDataURL("image/png") + ") repeat";

    // 如果水印还未添加，则添加水印到目标DOM
    if (!sourceBody.contains(water_div)) {
      sourceBody.appendChild(water_div);
    }
  };

  // 使用 MutationObserver 观察子元素变化
  const observer = new MutationObserver(
    debounce(() => {
      drawWatermark(); // 子元素变化时绘制水印
    }, 300)
  );

  observer.observe(sourceBody, {
    childList: true,
    subtree: true,
  });

  // 初始调用，确保在页面加载后绘制水印
  window.addEventListener("load", () => {
    // 等待子元素完全加载后绘制水印
    drawWatermark();
  });

  // 监听窗口调整大小事件，使用防抖处理
  window.addEventListener(
    "resize",
    debounce(() => {
      drawWatermark(); // 窗口大小调整时重新绘制水印
    }, 300)
  );

  // 可选：在某个时间后停止观察
  setTimeout(() => {
    observer.disconnect(); // 停止观察
  }, 5000); // 例如5秒后停止观察
};

// 导出水印模块
export default watermark;
