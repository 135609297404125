import http from "./axios";


export const deliveryRequestAddNew = (data) => {
  return http.json_post("/delivery/deliveryRequest/addNew", data);
};


// 判断是否是可发布时间段
export const ifCanDelivery = (data) => {
  return http.get("/delivery/ifCanDelivery", data);
};
