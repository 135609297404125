<template>
  <div class="batchExecute">
    <div class="tag">
      <span v-for="item in tags" :key="item">
        <span v-for="i in serverIdList" :key="i.id">
          <a-tag
            v-if="item === i.id"
            color="#108ee9"
            closable
            @close="() => handleClose(item)"
            >{{ i.title }}</a-tag
          >
        </span>
      </span>
      <span v-for="item in groupTag" :key="item">
        <span v-for="i in groupIdList" :key="i.id">
          <a-tag
            v-if="item === i.id"
            color="#108ee9"
            closable
            @close="() => handleGroupClose(item)"
            >{{ i.name }}</a-tag
          >
        </span>
      </span>
    </div>
    <div class="btn">
      <a-button icon="plus" @click="addServer">从主机列表中选择</a-button>

      <a-button icon="plus" @click="addGroupServer">从服务器组中选择</a-button>
    </div>
    <div>执行命令：</div>
    <div class="content">
      <codemirror
        ref="myCode"
        v-model="detailValue"
        :options="temOptions"
        class="code_style"
      >
      </codemirror>
    </div>
    <div class="btn">
      <a-popconfirm
        title="确定要执行吗?"
        ok-text="是"
        cancel-text="否"
        @confirm="begin"
      >
        <a-button icon="thunderbolt" type="primary"> 开始执行 </a-button>
      </a-popconfirm>
    </div>
    <!-- 新增服务器 -->
    <a-modal
      title="选择服务器"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="服务器:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="serverId"
            placeholder="试试搜索"
            showSearch
            option-filter-prop="children"
            style="width: 300px"
            allowClear
            mode="multiple"
          >
            <a-select-option v-for="item in serverIdList" :key="item.id">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button key="submit" type="primary" @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!--  -->
    <a-modal
      title=""
      v-model="beginVisible"
      :maskClosable="false"
      :afterClose="beginClose"
      width="800px"
    >
      <codemirror
        v-if="beginVisible"
        ref="myCode"
        v-model="beginValue"
        :options="temOptions"
        class="code_style"
      >
      </codemirror>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="beginVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 新增服务器组 -->
    <a-modal
      title="选择服务器"
      v-model="groupVisible"
      :maskClosable="false"
      :afterClose="groupClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="服务器:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="groupId"
            placeholder="试试搜索"
            showSearch
            option-filter-prop="children"
            style="width: 300px"
            allowClear
            mode="multiple"
          >
            <a-select-option v-for="item in groupIdList" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="groupVisible = false">取消</a-button>
          <a-button key="submit" type="primary" @click="group_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/batchExecute.js";
import { serverList } from "../lib/serverList.js";

export default {
  name: "batchExecute",
  data() {
    return {
      temOptions: {
        mode: "text/javascript",
        theme: "default",
        readOnly: false,
        lineNumbers: true,
        line: true,
        styleActiveLine: true, //设置光标所在行高亮，需引入工具包
        placeholder: "输入内容",
      },
      tags: [],
      groupTag: [],
      addKeyVisible: false,
      serverId: [],
      serverIdList: [],
      detailValue: "",
      beginVisible: false,
      beginValue: "",
      timer: null,
      groupVisible: false,
      groupId: [],
      groupIdList: [],
    };
  },
  mounted() {
    this.getServerAll();
    this.getGroupServer();
  },
  methods: {
    // 移除标签
    handleClose(removedTag) {
      // console.log(removedTag);
      const tags = this.tags.filter((tag) => tag !== removedTag);
      // console.log(tags);
      this.tags = tags;
    },
    handleGroupClose(removedTag) {
      // console.log(removedTag);
      const groupTag = this.groupTag.filter((tag) => tag !== removedTag);
      // console.log(tags);
      this.groupTag = groupTag;
    },
    // 查询所有服务器
    getServerAll() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        enabled: true,
      };
      serverList(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item) => {
            item.title = item.publicIp + "(" + item.instanceName + ")";
          });
          this.serverIdList = list;
        }
      });
    },
    getGroupServer() {
      let data = {};
      api.groupServer(data).then((res) => {
        if (res.result === 200) {
          this.groupIdList = res.data.serverGroup;
        }
      });
    },
    // 点击新增服务器组
    addGroupServer() {
      this.groupVisible = true;
      this.getGroupServer();
      this.groupId = this.groupTag;
    },
    group_submit() {
      this.groupVisible = false;
      this.groupTag = this.groupId;
    },
    groupClose() {},
    // 点击新增服务器
    addServer() {
      this.addKeyVisible = true;
      this.getServerAll();
      this.serverId = this.tags;
    },
    addKeyClose() {
    },
    add_submit() {
      this.tags = this.serverId;
      this.addKeyVisible = false;
    },
    // 开始执行
    begin() {
      this.beginValue = "";
      let data = {
        serverIdList: this.tags,
        groupIdList: this.groupTag,
        commandList: this.detailValue.replace(/\n/g, "").split(";"),
      };
      api.batchExecute(data).then((res) => {
        if (res.result === 200) {
          this.beginVisible = true;
          this.timer = setInterval(() => {
            this.beginTask(res.data);
          }, 1000);
        }
      });
    },
    beginTask(uuid) {
      api.getExecuteProcess({ uuid: uuid }).then((res) => {
        if (res.result === 200) {
          setTimeout(() => {
            this.beginValue = res.data;
          }, 500);
        }
      });
    },
    beginClose() {
      this.beginValue = "";
      clearInterval(this.timer);
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); //关闭定时器
    }
  },
};
</script>
<style>
.code_style .CodeMirror {
  height: 400px !important;
}
.code_style .CodeMirror-lines {
  padding: 0px;
}
.code_style .CodeMirror .CodeMirror-line {
  font-size: 14px !important;
  line-height: 150%;
  color: #4d4d4c;
  font-family: arial;
}
.code_style .CodeMirror .CodeMirror-placeholder {
  font-size: 13px;
  line-height: 150%;
  color: #8e908c;
  font-family: arial;
}
</style>
<style lang="scss" scoped>
.tag {
  height: 40px;
}
.btn {
  margin-top: 20px;
  margin-bottom: 10px;
}
.content {
  margin-top: 10px;
}
</style>
