<template>
  <div class="generatorCode">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="模块列表">
        <router-view name="three" v-if="active == '1'" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="手动上传">
        <router-view name="three" v-if="active == '2'" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="pom分析">
        <router-view name="three" v-if="active == '3'" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "mvnManagement",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/mvnManagement/moduleList") {
          this.active = "1";
        } else if (newVal == "/mvnManagement/manualUpload") {
          this.active = "2";
        } else if (newVal == "/mvnManagement/projectPom") {
          this.active = "3";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/mvnManagement/moduleList") return;
          this.$router.replace("/mvnManagement/moduleList");
        }
        if (newVal === "2") {
          if (this.$route.path == "/mvnManagement/manualUpload") return;
          this.$router.replace("/mvnManagement/manualUpload");
        }
        if (newVal === "3") {
          if (this.$route.path == "/mvnManagement/projectPom") return;
          this.$router.replace("/mvnManagement/projectPom");
        }
      },
    },
  },
};
</script>
