import http from "./axios";

// 查询列表
const queryPage = ({ serverId, port, system, remark, pageNo, pageSize }) => {
  return http.get("/serverPortInfo/page", {
    serverId,
    port,
    system,
    remark,
    pageNo,
    pageSize,
  });
};

// 添加
const addData = ({ serverId, port, system, remark }) => {
  return http.json_post("/serverPortInfo/add", {
    serverId,
    port,
    system,
    remark,
  });
};

// 编辑
const editData = ({ id, serverId, port, system, remark }) => {
  return http.json_post("/serverPortInfo/edit", {
    id,
    serverId,
    port,
    system,
    remark,
  });
};

// 删除
const deleteData = ({ id }) => {
  return http.json_post("/serverPortInfo/delete", {
    id,
  });
};

export default {
  deleteData,
  queryPage,
  addData,
  editData,
};
