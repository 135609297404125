<template>
  <div class="nodeList">
    <div class="reminder">节点列表 <span>nodeList</span></div>
    <div class="searchCondition">
      <div class="searchConditionItem">
        <a-checkbox v-model="innerLabelCondition" @change="labelFilter()">
          只看内部标签
        </a-checkbox>
      </div>
      <div class="searchButton">
        <a-dropdown :disabled="selectedRowKeys.length == 0">
          <a-button type="primary"> 批量操作 <a-icon type="down" /> </a-button>
          <a-menu slot="overlay">
            <a-menu-item>
              <a href="javascript:;" @click="batchSchedulableOnChange(false)">
                批量可调度
              </a>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="batchSchedulableOnChange(true)">
                批量不可调度
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="false"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.nodeId"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <span slot="nodeId" slot-scope="text, record">
        <a-tooltip overlayClassName="nodeId">
          <template slot="title">
            ip: {{ record.ip }}<br />
            serverId: {{ record.serverId }}
          </template>
          {{ record.nodeId }}
        </a-tooltip>
      </span>
      <span slot="usedMemoryRate" slot-scope="text, record">
        <a-tooltip>
          <template slot="title">
            已使用: {{ record.usedMemory }}<br />
            总量: {{ record.totalMemory }}
          </template>
          {{ record.usedMemoryRate }}
        </a-tooltip>
      </span>
      <span slot="schedulableTitle">
        调度设置
        <a-tooltip placement="left">
          <div slot="title">不可调度节点不会被分配新的 pod</div>
          <a-icon
            type="question-circle"
            style="color: #1879ff; font-size: 14px"
          />
        </a-tooltip>
      </span>
      <span slot="schedulable" slot-scope="text, record">
        <a-switch
          v-model="text"
          checkedChildren="可调度"
          unCheckedChildren="不可调度"
          @change="schedulableOnChange(record)"
        />
      </span>
      <span slot="label" slot-scope="text, record">
        <template v-for="(value, key) in record.label">
          <a-tag style="margin-top: 5px"> {{ key }}:{{ value }} </a-tag><br />
        </template>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button type="link" style="padding: 0" @click="editLabel(record)"
          >编辑标签</a-button
        >
        <a-divider type="vertical" />
        <a-button type="link" style="padding: 0" @click="getPodList(record)"
          >Pod列表</a-button
        >
      </span>
    </a-table>

    <a-modal
      title="编辑标签"
      v-model="editLabelShow"
      :maskClosable="false"
      width="800px"
    >
      <div class="searchButton">
        <a-button type="primary" @click="addLabel" icon="plus">新增</a-button>
      </div>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(labelColumns)"
        :columns="labelColumns"
        :dataSource="labelList"
        :pagination="false"
        size="small"
        :rowKey="(record) => record.label"
      >
        <span slot="action" slot-scope="text, record, index">
          <a-popconfirm
            placement="right"
            okText="确认"
            cancelText="取消"
            @confirm="removeLabel(record, index)"
          >
            <template slot="title">确认是否删除{{ record.label }}</template>
            <a href="javascript:;" style="color: #ff4d4f">删除</a>
          </a-popconfirm>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="editLabelShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="新增标签"
      v-model="addLabelShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
        <a-form-item label="label:">
          <a-input
            placeholder="请输入label"
            v-model.trim="addLabelData.label"
            :addon-before="innerLabelAddonBefore"
            allowClear
          />
        </a-form-item>
        <a-form-item label="value:">
          <a-input
            placeholder="请输入value"
            v-model.trim="addLabelData.value"
            allowClear
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addLabelShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="addLabelSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-drawer
      title="节点pod列表"
      placement="right"
      :visible="nodePodShow"
      @close="nodePodShow = false"
      width="1000"
    >
      <div class="searchCondition">
        <div class="searchConditionItem">
          通道名称：
          <a-input
            placeholder="请输入活动名称"
            v-model="filterPodName"
            @keyup.enter="filterPodList()"
            @change="allowClearPodChange"
            allowClear
          />
        </div>
        <div class="searchButton">
          <a-button type="primary" @click="filterPodList()" icon="search"
            >查询</a-button
          >
          <a-button type="primary" @click="getPodList()" icon="sync"
            >刷新</a-button
          >
        </div>
      </div>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(nodePodColumns)"
        :scroll="{ x: '100%', y: nodePodHeight }"
        :columns="nodePodColumns"
        :dataSource="nodePodList"
        :pagination="false"
        size="small"
        :rowKey="(record) => record.name"
        :row-selection="{
          selectedRowKeys: selectedNodePodRowKeys,
          onChange: onSelectNodePodChange,
        }"
        @change="handlePodTableChange"
      >
      </a-table>
      <a-popconfirm
        placement="right"
        okText="确认"
        cancelText="取消"
        @confirm="batchDeleteClick()"
        :disabled="selectedNodePodRowKeys.length == 0"
      >
        <template slot="title">确认是否批量删除</template>
        <a-button
          type="danger"
          icon="delete"
          style="margin-top: 10px"
          :disabled="selectedNodePodRowKeys.length == 0"
          >批量删除</a-button
        >
      </a-popconfirm>
    </a-drawer>
  </div>
</template>
<script>
import * as api from "../lib/k8s.js";

export default {
  name: "nodeList",
  data() {
    return {
      innerLabelCondition: true,
      tableColumns: [
        {
          title: "名称",
          dataIndex: "nodeId",
          scopedSlots: { customRender: "nodeId" },
        },
        {
          title: "内存占用率",
          dataIndex: "usedMemoryRate",
          scopedSlots: { customRender: "usedMemoryRate" },
          align: "center",
          width: 100,
        },
        {
          dataIndex: "schedulable",
          scopedSlots: {
            title: "schedulableTitle",
            customRender: "schedulable",
          },
          align: "center",
          width: 120,
        },
        {
          title: "标签",
          dataIndex: "label",
          scopedSlots: { customRender: "label" },
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 180,
        },
      ],
      tableDataSource: [],
      loadingTable: false,
      selectedRowKeys: [],
      selectedRows: [],
      nodeId: "",
      labelColumns: [
        {
          title: "label",
          dataIndex: "label",
        },
        {
          title: "value",
          dataIndex: "value",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 100,
        },
      ],
      labelList: [],
      editLabelShow: false,
      addLabelShow: false,
      addLabelData: {
        label: "",
        value: "",
      },
      innerLabelAddonBefore: "delivery.huiyu.org.cn/",
      nodePodColumns: [
        {
          title: "名称",
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
          ellipsis: true,
        },
        {
          title: "状态",
          dataIndex: "status",
          width: 100,
        },
        {
          title: "重启次数",
          dataIndex: "restartCount",
          width: 100,
        },
        {
          title: "cpu",
          dataIndex: "cpu",
          width: 150,
          sorter: (a, b) => a.cpuAmount - b.cpuAmount,
          sortOrder: false,
        },
        {
          title: "内存",
          dataIndex: "memory",
          width: 150,
          sorter: (a, b) => a.memoryAmount - b.memoryAmount,
          sortOrder: false,
        },
      ],
      nodePodList: [],
      allNodePodList: [],
      selectedNodePodRowKeys: [],
      selectedNodePodRows: [],
      filterPodName: "",
      nodePodShow: false,
    };
  },
  computed: {
    nodePodHeight() {
      let height = window.innerHeight - 240;
      return height;
    },
  },
  created() {
    this.query();
  },
  methods: {
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    query() {
      this.loadingTable = true;
      api
        .nodeList()
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource = res.data;
            this.tableDataSource.forEach((item) => {
              this.$set(
                item,
                "usedMemoryRate",
                ((item.usedMemory / item.totalMemory) * 100).toFixed(2) + "%"
              );
              this.$set(item, "allLabel", this.$common.deepClone(item.label));
            });
            this.labelFilter();
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    labelFilter() {
      if (this.innerLabelCondition) {
        this.tableDataSource.forEach((item) => {
          this.$set(item, "label", this.getInnerLabel(item.allLabel));
        });
      } else {
        this.tableDataSource.forEach((item) => {
          this.$set(item, "label", this.$common.deepClone(item.allLabel));
        });
      }
    },
    getInnerLabel(allLabel) {
      let label = this.$common.deepClone(allLabel);
      for (const key in label) {
        if (key.indexOf(this.innerLabelAddonBefore) == -1) {
          delete label[key];
        }
      }
      return label;
    },
    schedulableOnChange(val) {
      this.$confirm({
        title: "确认修改",
        content: (h) => <div style="color:red;">确定要修改节点调度状态?</div>,
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.loadingTable = true;
          let data = {
            nodeId: val.nodeId,
            unschedulable: val.schedulable,
          };
          api
            .markUnschedulable(data)
            .then((res) => {
              if (res.result == 200) {
                this.$message.success("修改成功");
                this.loadingTable = false;
                this.query();
              }
            })
            .catch((err) => {
              this.loadingTable = false;
            });
        },
        onCancel: () => {},
      });
    },
    batchSchedulableOnChange(unschedulable) {
      this.$confirm({
        title: "确认修改",
        content: (h) => <div style="color:red;">确定要修改节点调度状态?</div>,
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          let arr = this.selectedRows.filter((item) => {
            return item.schedulable == unschedulable;
          });
          if (arr.length > 0) {
            this.loadingTable = true;
            this.$axios
              .all(
                arr.map((item) => {
                  let data = {
                    nodeId: item.nodeId,
                    unschedulable,
                  };
                  return api.markUnschedulable(data);
                })
              )
              .then((resArr) => {
                this.$message.success("修改成功");
                this.loadingTable = false;
                this.query();
                this.selectedRowKeys.splice(0);
                this.selectedRows.splice(0);
              })
              .catch((err) => {
                this.loadingTable = false;
              });
          } else {
            this.selectedRowKeys.splice(0);
            this.selectedRows.splice(0);
          }
        },
        onCancel: () => {},
      });
    },
    editLabel(val) {
      this.nodeId = val.nodeId;
      this.labelList = Object.entries(this.getInnerLabel(val.allLabel)).map(
        ([label, value]) => ({
          label,
          value,
        })
      );
      this.editLabelShow = true;
    },
    addLabel() {
      this.addLabelData = {
        label: "",
        value: "",
      };
      this.addLabelShow = true;
    },
    addLabelSubmit() {
      if (!this.addLabelData.label || !this.addLabelData.value) {
        this.$message.warning("请填写标签信息");
        return;
      }
      let addLabelData = { ...this.addLabelData };
      addLabelData.label = this.innerLabelAddonBefore + addLabelData.label;

      let data = {
        nodeId: this.nodeId,
        ...addLabelData,
      };
      api.addLabelToNode(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("添加成功");
          this.addLabelShow = false;
          this.labelList.push(addLabelData);
        }
      });
    },
    removeLabel(val, index) {
      let data = {
        nodeId: this.nodeId,
        label: val.label,
      };
      api.removeLabelFromNode(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("删除成功");
          this.labelList.splice(index, 1);
        }
      });
    },
    onSelectNodePodChange(selectedRowKeys, selectedRows) {
      this.selectedNodePodRowKeys = selectedRowKeys;
      this.selectedNodePodRows = selectedRows;
    },
    handlePodTableChange(pagination, filters, sorter) {
      this.nodePodColumns.forEach((item) => {
        if (item.dataIndex == sorter.columnKey) {
          this.$set(item, "sortOrder", sorter.order);
        }
      });
    },
    allowClearPodChange(e) {
      if (e.target.value) {
        return;
      }
      this.filterPodList();
    },
    filterPodList() {
      if (!this.filterPodName) {
        this.nodePodList.splice(0);
        this.nodePodList.push(...this.allNodePodList);
      } else {
        this.nodePodList = this.allNodePodList.filter((item) => {
          return item.name.indexOf(this.filterPodName) !== -1;
        });
      }
    },
    getPodList(val) {
      if (val) {
        this.nodeId = val.nodeId;
        this.filterPodName = "";
        this.nodePodColumns.forEach((item) => {
          if (item.dataIndex == "cpu" || item.dataIndex == "memory") {
            this.$set(item, "sortOrder", false);
          }
        });
      }
      let data = {
        nodeId: this.nodeId,
        namespace: "全部命名空间",
      };
      api.podList(data).then((res) => {
        if (res.result == 200) {
          this.nodePodList = res.data;
          this.$axios
            .all(
              this.nodePodList.map((item) => {
                let params = {
                  namespace: item.namespace,
                  podName: item.name,
                };
                return api.getPodMetric(params);
              })
            )
            .then((resArr) => {
              resArr.forEach((res, index) => {
                this.$set(this.nodePodList[index], "cpu", res.data.cpu);
                this.$set(
                  this.nodePodList[index],
                  "cpuAmount",
                  res.data.cpuAmount
                );
                this.$set(this.nodePodList[index], "memory", res.data.memory);
                this.$set(
                  this.nodePodList[index],
                  "memoryAmount",
                  res.data.memoryAmount
                );
              });
              this.allNodePodList.push(...this.nodePodList);
            });
          this.nodePodShow = true;
        }
      });
    },
    batchDeleteClick() {
      this.$axios
        .all(
          this.selectedNodePodRows.map((item) => {
            let data = {
              name: item.name,
              namespace: item.namespace,
            };
            return api.deletePod(data);
          })
        )
        .then((resArr) => {
          this.$message.success("删除成功");
          this.getPodList();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.nodeId {
  .ant-tooltip-inner {
    width: 280px;
  }
}
</style>
