<template>
  <div class="subprojectList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        IP地址：
        <a-input
          placeholder="请输入项目的IP地址"
          v-model.trim="proName"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(columns)"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="pagination"
      size="small"
    >
      <span slot="enName" slot-scope="text, record" style="width: 100%">
        <a href="javascript:;" @click="publish(record)">{{ text }}</a>
      </span>
    </a-table>
  </div>
</template>
<script>
import { projectItemList } from "../lib/subprojectList.js";
export default {
  name: "subprojectList",
  data() {
    return {
      proName: "",
      columns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "英文名称",
          ellipsis: true,
          dataIndex: "enName",
          scopedSlots: { customRender: "enName" },
        },
        {
          title: "中文名称",
          ellipsis: true,
          dataIndex: "cnName",
        },
        {
          title: "ip地址",
          ellipsis: true,
          dataIndex: "ip",
        },
        {
          title: "上次发布时间",
          ellipsis: true,
          dataIndex: "lastDeliveryDate",
        },
        {
          title: "uuid",
          ellipsis: true,
          dataIndex: "currentUuid",
        },
        {
          title: "发布次数",
          ellipsis: true,
          dataIndex: "deliveryTimes",
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
        },
      ],
      dataSource: [],
      pagination: {},
      pageNo: 1,
    };
  },
  mounted() {
    this.query();
  },
  methods: {
    // 发布
    publish(val) {
      let judge = "";
      if (val.projectType == "JAVA") {
        judge = true;
      } else {
        judge = null;
      }
      this.$router.replace({
        path: "/detail",
        query: {
          id: val.projectId,
          judge: judge,
        },
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query() {
      let data = {
        pageNo: 1,
        pageSize: 10,
        ip: this.proName,
      };
      this.getProjectItemList(data);
    },
    // 查询子项目列表
    getProjectItemList(data) {
      projectItemList(data).then((res) => {
        if (res.result == 200) {
          let list = res.data.list;
          list.forEach((item, i) => {
            item.key = i + 1;
            item.lastDeliveryDate = this.$common.transformTime(
              item.lastDeliveryDate
            );
            if (item.status == "RUNNING") {
              item.status = "启动中";
            } else {
              item.status = "停止";
            }
          });
          this.dataSource = list;
          this.pagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.count}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.count,
            onChange: (current) => this.changePageItem(current),
          };
        }
      });
    },
    // 分页
    changePageItem(current) {
      this.pageNo = current;
      let data = {
        pageNo: current,
        pageSize: 10,
        ip: this.proName,
      };
      this.getProjectItemList(data);
    },
  },
};
</script>
<style lang="scss" scoped></style>
