<template>
  <div class="otherDeploy">
    <a-tabs v-model="activeKey">
      <a-tab-pane key="1" tab="局域网">
        <div class="searchCondition">
          <div class="searchButton">
            <a-button type="primary" @click="queryLan(1)" icon="search"
              >查询</a-button
            >
          </div>
        </div>
        <a-table
          :rowClassName="$common.rowClassColor"
          bordered
          :components="$common.getTitle(lanColumns)"
          :columns="lanColumns"
          :dataSource="lanDataSource"
          :pagination="lanPagination"
          :loading="lanLoading"
          size="small"
        >
          <span slot="action" slot-scope="text, record" style="width: 100%">
            <a href="javascript:;" @click="hostClick(record)">host管理</a>
          </span>
        </a-table>
        <!-- host管理 -->
        <a-modal
          title="host管理"
          v-model="hostShow"
          :maskClosable="false"
          :afterClose="hostClose"
          width="1000px"
        >
          <codemirror
            v-if="hostShow"
            ref="myCode"
            v-model="hostContent"
            :options="temOptions"
            class="code_style"
          >
          </codemirror>
          <template slot="footer">
            <div style="display: flex; justify-content: center">
              <a-button key="back" @click="hostShow = false">取消</a-button>
              <a-popconfirm
                title="确定要同步到服务器吗?"
                ok-text="确定"
                cancel-text="取消"
                @confirm="add_submit"
              >
                <a-button key="submit" type="primary" :loading="btnLoading"
                  >同步到服务器</a-button
                >
              </a-popconfirm>
            </div>
          </template>
        </a-modal>
      </a-tab-pane>
      <a-tab-pane key="2" tab="编译机">
        <router-view name="three" v-if="activeKey == '2'" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="环境管理">
        <router-view name="three" v-if="activeKey == '3'" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import * as api from "../lib/otherDeploy";
export default {
  name: "otherDeploy",
  data() {
    return {
      temOptions: {
        mode: "text/javascript",
        theme: "default",
        readOnly: false,
        lineNumbers: true,
        line: true,
        styleActiveLine: true, //设置光标所在行高亮，需引入工具包
        placeholder: "输入内容",
      },
      hostShow: false,
      hostContent: "",
      endSymbol: ";",
      hostId: "",
      btnLoading: false,
      envShow: false,
      activeKey: "1",
      lanColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "key",
          width: 60,
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      lanDataSource: [],
      lanPagination: {},
      lanLoading: false,

      uuid: "",
      timer: null,
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/otherDeploy") {
          this.activeKey = "1";
        } else if (newVal == "/otherDeploy/compiler") {
          this.activeKey = "2";
        } else if (newVal == "/otherDeploy/environment") {
          this.activeKey = "3";
        }
      },
    },
    activeKey: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/otherDeploy") return;
          this.$router.replace("/otherDeploy");
        } else if (newVal === "2") {
          if (this.$route.path == "/otherDeploy/compiler") return;
          this.$router.replace("/otherDeploy/compiler");
        } else if (newVal === "3") {
          if (this.$route.path == "/otherDeploy/environment") return;
          this.$router.replace("/otherDeploy/environment");
        }
      },
    },
  },
  created() {
    this.queryLan(1);
  },
  methods: {
    // 点击查询局域网列表
    queryLan(index) {
      this.getLanInfoList();
    },
    // 查询局域网列表
    getLanInfoList() {
      this.lanLoading = true;
      api
        .lanInfoList()
        .then((res) => {
          this.lanLoading = false;
          if (res.result === 200) {
            let list = res.data;
            list.forEach((item, index) => {
              item.key = index + 1;
            });
            this.lanDataSource = list;
          }
        })
        .catch((err) => {
          this.lanLoading = false;
        });
    },
    // host管理
    hostClick(val) {
      this.hostId = val.id;
      this.hostShow = true;
      api.hostList({ id: val.id }).then((res) => {
        if (res.result === 200) {
          if (res.data.length < 1) {
            this.endSymbol = "";
          }
          let list = res.data.join("\n");
          setTimeout(() => {
            this.hostContent = list;
          }, 500);
        }
      });
    },
    // 关闭host管理
    hostClose() {
      this.hostContent = "";
      this.endSymbol = ";";
    },
    // host同步确定
    add_submit() {
      this.btnLoading = true;
      let data = {
        id: this.hostId,
        // hosts: this.hostContent.replace(/[\r\n]/g, ""),
        hosts: this.hostContent,
      };
      api
        .synHost(data)
        .then((res) => {
          if (res.result === 200) {
            // this.btnLoading = false;
            // this.hostShow = false;
            // this.$message.success(res.data);
            // this.queryLan(1);
            this.uuid = res.data;
            this.timer = setInterval(() => {
              this.getSynHostResult();
            }, 3000);
          }
        })
        .catch((err) => {
          this.btnLoading = false;
        });
    },
    // uuid轮询
    getSynHostResult() {
      let data = {
        uuid: this.uuid,
      };
      api.synHostResult(data).then((res) => {
        if (res.result === 200) {
          if (res.data !== null) {
            this.$message.success(res.data);
            clearInterval(this.timer);
            this.btnLoading = false;
            this.hostShow = false;
          }
        }
      });
    },
  },
};
</script>
<style>
.code_style .CodeMirror {
  height: 600px;
}
.code_style .CodeMirror-lines {
  padding: 0px;
}
.code_style .CodeMirror .CodeMirror-line {
  font-size: 13px;
  line-height: 150%;
  color: #4d4d4c;
  font-family: arial;
}
.code_style .CodeMirror .CodeMirror-placeholder {
  font-size: 13px;
  line-height: 150%;
  color: #8e908c;
  font-family: arial;
}
</style>
<style lang="scss" scoped></style>
