<template>
  <div class="monitor">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="普通模板">
        <ordinary-file-list
          v-if="active == '1'"
          :nginxInstanceId="nginxInstanceId"
        ></ordinary-file-list>
      </a-tab-pane>
      <a-tab-pane key="2" tab="系统模板">
        <system-file-list
          v-if="active == '2'"
          :nginxInstanceId="nginxInstanceId"
        ></system-file-list>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import OrdinaryFileList from "./ordinaryFileList.vue";
import SystemFileList from "./systemFileList.vue";

export default {
  name: "fileList",
  components: { OrdinaryFileList, SystemFileList },
  props: ["nginxInstanceId"],
  data() {
    return {
      active: "1",
    };
  },
};
</script>
