import { render, staticRenderFns } from "./templateProject.vue?vue&type=template&id=0260e44e&scoped=true"
import script from "./templateProject.vue?vue&type=script&lang=js"
export * from "./templateProject.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0260e44e",
  null
  
)

export default component.exports