import http from "./axios";

export const topicList = ({
  pageNo,
  pageSize,
  categories,
  topic,
  clusterId,
  projectIds,
}) => {
  return http.get("/rocketmq/dashboard/topic/list", {
    pageNo,
    pageSize,
    categories,
    topic,
    clusterId,
    projectIds,
  });
};

// 查询status
export const statsList = ({ topic, clusterId }) => {
  return http.get("/rocketmq/dashboard/topic/stats", {
    topic,
    clusterId,
  });
};

// 查询status
export const configList = ({ topic, clusterId }) => {
  return http.get("/rocketmq/dashboard/topic/examineTopicConfig", {
    topic,
    clusterId,
  });
};
// 查询consumer manage
export const queryConsumerByTopic = ({ topic, clusterId }) => {
  return http.get("/rocketmq/dashboard/topic/queryConsumerByTopic", {
    topic: topic,
    clusterId,
  });
};

// 向指定topic发送消息
export const sendTopicMessage = ({
  topic,
  key,
  tag,
  messageBody,
  clusterId,
}) => {
  return http.json_post("/rocketmq/dashboard/topic/sendTopicMessage", {
    topic: topic,
    key: key,
    tag: tag,
    messageBody: messageBody,
    clusterId,
  });
};

// 查询指定topic的rout信息
export const routeList = ({ topic, clusterId }) => {
  return http.get("/rocketmq/dashboard/topic/route", {
    topic: topic,
    clusterId,
  });
};

// 查询指定topic的所有消费者组
export const reset = ({ topic, clusterId }) => {
  return http.get("/rocketmq/dashboard/topic/queryTopicConsumerInfo", {
    topic: topic,
    clusterId,
  });
};

// 重置消费者的offset
export const resetOffset = ({
  topic,
  force,
  consumerGroupList,
  resetTime,
  clusterId,
}) => {
  return http.json_post("/rocketmq/dashboard/consumer/resetOffset", {
    topic: topic,
    force: force,
    consumerGroupList: consumerGroupList,
    resetTime: resetTime,
    clusterId,
  });
};

// 删除topic
export const deleteTopic = ({ topic, clusterId }) => {
  return http.post("/rocketmq/dashboard/topic/deleteTopic", {
    topic: topic,
    clusterId,
  });
};

// 创建或者更新topic
export const createOrUpdate = ({
  writeQueueNums,
  readQueueNums,
  perm,
  order,
  topicName,
  brokerNameList,
  clusterNameList,
  clusterId,
}) => {
  return http.json_post("/rocketmq/dashboard/topic/createOrUpdate", {
    writeQueueNums: writeQueueNums,
    readQueueNums: readQueueNums,
    perm: perm,
    order: order,
    topicName: topicName,
    brokerNameList: brokerNameList,
    clusterNameList: clusterNameList,
    clusterId,
  });
};

// 获取近7天的消息产生量
export const sevenDayProduceInfo = ({ clusterId }) => {
  return http.get("/rocketmq/dashboard/topic/sevenDayProduceInfo", {
    clusterId,
  });
};

export const bindProject = ({ topicId, projectIdList }) => {
  return http.json_post("/rocketmq/dashboard/topic/bindProject", {
    topicId,
    projectIdList,
  });
};
