<template>
  <div class="shellExecute">
    <div class="searchCondition">
      <div class="searchConditionItem">
        服务器：
        <a-select
          v-model="serverId"
          placeholder="请选择服务器"
          showSearch
          :filter-option="false"
          @search="instanceIdSearch"
          allowClear
        >
          <a-select-option v-for="item in instanceList" :key="item.id">{{
            item.instanceName
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        任务：
        <a-select
          v-model="id"
          placeholder="请选择任务"
          showSearch
          :filter-option="false"
          @search="idSearch"
          allowClear
        >
          <a-select-option v-for="item in idList" :key="item.id">{{
            item.name
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(columns)"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="pagination"
      size="small"
      :loading="loadingTable"
    >
    </a-table>
  </div>
</template>
<script>
import * as api from "../lib/shellExecute.js";
import { serverList } from "../lib/statusList.js";
import { timeTask } from "../lib/shellTiming.js";
export default {
  name: "shellExecute",
  data() {
    return {
      loadingTable: false,
      serverId: undefined,
      instanceList: [],
      id: undefined,
      idList: [],
      columns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "任务",
          ellipsis: true,
          dataIndex: "taskName",
        },
        {
          title: "服务器",
          ellipsis: true,
          dataIndex: "serverName",
        },
        {
          title: "耗时",
          ellipsis: true,
          dataIndex: "seconds",
        },
        {
          title: "错误信息",
          ellipsis: true,
          dataIndex: "content",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
      ],
      dataSource: [],
      pagination: {},
      pageNo: 1,
    };
  },
  mounted() {
    this.query();
    this.getServerList();
    this.getTimeTask();
  },
  methods: {
    //   查询任务
    idSearch(val) {
      this.getTimeTask(val);
    },
    getTimeTask(val) {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        name: val,
      };
      timeTask(data).then((res) => {
        if (res.result === 200) {
          this.idList = res.data.records;
        }
      });
    },
    // 服务器下拉框发生变化
    instanceIdSearch(instanceName) {
      this.getServerList(instanceName);
    },
    // 查询服务器列表
    getServerList(instanceName) {
      let data = {
        instanceName: instanceName,
      };
      serverList(data).then((res) => {
        if (res.result === 200) {
          this.instanceList = res.data;
        }
      });
    },
    // 点击查询
    query() {
      let data = {
        pageNo: 1,
        pageSize: 10,
        serverId: this.serverId,
        id: this.id,
      };
      this.getLogPage(data);
    },
    // 查询shell执行记录
    getLogPage(data) {
      api.logPage(data).then((res) => {
        if (res.result == 200) {
          let list = res.data.records;
          this.dataSource = list;
          this.pagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.changePageItem(current),
          };
        }
      });
    },
    // 分页
    changePageItem(current) {
      this.pageNo = current;
      let data = {
        pageNo: current,
        pageSize: 10,
        serverId: this.serverId,
      };
      this.getLogPage(data);
    },
  },
};
</script>
