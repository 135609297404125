<template>
  <div v-if="show">
    <div class="path">{{ publicPath }}</div>
    <a-descriptions :column="2" class="desc">
      <template v-for="(value, key) in nodeStat">
        <a-descriptions-item :label="key">{{ value }}</a-descriptions-item>
      </template>
    </a-descriptions>
    <div class="textarea">
      <div class="header">
        <a-tooltip>
          <template slot="title"> 刷新 </template>
          <a-button
            type="link"
            icon="reload"
            style="margin-left: 20px; font-size: 20px"
            :loading="loading"
            @click="reload"
          />
        </a-tooltip>
        <a-tooltip>
          <template slot="title"> 保存 </template>
          <a-button
            type="link"
            icon="save"
            style="margin-left: 20px; font-size: 20px"
            :loading="saveLoading"
            @click="save"
          />
        </a-tooltip>
        <div style="float: right; width: 200px; margin-right: 20px">
          <a-select v-model="type" style="width: 100%">
            <a-select-option
              v-for="(item, index) in typeList"
              :key="item.id"
              :value="item.value"
              :label="item.name"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </div>
      </div>
      <a-textarea
        v-model="nodeData"
        :rows="10"
        placeholder="请输入内容"
        allowClear
      />
    </div>
  </div>
</template>

<script>
import api from "../lib/zookeeperUIList.js";

export default {
  name: "zookeeperDetail",
  props: ["nodeData", "nodeStat", "publicPath", "node", "nodeFlag", "id"],
  data() {
    return {
      show: false,
      loading: false,
      saveLoading: false,
      typeList: [{ name: "UTF-8", value: "UTF-8" }],
      type: "UTF-8",
    };
  },
  methods: {
    init() {
      this.show = true;
    },
    reload() {
      this.loading = true;
      this.$emit("nodeDetail", this.node, this.nodeFlag);
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    save() {
      let data = {
        id: this.id,
        path: this.publicPath,
        data: this.nodeData,
      };
      this.saveLoading = true;
      api.setData(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("设置成功");
          this.$emit("nodeDetail", this.node, this.nodeFlag);
          setTimeout(() => {
            this.saveLoading = false;
          }, 1000);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.path {
  height: 50px;
  line-height: 50px;
  font-size: 24px;
  font-weight: 500;
  color: #6200ff;
}
.desc {
  margin-left: 20px;
  margin-top: 20px;
  &::v-deep .ant-descriptions-item-content {
    color: #6200ff !important;
    font-size: 16px !important;
  }
  &::v-deep .ant-descriptions-item-label {
    color: black !important;
    font-size: 16px !important;
    font-weight: 500;
  }
}
.textarea {
  margin-top: 50px;
  border: 1px solid #d9d9d9;
  border-radius: 10px 10px 0 0;
  .header {
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #d9d9d9;
  }
  .ant-input {
    border: none !important;
    &:hover {
      border: none !important;
      box-shadow: none;
    }
    &:focus {
      border: none !important;
      box-shadow: none;
    }
  }
}
</style>
