import http from "./axios";

// 同步ecs服务器
export const syncEcsServerList = () => {
  return http.get("/delivery/server/syncEcsServerList");
};

// 删除服务器
export const serverDelete = (data) => {
  return http.post("/delivery/server/deleteServer", data);
};

// 新增服务器
export const addServer = (data) => {
  return http.json_post("/delivery/server/addServer", data);
};

// 修改服务器
export const updateServer = (data) => {
  return http.json_post("/delivery/server/updateServer", data);
};

// 查询服务器列表
export const serverList = (data) => {
  return http.get("/delivery/server/serverList", data);
};

// 查询服务器分组
export const serverGroupList = () => {
  return http.get("/delivery/serverGroup/list");
};

// 添加服务器分组
export const insertServerGroup = (data) => {
  return http.json_post("/delivery/serverGroup/insertServerGroup", data);
};

// 删除服务器分组
export const deleteServerGroup = (data) => {
  return http.json_post("/delivery/serverGroup/deleteServerGroup", data);
};

// 修改服务器分组
export const modifyServerGroup = (data) => {
  return http.json_post("/delivery/serverGroup/modifyServerGroup", data);
};

// 查询服务器分组详情
export const serverGroupDetail = (data) => {
  return http.get("/delivery/serverGroup/detail", data);
};

// 查询服务器账号列表
export const serverAccountList = (data) => {
  return http.get("/delivery/serverAccount/list", data);
};

// 新增服务器账号列表
export const addAccount = (data) => {
  return http.json_post("/delivery/serverAccount/addAccount", data);
};

// 删除服务器账号
export const deleteAccount = (data) => {
  return http.json_post("/delivery/serverAccount/deleteAccount", data);
};

// 批量授权服务器账号
export const grantAccount = (data) => {
  return http.json_post("/delivery/serverAccount/grantAccount", data);
};

// 批量撤销服务器账号
export const revokeAccount = (data) => {
  return http.json_post("/delivery/serverAccount/revokeAccount", data);
};

// 查询登录方式
export const accountType = () => {
  return http.get("/delivery/serverAccount/accountType");
};

// 查询阿里云主账号集合
export const mainAccountList = () => {
  return http.get("/delivery/aliyun/account/mainAccountList");
};

// 修改实例名称
export const updateServerInstanceName = (data) => {
  return http.json_post("/delivery/server/updateServerInstanceName", data);
};

// 初始化
export const initServer = (data) => {
  return http.json_post("/delivery/server/initServer", data);
};

export const userList = ({ name }) => {
  return http.get("/delivery/user/list", { name });
};
