<template>
  <div class="configAndStorage">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="配置">
        <router-view
          :namespace="namespace"
          :namespaceList="namespaceList"
          name="three"
          v-if="active == '1'"
        />
      </a-tab-pane>
      <a-tab-pane key="2" tab="存储声明">
        <router-view :namespace="namespace" name="three" v-if="active == '2'" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="存储卷">
        <router-view name="three" v-if="active == '3'" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  props: ["namespace", "namespaceList"],
  name: "configAndStorage",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/configAndStorage/configMap") {
          this.active = "1";
        } else if (newVal == "/configAndStorage/pvcList") {
          this.active = "2";
        } else if (newVal == "/configAndStorage/pvList") {
          this.active = "3";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/configAndStorage/configMap") return;
          this.$router.replace("/configAndStorage/configMap");
        }
        if (newVal === "2") {
          if (this.$route.path == "/configAndStorage/pvcList") return;
          this.$router.replace("/configAndStorage/pvcList");
        }
        if (newVal === "3") {
          if (this.$route.path == "/configAndStorage/pvList") return;
          this.$router.replace("/configAndStorage/pvList");
        }
      },
    },
  },
};
</script>
