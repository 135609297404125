import http from "./axios";

// 域名管理列表
export const domainNameList = ({
  pageNo,
  pageSize,
  accountId,
  domainName,
  domainStatus,
  expirationDateStatus,
}) => {
  return http.get("/delivery/aliyun/domainName/domainNameList", {
    pageNo: pageNo,
    pageSize: pageSize,
    domainName: domainName,
    domainStatus: domainStatus,
    expirationDateStatus: expirationDateStatus,
    accountId,
  });
};

// 查询阿里云主账号集合
export const mainAccountList = () => {
  return http.get("/delivery/aliyun/account/mainAccountList", {});
};

// 同步域名
export const synDomainName = ({ accountId }) => {
  return http.post("/delivery/aliyun/domainName/synDomainName", {
    accountId,
  });
};
