<template>
  <div class="nginxServerGroup">
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          v-model.trim="name"
          @pressEnter="query(1)"
          @change="allowClearChange"
          placeholder="请输入名称"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addClick" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="groupName" slot-scope="text, record">
        <editable-cell
          type="groupName"
          :text="text"
          @change="onCellChange(record, $event)"
        />
      </span>
      <span slot="nginxServerCount" slot-scope="text, record">
        <a-tooltip placement="left">
          <span slot="title"> 查看服务器 </span>
          <a-button type="link" @click="useClick(record)">{{ text }}</a-button>
        </a-tooltip>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="takeEffect(record)"
          v-if="record.workSign !== record.lastUpdateSign"
        >
          <template slot="title"
            >该操作将直接影响到nginx监听配置文件，是否继续</template
          >
          <a href="javascript:;" style="color: #ff4d4f">变更生效</a>
        </a-popconfirm>
        <a-divider
          type="vertical"
          v-if="record.workSign !== record.lastUpdateSign"
        />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.groupName }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="nginx服务器组:"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          required
        >
          <a-input v-model="groupName" placeholder="请输入nginx服务器组" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/nginxServerGroup.js";
import { serverList } from "../lib/trustList.js";
import editableCell from "../components/editableCell.vue";

export default {
  name: "nginxServerGroup",
  components: {
    editableCell,
  },
  data() {
    return {
      useAddTitle: "",
      instanceList: [],
      protocolTypeList: [
        {
          key: "HTTP",
          value: "HTTP",
        },
        {
          key: "HTTPS",
          value: "HTTPS",
        },
      ],
      serverId: undefined,
      //   port: "",
      //   weight: "",
      useAddId: "",
      title: "",
      useTitle: "",
      groupName: "",
      forwardToken: "zb20401036@163.com	",
      domain: "huiyu.org.cn	",
      inputRecord: "",
      protocolType: undefined,
      schedulerType: undefined,
      groupId: "",
      schedulerTypeList: [
        {
          key: "ROUND_ROBIN",
          value: "加权轮询",
        },
      ],
      useVisible: false,
      useColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "服务器名称",
          ellipsis: true,
          dataIndex: "instanceName",
        },
        {
          title: "内网ip",
          ellipsis: true,
          dataIndex: "innerIp",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      useDateSource: [],
      usePagination: {},
      useAddVisible: false,
      // -----------------------------
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "nginx服务器组名称",
          ellipsis: true,
          dataIndex: "groupName",
          width: 250,
          scopedSlots: { customRender: "groupName" },
        },
        {
          title: "服务器数量",
          ellipsis: true,
          dataIndex: "nginxServerCount",
          scopedSlots: { customRender: "nginxServerCount" },
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      name: "",
    };
  },
  mounted() {
    this.query(1);
    this.getServerList();
  },
  methods: {
    onCellChange(record, value) {
      this.title = "修改";
      this.groupId = record.id;
      this.groupName = value;
      this.add_submit();
    },
    // 服务器下拉框发生变化
    instanceIdSearch(instanceName) {
      this.getServerList(instanceName);
    },
    // 查询服务器列表
    getServerList(instanceName) {
      let data = {
        instanceName: instanceName,
        enabled: true,
      };
      serverList(data).then((res) => {
        if (res.result === 200) {
          this.instanceList = res.data;
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        groupName: this.name,
        type: "APPLICATION",
      };
      this.listNginxServerGroupList(data);
    },
    // 分页查询nginx服务器组
    listNginxServerGroupList(data) {
      this.loadingTable = true;
      api
        .listNginxServerGroup(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    takeEffect(val) {
      api.effectiveSign({ id: val.id }).then((res) => {
        if (res.result == 200) {
          this.$message.success("生效成功");
          this.query();
        }
      });
    },
    // 新增
    addClick() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.groupName = "";
      this.protocolType = undefined;
      this.schedulerType = undefined;
    },
    // 确定新增
    add_submit() {
      let data = {
        groupName: this.groupName,
        type: "APPLICATION",
      };
      if (this.title === "新增") {
        api.addGroup(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.groupId = this.groupId;
        api.modifyGroup(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.groupName = "";
      this.protocolType = undefined;
      this.schedulerType = undefined;
      this.groupId = "";
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        groupId: val.id,
        type: "APPLICATION",
      };
      api.removeGroup(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    // nginx服务器
    useClick(val) {
      this.$router.push({
        path: "/serverGroupList/nginxServerGroup/nginxServer",
        query: { groupId: val.id, groupName: val.groupName },
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
