import http from "./axios";

// 查询子项目列表
export const projectItemList = ({ pageNo, pageSize, ip }) => {
  return http.get("/delivery/project/projectItemList", {
    pageNo: pageNo,
    pageSize: pageSize,
    ip: ip,
  });
};
