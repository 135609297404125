import http from "./axios";

// 查询账号列表
export const aliyunList = ({ pageNo, pageSize, mainAccountId }) => {
  return http.get("/delivery/aliyun/account/ramList", {
    pageNo: pageNo,
    pageSize: pageSize,
    mainAccountId: mainAccountId,
  });
};

// 查询阿里云主账号集合
export const mainAccountList = () => {
  return http.get("/delivery/aliyun/account/mainAccountList", {});
};

// 权限类型集合
export const policyTypeList = () => {
  return http.get("/policyType/policyTypeList", {});
};
// 账号编码下拉框
export const accountCode = () => {
  return http.get("/delivery/aliyun/account/accountCode", {});
};

// 新增账号列表
export const aliyunAddAccount = ({
  accountName,
  remark,
  displayName,
  policyTypeId,
  parentId,
}) => {
  return http.json_post("/delivery/aliyun/account/addRamAccount", {
    accountName: accountName,
    remark: remark,
    displayName: displayName,
    policyTypeId: policyTypeId,
    parentId: parentId,
  });
};
// 编辑账号列表
export const aliyunEditAccount = ({
  accountName,
  id,
  remark,
  displayName,
  policyTypeId,
}) => {
  return http.json_post("/delivery/aliyun/account/editRamAccount", {
    accountName: accountName,
    id: id,
    remark: remark,
    displayName: displayName,
    policyTypeId: policyTypeId,
  });
};
// 删除账号列表
export const aliyunDeleteAccount = ({ id, parentId }) => {
  return http.json_post("/delivery/aliyun/account/deleteRamAccount", {
    id: id,
    parentId: parentId,
  });
};

// 获取阿里云所有权限集合
export const listPolicies = () => {
  return http.get("/delivery/aliyun/account/listPolicies", {});
};
// 当前账号拥有的权限
export const listAccountPolicies = ({ id }) => {
  return http.get("/delivery/aliyun/account/listAccountPolicies", {
    id: id,
  });
};
// 保存账号权限
export const editAccountPolicy = ({ id, policyList }) => {
  return http.json_post("/delivery/aliyun/account/editAccountPolicy", {
    id: id,
    policyList: policyList,
  });
};
