<template>
  <div class="securityGroupManagement">
    <div class="searchCondition">
      <div class="searchConditionItem">
        安全组名称：
        <a-input
          v-model.trim="name"
          placeholder="请输入安全组名称"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button @click="securityGroupSync">安全组数据同步</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      rowKey="id"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="tableLoading"
      size="small"
    >
      <span slot="securityGroupName" slot-scope="text, record">
        <a-tooltip placement="left">
          <span slot="title"> 查看安全组 </span>
          <a-button
            type="link"
            style="padding: 0"
            @click="securityGroupIdClick(record)"
          >
            {{ record.securityGroupId }}
          </a-button>
        </a-tooltip>
        <div>{{ text }}</div>
      </span>
    </a-table>
  </div>
</template>
<script>
import * as api from "../lib/securityGroupManagement.js";
export default {
  name: "securityGroupManagement",
  data() {
    return {
      name: "",
      pageNo: 1,
      tableColumns: [
        {
          title: "安全组ID/名称",
          ellipsis: true,
          dataIndex: "securityGroupName",
          scopedSlots: { customRender: "securityGroupName" },
        },
        {
          title: "所属专有网络",
          ellipsis: true,
          dataIndex: "vpcId",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "描述",
          ellipsis: true,
          dataIndex: "description",
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      tableLoading: false,
      syncLoading: null,
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    // 点击安全组数据同步
    securityGroupSync() {
      this.syncLoading = this.$syncLoading({ message: "数据同步中..." });
      api
        .syncSecurityGroup()
        .then((res) => {
          this.syncLoading.close();
          this.syncLoading = null;
          if (res.result === 200) {
            this.$message.success("同步成功");
          }
        })
        .catch((err) => {
          this.syncLoading.close();
          this.syncLoading = null;
        });
    },
    // 点击跳转
    securityGroupIdClick(val) {
      this.$router.replace({
        path: "/securityGroupItem",
        query: {
          securityGroupId: val.securityGroupId,
          securityGroupName: val.securityGroupName,
        },
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        securityGroupName: this.name,
      };
      this.getSecurityGroupList(data);
    },
    // 查询安全组管理列表
    getSecurityGroupList(data) {
      this.tableLoading = true;
      api
        .securityGroupList(data)
        .then((res) => {
          this.tableLoading = false;
          if (res.result == 200) {
            let list = res.data.records;
            list.forEach((item, i) => {
              item.key = i + 1;
              item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            });
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
  },
};
</script>
