<template>
  <div class="ramAccountPage">
    <div class="searchButton">
      <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      <a-button type="primary" @click="addClick" icon="plus">新增</a-button>
      <a-button type="primary" @click="goBack">返回</a-button>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">编辑</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.accountName }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="modifyTitle"
      v-model="modifyShow"
      :maskClosable="false"
      :afterClose="modifyClose"
      width="580px"
    >
      <a-form>
        <a-form-item
          label="权限类型:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-select
            v-model="modifyForm.policyTypeId"
            placeholder="请选择权限类型"
            @change="selectChange"
          >
            <a-select-option v-for="item in policyList" :key="item.id">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="账号名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input
            placeholder="请输入账号名称"
            v-model="modifyForm.accountName"
            allowClear
            disabled
          />
        </a-form-item>
        <a-form-item
          label="显示名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input
            placeholder="请输入显示名称"
            v-model="modifyForm.displayName"
            allowClear
            disabled
          />
        </a-form-item>

        <a-form-item
          label="备注:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-textarea
            placeholder="请输入备注"
            v-model="modifyForm.remark"
            allowClear
            :rows="4"
          ></a-textarea>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="modifyShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="modify_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/ramAccountPage.js";
export default {
  name: "ramAccountPage",
  data() {
    return {
      modifyId: "",
      addShow: false,
      accountId: undefined,
      accountList: [],
      // accountTypeList: [],
      // accountCodeList: [],
      // mainShow: false,
      // ramShow: false,
      policyList: [],
      pageNo: 1,
      modalSource: [],
      modalSource1: [],
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "账号名称",
          ellipsis: true,
          dataIndex: "accountName",
        },
        {
          title: "显示名称",
          ellipsis: true,
          dataIndex: "displayName",
        },
        {
          title: "权限类型",
          ellipsis: true,
          dataIndex: "policyType",
        },
        {
          title: "accessKey",
          ellipsis: true,
          dataIndex: "accessKey",
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      loadingModal: false,
      modifyForm: {
        accountName: "",
        accessKey: "",
        accessSecret: "",
        remark: "",
        displayName: "",
        policyTypeId: undefined,
      },
      modifyTitle: "",
      modifyShow: false,
      loading: false,
      jurisdictionShow: false,
      cardTitle: "已选择（0）",
      accountPoliciesList: [],
      majorKeyId: "",
      mainAccountId: undefined,
    };
  },
  mounted() {
    this.mainAccountId = this.$route.query.mainAccountId;
    this.query(1);
  },
  methods: {
    selectChange(val) {
      let newArr = this.policyList.filter((el) => el.id === val);
      this.modifyForm.accountName = newArr[0].code;
      this.modifyForm.displayName = newArr[0].name;
    },
    goBack() {
      this.$router.go(-1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        mainAccountId: this.mainAccountId,
      };
      this.getAliyunList(data);
    },
    // 查询账号列表
    getAliyunList(data) {
      this.loadingTable = true;
      api
        .aliyunList(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
            });
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 权限类型下拉框
    getPolicy() {
      api.policyTypeList().then((res) => {
        if (res.result === 200) {
          this.policyList = res.data;
        }
      });
    },
    // 点击新增
    addClick() {
      this.modifyTitle = "新增";
      this.modifyShow = true;
      // this.getAccountType();
      this.getPolicy();
      this.addShow = true;
    },
    // 点击编辑
    modifyClick(val) {
      this.modifyTitle = "编辑";
      this.modifyShow = true;
      this.getPolicy();
      // this.mainShow = false;
      // this.ramShow = true;
      this.addShow = false;
      this.modifyForm.accountName = val.accountName;
      this.modifyForm.displayName = val.displayName;
      this.modifyForm.remark = val.remark;
      this.modifyForm.policyTypeId = val.policyTypeId;
      this.modifyId = val.id;
    },
    // 确定新增
    modify_submit() {
      if (this.modifyTitle === "编辑") {
        let data = {
          id: this.modifyId,
          accountName: this.modifyForm.accountName,
          displayName: this.modifyForm.displayName,
          policyTypeId: this.modifyForm.policyTypeId,
          remark: this.modifyForm.remark,
        };
        this.loading = true;
        api
          .aliyunEditAccount(data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.$message.success("编辑成功");
              this.modifyShow = false;
              this.query(1);
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        let data = {
          accountName: this.modifyForm.accountName,
          remark: this.modifyForm.remark,
          displayName: this.modifyForm.displayName,
          policyTypeId: this.modifyForm.policyTypeId,
          parentId: this.mainAccountId,
        };
        if (!data.accountName) {
          this.$message.warning("请输入账号名称");
          return;
        }
        this.loading = true;
        api
          .aliyunAddAccount(data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.$message.success("新增成功");
              this.modifyShow = false;
              this.query(1);
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 关闭新增
    modifyClose() {
      this.modifyForm = {
        accountName: "",
        accessKey: "",
        accessSecret: "",
        remark: "",
        displayName: "",
        policyTypeId: undefined,
      };
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
        parentId: this.mainAccountId,
      };
      api.aliyunDeleteAccount(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
