<template>
  <div class="serverList">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="服务器列表">
        <router-view name="three" v-if="active == '1'" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="服务器账号列表">
        <router-view name="three" v-if="active == '2'" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="服务器分组">
        <router-view name="three" v-if="active == '3'" />
      </a-tab-pane>
      <a-tab-pane key="4" tab="服务器配置模版">
        <router-view name="three" v-if="active == '4'" />
      </a-tab-pane>
      <a-tab-pane key="5" tab="服务器端口占用列表">
        <router-view name="three" v-if="active == '5'" />
      </a-tab-pane>
      <a-tab-pane key="6" tab="终端访问日志">
        <router-view name="three" v-if="active == '6'" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "serverList",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/serverList/serverListTab") {
          this.active = "1";
        } else if (newVal == "/serverList/serverAccountListTab") {
          this.active = "2";
        } else if (newVal == "/serverList/serverGroup") {
          this.active = "3";
        } else if (newVal == "/serverList/serverConfigTemplate") {
          this.active = "4";
        } else if (newVal == "/serverList/serverPortInfoList") {
          this.active = "5";
        } else if (newVal == "/serverList/shellLog") {
          this.active = "6";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/serverList/serverListTab") return;
          this.$router.replace("/serverList/serverListTab");
        } else if (newVal === "2") {
          if (this.$route.path == "/serverList/serverAccountListTab") return;
          this.$router.replace("/serverList/serverAccountListTab");
        } else if (newVal === "3") {
          if (this.$route.path == "/serverList/serverGroup") return;
          this.$router.replace("/serverList/serverGroup");
        } else if (newVal === "4") {
          if (this.$route.path == "/serverList/serverConfigTemplate") return;
          this.$router.replace("/serverList/serverConfigTemplate");
        } else if (newVal === "5") {
          if (this.$route.path == "/serverList/serverPortInfoList") return;
          this.$router.replace("/serverList/serverPortInfoList");
        } else if (newVal === "6") {
          if (this.$route.path == "/serverList/shellLog") return;
          this.$router.replace("/serverList/shellLog");
        }
      },
    },
  },
};
</script>
