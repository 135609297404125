<template>
  <div class="domainNamedns">
    <div>
      <span @click="back()" class="back">
        <icon-font type="icon-arrow-left" />
        {{ domainName }}
      </span>
    </div>
    <div class="searchCondition">
      <div class="searchConditionItem">
        主机记录：
        <a-input
          v-model.trim="name"
          placeholder="请输入主机记录"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        记录值：
        <a-input
          placeholder="请输入记录值"
          v-model.trim="searchValue"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        记录类型：
        <a-select
          style="width: 240px"
          v-model="type"
          placeholder="请选择记录类型"
          @change="query(1)"
          allowClear
        >
          <a-select-option
            v-for="item in typeList"
            :key="item.key"
            :value="item.value"
            >{{ item.value }} - {{ item.label }}</a-select-option
          >
        </a-select>
      </div>

      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addClick('add')" icon="plus"
          >新增</a-button
        >
        <a-button type="primary" @click="addClick('batchAdd')" icon="plus"
          >批量新增</a-button
        >
        <a-button type="primary" @click="synUrlDomain">同步</a-button>
        <a-button
          type="primary"
          :disabled="disabledClick"
          @click="batchModifyTTLClick()"
          icon="plus"
          >批量修改时间</a-button
        >
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      rowKey="id"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :loading="tableLoading"
      size="small"
    >
      <span slot="line" slot-scope="text">
        <span v-for="item in lineList" :key="item.value">
          <span v-if="text === item.value">{{ item.label }}</span>
        </span>
      </span>
      <span slot="ttl" slot-scope="text">
        <span v-for="item in ttlList" :key="item.value">
          <span v-if="text === item.value">{{ item.label }}</span>
        </span>
      </span>
      <span slot="status" slot-scope="text" style="width: 100%">
        <a-tag v-if="text === 'ENABLE'" color="green">已启用</a-tag>
        <a-tag v-else color="orange">暂停</a-tag>
      </span>
      <span slot="safeMode" slot-scope="text, record">
        <a-switch
          checkedChildren="已开启"
          unCheckedChildren="已关闭"
          v-model="record.safeMode"
          :loading="loading"
          @change="updateSafeMode(record)"
        />
      </span>
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="suspendClick(record)"
          v-if="record.status === 'ENABLE'"
        >
          <template slot="title">是否暂停？</template>
          <a href="javascript:;">暂停</a>
        </a-popconfirm>
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="startClick(record)"
          v-else
        >
          <template slot="title">是否启用？</template>
          <a href="javascript:;">启用</a>
        </a-popconfirm>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.rr }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <div class="searchButton">
      <a-button
        type="danger"
        @click="suspendBatchClick"
        :disabled="disabledClick"
        >批量暂停</a-button
      >
      <a-button type="danger" @click="startBatchClick" :disabled="disabledClick"
        >批量启用</a-button
      >
      <a-button
        type="danger"
        @click="deleteBatchClick"
        :disabled="disabledClick"
        icon="delete"
        >批量删除</a-button
      >
    </div>
    <!-- 新增or编辑 -->
    <a-drawer
      :title="recordTitle"
      :width="720"
      :visible="recordShow"
      :body-style="{ paddingBottom: '80px' }"
      @close="recordClose"
    >
      <a-form>
        <template v-if="recordTitle != '批量修改时间'">
          <a-form-item label="记录类型">
            <a-select
              v-model="modifyForm.type"
              placeholder="请选择记录类型"
              style="width: 90%"
            >
              <a-select-option v-for="item in typeList" :key="item.value"
                >{{ item.value }} - {{ item.label }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item label="主机记录">
            <a-input
              id="rrInput"
              v-model="modifyForm.rr"
              style="width: 80%"
              placeholder="请输入主机记录"
            />
            <span style="padding: 0 8px">.{{ domainName }}</span>
            <a-tooltip placement="topLeft">
              <template slot="title">
                <div>主机记录就是域名前缀，常见用法有：</div>
                <div>www：解析后的域名为www.aliyun.com。</div>
                <div>@：直接解析主域名 aliyun.com。</div>
                <div>*：泛解析，匹配其他所有域名 *.aliyun.com。</div>
                <div>
                  mail：将域名解析为mail.aliyun.com，通常用于解析邮箱服务器。
                </div>
                <div>二级域名：如：abc.aliyun.com，填写abc。</div>
                <div>手机网站：如：m.aliyun.com，填写m。</div>
                <div>
                  显性URL：不支持泛解析（泛解析：将所有子域名解析到同一地址
                </div>
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
            <div v-if="recordTitle === '批量新增记录'">
              <span>标签：</span>
              <span>
                <a
                  href="javascript:;"
                  @click="labelClick('$INSTANCE_NAME', 'rrInput')"
                  :disabled="rrDisabled"
                  >$INSTANCE_NAME</a
                >
              </span>
            </div>
          </a-form-item>
          <a-form-item label="解析线路">
            <a-select
              v-model="modifyForm.line"
              placeholder="请选择解析线路"
              style="width: 90%; margin-right: 8px"
            >
              <a-select-option v-for="item in lineList" :key="item.value">{{
                item.label
              }}</a-select-option>
            </a-select>
            <a-tooltip placement="topLeft">
              <template slot="title">
                <div>如果只有一个IP地址或CNAME域名，请务必选择【默认】。</div>
                <div>
                  默认：必填！未匹配到智能解析线路时，返回【默认】线路设置结果。
                </div>
                <div>
                  境外：向除中国大陆以外的其他国家和地区，返回设置的记录值。
                </div>
                <div>搜索引擎：向搜索引擎爬虫的DNS，返回设置的记录值。</div>
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </a-form-item>
          <a-form-item label="记录值">
            <a-select
              v-model="modifyForm.serverIdList"
              mode="multiple"
              showSearch
              option-filter-prop="children"
              placeholder="请选择记录值"
              style="width: 90%"
              v-if="recordTitle === '批量新增记录'"
            >
              <a-select-option v-for="item in serversList" :key="item.id">
                <span>{{ item.instanceName }}</span>
                <span v-if="item.publicIp"> - {{ item.publicIp }}</span>
              </a-select-option>
            </a-select>
            <a-input
              v-model="modifyForm.value"
              style="width: 90%"
              placeholder="请输入记录值"
              v-else
            />
          </a-form-item>
        </template>
        <a-form-item label="TTL">
          <a-select
            v-model="modifyForm.ttl"
            placeholder="请选择生存时间"
            style="width: 90%"
          >
            <a-select-option v-for="item in ttlList" :key="item.value">{{
              item.label
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="优先级"
          v-if="recordTitle != '批量修改时间' && modifyForm.type == 'MX'"
        >
          <a-input
            v-model="modifyForm.priority"
            style="width: 90%; margin-right: 8px"
            placeholder="请输入优先级"
          />
          <a-tooltip placement="topLeft">
            <template slot="title">
              <div>MX记录的优先级，取值范围：[1,50]。</div>
              <div>
                记录类型为MX记录时，此参数必需，MX
                优先级的数值越低，优先级别就越高。
              </div>
            </template>
            <a-icon type="question-circle" />
          </a-tooltip>
        </a-form-item>
      </a-form>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="recordClose">
          取消
        </a-button>
        <a-button type="primary" @click="record_submit" :loading="loading">
          确定
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import * as api from "../lib/domainNamedns.js";
import { serverList } from "../lib/serverList.js";
export default {
  name: "domainNamedns",
  data() {
    return {
      loading: false,
      tableLoading: false,
      domainName: "",
      tableColumns: [
        {
          title: "主机记录",
          ellipsis: true,
          dataIndex: "rr",
        },
        {
          title: "记录类型",
          ellipsis: true,
          dataIndex: "type",
        },
        {
          title: "解析线路",
          ellipsis: true,
          dataIndex: "line",
          scopedSlots: { customRender: "line" },
        },
        {
          title: "记录值",
          ellipsis: true,
          dataIndex: "value",
        },
        {
          title: "TTL",
          ellipsis: true,
          dataIndex: "ttl",
          scopedSlots: { customRender: "ttl" },
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "解析保护",
          ellipsis: true,
          dataIndex: "safeMode",
          scopedSlots: { customRender: "safeMode" },
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      selectedRowKeys: [],
      selectedRows: [],
      serversList: [],
      pageNo: 1,
      name: "",
      searchValue: "",
      recordTitle: "",
      recordShow: false,
      typeList: [
        {
          value: "A",
          label: "将域名指向一个IPV4地址",
        },
        {
          value: "CNAME",
          label: "将域名指向另外一个域名",
        },
        {
          value: "AAAA",
          label: "将域名指向一个IPV6地址",
        },
        {
          value: "NS",
          label: "将子域名指向其他DNS服务器解析",
        },
        {
          value: "MX",
          label: "将域名指向邮件服务器地址",
        },
        {
          value: "SRV",
          label: "记录提供特定的服务的服务器",
        },
        {
          value: "TXT",
          label: "文本长度限制512, 通常做SPF记录(反垃圾邮件)",
        },
        {
          value: "CAA",
          label: "CA证书颁发机构授权校验",
        },
        {
          value: "显性URL",
          label: "将域名重定向到另外一个地址",
        },
        {
          value: "隐性URL",
          label: "与显性URL类似, 但是会隐藏真实目标地址",
        },
      ],
      lineList: [
        {
          value: "default",
          label: "默认",
        },
      ],
      ttlList: [
        {
          value: 60,
          label: "1分钟",
        },
        {
          value: 120,
          label: "2分钟",
        },
        {
          value: 300,
          label: "5分钟",
        },
        {
          value: 600,
          label: "10分钟",
        },
      ],
      modifyForm: {
        type: "A",
        line: "default",
        ttl: 600,
        value: "",
        rr: "",
        serverIdList: [],
        priority: null,
      },
      recordId: "",
      id: "",
      domainId: "",
      accountId: "",
      type: undefined,
    };
  },
  computed: {
    disabledClick() {
      if (this.selectedRowKeys.length > 0) {
        return false;
      }
      return true;
    },
    rrDisabled() {
      let text = this.modifyForm.rr;
      let res = text.indexOf("$INSTANCE_NAME") > -1 ? true : false;
      return res;
    },
  },
  mounted() {
    this.domainId = this.$route.query.domainId;
    this.domainName = this.$route.query.domainName;
    this.accountId = this.$route.query.accountId;
    this.query(1);
  },
  methods: {
    updateSafeMode(val) {
      val.loading = true;
      api.updateSafeMode({ id: val.id, safeMode: val.safeMode }).then((res) => {
        if (res.result == 200) {
          if (val.enabled) {
            this.$message.success("关闭成功");
          } else {
            this.$message.success("开启成功");
          }
          this.query();
          val.loading = false;
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
    // 同步
    synUrlDomain() {
      let data = {
        domainId: this.domainId,
        accountId: this.accountId,
      };
      api.synUrlDomain(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("同步成功");
          this.query();
        }
      });
    },
    // 查询服务器列表
    getServerList() {
      let data = {
        pageNo: 1,
        pageSize: 100,
      };
      serverList(data).then((res) => {
        if (res.result == 200) {
          let list = res.data.records;
          this.serversList = list;
        }
      });
    },
    // 点击主机记录标签
    labelClick(val, id) {
      this.modifyForm.rr = this.$common.insertInputTxt(
        val,
        id,
        this.modifyForm.rr
      );
    },
    // 表格选择
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 关闭新增弹框
    recordClose() {
      this.recordShow = false;
    },
    // 点击新增
    addClick(val) {
      this.modifyForm = {
        type: "A",
        line: "default",
        ttl: 600,
        value: "",
        rr: "",
        serverIdList: [],
        priority: 1,
      };
      this.recordShow = true;
      if (val === "add") {
        this.recordTitle = "新增记录";
      }
      if (val === "batchAdd") {
        this.recordTitle = "批量新增记录";
        this.getServerList();
      }
    },
    batchModifyTTLClick() {
      this.recordTitle = "批量修改时间";
      this.modifyForm = {
        type: "A",
        line: "default",
        ttl: 600,
        value: "",
        rr: "",
        serverIdList: [],
        priority: 1,
      };
      this.recordShow = true;
    },
    // 点击修改
    modifyClick(val) {
      this.recordShow = true;
      this.recordTitle = "修改记录";
      this.id = val.id;
      this.recordId = val.recordId;
      this.modifyForm = {
        type: val.type,
        line: val.line,
        ttl: val.ttl,
        value: val.value,
        rr: val.rr,
        serverIdList: [],
        priority: val.priority,
      };
    },
    // 确定新增or修改
    record_submit() {
      let data = {
        type: this.modifyForm.type,
        line: this.modifyForm.line,
        ttl: this.modifyForm.ttl,
        value: this.modifyForm.value,
        rr: this.modifyForm.rr,
        domainId: this.domainId,
        accountId: this.accountId,
      };
      if (data.type == "MX") {
        data.priority = this.modifyForm.priority;
      } else {
        data.priority = null;
      }
      this.loading = true;
      if (this.recordTitle === "新增记录") {
        api
          .urlDomainAddRecord(data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.recordShow = false;
              this.$message.success("新增成功");
              this.query(1);
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
      if (this.recordTitle === "修改记录") {
        data.id = this.id;
        data.recordId = this.recordId;
        api
          .urlDomainUpdateRecord(data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.recordShow = false;
              this.$message.success("修改成功");
              this.query();
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
      if (this.recordTitle === "批量新增记录") {
        data.serverIdList = this.modifyForm.serverIdList;
        api
          .urlDomainAddRecordBatch(data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.recordShow = false;
              this.$message.success("新增成功");
              this.query(1);
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }

      if (this.recordTitle === "批量修改时间") {
        this.$axios
          .all(
            this.selectedRows.map((item) => {
              let data = {
                recordId: item.recordId,
                id: item.id,
                type: item.type,
                line: item.line,
                ttl: this.modifyForm.ttl,
                value: item.value,
                rr: item.rr,
                domainId: this.domainId,
                accountId: this.accountId,
                priority: item.type == "MX" ? item.priority : null,
              };
              return api.urlDomainUpdateRecord(data);
            })
          )
          .then((resArr) => {
            this.$message.success("修改成功");
            this.query();
            this.loading = false;
            this.recordShow = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 批量启用
    startBatchClick() {
      this.$confirm({
        title: "确认启用",
        content: (h) => (
          <div style="color:red;">确定要启用所选择的主机记录?</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          let data = {
            idList: this.selectedRowKeys,
            accountId: this.accountId,
          };
          api.urlDomainResumeRecord(data).then((res) => {
            if (res.result === 200) {
              this.$message.success("启用成功");
              this.query();
            }
          });
        },
        onCancel: () => {},
      });
    },
    // 批量暂停
    suspendBatchClick() {
      this.$confirm({
        title: "确认暂停",
        content: (h) => (
          <div style="color:red;">确定要暂停所选择的主机记录?</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          let data = {
            idList: this.selectedRowKeys,
            accountId: this.accountId,
          };
          api.urlDomainPauseRecord(data).then((res) => {
            if (res.result === 200) {
              this.$message.success("暂停成功");
              this.query();
            }
          });
        },
        onCancel: () => {},
      });
    },
    // 批量删除
    deleteBatchClick() {
      this.$confirm({
        title: "确认删除",
        content: (h) => (
          <div style="color:red;">确定要删除所选择的主机记录?</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          let data = {
            idList: this.selectedRowKeys,
            accountId: this.accountId,
          };
          api.urlDomainDeleteRecord(data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.query();
            }
          });
        },
        onCancel: () => {},
      });
    },
    // 点击启用
    startClick(val) {
      let data = {
        idList: [val.id],
        accountId: this.accountId,
      };
      api.urlDomainResumeRecord(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("启用成功");
          this.query();
        }
      });
    },
    // 点击暂停
    suspendClick(val) {
      let data = {
        idList: [val.id],
        accountId: this.accountId,
      };
      api.urlDomainPauseRecord(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("暂停成功");
          this.query();
        }
      });
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        idList: [val.id],
        accountId: this.accountId,
      };
      api.urlDomainDeleteRecord(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        rr: this.name,
        value: this.searchValue,
        domainId: this.domainId,
        type: this.type,
      };
      this.getUrlDomainList(data);
    },
    // 查询域名解析列表
    getUrlDomainList(data) {
      this.tableLoading = true;
      api
        .urlDomainList(data)
        .then((res) => {
          this.tableLoading = false;
          if (res.result == 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tableDataSource.forEach((item) => {
              this.$set(item, "loading", false);
            });
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    // 分页
    changePageItem(current) {
      this.query(current);
    },
  },
};
</script>
<style lang="scss" scoped>
.back {
  display: inline-block;
  cursor: pointer;
  font-size: 20px;
  color: black;
  font-weight: 500;
  padding-bottom: 10px;
}
</style>
