<template>
  <div class="pvList">
    <div class="reminder">存储卷 <span>persistentVolumeClaim</span></div>
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="name"
          @pressEnter="query"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.name"
    >
      <span slot="boundPvc" slot-scope="text, record" v-html="text" />
    </a-table>
  </div>
</template>
<script>
import * as api from "../lib/k8s.js";

export default {
  name: "pvList",
  data() {
    return {
      name: "",
      tableColumns: [
        {
          title: "名称",
          dataIndex: "name",
        },
        {
          title: "总量",
          dataIndex: "capacity",
          width: 100,
        },
        {
          title: "访问模式",
          dataIndex: "accessMode",
          width: 120,
        },
        {
          title: "回收策略",
          dataIndex: "reclaimPolicy",
          width: 80,
        },
        {
          title: "状态",
          dataIndex: "status",
          width: 80,
        },
        {
          title: "存储类型",
          dataIndex: "pvType",
          width: 200,
        },
        {
          title: "绑定存储声明",
          dataIndex: "boundPvc",
          width: 400,
          scopedSlots: { customRender: "boundPvc" },
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          width: 170,
        },
      ],
      tableDataSource: [],
      loadingTable: false,
    };
  },
  created() {
    this.query();
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query();
    },
    query() {
      let data = {
        name: this.name,
      };
      this.loadingTable = true;
      api
        .pvList(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource = res.data;
            this.tableDataSource.forEach((item) => {
              this.$set(item, "boundPvc", item.boundPvc.replace("\n", "<br/>"));
            });
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
  },
};
</script>
