import http from "./axios";

// 查询所有消费者
export const consumerList = ({ clusterId }) => {
  return http.get("/rocketmq/dashboard/consumer/groupList", { clusterId });
};

// 查询指定消费者的连接信息
export const consumerConnection = ({ consumerGroup, clusterId }) => {
  return http.get("/rocketmq/dashboard/consumer/consumerConnection", {
    consumerGroup: consumerGroup,
    clusterId,
  });
};

// 查询指定消费者的detail信息
export const queryTopicByConsumer = ({ consumerGroup, clusterId }) => {
  return http.get("/rocketmq/dashboard/consumer/queryTopicByConsumer", {
    consumerGroup: consumerGroup,
    clusterId,
  });
};
// 查询指定消费者的配置信息
export const examineSubscriptionGroupConfig = ({
  consumerGroup,
  clusterId,
}) => {
  return http.get(
    "/rocketmq/dashboard/consumer/examineSubscriptionGroupConfig",
    {
      consumerGroup: consumerGroup,
      clusterId,
    }
  );
};

// 添加消费者组请求
export const createOrUpdate = ({
  brokerNameList,
  subscriptionGroupConfig,
  clusterNameList,
  clusterId,
}) => {
  return http.json_post("/rocketmq/dashboard/consumer/createOrUpdate", {
    brokerNameList: brokerNameList,
    subscriptionGroupConfig: subscriptionGroupConfig,
    clusterNameList: clusterNameList,
    clusterId,
  });
};

// 删除消费者组请求
export const deleteSubGroup = ({ groupName, brokerNameList, clusterId }) => {
  return http.json_post("/rocketmq/dashboard/consumer/deleteSubGroup", {
    groupName: groupName,
    brokerNameList: brokerNameList,
    clusterId,
  });
};

// 查询指定消费者组所在的所有broker
export const fetchBrokerNameList = ({ consumerGroup, clusterId }) => {
  return http.get("/rocketmq/dashboard/consumer/fetchBrokerNameList", {
    consumerGroup: consumerGroup,
    clusterId,
  });
};
