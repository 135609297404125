<template>
  <div class="serverGroup">
    <div class="searchButton">
      <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      <a-button type="primary" @click="addGroup" icon="plus">新增分组</a-button>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(columns)"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="pagination"
      :loading="tableLoading"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a href="javascript:;" @click="updateServer(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteServer(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增or修改分组 -->
    <a-modal
      :title="editServerTitle"
      v-model="visibleAddServer"
      :maskClosable="false"
      :afterClose="closeAddServer"
      width="700px"
    >
      <a-form>
        <a-form-item
          label="分组名称"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
          v-if="addFormShow"
        >
          <a-input placeholder="请输入分组名称" v-model="addForm.name" />
        </a-form-item>
        <a-form-item
          label="选择项目"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
          v-if="addFormShow"
        >
          <a-select
            v-model="addForm.projectId"
            placeholder="请输选择项目"
            showSearch
            option-filter-prop="children"
          >
            <a-select-option v-for="item in projectList" :key="item.id">
              {{ item.enName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 0 }"
          :wrapper-col="{ span: 23, offset: 2 }"
        >
          <a-transfer
            :data-source="mockData"
            :titles="['可选服务器', '已选服务器']"
            :target-keys="targetKeys"
            :selected-keys="selectedKeys"
            :render="(item) => item.title"
            @change="handleChange"
            @selectChange="handleSelectChange"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="visibleAddServer = false">取消</a-button>
          <a-button key="submit" type="primary" @click="addServerOk"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/serverList.js";
import { projectList } from "../lib/projectList.js";
export default {
  name: "serverGroup",
  data() {
    return {
      projectList: [],
      mockData: [],
      targetKeys: [],
      selectedKeys: [],
      columns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "key",
          width: 60,
        },
        {
          title: "分组名称",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "项目名称",
          ellipsis: true,
          dataIndex: "projectName",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataSource: [],
      pagination: {},
      pageNo: 1,
      tableLoading: false,
      editServerTitle: "",
      visibleAddServer: false,
      addFormShow: false,
      addForm: {
        name: "",
        projectId: undefined,
      },
      serverGroupId: "",
    };
  },
  mounted() {
    this.query(1);
    this.getServerAll();
    this.getProjectList();
  },
  methods: {
    handleChange(nextTargetKeys, direction, moveKeys) {
      this.targetKeys = nextTargetKeys;
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },
    // 点击添加分组
    addGroup() {
      this.editServerTitle = "新增分组";
      this.addFormShow = true;
      this.visibleAddServer = true;
    },
    // 确认添加分组
    addServerOk() {
      if (this.editServerTitle === "新增分组") {
        let data = {
          name: this.addForm.name,
          projectId: this.addForm.projectId,
          serverList: this.targetKeys,
        };
        if (!data.name) {
          this.$message.warning("请输入分组名称");
          return;
        }
        if (!data.projectId) {
          this.$message.warning("请选择项目");
          return;
        }
        api
          .insertServerGroup(data)
          .then((res) => {
            if (res.result === 200) {
              this.$message.success("新增成功");
              this.visibleAddServer = false;
              this.query(1);
            }
          })
          .catch((err) => {});
      } else {
        let data = {
          id: this.serverGroupId,
          serverList: this.targetKeys,
        };
        api
          .modifyServerGroup(data)
          .then((res) => {
            if (res.result === 200) {
              this.$message.success("修改成功");
              this.visibleAddServer = false;
              this.query();
            }
          })
          .catch((err) => {});
      }
    },
    // 关闭添加分组
    closeAddServer() {
      this.addFormShow = false;
      this.addForm = {
        name: "",
        projectId: undefined,
      };
      this.targetKeys = [];
      this.selectedKeys = [];
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      this.getServerGroupList();
    },
    // 查询服务器分组
    getServerGroupList(data) {
      api.serverGroupList().then((res) => {
        if (res.result === 200) {
          let list = res.data.serverGroup;
          list.forEach((item, index) => {
            item.key = index + 1;
          });
          this.dataSource = list;
        }
      });
    },
    // 查询所有服务器
    getServerAll() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
      };
      api.serverList(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item, index) => {
            item.key = item.id.toString();
            item.title = item.publicIp + "(" + item.instanceName + ")";
          });
          this.mockData = list;
        }
      });
    },
    // 查询所有项目
    getProjectList() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
      };
      projectList(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.list;
          this.projectList = list;
        }
      });
    },
    // 点击修改
    updateServer(val) {
      this.editServerTitle = "【" + val.name + "】分组修改";
      this.addForm.projectId = val.projectId;
      this.serverGroupId = val.id;
      this.getServerGroupDetail(val.id);
      this.visibleAddServer = true;
    },
    // 查询分组详情
    getServerGroupDetail(id) {
      this.targetKeys = [];
      api.serverGroupDetail({ id: id }).then((res) => {
        if (res.result === 200) {
          let list = res.data.serverList;
          let serverIdList = [];
          list.forEach((item) => {
            serverIdList.push(item.id.toString());
          });
          if (list) {
            this.targetKeys = serverIdList;
          }
        }
      });
    },
    // 点击删除
    deleteServer(val) {
      api.deleteServerGroup({ id: val.id }).then((res) => {
        if (res.result == 200) {
          this.$message.success("删除成功！");
          this.query();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ant-switch-checked {
  background-color: #52c41a;
}
.ant-transfer {
  ::v-deep .ant-transfer-list {
    width: 250px;
    height: 300px;
  }
}
</style>
