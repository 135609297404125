<template>
  <div class="publickeyList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        类型：
        <a-select v-model="typeName" placeholder="请选择类型" allowClear>
          <a-select-option v-for="i in typeList" :key="i.id">
            {{ i.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addClick" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="type" slot-scope="text">
        <a-tag v-if="text == 'DEVELOPER'">开发者</a-tag>
        <a-tag v-if="text == 'SERVER'">服务器</a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title"
            >确认是否删除{{ record.identifyName }}</template
          >
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      title="新增"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="类型:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="typeSelectName"
            placeholder="请选择类型"
            allowClear
            @change="typeSelectChange"
          >
            <a-select-option v-for="i in typeList" :key="i.id">
              {{ i.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="服务器:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="typeSelectName == 'SERVER'"
        >
          <a-select
            v-model="instanceId"
            showSearch
            placeholder="请选择服务器"
            :filter-option="false"
            @search="instanceIdSearch"
            allowClear
          >
            <a-select-option v-for="item in instanceList" :key="item.id">{{
              item.instanceName
            }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="开发者:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="typeSelectName == 'DEVELOPER'"
        >
          <a-select
            v-model="instanceId"
            showSearch
            placeholder="请选择开发者"
            :filter-option="false"
            @search="developerIdSarch"
            allowClear
          >
            <a-select-option v-for="item in developerList" :key="item.userId">{{
              item.userName
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="公钥:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="typeSelectName == 'DEVELOPER'"
        >
          <a-textarea
            v-model="publicKey"
            placeholder="请输入公钥"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-item>
        <a-form-item
          label="备注:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="remark" placeholder="请输入备注" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/publickeyList.js";
import { userList } from "../lib/projectList.js";

export default {
  name: "publickeyList",
  data() {
    return {
      typeName: undefined,
      typeList: [
        {
          id: "SERVER",
          name: "服务器",
        },
        {
          id: "DEVELOPER",
          name: "开发者",
        },
      ],
      addKeyVisible: false,
      loading: false,
      remark: "",
      publicKey: "",
      typeSelectName: undefined,
      instanceId: undefined,
      identifyId: undefined,
      instanceList: [],
      identifyList: [],
      developerList: [],
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "identifyName",
          width: 150,
        },
        {
          title: "类型",
          ellipsis: true,
          dataIndex: "type",
          scopedSlots: { customRender: "type" },
          width: 100,
        },
        {
          title: "公钥",
          ellipsis: true,
          dataIndex: "publicKey",
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
          width: 200,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 150,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        type: this.typeName,
      };
      this.getpublicKeyList(data);
    },
    // 查询账号列表
    getpublicKeyList(data) {
      this.loadingTable = true;
      api
        .publicKeyList(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 弹框类型下拉框变化
    typeSelectChange(val) {
      this.typeSelectName = val;
    },
    // 服务器下拉框发生变化
    instanceIdSearch(instanceName) {
      this.getServerList(instanceName);
    },
    // 查询服务器列表
    getServerList(instanceName) {
      let data = {
        instanceName: instanceName,
      };
      api.serverList(data).then((res) => {
        if (res.result === 200) {
          this.instanceList = res.data;
        }
      });
    },
    // 公钥下拉框发生变化
    identifyIdSarch(identifyName) {
      this.getSearchPublicKey(identifyName);
    },
    // 查询公钥集合列表
    getSearchPublicKey(identifyName) {
      let data = {
        identifyName: identifyName,
      };
      api.searchPublicKey(data).then((res) => {
        if (res.result === 200) {
          this.identifyList = res.data;
        }
      });
    },
    // 开发者下拉框发生变化
    developerIdSarch(userName) {
      this.getUserList(userName);
    },
    // 查询开发者
    getUserList(userName) {
      userList({ name: userName }).then((res) => {
        if (res.result === 200) {
          this.developerList = res.data.userList;
        }
      });
    },
    // 新增
    addClick() {
      this.addKeyVisible = true;
      this.getServerList();
      this.getSearchPublicKey();
      this.getUserList();
      this.typeSelectName = undefined;
      this.instanceId = undefined;
      this.remark = "";
      this.publicKey = undefined;
    },
    // 确定新增
    add_submit() {
      let data = {
        type: this.typeSelectName,
        identifyId: this.instanceId,
        remark: this.remark,
        publicKey: this.publicKey,
      };
      api.publicKeyAdd(data).then((res) => {
        if (res.result === 200) {
          this.addKeyVisible = false;
          this.$message.success("添加成功");
          this.query();
        }
      });
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.publicKeyDelete(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
