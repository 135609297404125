<template>
  <div class="innerApi">
    <div class="searchButton">
      <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      <a-button type="primary" @click="addTaskVisible = true" icon="plus"
        >新增</a-button
      >
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span
        slot="token"
        slot-scope="text, record"
        style="display: inline-block; width: 100%; cursor: pointer"
        @mouseenter="record.isCopyShow = true"
        @mouseleave="record.isCopyShow = false"
      >
        {{ text }}
        <a
          href="javascript:;"
          v-copy="record.token"
          v-show="record.isCopyShow"
          style="margin-left: 20px"
          >复制</a
        >
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="apiClassList(record)">接口分类列表</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteData(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <a-modal
      title="新增项目"
      v-model="addTaskVisible"
      :maskClosable="false"
      :afterClose="addTaskClose"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="项目:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="projectId"
            placeholder="请选择项目"
            showSearch
            :filter-option="false"
            @search="getProjectList"
            allowClear
          >
            <a-select-option
              v-for="(i, index) in projectList"
              :key="index"
              :value="i.id"
            >
              {{ i.enName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="描述:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input placeholder="请输入描述" v-model="desc" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addTaskVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addTask_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import api from "../lib/innerApi.js";
import { projectList } from "../lib/projectList.js";

export default {
  name: "innerApi",
  data() {
    return {
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "项目名",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "token",
          ellipsis: true,
          dataIndex: "token",
          width: 600,
          scopedSlots: { customRender: "token" },
        },
        {
          title: "项目描述",
          ellipsis: true,
          dataIndex: "desc",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      addTaskVisible: false,
      loading: false,
      projectList: [],
      projectId: undefined,
      desc: "",
    };
  },
  mounted() {
    this.query(1);
    this.getProjectList();
  },
  methods: {
    deleteData(val) {
      api.deleteData({ id: val.id }).then((res) => {
        if (res.result == 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    getProjectList(val) {
      this.projectList.splice(0);
      let data = {
        pageNo: 1,
        pageSize: 10,
        name: val,
      };
      projectList(data).then((res) => {
        if (res.result == 200) {
          this.projectList.push(...res.data.list);
        }
      });
    },
    addTaskClose() {
      this.projectId = undefined;
      this.desc = "";
    },
    addTask_submit() {
      let data = {
        projectId: this.projectId,
        desc: this.desc,
      };
      api.addData(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("添加成功");
          this.addTaskVisible = false;
          this.query();
        }
      });
    },
    apiClassList(val) {
      let preUrl =
        process.env.VUE_APP_CURRENTMODE == "dev"
          ? "http://localhost:8080"
          : location.protocol + "//delivery.huiyu.org.cn";
      let url = preUrl + `/yapi?projectId=${val.id}`;
      window.open(url);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        page: this.pageNo,
        limit: 10,
      };
      this.queryPage(data);
    },
    // 查询代码生成列表
    queryPage(data) {
      this.loadingTable = true;
      this.tableDataSource.splice(0);
      api
        .queryPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result == 200) {
            this.tableDataSource.push(...res.data.records);
            this.tableDataSource.forEach((item) => {
              this.$set(item, "isCopyShow", false);
            });
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
  },
};
</script>
<style>
.code_style .CodeMirror {
  height: 600px;
}
</style>
<style lang="scss" scoped>
::v-deep .ant-col-16 {
  width: 78%;
  margin-top: 5px;
  .ant-form-item-children {
    display: flex;
    .select-tag {
      width: 270px;
      margin-right: 20px;
    }
  }
}
</style>
