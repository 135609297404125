import http from "./axios";

export const syncProject = () => {
  return http.get("/delivery/git/syncProject");
};

export const projectTimeControlModelList = (data) => {
  return http.get("/delivery/projectTimeControlModelList", data);
};

export const subscribeProject = (data) => {
  return http.json_post("/delivery/project/subscribeProject", data);
};

export const cancelSubscribeProject = (data) => {
  return http.json_post("/delivery/project/cancelSubscribeProject", data);
};

export const addProjectTimeControlModel = (data) => {
  return http.json_post("/delivery/addProjectTimeControlModel", data);
};

export const editProjectTimeControlModel = (data) => {
  return http.json_post("/delivery/editProjectTimeControlModel", data);
};

export const deleteProjectTimeControl = (data) => {
  return http.json_post("/delivery/deleteProjectTimeControl", data);
};

export const listRepositoryBranches = (data) => {
  return http.get("/delivery/git/listRepositoryBranches", data);
};

export const listRepositoryCommits = (data) => {
  return http.get("/delivery/git/listRepositoryCommits", data);
};

export const currentDeliveryInfo = (data) => {
  return http.get("/delivery/git/currentDeliveryInfo", data);
};

export const gitProjectList = () => {
  return http.get("/delivery/git/gitProjectList");
};

export const projectList = (data) => {
  return http.get("/delivery/project/projectList", data);
};

export const ownerChange_post = (data) => {
  return http.json_post("/delivery/project/changeProjectOwner", data);
};

export const addProject = (data) => {
  return http.json_post("/delivery/project/addProject", data);
};

export const updateProject = (data) => {
  return http.json_post("/delivery/project/updateProject", data);
};

export const shareProject = (data) => {
  return http.post("/delivery/user/shareProject", data);
};

export const withdrawProject = (data) => {
  return http.post("/delivery/user/withdrawProject", data);
};

export const userList = (data) => {
  return http.get("/delivery/user/list", data);
};

export const deliveryConfigList = (data) => {
  return http.get("/delivery/deliveryConfig/list", data);
};

export const configDetailList = (data) => {
  return http.get("/delivery/deliveryConfig/configDetail", data);
};

export const deliveryConfigDelete = (data) => {
  return http.json_post("/delivery/deliveryConfig/delete", data);
};

export const initRelationPipeline = (data) => {
  return http.json_post("/delivery/deliveryConfig/initRelationPipeline", data);
};

export const updateAuditStatus = (data) => {
  return http.json_post("/delivery/deliveryConfig/updateAuditStatus", data);
};

export const addNew = (data) => {
  return http.json_post("/delivery/deliveryConfig/addNew", data);
};