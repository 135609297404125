<template>
  <div class="postingDetails">
    <div class="postingDetails_head">
      <span class="head_title_1">
        <a-icon type="arrow-left" class="m_arrow_left" @click="goBack" />
        应用发布
      </span>
      <span class="head_title_2">{{ requestTitle }}</span>
      <a-tag :color="statusTag.color"> {{ statusTag.label }} </a-tag>
      <div class="buttonAction">
        <a-button type="primary" @click="clickVisibleAdd" class="btn"
          >重新申请</a-button
        >
      </div>
    </div>
    <div class="postingDetails_pipeline">
      <pipeline @refreshStatus="getDeliveryData"></pipeline>
    </div>

    <!-- 重新发布 -->
    <newRequest
      :show="requestShow"
      :requestTitle="requestTitle"
      :configId="configId"
      :requestData="newRelease"
      :branchList="branchList"
      :branchInfo="branchInfo"
      @refresh="branchListRefresh"
      @submit="requestClose"
      @cancel="requestClose"
      :projectId="projectId"
    ></newRequest>
  </div>
</template>
<script>
import * as api from "../lib/postingDetails.js";
import { serverList } from "../lib/serverList";
import newRequest from "../pages/newRequest";
import { listRepositoryBranches } from "../lib/projectList.js";
import { synGitCommitLog } from "../lib/request.js";
import pipeline from "./pipeline.vue";

export default {
  name: "postingDetails",
  components: {
    newRequest,
    pipeline,
  },
  data() {
    return {
      requestShow: false,
      configId: "",
      newRelease: {
        appTitle: "",
        commitInfo: "",
        branchGit: undefined,
        commitId: undefined,
        projectId: "",
      },
      branchList: [],
      newProjectId: undefined,
      branchInfo: "",
      projectId: undefined,
      loadingCommand: null,
      activeKeyCommand: ["1"],
      activeKeyContent: "",
      stepsList: [
        { title: "资源准备" },
        { title: "发布准备" },
        { title: "检出前任务" },
        { title: "代码检出" },
        { title: "检出后任务" },
        { title: "开始执行打包" },
        { title: "完成执行打包" },
      ],
      stepsContentList: [
        { title: "资源准备" },
        { title: "权限检查" },
        { title: "资源检查" },
        { title: "文件传输" },
        { title: "发布前执行" },
        { title: "发布后执行" },
        { title: "发布成功" },
      ],
      statusTag: {},
      requestTitle: "",
      requestId: "",
      serverIdList: [],
      timer: null,
      requestStatus: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (sessionStorage.getItem("request_data")) {
        let query = JSON.parse(sessionStorage.getItem("request_data"));
        this.requestId = query.id;
        this.serverIdList = query.serverIdList;
        this.requestTitle = query.name;
        this.statusTag = this.statusTransformation(query.status);
        this.getServerList();

        this.configId = query.configId;
        this.newRelease = {
          appTitle: "",
          commitInfo: "",
          branchGit: undefined,
          commitId: undefined,
          projectId: query.projectId,
          environmentId: query.environmentId,
        };
        this.newProjectId = query.newProjectId;
        this.branchInfo = query.branchInfo;
        this.projectId = query.projectId;
      }
    },
    // 重新发布
    clickVisibleAdd() {
      this.init();
      this.requestShow = true;
      setTimeout(() => {
        this.branchListRefresh1();
      }, 200);
    },
    // 点击分支刷新
    branchListRefresh() {
      synGitCommitLog({ projectId: this.newProjectId }).then((res) => {
        if (res.result === 200) {
          this.branchListRefresh1();
        }
      });
    },
    branchListRefresh1() {
      this.branchList = [];
      listRepositoryBranches({ projectId: this.newProjectId }).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          list.forEach((item) => {
            this.branchList.push({
              branchName: item.branchName,
            });
            item.gitDate = this.$common.transformTime(
              item.commitInfo.committedDateStr.replace(/-/g, "/")
            );
          });
        }
      });
    },
    // 关闭新建申请发布
    requestClose() {
      this.requestShow = false;
    },
    // 请求服务器发布数据
    getDeliveryData(minId, refresh) {
      let params = {
        requestId: this.requestId,
        logType: "DELIVERY",
        minId: minId,
      };
      api
        .deliveryRequestLogDetailList(params)
        .then((res) => {
          this.closeLoading();
          if (res.result === 200) {
            let list = res.data.list;
            let status = res.data.deliveryRequestInfo.status;
            this.statusTag = this.statusTransformation(status);
            this.requestStatus = status;
          }
        })
        .catch((err) => {
          this.closeLoading();
        });
    },
    // 查询服务器列表
    getServerList() {
      serverList({ pageNo: 1, pageSize: 1000 }).then((res) => {
        if (res.result === 200) {
          this.hostList = [...res.data.records];
          this.hostList.forEach((item) => {
            this.$set(item, "disabled", false);
          });
          this.getDeliveryData(null, true);
        }
      });
    },
    // 点击返回
    goBack() {
      this.$router.go(-1);
    },
    // 状态转换
    statusTransformation(val) {
      let res = {
        label: "",
        color: "",
      };
      switch (val) {
        case "WAIT_AUDIT":
          res = {
            label: "等待审核",
            color: "orange",
          };
          break;
        case "WAIT_DELIVERY":
          res = {
            label: "已就绪",
            color: "orange",
          };
          break;
        case "DELIVERING":
          res = {
            label: "发布中",
            color: "orange",
          };
          break;
        case "SUCCESS":
          res = {
            label: "发布成功",
            color: "green",
          };
          break;
        case "FAILURE":
          res = {
            label: "发布失败",
            color: "red",
          };
          break;
        case "CANCELED":
          res = {
            label: "已取消",
            color: "red",
          };
          break;
        case "AUDIT_FAILURE":
          res = {
            label: "审核失败",
            color: "red",
          };
          break;
        case "COMPILED":
          res = {
            label: "等待发布",
            color: "orange",
          };
          break;
      }
      return res;
    },
    // 关闭过度动画
    closeLoading() {
      if (this.loadingCommand) {
        this.loadingCommand.close();
        this.loadingCommand = null;
      }
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
};
</script>
<style lang="scss" scoped>
.postingDetails {
  position: relative;
  .postingDetails_head {
    margin-bottom: 20px;
    position: relative;
    .head_title_1 {
      font-weight: bold;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.85);
      .m_arrow_left {
        cursor: pointer;
      }
      .m_arrow_left:hover {
        color: #1890ff;
      }
    }
    .head_title_2 {
      display: inline-block;
      margin: 0 10px;
    }
    .buttonAction {
      float: right;
      button {
        margin-right: 20px;
      }
    }
  }

  .postingDetails_pipeline {
    height: 700px;

    &::v-deep .pipeline_content {
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ededed;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #7c7c7c;
      }
    }
  }
  .postingDetails_command {
    position: relative;
    margin-bottom: 20px;
  }
  .postingDetails_content {
    position: relative;
    .content_collapse {
      display: flex;
      justify-content: space-between;
    }
  }
  ::v-deep .ant-collapse-content-box {
    padding: 0;
  }
  .textarea {
    margin: 0;
    padding: 10px 15px;
  }
}
</style>
