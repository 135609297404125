<template>
  <div class="ingressList">
    <div class="reminder">路由 <span>ingress</span></div>
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="name"
          @pressEnter="query"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.name"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <span slot="k8sIngressRuleVoList" slot-scope="text, record">
        <div v-for="item in record.k8sIngressRuleVoList">
          <a :href="item.url" target="_blank">{{ item.url }}</a>
          -> {{ item.serviceName }}
        </div>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="updateClick(record)">更新</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <a-popconfirm
      placement="right"
      okText="确认"
      cancelText="取消"
      @confirm="batchDeleteClick()"
      :disabled="selectedRowKeys.length == 0"
    >
      <template slot="title">确认是否批量删除</template>
      <a-button
        type="danger"
        icon="delete"
        :disabled="selectedRowKeys.length == 0"
        >批量删除</a-button
      >
    </a-popconfirm>

    <a-drawer
      title="更新"
      placement="right"
      :visible="updateShow"
      @close="updateShow = false"
      width="1000"
    >
      <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
        <a-form-item label="名称:">
          <a-input v-model.trim="updateName" disabled />
        </a-form-item>
        <a-form-item label="ingressClass:">
          <a-select
            v-model="updateData.ingressClass"
            :dropdownMatchSelectWidth="false"
          >
            <a-select-option
              v-for="(item, index) in ingressClassList"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="规则:">
          <a-button type="link" @click="addRule" size="small" icon="plus"
            >添加规则</a-button
          >
          <div v-for="(item, ruleIndex) in updateData.ruleList">
            <div class="item">
              <a-button
                type="link"
                @click="deleteRule(ruleIndex)"
                size="small"
                class="close"
                v-if="ruleIndex !== 0"
              >
                <a-icon type="close" />
              </a-button>
              <div>域名</div>
              <a-input v-model="item.domainName" placeholder="请输入域名" />
              <a-button
                type="link"
                @click="addMapping(ruleIndex)"
                size="small"
                icon="plus"
                >添加路径</a-button
              >
              <a-table
                :rowClassName="$common.rowClassColor"
                bordered
                :components="$common.getTitle(mappingTableColumns)"
                :columns="mappingTableColumns"
                :dataSource="item.mappingList"
                :pagination="false"
                size="small"
              >
                <span slot="path" slot-scope="text, record">
                  <a-input v-model="record.path" />
                </span>
                <span slot="rule" slot-scope="text, record">
                  <a-select
                    v-model="record.rule"
                    :dropdownMatchSelectWidth="false"
                    style="width: 200px"
                  >
                    <a-select-option
                      v-for="(item, index) in ruleSelectList"
                      :key="index"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </span>
                <span slot="service" slot-scope="text, record">
                  <a-select
                    v-model="record.service"
                    :dropdownMatchSelectWidth="false"
                    showSearch
                  >
                    <a-select-option
                      v-for="(item, index) in serviceList"
                      :key="index"
                      :value="item.name"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </span>
                <span slot="port" slot-scope="text, record">
                  <a-select
                    v-model="record.port"
                    :dropdownMatchSelectWidth="false"
                    @focus="getPortList(record)"
                  >
                    <a-select-option
                      v-for="(item, index) in portList"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </a-select-option>
                  </a-select>
                </span>
                <span slot="action" slot-scope="text, record, index">
                  <a
                    href="javascript:;"
                    style="color: #ff4d4f"
                    @click="deleteMapping(ruleIndex, index)"
                    >删除</a
                  >
                </span>
              </a-table>
            </div>
          </div>
        </a-form-item>
        <a-form-item label="TLS配置:">
          <a-button type="link" @click="addDomain" size="small" icon="plus"
            >新增TLS配置</a-button
          >
          <div class="item">
            <a-table
              :rowClassName="$common.rowClassColor"
              bordered
              :components="$common.getTitle(domainTableColumns)"
              :columns="domainTableColumns"
              :dataSource="updateData.domainList"
              :pagination="false"
              size="small"
            >
              <span slot="domainName" slot-scope="text, record">
                <a-input v-model="record.domainName" />
              </span>
              <span slot="secret" slot-scope="text, record">
                <a-select
                  v-model="record.secret"
                  :dropdownMatchSelectWidth="false"
                  style="width: 300px"
                  showSearch
                >
                  <a-select-option
                    v-for="(item, index) in secretList"
                    :key="index"
                    :value="item.metadata.name"
                  >
                    {{ item.metadata.name }}
                  </a-select-option>
                </a-select>
              </span>
              <span slot="action" slot-scope="text, record, index">
                <a
                  href="javascript:;"
                  style="color: #ff4d4f"
                  @click="deleteDomain(index)"
                  >删除</a
                >
              </span>
            </a-table>
          </div>
        </a-form-item>
      </a-form>

      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="updateShow = false">
          取消
        </a-button>
        <a-button type="primary" @click="updateSubmit"> 确定 </a-button>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import * as api from "../lib/k8s.js";

export default {
  name: "ingressList",
  props: ["namespace"],
  data() {
    return {
      name: "",
      tableColumns: [
        {
          title: "名称",
          dataIndex: "name",
        },
        {
          title: "命名空间",
          dataIndex: "namespace",
          width: 100,
        },
        {
          title: "网关类型",
          dataIndex: "gatewayType",
        },
        {
          title: "标签",
          dataIndex: "k8sIngressRuleVoList",
          scopedSlots: { customRender: "k8sIngressRuleVoList" },
        },
        {
          title: "端点",
          dataIndex: "endPoint",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      loadingTable: false,
      selectedRowKeys: [],
      secretList: [],
      updateData: {
        domainList: [],
        ingress: null,
        ruleList: [],
      },
      updateShow: false,
      ruleSelectList: [
        { label: "前缀匹配（Prefix）", value: "Prefix" },
        { label: "完整匹配（Exact）", value: "Exact" },
        {
          label: "默认（ImplementationSpecific）",
          value: "ImplementationSpecific",
        },
      ],
      serviceList: [],
      mappingTableColumns: [
        {
          title: "路径",
          dataIndex: "path",
          scopedSlots: { customRender: "path" },
          width: 200,
        },
        {
          title: "匹配规则",
          dataIndex: "rule",
          scopedSlots: { customRender: "rule" },
          width: 200,
        },
        {
          title: "服务名称",
          dataIndex: "service",
          scopedSlots: { customRender: "service" },
        },
        {
          title: "端口",
          dataIndex: "port",
          scopedSlots: { customRender: "port" },
          width: 100,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 60,
        },
      ],
      domainTableColumns: [
        {
          title: "域名",
          dataIndex: "domainName",
          scopedSlots: { customRender: "domainName" },
        },
        {
          title: "保密字典",
          dataIndex: "secret",
          scopedSlots: { customRender: "secret" },
          width: 300,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 60,
        },
      ],
      updateName: "",
      ingressClassList: [],
      portList: [],
    };
  },
  watch: {
    namespace: {
      immediate: true,
      handler() {
        this.query();
        this.getIngressClassList();
        this.getServiceList();
        this.getSecretList();
      },
    },
  },
  methods: {
    getIngressClassList() {
      api.ingressClassList().then((res) => {
        if (res.result === 200) {
          this.ingressClassList = res.data;
        }
      });
    },
    getServiceList() {
      let data = {
        namespace: this.namespace,
      };
      api.serviceList(data).then((res) => {
        if (res.result === 200) {
          this.serviceList = res.data;
        }
      });
    },
    getPortList(val) {
      this.portList.splice(0);
      this.portList.push(
        ...this.serviceList.filter((item) => {
          return item.name == val.service;
        })[0].ports
      );
    },
    getSecretList() {
      let data = {
        namespace: this.namespace,
      };
      api.secretList(data).then((res) => {
        if (res.result === 200) {
          this.secretList = res.data;
        }
      });
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query();
    },
    query() {
      let data = {
        namespace: this.namespace,
        name: this.name,
      };
      if (this.namespace == "全部命名空间" && this.name) {
        this.tableDataSource = this.tableDataSource.filter((item) => {
          return item.name.indexOf(this.name) !== -1;
        });
        return;
      }
      this.loadingTable = true;
      api
        .ingressList(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource = res.data;
            this.tableDataSource.forEach((item) => {
              item.k8sIngressRuleVoList.forEach((rule) => {
                this.$set(
                  rule,
                  "url",
                  (rule.port == "80" ? "http" : "https") + "://" + rule.host
                );
              });
            });
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    updateClick(val) {
      this.updateName = val.name;
      this.updateData = val.rule;
      this.$set(this.updateData, "ingressClass", val.gatewayType);
      this.updateShow = true;
    },
    addRule() {
      this.updateData.ruleList.push({
        domainName: "",
        mappingList: [
          {
            path: "/",
            rule: "Prefix",
            service: "",
            port: "",
          },
        ],
      });
    },
    deleteRule(ruleIndex) {
      this.updateData.ruleList.splice(ruleIndex, 1);
    },
    addMapping(ruleIndex) {
      this.updateData.ruleList[ruleIndex].mappingList.push({
        path: "/",
        rule: "Prefix",
        service: "",
        port: "",
      });
    },
    deleteMapping(ruleIndex, index) {
      this.updateData.ruleList[ruleIndex].mappingList.splice(index, 1);
    },
    addDomain() {
      this.updateData.domainList.push({
        domainName: "",
        secret: "",
      });
    },
    deleteDomain(index) {
      this.updateData.domainList.splice(index, 1);
    },
    updateSubmit() {
      let data = { ...this.updateData };

      api.updateIngressRule(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("更新成功");
          this.updateShow = false;
          this.query();
        }
      });
    },
    deleteClick(val) {
      let data = {
        name: val.name,
        namespace: val.namespace,
      };
      api.deleteIngress(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    batchDeleteClick() {
      let arr = [];
      this.selectedRowKeys.forEach((name) => {
        this.tableDataSource.forEach((item) => {
          if (item.name == name) {
            arr.push(item);
          }
        });
      });
      this.$axios
        .all(
          arr.map((item) => {
            let data = {
              name: item.name,
              namespace: item.namespace,
            };
            return api.deleteIngress(data);
          })
        )
        .then((resArr) => {
          this.$message.success("删除成功");
          this.query();
        });
    },
  },
};
</script>
<style scoped lang="scss">
.item {
  background-color: #f5f6fa;
  padding: 10px;
  margin-top: 10px;
  position: relative;
  .close {
    color: red;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .ant-table-wrapper {
    background-color: #fff !important;
  }
}
</style>
