import http from "./axios";

// 查询列表
const queryPage = ({
  pageNo,
  pageSize,
  serverId,
  ip,
  deployType,
  nginxInstanceId,
  listenServerGroupId,
  domain,
}) => {
  return http.get("/nginx/nginxGatewayPage", {
    pageNo,
    pageSize,
    serverId,
    ip,
    deployType,
    nginxInstanceId,
    listenServerGroupId,
    domain,
  });
};

// 添加
const addData = ({
  serverId,
  protocolType,
  port,
  deployType,
  domainId,
  nginxInstanceId,
  listenServerGroupId,
}) => {
  return http.json_post("/nginx/addNginxGateway", {
    serverId,
    protocolType,
    port,
    deployType,
    domainId,
    nginxInstanceId,
    listenServerGroupId,
  });
};

// 删除
const deleteData = ({ id }) => {
  return http.json_post("/nginx/deleteNginxGateway", {
    id,
  });
};

// 查询证书列表
const nginxGatewaySslPage = ({ pageNo, pageSize, nginxGatewayId }) => {
  return http.get("/nginx/nginxGatewaySslPage", {
    pageNo,
    pageSize,
    nginxGatewayId,
  });
};

// 添加证书
const addNginxGatewaySsl = ({ certificateId, nginxGatewayId }) => {
  return http.json_post("/nginx/addNginxGatewaySsl", {
    certificateId,
    nginxGatewayId,
  });
};

const bindDns = ({ id }) => {
  return http.json_post("/nginx/bindDns", {
    id,
  });
};

// 删除证书
const deleteNginxGatewaySsl = ({ id }) => {
  return http.json_post("/nginx/deleteNginxGatewaySsl", {
    id,
  });
};

export default {
  deleteData,
  queryPage,
  addData,
  nginxGatewaySslPage,
  addNginxGatewaySsl,
  deleteNginxGatewaySsl,
  bindDns,
};
