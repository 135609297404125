<template>
  <div class="schedulingTemplatePage">
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="name"
          @pressEnter="query"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="edit(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <a-popconfirm
      placement="right"
      okText="确认"
      cancelText="取消"
      @confirm="batchDeleteClick()"
      :disabled="selectedRowKeys.length == 0"
    >
      <template slot="title">确认是否批量删除</template>
      <a-button
        type="danger"
        icon="delete"
        :disabled="selectedRowKeys.length == 0"
        >批量删除</a-button
      >
    </a-popconfirm>

    <a-modal
      :title="title"
      v-model="updateShow"
      :maskClosable="false"
      width="670px"
    >
      <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="名称:">
          <a-input v-model="updateData.name" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item>
          <span slot="label">
            升级方式
            <a-tooltip>
              <template slot="title">
                滚动升级: 启动新Pod, 暂停旧版本的Pod, 适用于大部分情况 <br />
                替换升级: 先停止旧版本的所有Pod,
                然后启动新版本的Pod,升级期间会有短暂的服务不可用
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <div>
            <a-radio-group
              :options="kubeSchedulingUpdateMethods"
              v-model="updateData.updateMethod"
            />
          </div>
          <a-form>
            <a-row type="flex" justify="space-between">
              <template v-if="updateData.updateMethod == 'ROLLING_UPDATE'">
                <a-col>
                  <a-form-item label="不可用Pod最大数量:">
                    <a-input v-model="updateData.maxUnavailable">
                      <a-select
                        slot="addonAfter"
                        v-model="updateData.maxUnavailableUnit"
                      >
                        <a-select-option
                          v-for="(item, index) in unitList"
                          :key="index"
                          :value="item"
                        >
                          {{ item }}
                        </a-select-option>
                      </a-select>
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-col>
                  <a-form-item label="超过期望的Pod数量:">
                    <a-input v-model="updateData.maxSurge">
                      <a-select
                        slot="addonAfter"
                        v-model="updateData.maxSurgeUnit"
                      >
                        <a-select-option
                          v-for="(item, index) in unitList"
                          :key="index"
                          :value="item"
                        >
                          {{ item }}
                        </a-select-option>
                      </a-select>
                    </a-input>
                  </a-form-item>
                </a-col>
              </template>
              <a-col>
                <a-form-item>
                  <span slot="label">
                    最小准备时间
                    <a-tooltip>
                      <template slot="title">
                        在Pod被视为可用之前，Pod中的容器需至少运行多长时间，默认为0
                      </template>
                      <a-icon type="question-circle" />
                    </a-tooltip>
                  </span>
                  <a-input v-model="updateData.minimumReadyTime">
                    <div slot="addonAfter" style="width: 28px">秒</div>
                  </a-input>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="updateShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="update_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/k8s.js";

export default {
  name: "schedulingTemplatePage",
  data() {
    return {
      name: "",
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          width: 100,
        },
        {
          title: "名称",
          dataIndex: "name",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 120,
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      pageNo: 1,
      updateData: {},
      title: "",
      updateShow: false,
      selectedRowKeys: [],
      unitList: ["个", "%"],
      kubeSchedulingUpdateMethods: [],
    };
  },
  created() {
    this.getKubeSchedulingUpdateMethodEnum();
    this.query();
  },
  methods: {
    getKubeSchedulingUpdateMethodEnum() {
      api.kubeSchedulingUpdateMethodEnum().then((res) => {
        if (res.result == 200) {
          for (const label in res.data) {
            const value = res.data[label];
            this.kubeSchedulingUpdateMethods.unshift({ label, value });
          }
        }
      });
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query();
    },
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        name: this.name,
      };
      this.loadingTable = true;
      api
        .schedulingTemplatePage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource = res.data.records;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: 15,
              current: this.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    changePageItem(index) {
      this.query(index);
    },
    add() {
      this.title = "新增";
      this.updateData = {
        name: "",
        updateMethod: "ROLLING_UPDATE",
        maxUnavailable: 0,
        maxUnavailableUnit: "个",
        maxSurge: 0,
        maxSurgeUnit: "个",
        minimumReadyTime: 0,
      };
      this.updateShow = true;
    },
    edit(val) {
      this.title = "修改";
      this.updateData = {
        id: val.id,
        name: val.name,
        updateMethod: val.updateMethod,
        maxUnavailable: val.maxUnavailable,
        maxUnavailableUnit: val.maxUnavailableUnit,
        maxSurge: val.maxSurge,
        maxSurgeUnit: val.maxSurgeUnit,
        minimumReadyTime: val.minimumReadyTime,
      };
      this.updateShow = true;
    },
    update_submit() {
      let data = { ...this.updateData };
      if (this.title == "新增") {
        api.addSchedulingTemplate(data).then((res) => {
          if (res.result === 200) {
            this.$message.success("新增成功");
            this.updateShow = false;
            this.query();
          }
        });
      } else {
        api.editSchedulingTemplate(data).then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.updateShow = false;
            this.query();
          }
        });
      }
    },
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteSchedulingTemplate(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    batchDeleteClick() {
      let arr = [];
      this.selectedRowKeys.forEach((id) => {
        this.tableDataSource.forEach((item) => {
          if (item.id == id) {
            arr.push(item);
          }
        });
      });
      this.$axios
        .all(
          arr.map((item) => {
            let data = {
              id: item.id,
            };
            return api.deleteSchedulingTemplate(data);
          })
        )
        .then((resArr) => {
          this.$message.success("删除成功");
          this.query();
        });
    },
  },
};
</script>
