import { render, staticRenderFns } from "./vpnManager.vue?vue&type=template&id=57ed679e&scoped=true"
import script from "./vpnManager.vue?vue&type=script&lang=js"
export * from "./vpnManager.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57ed679e",
  null
  
)

export default component.exports