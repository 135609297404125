import http from "./axios";
// 查询shell执行记录
export const logPage = ({ pageNo, pageSize, id, serverId }) => {
  return http.get("/shell/timeTask/logPage", {
    pageNo: pageNo,
    pageSize: pageSize,
    id: id,
    serverId: serverId,
  });
};
