<template>
  <div class="securityItem">
    <div class="searchButton">
      <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      <a-button type="primary" @click="addClick">添加安全组规则</a-button>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      rowKey="id"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="false"
      :loading="tableLoading"
      size="small"
    >
      <span slot="policy" slot-scope="text, record">
        <span v-for="item in policyList" :key="item.value">
          <span v-if="text === item.value">{{ item.label }}</span>
        </span>
      </span>
      <span slot="ipProtocol" slot-scope="text, record">
        <span v-for="(item, i) in ipProtocolList" :key="i">
          <span v-if="text === item.value">{{ item.label }}</span>
        </span>
      </span>
      <span slot="nicType" slot-scope="text, record">
        <span v-for="item in nicTypeList" :key="item.value">
          <span v-if="text === item.value">{{ item.label }}</span>
        </span>
      </span>
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="copyClick(record)">克隆</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title"
            >确认是否删除{{ record.sourceCidrIp }}</template
          >
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 添加安全组规则 -->
    <a-modal
      :title="modifyTitle"
      v-model="modifyShow"
      :maskClosable="false"
      :afterClose="modifyClose"
      width="580px"
    >
      <a-form>
        <a-form-item
          label="网卡类型:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="modifyForm.nicType"
            placeholder="请选择网卡类型"
            style="width: 100%"
            disabled
          >
            <a-select-option v-for="item in nicTypeList" :key="item.value">{{
              item.label
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="规则方向:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="modifyForm.direction"
            placeholder="请选择规则方向"
            style="width: 100%"
          >
            <a-select-option v-for="item in directionList" :key="item.value">{{
              item.label
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="授权策略:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="modifyForm.policy"
            placeholder="请选择授权策略"
            style="width: 100%"
          >
            <a-select-option v-for="item in policyList" :key="item.value">{{
              item.label
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="协议类型:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-model="modifyForm.ipProtocolLabel"
            placeholder="请选择协议类型"
            style="width: 100%"
            @change="ipProtocolChange"
          >
            <a-select-option v-for="(item, i) in ipProtocolList" :key="i">{{
              item.label
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="端口范围:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input
            placeholder="例如:22/22或3389/3389"
            v-model="modifyForm.portRange"
            allowClear
            :disabled="portRangeDisabled"
          />
          <a-tooltip>
            <template slot="title">
              取值范围从1到65535；设置格式例如“1/200”、“80/80”，其中“-1/-1”不能单独设置，代表不限制端口。
            </template>
            <a-icon type="question-circle" class="question-cc" />
          </a-tooltip>
        </a-form-item>
        <a-form-item
          label="优先级:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input
            placeholder="请输入优先级"
            v-model="modifyForm.priority"
            allowClear
          />
          <a-tooltip>
            <template slot="title">
              优先级可选范围为1-100，默认值为1，即最高优先级。
            </template>
            <a-icon type="question-circle" class="question-cc" />
          </a-tooltip>
        </a-form-item>
        <!-- <a-form-item label="授权类型:" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
          <a-select
            v-model="modifyForm.nicType"
            placeholder="请选择授权类型"
            style="width: 100%"
          >
            <a-select-option v-for="item in typeList" :key="item.value">{{item.label}}</a-select-option>
          </a-select>
        </a-form-item> -->
        <a-form-item
          label="授权对象:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-textarea
            placeholder='例如:10.x.y.z/32，添加规则时支持多组授权对象，用","隔开，最多支持10组授权对象。'
            v-model="modifyForm.sourceCidrIp"
            allowClear
            :rows="2"
          ></a-textarea>
          <a-tooltip>
            <template slot="title">
              请根据实际场景设置授权对象的CIDR，另外，0.0.0.0/0
              代表允许或拒绝所有IP的访问，设置时请务必谨慎。
            </template>
            <a-icon type="question-circle" class="question-cc" />
          </a-tooltip>
        </a-form-item>
        <a-form-item
          label="描述:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          help="长度为2～256个字符，不能以http://或https://开头。"
        >
          <a-textarea
            v-model="modifyForm.description"
            allowClear
            :rows="2"
          ></a-textarea>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="modifyShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="modify_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/securityGroupManagement.js";
export default {
  name: "securityItem",
  props: {
    direction: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      modifyTitle: "",
      policyList: [
        {
          value: "Drop",
          label: "拒绝",
        },
        {
          value: "Accept",
          label: "允许",
        },
      ],
      directionList: [
        {
          value: "ingress",
          label: "入方向",
        },
        {
          value: "egress",
          label: "出方向",
        },
      ],
      ipProtocolList: [
        {
          value: "ALL",
          label: "全部",
          portRange: "-1/-1",
          disabled: true,
        },
        {
          value: "TCP",
          label: "自定义 TCP",
          portRange: "",
          disabled: false,
        },
        {
          value: "UDP",
          label: "自定义 UDP",
          portRange: "",
          disabled: false,
        },
        {
          value: "TCP",
          label: "SSH（22）",
          portRange: "22/22",
          disabled: true,
        },
        {
          value: "TCP",
          label: "telnet（23）",
          portRange: "23/23",
          disabled: true,
        },
        {
          value: "TCP",
          label: "HTTP（80）",
          portRange: "80/80",
          disabled: true,
        },
        {
          value: "TCP",
          label: "HTTPS（443）",
          portRange: "443/443",
          disabled: true,
        },
        {
          value: "TCP",
          label: "MS SQL（1433）",
          portRange: "1433/1433",
          disabled: true,
        },
        {
          value: "TCP",
          label: "Oracle（1521）",
          portRange: "1521/1521",
          disabled: true,
        },
        {
          value: "TCP",
          label: "MySQL（3306）",
          portRange: "3306/3306",
          disabled: true,
        },
        {
          value: "TCP",
          label: "RDP（3389）",
          portRange: "3389/3389",
          disabled: true,
        },
        {
          value: "TCP",
          label: "PostgreSQL（5432）",
          portRange: "5432/5432",
          disabled: true,
        },
        {
          value: "TCP",
          label: "Redis（6379）",
          portRange: "6379/6379",
          disabled: true,
        },
      ],
      nicTypeList: [
        {
          value: "internet",
          label: "公网网卡",
        },
        {
          value: "intranet",
          label: "内网网卡",
        },
      ],
      portRangeDisabled: false,
      modifyShow: false,
      modifyForm: {
        direction: undefined,
        ipProtocol: undefined,
        ipProtocolLabel: 1,
        nicType: "intranet",
        policy: "Accept",
        portRange: "",
        priority: "1",
        sourceCidrIp: "",
        description: "",
      },
      typeList: [],
      loading: false,
      name: "",
      pageNo: 1,
      tableColumns: [
        {
          title: "授权策略",
          ellipsis: true,
          dataIndex: "policy",
          scopedSlots: { customRender: "policy" },
        },
        {
          title: "协议类型",
          ellipsis: true,
          dataIndex: "ipProtocol",
          scopedSlots: { customRender: "ipProtocol" },
        },
        {
          title: "端口范围",
          ellipsis: true,
          dataIndex: "portRange",
        },
        {
          title: "网卡类型",
          ellipsis: true,
          dataIndex: "nicType",
          scopedSlots: { customRender: "nicType" },
        },
        {
          title: "授权对象",
          ellipsis: true,
          dataIndex: "sourceCidrIp",
        },
        {
          title: "描述",
          ellipsis: true,
          dataIndex: "description",
        },
        {
          title: "优先级",
          ellipsis: true,
          dataIndex: "priority",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tableLoading: false,
      securityGroupId: "",
    };
  },
  mounted() {
    this.securityGroupId = this.$route.query.securityGroupId;
    this.query(1);
  },
  methods: {
    // 选择协议类型
    ipProtocolChange(val) {
      this.portRangeDisabled = false;
      this.ipProtocolList.forEach((item, index) => {
        if (index === val) {
          this.modifyForm.ipProtocol = item.value;
          this.modifyForm.portRange = item.portRange;
          this.portRangeDisabled = item.disabled;
        }
      });
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        securityGroupId: this.securityGroupId,
        direction: this.direction,
      };
      this.getSecurityGroupItemList(data);
    },
    // 查询安全组
    getSecurityGroupItemList(data) {
      this.tableLoading = true;
      api
        .securityGroupItemList(data)
        .then((res) => {
          this.tableLoading = false;
          if (res.result === 200) {
            let list = res.data;
            this.tableDataSource = list;
          }
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    // 点击新增
    addClick() {
      this.modifyTitle = "添加安全组规则";
      this.modifyShow = true;
      this.modifyForm.direction = this.direction;
    },
    // 确定新增
    modify_submit() {
      let data = {
        securityGroupId: this.securityGroupId,
        ipProtocol: this.modifyForm.ipProtocol,
        portRange: this.modifyForm.portRange,
        sourceCidrIp: this.modifyForm.sourceCidrIp,
        policy: this.modifyForm.policy,
        nicType: this.modifyForm.nicType,
        priority: this.modifyForm.priority,
        direction: this.modifyForm.direction,
        description: this.modifyForm.description,
      };
      this.loading = true;
      if (
        this.modifyTitle === "添加安全组规则" ||
        this.modifyTitle === "克隆"
      ) {
        api
          .addSecurityGroupItem(data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.$message.success(this.modifyTitle + "成功");
              this.modifyShow = false;
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
      if (this.modifyTitle === "编辑安全组规则") {
      }
    },
    // 关闭新增
    modifyClose() {
      this.portRangeDisabled = false;
      this.loading = false;
      this.modifyForm = {
        direction: undefined,
        ipProtocol: undefined,
        ipProtocolLabel: 1,
        nicType: "intranet",
        policy: "Accept",
        portRange: "",
        priority: "1",
        sourceCidrIp: "",
        description: "",
      };
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteSecurityGroupItem(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    // 点击修改
    modifyClick(val) {
      this.modifyForm.direction = val.direction;
      this.modifyForm.nicType = val.nicType;
      this.modifyForm.policy = val.policy;
      this.modifyForm.priority = val.priority;
      this.modifyForm.sourceCidrIp = val.sourceCidrIp;
      this.modifyForm.description = val.description;
      for (let i in this.ipProtocolList) {
        let item = this.ipProtocolList[i];
        if (item.portRange === val.portRange) {
          this.modifyForm.ipProtocolLabel = Number(i);
          break;
        } else if (item.value === val.ipProtocol) {
          this.modifyForm.ipProtocolLabel = Number(i);
          break;
        }
      }
      this.ipProtocolChange(this.modifyForm.ipProtocolLabel);
      this.modifyTitle = "编辑安全组规则";
      this.modifyShow = true;
    },
    // 点击克隆
    copyClick(val) {
      this.modifyForm.direction = val.direction;
      this.modifyForm.ipProtocolLabel = val.direction;
      this.modifyForm.nicType = val.nicType;
      this.modifyForm.policy = val.policy;
      this.modifyForm.priority = val.priority;
      this.modifyForm.sourceCidrIp = val.sourceCidrIp;
      this.modifyForm.description = val.description;
      for (let i in this.ipProtocolList) {
        let item = this.ipProtocolList[i];
        if (item.portRange === val.portRange) {
          this.modifyForm.ipProtocolLabel = Number(i);
          break;
        } else if (item.value === val.ipProtocol) {
          this.modifyForm.ipProtocolLabel = Number(i);
          break;
        }
      }
      this.ipProtocolChange(this.modifyForm.ipProtocolLabel);
      this.modifyTitle = "克隆";
      this.modifyShow = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.question-cc {
  position: absolute;
  right: -30px;
  top: 0px;
}
</style>
