import http from "./axios";

// 查询编译数据
export const deliveryRequestLogDetailList = ({ requestId, logType, minId }) => {
  return http.get("/delivery/log/deliveryRequestLogDetailList", {
    requestId: requestId,
    logType: logType,
    minId: minId,
  });
};

// 查询编译数据
export const deliveryLog = ({ pageNo, pageSize, requestId }) => {
  return http.get("/delivery/log/deliveryLog", {
    pageNo: pageNo,
    pageSize: pageSize,
    requestId: requestId,
  });
};
