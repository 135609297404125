import http from "./axios";

// 目录列表
export const userServerRelationList = () => {
  return http.get("/delivery/userServerRelation/list");
};

// 新增分组
export const addUserServerGroup = ({ name }) => {
  return http.json_post("/delivery/userServerAccountGroup/addUserServerGroup", {
    name: name,
  });
};

// 删除分组
export const deleteUserServerGroup = ({ id }) => {
  return http.json_post(
    "/delivery/userServerAccountGroup/deleteUserServerGroup",
    {
      id: id,
    }
  );
};

// 修改分组名称
export const groupRename = ({ userServerGroupId, name }) => {
  return http.json_post("/delivery/userServerAccountGroup/rename", {
    userServerGroupId: userServerGroupId,
    name: name,
  });
};

// 服务器分组列表
export const groupList = () => {
  return http.get("/delivery/userServerAccountGroup/list");
};

// 服务器名称修改
export const rename = ({ userServerAccountId, alias }) => {
  return http.json_post("/delivery/serverAccount/rename", {
    userServerAccountId: userServerAccountId,
    alias: alias,
  });
};

// 服务器分组修改
export const reGroup = ({ userServerAccountGroupId, userServerAccountId }) => {
  return http.json_post("/delivery/serverAccount/reGroup", {
    userServerAccountGroupId: userServerAccountGroupId,
    userServerAccountId: userServerAccountId,
  });
};

// 查询常用命令
export const memoList = ({ pageNo, pageSize, type }) => {
  return http.get("/delivery/memo/list", {
    pageNo: pageNo,
    pageSize: pageSize,
    type: type,
  });
};

// 新增常用命令
export const memoAddMemo = ({ name, content, type, remark }) => {
  return http.json_post("/delivery/memo/addMemo", {
    name: name,
    content: content,
    type: type,
    remark: remark,
  });
};

// 删除常用命令
export const memoDeleteMemo = ({ id }) => {
  return http.json_post("/delivery/memo/deleteMemo", {
    id: id,
  });
};

// 修改常用命令
export const memoUpdateMemo = ({ id, name, content, remark }) => {
  return http.json_post("/delivery/memo/updateMemo", {
    id: id,
    name: name,
    content: content,
    remark: remark,
  });
};

// 查询服务器列表
export const listFile = ({ serverAccountId, path }) => {
  return http.get("/delivery/fileManager/listFile", {
    serverAccountId: serverAccountId,
    path: path,
  });
};

// 查询cookie
export const cookieInfo = () => {
  return http.get("/delivery/user/cookieInfo");
};

// 新建文件夹
export const newFolder = ({ serverAccountId, path, folderName }) => {
  return http.json_post("/delivery/fileManager/newFolder", {
    serverAccountId: serverAccountId,
    path: path,
    folderName: folderName,
  });
};

// 上传文件
export const upload = ({ file, uploadPath, serverAccountId }) => {
  return http.files("/delivery/fileManager/uploadFile", {
    file: file,
    uploadPath: uploadPath,
    serverAccountId: serverAccountId,
  });
};
