import http from "./axios";

// 查询发布申请列表
export const deliveryRequestList = (data) => {
  return http.get("/delivery/deliveryRequest/list", data);
};

export const queryStatus = (data) => {
  return http.get("/delivery/deliveryRequest/queryStatus", data);
};

export const recentlyProjectList = (data) => {
  return http.get("/delivery/project/recentlyProjectList", data);
};

// 审核操作
export const deliveryRequestAudit = (data) => {
  return http.json_post("/delivery/deliveryRequest/audit", data);
};
// 发布
export const deliveryRequestDeliveryNew = (data) => {
  return http.json_post("/delivery/deliveryRequest/deliveryNew", data);
};

// 删除
export const deleteDeliveryRequest = (data) => {
  return http.json_post(
    "/delivery/deliveryRequest/deleteDeliveryRequest",
    data
  );
};

// 撤销发布
export const cancelDeliveryRequest = (data) => {
  return http.json_post(
    "/delivery/deliveryRequest/cancelDeliveryRequest",
    data
  );
};

// 发布列表详情
export const configDetailList = (data) => {
  return http.get("/delivery/deliveryConfig/configDetail", data);
};

// 同步数据
export const synGitCommitLog = (data) => {
  return http.get("/delivery/git/synGitCommitLog", data);
};

// 重新发布
export const reDeliveryNew = (data) => {
  return http.json_post("/delivery/deliveryRequest/reDeliveryNew", data);
};
